import { Form, Input, Switch } from 'antd';
import { useEffect } from 'react';

import BackButton from './BackButton';
import MbxButton from './base/MbxButton';

import type { Asset } from '../reducers/AssetReducer';

type Props = Readonly<{
  data?: Asset;
  backLink: string;
  isSaving: boolean;
  onSubmit: (values: { name: string; isDraft: boolean }) => Promise<void>;
}>;

//
//
const AssetEditForm = (props: Props) => {
  const { data, onSubmit, isSaving, backLink } = props;
  const [form] = Form.useForm();

  const assetName = data?.name ?? '';

  useEffect(() => {
    form.setFieldsValue({
      name: assetName,
    });
  }, [assetName, form]);

  const handleSubmit = async (values: { name: string; isDraft: boolean }) => {
    console.log('Received values of form: ', values);
    await onSubmit(values);
  };

  return (
    <div>
      <Form onFinish={handleSubmit} name="AssetEditForm" layout="vertical" form={form}>
        <Form.Item
          label="(internal) name"
          name="name"
          initialValue={data?.name ?? ''}
          rules={[{ required: true, message: "Please input the asset's name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="is draft"
          name="isDraft"
          initialValue={data?.isDraft ?? false}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item>
          <MbxButton
            mbxType="primary"
            htmlType="submit"
            loading={isSaving}
            data-tid="btn-asset-submit"
          >
            {data != null ? 'Save' : 'Create'}
          </MbxButton>
          <BackButton
            style={{ marginLeft: '1em' }}
            disabled={isSaving}
            linkTo={backLink}
            size="large"
          >
            Cancel
          </BackButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AssetEditForm;
