import { Area } from '@ant-design/charts';
import classNames from 'classnames';
import { useState } from 'react';

import styles from './GraphAndTable.module.scss';
import { useOptionalCurrentAppContext } from '../../contexts';
import { getTrendString, getTrendType, TrendTypes } from '../../utils/analytics';

import type { Types } from '@antv/g2';

export type GraphAndTableData = {
  days: Array<{ day: string; views: number }>;
  languages: Array<{
    name: string;
    total: number | null;
    views: number | null;
    viewsPrior?: number | null;
    trend: number | null;
    days: Array<{ day: string; views: number }>;
  }>;
};

type Props = {
  renderGraph?: boolean;
  graphTitle: string;
  graphAndTableData: GraphAndTableData;
};

function notNull<T>(val: T | null): val is T {
  return val !== null;
}

const GRADIENT = 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#3ebfff';
const COLOR_LINE_ACTIVE = 'rgba(62, 191, 255, 1)';
const COLOR_LINE_INACTIVE = 'rgba(62, 191, 255, .25)';

const GraphAndTable = ({ renderGraph = true, graphTitle, graphAndTableData }: Props) => {
  const { currentApp } = useOptionalCurrentAppContext();
  const currentAppId = currentApp?.id;

  const [rolloverLanguage, setRolloverLanguage] = useState<string | null>(null);

  const totalData = graphAndTableData.days.map((item) => ({
    day: item.day,
    views: item.views,
    category: 'Total',
  }));

  // make the weekend background grey
  const annotations = totalData
    .map(({ day }, index) => {
      const dayIndex = new Date(day).getDay();

      // filter out non-Saturdays
      if (dayIndex !== 6) {
        return null;
      }

      return {
        type: 'region',
        start: [index, 'min'] as Types.AnnotationPosition,
        end: [index + 1, 'max'] as Types.AnnotationPosition,
        style: { fill: '#666' },
      };
    })
    .filter(notNull);

  const languagesData = graphAndTableData.languages.map((language) =>
    language.days.map((item) => ({
      day: item.day,
      views: item.views,
      category: language.name,
    }))
  );

  const mergedData = languagesData.concat(
    graphAndTableData.languages.length > 1 ? [totalData] : []
  );

  // Sort it, so that the current active line is always last in the array. That
  // way it will be drawn on top of the other lines.
  mergedData.sort((a) => {
    if (a?.[0]?.category === (rolloverLanguage ?? 'Total')) {
      return 1;
    }
    return -1;
  });

  const graphData = mergedData.flat();

  return (
    <>
      {renderGraph && (
        <div className={styles.graph} data-tid="analytics-graph">
          {/* We need to force re-render here, otherwise the graph might have 
        incorrect size when app is switched */}
          <div key={currentAppId}>
            <div className={styles.graphHeadline}>{graphTitle}</div>
            <Area
              isStack={false}
              areaStyle={({ category }) => {
                if (graphAndTableData.languages.length === 1) {
                  return { fill: GRADIENT };
                }

                if (rolloverLanguage === null) {
                  return { fill: category === 'Total' ? GRADIENT : 'transparent' };
                }

                return { fill: category === rolloverLanguage ? GRADIENT : 'transparent' };
              }}
              onEvent={(_, event) => {
                if (event.type === 'line:mouseover') {
                  setRolloverLanguage(event?.data?.data?.[0]?.category ?? null);
                }
                if (event.type === 'mouseout') {
                  setRolloverLanguage(null);
                }
              }}
              data={graphData}
              xField="day"
              yField="views"
              seriesField="category"
              meta={{
                day: { range: [0, 1] },
              }}
              xAxis={{
                label: {
                  formatter: (v) => v.split('-')[2],
                  style: (_, index, array) => {
                    const day = array[index].id;
                    const dayIndex = new Date(day).getDay();
                    return {
                      fill: dayIndex === 0 || dayIndex === 6 ? '#000' : '#AAA',
                      fontWeight: dayIndex === 0 || dayIndex === 6 ? 'bold' : undefined,
                    };
                  },
                },
              }}
              yAxis={{
                label: {
                  formatter: (v) => {
                    const value = Number(v);
                    return Number.isInteger(value) ? value.toLocaleString() : '';
                  },
                },
              }}
              tooltip={{
                offset: 30,
                customItems: (items) => items.sort((a, b) => b.data.views - a.data.views),
                title: (title) =>
                  new Intl.DateTimeFormat(navigator.language, {
                    dateStyle: 'full',
                  }).format(new Date(title)),
                formatter: ({ category, views }) => ({
                  name: category,
                  value: views.toLocaleString(),
                }),
              }}
              color={(data) => {
                if (graphAndTableData.languages.length === 1) {
                  return COLOR_LINE_ACTIVE;
                }

                if (rolloverLanguage === null) {
                  return data.category === 'Total' ? COLOR_LINE_ACTIVE : COLOR_LINE_INACTIVE;
                }

                return data.category === rolloverLanguage ? COLOR_LINE_ACTIVE : COLOR_LINE_INACTIVE;
              }}
              annotations={annotations}
              legend={false}
              animation={false}
            />
          </div>
        </div>
      )}
      {graphAndTableData.languages.length > 1 && (
        <>
          <table className={styles.table} data-tid="analytics-box-table">
            <thead>
              <tr>
                <th>Language</th>
                {graphAndTableData.languages?.[0]?.viewsPrior != null && <th>Previous 28 days</th>}
                <th>Last 28 days</th>
                <th>Trend</th>
              </tr>
            </thead>
            <tbody>
              {graphAndTableData.languages.map(({ name, viewsPrior, views, trend }) => (
                <tr
                  key={name}
                  onMouseOver={() => {
                    setRolloverLanguage(name);
                  }}
                  onMouseOut={() => {
                    setRolloverLanguage(null);
                  }}
                >
                  <td>
                    <div className={styles.localeName}>{name}</div>
                  </td>
                  {viewsPrior != null && <td>{viewsPrior.toLocaleString()}</td>}
                  <td>{views?.toLocaleString() ?? 0}</td>
                  <td
                    data-tid={`analytics-box-table-trend-color-${getTrendType(trend)}`}
                    className={classNames({
                      [styles.trendNA]: getTrendType(trend) === TrendTypes.NEUTRAL,
                      [styles.trendUp]: getTrendType(trend) === TrendTypes.UP,
                      [styles.trendDown]: getTrendType(trend) === TrendTypes.DOWN,
                    })}
                  >
                    {getTrendString(trend)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.break} />
        </>
      )}
    </>
  );
};

export default GraphAndTable;
