export const APPPLATFORMS_CREATE = 'APPPLATFORMS_CREATE';
export const APPPLATFORMS_CREATE_OK = 'APPPLATFORMS_CREATE_OK';
export const APPPLATFORMS_CREATE_FAIL = 'APPPLATFORMS_CREATE_FAIL';

export const APPPLATFORMS_DELETE = 'APPPLATFORMS_DELETE';
export const APPPLATFORMS_DELETE_OK = 'APPPLATFORMS_DELETE_OK';
export const APPPLATFORMS_DELETE_FAIL = 'APPPLATFORMS_DELETE_FAIL';

export const APPPLATFORMS_GET_LIST = 'APPPLATFORMS_GET_LIST';
export const APPPLATFORMS_GET_LIST_OK = 'APPPLATFORMS_GET_LIST_OK';
export const APPPLATFORMS_GET_LIST_FAIL = 'APPPLATFORMS_GET_LIST_FAIL';
