import ButtonLinked from './ButtonLinked';
import { getPreviousPages } from '../selectors/pageHistory';
import { useGlobalSelector } from '../utils/hooks';

import type { MbxButtonProps } from './base/MbxButton';
import type { ButtonLinkedProps } from './ButtonLinked';

type Props = Readonly<ButtonLinkedProps & MbxButtonProps>;

//
//
const BackButton = (props: Props) => {
  const { linkTo, children, ...rest } = props;
  const previousPages = useGlobalSelector(getPreviousPages);
  let x = linkTo;

  if (previousPages.length > 1) {
    const prev = previousPages[previousPages.length - 2];
    const [path] = prev.path.split('?');
    if (path === linkTo) {
      x = prev.path;
    }
  }

  return (
    <ButtonLinked {...rest} linkTo={x}>
      {children}
    </ButtonLinked>
  );
};

export default BackButton;
