import { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import { getLoginData } from '../actions/auth';
import { ROUTE_NAME_LOGIN, ROUTE_NAME_PROFILE } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getBacklinkFromSearch } from '../selectors/urlSelectors';
import { useAppDispatch } from '../utils/hooks';

//
//
const CheckLoginContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const backlink = getBacklinkFromSearch(history.location.search);
  const [loggedInUserConfirmed, setLoggedInUserConfirmed] = useState<boolean | null>(null);

  useEffect(() => {
    const checkCookie = async () => {
      try {
        await dispatch(getLoginData());
        setLoggedInUserConfirmed(() => true);
      } catch (err) {
        console.info(err);
        setLoggedInUserConfirmed(() => false);
      }
    };
    checkCookie();
  }, [dispatch]);

  if (loggedInUserConfirmed === true) {
    return <Redirect to={getUrlByName(ROUTE_NAME_PROFILE)} />;
  }

  if (loggedInUserConfirmed === false) {
    if (backlink != null && backlink !== '') {
      window.location.href = backlink;
    } else {
      return <Redirect to={getUrlByName(ROUTE_NAME_LOGIN)} />;
    }
  }

  return <div>Check login ...</div>;
};

export default CheckLoginContainer;
