import { Spin } from 'antd';

import styles from './AnalyticsAppMaus.module.scss';
import AnalyticsAppMausContent from './AnalyticsAppMausContent';
import { getAppAnalyticsById } from '../selectors/appAnalyticsSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { FunctionComponent } from 'react';

type Props = {
  currentAppId: number;
};

const AnalyticsAppMaus: FunctionComponent<Props> = ({ currentAppId }) => {
  const appAnalytics = useGlobalSelector((state) => getAppAnalyticsById(state, currentAppId));

  const maus = appAnalytics?.maus;

  return (
    <div className={styles.box} style={{ width: '100%' }}>
      <h3 className={styles.headline}>Monthly Active Devices</h3>
      {maus == null && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '12px',
            marginBottom: '12px',
          }}
        >
          <Spin />
        </div>
      )}

      {maus != null && <AnalyticsAppMausContent maus={maus} />}
    </div>
  );
};

export default AnalyticsAppMaus;
