import type { BatchUploadItem } from '../reducers/BatchUploadReducer';
import type { QueueFileInfo } from '../reducers/QueueReducer';

export type CompletedPart = {
  ETag: string;
  PartNumber: number;
};

export const oneMB = 1048576;

export const CHUNK_SIZE = oneMB * 10;
export const MAX_JOBS = 3;
export const FILE_THRESHOLD = CHUNK_SIZE;
export const TIMEOUT = 60 * 5;

export type WithBlob = {
  appId: number;
  fileRef: QueueFileInfo & { blob: File };
  batchContent: BatchUploadItem;
};

export enum MultipartResponseCode {
  SUCCESS,
  SUCCESS_RETRY_CLIENT_ONE,
  SUCCESS_RETRY_CLIENT_TWO,
  SUCCESS_RETRY_SERVER,
  FAILURE,
}
