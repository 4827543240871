import { createSelector } from '@reduxjs/toolkit';

import { getAnalyticsViewsForEpisodeFunc } from './analyticsViewsSelectors';
import { getEpisodeLocalizedByIdsFunc } from './episodeLocalizedSelectors';
import { getEpisodeTagsByIdsFunc } from './episodeTagSelectors';
import { getEpisodeVersionRequirementsByIdsFunc } from './episodeVersionRequirementsSelectors';
import { getResourceByIdFunc } from './resourceSelectors';
import { getEmptyEpisode } from '../reducers/EpisodeReducer';
import { sortByName, sortByCreatedAt, sortByViews } from '../utils/sort';

import type { EpisodeSortInfo } from './urlSelectors';
import type { RootState } from '../reducers';
import type { Episode, EpisodeAllIdsState, EpisodeByIdState } from '../reducers/EpisodeReducer';
import type { EpisodeTag } from '../reducers/EpisodeTagReducer';
import type { Selector } from 'react-redux';

export type FilteredEpisodesSettings = {
  appId: number;
  currentSearch: string;
  page: number;
  itemsPerPage: number;
  currentTagIds: Array<number>;
  currentTypeIds: Array<number>;
  currentSortInfo: EpisodeSortInfo;
};

export type EpisodeFilterResult = {
  max: number;
  maxPage: number;
  itemsPerPage: number;
  episodes: Array<Episode>;
  currentIdsStr: string;
  sortedBy: string;
};

//
//
const getById: Selector<RootState, EpisodeByIdState> = (state) => state.episodes.byId;
const getAllIds: Selector<RootState, EpisodeAllIdsState> = (state) => state.episodes.allIds;

//
//
// export const getEpisodeByIdFunc: Selector<RootState, (id: number) => Episode> = createSelector(
export const getEpisodeByIdFunc: Selector<RootState, (id: number) => Episode> = createSelector(
  getById,
  getEpisodeLocalizedByIdsFunc,
  getResourceByIdFunc,
  getEpisodeTagsByIdsFunc,
  getEpisodeVersionRequirementsByIdsFunc,
  getAnalyticsViewsForEpisodeFunc,
  //
  (
      byId,
      $getEpisodeLocalizedByIds,
      $getResourceById,
      $getEpisodeTagsByIds,
      $getEpisodeVersionRequirementsByIds,
      $getAnalyticsViewsForEpisodeFunc
    ) =>
    (id) => {
      const episodeNormalized = byId[id];
      if (episodeNormalized == null) {
        return getEmptyEpisode(id);
      }

      return {
        ...episodeNormalized,
        localizedEpisodes: $getEpisodeLocalizedByIds(episodeNormalized.localizedEpisodes),
        thumbnail:
          episodeNormalized.thumbnail != null
            ? $getResourceById(episodeNormalized.thumbnail)
            : null,
        tags: $getEpisodeTagsByIds(episodeNormalized.tags),
        requirements: $getEpisodeVersionRequirementsByIds(episodeNormalized.requirements),
        analytics: $getAnalyticsViewsForEpisodeFunc(id),
      };
    }
);

//
//
export const getEpisodeById = createSelector(
  getEpisodeByIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getEpisodeById, id) => $getEpisodeById(id)
);

//
//
export const getAllEpisodesForAppFunc = createSelector(
  getAllIds,
  getEpisodeByIdFunc,
  //
  (allIds, $getEpisodeById) => (appId: number) => {
    const ids = allIds[appId];
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id: number) => $getEpisodeById(id)).filter((e): e is Episode => !!e);
  }
);

//
//
export const getAllEpisodesForApp = createSelector(
  getAllEpisodesForAppFunc,
  (_: unknown, id: number) => id,
  //
  ($getAllEpisodesForApp, id) => $getAllEpisodesForApp(id)
);

//
//
export const getEpisodeCountFunc = createSelector(
  getAllEpisodesForAppFunc,
  //
  ($getAllEpisodesForApp) => (id: number) => {
    const episodesPerApp = $getAllEpisodesForApp(id);
    return episodesPerApp.length;
  }
);

//
//
export const getFilteredEpisodesInfo = createSelector(
  getAllEpisodesForAppFunc,
  (_: unknown, settings: FilteredEpisodesSettings) => settings,
  //
  ($getAllEpisodesForApp, settings) => {
    const {
      appId,
      currentSearch,
      page,
      itemsPerPage,
      currentTagIds,
      currentTypeIds,
      currentSortInfo,
    } = settings;
    const episodesPerApp = $getAllEpisodesForApp(appId);

    // sort episodes by createdAt DESC -> newest first
    // episodesPerApp.sort((a: Episode, b: Episode) => b.createdAt - a.createdAt);

    let filteredItems = episodesPerApp;
    if (currentSearch !== '') {
      filteredItems = episodesPerApp.filter((item: Episode) => {
        const splits = currentSearch.split(' ').map((str) => str.toLowerCase());
        let found = true;
        // search for episode name
        splits.forEach((str) => {
          found = found && item.name.toLowerCase().indexOf(str) !== -1;
        });
        return found;
      });
    }

    if (currentTagIds.length > 0) {
      filteredItems = filteredItems.filter(
        (item: Episode) =>
          // look for episodes that include _all_ tags
          item.tags.filter((et: EpisodeTag) => currentTagIds.includes(et.tag.id)).length ===
          currentTagIds.length
      );
    }

    if (currentTypeIds.length > 0) {
      filteredItems = filteredItems.filter((item: Episode) =>
        currentTypeIds.includes(item.playerTypeId)
      );
    }

    // sort items
    switch (currentSortInfo.sortBy) {
      case 'date': {
        filteredItems.sort(sortByCreatedAt);
        break;
      }
      case 'name': {
        filteredItems.sort(sortByName);
        break;
      }
      case 'views': {
        filteredItems.sort(sortByViews);
        break;
      }
      default: {
        throw new Error(`unknown sortBy for episodes ${currentSortInfo.sortBy}`);
      }
    }

    if (currentSortInfo.order === 'desc') {
      filteredItems.reverse();
    }

    const max = filteredItems.length;
    const firstIndex = (page - 1) * itemsPerPage;
    const currentPageItems = filteredItems.slice(firstIndex, firstIndex + itemsPerPage);
    return {
      max,
      maxPage: Math.ceil(max / itemsPerPage),
      itemsPerPage,
      episodes: currentPageItems,
      currentIdsStr: currentPageItems.map((e) => e.id).join(','),
      sortedBy: `${currentSortInfo.sortBy}:${currentSortInfo.order}`,
    };
  }
);
