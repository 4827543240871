import { Form, message, Switch } from 'antd';
import React, { useState } from 'react';

import styles from './UserCreateForm.module.scss';
import { updateAppUser } from '../actions/appUsers';
import { useAppDispatch } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { AppUser } from '../reducers/AppUsersReducer';

type Props = Readonly<{
  appUser: AppUser;
}>;

export const AppUserHideSwitch: React.FunctionComponent<Props> = (props: Props) => {
  const { appUser } = props;
  const [lockSwitch, setLockSwitch] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleChange = async (val: boolean) => {
    setLockSwitch(true);
    try {
      await dispatch(updateAppUser(appUser.id, val));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    } finally {
      setLockSwitch(false);
    }
  };

  return (
    <Form className={styles.form} name="hideAppUserForm">
      <Form.Item label="Hidden" name="hidden" initialValue={appUser?.hidden}>
        <Switch
          data-tid="hide-user-switch"
          disabled={lockSwitch}
          checked={appUser.hidden}
          onChange={(val) => handleChange(val)}
        />
      </Form.Item>
    </Form>
  );
};
