import { createSelector } from 'reselect';

import { getAppNormalizedByIdFunc } from './apps';
import { getFileByIdFunc } from './fileSelectors';
import { getEmptyResourceNormalized } from '../reducers/ResourceReducer';

import type { RootState } from '../reducers';
import type { ParentFile } from '../reducers/FileReducer';
import type { Resource } from '../reducers/ResourceReducer';

//
//
const getById = (state: RootState) => state.resources.byId;

//
//
export const getResourceNormalizedByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => {
    const resource = byId[id];
    if (resource == null) {
      return getEmptyResourceNormalized(id);
    }
    return resource;
  }
);

//
//
export const getResourceByIdFunc = createSelector(
  getResourceNormalizedByIdFunc,
  getFileByIdFunc,
  getAppNormalizedByIdFunc,
  //
  ($getResourceNormalizedById, $getFileById, $getAppNormalizedById) => (id: number) => {
    const resourceNormalized = $getResourceNormalizedById(id);
    const resource: Resource = {
      ...resourceNormalized,
      file:
        resourceNormalized.file != null
          ? ($getFileById(resourceNormalized.file) as ParentFile)
          : null,
      app: $getAppNormalizedById(resourceNormalized.appId),
    };
    return resource;
  }
);

//
//
export const getResourcesByIdsFunc = createSelector(
  getResourceByIdFunc,
  ($getResourceById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id: number) => $getResourceById(id));
  }
);

//
//
export const getAllResources = createSelector(
  getById,
  getResourcesByIdsFunc,
  //
  (byId, $getResourcesByIds) => $getResourcesByIds(Object.keys(byId).map((idStr) => Number(idStr)))
);
