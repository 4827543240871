import { Layout, Menu } from 'antd';

import AppMenu from './AppMenu';
import styles from './PageSider.module.scss';
import { TwofaForceReasons } from '../common/constants/auth';
import { useOptionalCurrentAppContext } from '../contexts';
import { getAuth } from '../selectors/authSelectors';
import { getPathsForMainNav } from '../selectors/pageHistory/advanced';
import { getIsSecure } from '../selectors/pageHistory/index';
import { useGlobalSelector } from '../utils/hooks';

const { Sider } = Layout;

//
//
const PageSider = () => {
  const isSecure = useGlobalSelector(getIsSecure);
  const pathsForMainNav = useGlobalSelector(getPathsForMainNav);
  const auth = useGlobalSelector(getAuth);

  const { currentApp } = useOptionalCurrentAppContext();

  if (isSecure === false || currentApp == null) {
    return null;
  }

  return (
    <Sider
      data-tid="sidepanel"
      width={225}
      style={{
        marginTop: 50,
      }}
      breakpoint="lg"
      collapsedWidth="0"
      className={styles.sider}
    >
      {auth.redirectForceTwofa !== TwofaForceReasons.IS_SUPERADMIN && (
        <Menu
          data-tid="menu-side"
          mode="inline"
          selectedKeys={pathsForMainNav}
          className={styles.sideMenu}
        >
          <AppMenu app={currentApp} />
        </Menu>
      )}
    </Sider>
  );
};

export default PageSider;
