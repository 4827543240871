import {
  BATCH_UPLOAD_ITEM_END_MULTIPART,
  BATCH_UPLOAD_ITEM_END_MULTIPART_FAIL,
  BATCH_UPLOAD_ITEM_GET_URL,
  BATCH_UPLOAD_ITEM_GET_URL_FAIL,
  BATCH_UPLOAD_ITEM_START_MULTIPART,
  BATCH_UPLOAD_ITEM_START_MULTIPART_FAIL,
} from '../constants/batchUploadItems';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const getUploadUrl = (appId: number, itemId: number) => async (dispatch: AppDispatch) => {
  return (
    await wrapFetch<{ url: string }>(
      {
        url: `/batchuploaditem/${itemId}/uploadurl`,
        params: {
          appId,
        },
        method: 'GET',
      },
      dispatch,
      { init: BATCH_UPLOAD_ITEM_GET_URL, fail: BATCH_UPLOAD_ITEM_GET_URL_FAIL },
      [200]
    )
  ).data;
};

//
//
export const requestMultipartUploadStart =
  (appId: number, itemId: number, totalParts: number) => async (dispatch: AppDispatch) => {
    return (
      await wrapFetch<{ urls: Record<string, string> }>(
        {
          url: `/batchuploaditem/${itemId}/startmultipart`,
          method: 'PUT',
          data: {
            appId,
            totalParts,
          },
        },
        dispatch,
        { init: BATCH_UPLOAD_ITEM_START_MULTIPART, fail: BATCH_UPLOAD_ITEM_START_MULTIPART_FAIL },
        [200]
      )
    ).data;
  };

//
//
export const requestMultipartUploadCompletion =
  (appId: number, itemId: number, md5Digest: string) => async (dispatch: AppDispatch) => {
    return (
      await wrapFetch<{ missingParts: Array<number> }>(
        {
          url: `/batchuploaditem/${itemId}/finishmultipart`,
          method: 'PUT',
          data: {
            md5Digest,
            appId,
          },
        },
        dispatch,
        { init: BATCH_UPLOAD_ITEM_END_MULTIPART, fail: BATCH_UPLOAD_ITEM_END_MULTIPART_FAIL },
        [200]
      )
    ).data;
  };

//
//
export const requestMissingUrls =
  (appId: number, itemId: number) => async (dispatch: AppDispatch) => {
    return (
      await wrapFetch<{ urls: Record<string, string> }>(
        {
          url: `/batchuploaditem/${itemId}/missingurls`,
          method: 'GET',
          params: {
            appId,
          },
        },
        dispatch,
        { init: BATCH_UPLOAD_ITEM_START_MULTIPART, fail: BATCH_UPLOAD_ITEM_START_MULTIPART_FAIL },
        [200]
      )
    ).data;
  };
