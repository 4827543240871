import { combineReducers } from 'redux';

import { getEmptyTag } from './TagReducer';
import {
  EPISODE_GET_METADATA_OK,
  EPISODE_UPDATE_OK,
  EPISODE_CREATE_OK,
  EPISODE_GET_DETAILS_OK,
  EPISODE_GET_LIST_OK,
} from '../constants/episodes';

import type { MbxTag } from './TagReducer';
import type { AnyAction } from 'redux';

type EpisodeTagBase = {
  id: number;
};

export type EpisodeTagNormalized = EpisodeTagBase & {
  tag: number;
};

export type EpisodeTag = EpisodeTagBase & {
  tag: MbxTag;
};

export type EpisodeTagByIdState = {
  [k: string | number]: EpisodeTagNormalized;
};
export type EpisodeTagState = {
  byId: EpisodeTagByIdState;
};

//
//
export const getEmptyEpisodeTag = (id?: number): EpisodeTag => ({
  id: id ?? -1,
  tag: getEmptyTag(),
});

const initialStateById: EpisodeTagByIdState = {};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): EpisodeTagByIdState => {
  switch (action.type) {
    case EPISODE_UPDATE_OK:
    case EPISODE_CREATE_OK:
    case EPISODE_GET_METADATA_OK:
    case EPISODE_GET_DETAILS_OK:
    case EPISODE_GET_LIST_OK:
      return {
        ...state,
        ...action.payload.entities.episodeTags,
      };

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
});

export default combined;
