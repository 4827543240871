import { getEpisodePlayerTypeInfo } from '../utils/episode';

import type { Episode } from '../reducers/EpisodeReducer';

type Props = {
  episode: Episode | null;
};

//
//
const PlayerTypeBubble = (props: Props) => {
  const { episode } = props;

  const resourceInfo = getEpisodePlayerTypeInfo(episode);

  return (
    <span
      style={{
        display: 'inline-block',
        backgroundColor: `${resourceInfo.color}4C`, // 4C => ~30% (see https://stackoverflow.com/a/8254129/388026)
        padding: '6px 10px',
        marginRight: 6,
        borderRadius: 16,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '22px',
        height: 32,
        textAlign: 'center',
        textTransform: 'uppercase',
      }}
    >
      {resourceInfo.label}
    </span>
  );
};

export default PlayerTypeBubble;
