import { schema, normalize } from 'normalizr';

import {
  ANALYTICSEXPORTS_GET_LIST,
  ANALYTICSEXPORTS_GET_LIST_FAIL,
  ANALYTICSEXPORTS_GET_LIST_OK,
  ANALYTICSEXPORTS_DOWNLOAD,
  ANALYTICSEXPORTS_DOWNLOAD_FAIL,
  EPISODE_GET_TOPTEN_WEEK_OK,
  EPISODE_GET_TOPTEN_WEEK_FAIL,
  EPISODE_GET_TOPTEN_WEEK,
  EPISODE_GET_TOPTEN_ALL_TIME,
  EPISODE_GET_TOPTEN_ALL_TIME_FAIL,
  EPISODE_GET_TOPTEN_ALL_TIME_OK,
  EPISODE_GET_VIEWS,
  EPISODE_GET_VIEWS_FAIL,
  EPISODE_GET_VIEWS_OK,
  APP_GET_ANALYTICS_TOTAL_VIEWS,
  APP_GET_ANALYTICS_TOTAL_VIEWS_FAIL,
  APP_GET_ANALYTICS_TOTAL_VIEWS_OK,
  APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS,
  APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS_FAIL,
  APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS_OK,
  APP_GET_ANALYTICS_MAUS,
  APP_GET_ANALYTICS_MAUS_FAIL,
  APP_GET_ANALYTICS_MAUS_OK,
} from '../constants/analytics';
import { AnalyticsExport, EpisodeSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const downloadAnalyticsExport = (aeId: number) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/analytics/exports/${aeId}/download`,
    },
    dispatch,
    {
      init: ANALYTICSEXPORTS_DOWNLOAD,
      fail: ANALYTICSEXPORTS_DOWNLOAD_FAIL,
    }
  );
};

//
//
export const fetchAnalyticsExportsList =
  (appId: number, typeId: number) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/analytics/exports`,
        params: {
          appId,
          typeId,
        },
      },
      dispatch,
      {
        init: ANALYTICSEXPORTS_GET_LIST,
        fail: ANALYTICSEXPORTS_GET_LIST_FAIL,
      }
    );
    const normalizedData = normalize(response.data, new schema.Array(AnalyticsExport));
    dispatch({ type: ANALYTICSEXPORTS_GET_LIST_OK, payload: normalizedData, appId, typeId });
  };

//
//
export const getTopTenEpisodesSelectedWeek =
  (appId: number, week: string) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/analytics/episodes/week/${appId}`,
        params: { week },
      },
      dispatch,
      {
        init: EPISODE_GET_TOPTEN_WEEK,
        fail: EPISODE_GET_TOPTEN_WEEK_FAIL,
      }
    );
    dispatch({ type: EPISODE_GET_TOPTEN_WEEK_OK, appId, payload: response.data });
    return response.data;
  };

//
//
export const getTopTenEpisodesAllTime = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/analytics/episodes/all/${appId}`,
    },
    dispatch,
    {
      init: EPISODE_GET_TOPTEN_ALL_TIME,
      fail: EPISODE_GET_TOPTEN_ALL_TIME_FAIL,
    }
  );
  dispatch({ type: EPISODE_GET_TOPTEN_ALL_TIME_OK, appId, payload: response.data });
  return response.data;
};

export const getEpisodeViewDetails =
  (appId: number, episodeId: number) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch({ url: `/episodes/${episodeId}/analytics` }, dispatch, {
      init: EPISODE_GET_VIEWS,
      fail: EPISODE_GET_VIEWS_FAIL,
    });
    const normalizedData = normalize(response.data, EpisodeSchema);
    dispatch({ type: EPISODE_GET_VIEWS_OK, appId, episodeId, payload: normalizedData });
    return response.data;
  };

export const getAppAnalyticsTotalViews = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/apps/${appId}/analytics/totalviews` }, dispatch, {
    init: APP_GET_ANALYTICS_TOTAL_VIEWS,
    fail: APP_GET_ANALYTICS_TOTAL_VIEWS_FAIL,
  });

  dispatch({ type: APP_GET_ANALYTICS_TOTAL_VIEWS_OK, appId, payload: response.data });
  return response.data;
};

export const getAppAnalyticsLast28DaysViews = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/apps/${appId}/analytics/last28daysviews` }, dispatch, {
    init: APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS,
    fail: APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS_FAIL,
  });

  dispatch({ type: APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS_OK, appId, payload: response.data });
  return response.data;
};

export const getAppAnalyticsMaus = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/apps/${appId}/analytics/maus` }, dispatch, {
    init: APP_GET_ANALYTICS_MAUS,
    fail: APP_GET_ANALYTICS_MAUS_FAIL,
  });

  dispatch({ type: APP_GET_ANALYTICS_MAUS_OK, appId, payload: response.data });
  return response.data;
};
