import { combineReducers } from 'redux';

import {
  REGIONCOUNTRY_CREATE_OK,
  REGIONCOUNTRY_REMOVE_OK,
  REGIONCOUNTRY_UPDATE_OK,
  REGIONCOUNTRY_ADD_ALL_OK,
  REGIONCOUNTRY_REMOVE_ALL_OK,
} from '../constants/regionCountries';
import { REGION_GET_LIST_OK, REGION_GET_DETAILS_OK, REGION_REMOVE_OK } from '../constants/regions';

import type { Country } from './CountryReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type RegionCountryBase = {
  id: number;
  isDraft: boolean;
  regionId: number;
  country: Country;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date | null;
};

export type RegionCountryNormalized = Merge<
  RegionCountryBase,
  {
    country: number;
  }
>;

export type RegionCountry = Merge<
  RegionCountryBase,
  {
    country: Country;
  }
>;

export type RegionCountryByIdState = {
  [k: string | number]: RegionCountryNormalized;
};
export type RegionCountryAllIdsState = {
  [k: string | number]: Array<number>;
};
export type RegionCountryState = {
  byId: RegionCountryByIdState;
  allIds: RegionCountryAllIdsState;
};

const initialStateById: RegionCountryByIdState = {};
const initialStateAllIds: RegionCountryAllIdsState = {};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): RegionCountryByIdState => {
  switch (action.type) {
    case REGION_GET_LIST_OK:
    case REGION_GET_DETAILS_OK:
    case REGIONCOUNTRY_ADD_ALL_OK:
      return {
        ...state,
        ...action.payload.entities.regionCountries,
      };

    case REGION_REMOVE_OK: {
      const newState = { ...state };
      action.region.regionCountries.forEach((rc: RegionCountry) => delete newState[rc.id]);
      return newState;
    }

    case REGIONCOUNTRY_REMOVE_ALL_OK: {
      const { regionId } = action;
      const newState = { ...state };

      for (const regioncountryId in newState) {
        const rc = newState[regioncountryId];
        if (rc.regionId === regionId) {
          delete newState[regioncountryId];
        }
      }

      return newState;
    }

    case REGIONCOUNTRY_CREATE_OK:
    case REGIONCOUNTRY_UPDATE_OK: {
      const id = action.payload.result;
      return {
        ...state,
        [id]: action.payload.entities.regionCountries[id],
      };
    }

    case REGIONCOUNTRY_REMOVE_OK: {
      const newState = { ...state };
      delete newState[action.regioncountryId];
      return newState;
    }

    default:
      return state;
  }
};

//
//
// eslint-disable-next-line default-param-last
const allIds = (state = initialStateAllIds, action: AnyAction): RegionCountryAllIdsState => {
  switch (action.type) {
    case REGION_GET_LIST_OK: {
      const newState = { ...state };
      action.payload.result.forEach((regionId: number) => {
        const region = { ...action.payload.entities.regions[regionId] };
        newState[region.id] = region.regionCountries;
      });
      return newState;
    }

    case REGION_GET_DETAILS_OK:
      if (action.payload.entities.regionCountries == null) {
        return {
          ...state,
          [action.regionId]: [],
        };
      }
      return {
        ...state,
        [action.regionId]: Object.keys(action.payload.entities.regionCountries).map((id) =>
          parseInt(id, 10)
        ),
      };

    case REGION_REMOVE_OK: {
      const { [action.region.id]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case REGIONCOUNTRY_CREATE_OK:
    case REGIONCOUNTRY_ADD_ALL_OK:
      return {
        ...state,
        [action.regionId]: state[action.regionId].concat(action.payload.result),
      };

    case REGIONCOUNTRY_REMOVE_OK:
      return {
        ...state,
        [action.regionId]: state[action.regionId].filter((id) => id !== action.regioncountryId),
      };

    case REGIONCOUNTRY_REMOVE_ALL_OK:
      return {
        ...state,
        [action.regionId]: [],
      };

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
});

export default combined;
