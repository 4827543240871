import { Layout } from 'antd';

import AlertPendingInvites from './AlertPendingInvites';
import AlertRunningBatchupload from './AlertRunningBatchupload';
import AlertSuperadmin from './AlertSuperadmin';
import Breadcrumb from './Breadcrumb';
import LayoutFooter from './PageFooter';
import PageHeader from './PageHeader';
import PageSider from './PageSider';
import SyncingStatus from '../containers/SyncingStatusContainer';

import type { PropsWithChildren } from 'react';

const { Content } = Layout;

//
//
const PageLayout = (props: PropsWithChildren<unknown>) => {
  const { children } = props;
  return (
    <div>
      <SyncingStatus />
      <PageHeader />

      <div>
        <Layout style={{ maxWidth: '1400px', margin: '0 auto' }}>
          <PageSider />
          <Layout>
            <Content data-tid="content-main" style={{ padding: '0 0 0 16px', marginTop: 0 }}>
              <AlertSuperadmin />
              <AlertPendingInvites />
              <AlertRunningBatchupload />
              <Breadcrumb />
              {children}
            </Content>
            <LayoutFooter />
          </Layout>
        </Layout>
      </div>
    </div>
  );
};
export default PageLayout;
