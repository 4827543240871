import { TrendTypes } from '../utils/analytics';

export const ANALYTICSEXPORTS_GET_LIST = 'ANALYTICSEXPORTS_GET_LIST';
export const ANALYTICSEXPORTS_GET_LIST_OK = 'ANALYTICSEXPORTS_GET_LIST_OK';
export const ANALYTICSEXPORTS_GET_LIST_FAIL = 'ANALYTICSEXPORTS_GET_LIST_FAIL';

export const ANALYTICSEXPORTS_DOWNLOAD = 'ANALYTICSEXPORTS_DOWNLOAD';
export const ANALYTICSEXPORTS_DOWNLOAD_OK = 'ANALYTICSEXPORTS_DOWNLOAD_OK';
export const ANALYTICSEXPORTS_DOWNLOAD_FAIL = 'ANALYTICSEXPORTS_DOWNLOAD_FAIL';

export const EPISODE_GET_TOPTEN_WEEK = 'EPISODE_GET_TOPTEN_WEEK';
export const EPISODE_GET_TOPTEN_WEEK_OK = 'EPISODE_GET_TOPTEN_WEEK_OK';
export const EPISODE_GET_TOPTEN_WEEK_FAIL = 'EPISODE_GET_TOPTEN_WEEK_FAIL';

export const EPISODE_GET_TOPTEN_ALL_TIME = 'EPISODE_GET_TOPTEN_ALL_TIME';
export const EPISODE_GET_TOPTEN_ALL_TIME_OK = 'EPISODE_GET_TOPTEN_ALL_TIME_OK';
export const EPISODE_GET_TOPTEN_ALL_TIME_FAIL = 'EPISODE_GET_TOPTEN_ALL_TIME_FAIL';

export const EPISODE_GET_VIEWS = 'EPISODE_GET_VIEWS';
export const EPISODE_GET_VIEWS_OK = 'EPISODE_GET_VIEWS_OK';
export const EPISODE_GET_VIEWS_FAIL = 'EPISODE_GET_VIEWS_FAIL';

export const APP_GET_ANALYTICS_TOTAL_VIEWS = 'APP_GET_ANALYTICS_TOTAL_VIEWS';
export const APP_GET_ANALYTICS_TOTAL_VIEWS_OK = 'APP_GET_ANALYTICS_TOTAL_VIEWS_OK';
export const APP_GET_ANALYTICS_TOTAL_VIEWS_FAIL = 'APP_GET_ANALYTICS_TOTAL_VIEWS_FAIL';

export const APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS = 'APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS';
export const APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS_OK = 'APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS_OK';
export const APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS_FAIL =
  'APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS_FAIL';

export const APP_GET_ANALYTICS_MAUS = 'APP_GET_ANALYTICS_MAUS';
export const APP_GET_ANALYTICS_MAUS_OK = 'APP_GET_ANALYTICS_MAUS_OK';
export const APP_GET_ANALYTICS_MAUS_FAIL = 'APP_GET_ANALYTICS_MAUS_FAIL';

export type TrendColorsType = {
  [TrendTypes.UP]: string;
  [TrendTypes.DOWN]: string;
  [TrendTypes.NEUTRAL]: string;
};

export const trendColors: TrendColorsType = {
  [TrendTypes.UP]: '#01912d',
  [TrendTypes.DOWN]: '#cb390b',
  [TrendTypes.NEUTRAL]: 'darkgray',
};
