import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Tooltip, Select } from 'antd';
import { useEffect } from 'react';

import { selectFilterFunc } from '../utils/filter';

import type { Category } from '../reducers/CategoryReducer';
import type { ReleaseCategory } from '../reducers/ReleaseCategoryReducer';
import type { Release } from '../reducers/ReleaseReducer';

type Props = {
  form: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  data: Release | null | undefined;
  appCategories: Array<Category>;
};

//
//
const ReleaseFormCategories = (props: Props) => {
  const { data, appCategories, form } = props;

  const categoryIds = appCategories
    .map((c) => c.id)
    .sort((a, b) => b - a)
    .join(',');

  useEffect(() => {
    form.resetFields(['categories']);
  }, [form, categoryIds]);

  return (
    <Form.Item
      label={
        <span>
          Categories&nbsp;
          <Tooltip
            title={
              <ul style={{ margin: 0 }}>
                <li>categories define in which section of the app the release appears</li>
                <li>categories are searchable in the admin interface list view</li>
                <li>you have to create categories before using them</li>
              </ul>
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      }
      name="categories"
      initialValue={(data?.categories ?? []).map(
        (rc: ReleaseCategory) => rc?.category?.name ?? `rc-${rc.id}`
      )}
    >
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        tokenSeparators={[',']}
        filterOption={selectFilterFunc}
      >
        {appCategories.map((c: Category) => (
          <Select.Option key={c.id} value={c.name}>
            <span
              style={{
                color: '#eb2f96',
                opacity: c.isDraft ? 0.4 : 1,
                fontWeight: 'bold',
              }}
            >
              {c.name}
            </span>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ReleaseFormCategories;
