import { schema, normalize } from 'normalizr';

import {
  PLATFORMS_GET_LIST,
  PLATFORMS_GET_LIST_OK,
  PLATFORMS_GET_LIST_FAIL,
  PLATFORMS_UPDATE,
  PLATFORMS_UPDATE_OK,
  PLATFORMS_UPDATE_FAIL,
  PLATFORMS_CREATE,
  PLATFORMS_CREATE_OK,
  PLATFORMS_CREATE_FAIL,
  PLATFORMS_DELETE,
  PLATFORMS_DELETE_OK,
  PLATFORMS_DELETE_FAIL,
} from '../constants/platforms';
import { PlatformSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const fetchPlatformList = () => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/platforms` }, dispatch, {
    init: PLATFORMS_GET_LIST,
    fail: PLATFORMS_GET_LIST_FAIL,
  });
  const normalizedData = normalize(response.data, new schema.Array(PlatformSchema));
  dispatch({ type: PLATFORMS_GET_LIST_OK, payload: normalizedData });
};

//
//
export const updatePlatform =
  (platformId: number, { name, subcategories }: { name: string; subcategories?: string }) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/platforms/${platformId}`,
        method: 'PUT',
        data: {
          name,
          subcategories,
        },
      },
      dispatch,
      {
        init: PLATFORMS_UPDATE,
        fail: PLATFORMS_UPDATE_FAIL,
      }
    );
    const normalizedData = normalize(response.data, PlatformSchema);
    dispatch({ type: PLATFORMS_UPDATE_OK, payload: normalizedData });
  };

//
//
export const createPlatform =
  ({ name, subcategories }: { name: string; subcategories?: string }) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/platforms`,
        method: 'POST',
        data: {
          name,
          subcategories,
        },
      },
      dispatch,
      {
        init: PLATFORMS_CREATE,
        fail: PLATFORMS_CREATE_FAIL,
      },
      201
    );
    const normalizedData = normalize(response.data, PlatformSchema);
    dispatch({ type: PLATFORMS_CREATE_OK, payload: normalizedData });
  };

//
//
export const deletePlatform = (platformId: number) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/platforms/${platformId}`,
      method: 'DELETE',
    },
    dispatch,
    {
      init: PLATFORMS_DELETE,
      fail: PLATFORMS_DELETE_FAIL,
    },
    204
  );
  dispatch({ type: PLATFORMS_DELETE_OK, platformId });
};
