import {
  AppstoreOutlined,
  ControlOutlined,
  LaptopOutlined,
  ScheduleOutlined,
  SecurityScanOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import UploadQueue from './UploadQueue';
import styles from './UserMenu.module.scss';
import { getUserData } from '../actions/user';
import {
  ROUTE_NAME_INTERNAL_APPS,
  ROUTE_NAME_INTERNAL_AUTHSERVICES,
  ROUTE_NAME_INTERNAL_JOBS,
  ROUTE_NAME_INTERNAL_PLATFORMS,
  ROUTE_NAME_INTERNAL_USERS,
  ROUTE_NAME_PROFILE,
} from '../navigation/routes';
import { SITEMAP_FLAT } from '../navigation/sitemap';
import { matchRoute, getUrlByName } from '../navigation/utils';
import { getUserProfileOptional, isUserSuperadmin } from '../selectors/authSelectors';
import { getPathname } from '../selectors/pageHistory';
import { getAllQueueItems } from '../selectors/queueSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

type AdminMenuProps = {
  checkedKey?: string;
};

export const AdminMenu = (props: AdminMenuProps) => {
  const { checkedKey } = props;

  const links = [
    {
      icon: <LaptopOutlined />,
      url: getUrlByName(ROUTE_NAME_INTERNAL_PLATFORMS),
      label: 'Platforms',
    },
    {
      icon: <SecurityScanOutlined />,
      url: getUrlByName(ROUTE_NAME_INTERNAL_AUTHSERVICES),
      label: 'Auth Services',
    },
    { icon: <AppstoreOutlined />, url: getUrlByName(ROUTE_NAME_INTERNAL_APPS), label: 'Apps' },
    { icon: <TeamOutlined />, url: getUrlByName(ROUTE_NAME_INTERNAL_USERS), label: 'Users' },
    { icon: <ScheduleOutlined />, url: getUrlByName(ROUTE_NAME_INTERNAL_JOBS), label: 'Jobs' },
  ];

  let urlPartialMatch = checkedKey ?? '';
  if (checkedKey != null) {
    links.some((l) => {
      const found = checkedKey.indexOf(l.url) === 0;
      if (found === true) {
        urlPartialMatch = l.url;
      }
      return found;
    });
  }

  return (
    <Menu
      data-tid="admin-menu-dropdown"
      selectedKeys={[urlPartialMatch]}
      items={links.map((link) => {
        return {
          key: link.url,
          icon: link.icon,
          label: (
            <Link to={link.url}>
              <span>{link.label}</span>
            </Link>
          ),
        };
      })}
    />
  );
};

//
//
const UserMenu = () => {
  const profile = useGlobalSelector(getUserProfileOptional);
  const isSuperadmin = useGlobalSelector(isUserSuperadmin);
  const pathname = useGlobalSelector(getPathname);
  const { matchedRoute } = matchRoute(SITEMAP_FLAT, pathname);
  const queuedItems = useGlobalSelector(getAllQueueItems);
  const dispatch = useAppDispatch();

  if (profile == null) {
    return null;
  }

  //Token check on first render
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getUserData());
      } catch (err) {
        const { msg } = logUnknownError(err);
        console.info(msg);
      }
    };
    fetchData();
  }, [dispatch]);

  return (
    <div data-tid="user-menu" className={styles.menu}>
      {isSuperadmin && (
        <Dropdown
          overlay={<AdminMenu checkedKey={matchedRoute?.url} />}
          arrow
          align={{ offset: [15, -16] }}
          trigger={['click']}
          placement="bottomRight"
          autoFocus
          overlayClassName="admin-menu-dropdown"
        >
          <div data-tid="admin-menu">
            <Link style={{ color: 'white' }} onClick={(e) => e.preventDefault()} to="#">
              <span className={styles.adminSettingsText}>Admin Settings</span>
              <ControlOutlined className={styles.adminSettingsIcon} />
            </Link>
          </div>
        </Dropdown>
      )}
      <UploadQueue queuedItems={queuedItems} />
      <Link to={getUrlByName(ROUTE_NAME_PROFILE)} data-tid="btn-user-open-profile">
        <Avatar
          style={{ marginTop: '-2px', height: '33px', width: '33px' }}
          src={profile.imageUrl}
        />
      </Link>
    </div>
  );
};

export default UserMenu;
