import { message } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { createAsset } from '../actions/assets';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_ASSETDETAIL } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import AssetCreate from '../pages/AssetCreate';
import { useAppDispatch } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

//
//
const AssetCreateContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentApp } = useCurrentAppContext();
  const [isSaving, setIsSaving] = useState(false);

  const handleCreateAsset = async ({ name, isDraft }: { name: string; isDraft: boolean }) => {
    try {
      setIsSaving(true);
      const newAsset = await dispatch(createAsset(currentApp.id, { name, isDraft }));
      setIsSaving(false);
      history.push(
        getUrlByName(ROUTE_NAME_APP_ASSETDETAIL, {
          ':appUid': currentApp.uid,
          ':assetId': newAsset.id,
        })
      );
      message.success(`Asset patch created`);
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
      setIsSaving(false);
    }
  };

  return <AssetCreate isSaving={isSaving} onSubmit={handleCreateAsset} />;
};

export default AssetCreateContainer;
