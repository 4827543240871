import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import MbxButton from './base/MbxButton';
import styles from './LoginForm.module.scss';
import { ROUTE_NAME_PASSWORDFORGOT } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

import type { ChangeEventHandler } from 'react';

type Props = Readonly<{
  prefilledEmail?: string | null;
  forceEmail?: string | null;
  onSubmit: (values: LoginFormValues) => Promise<void>;
  isLoggingIn: boolean;
}>;

export type LoginFormValues = {
  email: string;
  password: string;
};

//
//
const LoginForm = (props: Props) => {
  const { isLoggingIn, prefilledEmail, forceEmail, onSubmit } = props;
  const [emailContent, setEmailContent] = useState('');

  const handleSubmit = (values: LoginFormValues) => {
    onSubmit(values);
  };

  const recordEmailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmailContent(e.target.value);
  };

  return (
    <div>
      <Form
        name="normal_login"
        onFinish={handleSubmit}
        initialValues={{ email: forceEmail ?? prefilledEmail ?? undefined }}
        validateTrigger="onSubmit"
        className={styles.loginForm}
        noValidate
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Has to be valid email!' },
          ]}
        >
          <Input
            data-tid="login-input-email"
            autoFocus
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
            type="email"
            disabled={forceEmail != null}
            onChange={recordEmailChange}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input
            data-tid="login-input-password"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Link
            to={`${getUrlByName(ROUTE_NAME_PASSWORDFORGOT)}${
              emailContent !== '' ? `?email=${emailContent}` : ''
            }`}
          >
            <p className={styles.loginFormForgot}>Forgot password</p>
          </Link>
          <MbxButton
            data-tid="login-input-submit"
            disabled={isLoggingIn}
            loading={isLoggingIn}
            mbxType="primary"
            htmlType="submit"
            className={styles.loginFormButton}
          >
            Log in
          </MbxButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginForm;
