import { PlusOutlined } from '@ant-design/icons';
import { Avatar, List, Dropdown, Menu } from 'antd';

import styles from './EpisodeList.module.scss';
import { getValueFromKey } from '../common/utils/app';
import { AppUserHideSwitch } from '../components/AppUserHideSwitch';
import MbxButton from '../components/base/MbxButton';
import CustomPageHeader from '../components/CustomPageHeader';
import RoleListItem from '../components/RoleListItem';
import UserCreateForm from '../components/UserCreateForm';
import { useOptionalCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_SETTINGS, ROUTE_NAME_INTERNAL_USERS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getAppUser } from '../selectors/appUserSelectors';
import { isUserSuperadmin } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { UserCreateFormValues } from '../components/UserCreateForm';
import type { Role } from '../reducers/RoleReducer';
import type { User } from '../reducers/UsersReducer';
import type { MenuProps } from 'antd';

type Props = {
  user: User;
  roles: Array<Role>;
  updateUser?: (values: UserCreateFormValues) => Promise<void>;
  onCancel: () => void;
  addRoleToUser: (roleId: number) => Promise<void>;
  removeRoleFromUser: (roleId: number) => Promise<void>;
  isUpdatingRole: boolean;
};

//
//
const UserDetail = (props: Props) => {
  const { user, updateUser, onCancel, addRoleToUser, removeRoleFromUser, roles, isUpdatingRole } =
    props;

  const { currentApp: maybeCurrentApp } = useOptionalCurrentAppContext();
  const title = user !== null ? `${user.firstname} ${user.lastname}` : '';
  const userRoles = user?.roles ?? [];

  const backLink =
    maybeCurrentApp !== null
      ? getUrlByName(ROUTE_NAME_APP_SETTINGS, { ':appUid': maybeCurrentApp.uid })
      : getUrlByName(ROUTE_NAME_INTERNAL_USERS);
  const appUser =
    maybeCurrentApp == null
      ? null
      : useGlobalSelector((state) =>
          getAppUser(state, { appId: maybeCurrentApp.id, userId: user.id })
        );

  const isSuperadmin = useGlobalSelector(isUserSuperadmin);
  const appConfig = maybeCurrentApp?.configuration ?? [];
  const displayRoles = getValueFromKey<boolean>(appConfig, `adminData.displayRoles`, false);

  const handleMenuClick: MenuProps['onClick'] = (info) => {
    const roleId = Number(info.key.split('-').pop());
    if (!Number.isNaN(roleId)) {
      addRoleToUser(roleId);
    }
  };

  const handleRemoveRole = (role: Role) => {
    if (window.confirm(`Really remove role '${role.name}'?`)) {
      removeRoleFromUser(role.id);
    }
  };

  return (
    <div>
      <CustomPageHeader title={`User Detail ${title}`} backLink={backLink} />

      <div style={{ marginTop: '2em', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: 'calc(100% - 250px)' }}>
          <UserCreateForm data={user} onSubmit={updateUser} onCancel={onCancel} />
          {appUser != null && isSuperadmin === true && <AppUserHideSwitch appUser={appUser} />}
        </div>
        <div>
          <Avatar size={128} src={user.imageUrl} shape="square" />
          <p style={{ margin: '1em 0' }}>{`2FA: ${user?.twofaActivated?.toString() ?? 'false'}`}</p>
          {maybeCurrentApp === null && (
            <>
              <h2 style={{ marginTop: '1em' }}>Apps</h2>
              <ul>
                {user.apps?.map((a) => (
                  <li key={a.id}>{a.name}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>

      {(isSuperadmin || displayRoles) && (
        <div style={{ marginTop: '3em' }}>
          <div className={styles.header}>
            <h2>User Roles</h2>
            <div className={styles.headerExtras}>
              <Dropdown
                disabled={userRoles.length === roles.length}
                overlay={
                  <Menu onClick={handleMenuClick} data-tid="dropdown-user-role">
                    {roles.map((role) => (
                      <Menu.Item
                        key={`add-role-menuitem-${role.id}`}
                        disabled={userRoles.find((r) => r.id === role.id) != null}
                      >
                        {role.name}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <MbxButton
                  icon={<PlusOutlined />}
                  disabled={isUpdatingRole}
                  loading={isUpdatingRole}
                  data-tid="btn-add-role"
                >
                  Add a role
                </MbxButton>
              </Dropdown>
            </div>
          </div>

          <div>
            <List
              data-tid="role-list"
              bordered
              dataSource={userRoles.filter((role) => {
                // on appuser-detailpage only show roles that belong to the current app
                if (maybeCurrentApp === null) {
                  return true;
                }
                return role.appId === maybeCurrentApp.id;
              })}
              renderItem={(role: Role) => (
                <RoleListItem role={role} displayAppName onRemove={handleRemoveRole} />
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

UserDetail.defaultProps = {
  updateUser: null,
};

export default UserDetail;
