const PageImprint = () => (
  <div>
    <h1>Imprint</h1>

    <p style={{ marginTop: '2em', fontSize: 16, maxWidth: 500 }}>
      <b>Wonderz GmbH</b>
      <br />
      Glogauer Str. 6<br />
      10999 Berlin
      <br />
      <br />
      Represented by:
      <br />
      Sebastian Wehner
      <br />
      <br />
      VAT:
      <br />
      DE 311185789
      <br />
      <br />
      Disclaimer:
      <br />
      Despite careful content control, we assume no liability for the content of external links. The
      operators of the linked pages are solely responsible for their content.
      <br />
      <br />
      AG Charlottenburg - HRB 185242 B
    </p>
  </div>
);

export default PageImprint;
