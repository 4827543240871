import union from 'lodash.union';
import { combineReducers } from 'redux';

import { getEmptyEpisode } from './EpisodeReducer';
import { getEmptyRegion } from './RegionReducer';
import { EPISODE_GET_RELEASES_OK, EPISODE_REMOVE_OK } from '../constants/episodes';
import {
  RELEASE_GET_LIST_OK,
  RELEASE_GET_DETAILS_OK,
  RELEASE_UPDATE_OK,
  RELEASE_REMOVE_OK,
  RELEASE_CREATE_OK,
} from '../constants/releases';

import type { Episode } from './EpisodeReducer';
import type { Region } from './RegionReducer';
import type { ReleaseCategory } from './ReleaseCategoryReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type ReleaseBase = {
  id: number;
  appId: number;
  isDemoContent: boolean;
  isDraft: boolean;
  publishDate: Date;
  unpublishDate: Date | null;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date | null;
  updatedBy: number | null;
};

export type ReleaseNormalized = Merge<
  ReleaseBase,
  {
    episode: number;
    region: number;
    categories: Array<number>;
  }
>;

export type Release = Merge<
  ReleaseBase,
  {
    episode: Episode;
    region: Region;
    categories: Array<ReleaseCategory>;
  }
>;

export type ReleaseByIdState = {
  [k: string | number]: ReleaseNormalized;
};
export type ReleaseAllIdsState = {
  [k: string | number]: Array<number>;
};

export type ReleaseState = {
  byId: ReleaseByIdState;
  allIds: ReleaseAllIdsState;
};

//
//
export const getEmptyRelease = (id?: number): Release => ({
  id: id ?? -1,
  appId: -1,
  isDemoContent: false,
  isDraft: false,
  publishDate: new Date(),
  unpublishDate: null,
  createdAt: new Date(),
  createdBy: -1,
  updatedAt: null,
  updatedBy: null,
  episode: getEmptyEpisode(),
  region: getEmptyRegion(),
  categories: [],
});

const initialStateById: ReleaseByIdState = {};
const initialStateAllIds: ReleaseAllIdsState = {};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): ReleaseByIdState => {
  switch (action.type) {
    case RELEASE_CREATE_OK:
    case RELEASE_GET_LIST_OK:
    case EPISODE_GET_RELEASES_OK:
      if (action.payload.entities.releases == null) {
        return state;
      }

      return {
        ...state,
        ...action.payload.entities.releases,
      };

    case RELEASE_UPDATE_OK:
    case RELEASE_GET_DETAILS_OK: {
      const id = action.payload.result;
      return {
        ...state,
        [id]: { ...action.payload.entities.releases[id] },
      };
    }

    case RELEASE_REMOVE_OK: {
      const { [action.release.id]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case EPISODE_REMOVE_OK: {
      const { releaseIds } = action;
      const newState = { ...state };
      releaseIds.forEach((releaseId: number) => {
        delete newState[releaseId];
      });
      return newState;
    }

    default:
      return state;
  }
};

//
//
// eslint-disable-next-line default-param-last
const allIds = (state = initialStateAllIds, action: AnyAction): ReleaseAllIdsState => {
  switch (action.type) {
    case RELEASE_GET_LIST_OK: {
      const categoryId = action?.categoryId ?? null;
      if (categoryId === null) {
        return {
          ...state,
          [action.regionId]: action.payload.result,
        };
      }
      // categoryId was set -> result is only subset of alls region releases -> do union
      return {
        ...state,
        [action.regionId]: union(state[action.regionId], action.payload.result),
      };
    }

    case EPISODE_GET_RELEASES_OK: {
      if (action.payload.entities.releases == null) {
        return state;
      }
      const newState = { ...state };
      Object.keys(action.payload.entities.releases).forEach((rId) => {
        const r = action.payload.entities.releases[rId];
        if (newState[r.region] == null) {
          newState[r.region] = [r.id];
        } else if (newState[r.region].includes(r.id) === false) {
          newState[r.region] = [...newState[r.region], r.id];
        }
      });
      return newState;
    }

    case RELEASE_CREATE_OK: {
      const newIdOrIds = Array.isArray(action.payload.result)
        ? action.payload.result
        : [action.payload.result];
      if (state[action.regionId] == null) {
        return {
          ...state,
          [action.regionId]: newIdOrIds,
        };
      }
      return {
        ...state,
        [action.regionId]: [...state[action.regionId], ...newIdOrIds],
      };
    }

    case RELEASE_REMOVE_OK:
      return {
        ...state,
        [action.release.region.id]: state[action.release.region.id].filter(
          (releaseId) => releaseId !== action.release.id
        ),
      };

    case EPISODE_REMOVE_OK: {
      const { releaseIds } = action;
      const newState = { ...state };
      Object.keys(newState).forEach((regionId) => {
        newState[regionId] = newState[regionId].filter(
          (releaseId) => !releaseIds.includes(releaseId)
        );
      });
      return newState;
    }

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
});

export default combined;
