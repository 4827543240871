import { Tag } from 'antd';

import AssetEditForm from '../components/AssetEditForm';
import AssetResourceList from '../components/AssetResourceList';
import AssetVersionTable from '../components/AssetVersionTable';
import CustomPageHeader from '../components/CustomPageHeader';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_ASSETS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

import type { Asset } from '../reducers/AssetReducer';

type Props = Readonly<{
  isSaving: boolean;
  onSubmit: (data: { name: string; isDraft: boolean }) => Promise<void>;
  onFileSelected: (file: File, assetId: number) => Promise<void>;
  onResourceDelete: (assetId: number, assetResourceId: number) => Promise<void>;
  data: Asset;
}>;

//
//
const AssetDetail = (props: Props) => {
  const { data, onSubmit, isSaving, onFileSelected, onResourceDelete } = props;

  const { currentApp } = useCurrentAppContext();
  const backLink = getUrlByName(ROUTE_NAME_APP_ASSETS, {
    ':appUid': currentApp.uid,
  });

  return (
    <div>
      <CustomPageHeader
        title={
          <>
            <span style={{ marginRight: '10px' }}>{`Patch details for ${data.name}`}</span>
            {data.isActive && <Tag color="cyan">is active</Tag>}
          </>
        }
        backLink={backLink}
      />

      <div style={{ marginTop: '2em' }}>
        <AssetEditForm data={data} backLink={backLink} onSubmit={onSubmit} isSaving={isSaving} />
        <AssetVersionTable data={data} />
        <AssetResourceList
          data={data}
          currentApp={currentApp}
          onFileSelected={onFileSelected}
          onResourceDelete={onResourceDelete}
        />
      </div>
    </div>
  );
};

export default AssetDetail;
