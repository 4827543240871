import MbxButton from '../components/base/MbxButton';
import TwofaCodeForm from '../components/TwofaCodeForm';

import type { TwofaCodeFormValues } from '../components/TwofaCodeForm';
import type { TwofaData } from '../reducers/AuthReducer';

type Props = Readonly<{
  twofaData: TwofaData;
  login: (data: TwofaCodeFormValues) => Promise<void>;
  resetTwofa: () => void;
  loginInfo: { isLoggingIn: boolean; errorMessage: string };
}>;

//
//
const LoginTwofa = (props: Props) => {
  const { login, loginInfo, twofaData, resetTwofa } = props;
  const { isLoggingIn } = loginInfo;

  return (
    <div>
      <p data-tid="twofa-login-head" style={{ marginBottom: 0, fontWeight: 'bold' }}>
        {twofaData.userData.email}
      </p>
      <p style={{ marginTop: 0, fontSize: 11 }}>
        (
        <MbxButton
          mbxType="tertiary"
          style={{ margin: '4px 0', padding: 0, lineHeight: 1, height: 'auto', fontSize: 12 }}
          onClick={resetTwofa}
        >
          use different account
        </MbxButton>
        )
      </p>
      <TwofaCodeForm askForPassword={false} onSubmitCode={login} isLoading={isLoggingIn} />
    </div>
  );
};

export default LoginTwofa;
