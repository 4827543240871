import classNames from 'classnames';

import MbxTag from './base/MbxTag';
import { getAppRelevantTags } from '../common/utils/configuration';
import { trendColors } from '../constants/analytics';
import { useCurrentAppContext } from '../contexts';
import styles from '../pages/EpisodeList.module.scss';
import { getTrendString, getTrendType } from '../utils/analytics';

import type { TrendColorsType } from '../constants/analytics';
import type { Episode } from '../reducers/EpisodeReducer';
import type { EpisodeTag } from '../reducers/EpisodeTagReducer';

type Props = Readonly<{
  data: Episode;
}>;

//
//
const EpisodeInfo = (props: Props) => {
  const { data: episode } = props;

  const { currentApp } = useCurrentAppContext();
  const appRelevantTags = getAppRelevantTags(currentApp);

  return (
    <div data-tid="episode-infobox" className={styles.episodeInfobox}>
      <div style={{ flex: 1 }}>
        {episode.tags.length > 0 && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}>
            <div data-tid="episode-info-tags">
              {episode.tags.map((et: EpisodeTag) => (
                <MbxTag
                  color="geekblue"
                  size="large"
                  style={{ opacity: appRelevantTags.includes(et.tag.name) ? 1 : 0.45 }}
                  className={styles.episodeTag}
                  key={`episode-${episode.id}-tag-${et.id}`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {et.tag.name !== '' ? et.tag.name : `...`}
                </MbxTag>
              ))}
            </div>
          </div>
        )}
        <div data-tid="analytics-episode-views" style={{ display: 'flex' }}>
          <span className={styles.analyticsValue}>{episode.views.toLocaleString()}</span>
          <span className={classNames(styles.analyticsLabel, styles.seperatorLine)}>
            total views
          </span>
          <span className={styles.analyticsValue}>
            {(episode.analytics?.viewsIn28Days ?? 0).toLocaleString()}
          </span>
          <span className={styles.analyticsLabel} style={{ paddingRight: '6px' }}>
            last 28 days
          </span>
          <span
            className={styles.analyticsValue}
            data-tid={`analytics-episode-trend-color-${getTrendType(
              episode?.analytics?.viewTrend
            )}`}
            style={{
              color:
                trendColors[getTrendType(episode?.analytics?.viewTrend) as keyof TrendColorsType],
            }}
          >
            ({getTrendString(episode?.analytics?.viewTrend)})
          </span>
          <a className={styles.analyticsLink} href="#analytics_section">
            see all analytics
          </a>
        </div>
      </div>
    </div>
  );
};

export default EpisodeInfo;
