import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import { ROUTE_NAME_HOME } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getFilledCrumbs } from '../selectors/pageHistory/advanced';
import { getIsSecure } from '../selectors/pageHistory/index';
import { useGlobalSelector } from '../utils/hooks';

//
//
const MediaboxBreadcrumb = () => {
  const filledCrumbs = useGlobalSelector(getFilledCrumbs);
  const isSecure = useGlobalSelector(getIsSecure);

  if (isSecure === false) {
    return null;
  }

  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      {filledCrumbs.map((crumb, index) => {
        // always remove Home from breadcrumb
        if (crumb.path === getUrlByName(ROUTE_NAME_HOME)) {
          return null;
        }

        if (index < filledCrumbs.length - 1) {
          return (
            <Breadcrumb.Item key={crumb.path}>
              <Link to={crumb.path}>{crumb.name}</Link>
            </Breadcrumb.Item>
          );
        }
        return <Breadcrumb.Item key={crumb.path}>{crumb.name}</Breadcrumb.Item>;
      })}
    </Breadcrumb>
  );
};

export default MediaboxBreadcrumb;
