import { normalize } from 'normalizr';

import {
  REGIONLOCALE_CREATE,
  REGIONLOCALE_CREATE_OK,
  REGIONLOCALE_CREATE_FAIL,
  REGIONLOCALE_REMOVE,
  REGIONLOCALE_REMOVE_OK,
  REGIONLOCALE_REMOVE_FAIL,
  REGIONLOCALE_UPDATE,
  REGIONLOCALE_UPDATE_OK,
  REGIONLOCALE_UPDATE_FAIL,
} from '../constants/regionLocales';
import { RegionLocaleSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const addRegionLocale =
  (regionId: number, localeId: number, isDraft: boolean) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/regionlocales`,
        method: 'POST',
        data: {
          regionId,
          localeId,
          isDraft,
        },
      },
      dispatch,
      {
        init: REGIONLOCALE_CREATE,
        fail: REGIONLOCALE_CREATE_FAIL,
      },
      201
    );
    const normalizedData = normalize(response.data, RegionLocaleSchema);
    dispatch({ type: REGIONLOCALE_CREATE_OK, regionId, payload: normalizedData });
    return normalizedData;
  };

//
//
export const removeRegionLocale =
  (regionId: number, localeId: number) => async (dispatch: AppDispatch) => {
    await wrapFetch(
      {
        url: `/regionlocales/${localeId}`,
        method: 'DELETE',
      },
      dispatch,
      {
        init: REGIONLOCALE_REMOVE,
        fail: REGIONLOCALE_REMOVE_FAIL,
      },
      204
    );
    dispatch({ type: REGIONLOCALE_REMOVE_OK, regionId, localeId });
    return true;
  };

//
//
export const updateRegionLocale =
  (regionId: number, localeId: number, isDraft: boolean) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/regionlocales/${localeId}`,
        method: 'PUT',
        data: {
          isDraft,
        },
      },
      dispatch,
      {
        init: REGIONLOCALE_UPDATE,
        fail: REGIONLOCALE_UPDATE_FAIL,
      }
    );
    const normalizedData = normalize(response.data, RegionLocaleSchema);
    dispatch({ type: REGIONLOCALE_UPDATE_OK, regionId, payload: normalizedData });
    return normalizedData;
  };
