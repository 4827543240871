import { LinkOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Dropdown, Menu } from 'antd';
import { useHistory } from 'react-router-dom';

import LocalReleaseDates from './base/LocalReleaseDates';
import MbxButton from './base/MbxButton';
import { getReleaseStatus } from '../common/utils/release';
import { ROUTE_NAME_APP_RELEASECREATE, ROUTE_NAME_APP_RELEASEDETAIL } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import styles from '../pages/EpisodeDetail.module.scss';
import { getAllRegionsForApp } from '../selectors/regions/advanced';
import { getEpisodeReleases } from '../selectors/releaseSelectors';
import { CLIENT_TIMEZONE, formatDate } from '../utils/date';
import { useGlobalSelector } from '../utils/hooks';
import { addTimeoffset } from '../utils/timezone';

import type { ReleaseStatus } from '../common/types/release';
import type { App } from '../reducers/AppsReducer';
import type { Episode } from '../reducers/EpisodeReducer';
import type { Region } from '../reducers/RegionReducer';
import type { Release } from '../reducers/ReleaseReducer';
import type { MenuProps } from 'antd';

type Props = Readonly<{
  episode: Episode;
  app: App;
}>;

//
//
const EpisodeReleaseStatus = (props: Props) => {
  const { episode, app } = props;
  const history = useHistory();

  const episodeReleases = useGlobalSelector((state) => getEpisodeReleases(state, episode.id));
  const allRegions = useGlobalSelector((state) => getAllRegionsForApp(state, app.id));

  const handleReleaseMenuClick: MenuProps['onClick'] = (info) => {
    const regionId = parseInt(info.key?.split('-').pop() ?? '', 10);
    console.log('handleReleaseMenuClick()', regionId);
    if (!Number.isNaN(regionId)) {
      const region = allRegions.find((re) => re.id === regionId);
      if (region != null) {
        history.push(
          `${getUrlByName(ROUTE_NAME_APP_RELEASECREATE, {
            ':appUid': app.uid,
          })}?region=${region.id}&episode=${episode.id}`
        );
      }
    }
  };

  const releaseData: Array<{
    region: Region;
    regionRelease: Release;
    releaseStatus: ReleaseStatus;
    publishDate: string | null;
    unpublishDate: string | null;
  }> = [];
  const notReleasedRegionData: Array<Region> = [];

  allRegions.forEach((re) => {
    const regionRelease = episodeReleases.find((rl) => rl.region.id === re.id);
    if (regionRelease != null) {
      const selectedTimezone = re?.releaseTimezone?.name ?? null;
      const publishDate = addTimeoffset(
        regionRelease.publishDate,
        selectedTimezone,
        CLIENT_TIMEZONE
      );
      const unpublishDate = addTimeoffset(
        regionRelease.unpublishDate,
        selectedTimezone,
        CLIENT_TIMEZONE
      );

      const releaseStatus = getReleaseStatus(regionRelease, re);

      releaseData.push({
        region: re,
        regionRelease,
        releaseStatus,
        publishDate:
          regionRelease.publishDate != null
            ? publishDate != null
              ? formatDate(publishDate)
              : '??'
            : '??',
        unpublishDate:
          regionRelease.unpublishDate != null
            ? unpublishDate != null
              ? formatDate(unpublishDate)
              : '??'
            : '∞',
      });
    } else {
      notReleasedRegionData.push(re);
    }
  });

  return (
    <List
      locale={{ emptyText: 'No Data' }}
      header={
        <div className={styles.listHeader}>
          <h2>Release-status per market</h2>
          <Dropdown
            disabled={notReleasedRegionData.length === 0}
            overlay={
              <Menu onClick={handleReleaseMenuClick}>
                {notReleasedRegionData.map((re) => (
                  <Menu.Item key={`add-regionrelease-menuitem-${re.id}`}>{re.name}</Menu.Item>
                ))}
              </Menu>
            }
          >
            <MbxButton data-tid="btn-add-release" icon={<PlusOutlined />} size="middle">
              add release in
            </MbxButton>
          </Dropdown>
        </div>
      }
      bordered
      className={styles.list}
      dataSource={releaseData}
      renderItem={(info) => {
        const actions = [];
        actions.push(
          <MbxButton
            size="small"
            icon={<LinkOutlined />}
            onClick={() => {
              history.push(
                getUrlByName(ROUTE_NAME_APP_RELEASEDETAIL, {
                  ':appUid': app.uid,
                  ':releaseId': info.regionRelease.id,
                })
              );
            }}
          >
            open release
          </MbxButton>
        );

        return (
          <List.Item key={info.region.id} actions={actions}>
            <List.Item.Meta
              title={
                <>
                  <span className={styles.regionLabel}>{info.region.name}</span>
                  <LocalReleaseDates
                    style={{ display: 'inline-block' }}
                    release={info.regionRelease}
                    releaseStatus={info.releaseStatus}
                  />
                </>
              }
            />
          </List.Item>
        );
      }}
    />
  );
};

export default EpisodeReleaseStatus;
