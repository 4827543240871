import dayjs from './date';

import type { DateWrapper } from './date';

//
//
export function displayDateInDifferentTimezone(
  value: DateWrapper | Date | null | undefined,
  timezone: string | null | undefined
) {
  if (value == null || timezone == null || timezone === '') {
    return '??';
  }

  if (value instanceof Date) {
    value = dayjs(value);
  }

  return value.clone().tz(timezone).format('YYYY-MM-DD HH:mm');
}

//
//
export function addTimeoffset(
  value: DateWrapper | Date | null,
  inputTimezone: string,
  outputTimezone: string
) {
  if (value == null) {
    return null;
  }
  if (value instanceof Date) {
    value = dayjs(value);
  }

  try {
    const offset = value.clone().tz(inputTimezone).utcOffset();
    const offsetInHours = offset / 60;

    const offset2 = value.clone().tz(outputTimezone).utcOffset();
    const offset2InHours = offset2 / 60;

    const delta = Math.abs(offsetInHours - offset2InHours);
    // console.log({
    //   inputTimezone,
    //   outputTimezone,
    //   offset,
    //   offsetInHours,
    //   offset2,
    //   offset2InHours,
    //   delta,
    // });
    const result =
      offsetInHours > offset2InHours
        ? value.clone().subtract(delta, 'hours')
        : value.clone().add(delta, 'hours');

    return result;
  } catch (err) {
    console.warn(err);
    return null;
  }
}
