export const DEPLOYMENT_GET_LIST = 'DEPLOYMENT_GET_LIST';
export const DEPLOYMENT_GET_LIST_OK = 'DEPLOYMENT_GET_LIST_OK';
export const DEPLOYMENT_GET_LIST_FAIL = 'DEPLOYMENT_GET_LIST_FAIL';

export const DEPLOYMENT_GET_DETAILS = 'DEPLOYMENT_GET_DETAILS';
export const DEPLOYMENT_GET_DETAILS_OK = 'DEPLOYMENT_GET_DETAILS_OK';
export const DEPLOYMENT_GET_DETAILS_FAIL = 'DEPLOYMENT_GET_DETAILS_FAIL';

export const DEPLOYMENT_GET_PREVIOUS = 'DEPLOYMENT_GET_PREVIOUS';
export const DEPLOYMENT_GET_PREVIOUS_OK = 'DEPLOYMENT_GET_PREVIOUS_OK';
export const DEPLOYMENT_GET_PREVIOUS_FAIL = 'DEPLOYMENT_GET_PREVIOUS_FAIL';

export const DEPLOYMENT_GET_LATEST = 'DEPLOYMENT_GET_LATEST';
export const DEPLOYMENT_GET_LATEST_OK = 'DEPLOYMENT_GET_LATEST_OK';
export const DEPLOYMENT_GET_LATEST_FAIL = 'DEPLOYMENT_GET_LATEST_FAIL';

export const DEPLOYMENT_CREATE = 'DEPLOYMENT_CREATE';
export const DEPLOYMENT_CREATE_OK = 'DEPLOYMENT_CREATE_OK';
export const DEPLOYMENT_CREATE_FAIL = 'DEPLOYMENT_CREATE_FAIL';

export const DEPLOYMENT_FETCH_DATA = 'DEPLOYMENT_FETCH_DATA';
export const DEPLOYMENT_FETCH_DATA_OK = 'DEPLOYMENT_FETCH_DATA_OK';
export const DEPLOYMENT_FETCH_DATA_FAIL = 'DEPLOYMENT_FETCH_DATA_FAIL';
export const DEPLOYMENT_FETCH_DATA_COMPLETE = 'DEPLOYMENT_FETCH_DATA_COMPLETE';
export const DEPLOYMENT_FETCH_DATA_RESET = 'DEPLOYMENT_FETCH_DATA_RESET';

export const DEPLOYMENT_CALC_DIFF = 'DEPLOYMENT_CALC_DIFF';
export const DEPLOYMENT_CALC_DIFF_OK = 'DEPLOYMENT_CALC_DIFF_OK';
export const DEPLOYMENT_CALC_DIFF_FAIL = 'DEPLOYMENT_CALC_DIFF_FAIL';
export const DEPLOYMENT_CALC_DIFF_CANCELED = 'DEPLOYMENT_CALC_DIFF_CANCELED';

export const DEPLOYMENT_REMOVE = 'DEPLOYMENT_REMOVE';
export const DEPLOYMENT_REMOVE_OK = 'DEPLOYMENT_REMOVE_OK';
export const DEPLOYMENT_REMOVE_FAIL = 'DEPLOYMENT_REMOVE_FAIL';
