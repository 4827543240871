import { getUserProfile } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

const Home = () => {
  const user = useGlobalSelector(getUserProfile);

  return (
    <div>
      <h1>Home</h1>
      {user.apps?.length === 0 && (
        <p>
          You are not a member of any Mediabox apps - so nothing to see here ...
          <br />
          Ask another user from an app you want to join to invite you.
        </p>
      )}
    </div>
  );
};

export default Home;
