import { message, Alert } from 'antd';
import queryString from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import { getJobList, getJobMetadata } from '../actions/jobs';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { JobStatus } from '../common/constants/job-status';
import { ROUTE_NAME_INTERNAL_JOBDETAIL, ROUTE_NAME_INTERNAL_JOBS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import JobList from '../pages/JobList';
import { getFilteredJobsInfo } from '../selectors/jobSelectors';
import { getPageFromSearch } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

const DEFAULT_PER_PAGE = 25;

//
//
const JobListContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const currentPage = getPageFromSearch(history.location.search);

  const filteredJobsInfo = useGlobalSelector((state) =>
    getFilteredJobsInfo(state, { itemsPerPageCount: DEFAULT_PER_PAGE })
  );
  const { max, maxPage, jobs, itemsPerPageCount, newPageLoading } = filteredJobsInfo;

  const unfinishedJobs = jobs.filter((j) => {
    const status = j.logs?.[0]?.statusId;
    return status < JobStatus.COMPLETED;
  });

  const timeoutRef = useRef<null | number>(null);
  const [error, setError] = useState('');

  let unfinishedJobsIdsString = unfinishedJobs.map((j) => j.id).join(',');
  if (unfinishedJobsIdsString !== '' && timeoutRef.current == null) {
    unfinishedJobsIdsString += `###${+new Date()}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getJobList(currentPage));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentPage]);

  useEffect(() => {
    const checkJobStatus = async (ids: Array<number>) => {
      if (timeoutRef.current != null) {
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      try {
        dispatch(counterIncrement());
        await dispatch(getJobMetadata(ids));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };

    if (unfinishedJobsIdsString === '') {
      return;
    }

    const [ids] = unfinishedJobsIdsString.split('###');
    const idsAsNumbers = ids
      .split(',')
      .map((idStr) => Number(idStr))
      .filter((n): n is number => !!n);
    if (idsAsNumbers.length > 0) {
      if (timeoutRef.current == null) {
        timeoutRef.current = window.setTimeout(checkJobStatus, 5000, idsAsNumbers);
      }
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (timeoutRef.current != null) {
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [dispatch, unfinishedJobsIdsString]);

  const handleOpenDetails = (jobId: number) => {
    history.push(
      getUrlByName(ROUTE_NAME_INTERNAL_JOBDETAIL, {
        ':jobId': jobId,
      })
    );
  };

  const updateSearchParams = (opts: { page?: number }) => {
    const options = {
      ...{ page: currentPage },
      ...opts,
    };
    history.push(`${getUrlByName(ROUTE_NAME_INTERNAL_JOBS)}?${queryString.stringify(options)}`);
  };

  const handlePaginationChange = (page: number) => {
    updateSearchParams({ page });
  };

  if (maxPage > 0 && currentPage > maxPage) {
    return <Redirect to={`${getUrlByName(ROUTE_NAME_INTERNAL_JOBS)}?page=${maxPage}`} />;
  }
  if (currentPage <= 0) {
    return <Redirect to={getUrlByName(ROUTE_NAME_INTERNAL_JOBS)} />;
  }

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <JobList
      jobs={jobs}
      totalItems={max}
      itemsPerPage={itemsPerPageCount}
      currentPage={currentPage}
      newPageLoading={newPageLoading}
      handleOpenDetails={handleOpenDetails}
      handlePaginationChange={handlePaginationChange}
    />
  );
};

export default JobListContainer;
