import { Form, Input } from 'antd';

import MbxButton from './base/MbxButton';

type Props = Readonly<{
  prefilledEmail: string | null | undefined;
  onSubmit: (values: PasswordForgotFormValues) => Promise<void>;
  isSending: boolean;
}>;

export type PasswordForgotFormValues = {
  email: string;
};

//
//
const PasswordForgotForm = (props: Props) => {
  const { isSending, prefilledEmail, onSubmit } = props;

  const handleSubmit = (values: PasswordForgotFormValues) => {
    onSubmit(values);
  };

  return (
    <div>
      <Form onFinish={handleSubmit} noValidate name="PasswordForgotForm" validateTrigger="onSubmit">
        <Form.Item
          label="Email"
          name="email"
          initialValue={prefilledEmail ?? undefined}
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Has to be valid email!' },
          ]}
        >
          <Input
            data-tid="passwordforgot-input-email"
            autoFocus
            type="email"
            style={{ width: 420 }}
          />
        </Form.Item>

        <Form.Item>
          <MbxButton
            data-tid="passwordforgot-input-submit"
            loading={isSending}
            mbxType="primary"
            htmlType="submit"
          >
            Send
          </MbxButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordForgotForm;
