import { createSelector } from '@reduxjs/toolkit';

import { getUserByIdFunc } from './userSelectors';

import type { RootState } from '../reducers';
import type { BatchUpload, BatchUploadByAppIdState } from '../reducers/BatchUploadReducer';
import type { Selector } from 'react-redux';

//
//
const getByAppId: Selector<RootState, BatchUploadByAppIdState> = (state) =>
  state.batchUploads.byAppId;

//
//
export const getBatchUploadByAppIdFunc: Selector<RootState, (id?: number) => BatchUpload | null> =
  createSelector(getByAppId, getUserByIdFunc, (byId, $getUserById) => (id) => {
    if (id == null) {
      return null;
    }
    const batchUploadNormalized = byId[id];
    if (batchUploadNormalized == null) {
      return null;
    }

    return {
      ...batchUploadNormalized,
      creator: $getUserById(batchUploadNormalized.createdBy),
    };
  });

//
//
export const getBatchUploadByAppId = createSelector(
  getBatchUploadByAppIdFunc,
  (_: unknown, id?: number) => id,
  //
  ($getBatchUploadByAppId, id) => $getBatchUploadByAppId(id)
);
