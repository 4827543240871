export const EPISODELOCALIZED_CREATE = 'EPISODELOCALIZED_CREATE';
export const EPISODELOCALIZED_CREATE_OK = 'EPISODELOCALIZED_CREATE_OK';
export const EPISODELOCALIZED_CREATE_FAIL = 'EPISODELOCALIZED_CREATE_FAIL';

export const EPISODELOCALIZED_UPDATE = 'EPISODELOCALIZED_UPDATE';
export const EPISODELOCALIZED_UPDATE_OK = 'EPISODELOCALIZED_UPDATE_OK';
export const EPISODELOCALIZED_UPDATE_FAIL = 'EPISODELOCALIZED_UPDATE_FAIL';

export const EPISODELOCALIZED_DELETE = 'EPISODELOCALIZED_DELETE';
export const EPISODELOCALIZED_DELETE_OK = 'EPISODELOCALIZED_DELETE_OK';
export const EPISODELOCALIZED_DELETE_FAIL = 'EPISODELOCALIZED_DELETE_FAIL';

export const EPISODELOCALIZED_ADD_CONTENT = 'EPISODELOCALIZED_ADD_CONTENT';
export const EPISODELOCALIZED_ADD_CONTENT_OK = 'EPISODELOCALIZED_ADD_CONTENT_OK';
export const EPISODELOCALIZED_ADD_CONTENT_FAIL = 'EPISODELOCALIZED_ADD_CONTENT_FAIL';

export const EPISODELOCALIZED_REMOVE_CONTENT = 'EPISODELOCALIZED_REMOVE_CONTENT';
export const EPISODELOCALIZED_REMOVE_CONTENT_OK = 'EPISODELOCALIZED_REMOVE_CONTENT_OK';
export const EPISODELOCALIZED_REMOVE_CONTENT_FAIL = 'EPISODELOCALIZED_REMOVE_CONTENT_FAIL';
