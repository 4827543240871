import { createSelector } from 'reselect';

import { getUserByIdFunc } from './userSelectors';
import { sortByFirstname } from '../utils/sort';

import type { RootState } from '../reducers';
import type { AppUser } from '../reducers/AppUsersReducer';

const getById = (state: RootState) => state.appUser.byId;

//
//
export const getAppUsers = createSelector(
  getById,
  getUserByIdFunc,
  (_: unknown, appId: number) => appId,
  (byId, $getUserById, appId) => {
    if (appId == null) {
      return [];
    }

    const appUsers: Array<AppUser> = [];
    Object.keys(byId).map((appUserIdStr) => {
      if (byId[appUserIdStr].app === appId) {
        appUsers.push({
          ...byId[appUserIdStr],
          user: $getUserById(byId[appUserIdStr].user),
        });
      }
    });
    return appUsers.sort((a, b) => sortByFirstname(a.user, b.user));
  }
);

//
//
export const getAppUser = createSelector(
  getById,
  getUserByIdFunc,
  (_: unknown, data: { appId: number; userId: number }) => data,
  (byId, $getUserById, data) => {
    const { userId, appId } = data;
    const user = $getUserById(userId);
    if (user == null) {
      return null;
    }

    const appUserKey = Object.keys(byId).find(
      (appUserIdStr) => byId[appUserIdStr].app === appId && byId[appUserIdStr].user === userId
    );

    if (appUserKey == null) {
      return null;
    }

    return { ...byId[appUserKey], user: user };
  }
);
