import { createSelector } from 'reselect';

import { getEmptyAnalyticsExport } from '../reducers/AnalyticsReducer';

import type { RootState } from '../reducers';

//
//
const byId = (state: RootState) => state.analyticsExports.byId;

//
//
export const getAnalyticsExportByIdFunc = createSelector(
  byId,
  //
  ($byId) => (id: number) => {
    const ae = $byId[id];
    if (ae == null) {
      return getEmptyAnalyticsExport(id);
    }
    return ae;
  }
);

//
//
export const getAnalyticsExportById = createSelector(
  getAnalyticsExportByIdFunc,
  (_: unknown, analyticsExportId: number) => analyticsExportId,
  //
  ($getAnalyticsExportByIdFunc, aeId) => $getAnalyticsExportByIdFunc(aeId)
);

//
//
export const getAnalyticsExportsByIdsFunc = createSelector(
  getAnalyticsExportByIdFunc,
  //
  ($getAnalyticsExportByIdFunc) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      ids = [];
    }
    return ids.map((id) => $getAnalyticsExportByIdFunc(id));
  }
);

//
//
export const getAllAnalyticsExportsForAppFunc = createSelector(
  byId,
  getAnalyticsExportsByIdsFunc,
  //
  ($byId, $getAnalyticsExportsByIdsFunc) => (appId: number, typeId: number) => {
    const ids = Object.keys($byId)
      .filter((aeId) => $byId[aeId].appId === appId && $byId[aeId].typeId === typeId)
      .map((aeId) => Number(aeId));
    if (!Array.isArray(ids)) {
      return [];
    }
    return $getAnalyticsExportsByIdsFunc(ids);
  }
);

//
//
export const getAllAnalyticsExportsForAppAndType = createSelector(
  getAllAnalyticsExportsForAppFunc,
  (_: unknown, appId: number) => appId,
  (_: unknown, __: unknown, typeId: number) => typeId,
  //
  ($getAllAnalyticsExportsForAppFunc, appId, typeId) =>
    $getAllAnalyticsExportsForAppFunc(appId, typeId)
);
