import { Form, Input, Switch, Select } from 'antd';
import { useEffect } from 'react';

import BackButton from './BackButton';
import MbxButton from './base/MbxButton';
import styles from './RegionEditForm.module.scss';
import { selectFilterFunc } from '../utils/filter';

import type { Region } from '../reducers/RegionReducer';
import type { Timezone } from '../reducers/TimezoneReducer';

type Props = Readonly<{
  data?: Region | null | undefined;
  timezones: Array<Timezone>;
  backLink: string;
  onSubmit: (values: RegionEditFormValues) => Promise<boolean>;
}>;

export type RegionEditFormValues = {
  name: string;
  isDraft: boolean;
  useDefaultAsFallback: boolean;
  releaseTimezone: number;
};

//
//
const RegionEditForm = (props: Props) => {
  const { data, timezones = [], onSubmit, backLink } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const handleSubmit = async (values: RegionEditFormValues) => {
    console.log('Received values of form: ', values);
    try {
      await onSubmit(values);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        className={styles.form}
        name="RegionEditForm"
        layout="vertical"
      >
        <Form.Item
          label="Name"
          name="name"
          initialValue={data?.name ?? undefined}
          rules={[{ required: true, message: "Please input the region's name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Release timezone"
          name="releaseTimezone"
          initialValue={data?.releaseTimezone?.id ?? undefined}
          rules={[{ required: true, message: 'Please input release timezone!' }]}
        >
          <Select showSearch placeholder="Select a timezone" filterOption={selectFilterFunc}>
            {timezones.map((t) => (
              <Select.Option key={t.id} value={t.id}>
                {t.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="is draft"
          name="isDraft"
          initialValue={data?.isDraft ?? false}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="use content from default language, when no localized content is available"
          name="useDefaultAsFallback"
          initialValue={data?.useDefaultAsFallback ?? false}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item style={{ marginTop: '3em' }}>
          <MbxButton
            mbxType="primary"
            htmlType="submit"
            className={styles.loginFormButton}
            data-tid="btn-region-save"
          >
            {data != null ? 'Save' : 'Create'}
          </MbxButton>
          <BackButton linkTo={backLink} style={{ marginLeft: '1em' }} size="large">
            Cancel
          </BackButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegionEditForm;
