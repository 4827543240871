export const SCHEDULED = 'scheduled';
export const LIVE = 'live';
export const EXPIRED = 'expired';

export const STATUS_COLORS: { [key: string]: string } = {
  [LIVE]: 'green',
  [EXPIRED]: 'red',
  [SCHEDULED]: 'orange',
};

export const STATUS_STYLES: {
  [key: string]: {
    borderRadius?: number;
    padding?: string;
    backgroundColor?: string;
    color?: string;
  };
} = {
  [LIVE]: {},
  [EXPIRED]: {
    borderRadius: 4,
    padding: '2px 4px',
    backgroundColor: 'rgba(255,0,0,0.1)',
    color: '#900',
  },
  [SCHEDULED]: {
    borderRadius: 4,
    padding: '2px 4px',
    backgroundColor: 'rgba(239,163,47,0.1)',
    color: '#efa32f',
  },
};

// {
//   publishDateStatus: string;
//   releasable: boolean;
//   localeStatus: Array<{
//     id: number;
//     shortcode: string;
//     releasable: boolean;
//     reasons: Array<{ versionId: number; msg: string }>;
//     leCreated: boolean;
//   }>;
// }

// {
//   '10001': number;
//   '10002': { min: number; max: number };
//   '10003': number;
// }
