import { Pie } from '@ant-design/charts';

import styles from './PieAndTable.module.scss';

import type { FunctionComponent } from 'react';

export type PieAndTableData = {
  total: number;
  videos: number;
  audios: number;
  unity: number;
  html: number;
};

type Props = {
  pieAndTableData: PieAndTableData;
  testIdPrefix: string;
};

const pieColor = {
  video: '#78DE86',
  audio: '#3EBFFF',
  unity: '#FFC059',
  html: '#FF8163',
};

const toPercent = (value: number) =>
  value.toLocaleString(navigator.language, {
    style: 'percent',
    minimumFractionDigits: 2,
  });

const calcPercent = (value: number, total: number): number => {
  if (total === 0) {
    return 0;
  }

  return value / total;
};

const PieAndTable: FunctionComponent<Props> = ({ pieAndTableData, testIdPrefix }) => {
  const { total, videos, audios, unity, html } = pieAndTableData;

  const videoPercentage = calcPercent(videos, total);
  const audioPercentage = calcPercent(audios, total);
  const unityPercentage = calcPercent(unity, total);
  const htmlPercentage = calcPercent(html, total);

  const data = [
    { type: 'video', value: videoPercentage },
    { type: 'audio', value: audioPercentage },
    { type: 'unity', value: unityPercentage },
    { type: 'html', value: htmlPercentage },
  ];

  return (
    <>
      <div style={{ height: '150px' }} data-tid={`${testIdPrefix}-pie`}>
        <Pie
          data={data}
          angleField="value"
          colorField="type"
          tooltip={false}
          legend={false}
          label={false}
          animation={false}
          color={({ type }) => pieColor[type as keyof typeof pieColor]}
        />
      </div>
      <table className={styles.table}>
        <tbody>
          <tr data-tid={`${testIdPrefix}-table-video`}>
            <td>
              <div className={styles.dot} style={{ backgroundColor: pieColor.video }} />
            </td>
            <td>Video</td>
            <td>{videos.toLocaleString()}</td>
            <td>{toPercent(videoPercentage)}</td>
          </tr>
          <tr data-tid={`${testIdPrefix}-table-audio`}>
            <td>
              <div className={styles.dot} style={{ backgroundColor: pieColor.audio }} />
            </td>
            <td>Audio</td>
            <td>{audios.toLocaleString()}</td>
            <td>{toPercent(audioPercentage)}</td>
          </tr>
          <tr data-tid={`${testIdPrefix}-table-unity`}>
            <td>
              <div className={styles.dot} style={{ backgroundColor: pieColor.unity }} />
            </td>
            <td>Unity</td>
            <td>{unity.toLocaleString()}</td>
            <td>{toPercent(unityPercentage)}</td>
          </tr>
          <tr data-tid={`${testIdPrefix}-table-html`}>
            <td>
              <div className={styles.dot} style={{ backgroundColor: pieColor.html }} />
            </td>
            <td>HTML</td>
            <td>{html.toLocaleString()}</td>
            <td>{toPercent(htmlPercentage)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default PieAndTable;
