import { createSelector } from 'reselect';

import { getEpisodeByIdFunc } from './episodeSelectors';
import { getRegionByIdFunc } from './regions/advanced';
import { getReleaseCategoriesByIdsFunc } from './releasecategorySelectors';
import { getEmptyRelease } from '../reducers/ReleaseReducer';
import { sortReleaseByEpisodeName, sortByCreatedAt, sortByPublishDateAndId } from '../utils/sort';

import type { ReleaseSortInfo } from './urlSelectors';
import type { RootState } from '../reducers';
import type { ReleaseCategory } from '../reducers/ReleaseCategoryReducer';
import type { Release } from '../reducers/ReleaseReducer';
import type { Selector } from 'reselect';

//
//
const getById = (state: RootState) => state.releases.byId;
const getAllIds = (state: RootState) => state.releases.allIds;

//
//
export const getReleaseByIdFunc: Selector<RootState, (id: number) => Release> = createSelector(
  getById,
  getReleaseCategoriesByIdsFunc,
  getEpisodeByIdFunc,
  getRegionByIdFunc,
  //
  (byId, $getReleaseCategoriesByIds, $getEpisodeById, $getRegionById) => (id) => {
    const release = byId[id];
    if (release == null) {
      return getEmptyRelease(id);
    }

    const categories = $getReleaseCategoriesByIds(release.categories);
    // sort releaseCategories by id ASC so that they will not change places after update/reload
    // (not createdAt b/c could be that multiple categories have the same createdAt time)
    categories.sort((a, b) => a.id - b.id);

    return {
      ...release,
      episode: $getEpisodeById(release.episode),
      region: $getRegionById(release.region),
      categories,
    };
  }
);

//
//
export const getReleaseById = createSelector(
  getReleaseByIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getReleaseById, id) => $getReleaseById(id)
);

//
//
export const getReleasesByIds = createSelector(
  getReleaseByIdFunc,
  (_: unknown, ids: Array<number>) => ids,
  //
  ($getReleaseById, ids) => ids.map((id) => $getReleaseById(id))
);

//
//
export const getReleasesByCategory = createSelector(
  getById,
  getReleaseByIdFunc,
  (_: unknown, categoryId: number) => categoryId,
  (_: unknown, __: unknown, regionId: number | null | undefined) => regionId,
  //
  ($byId, $getReleaseById, categoryId, regionId) => {
    if (regionId == null) {
      return [];
    }

    const releases: Array<Release> = [];
    Object.keys($byId).forEach((releaseIdStr) => {
      const releaseId = parseInt(releaseIdStr, 10);
      const release = $getReleaseById(releaseId);
      if (release !== null && release.region.id === regionId) {
        const currentCategoryIds = release.categories.map(
          (releaseCategory) => releaseCategory.category.id
        );
        if (currentCategoryIds.includes(categoryId)) {
          releases.push(release);
        }
      }
    });

    releases.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
    releases.sort((a, b) => {
      const rcA = a.categories.find((rc) => rc.category.id === categoryId);
      const rcB = b.categories.find((rc) => rc.category.id === categoryId);
      if (rcA == null || rcB == null) {
        return 0;
      }
      return rcA.ordinal - rcB.ordinal;
    });

    return releases;
  }
);

//
//
export const getAllReleasesForRegion = createSelector(
  getAllIds,
  getReleaseByIdFunc,
  (_: unknown, regionId: number) => regionId,
  //
  ($allIds, $getReleaseById, regionId) => {
    if (regionId == null) {
      return [];
    }
    const allIds = $allIds[regionId];
    if (!Array.isArray(allIds)) {
      return [];
    }

    return allIds.map((id: number) => $getReleaseById(id));
  }
);

//
//
export const getReleasesNotInCategoryForRegion = createSelector(
  getAllIds,
  getReleaseByIdFunc,
  (_: unknown, regionId: number | undefined | null) => regionId,
  (_: unknown, __: unknown, categoryId: number) => categoryId,
  //
  ($allIds, $getReleaseById, regionId, categoryId) => {
    if (regionId == null) {
      return [];
    }
    const allIds = $allIds[regionId];
    if (!Array.isArray(allIds)) {
      return [];
    }

    return allIds
      .map((id: number) => $getReleaseById(id))
      .filter((rl) => {
        // console.log('release', rl);
        const categoryIds = rl.categories.map((c) => c.category.id);
        return !categoryIds.includes(categoryId);
      })
      .sort((a, b) => a.episode.name.localeCompare(b.episode.name));
  }
);

//
//
export const getAllReleases = (state: RootState): Array<Release> =>
  Object.keys(state.releases.byId).map((id: string) => getReleaseById(state, parseInt(id, 10)));

//
//
export const getAllReleasedEpisodeIdsPerRegion = (
  state: RootState
): { [k: string | number]: Array<number> } => {
  const episodeIds: { [k: string | number]: Array<number> } = {};
  Object.keys(state.releases.allIds).forEach((regionId) => {
    episodeIds[regionId] = [];
    const arr = state.releases.allIds[regionId];
    arr.forEach((id) => {
      const release = getReleaseById(state, id);
      if (release !== null) {
        const episodeId = release?.episode?.id ?? null;
        if (episodeId !== null) {
          episodeIds[regionId].push(episodeId);
        }
      }
    });
  });
  return episodeIds;
};

//
//
const searchStrs = (splits: Array<string>, needle: string): boolean => {
  let found = true;
  splits.forEach((str) => {
    const foundSubStr: boolean = needle.indexOf(str) !== -1;
    found = found && foundSubStr;
  });
  return found;
};

//
//
export const getFilteredReleasesInfo = (
  state: RootState,
  regionId: number,
  currentSearch: string,
  currentPage: number,
  itemsPerPage: number,
  currentCategoryIds: Array<number>,
  currentTypeIds: Array<number>,
  currentFree: boolean,
  currentSortInfo: ReleaseSortInfo
): {
  max: number;
  maxPage: number;
  releases: Array<Release>;
  itemsPerPage: number;
  currentIdsStr: string;
  sortedBy: string;
} => {
  const allReleases = getAllReleasesForRegion(state, regionId);

  let filteredItems = allReleases;

  if (currentFree === true) {
    filteredItems = filteredItems.filter((item) => item.isDemoContent);
  }

  if (currentTypeIds.length > 0) {
    filteredItems = filteredItems.filter((item) => {
      const playerType = item?.episode?.playerTypeId ?? -1;
      return playerType === -1 || currentTypeIds.includes(playerType);
    });
  }

  if (currentSearch !== '') {
    filteredItems = filteredItems.filter((item) => {
      const splits = currentSearch.toLowerCase().split(' ');
      let found = searchStrs(splits, item.episode.name.toLowerCase());

      // and episode categories name
      if (found === false) {
        found = item.categories.reduce((sum: boolean, releaseCategory: ReleaseCategory) => {
          // it's enough when we found one matching category
          if (sum === true) {
            return true;
          }
          return searchStrs(splits, releaseCategory.category.name.toLowerCase());
        }, false);
      }

      return found;
    });
  }

  if (currentCategoryIds.length > 0) {
    filteredItems = filteredItems.filter(
      (item) =>
        // look for releases that include _all_ categories
        item.categories.filter((releaseCategory) =>
          currentCategoryIds.includes(releaseCategory.category.id)
        ).length === currentCategoryIds.length
    );
  }

  // sort items
  switch (currentSortInfo.sortBy) {
    case 'publishDate': {
      filteredItems.sort(sortByPublishDateAndId);
      break;
    }
    case 'date': {
      filteredItems.sort(sortByCreatedAt);
      break;
    }
    case 'name': {
      filteredItems.sort(sortReleaseByEpisodeName);
      break;
    }
    default: {
      throw new Error(`unknown sortBy for episodes ${currentSortInfo.sortBy}`);
    }
  }

  if (currentSortInfo.order === 'desc') {
    filteredItems.reverse();
  }

  const max = filteredItems.length;

  const firstIndex = (currentPage - 1) * itemsPerPage;
  const currentPageItems = filteredItems.slice(firstIndex, firstIndex + itemsPerPage);
  return {
    max,
    maxPage: Math.ceil(max / itemsPerPage),
    itemsPerPage,
    currentIdsStr: currentPageItems.map((e) => e.id).join(','),
    releases: currentPageItems,
    sortedBy: `${currentSortInfo.sortBy}:${currentSortInfo.order}`,
  };
};

//
//
export const getEpisodeReleases = (state: RootState, episodeId: number) => {
  const allReleases = state.releases.byId;
  return Object.keys(allReleases)
    .map((id) => getReleaseById(state, Number(id)))
    .filter((release) => release.episode.id === episodeId);
};
