import { Card } from 'antd';
import { Link } from 'react-router-dom';

import MbxButton from '../components/base/MbxButton';
import CustomPageHeader from '../components/CustomPageHeader';
import { useCurrentAppContext } from '../contexts';
import {
  ROUTE_NAME_APP_ANALYTICSMAUSREPORTS,
  ROUTE_NAME_APP_ANALYTICSVIEWSREPORTS,
} from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

//
//
const AppAnalytics = () => {
  const { currentApp } = useCurrentAppContext();

  return (
    <>
      <CustomPageHeader title={`Reports for ${currentApp.name}`} />

      <div
        style={{
          marginTop: '2em',
        }}
      >
        <Card title="Content views" size="small" style={{ maxWidth: 675 }}>
          <p>
            You can download monthly exports for the content viewcounts and get a better overview of
            which episodes are trending and which maybe need a small push to get some traction...
          </p>
          <Link
            to={getUrlByName(ROUTE_NAME_APP_ANALYTICSVIEWSREPORTS, {
              ':appUid': currentApp.uid,
            })}
          >
            <MbxButton>Open list</MbxButton>
          </Link>
        </Card>

        <Card title="Monthly active users" size="small" style={{ marginTop: '2em', maxWidth: 675 }}>
          <p>
            The monthly MAU exports give you insights into who is using the app where in the world.
          </p>
          <Link
            to={getUrlByName(ROUTE_NAME_APP_ANALYTICSMAUSREPORTS, {
              ':appUid': currentApp.uid,
            })}
          >
            <MbxButton>Open list</MbxButton>
          </Link>
        </Card>
      </div>
    </>
  );
};

export default AppAnalytics;
