import { Spin, Button, Alert } from 'antd';

import LoginContainer from '../containers/LoginContainer';
import RegisterFormContainer from '../containers/RegisterFormContainer';
import { getUserProfileOptional } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { CheckInviteTokenResponse } from '../reducers/InviteReducer';
import type { User } from '../reducers/UsersReducer';

type Props = Readonly<{
  invitationData: CheckInviteTokenResponse | Error | null;
  logout: () => Promise<void>;
  joinApp: () => Promise<void>;
  declineInvite: () => Promise<void>;
}>;

const DebugInfo = ({
  inviteEmail,
  user,
}: {
  inviteEmail: string;
  user: User | null | undefined;
}) => (
  <div style={{ marginTop: '6em', fontSize: '10px' }}>
    <p style={{ margin: '0.25em 0' }}>
      <span>Invitation sent to: </span>
      <span>{inviteEmail}</span>
    </p>
    <p style={{ margin: '0.25em 0' }}>
      <span>You are: </span>
      <span>{user?.email ?? 'not logged in'}</span>
    </p>
  </div>
);

//
//
const UserInviteConfirmation = (props: Props) => {
  const { invitationData, logout, joinApp, declineInvite } = props;

  const user = useGlobalSelector(getUserProfileOptional);

  if (invitationData === null) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  if (invitationData instanceof Error) {
    let errorMessage;
    switch (invitationData.message) {
      case 'token_not_found':
      case 'token_invalid':
        errorMessage = 'This is not a valid invite token';
        break;
      case 'token_expired':
        errorMessage = 'This invite token already expired';
        break;
      default:
        errorMessage = invitationData.message;
    }

    return (
      <div>
        <Alert
          message="Error"
          description={errorMessage}
          type="error"
          showIcon
          style={{ width: '66%' }}
        />
      </div>
    );
  }

  if (user != null) {
    // CASE 1) logged in w/ same email
    if (user.email === invitationData.email) {
      return (
        <div data-tid="join-app-case-1">
          <h1
            style={{ fontSize: '24px', fontWeight: 'bold' }}
          >{`You have been invited to join the '${invitationData.appName ?? '??'}' team! 🎉`}</h1>
          <Button
            data-tid="btn-join-app"
            type="primary"
            onClick={() => {
              joinApp();
            }}
          >
            Join
          </Button>
          <Button
            data-tid="btn-decline-invite"
            onClick={() => {
              declineInvite();
            }}
            style={{ marginLeft: 10 }}
          >
            Decline
          </Button>
          <DebugInfo inviteEmail={invitationData.email} user={user} />
        </div>
      );
    }

    // CASE 2) logged in w/ different email
    return (
      <div data-tid="join-app-case-2">
        <h1
          style={{ fontSize: '24px', fontWeight: 'bold' }}
        >{`Invitation was sent to different email: ${invitationData.email}!`}</h1>
        <p>
          Log out and switch accounts to accept the invitation or contact the person who invited you
          and tell them to change the email.
        </p>
        <Button
          type="primary"
          onClick={() => {
            logout();
          }}
        >
          Logout
        </Button>
        <DebugInfo inviteEmail={invitationData.email} user={user} />
      </div>
    );
  }

  // CASE 3) not logged in
  return (
    <div data-tid="join-app-case-3">
      <h1 style={{ fontSize: '24px', fontWeight: 'bold' }}>{`You have been invited to join the '${
        invitationData.appName ?? '??'
      }' team! 🎉`}</h1>

      <div style={{ width: '100%', display: 'flex', marginTop: '2em' }}>
        <div
          style={{
            width: '46%',
            marginBottom: '0.5em',
          }}
        >
          <h2 style={{ textAlign: 'center' }}>Login</h2>
          <div
            style={{
              border: '1px solid rgba(0,0,0,.2)',
              borderRadius: '4px',
              padding: '1em',
            }}
          >
            <LoginContainer forceEmail={invitationData.email} comebackAfterLogin />
          </div>
        </div>
        <div
          style={{
            width: '8%',
            textAlign: 'center',
          }}
        >
          <span
            style={{
              display: 'inline-block',
              width: '32px',
              height: '32px',
              backgroundColor: '#f5f5f5',
              borderRadius: '16px',
              lineHeight: 2.3,
            }}
          >
            OR
          </span>
        </div>
        <div
          style={{
            width: '46%',
            marginBottom: '0.5em',
          }}
        >
          <h2 style={{ textAlign: 'center' }}>Register</h2>
          <div
            style={{
              border: '1px solid rgba(0,0,0,.2)',
              borderRadius: '4px',
              padding: '1em',
            }}
          >
            <RegisterFormContainer
              prefilledEmail={invitationData.email}
              noLoginButtonAfterSuccess
            />
          </div>
        </div>
      </div>
      <DebugInfo inviteEmail={invitationData.email} user={user} />
    </div>
  );
};

export default UserInviteConfirmation;
