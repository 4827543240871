import type { Role } from '../reducers/RoleReducer';

//
//
export type RoleGetListAction = {
  type: 'ROLE_GET_LIST';
  appId: number | null | undefined;
};

export type RoleGetListOkAction = {
  type: 'ROLE_GET_LIST_OK';
  roles: Array<Role>;
  appId: number | null | undefined;
};

//
//
export type RoleGetDetailsAction = {
  type: 'ROLE_GET_DETAILS';
  roleId: number;
};

export type RoleGetDetailsOkAction = {
  type: 'ROLE_GET_DETAILS_OK';
  role: Role;
};

//
//
export type RoleCreateAction = {
  type: 'ROLE_CREATE';
};

export type RoleCreateOkAction = {
  type: 'ROLE_CREATE_OK';
  role: Role;
};

//
//
export type RoleUpdateAction = {
  type: 'ROLE_UPDATE';
  roleId: number;
};

export type RoleUpdateOkAction = {
  type: 'ROLE_UPDATE_OK';
  role: Role;
};

//
//
export type RoleRemoveAction = {
  type: 'ROLE_REMOVE';
  roleId: number;
};

export type RoleRemoveOkAction = {
  type: 'ROLE_REMOVE_OK';
  roleId: number;
  appId: number | null;
};

//
//
export type RoleFailAction = {
  type:
    | 'ROLE_GET_LIST_FAIL'
    | 'ROLE_GET_DETAILS_FAIL'
    | 'ROLE_CREATE_FAIL'
    | 'ROLE_UPDATE_FAIL'
    | 'ROLE_REMOVE_FAIL';
  payload: string;
};

//
//
export const ROLE_GET_LIST = 'ROLE_GET_LIST';
export const ROLE_GET_LIST_OK = 'ROLE_GET_LIST_OK';
export const ROLE_GET_LIST_FAIL = 'ROLE_GET_LIST_FAIL';

export const ROLE_GET_DETAILS = 'ROLE_GET_DETAILS';
export const ROLE_GET_DETAILS_OK = 'ROLE_GET_DETAILS_OK';
export const ROLE_GET_DETAILS_FAIL = 'ROLE_GET_DETAILS_FAIL';

export const ROLE_CREATE = 'ROLE_CREATE';
export const ROLE_CREATE_OK = 'ROLE_CREATE_OK';
export const ROLE_CREATE_FAIL = 'ROLE_CREATE_FAIL';

export const ROLE_UPDATE = 'ROLE_UPDATE';
export const ROLE_UPDATE_OK = 'ROLE_UPDATE_OK';
export const ROLE_UPDATE_FAIL = 'ROLE_UPDATE_FAIL';

export const ROLE_REMOVE = 'ROLE_REMOVE';
export const ROLE_REMOVE_OK = 'ROLE_REMOVE_OK';
export const ROLE_REMOVE_FAIL = 'ROLE_REMOVE_FAIL';
