import { SYNC_COUNTER_INCREMENT, SYNC_COUNTER_DECREMENT, SYNC_ERROR } from '../constants/syncing';

import type { AnyAction } from 'redux';

const initialState = {
  count: 0,
  errors: [],
};

export type SyncingError = {
  id: string;
  message: string;
};

export type SyncingState = {
  count: number;
  errors: Array<SyncingError>;
};

//
//
const reducer = (state = initialState, action: AnyAction): SyncingState => {
  switch (action.type) {
    case SYNC_COUNTER_INCREMENT:
      return {
        ...state,
        count: state.count + 1,
      };

    case SYNC_COUNTER_DECREMENT:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
      };

    case SYNC_ERROR:
      return {
        ...state,
        errors: [...state.errors, action.error],
      };

    default:
      return state;
  }
};

export default reducer;
