export enum ReceiptType {
  GOOGLE_PLAY_STORE = 100,
  APPLE_STOREKIT_1 = 200,
  ACCOUNT_PROXY = 300,
}

export enum ReceiptTypeName {
  GOOGLE_PLAY_STORE = 'GooglePlayStore',
  APPLE_STOREKIT_1 = 'Apple-StoreKit-1',
  ACCOUNT_PROXY = 'AccountProxy',
}

export const ReceiptTypeToName = {
  [ReceiptType.GOOGLE_PLAY_STORE]: ReceiptTypeName.GOOGLE_PLAY_STORE,
  [ReceiptType.APPLE_STOREKIT_1]: ReceiptTypeName.APPLE_STOREKIT_1,
  [ReceiptType.ACCOUNT_PROXY]: ReceiptTypeName.ACCOUNT_PROXY,
} as const;

export const VALID_RECEIPT_TYPES = Object.values(ReceiptType)
  .filter((key) => Number.isInteger(key))
  .map((key) => key as ReceiptType);

export const VALID_RECEIPT_TYPE_NAMES = Object.values(ReceiptTypeName).map((key) => key);

export const CREATABLE_RECEIPT_TYPE_VALIDATORS = [
  ReceiptType.GOOGLE_PLAY_STORE,
  ReceiptType.APPLE_STOREKIT_1,
];

//
//

export type GooglePlayStoreReceiptData = {
  purchaseToken: string;
};

export type GooglePlayStoreReceipt = {
  type: ReceiptTypeName.GOOGLE_PLAY_STORE;
  data: GooglePlayStoreReceiptData;
};

export function isGooglePlayStoreReceiptData(receipt: unknown): receipt is GooglePlayStoreReceipt {
  return (
    receipt != null &&
    (receipt as GooglePlayStoreReceipt).type === ReceiptTypeName.GOOGLE_PLAY_STORE &&
    typeof (receipt as GooglePlayStoreReceipt).data?.purchaseToken === 'string'
  );
}

//
//

export type AppleStoreKit1ReceiptData = {
  receiptData: string;
};

export type AppleStoreKit1Receipt = {
  type: ReceiptTypeName.APPLE_STOREKIT_1;
  data: AppleStoreKit1ReceiptData;
};

export function isAppleStoreKit1ReceiptData(receipt: unknown): receipt is AppleStoreKit1Receipt {
  return (
    receipt != null &&
    (receipt as AppleStoreKit1Receipt).type === ReceiptTypeName.APPLE_STOREKIT_1 &&
    typeof (receipt as AppleStoreKit1Receipt).data?.receiptData === 'string'
  );
}

//
//

export type AccountProxyReceiptData = {
  service: string;
  client: {
    name: string;
    platform: string;
    version: string;
  };
  device: {
    id: string;
    name: string;
  };
  credentials: {
    username: string;
    password: string;
  };
};

export type AccountProxyReceipt = {
  type: ReceiptTypeName.ACCOUNT_PROXY;
  data: AccountProxyReceiptData;
};

export function isAccountProxyReceiptData(receipt: unknown): receipt is AccountProxyReceipt {
  return (
    receipt != null &&
    (receipt as AccountProxyReceipt).type === ReceiptTypeName.ACCOUNT_PROXY &&
    typeof (receipt as AccountProxyReceipt).data?.service === 'string' &&
    typeof (receipt as AccountProxyReceipt).data?.credentials?.username === 'string' &&
    typeof (receipt as AccountProxyReceipt).data?.credentials?.password === 'string'
  );
}
