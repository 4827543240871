import { type Worksheet, type Workbook, type Row, type Cell as ICell } from 'exceljs';
import { Readable } from 'readable-stream';

import { normalizeUnicode } from './lang';
import { EMPTY_ADDITIONAL_INFO } from '../common/constants/app';
import {
  ValidationError,
  LIMIT,
  MASTER_SHEET_NAME,
  IGNORE,
  HEADERS,
} from '../common/constants/batch-upload';
import { getDataFieldKeys } from '../common/utils/app';
import { getTotalFiles } from '../common/utils/batch-upload';
import { InvalidHeaderReason } from '../components/batchUpload/constants';

import type { Metadata, Metadatum, Cell, AdditionalInfo } from '../common/types/batch-upload';

function chunkArrayInGroups<T>(arr: Array<T>, size: number) {
  const result = [];
  let j = 0;
  for (let i = 0; i < Math.ceil(arr.length / size); i++) {
    result[i] = arr.slice(j, j + size);
    j = j + size;
  }
  return result;
}

const rowIsFilledFilter = (cells: Array<Cell>) => {
  return cells.length > 0 && !cells.every(({ value }) => value === null);
};

const toCells = (row?: Row) => {
  if (row == null) {
    return [];
  }
  const values: Array<Cell> = [];
  row.eachCell({ includeEmpty: true }, ({ fullAddress: { address }, value: cellValue }: ICell) => {
    const value = cellValue == null ? null : normalizeUnicode(cellValue.toString());
    values.push({ address, value });
  });
  return values;
};

const toAdditionalInfo = (
  mapping: AdditionalInfo<string>,
  values: Array<Cell>
): AdditionalInfo<Cell> => {
  let valuesIndex = 0;
  return getDataFieldKeys().reduce((all, key) => {
    if (mapping[key] === null) {
      return all;
    }
    all[key] = values.at(valuesIndex) ?? null;
    valuesIndex += 1;
    return all;
  }, EMPTY_ADDITIONAL_INFO<Cell>());
};

const toLanguages = (
  cells: Array<Cell>,
  languages: Array<Array<Cell>>,
  trueHeadersPerLanguage: number,
  mapping: AdditionalInfo<string>
): Metadatum<Cell>['languages'] => {
  return chunkArrayInGroups(cells, trueHeadersPerLanguage).reduce(
    (mappedLanguages, values, index) => {
      if (
        Array.from(Array(trueHeadersPerLanguage).keys()).every(
          (n) => values.at(n)?.value === IGNORE
        )
      ) {
        return mappedLanguages;
      }

      const language = {
        locale: languages.at(index)?.at(0) ?? { address: '', value: null },
        title: values[0],
        thumbnail: values[1],
        video: values[2],
        subtitles: values[3],
        additionalInfo: toAdditionalInfo(mapping, values.slice(4)),
      };

      return [...mappedLanguages, language];
    },
    [] as Metadatum<Cell>['languages']
  );
};

const detectTrueHeadersPerLanguage = (
  languageSubheaders: Array<Cell>
): { reason: InvalidHeaderReason } | { trueHeadersPerLanguage: number } => {
  if (languageSubheaders.length === 0) {
    return { reason: InvalidHeaderReason.NO_LANGUAGES };
  }

  let headerIndex = 0;

  const headersPerLanguage = languageSubheaders.reduce((headers, cell, index) => {
    if (index + 1 === languageSubheaders.length) {
      headers.add(headerIndex + 1);
      return headers;
    }

    const startOfNewLanguage = cell.value === HEADERS.EPISODE_TITLE && headerIndex > 0;

    if (startOfNewLanguage) {
      headers.add(headerIndex);
      headerIndex = 0;
    }

    headerIndex += 1;

    return headers;
  }, new Set<number>());

  if (headersPerLanguage.size !== 1) {
    return { reason: InvalidHeaderReason.DIFFERENT_HEADERS_PER_LANGUAGE };
  }

  const [trueHeadersPerLanguage] = headersPerLanguage;

  if (trueHeadersPerLanguage < 4) {
    return { reason: InvalidHeaderReason.NOT_ENOUGH_COMMON_HEADERS };
  }

  return { trueHeadersPerLanguage };
};

type ValidHeaders = {
  validHeaders: true;
  optionalColumns: AdditionalInfo<string>;
  mapper: (cells: Array<Cell>) => Metadatum<Cell>;
  isCreate?: true;
};

export const validateHeaders = (
  titles: Array<Cell>,
  subheaders: Array<Cell>,
  dataFieldNames: AdditionalInfo<string>
): { validHeaders: false; reason: InvalidHeaderReason } | ValidHeaders => {
  const validFirstTitle = titles.slice(0, 2).every(({ value }) => value === HEADERS.SHEET_TITLE);

  if (!validFirstTitle) {
    return { validHeaders: false, reason: InvalidHeaderReason.INVALID_TITLE };
  }

  const validCommonTitles =
    subheaders.at(0)?.value === HEADERS.NAME &&
    ((subheaders.at(1)?.value === HEADERS.RESOURCE_TYPE &&
      subheaders.at(2)?.value === HEADERS.TAGS) ||
      (subheaders.at(1)?.value === HEADERS.TAGS &&
        subheaders.at(2)?.value === HEADERS.EPISODE_TITLE));

  if (!validCommonTitles) {
    return { validHeaders: false, reason: InvalidHeaderReason.INVALID_COMMON_TITLES };
  }

  const isCreate = subheaders.at(1)?.value === HEADERS.RESOURCE_TYPE ? true : undefined;
  const offset = isCreate ? 3 : 2;
  const languages = titles.slice(offset).filter(({ value }) => !!value);
  const languageSubheaders = subheaders.slice(offset).filter(({ value }) => !!value);
  const detectionResult = detectTrueHeadersPerLanguage(languageSubheaders);

  if ('reason' in detectionResult) {
    return { validHeaders: false, ...detectionResult };
  }

  const { trueHeadersPerLanguage } = detectionResult;

  const languageTitles = chunkArrayInGroups(languages, trueHeadersPerLanguage);
  const validLanguages = languageTitles.every((cells) =>
    cells.every(({ value }) => value === cells.at(0)?.value)
  );

  if (!validLanguages) {
    return { validHeaders: false, reason: InvalidHeaderReason.INVALID_LANGUAGE_TITLE_GROUP };
  }

  const groupedLanguages = chunkArrayInGroups(languageSubheaders, trueHeadersPerLanguage);
  const validCommonHeaders = groupedLanguages.every(
    (cells) =>
      cells.at(0)?.value === HEADERS.EPISODE_TITLE &&
      cells.at(1)?.value === HEADERS.THUMBNAIL &&
      cells.at(2)?.value === HEADERS.VIDEO &&
      cells.at(3)?.value === HEADERS.SUBTITLES
  );

  if (!validCommonHeaders) {
    return { validHeaders: false, reason: InvalidHeaderReason.INVALID_COMMON_HEADERS };
  }

  const dataFieldNameValues = Object.values(dataFieldNames);
  const additionalInfo = groupedLanguages.map((cells) =>
    cells
      .slice(4)
      .map(({ value }) => value)
      .filter((value): value is string => !!value)
  );

  console.log({ dataFieldNameValues, additionalInfo });

  const validForConfig = additionalInfo.every((info) => {
    return info.every((value) => dataFieldNameValues.includes(value));
  });

  if (!validForConfig) {
    return { validHeaders: false, reason: InvalidHeaderReason.NOT_VALID_FOR_APP_CONFIG };
  }

  const correctOrder = (a: string, b: string) =>
    dataFieldNameValues.indexOf(a) - dataFieldNameValues.indexOf(b);
  const inAppConfigOrder = additionalInfo.every((info) => {
    const sorted = [...info].sort(correctOrder);
    return info.toString() === sorted.toString();
  });

  if (!inAppConfigOrder) {
    return { validHeaders: false, reason: InvalidHeaderReason.NOT_IN_APP_CONFIG_ORDER };
  }

  const otherHeaders = new Map<number, string>();
  const sameAsOtherLanguages = additionalInfo.every((info, index) => {
    otherHeaders.set(index, info.toString());
    return Array.from(Array(index).keys()).every(
      (previousLanguage) => otherHeaders.get(previousLanguage) === info.toString()
    );
  });

  if (!sameAsOtherLanguages) {
    return { validHeaders: false, reason: InvalidHeaderReason.DIFFERENT_ORDER_PER_LANGUAGE };
  }

  const optionalColumns = getDataFieldKeys().reduce(
    (columns, key) => {
      columns[key] = additionalInfo.at(0)?.find((info) => info === dataFieldNames[key]) ?? null;
      return columns;
    },
    { ...dataFieldNames }
  );

  const totalLanguages = languages.length / trueHeadersPerLanguage;

  const mapper = (cells: Array<Cell>): Metadatum<Cell> => {
    return {
      internalName: cells[0],
      resourceType: isCreate ? cells[1] : null,
      tags: isCreate ? cells[2] : cells[1],
      languages: toLanguages(
        cells.slice(offset, offset + totalLanguages * trueHeadersPerLanguage),
        languageTitles,
        trueHeadersPerLanguage,
        optionalColumns
      ),
    };
  };

  return {
    validHeaders: true,
    optionalColumns,
    isCreate,
    mapper,
  };
};

const parse = async (
  sheet: Worksheet,
  dataFieldNames: AdditionalInfo<string>
): Promise<{ issue: ValidationError } | { metadata: Metadata }> => {
  const headers = sheet.getRows(1, 2) ?? [];
  const titles = toCells(headers.at(0));
  const subheaders = toCells(headers.at(1));

  const parsedHeaders = validateHeaders(titles, subheaders, dataFieldNames);
  console.log({ titles, subheaders, dataFieldNames, parsedHeaders });

  if (!parsedHeaders.validHeaders) {
    return { issue: ValidationError.SHEET_TEMPLATE_MODIFIED };
  }

  const { optionalColumns, isCreate, mapper } = parsedHeaders;

  const x = sheet.getRows(3, sheet.rowCount) ?? [];
  const cellsPerRow = x.map(toCells).filter((arr) => arr.length > 0);

  // if "last" cells in row are empty, there are items missing in cells array -> fill with empty cells
  const maxCells = cellsPerRow.reduce((acc, current) => Math.max(acc, current.length), 0);
  const maxLengthRow = cellsPerRow.find((cells) => cells.length === maxCells);
  // should not happen - make TS happy
  if (maxLengthRow == null) {
    throw new Error('no row with max length found');
  }
  cellsPerRow.forEach((cells) => {
    const rowNumber = Number(cells[0].address.replace(/^\D+/g, ''));
    while (cells.length < maxCells) {
      const char = maxLengthRow[cells.length].address.replace(/\d+$/g, '');
      cells.push({ address: `${char}${rowNumber}`, value: null });
    }
  });

  const rows = cellsPerRow.filter(rowIsFilledFilter).map(mapper);

  if (getTotalFiles(rows) > LIMIT) {
    return { issue: ValidationError.LIMIT_EXCEEDED };
  }

  return { metadata: { isCreate, rows, optionalColumns } };
};

export const setupParser = (
  wb: Workbook,
  preProcess: (input: File | ArrayBuffer) => Promise<{ buffer: ArrayBuffer; delimiter: string }>
) => {
  const parseXLSX = async (i: File | ArrayBuffer, dataFieldNames: AdditionalInfo<string>) => {
    const { buffer } = await preProcess(i);
    const { worksheets } = await wb.xlsx.load(buffer);
    const sheet = worksheets.find(({ name }: { name: string }) => name === MASTER_SHEET_NAME);
    if (sheet == null) {
      return { issue: ValidationError.SHEET_TEMPLATE_MODIFIED };
    }
    return parse(sheet, dataFieldNames);
  };
  const parseCSV = async (i: File | ArrayBuffer, dataFieldNames: AdditionalInfo<string>) => {
    const { buffer, delimiter } = await preProcess(i);

    const readable = new Readable();
    readable._read = () => {}; // _read is required
    readable.push(new Uint8Array(buffer));
    readable.push(null);

    return parse(
      await wb.csv.read(readable, {
        parserOptions: { delimiter },
      }),
      dataFieldNames
    );
  };

  return { parseXLSX, parseCSV };
};
