import { message, Alert } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getAppDetails, updateAppConfig, removeUserFromApp } from '../actions/apps';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_USERDETAIL } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import AppSettings from '../pages/AppSettings';
import { useAppDispatch } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { Configuration } from '../common/types/app';

//
//
const AppSettingsContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { currentApp } = useCurrentAppContext();
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAppDetails(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
        setError(() => msg);
      }
    };

    if (currentApp.id !== -1) {
      fetchData();
    }
  }, [dispatch, currentApp.id]);

  const handleSubmitConfig = async (config: Configuration) => {
    console.log('handleSubmitConfig()', config);
    try {
      await dispatch(updateAppConfig(currentApp.id, config));
      message.success('project config updated');
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleUserRemove = async (userId: number) => {
    console.log('handleUserRemove()', userId);
    try {
      await dispatch(removeUserFromApp(currentApp.id, userId));
      message.success('user removed from project');
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleUserOpenDetails = (userId: number) => {
    history.push(
      getUrlByName(ROUTE_NAME_APP_USERDETAIL, { ':appUid': currentApp.uid, ':userId': userId })
    );
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <AppSettings
      details={currentApp}
      handleSubmitConfiguration={handleSubmitConfig}
      handleUserRemove={handleUserRemove}
      handleUserOpenDetails={handleUserOpenDetails}
    />
  );
};

export default AppSettingsContainer;
