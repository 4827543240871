import { useState } from 'react';

import styles from './FilePicker.module.css';

import type { DragEvent } from 'react';

type Props = Readonly<{
  setFiles: (files: Array<File>) => void;
  content: () => JSX.Element;
  accepted: Array<string> | '*';
  disabled?: boolean;
  oneFile?: boolean;
}>;

enum DragState {
  DRAGGING_OKAY,
  DRAGGING_NOT_OKAY,
  NOT_DRAGGING,
}

const FilePicker = ({ setFiles, content, accepted, oneFile, disabled }: Props) => {
  const [dragState, setDragState] = useState(DragState.NOT_DRAGGING);

  const dragClass = () => {
    switch (dragState) {
      case DragState.DRAGGING_OKAY:
        return styles.draggingOkay;
      case DragState.DRAGGING_NOT_OKAY:
        return styles.draggingNotOkay;
      default:
        return '';
    }
  };

  const checkFileList = (e: DragEvent<HTMLDivElement>) => {
    const mimeTypes = Array.from(e.dataTransfer?.items ?? []);
    const noFiles = mimeTypes.length === 0;
    const tooMany = oneFile === true && mimeTypes.length > 1;
    const notAccepted =
      accepted === '*' ? false : mimeTypes.filter(({ type }) => !accepted.includes(type)).length;
    return !(noFiles || tooMany || notAccepted);
  };

  const handle = (callback: (e: DragEvent<HTMLDivElement>) => void | Promise<void>) => {
    return (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (disabled) {
        return;
      }

      callback(e);
    };
  };

  const onDrag = (e: DragEvent<HTMLDivElement>) => {
    setDragState(checkFileList(e) ? DragState.DRAGGING_OKAY : DragState.DRAGGING_NOT_OKAY);
  };

  const onDrop = async (e: DragEvent<HTMLDivElement>) => {
    setDragState(DragState.NOT_DRAGGING);
    if (checkFileList(e)) {
      setFiles(Array.from(e.dataTransfer.files));
    }
  };

  return (
    <div
      style={{
        borderRadius: 10,
        border: `1px ${disabled ? 'solid' : 'dashed'} lightgrey`,
        padding: 20,
        marginTop: 20,
        marginBottom: 20,
      }}
      onDragEnter={handle(onDrag)}
      onDragOver={handle(onDrag)}
      onDrop={handle(onDrop)}
      onDragLeave={handle(() => setDragState(DragState.NOT_DRAGGING))}
      className={dragClass()}
    >
      {content()}
    </div>
  );
};

export default FilePicker;
