import { schema, normalize } from 'normalizr';

import {
  TIMEZONE_GET_LIST,
  TIMEZONE_GET_LIST_OK,
  TIMEZONE_GET_LIST_FAIL,
} from '../constants/timezones';
import { TimezoneSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const getTimezoneList = () => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/timezones`,
    },
    dispatch,
    {
      init: TIMEZONE_GET_LIST,
      fail: TIMEZONE_GET_LIST_FAIL,
    }
  );
  const normalizedData = normalize(response.data, new schema.Array(TimezoneSchema));
  dispatch({ type: TIMEZONE_GET_LIST_OK, payload: normalizedData });
  return normalizedData;
};
