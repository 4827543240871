import { message } from 'antd';
import { useEffect, useState } from 'react';

import { getSyncingCount } from '../selectors/syncingSelectors';
import { useGlobalSelector } from '../utils/hooks';

//
//
const SyncingStatusContainer = () => {
  const count = useGlobalSelector(getSyncingCount);
  const [messageIsLoading, setMessageIsLoading] = useState<null | (() => void)>(null);

  useEffect(() => {
    if (count === 0 && typeof messageIsLoading === 'function') {
      messageIsLoading();
      setMessageIsLoading(null);
    } else if (count > 0 && messageIsLoading === null) {
      const closeMessage = message.loading('syncing ...', 0);
      setMessageIsLoading(() => closeMessage);
    }
  }, [messageIsLoading, count]);

  return null;
};

export default SyncingStatusContainer;
