import { Spin } from 'antd';
import classNames from 'classnames';

import GraphAndTable from './analytics/GraphAndTable';
import PieAndTable from './analytics/PieAndTable';
import styles from './AnalyticsApp.module.scss';
import { getAppAnalyticsById } from '../selectors/appAnalyticsSelectors';
import { TrendTypes, getTrendString, getTrendType } from '../utils/analytics';
import { useGlobalSelector } from '../utils/hooks';

import type { GraphAndTableData } from './analytics/GraphAndTable';
import type { FunctionComponent } from 'react';

type Props = {
  currentAppId: number;
};

const AnalyticsAppAllTime: FunctionComponent<Props> = ({ currentAppId }) => {
  const appAnalytics = useGlobalSelector((state) => getAppAnalyticsById(state, currentAppId));

  const last28Days = appAnalytics?.last28Days;

  const graphAndTableData: GraphAndTableData = {
    days: last28Days?.days ?? [],
    languages:
      last28Days?.languages
        .map((language) => ({
          name: language.name,
          total: null,
          views: language.views,
          trend: language.trend,
          days: language.days,
        }))
        .sort((languageA, languageB) => languageB.views - languageA.views) ?? [],
  };

  return (
    <div
      className={classNames(styles.box, styles.boxLast28Days)}
      data-tid="analytics-last-28-days-box"
    >
      <h3 className={styles.headline}>Views (last 28 days)</h3>
      {last28Days == null && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
          <Spin />
        </div>
      )}
      {last28Days != null && (
        <>
          <div className={styles.boxLast28DaysContainer}>
            <div className={styles.boxStatsContainer} style={{ minWidth: '230px' }}>
              <div className={classNames(styles.greyBox, styles.boxTotalTrendContainer)}>
                <span>{last28Days.total.toLocaleString()}</span>
                {last28Days.trend != null && last28Days.trend !== 0 && (
                  <div
                    data-tid={`analytics-last-28-days-box-trend-color-${getTrendType(
                      last28Days.trend
                    )}`}
                  >
                    <span
                      className={classNames(styles.boxTotalTrend, {
                        [styles.boxTotalTrendUp]: getTrendType(last28Days.trend) === TrendTypes.UP,
                        [styles.boxTotalTrendDown]:
                          getTrendType(last28Days.trend) === TrendTypes.DOWN,
                      })}
                    >
                      {getTrendString(last28Days.trend)}
                    </span>
                  </div>
                )}
              </div>
              <PieAndTable pieAndTableData={last28Days} testIdPrefix="analytics-last-28-days-box" />
            </div>
            <div className={styles.separator} />
            <div className={styles.graphAndTableContainer}>
              <GraphAndTable graphTitle="Views per day" graphAndTableData={graphAndTableData} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AnalyticsAppAllTime;
