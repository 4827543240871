import { createSelector } from 'reselect';

import { getAppPlatformByIdFunc } from './appPlatformSelectors';
import { getAppVersionByIdFunc } from './appVersionSelectors';
import { getEmptyEpisodeVersionRequirement } from '../reducers/EpisodeVersionRequirementReducer';

import type { RootState } from '../reducers';
import type { EpisodeVersionRequirement } from '../reducers/EpisodeVersionRequirementReducer';

//
//
const getById = (state: RootState) => state.episodeVersionRequirements;

//
//
export const getEpisodeVersionRequirementByIdFunc = createSelector(
  getById,
  getAppPlatformByIdFunc,
  getAppVersionByIdFunc,
  //
  (byId, $getAppPlatformById, $getAppVersionById) => {
    return (id: number) => {
      if (id === null) {
        return null;
      }

      const evrNormalized = byId[id];
      if (evrNormalized == null) {
        return getEmptyEpisodeVersionRequirement(id);
      }

      const evr: EpisodeVersionRequirement = {
        ...evrNormalized,
        appPlatform: $getAppPlatformById(evrNormalized.appPlatform),
        minVersion: $getAppVersionById(evrNormalized.minVersion),
        maxVersion: $getAppVersionById(evrNormalized.maxVersion),
      };
      return evr;
    };
  }
);

//
//
export const getEpisodeVersionRequirementsByIdsFunc = createSelector(
  getEpisodeVersionRequirementByIdFunc,
  //
  ($getEpisodeVersionRequirementById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }

    return ids
      .map((id) => $getEpisodeVersionRequirementById(id))
      .filter((evr): evr is EpisodeVersionRequirement => !!evr);
  }
);
