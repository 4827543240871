import { Tag } from 'antd';
import classNames from 'classnames';

import styles from './MbxTag.module.scss';

import type { TagProps } from 'antd';

type Props = Readonly<TagProps & { size?: 'large' | 'small' }>;

const MbxTag = (props: Props) => {
  const { children, className, size = 'small', ...otherTagProps } = props;
  return (
    <Tag className={classNames([styles.mbxTag, size, className])} {...otherTagProps}>
      {children}
    </Tag>
  );
};

export default MbxTag;
