import { hash } from 'immutable';

type Props = Readonly<{
  step: number;
  items: Array<StepItem>;
}>;

export type StepItem = {
  name: string;
};

const Stepper = ({ items, step: currentStep }: Props) => {
  return (
    <div>
      {items.map(({ name }, index) => {
        const step = index + 1;
        const fontWeight = currentStep === step ? 'bold' : 'normal';
        return (
          <span style={{ fontWeight, padding: 4 }} key={hash(name)}>
            {step}. {name}
          </span>
        );
      })}
    </div>
  );
};

export default Stepper;
