const VIDEO_TEMPLATE_H265_HD_1_1 = 'h265_hd_1-1';
const VIDEO_TEMPLATE_H265_SD_1_1 = 'h265_sd_1-1';
const VIDEO_TEMPLATE_H265_HD_1_0 = 'h265_hd_1-0';
const VIDEO_TEMPLATE_H264_HD_1_1 = 'h264_hd_1-1';
const VIDEO_TEMPLATE_H264_HD_1_0 = 'h264_hd_1-0';
const VIDEO_TEMPLATE_HD_1_0 = 'hd_1-0';
const VIDEO_TEMPLATE_SD_1_0 = 'sd_1-0';
const VIDEO_TEMPLATE_H264_HLS_1_0 = 'h264_hls_1-0';

export const VideoTemplate = {
  VIDEO_TEMPLATE_H265_HD_1_1,
  VIDEO_TEMPLATE_H265_SD_1_1,
  VIDEO_TEMPLATE_H265_HD_1_0,
  VIDEO_TEMPLATE_H264_HD_1_1,
  VIDEO_TEMPLATE_H264_HD_1_0,
  VIDEO_TEMPLATE_HD_1_0,
  VIDEO_TEMPLATE_SD_1_0,
  VIDEO_TEMPLATE_H264_HLS_1_0,
} as const;

const VALID_VIDEO_TEMPLATES = Object.keys(VideoTemplate).map(
  (key) => VideoTemplate[key as keyof typeof VideoTemplate]
);

export type AllowedVideoTemplateName = (typeof VALID_VIDEO_TEMPLATES)[number];

export const VIDEO_VERSION_ORDER = [
  VIDEO_TEMPLATE_H265_HD_1_1,
  VIDEO_TEMPLATE_H265_SD_1_1,
  VIDEO_TEMPLATE_H264_HD_1_1,
  VIDEO_TEMPLATE_H264_HD_1_0,
  // b/c old H.265 from AWS MediaConvert isn't running on iOS devices, h265_hd_1-0 is ignored for now
  // VIDEO_TEMPLATE_H265_HD_1_0,
  VIDEO_TEMPLATE_HD_1_0,
  VIDEO_TEMPLATE_SD_1_0,
  VIDEO_TEMPLATE_H264_HLS_1_0,
] as const;

export type AllowedOrderedVideoTemplateName = (typeof VIDEO_VERSION_ORDER)[number];

export const VIDEO_VERSION_LABELS = {
  [VIDEO_TEMPLATE_H265_HD_1_1]: 'HD 1.1 (H.265)',
  [VIDEO_TEMPLATE_H265_SD_1_1]: 'SD 1.1 (H.265)',
  [VIDEO_TEMPLATE_H264_HD_1_1]: 'HD 1.1 (H.264)',
  [VIDEO_TEMPLATE_H264_HD_1_0]: 'HD 1.0 (H.264)',
  [VIDEO_TEMPLATE_H265_HD_1_0]: 'HD 1.0 (H.265) (deprecated)',
  [VIDEO_TEMPLATE_HD_1_0]: 'HD 1.0 (deprecated)',
  [VIDEO_TEMPLATE_SD_1_0]: 'SD 1.0 (deprecated)',
  [VIDEO_TEMPLATE_H264_HLS_1_0]: 'HLS 1.0 (H.264 HD+SD, AAC)',
} as const;

/**
 * @function
 * @returns {label is AllowedVideoTemplateName}
 */
export const isVideoTemplateName = function isVideoTemplateName(label: string) {
  return Object.keys(VIDEO_VERSION_LABELS).includes(label);
};
