import { Alert, message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import { getDeploymentList, deleteDeployment } from '../actions/deployments';
import { getRegionList } from '../actions/regions';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { useCurrentAppContext } from '../contexts';
import {
  ROUTE_NAME_APP_DEPLOYMENTCREATE,
  ROUTE_NAME_APP_DEPLOYMENTDETAIL,
  ROUTE_NAME_APP_DEPLOYMENTS,
} from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import DeploymentList from '../pages/DeploymentList';
import { getAllDeploymentForApp } from '../selectors/deploymentSelectors';
import { getAllRegionsForApp } from '../selectors/regions/advanced';
import { getPageFromSearch } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { Deployment } from '../../typings/deployments';

const ITEMS_PER_PAGE = 25;

//
//
const DeploymentListContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { currentApp } = useCurrentAppContext();

  const currentPage = getPageFromSearch(history.location.search);
  const allRegions = useGlobalSelector((state) => getAllRegionsForApp(state, currentApp.id));
  const allDeployments = useGlobalSelector((state) => getAllDeploymentForApp(state, currentApp.id));

  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      console.log('DeploymentListContainer.fetchData()');
      try {
        dispatch(counterIncrement());
        await dispatch(getDeploymentList(currentApp.id));
        // we only need this for the modal, so do it last
        await dispatch(getRegionList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentApp.id]);

  const handleDeploymentDelete = async (deployment: Deployment) => {
    try {
      await dispatch(deleteDeployment(deployment));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleDeploymentCreate = () => {
    history.push(
      getUrlByName(ROUTE_NAME_APP_DEPLOYMENTCREATE, {
        ':appUid': currentApp.uid,
      })
    );
  };

  const handleDeploymentDetail = (deploymentId: number) => {
    history.push(
      getUrlByName(ROUTE_NAME_APP_DEPLOYMENTDETAIL, {
        ':appUid': currentApp.uid,
        ':deploymentId': deploymentId,
      })
    );
  };

  const handlePaginationChange = (page: number) => {
    history.push(
      `${getUrlByName(ROUTE_NAME_APP_DEPLOYMENTS, {
        ':appUid': currentApp.uid,
      })}?page=${page}`
    );
  };

  const firstIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentPageItems = allDeployments.slice(firstIndex, firstIndex + ITEMS_PER_PAGE);
  const maxPage = Math.ceil(allDeployments.length / ITEMS_PER_PAGE);

  if (maxPage > 0 && currentPage > maxPage) {
    return (
      <Redirect
        to={`${getUrlByName(ROUTE_NAME_APP_DEPLOYMENTS, {
          ':appUid': currentApp.uid,
        })}?page=${maxPage}`}
      />
    );
  }
  if (currentPage <= 0) {
    return (
      <Redirect
        to={getUrlByName(ROUTE_NAME_APP_DEPLOYMENTS, {
          ':appUid': currentApp.uid,
        })}
      />
    );
  }

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <DeploymentList
      regions={allRegions}
      currentPageItems={currentPageItems}
      itemsPerPage={ITEMS_PER_PAGE}
      currentPage={currentPage}
      totalItems={allDeployments.length}
      handleDeploymentCreate={handleDeploymentCreate}
      handlePaginationChange={handlePaginationChange}
      deleteDeployment={handleDeploymentDelete}
      openDeploymentDetail={handleDeploymentDetail}
    />
  );
};

export default DeploymentListContainer;
