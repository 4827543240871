import { UploadOutlined, CloseOutlined, RedoOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, Upload, Button, message, Tooltip } from 'antd';
import { fromBlob } from 'file-type/browser';
import { useState, useEffect } from 'react';

import MbxButton from './base/MbxButton';
import styles from './EpisodeLocalizedAddModal.module.scss';
import EpisodeContentPreview from './EpisodeLocalizedContentPreview';
import EpisodeThumbnail from './EpisodeLocalizedThumbnail';
import TextareaWithCounter from './TextareaWithCounter';
import { MAX_EPISODE_LOCALIZED_DATA_FIELDS } from '../common/constants/app';
import { ContentType, ContentTypeNames } from '../common/constants/content-type';
import { ResourceType } from '../common/constants/resource-type';
import { MAX_UPLOAD_SIZE } from '../common/constants/upload';
import { getValueFromKey, isValidEpisodeLocalizedDataAtrtibute } from '../common/utils/app';
import { humanFileSize } from '../common/utils/bytes';
import {
  getAcceptedUploadTypes,
  mimeTypeExceptions,
  getAllowedContentTypesForResourceType,
} from '../common/utils/upload';
import { getAllQueueItems } from '../selectors/queueSelectors';
import { formatDate } from '../utils/date';
import { getResourceByContentType, getContentByContentType } from '../utils/episodeLocalized';
import { useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';
import { getFileUploadStatus } from '../utils/queue';
import { checkForSoftHyphens } from '../utils/text';

import type {
  AllowedContentType,
  ContentTypesWithThumbnailPreview,
} from '../common/constants/content-type';
import type { App } from '../reducers/AppsReducer';
import type { AllowedContentTypes } from '../reducers/EpisodeContentReducer';
import type { EpisodeLocalized } from '../reducers/EpisodeLocalizedReducer';
import type { Episode } from '../reducers/EpisodeReducer';

export interface CustomEventTarget extends EventTarget {
  result: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export type EpisodeLocalizedFormValues = {
  name: string;
  data1: string;
  data2: string;
  data3: string;
  data4: string;
  data5: string;
};

type Props = Readonly<{
  data: EpisodeLocalized | null | undefined;
  episode: Episode;
  app: App;
  canUserRerunConversion: boolean;

  onSubmit: (
    data: EpisodeLocalized | null | undefined,
    values: EpisodeLocalizedFormValues
  ) => Promise<void>;
  onFileSelect: (
    fileRef: File,
    resourceType: number,
    contentType: AllowedContentTypes,
    episodeLocalizedId: number,
    appPlatformId: number | null
  ) => Promise<void>;
  onContentRemove: (episodelocalizedId: number, contentId: number) => Promise<boolean>;
  onRerunConversion: (resourceId: number) => Promise<void>;
}>;

//
//
const EpisodeLocalizedForm = (props: Props) => {
  const {
    data,
    episode,
    canUserRerunConversion,
    app,
    onContentRemove,
    onFileSelect,
    onSubmit,
    onRerunConversion,
  } = props;
  const [form] = Form.useForm();

  const [isTriggeringRerun, setIsTriggeringRerun] = useState(false);
  const [draggingState, setDraggingState] = useState<{ [k: string]: boolean }>({});
  const [isPreparingUpload, setIsPreparingUpload] = useState<{ [k: string]: boolean }>({});

  const [wrongFormatError, setWrongFormatError] = useState<{ [x: string]: string }>({});

  const queuedItems = useGlobalSelector(getAllQueueItems);

  const episodeResourceType = episode?.resourceTypeId ?? null;
  const appConfig = app?.configuration ?? [];

  const [hyphensData, setHyphensData] = useState<{
    detected: boolean;
    parsedString: Array<string>;
  }>({
    detected: false,
    parsedString: [],
  });

  useEffect(() => {
    form.setFieldsValue({
      name: data?.name ?? '',
      data1: data?.data1 ?? '',
      data2: data?.data2 ?? '',
      data3: data?.data3 ?? '',
      data4: data?.data4 ?? '',
      data5: data?.data5 ?? '',
    });
    setHyphensData(checkForSoftHyphens(data?.name ?? ''));
  }, [data, form]);

  const handleTitleChange = (val: string) => {
    setHyphensData(checkForSoftHyphens(val));
    form.setFieldsValue({ name: val });
  };

  const handleOk = async (values: EpisodeLocalizedFormValues) => {
    console.log('Received values of form: ', values);
    await onSubmit(data, values);
  };

  const handleFileSelect = (
    key: string,
    file: File,
    contentType: AllowedContentType,
    appPlatformId: number | null
  ) => {
    console.log('handleFileSelect()', file, contentType, appPlatformId);

    if (wrongFormatError[key] != null) {
      const newErrorVal = { ...wrongFormatError };
      delete newErrorVal[key];
      setWrongFormatError(newErrorVal);
    }

    if (file.size === 0) {
      setWrongFormatError({
        ...wrongFormatError,
        [key]: `The file you selected has a size of 0 KB`,
      });
      console.error('selected file that has a size of 0', file.type);
      return false;
    }

    if (data == null) {
      throw new Error('handleFileSelect(): data is null');
    }

    let fileResourceType;
    const ert = episode?.resourceTypeId ?? null;
    const fileTypes = getAcceptedUploadTypes(contentType, ert);

    if (fileTypes == null) {
      throw new Error('unknown combination of contentType and resourceType');
    }

    const exceptionData = mimeTypeExceptions.find((ex) => ex.contentType === contentType);

    if (!fileTypes.includes(file.type) && exceptionData == null) {
      setWrongFormatError({
        ...wrongFormatError,
        [key]: `Incorrect mime-type of file: ${
          file.type != '' ? file.type : 'UNKNOWN'
        }. Accepted mime-types: ${fileTypes.join(', ')}`,
      });
      console.error('selected wrong format for contentType', file.type, fileTypes);
      return false;
    }

    if (exceptionData != null) {
      const fileNameSplitted = file.name.split('.');
      const uploadedFileExtension = fileNameSplitted[fileNameSplitted.length - 1];
      if (
        exceptionData.acceptedExtensions[0] !== '*' &&
        exceptionData.acceptedExtensions.includes(uploadedFileExtension) === false
      ) {
        setWrongFormatError({
          ...wrongFormatError,
          [key]: `Incorrect type of file. Accepted extensions: ${exceptionData.acceptedExtensions.join(
            ', '
          )}`,
        });
        return false;
      }

      if (
        exceptionData.maxFileSizeInKb > 0 &&
        Math.round(file.size / 1024) > exceptionData.maxFileSizeInKb
      ) {
        setWrongFormatError({
          ...wrongFormatError,
          [key]: `File is too large for this field. Max file size is ${exceptionData.maxFileSizeInKb} Kb`,
        });
        return false;
      }
    }

    // AWS has a size limit of 5GB for uploads to S3
    if (file.size >= MAX_UPLOAD_SIZE) {
      setWrongFormatError({
        ...wrongFormatError,
        [key]: `The file you selected is too big: ${humanFileSize(
          file.size
        )}. We support a max upload size of ${humanFileSize(MAX_UPLOAD_SIZE)}`,
      });
      return false;
    }

    setIsPreparingUpload((obj) => ({
      ...obj,
      [key]: true,
    }));

    if (
      contentType === ContentType.EPISODE_THUMBNAIL ||
      contentType === ContentType.EPISODE_LAUNCHIMAGE ||
      contentType === ContentType.EPISODE_ARTWORKIMAGE
    ) {
      fileResourceType = ResourceType.IMAGE_MEDIABOX;
    } else if (contentType === ContentType.EPISODE_SUBTITLES) {
      fileResourceType = ResourceType.SUBTITLES_MEDIABOX;
    } else {
      fileResourceType = ert;
    }

    onFileSelect(file, fileResourceType, contentType, data.id, appPlatformId);
    return false;
  };

  const handleContentRemove = async (
    itemKey: string,
    el: EpisodeLocalized,
    contentType: AllowedContentTypes,
    appPlatformId: number | null
  ) => {
    const content = getContentByContentType(el, contentType, appPlatformId);
    console.log('handleContentRemove()', { itemKey, el, contentType, content });
    if (content != null) {
      const result = await onContentRemove(el.id, content.id);
      if (result === true) {
        setIsPreparingUpload((obj) => ({
          ...obj,
          [itemKey]: false,
        }));
      }
    } else {
      console.error('handleContentRemove() error: content not found');
    }
  };

  const handleResourceRerunConversion = async (resourceId: number) => {
    setIsTriggeringRerun(true);
    await onRerunConversion(resourceId);
    setIsTriggeringRerun(false);
  };

  return (
    <>
      <Form onFinish={handleOk} form={form} name="EpisodeLocalizedAddForm" layout="vertical">
        <Form.Item label="Title">
          {/*
            because we have multiple elements in the Form.Item we need another nested Form.item
            see: https://ant.design/components/form/#components-form-demo-complex-form-control
          */}
          <Form.Item
            noStyle
            name="name"
            rules={[{ required: true, message: "Please input the episode's localized name!" }]}
          >
            <Input onChange={(e) => handleTitleChange(e.target.value)} />
          </Form.Item>
          {hyphensData.detected && (
            <label style={{ marginTop: '5px', display: 'block' }}>
              <Tooltip title="Soft hyphens detected in title">
                <InfoCircleOutlined style={{ color: 'geekblue' }} />
                &nbsp;
              </Tooltip>
              <span data-tid="hyphens-info-label" style={{ fontSize: '12px', color: '#999' }}>
                {hyphensData.parsedString.map((str, index) => (
                  <span key={index}>
                    {str}
                    {index !== hyphensData.parsedString.length - 1 && ( // don't add hyphen after last string
                      <span style={{ color: 'red' }}>-</span>
                    )}
                  </span>
                ))}
              </span>
            </label>
          )}
        </Form.Item>

        {/* to create an array from [1, ... MAX_EPISODE_LOCALIZED_DATA_FIELDS ] to iterate over */}
        {Array.from({ length: MAX_EPISODE_LOCALIZED_DATA_FIELDS }, (v, k) => k + 1).map((num) => {
          const dataAttrName = `data${num}`;
          if (!isValidEpisodeLocalizedDataAtrtibute(dataAttrName)) {
            return null;
          }

          const label = getValueFromKey<string>(
            appConfig,
            `episodeData.data${num}Label`,
            `Data${num}`
          );
          const visible = getValueFromKey<boolean>(
            appConfig,
            `episodeData.data${num}Visible`,
            false
          );

          if (visible === false) {
            return null;
          }

          return (
            <TextareaWithCounter
              initialValue={data?.[dataAttrName] ?? ''}
              label={label}
              num={num}
              key={`form-item-data-${num}`}
            />
          );
        })}

        <Form.Item style={{ marginTop: '1em' }}>
          <MbxButton mbxType="primary" htmlType="submit">
            {data == null ? 'Create' : 'Save'}
          </MbxButton>
        </Form.Item>
      </Form>

      {data != null && (
        <Form data-tid="form-upload" layout="vertical">
          {getAllowedContentTypesForResourceType(episode?.resourceTypeId).flatMap(
            (contentType: AllowedContentType, index) => {
              const appPlatformIds = [];
              if (
                episode?.resourceTypeId === ResourceType.ARCHIVE_UNITYBUNDLE &&
                contentType === ContentType.EPISODE_CONTENT
              ) {
                appPlatformIds.push(
                  ...app.platforms.map((ap) => ({ id: ap.id, name: ap.platform?.name ?? '??' }))
                );
              } else {
                appPlatformIds.push(null);
              }

              return appPlatformIds.map((apData) => {
                const currentAppPlatform = apData?.id ?? null;

                let Preview;

                const fileTypes = getAcceptedUploadTypes(contentType, episodeResourceType);
                if (fileTypes == null) {
                  throw new Error('unknown combination of contentType and resourceType');
                }

                const contentUploadAccept: string = fileTypes.join(',');

                const currentResource = getResourceByContentType(
                  data,
                  contentType,
                  currentAppPlatform
                );
                const resourceInfo = getFileUploadStatus(
                  data,
                  queuedItems,
                  contentType,
                  currentAppPlatform
                );

                if (contentType === ContentType.EPISODE_CONTENT) {
                  Preview = (
                    <EpisodeContentPreview
                      le={data}
                      resourceTypeId={episode.resourceTypeId}
                      width={160}
                      height={90}
                      currentAppPlatform={currentAppPlatform}
                    />
                  );
                } else {
                  Preview = (
                    <EpisodeThumbnail
                      le={data}
                      width={160}
                      height={90}
                      thumbnailContentType={contentType as ContentTypesWithThumbnailPreview}
                      currentAppPlatform={currentAppPlatform}
                    />
                  );
                }

                const uploadIsDisabled = data === null || currentResource !== null;
                const itemKey = `form-item-${contentType}-${index}${
                  apData != null ? `-${apData.id}` : ''
                }`;
                const contentTypeName = ContentTypeNames?.[contentType]?.split('/').pop() ?? '??';
                const isKeyPreparingUpload = isPreparingUpload[itemKey] ?? false;

                // const isDragging = draggingState[itemKey] ?? false;

                return (
                  <Form.Item
                    key={itemKey}
                    label={
                      <span>
                        {contentTypeName}
                        {apData != null && <strong>{` ${apData.name}`}</strong>}
                      </span>
                    }
                  >
                    <div style={{ display: 'flex', position: 'relative' }}>
                      {Preview}

                      <div style={{ marginLeft: 10 }}>
                        <Upload
                          accept={contentUploadAccept}
                          disabled={uploadIsDisabled || isKeyPreparingUpload}
                          multiple={false}
                          showUploadList={false}
                          beforeUpload={(file) =>
                            handleFileSelect(itemKey, file, contentType, currentAppPlatform)
                          }
                        >
                          <Button
                            data-dragdropenabled
                            onDragEnter={() => {
                              console.log('onDragEnter()', uploadIsDisabled);
                              if (!uploadIsDisabled) {
                                setDraggingState((state) => ({ ...state, [itemKey]: true }));
                              }
                            }}
                            onDragLeave={() => {
                              console.log('onDragLeave()', uploadIsDisabled);
                              if (!uploadIsDisabled) {
                                setDraggingState((state) => ({ ...state, [itemKey]: false }));
                              }
                            }}
                            onDrop={(e) => {
                              const fileList = e.dataTransfer?.files;
                              console.log('onDrop()', fileList);

                              e.preventDefault();
                              e.stopPropagation();

                              if (!uploadIsDisabled) {
                                setDraggingState((state) => ({ ...state, [itemKey]: false }));
                              }

                              if (fileList == null || fileList.length > 1) {
                                message.error(
                                  `You can only drop one file of type ${contentUploadAccept}`
                                );
                                return;
                              }

                              const reader = new FileReader();
                              reader.onload = (ev) => {
                                console.log('onload', ev);
                                if (ev.currentTarget != null) {
                                  const currentTarget = ev.currentTarget as CustomEventTarget;
                                  const blob = new Blob([currentTarget.result]);
                                  fromBlob(blob).then((a) => {
                                    console.log('file-type result', a);

                                    if (a != null) {
                                      const { mime } = a;
                                      const exceptionData = mimeTypeExceptions.find(
                                        (ex) => ex.contentType === contentType
                                      );
                                      if (exceptionData == null) {
                                        if (mime != null && !fileTypes.includes(mime)) {
                                          setWrongFormatError({
                                            ...wrongFormatError,
                                            [itemKey]: `Incorrect mime-type of file: ${mime}. Accepted mime-types: ${fileTypes.join(
                                              ', '
                                            )}`,
                                          });
                                          return;
                                        }
                                      } else if (
                                        exceptionData.acceptedExtensions.includes(a.ext) === false
                                      ) {
                                        setWrongFormatError({
                                          ...wrongFormatError,
                                          [itemKey]: `Incorrect type of file. Accepted extensions: ${exceptionData.acceptedExtensions.join(
                                            ', '
                                          )}`,
                                        });
                                        return;
                                      }
                                    }

                                    handleFileSelect(
                                      itemKey,
                                      fileList[0],
                                      contentType,
                                      currentAppPlatform
                                    );
                                  });
                                } else {
                                  const { msg } = logUnknownError('onload() currentTarget not set');
                                  message.error(msg);
                                }
                              };

                              // we only need the first 4100 bytes to get the mime-type
                              // taken from here: https://github.com/sindresorhus/file-type/blob/5de41fd05c9623e72585abb3c79ddbafafad07ea/core.js#L11
                              const blob = fileList[0].slice(0, 4100);
                              reader.readAsArrayBuffer(blob);
                            }}
                            style={{
                              height: 90,
                            }}
                            className={
                              draggingState[itemKey] === true ? styles.isDragging : undefined
                            }
                            disabled={uploadIsDisabled || isKeyPreparingUpload}
                            icon={<UploadOutlined />}
                          >
                            select file
                          </Button>
                        </Upload>
                      </div>
                      <div style={{ marginLeft: 10, lineHeight: 1, width: '100%' }}>
                        <div style={{ marginBottom: 10 }}>
                          <Input
                            disabled
                            value={currentResource?.file?.originalName ?? resourceInfo.name}
                          />
                        </div>
                        <MbxButton
                          onClick={() => {
                            handleContentRemove(itemKey, data, contentType, currentAppPlatform);
                          }}
                          danger
                          disabled={currentResource === null}
                          icon={<CloseOutlined />}
                          style={{ marginRight: 10 }}
                          data-tid={
                            contentType === ContentType.EPISODE_THUMBNAIL
                              ? 'remove-thumbnail-button'
                              : 'remove-content-button'
                          }
                        >
                          remove
                        </MbxButton>

                        {(episode.resourceTypeId === ResourceType.VIDEO_MEDIABOX ||
                          episode.resourceTypeId === ResourceType.AUDIO_ONLY) &&
                          contentType === ContentType.EPISODE_CONTENT &&
                          canUserRerunConversion === true && (
                            <MbxButton
                              onClick={() => {
                                if (currentResource != null) {
                                  handleResourceRerunConversion(currentResource.id);
                                }
                              }}
                              danger
                              disabled={currentResource == null}
                              icon={<RedoOutlined />}
                              loading={isTriggeringRerun}
                            >
                              rerun
                            </MbxButton>
                          )}
                      </div>

                      {currentResource != null && (
                        <span
                          style={{
                            display: 'inline-block',
                            position: 'absolute',
                            top: '-18px',
                            right: 0,
                            fontSize: '10px',
                          }}
                        >
                          {`last updated ${formatDate(currentResource?.updatedAt)}`}
                        </span>
                      )}
                    </div>
                    {wrongFormatError[itemKey] != null && (
                      <div
                        style={{
                          display: 'block',
                          width: '100%',
                          marginTop: '4px',
                          color: 'red',
                          fontWeight: 'bold',
                          fontSize: '10px',
                        }}
                      >
                        {wrongFormatError[itemKey]}
                      </div>
                    )}
                  </Form.Item>
                );
              });
            }
          )}
        </Form>
      )}
    </>
  );
};

export default EpisodeLocalizedForm;
