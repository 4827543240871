import { schema, normalize } from 'normalizr';

import { GET_APPS_LIST_OK } from '../constants/apps';
import {
  SEARCH_USER,
  SEARCH_USER_OK,
  SEARCH_USER_FAIL,
  SEARCH_CLEAR,
  USER_INVITE,
  USER_INVITE_OK,
  USER_INVITE_FAIL,
  USER_INVITE_REMOVE,
  USER_INVITE_REMOVE_OK,
  USER_INVITE_REMOVE_FAIL,
  USER_INVITE_GET_LIST,
  USER_INVITE_GET_LIST_OK,
  USER_INVITE_GET_LIST_FAIL,
  USER_INVITE_ACCEPT,
  USER_INVITE_ACCEPT_OK,
  USER_INVITE_ACCEPT_FAIL,
  USER_DELETE,
  USER_DELETE_OK,
  USER_DELETE_FAIL,
  USER_GET_LIST,
  USER_GET_LIST_OK,
  USER_GET_LIST_FAIL,
  USER_GET_DETAIL,
  USER_GET_DETAIL_OK,
  USER_GET_DETAIL_FAIL,
  USER_UPDATE,
  USER_UPDATE_OK,
  USER_UPDATE_FAIL,
  USER_ROLE_ADD,
  USER_ROLE_ADD_OK,
  USER_ROLE_ADD_FAIL,
  USER_ROLE_REMOVE,
  USER_ROLE_REMOVE_OK,
  USER_ROLE_REMOVE_FAIL,
} from '../constants/users';
import { UserSchema, AppSchema } from '../schemas';
import { getUserById } from '../selectors/userSelectors';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';
import type { GlobalStateGetter } from '../reducers';
import type { CheckInviteTokenResponse, UserInviteData } from '../reducers/InviteReducer';
import type { UserSignup } from '../reducers/UsersReducer';

//
//
export const clearUserSearch = () => ({ type: SEARCH_CLEAR });

//
//
export const createUserInvite = (invite: UserInviteData) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/users/invites`,
      method: 'POST',
      data: invite,
    },
    dispatch,
    {
      init: USER_INVITE,
      fail: USER_INVITE_FAIL,
    },
    201
  );
  dispatch({ type: USER_INVITE_OK, payload: response.data });
  return true;
};

//
//
export const removeUserInvite =
  (inviteId: number, hash: string) => async (dispatch: AppDispatch) => {
    await wrapFetch(
      {
        url: `/users/invites/${hash}`,
        method: 'DELETE',
      },
      dispatch,
      {
        init: USER_INVITE_REMOVE,
        fail: USER_INVITE_REMOVE_FAIL,
      },
      204
    );
    dispatch({ type: USER_INVITE_REMOVE_OK, inviteId });
    return true;
  };

//
//
export const getUserInvitesForApp = (appId: number | null) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/users/invites`,
      method: 'GET',
      params: {
        appId: appId == null ? 'null' : appId,
      },
    },
    dispatch,
    {
      init: USER_INVITE_GET_LIST,
      fail: USER_INVITE_GET_LIST_FAIL,
    }
  );
  dispatch({ type: USER_INVITE_GET_LIST_OK, appId, payload: response.data });
  return true;
};

//
//
export const joinApp = (hash: string) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/users/invites/${hash}`,
      method: 'PUT',
    },
    dispatch,
    {
      init: USER_INVITE_ACCEPT,
      fail: USER_INVITE_ACCEPT_FAIL,
    }
  );

  if (typeof response.data.apps !== 'undefined') {
    const normalizedApps = normalize(response.data.apps, new schema.Array(AppSchema));
    dispatch({ type: GET_APPS_LIST_OK, payload: normalizedApps });
  }

  const updatedUser = normalize(response.data.user, UserSchema);
  console.log('TEST', response.data);
  console.log('TEST', updatedUser);
  const { appId, inviteId } = response.data;
  dispatch({ type: USER_INVITE_ACCEPT_OK, appId, inviteId, updatedUser });

  return response.data;
};

//
//
export const confirmEmailToken = (token: string) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    { url: `/users/confirm/${token}`, method: 'PUT' },
    dispatch,
    null,
    204
  );
  return response.data;
};

//
//
export const checkInviteToken = (token: string) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch<CheckInviteTokenResponse>(
    { url: `/users/invites/${token}` },
    dispatch,
    null
  );
  return response.data;
};

//
//
export const deleteUser = (userId: number) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/users/${userId}`,
      method: 'DELETE',
    },
    dispatch,
    {
      init: USER_DELETE,
      fail: USER_DELETE_FAIL,
    },
    204
  );
  dispatch({ type: USER_DELETE_OK, payload: userId });
  return true;
};

//
//
export const getUserDetails =
  (userId: number, appId: number | null | undefined) =>
  async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const response = await wrapFetch({ url: `/users/${userId}`, params: { appId } }, dispatch, {
      init: USER_GET_DETAIL,
      fail: USER_GET_DETAIL_FAIL,
    });
    const normalizedData = normalize(response.data, UserSchema);
    const user = getUserById(getState(), userId);
    dispatch({
      type: USER_GET_DETAIL_OK,
      payload: normalizedData,
      appId,
      allPreviousRoles: user.roles,
    });
    return normalizedData;
  };

//
//
export const getUserList = () => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/users` }, dispatch, {
    init: USER_GET_LIST,
    fail: USER_GET_LIST_FAIL,
  });
  const normalizedData = normalize(response.data, new schema.Array(UserSchema));
  dispatch({ type: USER_GET_LIST_OK, payload: normalizedData });
  return normalizedData;
};

//
//
export const searchForUser = (q: string) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/users`, params: { q } }, dispatch, {
    init: SEARCH_USER,
    fail: SEARCH_USER_FAIL,
  });
  const normalizedData = normalize(response.data, new schema.Array(UserSchema));
  dispatch({ type: SEARCH_USER_OK, payload: normalizedData });
  return normalizedData;
};

//
//
export const updateUser =
  (userId: number, firstname: string, lastname: string) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/users/${userId}`,
        method: 'PUT',
        data: {
          firstname,
          lastname,
        },
      },
      dispatch,
      {
        init: USER_UPDATE,
        fail: USER_UPDATE_FAIL,
      }
    );
    const normalizedData = normalize(response.data, UserSchema);
    dispatch({ type: USER_UPDATE_OK, payload: normalizedData });
    return normalizedData;
  };

//
//
export const addUserRole = (userId: number, roleId: number) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/users/${userId}/roles`,
      method: 'POST',
      data: {
        roleId,
      },
    },
    dispatch,
    {
      init: USER_ROLE_ADD,
      fail: USER_ROLE_ADD_FAIL,
    },
    201
  );
  dispatch({ type: USER_ROLE_ADD_OK, userId, roleId });
  return true;
};

//
//
export const removeUserRole = (userId: number, roleId: number) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/users/${userId}/roles/${roleId}`,
      method: 'DELETE',
    },
    dispatch,
    {
      init: USER_ROLE_REMOVE,
      fail: USER_ROLE_REMOVE_FAIL,
    },
    204
  );
  dispatch({ type: USER_ROLE_REMOVE_OK, userId, roleId });
  return true;
};

//
//
export const registerUser = (data: UserSignup) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/users`,
      method: 'POST',
      data: {
        ...data,
        locale: navigator.language.replaceAll('-', '_'),
      },
    },
    dispatch,
    null,
    201
  );
  return true;
};

//
//
export const resendConfirmationEmail = (email: string) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/users/confirm/resend`,
      method: 'POST',
      data: {
        email,
      },
    },
    dispatch,
    null,
    204
  );
  return true;
};
