import { Spin } from 'antd';

import styles from './AppSwitcher.module.scss';
import { FileStatus } from '../common/constants/file-status';
import { ImageTemplates } from '../common/constants/image-templates';
import { hexLinearShade } from '../utils/colors';

import type { Icon } from '../reducers/ResourceReducer';

type AppIconProps = {
  app: { name: string; id: number; icon: Icon | null; privateBaseUrl: string };
  showSpinnerWhenLoading?: boolean;
  size?: 's' | 'l';
};

const AppIcon = ({
  app: { name: appName, id, icon, privateBaseUrl },
  showSpinnerWhenLoading = false,
  size = 's',
}: AppIconProps) => {
  // Hax for bgColor - generated by seed (app.id) to get it random,
  // but always the same per app.
  const dim = size === 's' ? '29px' : '75px';
  const appColor = Math.floor(Math.abs(Math.sin(id) * 16777215)).toString(16);
  const versionSorXS = icon?.file?.versions?.find((f) => {
    const templateName = f?.meta?.templateName ?? '';
    return templateName === (size === 's' ? ImageTemplates.xs : ImageTemplates.s);
  });

  const versionSorXSState = versionSorXS != null ? versionSorXS.lastStatusId : FileStatus.UNKNOWN;

  const textIcon = (
    <div
      style={{
        backgroundColor: hexLinearShade(-0.25, `#${appColor.padStart(6, '0')}`),
        width: dim,
        height: dim,
        border: '1px solid whitesmoke',
      }}
      className={styles.appIcon}
    >
      <span style={{ fontSize: size === 's' ? '16px' : '48px' }}>{appName[0]}</span>
    </div>
  );

  if (icon === null) {
    return textIcon;
  }

  return versionSorXS != null && versionSorXSState === FileStatus.READY ? (
    <img
      src={`${privateBaseUrl}/${versionSorXS.name}.${versionSorXS.ext}`}
      className={styles.appIcon}
      style={{ width: dim, height: dim, objectFit: 'cover' }}
    />
  ) : showSpinnerWhenLoading === true ? (
    <Spin
      size={size === 's' ? 'small' : 'large'}
      style={{ width: dim, height: dim, paddingTop: size === 'l' ? '1em' : '0.5em' }}
    />
  ) : (
    textIcon
  );
};

export default AppIcon;
