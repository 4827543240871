import { combineReducers } from 'redux';

import { APPPLATFORMS_GET_LIST_OK } from '../constants/appplatforms';
import { GET_APPS_LIST_OK, GET_APP_DETAILS_OK } from '../constants/apps';
import { ASSET_GET_DETAILS_OK } from '../constants/assets';
import { LOGIN_OK } from '../constants/auth';
import { EPISODE_GET_DETAILS_OK } from '../constants/episodes';
import {
  PLATFORMS_GET_LIST_OK,
  PLATFORMS_CREATE_OK,
  PLATFORMS_UPDATE_OK,
  PLATFORMS_DELETE_OK,
} from '../constants/platforms';

import type { AnyAction } from 'redux';

export type Platform = {
  id: number;
  name: string;
  subcategories?: string;
};

export type PlatformStateById = { [k: string | number]: Platform };
export type PlatformStateAllIds = Array<number>;

export type PlatformState = {
  byId: PlatformStateById;
  allIds: PlatformStateAllIds;
};

export const getEmptyPlatform = (id?: number): Platform => ({
  id: id ?? -1,
  name: '??',
});

const initialStateById: PlatformStateById = {};
const initialStateAllIds: PlatformStateAllIds = [];

//
//
const byId = (state = initialStateById, action: AnyAction): PlatformStateById => {
  switch (action.type) {
    case LOGIN_OK:
    case GET_APPS_LIST_OK:
    case PLATFORMS_GET_LIST_OK: {
      if (action.payload.entities.platforms == null) {
        return state;
      }
      return {
        ...action.payload.entities.platforms,
      };
    }

    case GET_APP_DETAILS_OK:
    case PLATFORMS_CREATE_OK:
    case PLATFORMS_UPDATE_OK:
    case APPPLATFORMS_GET_LIST_OK:
    case EPISODE_GET_DETAILS_OK:
    case ASSET_GET_DETAILS_OK: {
      if (action.payload.entities.platforms == null) {
        return state;
      }
      return {
        ...state,
        ...action.payload.entities.platforms,
      };
    }

    case PLATFORMS_DELETE_OK: {
      const { [action.platformId]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    default:
      return state;
  }
};

//
//
const allIds = (state = initialStateAllIds, action: AnyAction): PlatformStateAllIds => {
  switch (action.type) {
    case LOGIN_OK:
    case GET_APPS_LIST_OK:
    case PLATFORMS_GET_LIST_OK: {
      const platforms = action?.payload?.entities?.platforms;
      if (platforms == null) {
        return state;
      }

      return Object.keys(platforms).map((key) => parseInt(key, 10));
    }

    case APPPLATFORMS_GET_LIST_OK: {
      const platforms = action?.payload?.entities?.platforms;
      if (platforms == null) {
        return state;
      }
      const newState = [...state];
      Object.keys(platforms).forEach((platformIdStr) => {
        const platformId = parseInt(platformIdStr, 10);
        if (!newState.includes(platformId)) {
          newState.push(platformId);
        }
      });
      return newState;
    }

    case PLATFORMS_CREATE_OK:
      return [...state, action.payload.result];

    case PLATFORMS_DELETE_OK:
      return state.filter((platformId) => platformId !== action.platformId);
    // TODO: GET_APP_DETAILS_OK

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
});

export default combined;
