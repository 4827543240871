import pathToRegexp, { compile } from 'path-to-regexp';
import { matchPath } from 'react-router-dom';

import { ROUTES, isRouteName } from './routes';

import type { RouteData, Routes, RouteName } from './routes';
import type { RouteInfo, FlatRoute } from './sitemap';
import type { match } from 'react-router-dom';

//
//
export const comparePaths = (exactPath: string, pathPattern: string): boolean => {
  if (pathPattern.indexOf(':') !== -1) {
    const regexp = pathToRegexp(pathPattern, []);
    return regexp.test(exactPath);
  }
  return exactPath === pathPattern;
};

// TODO: optimize
//
export const findPath = (routes: Routes, sitemap: Array<RouteInfo>, path: string) => {
  const isRouteValid =
    Object.keys(routes).find((key) => {
      if (isRouteName(key)) {
        return comparePaths(path, routes[key].path);
      }
      return false;
    }) !== undefined;

  if (isRouteValid === false) {
    return [];
    // TODO: why return HOME if not found?!
    // const p = ROUTES.home;
    // return [{ path: p.path, name: p.name }];
  }

  const traverse = (paths: Array<RouteInfo>, needle: string, parents: Array<RouteData>) => {
    for (let i = 0; i < paths.length; i++) {
      const p = paths[i].data;
      const subroutes = paths[i].routes;
      if (comparePaths(needle, p.path)) {
        parents.push({ path: p.path, name: p.name });
        break;
      } else if (Array.isArray(subroutes)) {
        parents.push({ path: p.path, name: p.name });
        traverse(subroutes, needle, parents);
        // is it the same we inserted before? then remove it again
        if (parents[parents.length - 1].path === p.path) {
          parents.pop();
        } else {
          break;
        }
      }
    }
    return parents;
  };

  return traverse(sitemap, path, []);
};

//
//
export const fillPath = (path: string, params: Record<string, string | undefined> = {}) => {
  if (path.indexOf('/:') !== -1) {
    const newPath = compile(path);
    path = newPath(params);
  }
  return path;
};

//
//
export const getSitemapFlat = (sitemap: Array<RouteInfo>) => {
  const flatRoutes: Array<FlatRoute> = [];

  const traverse = (routes: Array<RouteInfo>) => {
    routes.forEach((r) => {
      if (r.include != null && r.include !== true) {
        return;
      }

      flatRoutes.push({
        data: r.data,
        Component: r.Component,
        secure: !!r.secure,
        appSpecific: !!r.appSpecific,
        needsAdmin: !!r.needsAdmin,
        requiredAppRights: r.requiredAppRights ?? [],
        restrictAccessThroughAppSettings: r.restrictAccessThroughAppSettings ?? null,
      });

      if (Array.isArray(r.routes)) {
        traverse(r.routes);
      }
    });
  };

  traverse(sitemap);
  return flatRoutes;
};

//
//
export const replace = (name: string, replacements: Record<string, string>) => {
  Object.keys(replacements).forEach((key) => {
    if (name.indexOf(`:${key}`) !== -1) {
      name = name.split(`:${key}`).join(replacements[key]);
    }
  });
  return name;
};

//
//
export const matchRoute = (flatSitemap: Array<FlatRoute>, pathname: string) => {
  let isSecure = false;
  let matchedRoute = null;

  for (let i = 0; i < flatSitemap.length; i++) {
    const route = flatSitemap[i].data;
    // TODO: matchedRoute = matchPath<{topic: "string"}>(pathname, { path: route.path, exact: true });
    matchedRoute = matchPath<Record<string, string | undefined>>(pathname, {
      path: route.path,
      exact: true,
    });
    if (matchedRoute != null) {
      isSecure = flatSitemap[i].secure;
      break;
    }
  }
  return {
    matchedRoute,
    isSecure,
  };
};

//
//
export function getRouteByName(name: RouteName): RouteData {
  const routeKey = Object.keys(ROUTES).find((key) => key === name);
  if (!isRouteName(routeKey)) {
    throw new Error(`unknown route key: ${name}`);
  }
  return ROUTES[routeKey];
}

//
//
export function getUrlByName(
  name: RouteName,
  replacements: { [k: string]: string | number } = {}
): string {
  const route = getRouteByName(name);
  let { path } = route;
  Object.keys(replacements).forEach((rplKey) => {
    path = path.split(rplKey).join(`${replacements[rplKey]}`);
  });
  return path;
}

//
//
export function getRouteNameByUrl(url: string): string {
  const splits = url.split('/');
  // we want to be able to handle URLs with trailing slashes
  if (url !== '/' && splits[splits.length - 1] === '') {
    splits.pop();
  }
  // console.log('getRouteNameByUrl()', { url, splits });
  const routeName = Object.keys(ROUTES).find((key) => {
    if (!isRouteName(key)) {
      throw new Error(`unknown route key: ${key}`);
    }
    const route = ROUTES[key];
    const routeSplits = route.path.split('/');
    if (splits.length === routeSplits.length) {
      // look for mismatches
      const mismatchFound = routeSplits.some((s, index) => {
        // if this is a placeholder, it's automatically a match
        if (s.indexOf(':') === 0 || s === splits[index]) {
          return false;
        }
        return true;
      });
      return mismatchFound === false;
    } else {
      return false;
    }
  });

  if (routeName == null) {
    throw new Error(`no match found for ${url}`);
  }
  return routeName;
}

//
//
export const getCurrentAppPage = (
  matchedRoute: match<Record<string, string | undefined>> | null
) => {
  if (matchedRoute == null) {
    return null;
  } else {
    const { params, url } = matchedRoute;
    if (params == null || params.appUid == null) {
      return null;
    } else {
      const splittedUrl = url.split('/');
      const appUidIndex = splittedUrl.findIndex((part) => part === params.appUid);
      if (appUidIndex < 0) {
        return null;
      }
      // Next one to app uid is the main page.
      // const mainPage = splittedUrl[appUidIndex + 1];
      if (splittedUrl.length > appUidIndex + 1) {
        const str = splittedUrl.slice(0, appUidIndex + 2).join('/');
        return getRouteNameByUrl(str);
      }
      return null;
    }
  }
};
