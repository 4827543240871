export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_USER_OK = 'SEARCH_USER_OK';
export const SEARCH_USER_FAIL = 'SEARCH_USER_FAIL';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';

export const USER_INVITE = 'USER_INVITE';
export const USER_INVITE_OK = 'USER_INVITE_OK';
export const USER_INVITE_FAIL = 'USER_INVITE_FAIL';

export const USER_INVITE_ACCEPT = 'USER_INVITE_ACCEPT';
export const USER_INVITE_ACCEPT_OK = 'USER_INVITE_ACCEPT_OK';
export const USER_INVITE_ACCEPT_FAIL = 'USER_INVITE_ACCEPT_FAIL';

export const USER_INVITE_REMOVE = 'USER_INVITE_REMOVE';
export const USER_INVITE_REMOVE_OK = 'USER_INVITE_REMOVE_OK';
export const USER_INVITE_REMOVE_FAIL = 'USER_INVITE_REMOVE_FAIL';

export const USER_INVITE_GET_LIST = 'USER_INVITE_GET_LIST';
export const USER_INVITE_GET_LIST_OK = 'USER_INVITE_GET_LIST_OK';
export const USER_INVITE_GET_LIST_FAIL = 'USER_INVITE_GET_LIST_FAIL';

export const USER_DELETE = 'USER_DELETE';
export const USER_DELETE_OK = 'USER_DELETE_OK';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_GET_LIST = 'USER_GET_LIST';
export const USER_GET_LIST_OK = 'USER_GET_LIST_OK';
export const USER_GET_LIST_FAIL = 'USER_GET_LIST_FAIL';

export const USER_GET_DETAIL = 'USER_GET_DETAIL';
export const USER_GET_DETAIL_OK = 'USER_GET_DETAIL_OK';
export const USER_GET_DETAIL_FAIL = 'USER_GET_DETAIL_FAIL';

export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_OK = 'USER_UPDATE_OK';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const USER_ROLE_ADD = 'USER_ROLE_ADD';
export const USER_ROLE_ADD_OK = 'USER_ROLE_ADD_OK';
export const USER_ROLE_ADD_FAIL = 'USER_ROLE_ADD_FAIL';

export const USER_ROLE_REMOVE = 'USER_ROLE_REMOVE';
export const USER_ROLE_REMOVE_OK = 'USER_ROLE_REMOVE_OK';
export const USER_ROLE_REMOVE_FAIL = 'USER_ROLE_REMOVE_FAIL';
