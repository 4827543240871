import { Alert } from 'antd';
import uniqBy from 'lodash.uniqby';
import queryString from 'query-string';
import { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import { generateCalendarFeedData, getCalendarFeedData } from '../actions/calendarFeed';
import { getCategoryList } from '../actions/categories';
import { getEpisodesMetadata } from '../actions/episodes';
import { getRegionList } from '../actions/regions';
import { getReleaseList } from '../actions/releases';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { getValueFromKey } from '../common/utils/app';
import { useCurrentAppContext } from '../contexts';
import {
  ROUTE_NAME_APP_CATEGORYSORT,
  ROUTE_NAME_APP_EPISODEDETAIL,
  ROUTE_NAME_APP_RELEASECREATE,
  ROUTE_NAME_APP_RELEASEDETAIL,
  ROUTE_NAME_APP_RELEASES,
} from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import ReleaseList from '../pages/ReleaseList';
import { isUserSuperadmin } from '../selectors/authSelectors';
import { getCalendarFeedUrls } from '../selectors/calendarFeedSelectors';
import { getAllCategoriesForApp } from '../selectors/categorySelectors';
import { getAllRegionsForApp } from '../selectors/regions/advanced';
import { getAllReleasesForRegion, getFilteredReleasesInfo } from '../selectors/releaseSelectors';
import {
  getQFromSearch,
  getPageFromSearch,
  getTypesFromSearch,
  getCategoriesFromSearch,
  getRegionFromSearch,
  getFreeFromSearch,
  getReleaseSortInfoFromSearch,
} from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { SearchItem } from '../components/ListSearchInput';
import type { Release } from '../reducers/ReleaseReducer';

const DEFAULT_PER_PAGE = 25;

export type PageOptions = {
  page?: number;
  region?: number;
  q?: string;
  cats?: string;
  types?: string;
  free?: boolean;
  sort?: string;
};

//
//
const ReleaseListContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentApp } = useCurrentAppContext();

  const currentSearch = getQFromSearch(history.location.search);
  const currentPage = getPageFromSearch(history.location.search);
  const currentRegionId = getRegionFromSearch(history.location.search);
  const currentCategoryIds = getCategoriesFromSearch(history.location.search);
  const currentTypeIds = getTypesFromSearch(history.location.search);
  const currentFree = getFreeFromSearch(history.location.search);
  const currentSortInfo = getReleaseSortInfoFromSearch(history.location.search);

  const calendarFeedUrls = useGlobalSelector((state) => getCalendarFeedUrls(state, currentApp.id));
  const appUsingCalendarFeed = getValueFromKey<boolean>(
    currentApp.configuration,
    'adminData.appUsingCalendarFeed',
    false
  );
  const isSuperadmin = useGlobalSelector(isUserSuperadmin);
  const displayCanlendarFeed = isSuperadmin === true || appUsingCalendarFeed === true;

  const regions = useGlobalSelector((state) => getAllRegionsForApp(state, currentApp.id));
  const allReleasesForRegion = useGlobalSelector((state) =>
    getAllReleasesForRegion(state, currentRegionId)
  );
  const allCategoriesForApp = useGlobalSelector((state) =>
    getAllCategoriesForApp(state, currentApp.id)
  );
  const filteredReleasesInfo = useGlobalSelector((state) =>
    getFilteredReleasesInfo(
      state,
      currentRegionId,
      currentSearch,
      currentPage,
      DEFAULT_PER_PAGE,
      currentCategoryIds,
      currentTypeIds,
      currentFree,
      currentSortInfo
    )
  );

  const [error, setError] = useState('');

  const idsStr = filteredReleasesInfo.releases.map((r) => r.episode.id).join(',');

  useEffect(() => {
    let isMounted = true;
    const fetchRegions = async () => {
      console.log('fetchRegions()', currentApp.id);
      if (currentApp.id === -1) {
        return;
      }
      try {
        dispatch(counterIncrement());
        await dispatch(getRegionList(currentApp.id));
        await dispatch(getCategoryList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        if (isMounted) {
          setError(msg);
        }
      } finally {
        dispatch(counterDecrement());
      }
    };

    fetchRegions();

    return () => {
      isMounted = false;
    };
  }, [dispatch, currentApp.id]);

  useEffect(() => {
    let isMounted = true;
    const fetchReleases = async () => {
      console.log('fetchReleases()', currentRegionId);
      if (currentApp.id === -1 || currentRegionId === -1) {
        return;
      }

      try {
        dispatch(counterIncrement());
        await dispatch(getReleaseList(currentApp.id, currentRegionId));
      } catch (err) {
        const { msg } = logUnknownError(err);
        if (isMounted) {
          setError(msg);
        }
      } finally {
        dispatch(counterDecrement());
      }
    };

    fetchReleases();

    return () => {
      isMounted = false;
    };
  }, [dispatch, currentApp.id, currentRegionId]);

  useEffect(() => {
    let isMounted = true;
    const fetchMetadata = async () => {
      if (currentApp.id === null || currentApp.id === -1 || idsStr === '') {
        return;
      }

      const ids = idsStr.split(',').map((i) => parseInt(i, 10));
      if (ids.length > 0) {
        try {
          dispatch(counterIncrement());
          await dispatch(getEpisodesMetadata(currentApp.id, ids));
        } catch (err) {
          const { msg } = logUnknownError(err);
          if (isMounted) {
            setError(msg);
          }
        } finally {
          dispatch(counterDecrement());
        }
      }
    };

    fetchMetadata();

    return () => {
      isMounted = false;
    };
  }, [dispatch, currentApp.id, idsStr]);

  useEffect(() => {
    let isMounted = true;
    const fetchCalendarfeed = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getCalendarFeedData(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        if (isMounted) {
          setError(msg);
        }
      } finally {
        dispatch(counterDecrement());
      }
    };
    if (displayCanlendarFeed === true) {
      fetchCalendarfeed();
    }
    return () => {
      isMounted = false;
    };
  }, [dispatch, currentApp.id, displayCanlendarFeed]);

  const handleGenerateCalendarData = () => {
    dispatch(generateCalendarFeedData(currentApp.id));
  };

  const updateSearchParams = (opts: PageOptions) => {
    const options = {
      page: currentPage,
      region: currentRegionId,
      q: currentSearch,
      cats: currentCategoryIds.join('x'),
      free: currentFree,
      types: currentTypeIds.join('x'),
      sort: `${currentSortInfo.sortBy}:${currentSortInfo.order}`,
      ...opts,
    };
    history.push(
      `${getUrlByName(ROUTE_NAME_APP_RELEASES, {
        ':appUid': currentApp.uid,
      })}?${queryString.stringify(options)}`
    );
  };

  const handleReleaseEdit = (releaseId: number) => {
    history.push(
      getUrlByName(ROUTE_NAME_APP_RELEASEDETAIL, {
        ':appUid': currentApp.uid,
        ':releaseId': releaseId,
      })
    );
  };

  const handleOpenCategory = (categoryId: number) => {
    history.push(
      `${getUrlByName(ROUTE_NAME_APP_CATEGORYSORT, {
        ':appUid': currentApp.uid,
        ':categoryId': categoryId,
      })}?region=${currentRegionId}`
    );
  };

  const handleSort = (sortBy: string) => {
    console.log('handleSort()', sortBy);
    updateSearchParams({ sort: sortBy, page: 1 });
  };

  const handleCatsChanged = (ids: Array<string>) => {
    console.log('aaa handleCatsChanged()', ids);
    updateSearchParams({ cats: ids.join('x'), page: 1 });
  };

  const handleTypesChanged = (ids: Array<number>) => {
    console.log('aaa handleTypesChanged()', ids);
    updateSearchParams({ types: ids.join('x'), page: 1 });
  };

  const handleChangeRegion = (regionId: number) => {
    console.log('handleChangeRegion()', regionId);
    updateSearchParams({ region: regionId, page: 1 });
  };

  const handlePaginationChange = (page: number) => {
    updateSearchParams({ page });
  };

  const handleFilter = (searchStr: string) => {
    updateSearchParams({ q: searchStr, page: 1 });
  };

  const handleSelectSearch = (value: string | number) => {
    console.log('handleSelect()', value);
    const result = /^(release|category)-([0-9]+)$/.exec(value.toString());
    if (result !== null) {
      const [, type, idStr] = result;
      const id = Number(idStr);

      if (type === 'category') {
        updateSearchParams({ cats: [...currentCategoryIds, id].join('x'), page: 1 });
      } else if (type === 'release') {
        handleReleaseEdit(id);
      }
    }
  };

  const handleResetSearch = (item: SearchItem) => {
    console.log('handleResetSearch()', item);
    if (item.type === 'search') {
      updateSearchParams({ q: '', page: 1 });
    }
  };

  const handleToggleFreeContent = () => {
    updateSearchParams({ free: !currentFree, page: 1 });
  };

  const handleOpenEpisodeDetails = (release: Release) => {
    history.push(
      getUrlByName(ROUTE_NAME_APP_EPISODEDETAIL, {
        ':appUid': currentApp.uid,
        ':episodeId': release.episode.id,
      })
    );
  };

  const handleOpenAddRelease = () => {
    const search =
      currentRegionId != null && currentRegionId !== -1 ? `?region=${currentRegionId}` : '';
    history.push(
      `${getUrlByName(ROUTE_NAME_APP_RELEASECREATE, {
        ':appUid': currentApp.uid,
      })}${search}`
    );
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  if (currentRegionId === -1) {
    if (Array.isArray(regions) && regions.length > 0) {
      return (
        <Redirect
          to={`${getUrlByName(ROUTE_NAME_APP_RELEASES, {
            ':appUid': currentApp.uid,
          })}?region=${regions[0].id}`}
        />
      );
    }
  }

  const { maxPage, releases, max, itemsPerPage, sortedBy } = filteredReleasesInfo;

  if (maxPage > 0 && currentPage > maxPage) {
    return (
      <Redirect
        to={`${getUrlByName(ROUTE_NAME_APP_RELEASES, {
          ':appUid': currentApp.uid,
        })}?region=${currentRegionId}&page=${maxPage}`}
      />
    );
  }
  if (currentPage <= 0) {
    return (
      <Redirect
        to={`${getUrlByName(ROUTE_NAME_APP_RELEASES, {
          ':appUid': currentApp.uid,
        })}?region=${currentRegionId}`}
      />
    );
  }

  const searchData: Array<SearchItem> = [];

  allReleasesForRegion.forEach((release) => {
    searchData.push({
      key: `release-${release.id}`,
      text: release?.episode?.name ?? '...',
      value: release.id.toString(),
      type: 'release',
    });
  });

  const searchCategories: Array<SearchItem> = [];
  allCategoriesForApp.forEach((category) => {
    searchCategories.push({
      key: `category-${category.id}`,
      text: category.name,
      value: category.id.toString(),
      type: 'category',
      isLocked: category.isLocked,
      isDraft: category.isDraft,
    });
  });

  const uniqueCategories = uniqBy(searchCategories, 'key');

  const currentSearchItems: Array<SearchItem> = [];
  if (currentSearch !== '') {
    currentSearchItems.push({ type: 'search', value: currentSearch, text: currentSearch });
  }

  const currentFilterCategories: Array<string> = [];

  currentCategoryIds.forEach((categoryId: number) => {
    const category = uniqueCategories.find((obj) => obj.value === categoryId.toString());
    if (category != null) {
      currentFilterCategories.push(category.value);
    } else {
      const errorCategory: SearchItem = {
        key: `category-${categoryId}`,
        text: `unknown category ${categoryId}`,
        value: categoryId.toString(),
        type: 'category',
        isLocked: false,
        isDraft: false,
        isError: true,
      };
      searchCategories.push(errorCategory);
      currentFilterCategories.push(errorCategory.value);
    }
  });

  return (
    <ReleaseList
      regions={regions}
      currentApp={currentApp}
      currentRegionId={currentRegionId}
      allReleasesForRegion={allReleasesForRegion}
      allCategoriesForApp={allCategoriesForApp}
      currentPage={currentPage}
      searchItems={currentSearchItems}
      searchSource={searchData}
      categorySource={searchCategories}
      currentFilterCategories={currentFilterCategories}
      currentFree={currentFree}
      currentPageItems={releases}
      totalItems={max}
      itemsPerPage={itemsPerPage}
      sortedBy={sortedBy}
      currentFilterTypes={currentTypeIds}
      calendarLinks={calendarFeedUrls}
      appUsingCalendarFeed={displayCanlendarFeed}
      //
      openCategory={handleOpenCategory}
      handleSort={handleSort}
      handleCategoriesChanged={handleCatsChanged}
      handleTypesChanged={handleTypesChanged}
      handleOpenAddRelease={handleOpenAddRelease}
      handleSelectSearch={handleSelectSearch}
      handleChangeRegion={handleChangeRegion}
      handleFilter={handleFilter}
      openEditRelease={handleReleaseEdit}
      openEpisodeDetail={handleOpenEpisodeDetails}
      handleResetSearch={handleResetSearch}
      handleToggleFreeContent={handleToggleFreeContent}
      handlePaginationChange={handlePaginationChange}
      handleGenerateCalendarData={handleGenerateCalendarData}
    />
  );
};

export default ReleaseListContainer;
