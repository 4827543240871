import { normalize } from 'normalizr';

import {
  BATCH_UPLOAD_CREATE,
  BATCH_UPLOAD_CREATE_OK,
  BATCH_UPLOAD_CREATE_FAIL,
  BATCH_UPLOAD_UPDATE,
  BATCH_UPLOAD_UPDATE_OK,
  BATCH_UPLOAD_UPDATE_FAIL,
  BATCH_UPLOAD_GET,
  BATCH_UPLOAD_GET_FAIL,
  BATCH_UPLOAD_GET_OK,
  BATCH_UPLOAD_RESET,
} from '../constants/batchUpload';
import { BatchUploadSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { BatchUploadStatus } from '../common/constants/batch-upload';
import type { Metadata, Issue } from '../common/types/batch-upload';
import type { AppDispatch } from '../configureStore';
import type { BatchUpload } from '../reducers/BatchUploadReducer';

//
//
export const getActiveUpload = (appId: number, id?: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch<BatchUpload | null>(
    {
      url: `/batchupload${id != null ? `/${id}` : ''}`,
      params: {
        appId,
      },
      method: 'GET',
    },
    dispatch,
    { init: BATCH_UPLOAD_GET, fail: BATCH_UPLOAD_GET_FAIL },
    [200]
  );

  if (response.data === null) {
    dispatch({
      type: BATCH_UPLOAD_RESET,
    });
    return;
  }

  dispatch({
    type: BATCH_UPLOAD_GET_OK,
    appId,
    batchUploadId: response.data.id,
    payload: normalize(response.data, BatchUploadSchema),
  });

  return response.data;
};

//
//
export const createUpload =
  (appId: number, metadata: Metadata) => async (dispatch: AppDispatch) => {
    try {
      const response = await wrapFetch<{ issues: Array<Issue> } | BatchUpload>(
        {
          url: `/batchupload`,
          method: 'POST',
          data: {
            appId,
            ...metadata,
          },
        },
        dispatch,
        { init: BATCH_UPLOAD_CREATE, fail: BATCH_UPLOAD_CREATE_FAIL },
        [201, 400]
      );

      const { data, status } = response;

      if (status === 400 && 'issues' in data) {
        return response.data;
      }

      if ('id' in data) {
        const { id: batchUploadId } = data;

        const normalizedData = normalize(data, BatchUploadSchema);
        dispatch({
          type: BATCH_UPLOAD_CREATE_OK,
          appId,
          batchUploadId,
          payload: normalizedData,
          issues: data?.issues ?? [],
        });
      }

      return data;
    } catch {
      return false;
    }
  };

//
//
export const setUploadStatus =
  (batchUploadId: number, status: BatchUploadStatus, appId: number, size?: string) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await wrapFetch<BatchUpload>(
        {
          url: `/batchupload/${batchUploadId}`,
          method: 'PUT',
          data: {
            status,
            appId,
            size,
          },
        },
        dispatch,
        { init: BATCH_UPLOAD_UPDATE, fail: BATCH_UPLOAD_UPDATE_FAIL },
        [200, 202, 204, 400]
      );

      if (response.status === 400) {
        return response.data;
      }

      const normalizedData = normalize(response.data, BatchUploadSchema);
      dispatch({
        type: BATCH_UPLOAD_UPDATE_OK,
        appId,
        batchUploadId,
        payload: normalizedData,
        issues: response.data?.issues ?? [],
      });
      return response.data;
    } catch {
      return false;
    }
  };
