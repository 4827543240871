import { QuestionCircleOutlined } from '@ant-design/icons';
import { Alert, Collapse, Typography, Input, Form, Select, Tooltip } from 'antd';
import { useState } from 'react';

import { ROLE_ARN_REGEX } from '../common/constants/app';
import { generate as generateApiKey } from '../common/utils/apikey';
import MbxButton from '../components/base/MbxButton';

import type { AppEditFormValues } from '../components/AppEditForm';

const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;

type Props = {
  handleSubmit: (values: AppEditFormValues) => Promise<void>;
  isLoading: boolean;
};

interface AppDataV11a {
  name: string;
  uid: string;
  awsRegion: string | undefined;
  bucket: string;
  bucketArn: string;
  archiveBucket: string;
  arn: string;
  oai: string;
  cfIdPub: string;
}

type AppDataKeys = keyof AppDataV11a;

const TerraformRun = ({ env, init = false }: { env: string; init?: boolean }) => (
  <>
    <p>After adding it and saving the file, run the terraform command to apply the updates:</p>

    <div>
      <p>
        <Text code>
          <b>{`${init === true ? 'terraform init && ' : ''}terraform apply`}</b>
        </Text>
      </p>
      <p>
        in the
        <Text code>{`Mediabox-Admin-Setup/${env}/10 clients`}</Text>
        folder
      </p>
    </div>

    <p>
      Look at the output and check what resources it wants to create - if everything looks fine,
      type &quot;yes&quot; and hit return. It&apos;ll take a while for terraform to create
      everything...
    </p>
  </>
);

//
// eslint-disable-next-line @typescript-eslint/naming-convention
const AppCreateV1_1 = (props: Props) => {
  const { handleSubmit, isLoading } = props;
  const [form] = Form.useForm();

  const [data, setData] = useState<AppDataV11a>({
    name: '',
    uid: '',
    awsRegion: undefined,
    bucket: '',
    bucketArn: '',
    archiveBucket: '',
    arn: '',
    cfIdPub: '',
    oai: '',
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [newApiKey] = useState(generateApiKey());

  const updateValues = (attr: AppDataKeys, val: string) => {
    setData((dd) => {
      const d = { ...dd };
      d[attr] = val.trim();
      console.log(dd);
      return d;
    });
  };

  const onSubmit = (values: AppEditFormValues) => {
    console.log('Received values of form: ', values);
    handleSubmit({ ...values, setupVersion: '1.1' });
  };

  const handleNext = (newIndex: number) => {
    const fieldsToBeValidated = [];

    try {
      switch (newIndex) {
        case 1:
          fieldsToBeValidated.push('name', 'uid', 'awsRegion');
          break;
        case 2:
        case 3:
          fieldsToBeValidated.push(
            'name',
            'uid',
            'awsRegion',
            'contentBucket',
            'archiveBucket',
            'roleArn'
          );
          break;
        case 4:
        case 5:
          fieldsToBeValidated.push(
            'name',
            'uid',
            'awsRegion',
            'contentBucket',
            'archiveBucket',
            'roleArn',
            'cfIdPub',
            'oai'
          );
          break;
        default:
          console.error('handleNext()', newIndex);
          throw new Error('unknown index');
      }

      form
        .validateFields()
        .then((values) => {
          console.log(values, data);
          setCurrentStep(newIndex);
        })
        .catch((err) => {
          throw err;
        });
    } catch (err) {
      console.error(err);
    }
  };

  const appEnvironment = process.env.REACT_APP_ENVIRONMENT ?? '';
  const varsFile = `${data.uid}.tfvars`;

  let bucketUrl = `${data.bucket}.s3.`;
  if (data.awsRegion !== 'us-east-1') {
    bucketUrl += `${data.awsRegion}.`;
  }
  bucketUrl += `amazonaws.com`;

  return (
    <div>
      <h1 style={{ marginBottom: '3em' }}>Create new app (v1.1b)</h1>

      <Alert
        style={{ marginBottom: '1em' }}
        message={
          <div>
            <p>
              There is a Github repository that helps you manage all resources needed to run the
              client&apos;s Mediabox app.
            </p>
            <p>
              <a
                href="https://github.com/WonderzGmbH/Mediabox-Admin-Client-Setup"
                target="_blank"
                rel="noopener noreferrer"
              >
                WonderzGmbH/Mediabox-Admin-Client-Setup
              </a>
            </p>
          </div>
        }
      />

      <Form form={form} onFinish={onSubmit} name="appCreateFormV1.1">
        <Collapse defaultActiveKey={['0']} activeKey={[currentStep.toString()]}>
          {/*
           ******** STEP 0 ********
           */}
          <Panel
            header={
              <>
                <span>
                  <b>Give app a name and UID</b>
                </span>
                {currentStep > 0 && (
                  <span role="img" aria-label="checkmark" style={{ marginLeft: 10 }}>
                    ✅
                  </span>
                )}
                {currentStep > 0 && (
                  <ul style={{ marginTop: 10, fontSize: 12 }}>
                    <li>
                      <b data-tid="panel-header-step-0-1">{data.name}</b>
                    </li>
                    <li>
                      <b data-tid="panel-header-step-0-2">{data.uid}</b>
                    </li>
                    <li>
                      <b data-tid="panel-header-step-0-3">{data.awsRegion}</b>
                    </li>
                  </ul>
                )}
              </>
            }
            key="0"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input the app's name!" }]}
              initialValue={data.name}
            >
              <Input onChange={({ target: { value } }) => updateValues('name', value)} />
            </Form.Item>
            <Form.Item
              label="UID"
              name="uid"
              rules={[
                {
                  required: true,
                  message: "Please input the app's UID!",
                },
                {
                  // eslint-disable-next-line prefer-regex-literals
                  pattern: new RegExp('^[a-z0-9](?:[a-z0-9-_]{0,253}[a-z0-9])?$'),
                  message:
                    'Wrong format! Allowed characters a-z 0-9 - _. Has to start with letter or number.',
                },
              ]}
              initialValue={data.uid}
            >
              <Input onChange={({ target: { value } }) => updateValues('uid', value)} />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  AWS Region&nbsp;
                  <Tooltip title="This is where we will create the S3 buckets - CloudFront will then distribute everything globally">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="awsRegion"
              rules={[{ required: true, message: 'Please input the AWS region!' }]}
              initialValue={data.awsRegion}
            >
              <Select
                showSearch
                style={{ width: 400 }}
                placeholder="Select a region"
                optionFilterProp="children"
                onChange={(value: string) => updateValues('awsRegion', value)}
                filterOption={(input, option) =>
                  option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="us-east-2">US East (Ohio) (us-east-2)</Option>
                <Option value="us-east-1">US East (N. Virginia) (us-east-1)</Option>
                <Option value="us-west-1">US West (N. California) (us-west-1)</Option>
                <Option value="us-west-2">US West (Oregon) (us-west-2)</Option>
                <Option value="ap-south-1">Asia Pacific (Mumbai) (ap-south-1)</Option>
                <Option value="ap-northeast-3">Asia Pacific (Osaka-Local) (ap-northeast-3)</Option>
                <Option value="ap-northeast-2">Asia Pacific (Seoul) (ap-northeast-2)</Option>
                <Option value="ap-southeast-1">Asia Pacific (Singapore) (ap-southeast-1)</Option>
                <Option value="ap-southeast-2">Asia Pacific (Sydney) (ap-southeast-2)</Option>
                <Option value="ap-northeast-1">Asia Pacific (Tokyo) (ap-northeast-1)</Option>
                <Option value="ca-central-1">Canada (Central) (ca-central-1)</Option>
                <Option value="eu-central-1">Europe (Frankfurt) (eu-central-1)</Option>
                <Option value="eu-west-1">Europe (Ireland) (eu-west-1)</Option>
                <Option value="eu-west-2">Europe (London) (eu-west-2)</Option>
                <Option value="eu-west-3">Europe (Paris) (eu-west-3)</Option>
                <Option value="eu-north-1">Europe (Stockholm) (eu-north-1)</Option>
                <Option value="sa-east-1">South America (São Paulo) (sa-east-1)</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <MbxButton data-tid="btn-submit-step-0" onClick={() => handleNext(1)}>
                Next
              </MbxButton>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 1 ********
           */}
          <Panel
            header={
              <>
                <span>
                  <b>
                    Create content & archive S3 buckets and AWS role{' '}
                    <Text code>{'Mediabox-Admin-Client-Setup'}</Text>
                  </b>
                </span>
                {currentStep > 1 && (
                  <span role="img" aria-label="checkmark" style={{ marginLeft: 10 }}>
                    ✅
                  </span>
                )}
                {currentStep > 1 && (
                  <ul style={{ marginTop: 10, fontSize: 12 }}>
                    <li>
                      <b data-tid="panel-header-step-1-1">{data.bucket}</b>
                    </li>
                    <li>
                      <b data-tid="panel-header-step-1-2">{data.bucketArn}</b>
                    </li>
                    <li>
                      <b data-tid="panel-header-step-1-3">{data.archiveBucket}</b>
                    </li>
                    <li>
                      <b data-tid="panel-header-step-1-4">{data.arn}</b>
                    </li>
                  </ul>
                )}
              </>
            }
            key="1"
            // disabled={currentStep < 1}
            collapsible={currentStep < 1 ? 'disabled' : undefined}
          >
            <ol>
              <li>Clone the aforementioned Github repository</li>
              <li>
                Create a folder <Text code>{`<git-repo>/${appEnvironment}/${data.uid}`}</Text>
              </li>
              <li>
                <p>
                  Create a <Text code>{varsFile}</Text> file with the following content in the{' '}
                  <Text code>{`<git-repo>/${appEnvironment}/${data.uid}`}</Text> folder
                </p>
                <pre>
                  {`# content of ${varsFile}

profile = "<AWS-profile>" # insert the name of the AWS profile you're using to create the resource here
region = "${data.awsRegion ?? ''}"
environment = "${appEnvironment}"
app_uid = "${data.uid}"`}
                </pre>
              </li>
              <li>
                Run these commands in the{' '}
                <Text code>{`<git-repo>/${appEnvironment}/${data.uid}`}</Text> folder to symlink the
                necessary file
                <pre>{`ln -s ../../00\\ source/v1.1b/main.tf`}</pre>
              </li>
              <li>
                <p>Once setup, you can initialise Terraform and apply the first set of changes:</p>
                <pre>{`terraform init && terraform apply --var-file=${varsFile}`}</pre>
                <p>
                  Look at the output and check what resources it wants to create - if everything
                  looks fine, type &quot;yes&quot; and hit return. It&apos;ll take a while for
                  terraform to create everything...
                </p>
              </li>

              <li>
                <p>Terraform should print the outputs of two buckets and a role we need:</p>
                <pre>{`bucket_archive_name = "mbx-${appEnvironment}-xxxxxxxxxxxxxxx-archive"
bucket_content_arn = "arn:aws:s3:::mbx-${appEnvironment}-xxxxxxxxxxxxxxx-content"
bucket_content_name = "mbx-${appEnvironment}-xxxxxxxxxxxxxxx-content"
bucket_content_regional_domain_name = "mbx-${appEnvironment}-xxxxxxxxxxxxxxx-content.s3.<REGION>.amazonaws.com"
role_arn = "arn:aws:iam::999999999999:role/MediaboxAccess_xxxxxxxxxxxxxxx_${appEnvironment}"`}</pre>
              </li>
            </ol>

            <Alert
              style={{ marginBottom: '1em' }}
              type="warning"
              message={
                <>
                  <p>
                    Because bucketnames are global, someone else could already use your chosen name,
                    if that happens, change it until you can get to the next screen without an error
                    saying &quot;Bucket name already exists&quot;.
                  </p>
                  <p>
                    You can add <Text code>content_bucket = &quot;other-name&quot;</Text> and/or{' '}
                    <Text code>archive_bucket = &quot;other-name-2&quot;</Text> to the{' '}
                    <Text code>{varsFile}</Text> file to override the default names.
                  </p>
                </>
              }
            />

            <p>Paste the names in the input fields below:</p>

            <Form.Item
              label="bucket_content_name"
              name="contentBucket"
              rules={[
                {
                  required: true,
                  message:
                    'Please input the name of the S3 bucket where the uploaded content should be stored!',
                },
                {
                  // eslint-disable-next-line prefer-regex-literals
                  pattern: new RegExp('^[a-z0-9](?:[a-z0-9-.]{0,253}[a-z0-9])?$', ''),
                  message: 'Wrong format!',
                },
              ]}
              initialValue={data.bucket}
            >
              <Input
                placeholder={`mbx-${appEnvironment}-${data.uid}-content`}
                onChange={({ target: { value } }) => updateValues('bucket', value)}
              />
            </Form.Item>

            <Form.Item
              label="bucket_content_arn"
              name="contentBucketArn"
              rules={[
                {
                  required: true,
                  message:
                    'Please input the ARN of the S3 bucket where the uploaded content should be stored!',
                },
                {
                  // eslint-disable-next-line prefer-regex-literals
                  pattern: new RegExp('^arn:aws:s3:::(?:[a-z0-9-.]{0,253}[a-z0-9])?$', ''),
                  message: 'Wrong format!',
                },
              ]}
              initialValue={data.bucket}
            >
              <Input
                placeholder={`arn:aws:s3:::mbx-${appEnvironment}-${data.uid}-content`}
                onChange={({ target: { value } }) => updateValues('bucketArn', value)}
              />
            </Form.Item>

            <Form.Item
              label="bucket_archive_name"
              name="archiveBucket"
              rules={[
                {
                  required: true,
                  message:
                    'Please input the name of the S3 bucket where the archived content should be stored!',
                },
                {
                  // eslint-disable-next-line prefer-regex-literals
                  pattern: new RegExp('^[a-z0-9](?:[a-z0-9-.]{0,253}[a-z0-9])?$', ''),
                  message: 'Wrong format!',
                },
              ]}
              initialValue={data.archiveBucket}
            >
              <Input
                placeholder={`mbx-${appEnvironment}-${data.uid}-archive`}
                onChange={({ target: { value } }) => updateValues('archiveBucket', value)}
              />
            </Form.Item>

            <Form.Item
              label="role_arn"
              name="roleArn"
              rules={[
                {
                  required: true,
                  message: 'Please input the ARN of the AWS role in the clients account!',
                },
                {
                  pattern: ROLE_ARN_REGEX,
                  message:
                    'This is not a ARN for a Role. It should look something like this arn:aws:iam::1234567342:role/xxxxxx',
                },
              ]}
              initialValue={data.arn}
            >
              <Input
                onChange={({ target: { value } }) => updateValues('arn', value)}
                placeholder="arn:aws:iam::1234567890:role/MediaboxExternal"
              />
            </Form.Item>

            <Form.Item>
              <MbxButton data-tid="btn-submit-step-1" onClick={() => handleNext(2)}>
                Next
              </MbxButton>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 2 ********
           */}
          <Panel
            header={
              <>
                <span>
                  <b>
                    Update AWS IAM Role/Policy + bucket access rights{' '}
                    <Text code>{'Mediabox-Admin-Setup'}</Text>
                  </b>
                </span>
                {currentStep > 2 && (
                  <span role="img" aria-label="checkmark" style={{ marginLeft: '1em' }}>
                    ✅
                  </span>
                )}
              </>
            }
            key="2"
            disabled={currentStep < 2}
          >
            <p>
              We also have to allow the client&apos;s Role to access Mediabox&apos;s output bucket.
            </p>
            <p>
              Add the follwing JSON object to the apps array in{' '}
              <a
                href={`https://github.com/WonderzGmbH/Mediabox-Admin-Setup/blob/master/${appEnvironment}/10%20clients/external_access.tf#L14`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terraform&apos;s external_access.tf
              </a>
            </p>

            <pre data-tid="snippet-external-access">{`{
  uid = "${data.uid}"
  role_arn = "${data.arn}"
  archive_bucket = "${data.archiveBucket}"
  content_bucket_arn = "${data.bucketArn}"
}`}</pre>

            <TerraformRun env={appEnvironment} />

            <p>
              Afterwards we are able to assume this Role and access the client&apos;s S3 bucket and
              the Mediabox output bucket in their name.
            </p>

            <Form.Item>
              <MbxButton data-tid="btn-submit-step-4" onClick={() => handleNext(3)}>
                Next
              </MbxButton>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 3 ********
           */}
          <Panel
            header={
              <>
                <span>
                  <b>
                    Create certificate, CloudFront + DNS <Text code>{'Mediabox-Admin-Setup'}</Text>
                  </b>
                </span>
                {currentStep > 3 && (
                  <span role="img" aria-label="checkmark" style={{ marginLeft: 10 }}>
                    ✅
                  </span>
                )}
                {currentStep > 3 && (
                  <ul style={{ marginTop: 10, fontSize: 12 }}>
                    <li>
                      <b data-tid="panel-header-step-3-1">{data.cfIdPub}</b>
                    </li>
                    <li>
                      <b data-tid="panel-header-step-3-2">{data.oai}</b>
                    </li>
                  </ul>
                )}
              </>
            }
            key="3"
            disabled={currentStep < 3}
          >
            <p>
              All content should be served via https - so we need a certificate and CloudFront
              distribution for this.
            </p>
            <ol>
              <li>
                <p>
                  open the <Text code>Mediabox-Admin-Setup/10 clients/appsetup-v1.1.tf</Text> file
                  and add:
                </p>
                <pre>{`module "${data.uid}" {
  source = "./modules/appsetup-v1.1a"
  providers = {
    aws.useast1 = aws.useast1
  }

  zone_id = data.terraform_remote_state.setup.outputs.zone_id
  domain = var.domain
  environment = var.environment
  feedcron_role = data.terraform_remote_state.lambdas.outputs.role_feedupdater_name

  cdn_originrequest_id = aws_cloudfront_cache_policy.cdn_originrequest.id
  cdn_originrequest_with_sub_id = aws_cloudfront_cache_policy.cdn_originrequest_with_sub.id

  uid = "${data.uid}"
  cloudfront_setup = {
    bucket = "${bucketUrl}"
    lambda_edge = data.terraform_remote_state.lambdas.outputs.lambda_contentaccess_qualified_arn
    originrequest = data.terraform_remote_state.lambdas.outputs.lambda_contentoriginrequest_qualified_arn
  }
}`}</pre>
              </li>
              <li>
                <p>
                  Additionally, you have to add the CloudFront ID + OAI to the output like this:
                </p>
                <pre>{`output "origin_access_identities" {
  value = zipmap(
    [
      module.app-uid-1.app_uid,
      module.app-uid-2.app_uid,
      module.app-uid-3.app_uid,
      // ... ⬇ add this line
      module.${data.uid}.app_uid
    ],
    [
      module.app-uid-1.origin_access_identity,
      module.app-uid-2.origin_access_identity,
      module.app-uid-3.origin_access_identity,
      // ... ⬇ add this line
      module.${data.uid}.origin_access_identity
    ]
  )
}

output "cf_ids" {
  value = zipmap(
    [
      module.app-uid-1.app_uid,
      module.app-uid-2.app_uid,
      module.app-uid-3.app_uid,
      // ... ⬇ add this line
      module.${data.uid}.app_uid
    ],
    [
      module.app-uid-1.cfIdPub,
      module.app-uid-2.cfIdPub,
      module.app-uid-3.cfIdPub,
      // ... ⬇ add this line
      module.${data.uid}.cfIdPub
    ]
  )
}
`}</pre>
              </li>
              <li>
                <TerraformRun env={appEnvironment} init={true} />
              </li>
              <li>
                <p>
                  Example output from Terraform where you can find the distribution ID and the
                  origin access identity
                </p>
                <pre>{`cf_ids = {
  "app-uid-1" = "xxxxxxxxxxxxxxxxx"
  "app-uid-2" = "yyyyyyyyyyyyyyyy"
  "app-uid-3" = "Exxxxxxxxxxxxx"
  "${data.uid}" = "E6FVLxxxxxxxxx"
}
origin_access_identities = {
  "app-uid-1" = "arn:aws:iam::cloudfront:user/CloudFront Origin Access Identity Exxxxxxxxxxxxx"
  "app-uid-2" = "arn:aws:iam::cloudfront:user/CloudFront Origin Access Identity Eyyyyyyyyyyyyy"
  "app-uid-3" = "arn:aws:iam::cloudfront:user/CloudFront Origin Access Identity Exxxxxxxxxxxxx"
  "${data.uid}" = "arn:aws:iam::cloudfront:user/CloudFront Origin Access Identity E6FVLxxxxxxxxx"
}
`}</pre>
              </li>
              <li>
                <p>
                  Copy+paste the distribution ID and origin access identity into the fields below:
                </p>
                <Form.Item
                  label="distribution ID"
                  name="cfIdPub"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the ID',
                    },
                  ]}
                  initialValue={data.cfIdPub}
                >
                  <Input
                    onChange={({ target: { value } }) => updateValues('cfIdPub', value)}
                    placeholder="EDFDVBD6EXAMPLE"
                  />
                </Form.Item>

                <Form.Item
                  label="Origin Access Identity"
                  name="oai"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the OAI',
                    },
                  ]}
                  initialValue={data.oai}
                >
                  <Input
                    onChange={({ target: { value } }) => updateValues('oai', value)}
                    placeholder="arn:aws:iam::cloudfront:user/CloudFront Origin Access Identity E6EJPW6B666O6M"
                  />
                </Form.Item>
              </li>
            </ol>

            <Form.Item>
              <MbxButton data-tid="btn-submit-step-3" onClick={() => handleNext(4)}>
                Next
              </MbxButton>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 4 ********
           */}
          <Panel
            header={
              <>
                <span>
                  <b>
                    Allow CloudFront access to user&apos;s bucket{' '}
                    <Text code>{'Mediabox-Admin-Client-Setup'}</Text>
                  </b>
                </span>
                {currentStep > 4 && (
                  <span role="img" aria-label="checkmark" style={{ marginLeft: 10 }}>
                    ✅
                  </span>
                )}
              </>
            }
            key="4"
            disabled={currentStep < 4}
          >
            <p>
              The CloudFront distribution running in the Mediabox AWS account needs to be able to
              access files from the client&apos;s bucket.
              <br />
              Add the Origin Access Identity from the previous step to the{' '}
              <Text code>{`<git-repo>/${appEnvironment}/${data.uid}/${varsFile}`}</Text> file:
            </p>

            <pre>{`# content of ${varsFile}

profile = "<AWS-profile>" # insert the name of the AWS profile you're using to create the resource here
region = "${data.awsRegion ?? ''}"
environment = "${appEnvironment}"
app_uid = "${data.uid}"

# ⬇ add this line
oai = "${data.oai}"`}</pre>

            <p>Apply the changes:</p>
            <pre>{`terraform apply --var-file=${varsFile}`}</pre>
            <p>
              Look at the output and check what resources it wants to create - if everything looks
              fine, type &quot;yes&quot; and hit return.
              <br />
              It&apos;ll take a while for terraform to create everything...
            </p>
            <p>Done? Go to the next step</p>

            <Form.Item>
              <MbxButton data-tid="btn-submit-step-4" onClick={() => handleNext(5)}>
                Next
              </MbxButton>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 5 ********
           */}
          <Panel header="Create app" key="5" disabled={currentStep < 5}>
            <div style={{ marginBottom: '2em' }}>
              <Form.Item style={{ marginBottom: 0 }} label="App name">
                <span>{data.name}</span>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label="App UID">
                <span>{data.uid}</span>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label="S3 bucket">
                <span>{data.bucket}</span>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label="S3 archive bucket">
                <span>{data.archiveBucket}</span>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label="Role ARN">
                <span>{data.arn}</span>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label="Distribution ID (cdn)">
                <span>{data.cfIdPub}</span>
              </Form.Item>
            </div>

            <div style={{ marginBottom: '2em' }}>
              <Alert
                type="info"
                showIcon
                style={{ maxWidth: 500 }}
                description={
                  <>
                    <p>
                      Every app needs an API-key to talk to our backend.
                      <br />
                      We generated one for you:
                    </p>
                    <Form.Item style={{ marginBottom: 0 }} label="API key">
                      <span>{newApiKey}</span>
                    </Form.Item>
                    <p>
                      You have to add the new API-key to the{' '}
                      <Text code>Mediabox-Admin-Setup/03 lambdas/${appEnvironment}.tfvars</Text>{' '}
                      like this
                    </p>
                    <pre>{`apps = {
  skyship-supersimple = {
    keys = [
      {
        key = "xxxxxxxxx"
        type = "normal"
      },
      {
        key = "yyyyyyyyy"
        type = "preview"
      }
    ]
  }
  // ... ⬇ add this
  ${data.uid} = {
    keys = [
      {
        key = "${newApiKey}"
        type = "normal"
      },
      {
        key = "<COPY THE PREVIEW KEY FROM ANOTHER APP>"
        type = "preview"
      }
    ]
  }
}`}</pre>
                    <p>And apply the changes:</p>
                    <pre>{`terraform apply --var-file=${appEnvironment}.tfvars`}</pre>
                  </>
                }
              />
            </div>

            <Form.Item>
              <MbxButton
                data-tid="btn-submit-app-create"
                mbxType="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Create App
              </MbxButton>
            </Form.Item>
          </Panel>
        </Collapse>
      </Form>
    </div>
  );
};

export default AppCreateV1_1;
