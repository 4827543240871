import {
  CalendarOutlined,
  CloudUploadOutlined,
  FileZipOutlined,
  GlobalOutlined,
  HomeOutlined,
  LineChartOutlined,
  SettingOutlined,
  TagsOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getValueByName } from '../common/utils/configuration';
import {
  isRouteName,
  ROUTE_NAME_APP_ANALYTICS,
  ROUTE_NAME_APP_ASSETS,
  ROUTE_NAME_APP_CATEGORIES,
  ROUTE_NAME_APP_DEPLOYMENTS,
  ROUTE_NAME_APP_EPISODES,
  ROUTE_NAME_APP_HOME,
  ROUTE_NAME_APP_REGIONS,
  ROUTE_NAME_APP_RELEASES,
  ROUTE_NAME_APP_SETTINGS,
} from '../navigation/routes';
import { SITEMAP_FLAT } from '../navigation/sitemap';
import { getCurrentAppPage, matchRoute, getUrlByName } from '../navigation/utils';
import { getAuth, getUserProfileOptional, isUserSuperadmin } from '../selectors/authSelectors';
import { getPathname } from '../selectors/pageHistory';
import { useGlobalSelector } from '../utils/hooks';

import type { App } from '../reducers/AppsReducer';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';

type Props = {
  app: App;
};

const AppMenu = (props: Props) => {
  const { app } = props;

  const pathname = useGlobalSelector(getPathname);
  const { matchedRoute } = matchRoute(SITEMAP_FLAT, pathname);
  const isSuperadmin = useGlobalSelector(isUserSuperadmin);
  const { authServiceName } = useGlobalSelector(getAuth);
  const currentUser = useGlobalSelector(getUserProfileOptional);
  const [currentKey, setCurrentKey] = useState<string>(
    getUrlByName(ROUTE_NAME_APP_HOME, { ':appUid': app.uid })
  );
  const [disableMenu, setDisableMenu] = useState(false);

  const appAuthServiceNames = app.authServices.map((aas) => aas.authService?.name);
  useEffect(() => {
    const appForcingTwofa = getValueByName<boolean>(app, 'appData.forceTwofa', false);
    const userHasTwofaActivated = currentUser?.twofaActivated ?? false;
    const isDisabledBcTwofaForce = appForcingTwofa && !userHasTwofaActivated;

    if (!isSuperadmin && !appAuthServiceNames.includes(authServiceName)) {
      setDisableMenu(true);
    } else if (isDisabledBcTwofaForce === true) {
      setDisableMenu(true);
    } else {
      setDisableMenu(false);
    }
  }, [app, isSuperadmin, authServiceName]);

  const defaultItems: Array<ItemType> = [
    {
      key: getUrlByName(ROUTE_NAME_APP_HOME, { ':appUid': app.uid }),
      icon: <HomeOutlined />,
      label: (
        <Link to={getUrlByName(ROUTE_NAME_APP_HOME, { ':appUid': app.uid })}>
          <span>Home</span>
        </Link>
      ),
    },
    { type: 'divider' },
    {
      key: getUrlByName(ROUTE_NAME_APP_EPISODES, { ':appUid': app.uid }),
      icon: <YoutubeOutlined />,
      label: (
        <Link to={getUrlByName(ROUTE_NAME_APP_EPISODES, { ':appUid': app.uid })}>
          <span>Episodes</span>
        </Link>
      ),
    },
    { type: 'divider' },
    {
      key: getUrlByName(ROUTE_NAME_APP_RELEASES, { ':appUid': app.uid }),
      icon: <CalendarOutlined />,
      label: (
        <Link to={getUrlByName(ROUTE_NAME_APP_RELEASES, { ':appUid': app.uid })}>
          <span>Releases</span>
        </Link>
      ),
    },
    {
      key: getUrlByName(ROUTE_NAME_APP_CATEGORIES, { ':appUid': app.uid }),
      icon: <TagsOutlined />,
      label: (
        <Link to={getUrlByName(ROUTE_NAME_APP_CATEGORIES, { ':appUid': app.uid })}>
          <span>Categories</span>
        </Link>
      ),
    },
    { type: 'divider' },
    {
      key: getUrlByName(ROUTE_NAME_APP_DEPLOYMENTS, { ':appUid': app.uid }),
      icon: <CloudUploadOutlined />,
      label: (
        <Link to={getUrlByName(ROUTE_NAME_APP_DEPLOYMENTS, { ':appUid': app.uid })}>
          <span>Deployments</span>
        </Link>
      ),
    },
    {
      key: getUrlByName(ROUTE_NAME_APP_ASSETS, { ':appUid': app.uid }),
      icon: <FileZipOutlined />,
      label: (
        <Link to={getUrlByName(ROUTE_NAME_APP_ASSETS, { ':appUid': app.uid })}>
          <span>Asset patches</span>
        </Link>
      ),
    },
    { type: 'divider' },
    {
      key: getUrlByName(ROUTE_NAME_APP_ANALYTICS, { ':appUid': app.uid }),
      icon: <LineChartOutlined />,
      label: (
        <Link to={getUrlByName(ROUTE_NAME_APP_ANALYTICS, { ':appUid': app.uid })}>
          <span>Reports</span>
        </Link>
      ),
    },
    { type: 'divider' },
    {
      key: getUrlByName(ROUTE_NAME_APP_REGIONS, { ':appUid': app.uid }),
      icon: <GlobalOutlined />,
      label: (
        <Link to={getUrlByName(ROUTE_NAME_APP_REGIONS, { ':appUid': app.uid })}>
          <span>Markets</span>
        </Link>
      ),
    },
  ];

  if (process.env.REACT_APP_SETTINGS === 'true') {
    defaultItems.push({
      key: getUrlByName(ROUTE_NAME_APP_SETTINGS, { ':appUid': app.uid }),
      icon: <SettingOutlined />,
      label: (
        <Link to={getUrlByName(ROUTE_NAME_APP_SETTINGS, { ':appUid': app.uid })}>
          <span>Settings</span>
        </Link>
      ),
    });
  }

  const [menuItems, setMenuItems] = useState<Array<ItemType>>([]);

  useEffect(() => {
    const currentAppPage = getCurrentAppPage(matchedRoute);
    if (isRouteName(currentAppPage)) {
      setCurrentKey(getUrlByName(currentAppPage, { ':appUid': app.uid }));
    } else {
      setCurrentKey(getUrlByName(ROUTE_NAME_APP_HOME, { ':appUid': app.uid }));
    }
  }, [matchedRoute, app]);

  useEffect(() => {
    setMenuItems(defaultItems);
  }, [app]);

  return <Menu disabled={disableMenu} selectedKeys={[currentKey]} items={menuItems} />;
};

export default AppMenu;
