import { useState } from 'react';

import { registerUser } from '../actions/users';
import RegisterForm from '../components/RegisterForm';
import { isUserLoggedIn } from '../selectors/authSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { UserSignup } from '../reducers/UsersReducer';

type Props = Readonly<{
  prefilledEmail?: string;
  noLoginButtonAfterSuccess?: boolean;
}>;

export type RegisterInfo = {
  isActive: boolean;
  errorMessage: string;
  success: boolean;
};

const initialRegisterInfo: RegisterInfo = { isActive: false, errorMessage: '', success: false };

//
//
const RegisterContainer = (props: Props) => {
  const dispatch = useAppDispatch();

  const { prefilledEmail, noLoginButtonAfterSuccess = false } = props;
  const userLoggedIn = useGlobalSelector(isUserLoggedIn);

  const [registerInfo, setRegisterInfo] = useState(initialRegisterInfo);

  const handleRegister = async (data: UserSignup) => {
    try {
      setRegisterInfo({ ...registerInfo, isActive: true, errorMessage: '' });
      await dispatch(registerUser(data));
      setRegisterInfo({ ...registerInfo, isActive: false, success: true });
    } catch (err) {
      const { msg } = logUnknownError(err);
      setRegisterInfo({ ...registerInfo, isActive: false, errorMessage: msg });
    }
  };

  return (
    <RegisterForm
      onRegister={handleRegister}
      registerInfo={registerInfo}
      prefilledEmail={prefilledEmail}
      isUserLoggedIn={userLoggedIn}
      noLoginButtonAfterSuccess={noLoginButtonAfterSuccess}
    />
  );
};

export default RegisterContainer;
