export const REGIONCOUNTRY_CREATE = 'REGIONCOUNTRY_CREATE';
export const REGIONCOUNTRY_CREATE_OK = 'REGIONCOUNTRY_CREATE_OK';
export const REGIONCOUNTRY_CREATE_FAIL = 'REGIONCOUNTRY_CREATE_FAIL';

export const REGIONCOUNTRY_ADD_ALL = 'REGIONCOUNTRY_ADD_ALL';
export const REGIONCOUNTRY_ADD_ALL_OK = 'REGIONCOUNTRY_ADD_ALL_OK';
export const REGIONCOUNTRY_ADD_ALL_FAIL = 'REGIONCOUNTRY_ADD_ALL_FAIL';

export const REGIONCOUNTRY_REMOVE = 'REGIONCOUNTRY_REMOVE';
export const REGIONCOUNTRY_REMOVE_OK = 'REGIONCOUNTRY_REMOVE_OK';
export const REGIONCOUNTRY_REMOVE_FAIL = 'REGIONCOUNTRY_REMOVE_FAIL';

export const REGIONCOUNTRY_REMOVE_ALL = 'REGIONCOUNTRY_REMOVE_ALL';
export const REGIONCOUNTRY_REMOVE_ALL_OK = 'REGIONCOUNTRY_REMOVE_ALL_OK';
export const REGIONCOUNTRY_REMOVE_ALL_FAIL = 'REGIONCOUNTRY_REMOVE_ALL_FAIL';

export const REGIONCOUNTRY_UPDATE = 'REGIONCOUNTRY_UPDATE';
export const REGIONCOUNTRY_UPDATE_OK = 'REGIONCOUNTRY_UPDATE_OK';
export const REGIONCOUNTRY_UPDATE_FAIL = 'REGIONCOUNTRY_UPDATE_FAIL';
