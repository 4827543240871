import {
  APPRECEIPTVALIDATORS_GET_LIST,
  APPRECEIPTVALIDATORS_GET_LIST_FAIL,
  APPRECEIPTVALIDATORS_GET_LIST_OK,
  APPRECEIPTVALIDATORS_CREATE,
  APPRECEIPTVALIDATORS_CREATE_FAIL,
  APPRECEIPTVALIDATORS_CREATE_OK,
  APPRECEIPTVALIDATORS_DELETE,
  APPRECEIPTVALIDATORS_DELETE_FAIL,
  APPRECEIPTVALIDATORS_DELETE_OK,
  APPRECEIPTVALIDATORS_UPDATE,
  APPRECEIPTVALIDATORS_UPDATE_FAIL,
  APPRECEIPTVALIDATORS_UPDATE_OK,
} from '../constants/appreceiptvalidators';
import { wrapFetch } from '../utils/api';

import type { Configuration } from '../common/types/app';
import type { AppDispatch } from '../configureStore';

//
//
export const createAppReceiptValidator =
  (appId: number, receiptTypeId: number) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/appreceiptvalidators`,
        method: 'POST',
        data: {
          appId,
          receiptTypeId,
        },
      },
      dispatch,
      {
        init: APPRECEIPTVALIDATORS_CREATE,
        fail: APPRECEIPTVALIDATORS_CREATE_FAIL,
      },
      201
    );
    dispatch({ type: APPRECEIPTVALIDATORS_CREATE_OK, payload: response.data, appId });
  };

//
//
export const deleteAppReceiptValidator =
  (appId: number, appReceiptValidatorId: number) => async (dispatch: AppDispatch) => {
    await wrapFetch(
      {
        url: `/appreceiptvalidators/${appReceiptValidatorId}`,
        method: 'DELETE',
      },
      dispatch,
      {
        init: APPRECEIPTVALIDATORS_DELETE,
        fail: APPRECEIPTVALIDATORS_DELETE_FAIL,
      },
      204
    );
    dispatch({ type: APPRECEIPTVALIDATORS_DELETE_OK, appId, appReceiptValidatorId });
  };

//
//
export const fetchAppReceiptValidatorList = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/appreceiptvalidators`,
      method: 'GET',
      params: {
        appId,
      },
    },
    dispatch,
    {
      init: APPRECEIPTVALIDATORS_GET_LIST,
      fail: APPRECEIPTVALIDATORS_GET_LIST_FAIL,
    }
  );

  dispatch({ type: APPRECEIPTVALIDATORS_GET_LIST_OK, payload: response.data, appId });
};

//
//
export const updateAppReceiptValidator =
  (appReceiptValidatorId: number, config: Configuration) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/appreceiptvalidators/${appReceiptValidatorId}`,
        method: 'PUT',
        data: { config },
      },
      dispatch,
      {
        init: APPRECEIPTVALIDATORS_UPDATE,
        fail: APPRECEIPTVALIDATORS_UPDATE_FAIL,
      }
    );

    dispatch({ type: APPRECEIPTVALIDATORS_UPDATE_OK, payload: response.data });
  };
