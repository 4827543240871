import * as Sentry from '@sentry/browser';
import md5 from 'md5';
import asyncPool from 'tiny-async-pool';

import { blobToUint8Array, chunkFile } from './binary';
import { CHUNK_SIZE, TIMEOUT, MultipartResponseCode, MAX_JOBS } from './multipart-constants';
import {
  requestMultipartUploadStart,
  requestMultipartUploadCompletion,
  requestMissingUrls,
} from '../actions/batchUploadItem';
import { getPartNumbers } from '../common/utils/batch-upload';

import type { WithBlob } from './multipart-constants';
import type { AppDispatch } from '../configureStore';
import type { QueueData } from '../reducers/QueueReducer';

export async function generateDigest(
  file: File,
  completedChunks: Map<number, string>,
  totalParts: number
) {
  const fromHexString = (hexString: string) => Uint8Array.from(Buffer.from(hexString, 'hex'));

  const allThere = completedChunks.size === totalParts;

  const hashes = allThere
    ? Array.from(completedChunks.entries())
        .sort(([a], [b]) => a - b)
        .map(([, hash]) => hash.replaceAll('"', ''))
    : await Promise.all(
        chunkFile(file, totalParts).map(async (chunk) => md5(await blobToUint8Array(chunk)))
      );

  return `${md5(fromHexString(hashes.join('')))}-${totalParts}`;
}

function getChunkForPartNumber(file: File, partNumber: number) {
  const start = (partNumber - 1) * CHUNK_SIZE;
  const end = start + CHUNK_SIZE;
  return file.slice(start, end);
}

function isTimeoutError(err: unknown) {
  return typeof err === 'object' && err != null && 'name' in err && err.name === 'TimeoutError';
}

function uploadFlow(
  upload: (parts: Array<number>, urls: Record<number, string>) => Promise<Array<number>>,
  completion: () => Promise<{ missingParts: Array<number> }>,
  successCode: MultipartResponseCode,
  retryCode: MultipartResponseCode
) {
  return async (parts: Array<number>, urls: Record<number, string>) => {
    /* upload chunks */
    const failedParts = await upload(parts, urls);

    console.log({ failedParts });

    /* retry failed chunks on client */
    if (failedParts.length) {
      await upload(failedParts, urls);
    }

    /* request completion */
    const { missingParts } = await completion();

    const code = failedParts.length === 0 ? successCode : retryCode;

    return { missingParts, code };
  };
}

function setupUpload(
  file: File,
  {
    appId,
    id,
    totalParts,
    originalTotalParts,
  }: { appId: number; id: number; totalParts: number; originalTotalParts: number | null },
  dispatch: AppDispatch,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  progress: (arg: number) => void
) {
  const isAlreadyStarted = originalTotalParts !== null;
  const completedChunks = new Map<number, string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const multipartSendProgress = (_: Array<number>) => {
    // TODO WBP-2232 show progress
  };

  const upload = async (chunks: Array<number>, urls: Record<number, string>) => {
    const uploadChunk = async (partNumber: number) => {
      const body = getChunkForPartNumber(file, partNumber);
      const localHash = `"${md5(await blobToUint8Array(body))}"`;
      try {
        const { headers } = await fetch(urls[partNumber], {
          method: 'PUT',
          body,
          // TODO WBP-2233 also from user
          signal: AbortSignal.timeout(TIMEOUT * 1000),
        });
        const etag = headers.get('etag');
        if (localHash === etag) {
          completedChunks.set(partNumber, etag);

          multipartSendProgress(chunks);
        }
      } catch (err) {
        if (isTimeoutError(err)) {
          console.warn(`Part ${partNumber} timed out`);
          return;
        }

        Sentry.captureException(err);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
    for await (const _ of asyncPool(MAX_JOBS, chunks, uploadChunk)) {
      // .next()
      // we don't need the result, so it is ignored
    }

    return chunks.filter((partNumber) => !completedChunks.has(partNumber));
  };

  const completion = async () => {
    const digest = await generateDigest(file, completedChunks, totalParts);
    return dispatch(requestMultipartUploadCompletion(appId, id, digest));
  };
  const firstTry = uploadFlow(
    upload,
    completion,
    MultipartResponseCode.SUCCESS,
    MultipartResponseCode.SUCCESS_RETRY_CLIENT_ONE
  );
  const secondTry = uploadFlow(
    upload,
    completion,
    MultipartResponseCode.SUCCESS_RETRY_SERVER,
    MultipartResponseCode.SUCCESS_RETRY_CLIENT_TWO
  );

  const getUrls = async () =>
    dispatch(
      isAlreadyStarted
        ? requestMissingUrls(appId, id)
        : requestMultipartUploadStart(appId, id, totalParts)
    );

  return { firstTry, secondTry, completedChunks, getUrls };
}

export function canUploadAsMultiPart(
  item: Omit<QueueData, 'appPlatformId' | 'type'>
): item is WithBlob {
  return item.multipartEnabled === true && item.batchContent != null && item.fileRef?.blob != null;
}

export async function multipartUpload(
  {
    fileRef: { blob },
    batchContent: { id, totalParts: originalTotalParts, missingParts: originalMissing },
    appId,
  }: WithBlob,
  dispatch: AppDispatch,
  progress: (percent: number) => void
): Promise<MultipartResponseCode> {
  /* setup */
  const totalParts = originalTotalParts ?? Math.ceil(blob.size / CHUNK_SIZE);
  const parts = originalMissing?.length ? originalMissing : getPartNumbers(totalParts);
  const { firstTry, secondTry, completedChunks, getUrls } = setupUpload(
    blob,
    {
      appId,
      id,
      totalParts,
      originalTotalParts,
    },
    dispatch,
    progress
  );

  /* request s3 multipart upload creation or get missing urls */
  const { urls } = await getUrls();

  /* first upload attempt */
  const { missingParts, code } = await firstTry(parts, urls);

  /* all good */
  if (missingParts.length === 0) {
    return code;
  }

  /* retry failed chunks */
  for (const part of missingParts) {
    completedChunks.delete(part);
  }

  /* second upload attempt */
  const { missingParts: secondTryMissingParts, code: secondTryCode } = await secondTry(
    missingParts,
    urls
  );

  return secondTryMissingParts.length === 0 ? secondTryCode : MultipartResponseCode.FAILURE;
}
