import { Alert } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getRegionList } from '../actions/regions';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_REGIONCREATE, ROUTE_NAME_APP_REGIONDETAIL } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import RegionList from '../pages/RegionList';
import { getAllRegionsForApp } from '../selectors/regions/advanced';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { Region } from '../reducers/RegionReducer';

//
//
const RegionListContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentApp } = useCurrentAppContext();

  const allRegions = useGlobalSelector((state) => getAllRegionsForApp(state, currentApp.id));
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getRegionList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentApp.id]);

  const handleRegionAdd = () => {
    history.push(
      getUrlByName(ROUTE_NAME_APP_REGIONCREATE, {
        ':appUid': currentApp.uid,
      })
    );
  };

  const handleRegionEdit = (region: Region) => {
    history.push(
      getUrlByName(ROUTE_NAME_APP_REGIONDETAIL, {
        ':appUid': currentApp.uid,
        ':regionId': region.id,
      })
    );
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <RegionList allRegions={allRegions} addRegion={handleRegionAdd} editRegion={handleRegionEdit} />
  );
};

export default RegionListContainer;
