import { useHistory } from 'react-router-dom';

import MbxButton from './base/MbxButton';

import type { MbxButtonProps } from './base/MbxButton';

export type ButtonLinkedProps = Readonly<
  MbxButtonProps & {
    linkTo: string;
    'data-tid'?: string;
  }
>;

//
//
const ButtonLinked = (props: ButtonLinkedProps) => {
  const history = useHistory();
  const { linkTo, children, ...rest } = props;

  return (
    <MbxButton
      {...rest}
      onClick={(e) => {
        e.stopPropagation();
        history.push(linkTo);
      }}
    >
      {children}
    </MbxButton>
  );
};

export default ButtonLinked;
