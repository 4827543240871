import { normalize } from 'normalizr';

import {
  APPVERSIONS_CREATE,
  APPVERSIONS_CREATE_OK,
  APPVERSIONS_CREATE_FAIL,
  APPVERSIONS_DELETE,
  APPVERSIONS_DELETE_OK,
  APPVERSIONS_DELETE_FAIL,
  APPVERSIONS_UPDATE,
  APPVERSIONS_UPDATE_OK,
  APPVERSIONS_UPDATE_FAIL,
} from '../constants/appversions';
import { AppVersionSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const createAppVersion =
  (appPlatformId: number, name: string, description: string | null | undefined) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/appversions`,
        method: 'POST',
        data: {
          appPlatformId,
          name,
          description,
        },
      },
      dispatch,
      {
        init: APPVERSIONS_CREATE,
        fail: APPVERSIONS_CREATE_FAIL,
      },
      201
    );
    const normalizedData = normalize(response.data, AppVersionSchema);
    dispatch({ type: APPVERSIONS_CREATE_OK, payload: normalizedData, appPlatformId });
  };

//
//
export const deleteAppVersion =
  (appPlatformId: number, appVersionId: number) => async (dispatch: AppDispatch) => {
    await wrapFetch(
      {
        url: `/appversions/${appVersionId}`,
        method: 'DELETE',
      },
      dispatch,
      {
        init: APPVERSIONS_DELETE,
        fail: APPVERSIONS_DELETE_FAIL,
      },
      204
    );
    dispatch({ type: APPVERSIONS_DELETE_OK, appPlatformId, appVersionId });
  };

//
//
export const updateAppVersion =
  (
    appVersionId: number,
    { name, description }: { name: string; description: string | null | undefined }
  ) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/appversions/${appVersionId}`,
        method: 'PUT',
        data: {
          name,
          description,
        },
      },
      dispatch,
      {
        init: APPVERSIONS_UPDATE,
        fail: APPVERSIONS_UPDATE_FAIL,
      }
    );
    const normalizedData = normalize(response.data, AppVersionSchema);
    dispatch({ type: APPVERSIONS_UPDATE_OK, payload: normalizedData });
  };
