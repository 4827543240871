import { PlusCircleOutlined } from '@ant-design/icons';
import { Alert, Dropdown, List, Menu } from 'antd';

import styles from './AppsList.module.scss';
import { APP_SETUP_VERSIONS } from '../common/constants/app';
import AppListItem from '../components/AppListItem';
import MbxButton from '../components/base/MbxButton';

import type { App } from '../reducers/AppsReducer';
import type { MenuProps } from 'antd';

type Props = Readonly<{
  allApps: Array<App>;
  handleAddApp: (setupVersion: string) => void;
  handleAppHome: (app: App) => void;
  handleAppEdit: (app: App) => void;
  isLoading: boolean;
  error: string;
}>;

//
//
const AppsList = (props: Props) => {
  const { error, allApps, handleAddApp, handleAppHome, handleAppEdit } = props;

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  const handleReleaseMenuClick: MenuProps['onClick'] = (info) => {
    console.log('handleReleaseMenuClick()', info);
    const { key } = info;
    const setupVersion = key.split('-').pop();
    if (setupVersion != null && APP_SETUP_VERSIONS.includes(setupVersion)) {
      handleAddApp(setupVersion);
    } else {
      console.error('unknown setupVersion', setupVersion);
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <h1>List of all apps</h1>
        <div className={styles.headerExtras}>
          <Dropdown
            overlay={
              <Menu onClick={handleReleaseMenuClick} data-tid="app-create-dropdown">
                {APP_SETUP_VERSIONS.map((v) => (
                  <Menu.Item key={`add-app-menuitem-${v}`}>{`v${v}`}</Menu.Item>
                ))}
              </Menu>
            }
          >
            <MbxButton data-tid="btn-add-app" mbxType="primary" icon={<PlusCircleOutlined />}>
              add app
            </MbxButton>
          </Dropdown>
        </div>
      </div>

      <List
        id="mbx-app-list"
        bordered={false}
        dataSource={allApps}
        renderItem={(app) => (
          <AppListItem app={app} handleAppHome={handleAppHome} handleAppEdit={handleAppEdit} />
        )}
      />
    </div>
  );
};

export default AppsList;
