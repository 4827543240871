import { SearchOutlined } from '@ant-design/icons';
import { Button, AutoComplete, Input } from 'antd';
import { useState } from 'react';

import type { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';
import type { KeyboardEvent } from 'react';

export type SearchItemType =
  | 'search'
  | 'category'
  | 'tag'
  | 'app-relevant-tag'
  | 'asset'
  | 'release'
  | 'episode';
export type SearchItem = {
  id?: number;
  key?: string;
  text: string;
  value: string;
  type: SearchItemType;
  isDraft?: boolean;
  isLocked?: boolean;
  isError?: boolean;
};

type Props = Readonly<{
  handleSelect: (value: string | number) => void;
  dataSource: Array<SearchItem>;
  filterOption: FilterFunc<DefaultOptionType>;
  handleButtonClick: (currentSearch: string) => void;
  id?: string;
}>;

//
//
const ListSearchInput = ({
  handleSelect,
  dataSource,
  handleButtonClick,
  filterOption,
  id,
}: Props) => {
  const [currentSearch, setCurrentSearch] = useState('');
  let wasItemJustSelected = false;

  const handleKeyUp = (ev: KeyboardEvent) => {
    if (ev.key === 'Enter') {
      if (wasItemJustSelected) {
        wasItemJustSelected = false;
        return;
      }

      const q = currentSearch.trim();
      if (q !== '') {
        handleButtonClick(q);
        setCurrentSearch('');
      }
    }
  };

  return (
    <div
      className="global-search-wrapper"
      style={{ width: 300, marginBottom: 4, display: 'flex', justifyContent: 'right' }}
    >
      <AutoComplete
        id={id}
        className="global-search"
        defaultActiveFirstOption={false}
        style={{ width: 'calc(100% - 50px)' }}
        onSelect={(value: string | number) => {
          wasItemJustSelected = true;
          setCurrentSearch('');
          handleSelect(value);
        }}
        onChange={(value: string | number) => {
          setCurrentSearch(value.toString());
        }}
        value={currentSearch}
        options={dataSource.map((item) => ({
          label: item.text,
          value: item.key ?? '??',
        }))}
        filterOption={filterOption}
      >
        <Input
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          autoCapitalize="off"
          onKeyUp={handleKeyUp}
          placeholder="Search"
          style={{
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: 0,
          }}
          // bordered={false}
        />
      </AutoComplete>
      <Button
        data-tid="btn-search"
        style={{
          marginLeft: 0,
          padding: '4px 15px',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
        }}
        type="default"
        onClick={() => {
          const q = currentSearch.trim();
          if (q !== '') {
            handleButtonClick(q);
            setCurrentSearch('');
          }
        }}
      >
        <SearchOutlined />
      </Button>
    </div>
  );
};

export default ListSearchInput;
