import { UPDATE_APP_USER, UPDATE_APP_USER_FAIL, UPDATE_APP_USER_OK } from '../constants/appUsers';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const updateAppUser =
  (appUserId: number, hidden: boolean) => async (dispatch: AppDispatch) => {
    const result = await wrapFetch(
      {
        url: `/appusers/${appUserId}`,
        method: 'PUT',
        data: {
          hidden,
        },
      },
      dispatch,
      {
        init: UPDATE_APP_USER,
        fail: UPDATE_APP_USER_FAIL,
      },
      200
    );
    console.log('RESULT', result.data);
    dispatch({
      type: UPDATE_APP_USER_OK,
      appUserId,
      hidden: result.data.hidden,
    });
  };
