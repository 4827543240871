import { HISTORY_PUSH, HISTORY_POP, HISTORY_REPLACE } from '../constants/pagehistory';

import type { LocationItem } from '../reducers/PageHistoryReducer';

//
export const pop = (location: LocationItem) => ({
  type: HISTORY_POP,
  location,
});

//
export const push = (location: LocationItem) => ({
  type: HISTORY_PUSH,
  location,
});

//
export const replace = (location: LocationItem) => ({
  type: HISTORY_REPLACE,
  location,
});
