import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';

import MbxButton from './base/MbxButton';
import styles from './ReleaseMultiEpisodeSelector.module.scss';
import { getEpisodePlayerTypeInfo } from '../utils/episode';

import type { Episode } from '../reducers/EpisodeReducer';

type Props = {
  form: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  unreleasedEpisodes: Array<Episode>;
  alreadySelectedEpisodes: Array<number>;
  setAlreadySelectedEpisodes: (ids: Array<number>) => void;
};

//
//
const ReleaseMultiEpisodeSelector = (props: Props) => {
  const { unreleasedEpisodes, form, alreadySelectedEpisodes, setAlreadySelectedEpisodes } = props;

  console.log('ReleaseMultiEpisodeSelector()', {
    unreleasedEpisodes,
    form,
    alreadySelectedEpisodes,
    setAlreadySelectedEpisodes,
  });

  const handleEpisodeSelect = (index: number, value: number) => {
    console.log('handleEpisodeSelect()', index, value);
    const newSelectedEpisodes = [...alreadySelectedEpisodes];
    newSelectedEpisodes[index] = value;
    setAlreadySelectedEpisodes(newSelectedEpisodes);

    const fieldName = `episodeId${index > 0 ? index : ''}`;
    form.setFieldsValue({ [fieldName]: value });
  };

  const handleMultiEpisodeAdd = () => {
    setAlreadySelectedEpisodes([...alreadySelectedEpisodes, -1]);
  };

  const handleMultiEpisodeRemove = (index: number) => {
    const newSelectedEpisodes = [...alreadySelectedEpisodes];
    const formerLength = newSelectedEpisodes.length;
    newSelectedEpisodes.splice(index, 1);
    console.log('handleMultiEpisodeRemove()', index, newSelectedEpisodes);
    setAlreadySelectedEpisodes(newSelectedEpisodes);

    const newValues: { [k: string]: number | undefined } = {};
    for (let i = 0; i < formerLength; i++) {
      const val = newSelectedEpisodes[i];
      const fieldName = `episodeId${i > 0 ? i : ''}`;
      newValues[fieldName] = val === -1 ? undefined : val;
    }
    form.setFieldsValue(newValues);
  };

  return (
    <>
      {unreleasedEpisodes.map((ee, index) => {
        if (index >= alreadySelectedEpisodes.length) {
          return null;
        }

        return (
          <Form.Item
            label={index === 0 ? 'Episode' : undefined}
            key={`episode-input-${index}`}
            data-tid={`episode-input-${index}`}
            style={{
              marginTop: index > 0 ? 0 : '1em',
              marginBottom: index < alreadySelectedEpisodes.length - 1 ? 8 : '1em',
            }}
          >
            <div style={{ display: 'flex' }}>
              <Form.Item
                name={index === 0 ? 'episodeId' : `episodeId${index}`}
                rules={[{ required: index === 0, message: 'Please select an Episode!' }]}
                noStyle
              >
                <Select
                  showSearch
                  data-tid={`episode-select-${index}`}
                  placeholder="Select an episode"
                  filterOption={(input, option) =>
                    option?.['data-tval'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(value: number) => handleEpisodeSelect(index, value)}
                  value={
                    alreadySelectedEpisodes[index] === -1
                      ? undefined
                      : alreadySelectedEpisodes[index]
                  }
                >
                  {unreleasedEpisodes.map((e: Episode) => {
                    const alreadySelectedEpisodesCopy = [...alreadySelectedEpisodes];
                    alreadySelectedEpisodesCopy.splice(index, 1);
                    const isAlreadySelectedSomewhere = alreadySelectedEpisodesCopy.includes(e.id);
                    if (isAlreadySelectedSomewhere) {
                      return null;
                    }
                    const resourceInfo = getEpisodePlayerTypeInfo(e);
                    return (
                      <Select.Option key={e.id} value={e.id} data-tval={e.name}>
                        <div
                          className={styles.selectItem}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <span
                            className={styles.bubble}
                            style={{
                              backgroundColor: `${resourceInfo.color}4C`, // 4C => ~30% (see https://stackoverflow.com/a/8254129/388026)
                            }}
                          >
                            {resourceInfo.label}
                          </span>
                          <span>{e.name}</span>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {alreadySelectedEpisodes.length > 1 && index <= alreadySelectedEpisodes.length && (
                <MbxButton
                  data-tid="remove-episodeselect-btn"
                  size="middle"
                  icon={<MinusOutlined />}
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    handleMultiEpisodeRemove(index);
                  }}
                />
              )}
              {index === alreadySelectedEpisodes.length - 1 &&
                index !== unreleasedEpisodes.length - 1 && (
                  <MbxButton
                    data-tid="add-episodeselect-btn"
                    size="middle"
                    icon={<PlusOutlined />}
                    style={{ marginLeft: 10 }}
                    onClick={handleMultiEpisodeAdd}
                  />
                )}
            </div>
          </Form.Item>
        );
      })}
    </>
  );
};

export default ReleaseMultiEpisodeSelector;
