export const ASSETRESOURCE_CREATE = 'ASSETRESOURCE_CREATE';
export const ASSETRESOURCE_CREATE_OK = 'ASSETRESOURCE_CREATE_OK';
export const ASSETRESOURCE_CREATE_FAIL = 'ASSETRESOURCE_CREATE_FAIL';

export const ASSETRESOURCE_UPDATE = 'ASSETRESOURCE_UPDATE';
export const ASSETRESOURCE_UPDATE_OK = 'ASSETRESOURCE_UPDATE_OK';
export const ASSETRESOURCE_UPDATE_FAIL = 'ASSETRESOURCE_UPDATE_FAIL';

export const ASSETRESOURCE_DELETE = 'ASSETRESOURCE_DELETE';
export const ASSETRESOURCE_DELETE_OK = 'ASSETRESOURCE_DELETE_OK';
export const ASSETRESOURCE_DELETE_FAIL = 'ASSETRESOURCE_DELETE_FAIL';
