import merge from 'lodash.merge';
import { combineReducers } from 'redux';

import { APPPLATFORMS_CREATE_OK } from '../constants/appplatforms';
import {
  GET_APPS_LIST,
  GET_APPS_LIST_OK,
  GET_APPS_LIST_FAIL,
  GET_APP_DETAILS_OK,
  REMOVE_USER_FROM_APP_OK,
  APP_UPDATE_OK,
  APP_UPDATE_CONFIG_OK,
  APP_CREATE,
  APP_CREATE_OK,
  APP_CREATE_FAIL,
  APP_DELETE_ICON_OK,
  APP_UPDATE_ICON_OK,
} from '../constants/apps';
import { LOGIN_OK } from '../constants/auth';
import { JOB_GET_METADATA_OK, JOB_GET_DETAILS_OK } from '../constants/jobs';
import { USER_GET_DETAIL_OK, USER_INVITE_ACCEPT_OK } from '../constants/users';

import type { AppAuthService } from './AppAuthServiceReducer';
import type { AppPlatform } from './AppPlatformReducer';
import type { Icon } from './ResourceReducer';
import type { Configuration } from '../common/types/app';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type AppBase = {
  id: number;
  uid: string;
  name: string;
  hash: string;
  contentBucket: string;
  archiveBucket: string;
  roleArn: string;
  cfIdPub: string;
  cfIdSec: string | null;
  publicBaseUrl: string;
  privateBaseUrl: string;
  createdBy: number;
  createdAt: Date;
  updatedAt: Date | null;
  configuration: Configuration;
  users: Array<number>;
  setupVersion: string;
};

export type AppNormalized = Merge<
  AppBase,
  {
    platforms: Array<number>;
    authServices: Array<number>;
    icon: number | null;
  }
>;

export type App = Merge<
  AppBase,
  {
    // users: Array<UserNormalized>;
    platforms: Array<AppPlatform>;
    authServices: Array<AppAuthService>;
    icon: Icon | null;
  }
>;

export type AppUiState = {
  isCreatingApp: boolean;
  isCreatingAppError: string;
  isFetchingAppList: boolean;
  isFetchingAppListError: string;
};

export type AppByIdState = {
  [k: string | number]: AppNormalized;
};
export type AppAllIdsState = Array<number>;
export type AppState = {
  byId: AppByIdState;
  allIds: AppAllIdsState;
  ui: AppUiState;
};

//
//
export const getEmptyAppNormalized = (id?: number, uid = ''): AppNormalized => ({
  id: id ?? -1,
  uid,
  name: '',
  hash: '',
  contentBucket: '',
  archiveBucket: '',
  roleArn: '',
  cfIdPub: '',
  cfIdSec: null,
  publicBaseUrl: '',
  privateBaseUrl: '',
  createdBy: -1,
  createdAt: new Date(),
  updatedAt: null,
  users: [],
  platforms: [],
  authServices: [],
  configuration: [],
  setupVersion: '1.0',
  icon: null,
});

//
//
export const getEmptyApp = (id?: number, uid = ''): App => ({
  id: id ?? -1,
  uid,
  name: '',
  hash: '',
  contentBucket: '',
  archiveBucket: '',
  roleArn: '',
  cfIdPub: '',
  cfIdSec: null,
  publicBaseUrl: '',
  privateBaseUrl: '',
  createdBy: -1,
  createdAt: new Date(),
  updatedAt: null,
  users: [],
  platforms: [],
  authServices: [],
  configuration: [],
  setupVersion: '1.0',
  icon: null,
});

const initialStateById: AppByIdState = {};
const initialStateAllIds: AppAllIdsState = [];
const initialStateUi: AppUiState = {
  isCreatingApp: false,
  isCreatingAppError: '',
  isFetchingAppList: false,
  isFetchingAppListError: '',
};
//
//
const byId = (state = initialStateById, action: AnyAction): AppByIdState => {
  switch (action.type) {
    case GET_APPS_LIST_OK:
      return {
        ...action.payload.entities.apps,
      };

    case LOGIN_OK:
    case USER_GET_DETAIL_OK:
    case JOB_GET_METADATA_OK:
    case JOB_GET_DETAILS_OK:
      return merge({}, state, action.payload.entities.apps);

    case GET_APP_DETAILS_OK:
    case APP_UPDATE_OK:
    case APP_UPDATE_CONFIG_OK:
    case APP_UPDATE_ICON_OK:
    case APP_CREATE_OK: {
      const id = action.payload.result;
      return {
        ...state,
        [id]: action.payload.entities.apps[id],
      };
    }

    case USER_INVITE_ACCEPT_OK: {
      const apps = action?.updatedUser?.entities?.apps;
      if (apps != null) {
        return {
          ...state,
          ...apps,
        };
      }

      return state;
    }

    case REMOVE_USER_FROM_APP_OK: {
      const id = action.payload.appId;

      return {
        ...state,
        [id]: {
          ...state[id],
          users: state[id].users.filter((u) => u !== action.payload.userId),
        },
      };
    }
    case APP_DELETE_ICON_OK: {
      const { appId } = action.payload;
      const app = state[appId];
      return { ...state, [appId]: { ...app, icon: null } };
    }

    case APPPLATFORMS_CREATE_OK: {
      const { appId, payload } = action;
      const addedPlatform = payload?.entities?.appPlatforms;

      if (addedPlatform != null) {
        const addedPlatformId = Object.keys(addedPlatform).map((key) => addedPlatform[key])[0]
          .platform as number;

        const app = state[appId];
        if (app != null) {
          return { ...state, [appId]: { ...app, platforms: [...app.platforms, addedPlatformId] } };
        }
      }
      return state;
    }

    default:
      return state;
  }
};

//
//
const allIds = (state = initialStateAllIds, action: AnyAction): AppAllIdsState => {
  switch (action.type) {
    case APP_CREATE_OK:
      return [...state, action.payload.result];
    case GET_APPS_LIST_OK:
      return action.payload.result;

    case USER_INVITE_ACCEPT_OK: {
      const { updatedUser } = action;
      const { entities } = updatedUser;

      if (entities.apps != null) {
        const newState = [...state];
        Object.keys(entities.apps).forEach((appIdStr) => {
          const appId = parseInt(appIdStr, 10);
          if (!newState.includes(appId)) {
            newState.push(appId);
          }
        });
        return newState;
      }
      return state;
    }

    case LOGIN_OK: {
      if (action.payload?.entities?.apps == null) {
        return state;
      }
      const { apps } = action.payload.entities;
      const ids = Object.keys(apps).map((k) => apps[k].id);
      const uniqueIds = [...new Set(state.concat(ids))];
      return uniqueIds;
    }

    default:
      return state;
  }
};

//
//
const ui = (state = initialStateUi, action: AnyAction): AppUiState => {
  switch (action.type) {
    case APP_CREATE:
      return {
        ...state,
        isCreatingApp: true,
        isCreatingAppError: '',
      };

    case APP_CREATE_OK:
      return {
        ...state,
        isCreatingApp: false,
        isCreatingAppError: '',
      };

    case APP_CREATE_FAIL:
      return {
        ...state,
        isCreatingApp: false,
        isCreatingAppError: action.payload.message,
      };

    case GET_APPS_LIST:
      return {
        ...state,
        isFetchingAppList: true,
        isFetchingAppListError: '',
      };

    case GET_APPS_LIST_OK:
      return {
        ...state,
        isFetchingAppList: false,
        isFetchingAppListError: '',
      };

    case GET_APPS_LIST_FAIL:
      return {
        ...state,
        isFetchingAppList: false,
        isFetchingAppListError: action.payload.message,
      };

    default:
      return state;
  }
};

const reducers = combineReducers({
  byId,
  allIds,
  ui,
});

export default reducers;
