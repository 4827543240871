import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip, Tag } from 'antd';

type Props = Readonly<{
  label?: string;
  tooltip?: string;
  useDefaultAsFallback: boolean;
  // style?: React.CSSProperties;
  className?: string;
}>;

//
//
const RegionLocaleDefaultTag = (props: Props) => {
  const {
    label = 'default',
    tooltip = 'default but not fallback',
    useDefaultAsFallback,
    className,
    // style = {},
  } = props;

  const highlightSpecialCase = useDefaultAsFallback === false;

  const tag = (
    <Tag
      color="cyan"
      icon={highlightSpecialCase === true ? <ExclamationCircleOutlined /> : null}
      // style={{ ...style }}
      className={className}
    >
      {label}
    </Tag>
  );

  if (highlightSpecialCase === true) {
    return <Tooltip title={tooltip}>{tag}</Tooltip>;
  }
  return tag;
};

export default RegionLocaleDefaultTag;
