import { createSelector } from 'reselect';

import type { RootState } from '../reducers';

const byId = (state: RootState) => state.appAnalytics.byId;

export const getAppAnalyticsByIdFunc = createSelector(byId, ($byId) => (id: number) => {
  const appAnalytics = $byId[id];
  if (appAnalytics == null) {
    return null;
  }
  return appAnalytics;
});

export const getAppAnalyticsById = createSelector(
  getAppAnalyticsByIdFunc,
  (_: unknown, appId: number) => appId,
  ($getAppAnalyticsByIdFunc, appId) => $getAppAnalyticsByIdFunc(appId)
);
