import { LeftOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useHistory } from 'react-router-dom';

import { getPreviousPages } from '../selectors/pageHistory';
import { useGlobalSelector } from '../utils/hooks';

import type { PageHeaderProps } from '@ant-design/pro-layout';
import type { Merge } from 'type-fest';

type OwnProps = Readonly<{
  backLink?: string;
}>;

type Props = Merge<OwnProps, PageHeaderProps>;

const CustomPageHeader = (props: Props) => {
  const history = useHistory();
  const previousPages = useGlobalSelector(getPreviousPages);

  const { backLink, ...rest } = props;

  let x: string | undefined;
  if (backLink != null) {
    x = backLink;
    if (previousPages.length > 1) {
      const prev = previousPages[previousPages.length - 2];
      const [path] = prev.path.split('?');
      if (path === backLink) {
        x = prev.path;
      }
    }
  }

  return (
    <PageHeader
      {...rest}
      backIcon={x != null ? <LeftOutlined /> : null}
      onBack={() => {
        if (x != null) {
          history.push(x);
        }
      }}
      style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
    />
  );
};

export default CustomPageHeader;
