export const CATEGORY_GET = 'CATEGORY_GET';
export const CATEGORY_GET_OK = 'CATEGORY_GET_OK';
export const CATEGORY_GET_FAIL = 'CATEGORY_GET_FAIL';

export const CATEGORY_GET_LIST = 'CATEGORY_GET_LIST';
export const CATEGORY_GET_LIST_OK = 'CATEGORY_GET_LIST_OK';
export const CATEGORY_GET_LIST_FAIL = 'CATEGORY_GET_LIST_FAIL';

export const CATEGORY_CREATE = 'CATEGORY_CREATE';
export const CATEGORY_CREATE_OK = 'CATEGORY_CREATE_OK';
export const CATEGORY_CREATE_FAIL = 'CATEGORY_CREATE_FAIL';

export const CATEGORY_UPDATE = 'CATEGORY_UPDATE';
export const CATEGORY_UPDATE_OK = 'CATEGORY_UPDATE_OK';
export const CATEGORY_UPDATE_FAIL = 'CATEGORY_UPDATE_FAIL';

export const CATEGORY_REMOVE = 'CATEGORY_REMOVE';
export const CATEGORY_REMOVE_OK = 'CATEGORY_REMOVE_OK';
export const CATEGORY_REMOVE_FAIL = 'CATEGORY_REMOVE_FAIL';
