import { APP_UPDATE_CONFIG_OK } from '../constants/apps';
import { CALENDARFEED_GENERATE_URLS_OK, CALENDARFEED_GET_URLS_OK } from '../constants/calendarFeed';

import type { AnyAction } from 'redux';

export type CalendarFeedDataBase = {
  id: number;
  appId: number;
  userId: number;
  typeId: number;
  dataHash: string;
  createdAt: Date;
  updatedAt: Date | null;
};

export type CalendarUrlsType = {
  published: string;
  scheduled: string;
  incomplete: string;
};

export type CalendarFeedState = {
  [x: string | number]: Array<CalendarFeedDataBase>;
};

const calendarFeedInitialState: CalendarFeedState = {};

const byAppId = (state = calendarFeedInitialState, action: AnyAction): CalendarFeedState => {
  switch (action.type) {
    case CALENDARFEED_GET_URLS_OK:
    case CALENDARFEED_GENERATE_URLS_OK: {
      const { payload: data, appId } = action;

      if (Array.isArray(data) === false) {
        return state;
      }

      return {
        ...state,
        [appId]: data,
      };
    }
    //Reset state to force reload after app config change
    case APP_UPDATE_CONFIG_OK: {
      return {};
    }
    default:
      return state;
  }
};

export default byAppId;
