import { Form, Input, Button } from 'antd';

import ButtonLinked from './ButtonLinked';
import styles from './LoginForm.module.scss';
import { ROUTE_NAME_LOGIN } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

import type { RegisterInfo } from '../containers/RegisterFormContainer';
import type { UserSignup } from '../reducers/UsersReducer';

type Props = Readonly<{
  prefilledEmail?: string;
  noLoginButtonAfterSuccess: boolean;
  registerInfo: RegisterInfo;
  isUserLoggedIn: boolean;
  onRegister: (data: UserSignup) => Promise<void>;
}>;

//
//
const RegisterForm = (props: Props) => {
  const { registerInfo, prefilledEmail, onRegister, noLoginButtonAfterSuccess } = props;

  if (registerInfo.success === true) {
    return (
      <div key="form-register-container" data-tid="form-register-container">
        <h2>Successfully registered!</h2>
        <p>
          We sent you an email with a confirmation-link - you have to confirm your email-address
          before trying to log in.
        </p>
        {!noLoginButtonAfterSuccess && (
          <ButtonLinked linkTo={getUrlByName(ROUTE_NAME_LOGIN)} type="primary">
            Log in
          </ButtonLinked>
        )}
      </div>
    );
  }

  const handleSubmit = (values: UserSignup) => {
    onRegister(values);
  };

  return (
    <div key="form-register-container" data-tid="form-register-container">
      <Form onFinish={handleSubmit} className={styles.loginForm} name="RegisterForm">
        <Form.Item
          label="Email"
          name="email"
          initialValue={prefilledEmail ?? undefined}
          rules={[{ required: true }]}
        >
          <Input data-tid="register-input-email" disabled={prefilledEmail != null} type="email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input data-tid="register-input-password" type="password" />
        </Form.Item>
        <Form.Item
          label="Firstname"
          name="firstname"
          rules={[{ required: true, message: 'Please input firstname!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Lastname"
          name="lastname"
          rules={[{ required: true, message: 'Please input lastname!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            data-tid="register-input-submit"
            loading={registerInfo.isActive}
            type="primary"
            htmlType="submit"
            className={styles.loginFormButton}
          >
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegisterForm;
