const UNITS = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte', 'petabyte'];
const BYTES_PER_KB = 1024;

type Options = {
  locale?: string | Array<string>;
  fractionDigits?: number;
};

// taken form here: https://stackoverflow.com/a/72596863/388026
//
export function humanFileSize(sizeBytes: number | bigint, options: Options = {}): string {
  let size = Math.abs(Number(sizeBytes));

  let { locale, fractionDigits } = options;

  if (locale == null) {
    try {
      locale = window.navigator.language; // eslint-disable-line no-undef
    } catch (err) {
      locale = [];
    }
  }

  if (fractionDigits == null) {
    fractionDigits = 2;
  }

  let u = 0;
  while (size >= BYTES_PER_KB && u < UNITS.length - 1) {
    size /= BYTES_PER_KB;
    u += 1;
  }

  return new Intl.NumberFormat(locale, {
    style: 'unit',
    unit: UNITS[u],
    unitDisplay: 'short',
    maximumFractionDigits: fractionDigits,
  }).format(size);
}
