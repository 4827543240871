import { createSelector } from 'reselect';

import { getCategoryByIdFunc } from './categorySelectors';

import type { RootState } from '../reducers';
import type {
  ReleaseCategory,
  ReleaseCategoryNormalized,
} from '../reducers/ReleaseCategoryReducer';

//
//
const getById = (state: RootState) => state.releaseCategories.byId;

//
//
export const getReleaseCategoryByIdFunc = createSelector(
  getById,
  getCategoryByIdFunc,
  //
  (byId, getCategoryById) => (id: number) => {
    const releaseCategory: ReleaseCategoryNormalized = byId[id];
    if (releaseCategory == null) {
      return null;
    }
    return {
      ...releaseCategory,
      category: getCategoryById(releaseCategory.category),
    };
  }
);

//
//
export const getReleaseCategoriesByIdsFunc = createSelector(
  getReleaseCategoryByIdFunc,
  //
  (getReleaseCategoryById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }

    return ids
      .map((id: number) => getReleaseCategoryById(id))
      .filter((rc): rc is ReleaseCategory => !!rc);
  }
);

//
//
export const getAllReleaseCategories = createSelector(
  getById,
  getReleaseCategoryByIdFunc,
  //
  (byId, getReleaseCategoryById) =>
    Object.keys(byId)
      .map((id: string) => getReleaseCategoryById(parseInt(id, 10)))
      .filter((rc): rc is ReleaseCategory => !!rc)
);
