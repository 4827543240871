import { Form, Input } from 'antd';
import { useState, useEffect } from 'react';

import type { ChangeEventHandler } from 'react';

const TEXTAREA_MAX_LENGTH = 500;

const { TextArea } = Input;

type Props = {
  label: string;
  initialValue: string;
  num: number;
  // maxLength?: number;
};

//
//
const TextareaWithCounter = (props: Props) => {
  const { initialValue, label, num } = props;
  const [dataLength, setDataLength] = useState(initialValue.length);

  useEffect(() => {
    setDataLength(() => initialValue.length);
  }, [initialValue]);

  const handleTextAreaUpdate: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setDataLength(e.target.value.length);
  };

  return (
    <Form.Item label={label} style={{ marginBottom: 2 }}>
      <div style={{ lineHeight: 1 }}>
        <Form.Item name={`data${num}`} initialValue={initialValue} style={{ marginBottom: 0 }}>
          <TextArea
            maxLength={TEXTAREA_MAX_LENGTH}
            style={{ marginBottom: 0 }}
            onChange={(e) => {
              console.log('onChange ...');
              handleTextAreaUpdate(e);
            }}
          />
        </Form.Item>
      </div>
      <p
        style={{
          margin: 0,
          padding: '4px 0 0 0',
          lineHeight: 1,
          fontSize: '11px',
          textAlign: 'right',
          color: dataLength > Math.round(TEXTAREA_MAX_LENGTH * 0.95) ? 'red' : 'inherit',
        }}
      >
        {`${dataLength}/${TEXTAREA_MAX_LENGTH}`}
      </p>
    </Form.Item>
  );
};

export default TextareaWithCounter;
