import * as Sentry from '@sentry/browser';
import { notification } from 'antd';
import throttle from 'lodash.throttle';
import { schema, normalize } from 'normalizr';

import { changeIcon } from './apps';
import { addAssetResource } from './assetresources';
import { getUploadUrl } from './batchUploadItem';
import { addEpisodeLocalizedContent } from './episodeLocalized';
import { createResource } from './resources';
import {
  ADD_TO_QUEUE,
  QUEUE_ITEM_FOR_UPLOAD,
  UPLOAD_START,
  UPLOAD_PROGRESS,
  UPLOAD_DONE,
  UPLOAD_FAILED,
  UPLOAD_CANCELED,
  FILE_STATUS_UPDATE,
  FILE_STATUS_UPDATE_OK,
  FILE_STATUS_UPDATE_FAIL,
} from '../constants/queue';
import { QueueItemType } from '../reducers/QueueReducer';
import { FileSchema } from '../schemas';
import { getAllQueueItems } from '../selectors/queueSelectors';
import { createUpload, wrapFetch } from '../utils/api';
import { uuidv4 } from '../utils/crypto';
import { logUnknownError } from '../utils/log';
import { canUploadAsMultiPart, multipartUpload } from '../utils/multipart';
import { MultipartResponseCode } from '../utils/multipart-constants';

import type { AppDispatch } from '../configureStore';
import type { GlobalStateGetter } from '../reducers/index';
import type { QueueData, QueueItem } from '../reducers/QueueReducer';
import type { Resource } from '../reducers/ResourceReducer';

export type UploadType = 'episode' | 'asset';

// we are not storing these in state but rather here b/c they changed and redux was complaining
// about "invariant state changes"
const fileRefs: Record<string, File> = {};
const xhrs: Record<string, XMLHttpRequest> = {};

//
//
export const addUploadToQueue = (info: QueueData, fileRef: File) => {
  const id = uuidv4();

  // add fileRef to list so that we don't need to store Files in state
  fileRefs[id] = fileRef;

  return {
    type: ADD_TO_QUEUE,
    payload: {
      ...info,
      id,
      createdAt: new Date(),
      state: 'added',
      percent: 0,
      resultId: null,
    },
  };
};

//
//
export const removeFromQueue =
  (type: UploadType, parentId: number, resultId: number) =>
  (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const queueItems = getAllQueueItems(getState());
    const item = queueItems.find((qi) => {
      if (type === 'episode') {
        return qi.episodeLocalizedId === parentId && qi.resultId === resultId;
      }
      if (type === 'asset') {
        return qi.assetId === parentId && qi.resultId === resultId;
      }
      return false;
    });

    if (item != null) {
      const xhr = xhrs[item.id];
      if (xhr != null) {
        xhr.abort();
        dispatch({
          type: UPLOAD_CANCELED,
          payload: item.id,
        });
        // remove fileRef from list
        delete fileRefs[item.id];
        delete xhrs[item.id];
      }
    }
  };

export const removeBatchUploadFromQueue =
  (appId: number) => (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const queuedItems = getAllQueueItems(getState());
    const currentlyUploadingItems = queuedItems.filter(
      ({ type, appId: id }) => type === QueueItemType.BATCH_CONTENT && appId === id
    );

    for (const item of currentlyUploadingItems) {
      dispatch({
        type: UPLOAD_CANCELED,
        payload: item.id,
      });
      const xhr = xhrs[item.id];
      if (xhr != null) {
        xhr.abort();
        // remove fileRef from list
        delete fileRefs[item.id];
        delete xhrs[item.id];
      }
    }
  };

//
//
export const checkNextInQueue = () => {
  console.log('action checkNextInQueue()');
  return async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const queueItems = getAllQueueItems(getState());
    console.log('checkNextInQueue()', queueItems);
    if (queueItems.length === 0) {
      return;
    }

    // check if there is already one upload -> if yes, skip for now
    const isUploading = queueItems.find(
      (item) => item.state === 'uploading' || item.state === 'initializing'
    );
    if (isUploading != null) {
      return;
    }

    const nextItem: QueueItem | undefined = queueItems.find((item) => item.state === 'added');

    if (nextItem != null) {
      dispatch({ type: QUEUE_ITEM_FOR_UPLOAD, payload: nextItem.id });

      try {
        let uploadUrl = '';
        let resource: Resource | { id: number } = { id: -1 };

        const sendProgress = (percent: number) => {
          dispatch({ type: UPLOAD_PROGRESS, payload: { id: nextItem.id, percent } });
        };
        const throttledSendProgress = throttle(sendProgress, 200);

        if (canUploadAsMultiPart(nextItem)) {
          dispatch({
            type: UPLOAD_START,
            payload: nextItem.id,
            resultId: nextItem.batchContent.id,
          });

          // TODO WBP-2232 if some parts are already uploaded, dispatch percent

          const code = await multipartUpload(nextItem, dispatch, sendProgress);

          if (code === MultipartResponseCode.FAILURE) {
            dispatch({ type: UPLOAD_FAILED, payload: nextItem.id });
          }

          if (code !== MultipartResponseCode.FAILURE) {
            dispatch({ type: UPLOAD_DONE, payload: nextItem.id });

            notification.success({
              message: 'Upload done',
              description: nextItem.fileRef.name,
              duration: 2,
            });
          }

          dispatch(checkNextInQueue());

          return;
        }

        switch (nextItem.type) {
          case QueueItemType.ASSET:
          case QueueItemType.EPISODE: {
            if (nextItem.contentType == null || nextItem.resourceType == null) {
              throw Error('Content or resource type not set');
            }

            ({ data: resource, uploadUrl } = await dispatch(
              createResource(
                nextItem.appId,
                nextItem.fileRef.name,
                nextItem.resourceType,
                nextItem.fileRef.type,
                nextItem.contentType
              )
            ));

            console.log('resource', resource);
            break;
          }
          case QueueItemType.ICON: {
            if (nextItem.resourceType == null) {
              throw Error('Content or resource type not set');
            }

            ({ data: resource, uploadUrl } = await dispatch(
              createResource(
                nextItem.appId,
                nextItem.fileRef.name,
                nextItem.resourceType,
                nextItem.fileRef.type
              )
            ));

            console.log('resource', resource);
            break;
          }
          case QueueItemType.BATCH_CONTENT: {
            if (!nextItem.batchContent) {
              throw new Error('Batch file content not set');
            }
            ({ url: uploadUrl } = await dispatch(
              getUploadUrl(nextItem.appId, nextItem.batchContent.id)
            ));
            break;
          }
          default: {
            dispatch({
              type: UPLOAD_FAILED,
              payload: nextItem.id,
              error: new Error('unknown item queued'),
            });
            notification.error({
              message: 'Upload failed',
              description: nextItem.fileRef.name,
            });
            dispatch(checkNextInQueue());
            return;
          }
        }

        console.log('uploadUrl', uploadUrl);

        let resultId: number | null = null;

        switch (nextItem.type) {
          case QueueItemType.ASSET: {
            if (nextItem.assetId == null) {
              throw new Error('assetId not set');
            }

            const assetResult = await dispatch(addAssetResource(nextItem.assetId, resource.id));
            resultId = assetResult.id;
            break;
          }
          case QueueItemType.EPISODE: {
            if (nextItem.episodeLocalizedId == null) {
              throw new Error('episodeLocalizedId not set');
            }

            if (nextItem.contentType == null || nextItem.resourceType == null) {
              throw Error('Content or resource type not set');
            }

            const contentResult = await dispatch(
              addEpisodeLocalizedContent(
                nextItem.episodeLocalizedId,
                nextItem.contentType,
                resource.id,
                nextItem.appPlatformId,
                nextItem.appId
              )
            );
            resultId = contentResult.id;
            break;
          }
          case QueueItemType.ICON: {
            const iconId = await dispatch(changeIcon(nextItem.appId, resource.id));
            if (iconId == null) {
              throw new Error('Icon id not found');
            }
            resultId = iconId;
            break;
          }
          case QueueItemType.BATCH_CONTENT: {
            if (!nextItem.batchContent) {
              throw new Error('Batch file content not set');
            }
            resultId = nextItem.batchContent.id;
            break;
          }
        }

        // add resource-info to upload-queue-item
        // nextItem.resourceId = resource.id;
        // TODO: if this step fails, delete resource again :(
        //       maybe think about combining this into one step to improve handling in API

        try {
          const actualFileRef = fileRefs[nextItem.id];
          if (actualFileRef == null) {
            throw new Error('fileRef not found in list');
          }
          const { xhr, upload } = createUpload(uploadUrl, actualFileRef, (event) => {
            if (event.lengthComputable) {
              const percent = Math.round((event.loaded / event.total) * 100);
              throttledSendProgress(percent);
            }
          });

          xhrs[nextItem.id] = xhr;

          dispatch({ type: UPLOAD_START, payload: nextItem.id, resultId });
          await upload();

          dispatch({ type: UPLOAD_DONE, payload: nextItem.id });

          notification.success({
            message: 'Upload done',
            description: nextItem.fileRef.name,
            duration: 2,
          });
        } catch (err) {
          console.error(err);
          dispatch({ type: UPLOAD_FAILED, payload: nextItem.id, error: err });
          notification.error({
            message: 'Upload failed',
            description: nextItem.fileRef.name,
          });
          Sentry.captureException(err);
        } finally {
          // remove fileRef from list b/c we don't need it anymore
          delete fileRefs[nextItem.id];
          delete xhrs[nextItem.id];
        }
      } catch (err) {
        const { msg } = logUnknownError(err);
        dispatch({
          type: UPLOAD_FAILED,
          payload: nextItem.id,
          error: err,
        });
        notification.error({
          message: 'Upload failed',
          description: `Error for ${nextItem.fileRef.name}: ${msg}`,
        });
        Sentry.captureException(err);
      }

      dispatch(checkNextInQueue());
    }
  };
};

//
//
export const checkFilesStatus = (fileIds: Array<number>) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/resources/filestatus`,
      params: {
        // allow maximum of 100 ids (performance ...)
        ids: fileIds.slice(0, 100).join(','),
      },
    },
    dispatch,
    {
      init: FILE_STATUS_UPDATE,
      fail: FILE_STATUS_UPDATE_FAIL,
    }
  );
  const normalizedData = normalize(response.data, new schema.Array(FileSchema));
  // const allResources = getAllResources(getState());
  // dispatch({ type: FILE_STATUS_UPDATE_OK, payload: normalizedData, allResources });
  dispatch({ type: FILE_STATUS_UPDATE_OK, payload: normalizedData });
  return normalizedData;
};
