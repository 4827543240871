import { createSelector } from 'reselect';

import { getEmptyApp } from '../../reducers/AppsReducer';
import { sortByName } from '../../utils/sort';
import { getAppAuthServicesByIdsFunc } from '../appAuthServices/advanced';
import { getAppPlatformsByIdsFunc } from '../appPlatformSelectors';
import { getResourceByIdFunc } from '../resourceSelectors';

import { getAppNormalizedByIdFunc, getAppsNormalizedByIdsFunc } from './index';

import type { RootState } from '../../reducers';
import type { App } from '../../reducers/AppsReducer';
import type { Selector } from 'reselect';

//
//
const getById = (state: RootState) => state.apps.byId;
const getAllIds = (state: RootState) => state.apps.allIds;
export const getAppUiState = (state: RootState) => state.apps.ui;

//
//
export const getAppByIdFunc: Selector<RootState, (id: number) => App> = createSelector(
  getAppNormalizedByIdFunc,
  getAppAuthServicesByIdsFunc,
  getAppPlatformsByIdsFunc,
  getResourceByIdFunc,
  // getUsersNormalizedByIdsFunc,
  //
  (
      $getAppNormalizedByIdFunc,
      $getAppAuthServicesByIds,
      $getAppPlatformsByIds,
      $getIconByIdFunc
      // $getUsersNormalizedByIds
    ) =>
    (id: number) => {
      const app = $getAppNormalizedByIdFunc(id);

      return {
        ...app,
        platforms: $getAppPlatformsByIds(app.platforms),
        // users: $getUsersNormalizedByIds(app.users),
        authServices: $getAppAuthServicesByIds(app.authServices),
        icon: app.icon !== null ? $getIconByIdFunc(app.icon) : null,
      };
    }
);

//
//
export const getAppById = createSelector(
  getAppByIdFunc,
  (_: unknown, appId: number) => appId,
  //
  ($getAppById, appId) => $getAppById(appId)
);

//
//
export const getAppsNormalizedByIds = createSelector(
  getAppsNormalizedByIdsFunc,
  (_: unknown, appIds: Array<number>) => appIds,
  //
  ($getAppsNormalizedByIds, appIds) => $getAppsNormalizedByIds(appIds)
);

//
//
export const getAppsByIdsFunc = createSelector(
  getAppByIdFunc,
  //
  ($getAppById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id) => $getAppById(id)).filter((a): a is App => !!a);
  }
);

//
//
export const getAppsByIds = createSelector(
  getAppsByIdsFunc,
  (_: unknown, appIds: Array<number>) => appIds,
  //
  ($getAppsByIds, appIds) => $getAppsByIds(appIds)
);

//
//
export const getAllApps = createSelector(
  getAllIds,
  getAppsByIdsFunc,
  //
  (allIds, $getAppsByIds) => {
    const apps = $getAppsByIds(allIds);
    return apps.sort(sortByName);
  }
);

//
//
export const getAppByUidFunc: Selector<RootState, (uid: string | null | undefined) => App | null> =
  createSelector(
    getAllIds,
    getAppByIdFunc,
    //
    (allIds, $getAppById) => (uid) => {
      if (uid == null) {
        return null;
      }
      // if (typeof appUid === 'undefined' || appUid === null) {
      //   throw new Error('appUid not set');
      //   // return null;
      // }
      let app;
      allIds.find((appId) => {
        const a = $getAppById(appId);
        const match = a.uid === uid;
        if (match === true) {
          app = a;
        }
        return match;
      });
      if (app == null) {
        app = getEmptyApp(-1);
      }
      return app;
    }
  );

//
//
export const getAppByUid = createSelector(
  getAppByUidFunc,
  (_: unknown, uid: string | null | undefined) => uid,
  //
  ($getAppByUid, uid) => $getAppByUid(uid)
);

//
//
export const getAppNameById = createSelector(
  getById,
  (_: unknown, id: number | null) => id,
  ($getById, id) => {
    if (id == null) {
      return 'global';
    }
    const app = $getById[id];
    return app?.name ?? '??';
  }
);
