import { createSelector } from 'reselect';

import { ROUTES } from '../../navigation/routes';
import { SITEMAP, SITEMAP_FLAT } from '../../navigation/sitemap';
import { findPath, matchRoute } from '../../navigation/utils';

import type { RouteData } from '../../navigation/routes';
import type { RootState } from '../../reducers';
import type { Selector } from 'reselect';

//
//
export const getPreviousPages = (state: RootState) => state.pageHistory.previousPages;

//
//
export const getPathname = (state: RootState) => state.pageHistory.pathname;

//
//
export const getCrumbs: Selector<RootState, Array<RouteData>> = createSelector(
  getPathname,
  //
  (pathname) => findPath(ROUTES, SITEMAP, pathname)
);

//
//
export const getParams: Selector<RootState, Record<string, string | undefined>> = createSelector(
  getPathname,
  //
  (pathname) => {
    const { matchedRoute } = matchRoute(SITEMAP_FLAT, pathname);
    const { params } = matchedRoute ?? { params: {} };
    return params;
  }
);

//
//
export const getIsSecure: Selector<RootState, boolean> = createSelector(
  getPathname,
  //
  (pathname) => {
    const { isSecure } = matchRoute(SITEMAP_FLAT, pathname);
    return isSecure;
  }
);
