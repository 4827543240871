import { Alert, message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { fetchAppPlatformList } from '../actions/appplatforms';
import { deleteAssetResource } from '../actions/assetresources';
import { getAssetDetails, updateAsset } from '../actions/assets';
import { addUploadToQueue, checkNextInQueue, removeFromQueue } from '../actions/queue';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { ContentType } from '../common/constants/content-type';
import { ResourceType } from '../common/constants/resource-type';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_ASSETS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import AssetDetail from '../pages/AssetDetail';
import { QueueItemType } from '../reducers/QueueReducer';
import { getAssetById } from '../selectors/assetSelectors';
import { getPreviousPages } from '../selectors/pageHistory';
import { getAssetId } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

//
//
const AssetDetailContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentApp } = useCurrentAppContext();
  const params = useParams();
  const assetId = getAssetId(params);

  const assetDetails = useGlobalSelector((state) => getAssetById(state, assetId));
  // console.log('assetDetails', JSON.stringify(assetDetails, null, 2));

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');

  const previousPages = useGlobalSelector(getPreviousPages);
  let linkForAfterSave = getUrlByName(ROUTE_NAME_APP_ASSETS, {
    ':appUid': currentApp.uid,
  });
  if (previousPages.length > 1) {
    const prev = previousPages[previousPages.length - 2];
    const [path] = prev.path.split('?');
    if (path === linkForAfterSave) {
      linkForAfterSave = prev.path;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      console.log('AssetDetailContainer.fetchData()');
      try {
        dispatch(counterIncrement());
        await dispatch(getAssetDetails(assetId));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };

    fetchData();
  }, [dispatch, assetId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(fetchAppPlatformList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentApp.id]);

  const handleAssetSave = async ({ name, isDraft }: { name: string; isDraft: boolean }) => {
    console.log('SAVE');
    try {
      setIsSaving(true);
      await dispatch(
        updateAsset(assetId, {
          name,
          ordinal: assetDetails.ordinal,
          isActive: assetDetails.isActive,
          isDraft: isDraft,
        })
      );

      history.push(linkForAfterSave);
      message.success('saved');
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    } finally {
      setIsSaving(false);
    }
  };

  const handleFileSelect = async (fileRef: File) => {
    console.log('FILE', fileRef);
    try {
      if (assetDetails == null) {
        throw new Error('assetDetails not set');
      }

      if (fileRef.size === 0) {
        message.error('Size of the selected file is 0KB');
        return;
      }

      dispatch(
        addUploadToQueue(
          {
            appId: currentApp.id,
            assetId: assetDetails.id,
            resourceType: ResourceType.ARCHIVE_ASSETS,
            fileRef: {
              name: fileRef.name,
              type: fileRef.type,
              size: fileRef.size,
            },
            contentType: ContentType.ASSET_CONTENT,
            // TODO: check this. it was missing before and I just added it as NULL
            appPlatformId: null,
            type: QueueItemType.ASSET,
          },
          fileRef
        )
      );
      dispatch(checkNextInQueue());
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
      throw err;
    }
  };

  const handleResourceDelete = async (aId: number, assetResourceId: number) => {
    console.log('handleResourceDelete', { aId, assetResourceId });
    try {
      dispatch(removeFromQueue('asset', aId, assetResourceId));
      await dispatch(deleteAssetResource(assetResourceId));
      dispatch(checkNextInQueue());
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <AssetDetail
      data={assetDetails}
      onSubmit={handleAssetSave}
      isSaving={isSaving}
      onFileSelected={handleFileSelect}
      onResourceDelete={handleResourceDelete}
    />
  );
};

export default AssetDetailContainer;
