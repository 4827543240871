import { combineReducers } from 'redux';

import {
  ADD_TO_QUEUE,
  QUEUE_ITEM_FOR_UPLOAD,
  UPLOAD_START,
  UPLOAD_PROGRESS,
  UPLOAD_DONE,
  UPLOAD_FAILED,
  UPLOAD_CANCELED,
} from '../constants/queue';

import type { BatchUploadItem } from './BatchUploadReducer';
import type { AllowedContentTypes } from './EpisodeContentReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

export type QueueFileInfo = {
  name: string;
  type: string;
  size: number;
  blob?: File;
};

export type QueueData = {
  appId: number;
  episodeId?: number;
  episodeLocalizedId?: number;
  assetId?: number;
  resourceType?: number;
  fileRef: QueueFileInfo;
  contentType?: AllowedContentTypes;
  appPlatformId: number | null;
  resourceId?: number;
  type: QueueItemType;
  batchContent?: BatchUploadItem;
  multipartEnabled?: boolean;
};

export enum QueueItemType {
  ASSET,
  EPISODE,
  BATCH_CONTENT,
  ICON,
}

export type QueueDataState = 'added' | 'initializing' | 'uploading' | 'done' | 'error' | 'canceled';

export type QueueItem = Merge<
  QueueData,
  {
    id: string;
    state: QueueDataState;
    percent: number;
    createdAt: Date;
    resultId: number | null;
    type: QueueItemType;
  }
>;

export type QueueByIdState = {
  [k: string]: QueueItem;
};
export type QueueAllIdsState = Array<string>;
export type QueueState = {
  byId: QueueByIdState;
  allIds: QueueAllIdsState;
};

const INITAL_BYID_STATE: QueueByIdState = {};
const INITAL_ALLIDS_STATE: QueueAllIdsState = [];

//
//
const byId = (state = INITAL_BYID_STATE, action: AnyAction): QueueByIdState => {
  switch (action.type) {
    case ADD_TO_QUEUE:
      return {
        ...state,
        [action.payload.id]: action.payload as QueueItem,
      };

    case QUEUE_ITEM_FOR_UPLOAD: {
      const id = action.payload;
      if (state[id] != null) {
        return {
          ...state,
          [id]: {
            ...state[id],
            state: 'initializing',
          },
        };
      }
      return state;
    }

    case UPLOAD_START: {
      const id = action.payload;
      const item = state[id];
      if (item != null) {
        const newItem = { ...item };
        newItem.state = 'uploading';
        newItem.resultId = action.resultId;
        return {
          ...state,
          [id]: newItem,
        };
      }
      return state;
    }

    case UPLOAD_CANCELED: {
      const id = action.payload;
      const item = state[id];
      if (item != null) {
        const newItem = { ...item };
        newItem.state = 'canceled';
        return {
          ...state,
          [id]: newItem,
        };
      }
      return state;
    }

    case UPLOAD_DONE: {
      const id = action.payload;
      const item = state[id];
      if (item != null) {
        const newItem = { ...item };
        newItem.state = 'done';
        return {
          ...state,
          [id]: newItem,
        };
      }
      return state;
    }

    case UPLOAD_FAILED: {
      const id = action.payload;
      const item = state[id];
      if (item != null) {
        const newItem = { ...item };
        newItem.state = 'error';
        return {
          ...state,
          [id]: newItem,
        };
      }
      return state;
    }

    case UPLOAD_PROGRESS: {
      const id = action.payload.id;
      const item = state[id];
      if (item != null) {
        const newItem = { ...item };
        if (newItem.percent !== action.payload.percent) {
          newItem.percent = action.payload.percent;
          return {
            ...state,
            [id]: newItem,
          };
        }
      }
      return state;
    }

    default:
      return state;
  }
};

//
//
const allIds = (state = INITAL_ALLIDS_STATE, action: AnyAction): QueueAllIdsState => {
  switch (action.type) {
    case ADD_TO_QUEUE:
      return [...state, action.payload.id];

    default:
      return state;
  }
};

const combinedReducers = combineReducers({
  byId,
  allIds,
});

export default combinedReducers;
