import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { List, Button, message, Alert } from 'antd';
import { useEffect, useState } from 'react';

import styles from './PlatformList.module.scss';
import {
  fetchPlatformList,
  updatePlatform,
  createPlatform,
  deletePlatform,
} from '../actions/platforms';
import CustomPageHeader from '../components/CustomPageHeader';
import PlatformAddModal from '../components/PlatformAddModal';
import { getAllPlatforms } from '../selectors/platformSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { Platform } from '../reducers/PlatformReducer';

//
//
const PlatformList = () => {
  const dispatch = useAppDispatch();
  const platforms = useGlobalSelector(getAllPlatforms);
  const [modalInfo, setModalInfo] = useState<{
    isOpen: boolean;
    selectedPlatform: null | Platform;
  }>({ isOpen: false, selectedPlatform: null });
  const [error, setError] = useState('');

  useEffect(() => {
    const loadData = async () => {
      try {
        await dispatch(fetchPlatformList());
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      }
    };
    loadData();
  }, [dispatch]);

  const handlePlatformAdd = () => {
    setModalInfo({ isOpen: true, selectedPlatform: null });
  };

  const handlePlatformAddCancel = () => {
    setModalInfo({ isOpen: false, selectedPlatform: null });
  };

  const handlePlatformOpenDetails = (platform: Platform) => {
    setModalInfo({ isOpen: true, selectedPlatform: platform });
  };

  const handlePlatformDelete = async (platform: Platform) => {
    try {
      await dispatch(deletePlatform(platform.id));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleOnSubmit = async (
    platformId: number | null | undefined,
    name: string,
    subcategories?: string
  ) => {
    try {
      console.log('handleOnSubmit()', { platformId, name, subcategories });
      if (platformId != null) {
        await dispatch(updatePlatform(platformId, { name, subcategories }));
      } else {
        await dispatch(createPlatform({ name, subcategories }));
      }
      handlePlatformAddCancel();
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <div>
      <CustomPageHeader
        title="Platform list"
        extra={[
          <Button
            type="primary"
            onClick={handlePlatformAdd}
            icon={<PlusCircleOutlined />}
            key="btn-add-platform"
            data-tid="btn-add-platform"
          >
            add platform
          </Button>,
        ]}
      />

      <List
        className={styles.list}
        bordered={false}
        dataSource={platforms}
        renderItem={(platform) => {
          const actions = [
            <Button
              size="small"
              icon={<EditOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                handlePlatformOpenDetails(platform);
              }}
              data-tid="btn-edit-platform"
              key="btn-edit-platform"
            >
              edit
            </Button>,
            <Button
              ghost
              size="small"
              danger
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                if (window.confirm(`Really delete platform '${platform.name}'`)) {
                  handlePlatformDelete(platform);
                }
              }}
              data-tid="btn-delete-platform"
              key="btn-delete-platform"
            >
              delete
            </Button>,
          ];

          return (
            <List.Item
              className={styles.listItem}
              onClick={() => {
                handlePlatformOpenDetails(platform);
              }}
              key={`platform-item-${platform.id}`}
              actions={actions}
            >
              <List.Item.Meta title={platform.name} description={platform.subcategories} />
            </List.Item>
          );
        }}
      />

      <PlatformAddModal
        visible={modalInfo.isOpen}
        onSubmit={handleOnSubmit}
        onClose={handlePlatformAddCancel}
        data={modalInfo.selectedPlatform}
      />
    </div>
  );
};

export default PlatformList;
