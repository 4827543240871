import { createSelector } from 'reselect';

import { getResourceByIdFunc } from './resourceSelectors';
import { getEmptyEpisodeContent } from '../reducers/EpisodeContentReducer';

import type { RootState } from '../reducers';
import type { EpisodeContent } from '../reducers/EpisodeContentReducer';

//
//
const getById = (state: RootState) => state.episodeContent.byId;

//
//
export const getEpisodeContentByIdFunc = createSelector(
  getById,
  getResourceByIdFunc,
  //
  (byId, $getResourceById) => (id: number) => {
    const episodeContent = byId[id];
    if (episodeContent == null) {
      return getEmptyEpisodeContent(id);
    }
    return {
      ...episodeContent,
      resource: $getResourceById(episodeContent.resource),
    };
  }
);

//
//
export const getEpisodeContentById = createSelector(
  getEpisodeContentByIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getEpisodeContentById, id) => $getEpisodeContentById(id)
);

//
//
export const getEpisodeContentByIdsFunc = createSelector(
  getEpisodeContentByIdFunc,
  //
  ($getEpisodeContentById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id) => $getEpisodeContentById(id)).filter((ec): ec is EpisodeContent => !!ec);
  }
);
