import { message } from 'antd';
import { forEachSeries } from 'p-iteration';
import { useState, useEffect, useCallback } from 'react';
import { useHistory, Redirect, useParams } from 'react-router-dom';

import { getCategory } from '../actions/categories';
import { getEpisodesMetadata } from '../actions/episodes';
import { getRegionList } from '../actions/regions';
import {
  deleteReleaseCategories,
  updateReleaseCategories,
  clearReleaseCategoriesForRegion,
} from '../actions/releaseCategories';
import { getReleaseList, updateRelease } from '../actions/releases';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_CATEGORYSORT } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import CategorySort from '../pages/CategorySort';
import { getCategoryById } from '../selectors/categorySelectors';
import { getAllRegionsForApp } from '../selectors/regions/advanced';
import {
  getReleasesByCategory,
  getReleasesNotInCategoryForRegion,
} from '../selectors/releaseSelectors';
import { getCategoryId, getRegionFromSearch } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { ReleaseCategory } from '../reducers/ReleaseCategoryReducer';
import type { Release } from '../reducers/ReleaseReducer';

//
//
const CategorySortContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentApp } = useCurrentAppContext();

  const params = useParams();
  const currentCategoryId = getCategoryId(params);

  const currentCategory = useGlobalSelector((state) => getCategoryById(state, currentCategoryId));
  const currentRegionId = getRegionFromSearch(history.location.search);

  const regions = useGlobalSelector((state) => getAllRegionsForApp(state, currentApp.id));
  const releasesNotInCategoryForRegion = useGlobalSelector((state) =>
    getReleasesNotInCategoryForRegion(state, currentRegionId, currentCategoryId)
  );
  const filterReleases = useGlobalSelector((state) =>
    getReleasesByCategory(state, currentCategoryId, currentRegionId)
  );
  const releaseEpisodeIds = filterReleases.map((r) => r?.episode?.id).join(',');

  const [isSaving, setIsSaving] = useState(false);

  const syncCountIncrement = useCallback(() => dispatch(counterIncrement()), [dispatch]);
  const syncCountDecrement = useCallback(() => dispatch(counterDecrement()), [dispatch]);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        syncCountIncrement();
        await dispatch(getRegionList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        syncCountDecrement();
      }
    };

    fetchRegions();
  }, [dispatch, currentApp.id, syncCountIncrement, syncCountDecrement]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        syncCountIncrement();
        await dispatch(getCategory(currentCategoryId));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        syncCountDecrement();
      }
    };

    fetchCategory();
  }, [dispatch, currentCategoryId, syncCountIncrement, syncCountDecrement]);

  useEffect(() => {
    const fetchReleases = async () => {
      if (currentRegionId === -1) {
        return;
      }
      try {
        syncCountIncrement();
        await dispatch(getReleaseList(currentApp.id, currentRegionId));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        syncCountDecrement();
      }
    };

    fetchReleases();
  }, [dispatch, currentApp.id, currentRegionId, syncCountIncrement, syncCountDecrement]);

  useEffect(() => {
    const fetchMetadata = async () => {
      if (currentApp.id === null || currentApp.id === -1 || releaseEpisodeIds === '') {
        return;
      }

      try {
        const ids = releaseEpisodeIds.split(',').map((idStr) => parseInt(idStr, 10));
        syncCountIncrement();
        if (ids.length > 0) {
          const chunks = [];
          const CHUNK_SIZE = 25;
          for (let i = 0, j = ids.length; i < j; i += CHUNK_SIZE) {
            chunks.push(ids.slice(i, i + CHUNK_SIZE));
          }
          await forEachSeries(chunks, async (chunkIds) => {
            await dispatch(getEpisodesMetadata(currentApp.id, chunkIds));
          });
        }
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        syncCountDecrement();
      }
    };

    fetchMetadata();
  }, [dispatch, currentApp.id, releaseEpisodeIds, syncCountIncrement, syncCountDecrement]);

  const getReleaseCategoryFromId = (
    releases: Array<Release>,
    releaseId: number,
    categoryId: number
  ): ReleaseCategory => {
    const release = releases.find((r: Release) => r.id === releaseId);
    if (typeof release === 'undefined') {
      throw new Error('release not found in releases');
    }
    const releaseCategory = release.categories.find(
      (rc: ReleaseCategory) => rc.category.id === categoryId
    );
    if (typeof releaseCategory === 'undefined') {
      throw new Error("releaseCategory not found in release's categories");
    }
    return releaseCategory;
  };

  const handleSwitchRegion = (regionId: number) => {
    history.push(
      `${getUrlByName(ROUTE_NAME_APP_CATEGORYSORT, {
        ':appUid': currentApp.uid,
        ':categoryId': currentCategoryId,
      })}?region=${regionId}`
    );
  };

  const handleSaveSort = async (sortOrder: Array<number>) => {
    console.log('handleSaveSort()', sortOrder);
    if (sortOrder !== null) {
      try {
        setIsSaving(() => true);
        let ordinal = 0;
        const updatedCategories = sortOrder.map((releaseId: number) => {
          const releaseCategory = getReleaseCategoryFromId(
            filterReleases,
            releaseId,
            currentCategoryId
          );
          return {
            id: releaseCategory.id,
            // eslint-disable-next-line no-plusplus
            ordinal: ordinal++,
            category: releaseCategory.category.id,
            createdAt: releaseCategory.createdAt,
          };
        });
        await dispatch(updateReleaseCategories(currentApp.id, updatedCategories));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
        throw err;
      } finally {
        setIsSaving(() => false);
      }
    }
  };

  const handleRemoveCategoryFromRelease = async (releaseId: number) => {
    console.log('handleRemoveCategoryFromRelease()', releaseId, currentCategoryId, filterReleases);

    const release = filterReleases.find((r) => r.id === releaseId);
    if (typeof release !== 'undefined' && Array.isArray(release.categories)) {
      const releaseCategory = release.categories.find((c) => {
        const categoryId = c?.category?.id ?? -1;
        if (categoryId === -1) {
          return false;
        }
        return categoryId === currentCategoryId;
      });
      if (typeof releaseCategory !== 'undefined') {
        console.log('delete releaseCategory', releaseCategory);
        try {
          await dispatch(deleteReleaseCategories(releaseCategory.id));
        } catch (err) {
          const { msg } = logUnknownError(err);
          message.error(msg);
        }
      }
    } else {
      console.error('release not found, id: ', releaseId);
    }
  };

  const handleClearCategoryForRegion = async () => {
    console.log('handleClearCategoryForRegion()', currentCategoryId, currentRegionId);
    try {
      await dispatch(clearReleaseCategoriesForRegion(currentCategoryId, currentRegionId));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleAddReleaseToCategory = async (release: Release) => {
    console.log('handleAddReleaseToCategory()', release);
    try {
      setIsSaving(() => true);
      await dispatch(
        updateRelease(
          release.id,
          release.isDemoContent,
          release.isDraft,
          release.publishDate,
          release.unpublishDate,
          [...release.categories.map((rc) => rc.category), currentCategory]
        )
      );
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    } finally {
      setIsSaving(() => false);
    }
  };

  if (currentRegionId === -1) {
    console.log('currentRegionId === -1', regions);
    if (Array.isArray(regions) && regions.length > 0) {
      return (
        <Redirect
          to={`${getUrlByName(ROUTE_NAME_APP_CATEGORYSORT, {
            ':appUid': currentApp.uid,
            ':categoryId': currentCategoryId,
          })}?region=${regions[0].id}`}
        />
      );
    }
  }

  // if (regions.length === 0 && isLoadingRegions) {
  //   console.log('spin b/c', isLoadingRegions, currentRegionId);
  //   return <Spin />;
  // }

  return (
    <CategorySort
      currentApp={currentApp}
      currentCategory={currentCategory}
      releasesNotInCategoryForRegion={releasesNotInCategoryForRegion}
      filterReleases={filterReleases}
      currentRegionId={currentRegionId}
      regions={regions}
      isSaving={isSaving}
      switchRegion={handleSwitchRegion}
      saveSortOrder={handleSaveSort}
      removeCategoryFromRelease={handleRemoveCategoryFromRelease}
      clearCategoryForRegion={handleClearCategoryForRegion}
      addReleaseToCategory={handleAddReleaseToCategory}
    />
  );
};

export default CategorySortContainer;
