import { Alert, Button } from 'antd';
import { useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { ROUTE_NAME_INTERNAL_APPDETAIL, ROUTE_NAME_PROFILETWOFASETUP } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getAllApps } from '../selectors/apps/advanced';
import { getAuth, isUserSuperadmin } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';
import { notUndefined } from '../utils/typeGuards';

//
//
const AlertSuperadmin2fa = () => {
  const history = useHistory();
  const auth = useGlobalSelector(getAuth);

  const isSuperadmin = useGlobalSelector(isUserSuperadmin);
  const allApps = useGlobalSelector(getAllApps);

  const isAppPlatformMissing = useMemo(() => {
    if (isSuperadmin === false) {
      return [];
    }
    return allApps
      .map((app) => {
        if (Array.isArray(app.platforms)) {
          if (app.platforms.length === 0) {
            return { name: app.name, id: app.id };
          }
        }
      })
      .filter(notUndefined);
  }, [allApps, isSuperadmin]);

  if (auth.redirectForceTwofa > 0) {
    return (
      <div style={{ margin: '2em', display: 'flex', justifyContent: 'center' }}>
        <Alert
          data-tid="alert-twofa-force"
          message="Security is important"
          description={
            <>
              <p>
                Superadmins have to use 2FA - set it up now. Otherwise you cannot access the rest of
                the admin.
              </p>
              <Link to={getUrlByName(ROUTE_NAME_PROFILETWOFASETUP)}>
                <Button
                  style={{
                    display:
                      history.location.pathname === getUrlByName(ROUTE_NAME_PROFILETWOFASETUP)
                        ? 'none'
                        : 'inherit',
                  }}
                  size="small"
                >
                  Setup now
                </Button>
              </Link>
            </>
          }
          type="warning"
          showIcon
          style={{ maxWidth: '640px' }}
        />
      </div>
    );
  }

  if (isAppPlatformMissing.length > 0) {
    return (
      <div style={{ margin: '2em', display: 'flex', justifyContent: 'center' }}>
        <Alert
          data-tid="alert-missing-appplatform"
          message="AppPlatform missing for these apps"
          description={
            <ul>
              {isAppPlatformMissing.map((data) => (
                <li key={`app-missing-platforms-${data.id}`}>
                  <Link
                    to={getUrlByName(ROUTE_NAME_INTERNAL_APPDETAIL, {
                      ':appId': data.id.toString(),
                    })}
                  >
                    {data.name}
                  </Link>
                </li>
              ))}
            </ul>
          }
          type="warning"
          showIcon
          style={{ maxWidth: '640px' }}
        />
      </div>
    );
  }

  return null;
};

export default AlertSuperadmin2fa;
