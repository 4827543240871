import { List, notification, Progress, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { BatchUploadComponentState } from './constants';
import Checkmark from './icons/Checkmark';
import Uploading from './icons/Uploading';
import { getCurrentlyUploadingItems } from './utils';
import { ContentType } from '../../common/constants/content-type';
import { humanFileSize } from '../../common/utils/bytes';
import { getAllQueueItems } from '../../selectors/queueSelectors';
import { useGlobalSelector } from '../../utils/hooks';
import { normalizeUnicode } from '../../utils/lang';
import { formatTimeSpan, toTimespan } from '../../utils/timespan';

import type { AllowedContentType } from '../../common/constants/content-type';
import type { BatchUploadItem } from '../../reducers/BatchUploadReducer';
import type { TimeSpan } from '../../utils/timespan';

type SizeByType = {
  type: string;
  sizeProgress: number;
  sizeTotal: number;
  progress: number;
  total: number;
};

type Props = Readonly<{
  allFiles: Array<File>;
  fileData: Array<BatchUploadItem>;
  state: BatchUploadComponentState;
}>;

const UploadProgress = ({ allFiles, fileData, state }: Props) => {
  const queuedItems = useGlobalSelector(getAllQueueItems);

  const [uploadStart, setUploadStart] = useState(0);
  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState<TimeSpan>();
  const [sizeByType, setSizeByType] = useState<Array<SizeByType>>([]);

  const icon = (progress: number, total: number) => {
    return progress === total ? <Checkmark /> : <Uploading />;
  };

  useEffect(() => {
    if (state === BatchUploadComponentState.UPLOADING_CLIENT && uploadStart === 0) {
      setUploadStart(performance.now());
    }
  }, [state]);

  useEffect(() => {
    const currentlyUploadingItems = getCurrentlyUploadingItems(queuedItems);

    const calculateSize = (type: string, contentType: AllowedContentType) => {
      const remaining = currentlyUploadingItems.filter(
        ({ batchContent }) => !!batchContent && batchContent.contentTypeId === contentType
      );
      const statistics = sizeByType.find((byType) => byType.type === type);

      if (statistics == null) {
        return [];
      }

      const { sizeTotal, total } = statistics;

      return [
        {
          type,
          sizeProgress: sizeTotal - remaining.reduce((sum, { fileRef: { size } }) => sum + size, 0),
          sizeTotal,
          progress: total - remaining.length,
          total,
        },
      ];
    };

    const videoSize = calculateSize('Videos', ContentType.EPISODE_CONTENT);
    const subtitleSize = calculateSize('Subtitles', ContentType.EPISODE_SUBTITLES);
    const thumbnailSize = calculateSize('Thumbnails', ContentType.EPISODE_THUMBNAIL);

    setSizeByType([...videoSize, ...subtitleSize, ...thumbnailSize]);

    const totalSize = Math.floor(allFiles.reduce((sum, { size }) => sum + size, 0));
    const uploadedSize = Math.floor(
      allFiles.reduce((sum, { name: fileName, size }) => {
        const notUploadedYet = currentlyUploadingItems.find(
          ({ fileRef: { name } }) => name === fileName
        );
        return notUploadedYet != null ? sum + (size * notUploadedYet.percent) / 100 : sum + size;
      }, 0)
    );
    const percentComplete = (uploadedSize / totalSize) * 100;

    if (percentComplete === 100) {
      const message = `Duration: ${(performance.now() - (uploadStart ?? 0)) / 1000}s`;

      console.log({ message });

      notification.info({ message, duration: 10 });
    }

    setPercentageCompleted(percentComplete);
  }, [queuedItems]);

  useEffect(() => {
    const timestamp = performance.now();
    const duration = timestamp - uploadStart;
    const timePerPercent = duration / percentageCompleted;
    const remainingPercentage = 100 - percentageCompleted;
    const remainingSeconds = Math.floor((timePerPercent * remainingPercentage) / 1000);

    if (percentageCompleted === 100) {
      setTimeRemaining({ hours: 0, minutes: 0 });
      return;
    }

    setTimeRemaining(toTimespan(remainingSeconds));
  }, [percentageCompleted]);

  useEffect(() => {
    if (sizeByType.length > 0 || state !== BatchUploadComponentState.UPLOADING_CLIENT) {
      return;
    }

    const initSizeByType = (type: string, collection: Array<BatchUploadItem>) => {
      if (collection.length === 0) {
        return [];
      }

      const names = collection.map(({ name }) => name);

      return [
        {
          type,
          sizeProgress: 0,
          sizeTotal: allFiles
            .filter(({ name }) => names.includes(normalizeUnicode(name)))
            .reduce((sum, { size }) => sum + size, 0),
          progress: 0,
          total: collection.length,
        },
      ];
    };

    const videos = fileData.filter(
      ({ contentTypeId }) => contentTypeId === ContentType.EPISODE_CONTENT
    );
    const subtitles = fileData.filter(
      ({ contentTypeId }) => contentTypeId === ContentType.EPISODE_SUBTITLES
    );
    const thumbnails = fileData.filter(
      ({ contentTypeId }) => contentTypeId === ContentType.EPISODE_THUMBNAIL
    );

    setSizeByType([
      ...initSizeByType('Videos', videos),
      ...initSizeByType('Subtitles', subtitles),
      ...initSizeByType('Thumbnails', thumbnails),
    ]);
  }, [fileData]);

  const formatPercentageInt = (percentage: number) => {
    const up = Math.ceil(percentage);
    return up === 100 || percentage === 100 ? Math.floor(percentage) : Math.ceil(percentage);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginTop: 30,
          marginBottom: 30,
          fontSize: '25px',
          fontWeight: 'bold',
        }}
      >
        <Progress
          strokeLinecap="butt"
          percent={percentageCompleted}
          showInfo={false}
          strokeWidth={60}
          style={{ width: '70%' }}
          strokeColor="#1890ff"
        />
        <div
          style={{
            width: '15%',
            padding: 10,
            marginLeft: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: percentageCompleted === 100 ? '#78DE86' : 'lightgrey',
            borderRadius: 25,
          }}
        >
          {percentageCompleted > 0 ? `${formatPercentageInt(percentageCompleted)} %` : <Spin />}
        </div>
        <div
          style={{
            width: '15%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            whiteSpace: 'pre',
            marginLeft: 10,
          }}
        >
          <span
            style={{
              position: 'relative',
              left: 80,
              bottom: 20,
              fontWeight: 'normal',
              fontSize: 12,
              opacity: 0.5,
            }}
          >
            remaining
          </span>
          {timeRemaining ? formatTimeSpan(timeRemaining) : '-- : --'}
          <span
            style={{
              position: 'relative',
              right: 80,
              top: 20,
              fontWeight: 'normal',
              fontSize: 12,
              opacity: 0.5,
            }}
          >
            hours
          </span>
          <span
            style={{
              position: 'relative',
              right: 70,
              top: 20,
              fontWeight: 'normal',
              fontSize: 12,
              opacity: 0.5,
            }}
          >
            minutes
          </span>
        </div>
      </div>
      <List>
        {sizeByType.map(({ type, sizeProgress, sizeTotal, progress, total }) => (
          <List.Item key={type} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontWeight: 'bold' }}>{type}</span>
            <span>
              {humanFileSize(sizeProgress)} / {humanFileSize(sizeTotal)}
            </span>
            <span
              style={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'pre',
              }}
            >
              {progress} / {total} {icon(progress, total)}
            </span>
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default UploadProgress;
