import { combineReducers } from 'redux';

import { ANALYTICSEXPORTS_GET_LIST_OK } from '../constants/analytics';

import type { AnyAction } from 'redux';
import type { JsonValue } from 'type-fest';

export type AnalyticsExport = {
  id: number;
  appId: number;
  typeId: number;
  ext: string;
  month: number;
  year: number;
  meta: null | Record<string, JsonValue>;
  createdAt: Date;
};

export type AnalyticsExportByIdState = {
  [k: string | number]: AnalyticsExport;
};
export type AnalyticsExportState = {
  byId: AnalyticsExportByIdState;
};

//
//
export const getEmptyAnalyticsExport = (id?: number): AnalyticsExport => ({
  id: id ?? -1,
  appId: -1,
  typeId: -1,
  ext: '',
  createdAt: new Date(),
  month: 1,
  year: 1999,
  meta: null,
});

const initialStateById: AnalyticsExportByIdState = {};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): AnalyticsExportByIdState => {
  switch (action.type) {
    case ANALYTICSEXPORTS_GET_LIST_OK: {
      const exports = action.payload?.entities?.analyticsExports ?? null;
      if (exports == null) {
        return state;
      }
      return {
        ...state,
        ...exports,
      };
    }

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
});

export default combined;
