import uniqBy from 'lodash.uniqby';

import type { MbxTag } from '../reducers/TagReducer';

export type TagInfo = {
  id: number | 'new';
  name: string;
};

//
//
export const generateTagInfo = (newTags: Array<string>, allTags: Array<MbxTag>) => {
  const tagsInfo = uniqBy(
    newTags.map((tagStr: string) => {
      const existingTag = allTags.find(
        (t: MbxTag) => t.name.toLowerCase() === tagStr.toLowerCase()
      );
      if (existingTag != null) {
        const info: TagInfo = { id: Number(existingTag.id), name: existingTag.name };
        return info;
      }
      const info: TagInfo = { id: 'new', name: tagStr };
      return info;
    }),
    'name'
  );
  return tagsInfo;
};
