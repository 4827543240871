import { Alert, message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getCategoryList } from '../actions/categories';
import { getEpisodeList } from '../actions/episodes';
import { getRegionList } from '../actions/regions';
import { createRelease, getReleaseList } from '../actions/releases';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { getTimezoneList } from '../actions/timezones';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_RELEASES } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import ReleaseCreate from '../pages/ReleaseCreate';
import { getAllCategoriesForApp } from '../selectors/categorySelectors';
import { getAllEpisodesForApp } from '../selectors/episodeSelectors';
import { getAllRegionsForApp } from '../selectors/regions/advanced';
import { getAllReleasedEpisodeIdsPerRegion } from '../selectors/releaseSelectors';
import { getRegionFromSearch, getEpisodeFromSearch } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { ReleaseFormSubmitValues } from '../components/ReleaseEditForm';

//
//
const ReleaseCreateContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentApp } = useCurrentAppContext();

  const currentRegionId = getRegionFromSearch(history.location.search);
  const currentEpisodeId = getEpisodeFromSearch(history.location.search);

  const appEpisodes = useGlobalSelector((state) => getAllEpisodesForApp(state, currentApp.id));
  const appRegions = useGlobalSelector((state) => getAllRegionsForApp(state, currentApp.id));
  const releasedEpisodeIdsPerRegion = useGlobalSelector(getAllReleasedEpisodeIdsPerRegion);
  const appCategories = useGlobalSelector((state) => getAllCategoriesForApp(state, currentApp.id));

  const [error, setError] = useState('');
  const [selectedRegionId, setSelectedRegionId] = useState<null | number>(null);

  // fetch regions, episodes and categories once the current appId changed
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        // TODO: do the first 3 in parallel
        // fetch regions first - it's quicker and we need that to decide if we prefill the first dropdown
        await dispatch(getRegionList(currentApp.id));
        // last step b/c it takes the longest
        await dispatch(getEpisodeList(currentApp.id));
        await dispatch(getCategoryList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentApp.id]);

  // fetch timezones onMount
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getTimezoneList());
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch]);

  const regionIds = appRegions
    .map((r) => r.id)
    .sort((a, b) => b - a)
    .join(',');

  // when the regionIds change (should only happen once after loading the regions), update the
  // selectedRegionId based on either currentRegionId or regions-array
  useEffect(() => {
    const updateSelectedRegion = async () => {
      if (regionIds !== '') {
        let id: null | number = null;
        const ids = regionIds.split(',').map((idStr) => parseInt(idStr, 10));
        if (ids.includes(currentRegionId)) {
          id = currentRegionId;
        } else if (ids.length === 1) {
          [id] = ids;
        }
        setSelectedRegionId(() => id);
      }
    };

    updateSelectedRegion();
  }, [dispatch, regionIds, currentRegionId]);

  // after selectedRegionId changes, fetch releases for this region
  useEffect(() => {
    const fetchData = async () => {
      if (selectedRegionId != null) {
        try {
          dispatch(counterIncrement());
          await dispatch(getReleaseList(currentApp.id, selectedRegionId));
        } catch (err) {
          const { msg } = logUnknownError(err);
          message.error(msg);
        } finally {
          dispatch(counterDecrement());
        }
      }
    };
    fetchData();
  }, [dispatch, currentApp.id, selectedRegionId]);

  const handleReleaseCreate = async (values: ReleaseFormSubmitValues) => {
    try {
      console.log('handleSubmit()', values);
      const releases = await dispatch(
        createRelease(
          currentApp.id,
          values.regionId,
          values.episodeId,
          values.publishDate,
          values.unpublishDate,
          values.isDraft,
          values.isDemoContent,
          values.categories
        )
      );

      const { createAnotherChecked } = values;
      if (!createAnotherChecked) {
        const regionId = releases[0]?.region.id ?? -1;
        history.push(
          `${getUrlByName(ROUTE_NAME_APP_RELEASES, {
            ':appUid': currentApp.uid,
          })}${regionId !== -1 ? `?region=${regionId}` : ''}`
        );
      }
      message.success(`Release(s) created (${releases.length})`);
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <ReleaseCreate
      releasedEpisodeIdsPerRegion={releasedEpisodeIdsPerRegion}
      appEpisodes={appEpisodes}
      appRegions={appRegions}
      selectedRegionId={selectedRegionId}
      appCategories={appCategories}
      currentRegionId={currentRegionId}
      currentEpisodeId={currentEpisodeId}
      //
      createRelease={handleReleaseCreate}
      setSelectedRegionId={setSelectedRegionId}
    />
  );
};

export default ReleaseCreateContainer;
