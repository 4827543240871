import { QuestionCircleOutlined } from '@ant-design/icons';
import { Alert, Collapse, Typography, Input, Form, Button, Select, Tooltip } from 'antd';
import { useState } from 'react';

import { ROLE_ARN_REGEX } from '../common/constants/app';

import type { AppEditFormValues } from '../components/AppEditForm';

const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;

type Props = {
  handleSubmit: (values: AppEditFormValues) => Promise<void>;
  isLoading: boolean;
};

interface AppData {
  name: string;
  uid: string;
  awsRegion: string | undefined;
  bucket: string;
  archiveBucket: string;
  arn: string;
  verification1: string;
  verification2: string;
  cfId1: string;
  cfUrl1: string;
  cfId2: string;
  cfUrl2: string;
}

type AppDataKeys = keyof AppData;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

//
//
const TerraformRun = () => (
  <>
    <p>After adding it and saving the file, run the terraform command to apply the updates:</p>

    <div>
      <p>
        <Text code>
          <b>terraform apply --var-file=production.tfvars</b>
        </Text>
      </p>
      <p>
        in the
        <Text code>Mediabox-Admin-Setup/production/10 clients</Text>
        folder
      </p>
    </div>

    <p>
      Look at the output and check what resources it wants to create - if everything looks fine,
      type &quot;yes&quot; and hit return. It&apos;ll take a while for terraform to create
      everything...
    </p>
  </>
);

//
//
const AppCreate = (props: Props) => {
  const { handleSubmit, isLoading } = props;
  const [form] = Form.useForm();

  const [data, setData] = useState<AppData>({
    name: '',
    uid: '',
    awsRegion: undefined,
    bucket: '',
    archiveBucket: '',
    arn: '',
    verification1: '',
    verification2: '',
    cfId1: '',
    cfUrl1: '',
    cfId2: '',
    cfUrl2: '',
  });

  const [currentStep, setCurrentStep] = useState(0);

  const updateValues = (attr: AppDataKeys, val: string) => {
    setData((dd) => {
      const d = { ...dd };
      d[attr] = val.trim();
      console.log(dd);
      return d;
    });
  };

  const onSubmit = (values: AppEditFormValues) => {
    console.log('Received values of form: ', values);
    handleSubmit({ ...values, setupVersion: '1.0' });
  };

  const handleNext = (newIndex: number) => {
    const fieldsToBeValidated = [];

    try {
      switch (newIndex) {
        case 1:
          fieldsToBeValidated.push('name', 'uid', 'awsRegion');
          break;
        case 2:
        case 3:
          fieldsToBeValidated.push('name', 'uid', 'awsRegion', 'contentBucket', 'archiveBucket');
          break;
        case 4:
        case 5:
          fieldsToBeValidated.push(
            'name',
            'uid',
            'awsRegion',
            'contentBucket',
            'archiveBucket',
            'roleArn'
          );
          break;
        case 6:
        case 7:
          fieldsToBeValidated.push(
            'name',
            'uid',
            'awsRegion',
            'contentBucket',
            'archiveBucket',
            'roleArn',
            'verification1',
            'verification2'
          );
          break;
        case 8:
        case 9:
          fieldsToBeValidated.push(
            'name',
            'uid',
            'awsRegion',
            'contentBucket',
            'archiveBucket',
            'roleArn',
            'verification1',
            'verification2',
            'cfIdPub',
            'cfUrlPub',
            'cfIdSec',
            'cfUrlSec'
          );
          break;
        default:
          console.error('handleNext()', newIndex);
          throw new Error('unknown index');
      }

      form
        .validateFields()
        .then((values) => {
          console.log(values, data);
          setCurrentStep(newIndex);
        })
        .catch((err) => {
          throw err;
        });
    } catch (err) {
      console.error(err);
    }
  };

  const appEnvironment = process.env.REACT_APP_ENVIRONMENT ?? '';
  const varsFile = `${data.uid}_${appEnvironment}.tfvars`;

  return (
    <div>
      <h1 style={{ marginBottom: '3em' }}>Create new app (v1.0)</h1>

      <Alert
        style={{ marginBottom: '1em' }}
        message={
          <div>
            <p>
              There is a Github repository that can help the client with setting up all resources
              needed to run their Mediabox app.
            </p>
            <p>
              <a
                href="https://github.com/WonderzGmbH/Mediabox-Admin-Client-Setup"
                target="_blank"
                rel="noopener noreferrer"
              >
                WonderzGmbH/Mediabox-Admin-Client-Setup
              </a>
            </p>
          </div>
        }
      />

      <Form form={form} {...formItemLayout} onFinish={onSubmit} name="appCreateForm">
        <Collapse defaultActiveKey={['0']} activeKey={[currentStep.toString()]}>
          {/*
           ******** STEP 0 ********
           */}
          <Panel
            header={
              <>
                <span>Give app a name and UID</span>
                {currentStep > 0 && (
                  <b style={{ marginLeft: 20 }} data-tid="panel-header-step-0">
                    <span role="img" aria-label="checkmark" style={{ marginRight: 10 }}>
                      ✅
                    </span>
                    {data.name} / {data.uid} / {data.awsRegion}
                  </b>
                )}
              </>
            }
            key="0"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input the app's name!" }]}
              initialValue={data.name}
            >
              <Input onChange={({ target: { value } }) => updateValues('name', value)} />
            </Form.Item>
            <Form.Item
              label="UID"
              name="uid"
              rules={[
                {
                  required: true,
                  message: "Please input the app's UID!",
                },
                {
                  // eslint-disable-next-line prefer-regex-literals
                  pattern: new RegExp('^[a-z0-9](?:[a-z0-9-_]{0,253}[a-z0-9])?$'),
                  message:
                    'Wrong format! Allowed characters a-z 0-9 - _. Has to start with letter or number.',
                },
              ]}
              initialValue={data.uid}
            >
              <Input onChange={({ target: { value } }) => updateValues('uid', value)} />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  AWS Region&nbsp;
                  <Tooltip title="This is where we will create the S3 buckets - CloudFront will then distribute everything globally">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="awsRegion"
              rules={[{ required: true, message: 'Please input the AWS region!' }]}
              initialValue={data.awsRegion}
            >
              <Select
                showSearch
                style={{ width: 400 }}
                placeholder="Select a region"
                optionFilterProp="children"
                onChange={(value: string) => updateValues('awsRegion', value)}
                filterOption={(input, option) =>
                  option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="us-east-2">US East (Ohio) (us-east-2)</Option>
                <Option value="us-east-1">US East (N. Virginia) (us-east-1)</Option>
                <Option value="us-west-1">US West (N. California) (us-west-1)</Option>
                <Option value="us-west-2">US West (Oregon) (us-west-2)</Option>
                <Option value="ap-south-1">Asia Pacific (Mumbai) (ap-south-1)</Option>
                <Option value="ap-northeast-3">Asia Pacific (Osaka-Local) (ap-northeast-3)</Option>
                <Option value="ap-northeast-2">Asia Pacific (Seoul) (ap-northeast-2)</Option>
                <Option value="ap-southeast-1">Asia Pacific (Singapore) (ap-southeast-1)</Option>
                <Option value="ap-southeast-2">Asia Pacific (Sydney) (ap-southeast-2)</Option>
                <Option value="ap-northeast-1">Asia Pacific (Tokyo) (ap-northeast-1)</Option>
                <Option value="ca-central-1">Canada (Central) (ca-central-1)</Option>
                <Option value="eu-central-1">Europe (Frankfurt) (eu-central-1)</Option>
                <Option value="eu-west-1">Europe (Ireland) (eu-west-1)</Option>
                <Option value="eu-west-2">Europe (London) (eu-west-2)</Option>
                <Option value="eu-west-3">Europe (Paris) (eu-west-3)</Option>
                <Option value="eu-north-1">Europe (Stockholm) (eu-north-1)</Option>
                <Option value="sa-east-1">South America (São Paulo) (sa-east-1)</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button data-tid="btn-submit-step-0" onClick={() => handleNext(1)}>
                Next
              </Button>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 1 ********
           */}
          <Panel
            header={
              <>
                <span>Create content & archive S3 buckets (user)</span>
                {currentStep > 1 && (
                  <b data-tid="panel-header-step-1" style={{ marginLeft: 20 }}>
                    <span role="img" aria-label="checkmark" style={{ marginRight: 10 }}>
                      ✅
                    </span>
                    {data.bucket} / {data.archiveBucket}
                  </b>
                )}
              </>
            }
            key="1"
            // disabled={currentStep < 1}
            collapsible={currentStep < 1 ? 'disabled' : undefined}
          >
            <ol>
              <li>Clone (or fork and clone) the aforementioned Github repository</li>
              <li>
                <p>
                  {`Create a ${varsFile} file with the following content in the root of the cloned repo`}
                </p>
                <pre>
                  {`# content of ${varsFile}

region = "${data.awsRegion ?? ''}"
environment = "${appEnvironment}"
app_uid = "${data.uid}"
              `}
                </pre>
              </li>
              <li>
                <p>
                  open the <Text code>Mediabox-Admin-Client-Setup/01 buckets</Text> folder in your
                  terminal and run:
                </p>
                <pre>{`terraform init && terraform apply --profile=<AWS_PROFILE_NAME> --var-file=../${varsFile}`}</pre>
              </li>
              <li>
                <p>Terraform should create two buckets and print out their names:</p>
                <pre>{`bucket_archive_arn = arn:aws:s3:::mediabox.${appEnvironment}.xxxxxxxxxxx.archive
bucket_archive_name = mediabox.${appEnvironment}.xxxxxxxxxxx.archive
bucket_content_arn = arn:aws:s3:::mediabox.${appEnvironment}.xxxxxxxxxxx.content
bucket_content_name = mediabox.${appEnvironment}.xxxxxxxxxxx.content`}</pre>
              </li>
            </ol>

            <Alert
              style={{ marginBottom: '1em' }}
              type="warning"
              message={`Because bucketnames are global, someone else could already use your chosen name, if
              that happens, change it until you can get to the next screen without an error saying
              "Bucket name already exists".`}
            />

            <p>Paste the new bucket names in the input fields below:</p>

            <Form.Item
              label="S3 content bucket"
              name="contentBucket"
              rules={[
                {
                  required: true,
                  message:
                    'Please input the name of the S3 bucket where the uploaded content should be stored!',
                },
                {
                  // eslint-disable-next-line prefer-regex-literals
                  pattern: new RegExp('^[a-z0-9](?:[a-z0-9-.]{0,253}[a-z0-9])?$', ''),
                  message: 'Wrong format!',
                },
              ]}
              initialValue={data.bucket}
            >
              <Input
                placeholder={`mediabox.${appEnvironment}.${data.uid}.content`}
                onChange={({ target: { value } }) => updateValues('bucket', value)}
              />
            </Form.Item>
            <Form.Item
              label="S3 archive bucket"
              name="archiveBucket"
              rules={[
                {
                  required: true,
                  message:
                    'Please input the name of the S3 bucket where the archived content should be stored!',
                },
                {
                  // eslint-disable-next-line prefer-regex-literals
                  pattern: new RegExp('^[a-z0-9](?:[a-z0-9-.]{0,253}[a-z0-9])?$', ''),
                  message: 'Wrong format!',
                },
              ]}
              initialValue={data.archiveBucket}
            >
              <Input
                placeholder={`mediabox.${appEnvironment}.${data.uid}.archive`}
                onChange={({ target: { value } }) => updateValues('archiveBucket', value)}
              />
            </Form.Item>

            <Form.Item>
              <Button data-tid="btn-submit-step-1" onClick={() => handleNext(2)}>
                Next
              </Button>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 2 ********
           */}
          <Panel
            header={
              <>
                <span>Create AWS Role + Policies (user)</span>
                {currentStep > 2 && (
                  <b data-tid="panel-header-step-2" style={{ marginLeft: 20 }}>
                    <span role="img" aria-label="checkmark" style={{ marginRight: 10 }}>
                      ✅
                    </span>
                    {data.arn}
                  </b>
                )}
              </>
            }
            key="2"
            disabled={currentStep < 2}
          >
            <p>
              To be able to access all neccessary objects in all S3 buckets, the <b>client</b> has
              to create a AWS role for us to assume.
            </p>
            <ol>
              <li>
                <p>
                  open the <Text code>Mediabox-Admin-Client-Setup/02 iam</Text> folder in your
                  terminal and run:
                </p>
                <pre>{`terraform init && terraform apply --profile=<AWS_PROFILE_NAME> --var-file=../${varsFile}`}</pre>
              </li>
              <li>
                <p>
                  Terraform should create one role, attach two inline policies and print out the ARN
                  of the role:
                </p>
                <pre>{`role_arn = arn:aws:iam::1234567890:role/MediaboxAccess_${data.uid}_${appEnvironment}`}</pre>
              </li>
            </ol>

            <p>Paste the new role&apos;s ARN into the input field below.</p>

            <Form.Item
              label="S3 role ARN"
              name="roleArn"
              rules={[
                {
                  required: true,
                  message: 'Please input the ARN of the AWS role in the clients account!',
                },
                {
                  pattern: ROLE_ARN_REGEX,
                  message:
                    'This is not a ARN for a Role. It should look something like this arn:aws:iam::1234567342:role/xxxxxx',
                },
              ]}
              initialValue={data.arn}
            >
              <Input
                onChange={({ target: { value } }) => updateValues('arn', value)}
                placeholder="arn:aws:iam::1234567890:role/MediaboxExternal"
              />
            </Form.Item>

            <Form.Item>
              <Button data-tid="btn-submit-step-2" onClick={() => handleNext(4)}>
                Next
              </Button>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 4 ********
           */}
          <Panel
            header={
              <>
                <span>Add Role to MediaboxSwitchToClientAccount Policy + bucket (Mediabox)</span>
                {currentStep > 4 && (
                  <span role="img" aria-label="checkmark" style={{ marginLeft: '1em' }}>
                    ✅
                  </span>
                )}
              </>
            }
            key="4"
            disabled={currentStep < 4}
          >
            <p>
              We also have to allow the client&apos;s Role to access Mediabox&apos;s output bucket.
            </p>
            <p>
              Add the follwing JSON object to the apps array in{' '}
              <a
                href={`https://github.com/WonderzGmbH/Mediabox-Admin-Setup/blob/master/${appEnvironment}/10%20clients/external_access.tf#L14`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terraform&apos;s external_access.tf
              </a>
              . <b>Don&apos;t forget to add the comma!</b>
            </p>

            <pre data-tid="snippet-external-access">{`{
  role_arn = "${data.arn}"
  uid = "${data.uid}"
  archive_bucket = "${data.archiveBucket}"
}`}</pre>

            <TerraformRun />

            <p>
              Afterwards we are able to assume this Role and access the client&apos;s S3 bucket and
              the Mediabox output bucket in their name.
            </p>

            <Form.Item>
              <Button data-tid="btn-submit-step-4" onClick={() => handleNext(5)}>
                Next
              </Button>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 5 ********
           */}
          <Panel
            header={
              <>
                <span>Create Certificate (user)</span>
                {currentStep > 5 && (
                  <b data-tid="panel-header-step-5" style={{ marginLeft: 20 }}>
                    <span role="img" aria-label="checkmark" style={{ marginRight: 10 }}>
                      ✅
                    </span>{' '}
                    {data.verification1} / {data.verification2}
                  </b>
                )}
              </>
            }
            key="5"
            disabled={currentStep < 5}
          >
            <p>All content should be served via https - so we need a certificate for this.</p>
            <ol>
              <li>
                <p>
                  open the <Text code>Mediabox-Admin-Client-Setup/03 certificate</Text> folder in
                  your terminal and run:
                </p>
                <pre>{`terraform init && terraform apply --profile=<AWS_PROFILE_NAME> --var-file=../${varsFile}`}</pre>
              </li>
              <li>
                <p>
                  Terraform should create a certificate and print out the validation data we need to
                  validate the certificate in the Mediabox&apos;s account.
                </p>
                <pre>{`domain_validation_options = [
  {
    "domain_name" = "*.${data.uid}.mediabox.xxxxxx"
    "resource_record_name" = "_xxxxxxxxxxxxxxxxxx.${data.uid}.mediabox.xxxxxx."
    "resource_record_type" = "CNAME"
    "resource_record_value" = "_xxxxxxxxxxxxxxxx.yyyyyyyyyy.acm-validations.aws."
  },
]`}</pre>
              </li>
            </ol>

            <p>
              Copy+paste the first part from <Text code>resource_record_name</Text> and first two
              parts from <Text code>resource_record_value</Text> into the input fields below:
            </p>

            <Form.Item
              label="first value"
              name="verification1"
              rules={[
                {
                  required: true,
                  message: 'Please input the 1st part of the verification!',
                },
              ]}
              initialValue={data.verification1}
            >
              <Input
                onChange={({ target: { value } }) => updateValues('verification1', value)}
                placeholder="_xxxxxxxxxxxxxxxxxx"
              />
            </Form.Item>

            <Form.Item
              label="second value"
              name="verification2"
              rules={[
                {
                  required: true,
                  message: 'Please input the 2nd part of the verification!',
                },
              ]}
              initialValue={data.verification2}
            >
              <Input
                onChange={({ target: { value } }) => updateValues('verification2', value)}
                placeholder="_xxxxxxxxxxxxxxxx.yyyyyyyyyy"
              />
            </Form.Item>

            <Form.Item>
              <Button data-tid="btn-submit-step-5" onClick={() => handleNext(6)}>
                Next
              </Button>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 6 ********
           */}
          <Panel
            header={
              <>
                <span>Verify Certificate (Mediabox)</span>
                {currentStep > 6 && (
                  <span role="img" aria-label="checkmark" style={{ marginLeft: '1em' }}>
                    ✅
                  </span>
                )}
              </>
            }
            key="6"
            disabled={currentStep < 6}
          >
            <p>
              To allow the client to use our domain for their certificate, we have to verify it
              using DNS
            </p>

            <p>
              Add the follwing JSON object to the apps array in{' '}
              <a
                href="https://github.com/WonderzGmbH/Mediabox-Admin-Setup/blob/master/production/10%20clients/certificates.tf#L7"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terraform&apos;s certificates.tf
              </a>
              . <b>Don&apos;t forget to add the comma!</b>
            </p>

            <pre data-tid="snippet-certificates">{`{
  name = "${data.verification1}.${data.uid}"
  value = "${data.verification2}"
}`}</pre>

            <TerraformRun />

            <Alert
              style={{ marginBottom: '1em' }}
              type="warning"
              message="You have to wait until the validation was successful before continuing with the next step!"
            />

            <Form.Item>
              <Button data-tid="btn-submit-step-6" onClick={() => handleNext(7)}>
                Next
              </Button>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 7 ********
           */}
          <Panel
            header={
              <>
                <span>Create CloudFront distributions (user)</span>
                {currentStep > 7 && (
                  <b data-tid="panel-header-step-7" style={{ marginLeft: 20 }}>
                    <span role="img" aria-label="checkmark" style={{ marginRight: 10 }}>
                      ✅
                    </span>
                    <br />
                    {data.cfId1} / {data.cfUrl1}
                    <br />
                    {data.cfId2} / {data.cfUrl2}
                  </b>
                )}
              </>
            }
            key="7"
            disabled={currentStep < 7}
          >
            <p>
              To be able to expose the content to the public, we need to create two CloudFront
              distributions pointing to separate subfolders in the content bucket.
            </p>

            <ol>
              <li>
                <p>
                  open the <Text code>Mediabox-Admin-Client-Setup/04 cloudfront</Text> folder in
                  your terminal and run:
                </p>
                <pre>{`terraform init && terraform apply --profile=<AWS_PROFILE_NAME> --var-file=../${varsFile}`}</pre>
              </li>
              <li>
                <p>
                  Terraform should create two cloudfront distributions and print out all info you
                  need for the next step:
                </p>
                <pre>{`xxxx1 = arn:aws:iam::1234567890:role/MediaboxAccess_
xxxx2 = arn:aws:iam::1234567890:role/MediaboxAccess_
xxxx3 = arn:aws:iam::1234567890:role/MediaboxAccess_
xxxx4 = arn:aws:iam::1234567890:role/MediaboxAccess_`}</pre>
              </li>
            </ol>

            <p>Copy+paste both distribution IDs and Domain Names into the fields below:</p>

            <Form.Item
              label="distribution ID (cdn)"
              name="cfIdPub"
              rules={[
                {
                  required: true,
                  message: 'Please input the ID',
                },
              ]}
              initialValue={data.cfId1}
            >
              <Input
                onChange={({ target: { value } }) => updateValues('cfId1', value)}
                placeholder="EDFDVBD6EXAMPLE"
              />
            </Form.Item>

            <Form.Item
              label="Domain Name (cdn)"
              name="cfUrlPub"
              rules={[
                {
                  required: true,
                  message: 'Please input the URL',
                },
                {
                  // eslint-disable-next-line prefer-regex-literals
                  pattern: new RegExp('^[a-z0-9]{3,20}.cloudfront.net$'),
                  message: 'Wrong format!',
                },
              ]}
              initialValue={data.cfUrl1}
            >
              <Input
                onChange={({ target: { value } }) => updateValues('cfUrl1', value)}
                placeholder="dk2s2edlofo2z.cloudfront.net"
              />
            </Form.Item>

            <Form.Item
              label="distribution ID (cdnsec)"
              name="cfIdSec"
              rules={[
                {
                  required: true, // TODO: v1.1 does not require this
                  message: 'Please input the ID',
                },
              ]}
              initialValue={data.cfId2}
            >
              <Input
                onChange={({ target: { value } }) => updateValues('cfId2', value)}
                placeholder="EDFDVBD6EXAMPLE"
              />
            </Form.Item>

            <Form.Item
              label="Domain Name (cdnsec)"
              name="cfUrlSec"
              rules={[
                {
                  required: true,
                  message: 'Please input the URL',
                },
                {
                  // eslint-disable-next-line prefer-regex-literals
                  pattern: new RegExp('^[a-z0-9]{3,20}.cloudfront.net$'),
                  message: 'Wrong format!',
                },
              ]}
              initialValue={data.cfUrl2}
            >
              <Input
                onChange={({ target: { value } }) => updateValues('cfUrl2', value)}
                placeholder="dk2s2edlofo2z.cloudfront.net"
              />
            </Form.Item>

            <Form.Item>
              <Button data-tid="btn-submit-step-7" onClick={() => handleNext(8)}>
                Next
              </Button>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 8 ********
           */}
          <Panel
            header={
              <>
                <span>Create CloudFront distributions custom DNS entries (Mediabox)</span>
                {currentStep > 8 && (
                  <span role="img" aria-label="checkmark" style={{ marginLeft: '1em' }}>
                    ✅
                  </span>
                )}
              </>
            }
            key="8"
            disabled={currentStep < 8}
          >
            <p>
              For us to be able to link to the uploaded assets using a mediabox-subdomain, we need
              to create two DNS entries - one for each CloudFront distribution.
            </p>

            <p>
              Add the follwing JSON object to the apps array in{' '}
              <a
                href={`https://github.com/WonderzGmbH/Mediabox-Admin-Setup/blob/master/${appEnvironment}/10%20clients/cloudfront.tf#L7`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terraform&apos;s <Text code>10 clients/cloudfront.tf</Text>
              </a>
              . <b>Don&apos;t forget to add the comma!</b>
            </p>

            <pre data-tid="snippet-cloudfront">{`{
  uid = "${data.uid}"
  alias_cdn = "${data.cfUrl1}"
  alias_cdnsec = "${data.cfUrl2}"
}`}</pre>

            <TerraformRun />

            <Form.Item>
              <Button data-tid="btn-submit-step-8" onClick={() => handleNext(9)}>
                Next
              </Button>
            </Form.Item>
          </Panel>

          {/*
           ******** STEP 9 ********
           */}
          <Panel header="Create app" key="9" disabled={currentStep < 9}>
            <Form.Item style={{ marginBottom: 0 }} label="App name">
              <span>{data.name}</span>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label="App UID">
              <span>{data.uid}</span>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label="S3 bucket">
              <span>{data.bucket}</span>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label="S3 archive bucket">
              <span>{data.archiveBucket}</span>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label="Role ARN">
              <span>{data.arn}</span>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label="Distribution ID (cdn)">
              <span>{data.cfId1}</span>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label="Distribution ID (cdnsec)">
              <span>{data.cfId2}</span>
            </Form.Item>

            <Form.Item>
              <Button
                data-tid="btn-submit-app-create"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Create App
              </Button>
            </Form.Item>
          </Panel>
        </Collapse>
      </Form>
    </div>
  );
};

export default AppCreate;
