import { schema, normalize } from 'normalizr';

import {
  CATEGORY_CREATE,
  CATEGORY_CREATE_OK,
  CATEGORY_CREATE_FAIL,
  CATEGORY_GET,
  CATEGORY_GET_OK,
  CATEGORY_GET_FAIL,
  CATEGORY_GET_LIST,
  CATEGORY_GET_LIST_OK,
  CATEGORY_GET_LIST_FAIL,
  CATEGORY_REMOVE,
  CATEGORY_REMOVE_OK,
  CATEGORY_REMOVE_FAIL,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_OK,
  CATEGORY_UPDATE_FAIL,
} from '../constants/categories';
import { CategorySchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const createCategory =
  (appId: number, name: string, isDraft: boolean, isLocked: boolean) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/categories`,
        method: 'POST',
        data: {
          appId,
          name,
          isDraft,
          isLocked,
        },
      },
      dispatch,
      { init: CATEGORY_CREATE, fail: CATEGORY_CREATE_FAIL },
      201
    );
    const normalizedData = normalize(response.data, CategorySchema);
    dispatch({ type: CATEGORY_CREATE_OK, appId, payload: normalizedData });
    return response.data;
  };

//
//
export const getCategory = (categoryId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/categories/${categoryId}`,
    },
    dispatch,
    { init: CATEGORY_GET, fail: CATEGORY_GET_FAIL }
  );
  const normalizedData = normalize(response.data, CategorySchema);
  dispatch({ type: CATEGORY_GET_OK, categoryId, payload: normalizedData });
  return normalizedData;
};

//
//
export const getCategoryList = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/categories`,
      params: {
        appId,
      },
    },
    dispatch,
    { init: CATEGORY_GET_LIST, fail: CATEGORY_GET_LIST_FAIL }
  );
  const normalizedData = normalize(response.data, new schema.Array(CategorySchema));
  dispatch({ type: CATEGORY_GET_LIST_OK, appId, payload: normalizedData });
  return normalizedData;
};

//
//
export const deleteCategory =
  (appId: number, categoryId: number) => async (dispatch: AppDispatch) => {
    await wrapFetch(
      {
        url: `/categories/${categoryId}`,
        method: 'DELETE',
      },
      dispatch,
      { init: CATEGORY_REMOVE, fail: CATEGORY_REMOVE_FAIL },
      204
    );
    dispatch({ type: CATEGORY_REMOVE_OK, appId, categoryId });
    return true;
  };

//
//
export const updateCategory =
  (categoryId: number, name: string, isDraft: boolean, isLocked: boolean) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/categories/${categoryId}`,
        method: 'PUT',
        data: {
          name,
          isDraft,
          isLocked,
        },
      },
      dispatch,
      { init: CATEGORY_UPDATE, fail: CATEGORY_UPDATE_FAIL }
    );
    const normalizedData = normalize(response.data, CategorySchema);
    dispatch({ type: CATEGORY_UPDATE_OK, payload: normalizedData });
    return response.data;
  };
