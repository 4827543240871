export const SOFT_HYPHEN_UNICODE = '\u00AD';

//
//
export const checkForSoftHyphens: (str: string) => {
  detected: boolean;
  parsedString: Array<string>;
} = (str) => {
  if (str.indexOf(SOFT_HYPHEN_UNICODE) > -1) {
    return { detected: true, parsedString: str.split(SOFT_HYPHEN_UNICODE) };
  }
  return { detected: false, parsedString: [] };
};
