import { FileStatus } from '../constants/file-status';

import type { Requirement } from '../types/asset';

//
//
export function getSortedResourceIds(
  resources: Array<{ id: number; requirements?: Array<Requirement>; createdAt: Date }>,
  appPlatformId: number,
  compareFunc: (a: string, b: string) => number
) {
  const sortedIds = resources.map((r) => r.id);
  sortedIds.sort((a, b) => {
    const resA = resources.find((res) => res.id === a);
    if (resA == null) {
      throw new Error(`assetResource not found in array [id:${a}]`);
    }

    const resB = resources.find((res) => res.id === b);
    if (resB == null) {
      throw new Error(`assetResource not found in array [id:${b}]`);
    }

    const reqsA = resA?.requirements ?? [];
    const reqsB = resB?.requirements ?? [];

    const reqA = reqsA.find((req) => req.appPlatform?.id === appPlatformId);
    const reqB = reqsB.find((req) => req.appPlatform?.id === appPlatformId);

    const compMin = compareFunc(
      reqB?.minVersion?.name ?? '0.0.1',
      reqA?.minVersion?.name ?? '0.0.1'
    );

    if (compMin !== 0) {
      return compMin;
    }

    const compMax = compareFunc(
      reqA?.maxVersion?.name ?? '999.0.0',
      reqB?.maxVersion?.name ?? '999.0.0'
    );

    if (compMax !== 0) {
      return compMax;
    }

    const createdA = resA.createdAt;
    const createdB = resB.createdAt;
    const diff = createdB.getTime() - createdA.getTime();
    return diff;
  });
  return sortedIds;
}

//
//
export function canBeSetActive(asset: {
  resources?: Array<{
    resource?: { file?: { versions?: Array<{ lastStatusId: number }> } | null };
  }>;
}) {
  let canBeSetAsActive = false;
  const resources = asset.resources ?? [];
  if (Array.isArray(resources) && resources.length > 0) {
    const assetResourceWithReadyContent = resources.find((ar) => {
      const versions = ar.resource?.file?.versions ?? [];
      const oneVersionNotReady = versions.find((v) => v.lastStatusId !== FileStatus.READY) != null;
      return oneVersionNotReady === false;
    });
    if (assetResourceWithReadyContent != null) {
      canBeSetAsActive = true;
    }
  }
  return canBeSetAsActive;
}
