import { createSelector } from 'reselect';

import { getAppByIdFunc } from './apps/advanced';
import { FeedType, FeedTypeNames } from '../common/constants/calendar-feed';

import type { RootState } from '../reducers';

const getByAppId = (state: RootState) => state.calendarFeed;

export const getCalendarDataByAppIdFunc = createSelector(
  getByAppId,
  (byAppId) => (appId: number) => {
    return byAppId[appId];
  }
);

export const getCalendarFeedUrls = createSelector(
  getCalendarDataByAppIdFunc,
  getAppByIdFunc,
  (_: unknown, appId: number) => appId,
  ($getCalendarDataByAppId, $getApp, appId) => {
    const result = {
      scheduled: '',
      published: '',
      incomplete: '',
    };

    const app = $getApp(appId);

    const data = $getCalendarDataByAppId(appId);

    if (data == null || app == null) {
      return result;
    }

    const scheduledData = data.find((item) => item.typeId === FeedType.SCHEDULED);
    const publishedData = data.find((item) => item.typeId === FeedType.PUBLISHED);
    const incompleteData = data.find((item) => item.typeId === FeedType.INCOMPLETE);

    if (scheduledData != null) {
      result.scheduled = `${process.env.REACT_APP_CALENDAR_ENDPOINT}/${app.uid}/${
        FeedTypeNames[scheduledData.typeId as keyof typeof FeedTypeNames]
      }/${scheduledData.dataHash}`;
    }

    if (publishedData != null) {
      result.published = `${process.env.REACT_APP_CALENDAR_ENDPOINT}/${app.uid}/${
        FeedTypeNames[publishedData.typeId as keyof typeof FeedTypeNames]
      }/${publishedData.dataHash}`;
    }

    if (incompleteData != null) {
      result.incomplete = `${process.env.REACT_APP_CALENDAR_ENDPOINT}/${app.uid}/${
        FeedTypeNames[incompleteData.typeId as keyof typeof FeedTypeNames]
      }/${incompleteData.dataHash}`;
    }

    return result;
  }
);
