import { createSelector } from 'reselect';

import { getEmptyCountry } from '../reducers/CountryReducer';
import { sortByName } from '../utils/sort';

import type { RootState } from '../reducers';

//
//
const getById = (state: RootState) => state.countries;

//
//
export const getCountryByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => {
    let country = byId[id];
    if (country == null) {
      country = getEmptyCountry(id);
    }
    return country;
  }
);

//
//
// export const getRoleById: (GlobalState, number) => Role = createSelector(
//   getRoleByIdFunc,
//   (_, id) => id,
//   //
//   (getRoleById, id) => getRoleById(id)
// );

//
//
export const getCountriesByIdsFunc = createSelector(
  getCountryByIdFunc,
  //
  (getCountryById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }

    return ids.map((id) => getCountryById(id));
  }
);

//
//
export const getAllCountries = createSelector(
  getById,
  getCountryByIdFunc,
  //
  (byId, getCountryById) => {
    const countries = Object.keys(byId).map((id: string) => getCountryById(parseInt(id, 10)));
    countries.sort(sortByName);
    return countries;
  }
);
