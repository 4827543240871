import { combineReducers } from 'redux';

import { EPISODE_GET_VIEWS_OK } from '../constants/analytics';

import type { DayViews } from '../common/types/analytics';
import type { AnyAction } from 'redux';

type AnalyticsViewsEpisodes = {
  viewTrend: number | null;
  viewsIn28Days: number | null;
  viewsInPrior28Days: number | null;
  viewDays: DayViews;
};

export type AnalyticsViewsEpisodesByIdState = {
  [k: string | number]: AnalyticsViewsEpisodes;
};

export type AnalyticsViewsEpisodesState = {
  byId: AnalyticsViewsEpisodesByIdState;
};

export const getEmptyAnalyticsViewsEpisodes = (): AnalyticsViewsEpisodes => ({
  viewTrend: null,
  viewsIn28Days: null,
  viewsInPrior28Days: null,
  viewDays: [],
});

const initialStateById: AnalyticsViewsEpisodesByIdState = {};

const byId = (state = initialStateById, action: AnyAction): AnalyticsViewsEpisodesByIdState => {
  switch (action.type) {
    case EPISODE_GET_VIEWS_OK: {
      return { ...state, ...action.payload.entities.episodes };
    }

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
});

export default combined;
