import { message, Alert } from 'antd';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import {
  getAssetList,
  getAssetMetadata,
  deleteAsset,
  updateAsset,
  bulkUpdateAssets,
} from '../actions/assets';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { useCurrentAppContext } from '../contexts';
import {
  ROUTE_NAME_APP_ASSETCREATE,
  ROUTE_NAME_APP_ASSETDETAIL,
  ROUTE_NAME_APP_ASSETS,
} from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import AssetList from '../pages/AssetList';
import { getAllAssetsForApp, getFilteredAssetsInfo } from '../selectors/assetSelectors';
import { getQFromSearch, getPageFromSearch } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { SearchItem } from '../components/ListSearchInput';
import type { Asset } from '../reducers/AssetReducer';

const DEFAULT_PER_PAGE = 25;

//
//
const AssetListContainer = () => {
  console.log('AssetListContainer()');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentApp } = useCurrentAppContext();

  const allAssets = useGlobalSelector((state) => getAllAssetsForApp(state, currentApp.id));

  const currentSearch = getQFromSearch(history.location.search);
  const currentPage = getPageFromSearch(history.location.search);

  const [filterInfo, setFilterInfo] = useState({
    appId: currentApp.id,
    page: currentPage,
    search: currentSearch,
    itemsPerPage: DEFAULT_PER_PAGE,
  });
  const [error, setError] = useState('');

  useEffect(() => {
    setFilterInfo(() => ({
      appId: currentApp.id,
      page: currentPage,
      search: currentSearch,
      itemsPerPage: DEFAULT_PER_PAGE,
    }));
  }, [currentApp.id, currentPage, currentSearch]);

  const filteredAssetsInfo = useGlobalSelector((state) => getFilteredAssetsInfo(state, filterInfo));
  const { max, assets, itemsPerPage, allActiveAssets } = filteredAssetsInfo;

  const assetIdsString = assets.map((a) => a.id).join(',');

  // componentDidMount
  useEffect(() => {
    const fetchData = async () => {
      console.log('AssetListContainer.fetchData()');
      try {
        dispatch(counterIncrement());
        await dispatch(getAssetList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentApp.id]);

  useEffect(() => {
    const fetchMetadata = async (ids: Array<number>) => {
      console.log('AssetListContainer.fetchMetadata()');
      try {
        dispatch(counterIncrement());
        await dispatch(getAssetMetadata(ids));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    if (assetIdsString.length > 0) {
      const ids = assetIdsString.split(',').map((id) => parseInt(id, 10));
      fetchMetadata(ids);
    }
  }, [dispatch, assetIdsString]);

  const handleOpenDetails = (assetId: number) => {
    if (assetId != null && !Number.isNaN(assetId)) {
      history.push(
        getUrlByName(ROUTE_NAME_APP_ASSETDETAIL, {
          ':appUid': currentApp.uid,
          ':assetId': assetId,
        })
      );
    }
  };

  const handleOpenCreate = () => {
    history.push(getUrlByName(ROUTE_NAME_APP_ASSETCREATE, { ':appUid': currentApp.uid }));
  };

  const updateSearchParams = (opts: { page?: number; q?: string }) => {
    const options: { page: number; q: string | undefined } = {
      page: currentPage,
      q: currentSearch,
      ...opts,
    };
    if (options.q === '') {
      options.q = undefined;
    }
    history.push(
      `${getUrlByName(ROUTE_NAME_APP_ASSETS, {
        ':appUid': currentApp.uid,
      })}?${queryString.stringify(options)}`
    );
  };

  const handlePaginationChange = (page: number) => {
    updateSearchParams({ page });
  };

  const maxPage = Math.ceil(max / itemsPerPage);

  if (maxPage > 0 && currentPage > maxPage) {
    return (
      <Redirect
        to={`${getUrlByName(ROUTE_NAME_APP_ASSETS, {
          ':appUid': currentApp.uid,
        })}?page=${maxPage}`}
      />
    );
  }
  if (currentPage <= 0) {
    return (
      <Redirect
        to={getUrlByName(ROUTE_NAME_APP_ASSETS, {
          ':appUid': currentApp.uid,
        })}
      />
    );
  }

  const handleSearchClose = (item: SearchItem) => {
    console.log('handleSearchClose()', item);
    if (item.type === 'search') {
      history.push(
        getUrlByName(ROUTE_NAME_APP_ASSETS, {
          ':appUid': currentApp.uid,
        })
      );
    }
  };

  const handleAssetDelete = async (assetId: number) => {
    try {
      await dispatch(deleteAsset(currentApp.id, assetId));
      message.success('Asset patch deleted');
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const assetSearchData: Array<SearchItem> = allAssets.map((asset) => ({
    key: `key-${asset.id}-iuasidhjkbkasd`,
    text: asset.name,
    value: `asset-${asset.id}-ouiasrnewh`,
    type: 'asset',
  }));

  const handleSelect = (value: string | number) => {
    console.log('handleSelect()', value);
    const [, assetId] = value.toString().split('-');
    handleOpenDetails(parseInt(assetId, 10));
  };

  const handleFilter = (q: string) => {
    console.log('handleFilter()', q);
    updateSearchParams({ q, page: 1 });
  };

  const handleToggleActive = async (asset: Asset) => {
    console.log('handleToggleActive()', asset);
    try {
      const newIsActive = !asset.isActive;
      let ordinal = 0;
      if (newIsActive === true) {
        ordinal = Math.max(-1, ...allActiveAssets.map((a) => a.ordinal)) + 1;
      }
      await dispatch(
        updateAsset(asset.id, {
          name: asset.name,
          ordinal,
          isActive: newIsActive,
          isDraft: asset.isDraft,
        })
      );
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleUpdateActiveSort = async (assets2: Array<{ id: number; ordinal: number }>) => {
    console.log('handleUpdateActiveSort()', assets2);
    try {
      await dispatch(bulkUpdateAssets(currentApp.id, assets2));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <AssetList
      data={assets}
      dataActive={allActiveAssets}
      allAssets={allAssets}
      searchData={assetSearchData}
      totalItems={max}
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      currentSearch={currentSearch}
      handlePaginationChange={handlePaginationChange}
      handleOpenDetails={handleOpenDetails}
      handleToggleActive={handleToggleActive}
      handleOpenCreate={handleOpenCreate}
      handleSearchClose={handleSearchClose}
      handleAssetDelete={handleAssetDelete}
      handleSelect={handleSelect}
      handleFilter={handleFilter}
      handleUpdateActiveSort={handleUpdateActiveSort}
    />
  );
};

AssetListContainer.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'AssetListContainer',
};

export default AssetListContainer;
