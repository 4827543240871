import { EditOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Button } from 'antd';
import { useState } from 'react';

import AppIcon from '../components/AppIcon';
import ConfigurationForm from '../components/ConfigurationForm';
import UploadIconModal from '../components/UploadIconModal';
import UserList from '../components/UserList';
import { getAppByUid } from '../selectors/apps/advanced';
import { useGlobalSelector } from '../utils/hooks';

import type { Configuration } from '../common/types/app';
import type { App } from '../reducers/AppsReducer';

type Props = Readonly<{
  details: App;
  handleSubmitConfiguration: (config: Configuration) => Promise<void>;
  handleUserRemove: (userId: number) => Promise<void>;
  handleUserOpenDetails: (userId: number) => void;
  handleRerunAllConversions?: () => Promise<void>;
}>;

//
//
const AppSettings = (props: Props) => {
  const { details, handleSubmitConfiguration, handleUserRemove, handleUserOpenDetails } = props;
  const [iconModalVisible, setIconModalVisible] = useState(false);

  const app = useGlobalSelector((state) => getAppByUid(state, details.uid));

  const UploadIconButton = () => {
    return (
      <Button
        size="small"
        data-tid="btn-upload-icon"
        key="btn-upload-icon"
        icon={<EditOutlined />}
        style={{ position: 'absolute', top: '75px', left: '5px' }}
        onClick={() => setIconModalVisible(!iconModalVisible)}
      />
    );
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <PageHeader
          title={`Settings ${details.name}`}
          style={{ width: 'calc(100% - 115px)', paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}
        />
        <div
          style={{
            display: 'flex',
            padding: '15px',
            border: '1px solid #ccc',
            height: '105px',
            width: '105px',
            marginLeft: 'auto',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {app !== null && <AppIcon app={app} size="l" showSpinnerWhenLoading={true} />}
          <UploadIconButton />
        </div>
      </div>

      <UserList
        data={details}
        handleUserRemove={handleUserRemove}
        handleUserOpenDetails={handleUserOpenDetails}
      />

      <ConfigurationForm
        title="Project specific configuration options"
        data={details.configuration}
        onUpdateConfig={handleSubmitConfiguration}
        appOnly
      />

      {app !== null && (
        <UploadIconModal
          app={app}
          visible={iconModalVisible}
          onClose={() => setIconModalVisible(false)}
        />
      )}
    </div>
  );
};

export default AppSettings;
