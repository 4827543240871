import { PlusOutlined } from '@ant-design/icons';
import { List } from 'antd';
import { useState } from 'react';

import MbxButton from '../components/base/MbxButton';
import CustomPageHeader from '../components/CustomPageHeader';
import RoleListItem from '../components/RoleListItem';
import RoleModal from '../components/RoleModal';

import type { RoleModalMode, RoleModalValues } from '../components/RoleModal';
import type { Role } from '../reducers/RoleReducer';

type Props = {
  roles: Array<Role>;
  handleRoleAdd: (values: RoleModalValues) => Promise<boolean>;
  handleRoleEdit: (roleId: number, values: RoleModalValues) => Promise<boolean>;
  handleRoleDelete: (role: Role) => Promise<boolean>;
};

//
//
const RoleList = (props: Props) => {
  const { roles, handleRoleAdd, handleRoleEdit, handleRoleDelete } = props;

  const [isModalSaving, setIsModalSaving] = useState(false);

  const [modalInfo, setModalInfo] = useState<{
    isOpen: boolean;
    mode: RoleModalMode;
    role: Role | null | undefined;
  }>({
    isOpen: false,
    mode: 'add',
    role: null,
  });

  const handleModalOpen = (mode: RoleModalMode, role: Role | null) => {
    setModalInfo({ isOpen: true, mode, role });
  };

  const handleModalClose = () => {
    setModalInfo({ ...modalInfo, isOpen: false });
  };

  const handleModalSubmit = async (
    mode: RoleModalMode,
    values: RoleModalValues,
    roleId: number | null | undefined
  ) => {
    console.log('handleModalSubmit()', mode, values, roleId);
    let success = false;
    setIsModalSaving(true);
    if (mode === 'add') {
      success = await handleRoleAdd(values);
    } else if (mode === 'edit' && roleId != null) {
      success = await handleRoleEdit(roleId, values);
    }
    setIsModalSaving(false);
    if (success) {
      handleModalClose();
    }
  };

  const handleOpenRoleDelete = (role: Role) => {
    if (window.confirm(`Really delete role '${role.name}'?`)) {
      handleRoleDelete(role);
    }
  };

  return (
    <div>
      <CustomPageHeader
        title="List of all roles"
        extra={
          <MbxButton
            data-tid="btn-add-role"
            mbxType="primary"
            onClick={() => handleModalOpen('add', null)}
            icon={<PlusOutlined />}
          >
            add role
          </MbxButton>
        }
      />

      <List
        data-tid="role-list"
        bordered={false}
        dataSource={roles}
        renderItem={(role: Role) => (
          <RoleListItem
            role={role}
            onEdit={() => handleModalOpen('edit', role)}
            onDelete={handleOpenRoleDelete}
          />
        )}
      />

      <RoleModal
        visible={modalInfo.isOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        isSaving={isModalSaving}
        mode={modalInfo.mode}
        role={modalInfo.role}
      />
    </div>
  );
};

export default RoleList;
