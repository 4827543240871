const CONTENT_VIEWS = 100;
const MONTHLY_ACTIVE_USERS = 200;

export const AnalyticsExportType = {
  CONTENT_VIEWS,
  MONTHLY_ACTIVE_USERS,
} as const;

export const VALID_ANALYTICSEXPORT_TYPES = Object.keys(AnalyticsExportType).map(
  (key) => AnalyticsExportType[key as keyof typeof AnalyticsExportType]
);

export type AllowedAnalyticsExportType = (typeof VALID_ANALYTICSEXPORT_TYPES)[number];
export type AllowedAnalyticsExportTypeKeys = `${AllowedAnalyticsExportType}`;

export const AnalyticsExportCounterName = {
  DAYS_1_N1R1: 'days1.n1r1',
  DAYS_1_N2R1: 'days1.n2r1',
  DAYS_1_N1: 'days1.n1',
  DAYS_1_N2: 'days1.n2',
  DAYS_1_N3: 'days1.n3',
  DAYS_1_N4: 'days1.n4',
  WEEKS_1_N1R1: 'weeks1.n1r1',
  WEEKS_1_N2R1: 'weeks1.n2r1',
  MONTHS_1_N1R1: 'months1.n1r1',
  MONTHS_1_N2R1: 'months1.n2r1',
  MONTHS_1_N3R1: 'months1.n3r1',
  MONTHS_3_N1R1: 'months3.n1r1',
  MONTHS_3_N2R1: 'months3.n2r1',
  YEARS_100_N1: 'years100.n1',
} as const;

export const ANALYTICS_EXPORT_COUNTERNAME_ORDER = [
  AnalyticsExportCounterName.DAYS_1_N1R1,
  AnalyticsExportCounterName.DAYS_1_N2R1,
  AnalyticsExportCounterName.DAYS_1_N1,
  AnalyticsExportCounterName.DAYS_1_N2,
  AnalyticsExportCounterName.DAYS_1_N3,
  AnalyticsExportCounterName.DAYS_1_N4,
  AnalyticsExportCounterName.WEEKS_1_N1R1,
  AnalyticsExportCounterName.WEEKS_1_N2R1,
  AnalyticsExportCounterName.MONTHS_1_N1R1,
  AnalyticsExportCounterName.MONTHS_1_N2R1,
  AnalyticsExportCounterName.MONTHS_1_N3R1,
  AnalyticsExportCounterName.MONTHS_3_N1R1,
  AnalyticsExportCounterName.MONTHS_3_N2R1,
  AnalyticsExportCounterName.YEARS_100_N1,
] as const;
