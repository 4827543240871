export const REGION_GET_LIST = 'REGION_GET_LIST';
export const REGION_GET_LIST_OK = 'REGION_GET_LIST_OK';
export const REGION_GET_LIST_FAIL = 'REGION_GET_LIST_FAIL';

export const REGION_GET_DETAILS = 'REGION_GET_DETAILS';
export const REGION_GET_DETAILS_OK = 'REGION_GET_DETAILS_OK';
export const REGION_GET_DETAILS_FAIL = 'REGION_GET_DETAILS_FAIL';

export const REGION_CREATE = 'REGION_CREATE';
export const REGION_CREATE_OK = 'REGION_CREATE_OK';
export const REGION_CREATE_FAIL = 'REGION_CREATE_FAIL';

export const REGION_UPDATE = 'REGION_UPDATE';
export const REGION_UPDATE_OK = 'REGION_UPDATE_OK';
export const REGION_UPDATE_FAIL = 'REGION_UPDATE_FAIL';

export const REGION_REMOVE = 'REGION_REMOVE';
export const REGION_REMOVE_OK = 'REGION_REMOVE_OK';
export const REGION_REMOVE_FAIL = 'REGION_REMOVE_FAIL';
