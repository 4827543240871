import { Button, Modal } from 'antd';
import QRCode from 'qrcode.react'; // eslint-disable-line import/no-named-as-default
import { useState } from 'react';

type Props = Readonly<{
  visible: boolean;
  url: string;
  onClose: (modalName: string) => void;
}>;

//
//
const QrCodeModal = (props: Props) => {
  const { visible, onClose, url } = props;

  const [showLink, setShowLink] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  const handleClose = () => {
    onClose('qr');
    setShowLink(false);
    setCopied(false);
  };

  return (
    <Modal
      title="Preview in app"
      centered
      open={visible}
      footer={null}
      onOk={handleClose}
      onCancel={handleClose}
      width={300}
    >
      <QRCode value={url} size={240} />
      <div style={{ backgroundColor: 'rgb(240, 240, 240)', padding: '8px' }}>
        <Button onClick={() => setShowLink(!showLink)} block data-tid="show-preview-link-button">
          {showLink ? 'Hide link' : 'Show link'}
        </Button>
        {showLink && (
          <div
            data-tid="preview-url-text"
            style={{ fontSize: '10px', marginTop: '5px', cursor: 'pointer' }}
            onClick={handleCopy}
          >
            {url}
            <p style={{ color: '#0388fc', margin: '10px 0px 0px 0px' }}>
              {copied ? 'Copied to clipboard!' : 'Click to copy'}
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default QrCodeModal;
