import { Layout } from 'antd';

import AppSwitcher from './AppSwitcher';
import styles from './PageHeader.module.scss';
import UserMenu from './UserMenu';
import { getAuth } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

const { Header } = Layout;

//
//
const PageHeader = () => {
  const { isLoggedIn } = useGlobalSelector(getAuth);

  return isLoggedIn === true ? (
    <Header className={styles.header}>
      <div className={styles.headerCentered}>
        <div className={styles.headerContainer}>
          <div className={styles.left}>
            <AppSwitcher />
          </div>

          <div className={styles.right}>
            <UserMenu />
            <div className={styles.logoWrapper}>
              <a href="https://www.wunderbox.tv/" target="_blank" rel="noreferrer">
                <img className={styles.logoImg} src="/wonderz-logo.png" alt="Wonderz" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Header>
  ) : (
    <Header style={{ padding: '0' }}>
      <div className={styles.headerCentered}>
        <div className={styles.logo}>
          <a href="https://www.wunderbox.tv/" target="_blank" rel="noreferrer">
            <img src="/wonderz-logo.png" alt="Wonderz" width="100%" />
          </a>
        </div>
      </div>
      <UserMenu />
    </Header>
  );
};

//
//
export default PageHeader;
