import { Alert, message } from 'antd';
import uniqBy from 'lodash.uniqby';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getEpisodeViewDetails } from '../actions/analytics';
import { fetchAppPlatformList } from '../actions/appplatforms';
import {
  getEpisodeDetails,
  updateEpisode,
  getEpisodeReleases as fetchEpisodeReleases,
  deleteEpisode,
} from '../actions/episodes';
import { getRegionList } from '../actions/regions';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { getTagList } from '../actions/tags';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_EPISODES } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import EpisodeDetail from '../pages/EpisodeDetail';
import { getEpisodeById } from '../selectors/episodeSelectors';
import { getPreviousPages } from '../selectors/pageHistory';
import { getAllTagsForApp } from '../selectors/tagSelectors';
import { getEpisodeId } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { EpisodeEditFormValues } from '../components/EpisodeEditForm';
import type { Episode } from '../reducers/EpisodeReducer';
import type { MbxTag } from '../reducers/TagReducer';

//
//
const EpisodeDetailContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { currentApp } = useCurrentAppContext();

  const params = useParams();
  const episodeId = getEpisodeId(params);

  const details = useGlobalSelector((state) => getEpisodeById(state, episodeId));
  const allTags = useGlobalSelector((state) => getAllTagsForApp(state, currentApp.id));

  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState('');

  const previousPages = useGlobalSelector(getPreviousPages);
  let linkForAfterSave = getUrlByName(ROUTE_NAME_APP_EPISODES, {
    ':appUid': currentApp.uid,
  });
  if (previousPages.length > 1) {
    const prev = previousPages[previousPages.length - 2];
    const [path] = prev.path.split('?');
    if (path === linkForAfterSave) {
      linkForAfterSave = prev.path;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getEpisodeDetails(currentApp.id, episodeId));
        await dispatch(getEpisodeViewDetails(currentApp.id, episodeId));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentApp.id, episodeId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getTagList(currentApp.id));
        await dispatch(fetchAppPlatformList(currentApp.id));
        await dispatch(getRegionList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentApp.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(fetchEpisodeReleases(episodeId));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, episodeId]);

  const handleEpisodeUpdate = async (values: EpisodeEditFormValues): Promise<boolean> => {
    try {
      const { name, playerType, resourceType, tags } = values;
      console.log('handleEpisodeUpdate()', name, playerType, resourceType, tags);
      if (details !== null) {
        setIsBusy(true);
        const thumbnailId = details?.thumbnail?.id ?? null;
        const tagsInfo: Array<{ id: number | 'new'; name: string }> = uniqBy(
          tags.map((tagStr: string) => {
            const existingTag = allTags.find((t: MbxTag) => t.name === tagStr);
            if (existingTag != null) {
              return { id: Number(existingTag.id), name: existingTag.name };
            } else {
              return { id: 'new', name: tagStr };
            }
          }),
          'name'
        );
        await dispatch(
          updateEpisode(
            details.id,
            name,
            playerType,
            resourceType,
            thumbnailId,
            tagsInfo,
            currentApp.id
          )
        );
        setIsBusy(false);
        message.success('saved');
      } else {
        throw new Error('details not set');
      }
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
      setIsBusy(false);
    }
    return false;
  };

  const handleEpisodeDelete = async (episode: Episode) => {
    try {
      await dispatch(deleteEpisode(episode));
      history.push(linkForAfterSave);
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <EpisodeDetail
      currentApp={currentApp}
      episodeDetails={details}
      handleEpisodeUpdate={handleEpisodeUpdate}
      handleEpisodeDelete={handleEpisodeDelete}
      isBusy={isBusy}
    />
  );
};

export default EpisodeDetailContainer;
