import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { askForPasswordReset } from '../actions/auth';
import PasswordForgotPage from '../pages/PasswordForgot';
import { getEmailFromSearch } from '../selectors/urlSelectors';
import { useAppDispatch } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { PasswordForgotFormValues } from '../components/PasswordForgotForm';

//
//
const PasswordForgotContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const prefilledEmail = getEmailFromSearch(history.location.search);
  const [sendData, setSendData] = useState({ isSending: false, error: '', success: false });

  const handleSubmit = async (values: PasswordForgotFormValues) => {
    try {
      const { email } = values;
      setSendData({ isSending: true, error: '', success: false });
      await dispatch(askForPasswordReset(email));
      setSendData({ isSending: false, error: '', success: true });
    } catch (err) {
      const { msg } = logUnknownError(err);
      setSendData({ isSending: false, error: msg, success: false });
    }
  };

  return (
    <PasswordForgotPage
      onSubmit={handleSubmit}
      sendData={sendData}
      prefilledEmail={prefilledEmail}
    />
  );
};

export default PasswordForgotContainer;
