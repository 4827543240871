import { ContentType } from '../constants/content-type';
import { ResourceType, SubfolderName } from '../constants/resource-type';

import type { AllowedContentType } from '../constants/content-type';

/**
 * Array of data with exceptions. Some type of files just have custom mime types
 * or don't have it at all and we still need to check it somehow.
 *
 * NOTE: We can avoid extension check, by using ['*'] as acceptedExtensions
 * NOTE: We can avoid file size check by setting maxFileSizeInKb 0 or less
 * */
export const mimeTypeExceptions = [
  {
    maxFileSizeInKb: 2000,
    contentType: ContentType.EPISODE_SUBTITLES,
    acceptedExtensions: ['dfxp', 'scc', 'srt', 'ttml', 'vtt', 'ssa', 'ass'],
  },
];

//
//
export const getAcceptedUploadTypes = (
  contentTypeId: AllowedContentType,
  resourceTypeId: ResourceType
) => {
  if (contentTypeId === ContentType.EPISODE_CONTENT) {
    switch (resourceTypeId) {
      case ResourceType.ARCHIVE_WEBPAGE:
      case ResourceType.ARCHIVE_UNITYBUNDLE:
        return ['application/zip', 'application/x-zip-compressed', 'multipart/x-zip'];
      case ResourceType.VIDEO_MEDIABOX:
        return [
          'video/mp4',
          'video/x-m4v',
          'video/H263',
          'video/H264',
          'video/H265',
          'video/ogg',
          'video/quicktime',
          'video/VP8',
        ];
      case ResourceType.AUDIO_ONLY:
        return [
          'audio/mpeg',
          'audio/mp3',
          'audio/ogg',
          'audio/wav',
          'audio/x-wav',
          'audio/vnd.wave',
        ];
      default:
        return null;
    }
  } else {
    switch (contentTypeId) {
      case ContentType.EPISODE_THUMBNAIL:
      case ContentType.EPISODE_LAUNCHIMAGE:
      case ContentType.EPISODE_ARTWORKIMAGE:
        return ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/webp'];
      default: {
        // Handle exceptions - eg. subtitles
        if (mimeTypeExceptions.find((ex) => ex.contentType === contentTypeId) != null) {
          return ['*'];
        }
        return null;
      }
    }
  }
};

//
//
export const createUploadSettings = ({
  resourceTypeId,
  contentTypeId,
}: {
  contentTypeId: AllowedContentType;
  resourceTypeId: ResourceType;
}) => {
  const settings: { isPrivate: number; destination: string | null } = {
    isPrivate: 0,
    destination: null,
  };

  if (
    contentTypeId === ContentType.EPISODE_CONTENT ||
    contentTypeId === ContentType.EPISODE_LAUNCHIMAGE ||
    contentTypeId === ContentType.EPISODE_ARTWORKIMAGE ||
    contentTypeId === ContentType.EPISODE_SUBTITLES ||
    resourceTypeId === ResourceType.APP_ICON
  ) {
    settings.isPrivate = 1;
  }

  switch (resourceTypeId) {
    case ResourceType.IMAGE_MEDIABOX:
    case ResourceType.APP_ICON:
      settings.destination = SubfolderName.IMAGES;
      break;
    case ResourceType.VIDEO_MEDIABOX:
      settings.destination = SubfolderName.VIDEOS;
      break;
    case ResourceType.ARCHIVE_WEBPAGE:
    case ResourceType.ARCHIVE_UNITYBUNDLE:
      settings.destination = SubfolderName.ARCHIVES;
      break;
    case ResourceType.ARCHIVE_ASSETS:
      settings.destination = SubfolderName.ASSETS;
      break;
    case ResourceType.AUDIO_ONLY:
      settings.destination = SubfolderName.AUDIOS;
      break;
    case ResourceType.SUBTITLES_MEDIABOX:
      settings.destination = SubfolderName.SUBTITLES;
      break;
    default:
      return null;
  }
  return settings;
};

//
//
export const getAllowedContentTypesForResourceType = (resourceType: ResourceType | -1) => {
  const contentTypes: Array<AllowedContentType> = [];
  if (resourceType === -1) {
    return contentTypes;
  }

  contentTypes.push(ContentType.EPISODE_THUMBNAIL as AllowedContentType);
  contentTypes.push(ContentType.EPISODE_CONTENT as AllowedContentType);

  switch (resourceType) {
    case ResourceType.ARCHIVE_UNITYBUNDLE:
    case ResourceType.ARCHIVE_WEBPAGE:
      contentTypes.push(ContentType.EPISODE_LAUNCHIMAGE as AllowedContentType);
      break;
    case ResourceType.AUDIO_ONLY:
      contentTypes.push(ContentType.EPISODE_ARTWORKIMAGE as AllowedContentType);
      contentTypes.push(ContentType.EPISODE_SUBTITLES as AllowedContentType);
      break;
    case ResourceType.VIDEO_MEDIABOX:
      contentTypes.push(ContentType.EPISODE_SUBTITLES as AllowedContentType);
      break;
    default:
      return [];
  }
  return contentTypes;
};
