export const JOB_GET_LIST = 'JOB_GET_LIST';
export const JOB_GET_LIST_OK = 'JOB_GET_LIST_OK';
export const JOB_GET_LIST_FAIL = 'JOB_GET_LIST_FAIL';

export const JOB_SWITCH_PAGE = 'JOB_SWITCH_PAGE';

export const JOB_GET_DETAILS = 'JOB_GET_DETAILS';
export const JOB_GET_DETAILS_OK = 'JOB_GET_DETAILS_OK';
export const JOB_GET_DETAILS_FAIL = 'JOB_GET_DETAILS_FAIL';

export const JOB_GET_METADATA = 'JOB_GET_METADATA';
export const JOB_GET_METADATA_OK = 'JOB_GET_METADATA_OK';
export const JOB_GET_METADATA_FAIL = 'JOB_GET_METADATA_FAIL';
