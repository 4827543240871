import { Alert, message } from 'antd';
import { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { createUpload, getActiveUpload, setUploadStatus } from '../actions/batchUpload';
import {
  BatchUploadItemStatus,
  BatchUploadStatus,
  Severity,
  SeverityMapping,
  ValidationError,
} from '../common/constants/batch-upload';
import { getDataFieldNames, getValueFromKey } from '../common/utils/app';
import { humanFileSize } from '../common/utils/bytes';
import Buttons from '../components/batchUpload/Buttons';
import {
  BatchUploadComponentState,
  BatchUploadModalState,
  cancelCopy,
  helpLinks,
  steps,
} from '../components/batchUpload/constants';
import BatchUploadContent from '../components/batchUpload/Content';
import Rocket from '../components/batchUpload/icons/Rocket';
import Modal from '../components/batchUpload/Modal';
import {
  parseCSV,
  parseXLSX,
  currentStep,
  returnToEpisodes,
  setupUploadQueue,
  getCurrentlyUploadingItems,
  isntMyUpload,
} from '../components/batchUpload/utils';
import HelpBox from '../components/HelpBox';
import Stepper from '../components/Stepper';
import { BATCH_UPLOAD_RESET } from '../constants/batchUpload';
import { useCurrentAppContext } from '../contexts';
import { getAuth, isUserSuperadmin } from '../selectors/authSelectors';
import { getBatchUploadByAppId } from '../selectors/batchUploadSelectors';
import { getAllQueueItems } from '../selectors/queueSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { multipartUpload } from '../utils/multipart';

import type { Issue, Metadata } from '../common/types/batch-upload';
import type { BatchUpload } from '../reducers/BatchUploadReducer';

//
//
const BatchUploadCreate = () => {
  const { currentApp } = useCurrentAppContext();

  const appConfig = currentApp.configuration ?? [];
  const displayBatchUpload = getValueFromKey<boolean>(
    appConfig,
    `adminData.displayBatchUploader`,
    false
  );
  const isSuperadmin = useGlobalSelector(isUserSuperadmin);
  const dataFieldMapping = getDataFieldNames(appConfig);

  const goBack = returnToEpisodes(currentApp.uid, useHistory());
  const dispatch = useAppDispatch();
  const { addBatchUploadToQueue, removeFromQueue: removeBatchUploadFromQueue } = setupUploadQueue(
    currentApp.id,
    dispatch
  );

  const [componentState, setComponentState] = useState(BatchUploadComponentState.LANDING_PAGE);
  const [workbook, setWorkbook] = useState<File | null>(null);
  const [validationIssues, setValidationIssues] = useState<Array<Issue>>([]);
  const [files, setFiles] = useState<Array<File>>([]);
  const [modalState, setModalState] = useState<BatchUploadModalState | null>(null);
  const [retries, setRetries] = useState(0);
  const [wasResumed, setWasResumed] = useState(false);
  // eslint-disable-next-line no-undef
  const [wakelock, setWakelock] = useState<WakeLockSentinel>();

  const MAX_RETRIES = 10;

  const { userId } = useGlobalSelector(getAuth);
  const queuedItems = useGlobalSelector(getAllQueueItems);
  const batchUpload = useGlobalSelector((appState) =>
    getBatchUploadByAppId(appState, currentApp.id)
  );

  const checkActive = async () => {
    const upload = await dispatch(getActiveUpload(currentApp.id));
    if (upload != null && (upload.creator.id === userId || isSuperadmin === true)) {
      setModalState(BatchUploadModalState.EXISTING_UPLOAD);
    }
  };

  /* fetch to make sure none is active */
  useEffect(() => {
    checkActive();
  }, [currentApp.id]);

  if (isntMyUpload(batchUpload, userId) && isSuperadmin === false) {
    message.info('Another user is currently working on an upload.');
    goBack();
    return null;
  }

  console.log({ state: BatchUploadComponentState[componentState] });

  const reset = () => {
    dispatch({ type: BATCH_UPLOAD_RESET });
    setComponentState(BatchUploadComponentState.COLLECTING_METADATA);
    setWorkbook(null);
    setFiles([]);
    setValidationIssues([]);
    setModalState(null);
    setRetries(0);
  };

  const setServerStatus = async (status: BatchUploadStatus, size?: string) => {
    if (batchUpload) {
      const response = await dispatch(setUploadStatus(batchUpload.id, status, currentApp.id, size));

      if (response === false) {
        setModalState(BatchUploadModalState.MISC_ERROR);
      }

      return response;
    }
  };

  const cancel = async (goToEpisodes = true) => {
    await setServerStatus(BatchUploadStatus.CANCELED);
    removeBatchUploadFromQueue();
    reset();

    if (goToEpisodes === true) {
      goBack();
    }
  };

  const submitMetadata = async () => {
    if (workbook !== null) {
      setComponentState(BatchUploadComponentState.VALIDATING);

      let parsed:
        | {
            issue: ValidationError;
          }
        | {
            metadata: Metadata;
          };
      try {
        parsed =
          workbook.type === 'text/csv'
            ? await parseCSV(workbook, dataFieldMapping)
            : await parseXLSX(workbook, dataFieldMapping);
      } catch (err) {
        console.log(err);
        setComponentState(BatchUploadComponentState.VALIDATION_FAILURE);
        setValidationIssues([{ key: ValidationError.PARSE_ERROR, erroredCells: [] }]);
        return;
      }

      if ('issue' in parsed) {
        setComponentState(BatchUploadComponentState.VALIDATION_FAILURE);
        setValidationIssues([{ key: parsed.issue, erroredCells: [] }]);
        return;
      }

      const { metadata } = parsed;
      const response = await dispatch(createUpload(currentApp.id, metadata));

      if (response === false) {
        setModalState(BatchUploadModalState.MISC_ERROR);
        return;
      }

      const { issues } = response;

      if (issues == null || issues.length === 0) {
        setComponentState(BatchUploadComponentState.VALIDATION_SUCCESS);
        return;
      }

      const validationResultHasOnlyWarnings = issues.every(
        ({ key }) => SeverityMapping[key] === Severity.WARNING
      );
      setValidationIssues(issues);
      setComponentState(
        validationResultHasOnlyWarnings
          ? BatchUploadComponentState.VALIDATION_WARNINGS
          : BatchUploadComponentState.VALIDATION_FAILURE
      );
    }
  };

  const requestWakeLock = async () => {
    if (!('wakeLock' in navigator)) {
      return;
    }

    try {
      const wakelockRequest = await navigator.wakeLock.request('screen');

      console.log({ wakelock: wakelockRequest });

      setWakelock(wakelockRequest);
    } catch (err) {
      console.error(err);
    }
  };

  const releaseWakeLock = async () => {
    if (!('wakeLock' in navigator) || wakelock == null) {
      return;
    }

    wakelock.release();

    console.log({ wakelock });
  };

  const submitFiles = async () => {
    setComponentState(BatchUploadComponentState.QUEUING_ITEMS);
    const totalSize = humanFileSize(files.reduce((sum, { size }) => sum + size, 0));
    const response = await setServerStatus(BatchUploadStatus.UPLOADING, totalSize);

    if (response != null && response != false) {
      const { items, issues } = response;

      if (issues != null && issues.length > 0) {
        setValidationIssues(issues);
        setModalState(BatchUploadModalState.VALIDATION_WAS_PREVIOUSLY_SUCCESSFUL);
        setComponentState(BatchUploadComponentState.VALIDATION_FAILURE);
        return;
      }

      const notUploaded = items.filter(({ status }) => status !== BatchUploadItemStatus.UPLOADED);

      console.log({ notUploaded });

      addBatchUploadToQueue(files, notUploaded);
      requestWakeLock();
      setComponentState(BatchUploadComponentState.UPLOADING_CLIENT);
    }
  };

  const handleDoneResult = async (result?: BatchUpload | false) => {
    if (!result) {
      setModalState(BatchUploadModalState.MISC_ERROR);
      return;
    }

    const { status, items, appId } = result;

    if (status === BatchUploadStatus.MISSING_MULTIPART) {
      // TODO WBP-2232 move this to queue and change status back to uploading

      const missing = items.filter(
        ({ status: itemStatus }) =>
          itemStatus === BatchUploadItemStatus.MULTIPART_START ||
          itemStatus === BatchUploadItemStatus.MULTIPART_ERROR
      );

      for (const batchContent of missing) {
        const file = files.find(({ name }) => name === batchContent.name);

        if (file == null) {
          // file was deleted, so this will end up needing to be re-uploaded
          continue;
        }

        await multipartUpload(
          {
            fileRef: {
              blob: file,
              name: file.name,
              size: file.size,
              type: file.type,
            },
            appId,
            batchContent,
          },
          dispatch,
          (arg: number) => arg
        );
      }

      setRetries((oldRetries) => oldRetries + 1);

      return;
    }

    const uploadDoneAndCreatingFiles =
      componentState === BatchUploadComponentState.UPLOAD_DONE_FROM_CLIENT &&
      status === BatchUploadStatus.CREATING_FILES;

    setRetries((oldRetries) => (uploadDoneAndCreatingFiles ? 0 : oldRetries + 1));

    if (status === BatchUploadStatus.UPLOAD_DONE) {
      setComponentState(BatchUploadComponentState.UPLOAD_SUCCESS);
    }

    if (status === BatchUploadStatus.CREATING_FILES) {
      setComponentState(BatchUploadComponentState.CREATING_FILES);
    }
  };

  const poll = (
    handleOverMaxRetries: () => void,
    handleResult: (upload?: BatchUpload | false) => Promise<void>
  ) => {
    const wait = async (ms: number) => {
      console.log({ retries });
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    return async () => {
      await wait(2000);
      return retries === MAX_RETRIES ? handleOverMaxRetries() : handleResult();
    };
  };

  const onClientUploadDone = poll(
    () => {
      setFiles([]);
      setRetries(0);
      setComponentState(BatchUploadComponentState.UPLOAD_MISSING_FILES);
    },
    async () => handleDoneResult(await setServerStatus(BatchUploadStatus.UPLOAD_DONE))
  );

  const onWaitingForFiles = poll(
    () => setModalState(BatchUploadModalState.MISC_ERROR),
    async () => handleDoneResult(await dispatch(getActiveUpload(currentApp.id, batchUpload?.id)))
  );

  const resumeProgress = () => {
    if (batchUpload === null) {
      return;
    }

    setWasResumed(true);

    if (batchUpload.status === BatchUploadStatus.CREATING_FILES) {
      setComponentState(BatchUploadComponentState.CREATING_FILES);
      setRetries(1);
      return;
    }

    const allFilesThere = batchUpload.items.every(
      ({ status }) => status === BatchUploadItemStatus.UPLOADED
    );

    if (allFilesThere) {
      setComponentState(BatchUploadComponentState.UPLOAD_DONE_FROM_CLIENT);
      setRetries(1);
      return;
    }

    const incompleteMultiparts =
      batchUpload.items.some(
        ({ status }) =>
          status === BatchUploadItemStatus.MULTIPART_ERROR ||
          status === BatchUploadItemStatus.MULTIPART_START
      ) || batchUpload.status === BatchUploadStatus.MISSING_MULTIPART;

    if (incompleteMultiparts) {
      // TODO WBP-2292 don't re-upload the whole thing
      return;
    }

    setComponentState(BatchUploadComponentState.UPLOAD_MISSING_FILES);
  };

  /* change status when queue has been emptied */
  useEffect(() => {
    if (
      componentState === BatchUploadComponentState.UPLOADING_CLIENT &&
      getCurrentlyUploadingItems(queuedItems).length === 0
    ) {
      setComponentState(BatchUploadComponentState.UPLOAD_DONE_FROM_CLIENT);
      releaseWakeLock();
      onClientUploadDone();
    }
  }, [queuedItems]);

  /* poll server for upload status once the upload is done from client side */
  useEffect(() => {
    if (componentState === BatchUploadComponentState.UPLOAD_DONE_FROM_CLIENT) {
      onClientUploadDone();
    }
    if (componentState === BatchUploadComponentState.CREATING_FILES) {
      onWaitingForFiles();
    }
  }, [retries]);

  if (isSuperadmin === false && displayBatchUpload === false) {
    return (
      <div>
        <h2 style={{ marginBottom: '1em' }}>Oooops</h2>
        <p>
          You cannot access the batch uploader for this app at the moment – if you think this is a
          mistake, please contact us.
        </p>
      </div>
    );
  }

  return (
    <div>
      {batchUpload != null && batchUpload.creator.id !== userId && isSuperadmin === true && (
        <div style={{ marginBottom: '2em' }}>
          <Alert
            message="Warning!!!"
            description="This is not your own Batchupload that you are seeing! You are a superadmin and can view other user's work."
            type="error"
            showIcon
          />
        </div>
      )}
      <div style={{ display: 'flex' }}>
        <Rocket />
        <h1 style={{ marginLeft: 10 }}>Batch Upload</h1>
      </div>
      <hr style={{ borderTop: '0.0001px solid lightgrey' }} />
      <Stepper step={currentStep(componentState)} items={steps} />
      <div style={{ display: 'flex', marginTop: 50 }}>
        <div
          style={{
            width: '80%',
            marginRight: 30,
          }}
        >
          <BatchUploadContent
            state={componentState}
            app={currentApp}
            workbook={workbook}
            files={files}
            issues={validationIssues}
            batchUpload={batchUpload}
            setState={setComponentState}
            setWorkbook={setWorkbook}
            setFiles={setFiles}
            wasResumed={wasResumed}
          />
          <Buttons
            state={componentState}
            setState={setComponentState}
            setModalState={setModalState}
            submitMetadata={submitMetadata}
            cancel={cancel}
            submitDone={goBack}
            reset={reset}
          />
        </div>
        {<HelpBox title="More Information" links={helpLinks} />}
      </div>
      <Modal
        setModalState={setModalState}
        state={modalState}
        handleCancel={cancel}
        submitFiles={submitFiles}
        resumeProgress={resumeProgress}
      />
      <Prompt
        when={
          currentStep(componentState) > 2 &&
          componentState !== BatchUploadComponentState.UPLOAD_SUCCESS &&
          modalState !== BatchUploadModalState.CONFIRM_CANCEL
        }
        message={(locationNext, historyAction) =>
          JSON.stringify({
            historyAction,
            message: cancelCopy('Ok'),
            locationNext,
          })
        }
      />
    </div>
  );
};

export default BatchUploadCreate;
