const VIDEOCLIP = 1;
const MUSIC = 2;
const HTML5 = 3;
const UNITY = 4;
const AUDIO = 5;

export const PlayerType = {
  VIDEOCLIP,
  MUSIC,
  HTML5,
  UNITY,
  AUDIO,
} as const;

export const PlayerTypeNames = {
  [PlayerType.VIDEOCLIP]: 'videoclip-player',
  [PlayerType.MUSIC]: 'music-player',
  [PlayerType.HTML5]: 'storybook-player',
  [PlayerType.UNITY]: 'game-player',
  [PlayerType.AUDIO]: 'audio-player',
} as const;

export const PlayerTypeShortNames = {
  [PlayerType.VIDEOCLIP]: 'video',
  [PlayerType.MUSIC]: 'music',
  [PlayerType.HTML5]: 'html5',
  [PlayerType.UNITY]: 'unity',
  [PlayerType.AUDIO]: 'audio',
} as const;

export const VALID_PLAYER_TYPES = Object.keys(PlayerType).map(
  (key) => PlayerType[key as keyof typeof PlayerType]
);

export type AllowedPlayerType = (typeof VALID_PLAYER_TYPES)[number];
export type AllowedPlayerTypeKey = `${AllowedPlayerType}`;

export const VALID_PLAYER_TYPE_NAMES = Object.keys(PlayerTypeNames).map(
  (key) => PlayerTypeNames[key as AllowedPlayerTypeKey]
);

export type AllowedPlayerTypeName = (typeof VALID_PLAYER_TYPE_NAMES)[number];
