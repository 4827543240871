import { Collapse, Tree } from 'antd';

import { getContentFrom } from '../utils/assetResource';

import type { AssetResource } from '../reducers/AssetResourceReducer';

const { Panel } = Collapse;
const { DirectoryTree } = Tree;

type Props = Readonly<{
  data: AssetResource;
}>;

const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 10,
  marginTop: 10,
  border: 0,
  overflow: 'hidden',
};

//
//
const AssetResourceContentTree = (props: Props) => {
  const { data } = props;
  const treeData = getContentFrom(data);

  return (
    <Collapse bordered={false}>
      <Panel key="arct" header="Content" style={customPanelStyle}>
        <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
          <DirectoryTree virtual={false} defaultExpandAll selectable={false} treeData={treeData} />
        </div>
      </Panel>
    </Collapse>
  );
};

export default AssetResourceContentTree;
