import { LoadingOutlined } from '@ant-design/icons';
import { Form, Input, Alert, Spin } from 'antd';
import { useMemo } from 'react';

import styles from './DeploymentCreate.module.scss';
import { getMetadataForDeployment } from '../common/utils/deployment';
import MbxButton from '../components/base/MbxButton';
import DeploymentDiff from '../components/DeploymentDiff';
import DeploymentRegionTable from '../components/DeploymentRegionTable';
import { useCurrentAppContext } from '../contexts';
import { getUserProfile } from '../selectors/authSelectors';
import { getDeploymentDiff } from '../selectors/deploymentSelectors';
import { getLabelFromKey } from '../utils/deployment';
import { useGlobalSelector } from '../utils/hooks';

import type { DeploymentFetchData } from '../../typings/deployments';
import type { Region } from '../reducers/RegionReducer';

type Props = {
  allRegions: Array<Region>;
  createDeploymentData: DeploymentFetchData | null;
  onCreate: (values: DeploymentCreateFormValues) => Promise<void>;
};

export type DeploymentCreateFormValues = {
  password: string;
};

//
//
const DeploymentCreate = (props: Props) => {
  const handleDeploymentCreate = (values: DeploymentCreateFormValues) => {
    const { onCreate } = props;
    onCreate(values);
  };

  const { currentApp } = useCurrentAppContext();
  const profile = useGlobalSelector(getUserProfile);
  const { allRegions, createDeploymentData } = props;

  const error = createDeploymentData?.error ?? null;
  const isSaving = createDeploymentData?.isSaving ?? false;
  const isLoading = createDeploymentData?.isLoading ?? true;
  const loadingPercent = createDeploymentData?.loadingPercent ?? 0;
  const content = createDeploymentData?.content ?? null;
  const latest = createDeploymentData?.latest ?? null;
  const contentLength = content ? Object.keys(content).length : 0;

  const isNotInitialized = (createDeploymentData?.appId ?? -1) === -1;

  const ddiff = useGlobalSelector(getDeploymentDiff);
  const { isCalculatingDiff, diff } = ddiff;

  const disableButton =
    isNotInitialized ||
    isSaving ||
    createDeploymentData == null ||
    isLoading === true ||
    contentLength === 0 ||
    !(isCalculatingDiff === false && diff != null);

  const meta = useMemo(() => getMetadataForDeployment(content), [content]);
  const latestMeta = useMemo(
    () => getMetadataForDeployment(latest !== false ? latest?.content ?? null : null),
    [latest]
  );

  return (
    <div>
      <div className={styles.header}>
        <h1>{`Create Deployment for app '${currentApp.name}'`}</h1>
        <div className={styles.headerExtras}>
          <Form
            layout="inline"
            onFinish={handleDeploymentCreate}
            className={styles.form}
            name="appEditForm"
          >
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input type="password" placeholder="Password" />
            </Form.Item>
            <Form.Item style={{ marginInlineEnd: 0 }}>
              <MbxButton mbxType="primary" size="middle" disabled={disableButton} htmlType="submit">
                create
              </MbxButton>
            </Form.Item>
          </Form>
        </div>
      </div>

      {error != null && <Alert showIcon type="error" message="Error" description={error} />}

      {isNotInitialized === true && <Spin />}

      {isNotInitialized === false &&
        content != null &&
        Object.keys(content).map((key) => {
          const errored = error === key;
          let backgroundColor = content[key] == null ? '#ffd591' : '#b7eb8f';
          if (errored) {
            backgroundColor = 'red';
          }
          return (
            <div
              key={`content-${key}`}
              style={{
                background: backgroundColor,
                marginBottom: '10px',
                marginTop: '10px',
                padding: '10px',
                borderRadius: 4,
              }}
            >
              <span style={{ marginRight: 10 }}>{getLabelFromKey(key, allRegions)}</span>
              {content[key] == null && !errored && <LoadingOutlined />}
            </div>
          );
        })}

      <DeploymentRegionTable meta={meta ?? []} oldMeta={latestMeta ?? []} allRegions={allRegions} />

      <DeploymentDiff
        allRegions={allRegions}
        isLoading={isLoading}
        loadingPercent={loadingPercent}
        last={latest}
        current={
          content === null
            ? null
            : {
                id: -1,
                appId: currentApp.id,
                content,
                createdAt: new Date(),
                updatedAt: null,
                creator: profile,
              }
        }
      />
    </div>
  );
};

export default DeploymentCreate;
