import { createSelector } from 'reselect';

import { getResourceNormalizedByIdFunc } from './resource/index';
import { getEmptyFile } from '../reducers/FileReducer';

import type { RootState } from '../reducers';
import type { File, FileNormalized, VersionFile, ParentFile } from '../reducers/FileReducer';

//
//
const getById = (state: RootState) => state.files.byId;

const isVersionFile = (file: File | FileNormalized): file is VersionFile =>
  (file as VersionFile).parentId != null;

//
//
export const getFileByIdFunc = createSelector(
  getById,
  getResourceNormalizedByIdFunc,
  // getFilesByIdsFunc, // circular dep (see: https://github.com/reduxjs/reselect/issues/169#issuecomment-675021575)
  //
  (byId, $getResourceNormalizedById) => {
    const $getFileById = (id: number) => {
      const file = byId[id];
      if (file == null) {
        return getEmptyFile(id);
      }

      if (isVersionFile(file)) {
        return file;
      }

      const versions: Array<VersionFile> = [];
      if (Array.isArray(file.versions)) {
        file.versions.forEach((vId) => {
          const vf = $getFileById(vId);
          if (isVersionFile(vf)) {
            versions.push(vf);
          }
        });
      }

      // console.log('PARENTFILE', file);
      const pf: ParentFile = {
        ...file,
        versions,
        resource: $getResourceNormalizedById(file.resource),
      };
      // console.log('PARENTFILE 2', pf);
      return pf;
    };
    return $getFileById;
  }
);

//
//
export const getFileByIdAllowNullFunc = createSelector(
  getFileByIdFunc,
  //
  ($getFileById) => (id: number | null | undefined) => {
    if (id == null) {
      return null;
    }
    return $getFileById(id);
  }
);

//
//
export const getFileById = createSelector(
  getFileByIdFunc,
  (_: RootState, id: number) => id,
  //
  ($getFileById, id) => $getFileById(id)
);

//
//
export const getFilesByIdsFunc = createSelector(
  getFileByIdFunc,
  //
  ($getFileById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }
    const files = ids.map((id) => $getFileById(id)).filter((f): f is File => !!f);
    // console.log('files =>', files);
    return files;
  }
);

//
//
export const getAllFiles = createSelector(
  getById,
  getFilesByIdsFunc,
  //
  (byId, $getFilesByIdsFunc) => {
    const ids = Object.keys(byId).map((idStr) => Number(idStr));
    return $getFilesByIdsFunc(ids);
  }
);
