import { Alert, message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  fetchDataForDeployment,
  resetDeploymentData,
  createDeployment,
  fetchLatestDeploymentForApp,
} from '../actions/deployments';
import { getRegionList } from '../actions/regions';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_DEPLOYMENTS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import DeploymentCreate from '../pages/DeploymentCreate';
import { getCreateDeploymentData } from '../selectors/deploymentSelectors';
import { getAllRegionsForApp } from '../selectors/regions/advanced';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { DeploymentCreateFormValues } from '../pages/DeploymentCreate';

//
//
const DeploymentCreateContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { currentApp } = useCurrentAppContext();

  const allRegions = useGlobalSelector((state) => getAllRegionsForApp(state, currentApp.id));
  const createDeploymentData = useGlobalSelector(getCreateDeploymentData);

  const [error, setError] = useState('');

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      console.log('DeploymentCreateContainer.fetchData()');
      try {
        dispatch(resetDeploymentData());
        const regions = await dispatch(getRegionList(currentApp.id));

        await dispatch(fetchDataForDeployment(currentApp.id, regions, abortController.signal));
        await dispatch(fetchLatestDeploymentForApp(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
        setError(() => msg);
      }
    };

    fetchData();

    return () => {
      abortController.abort();
      dispatch(resetDeploymentData());
    };
  }, [dispatch, currentApp.id]);

  // componentWillUnmount() {
  //   const { resetDeploymentData } = this.props;
  //   resetDeploymentData();
  // }

  const handleCreateDeployment = async (values: DeploymentCreateFormValues) => {
    try {
      if (createDeploymentData == null) {
        throw new Error('no deployment data found');
      }
      const contentStr = JSON.stringify(createDeploymentData.content);
      const { password } = values;
      await dispatch(createDeployment(currentApp.id, contentStr, password));
      message.success('saved');
      history.push(
        getUrlByName(ROUTE_NAME_APP_DEPLOYMENTS, {
          ':appUid': currentApp.uid,
        })
      );
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <DeploymentCreate
      allRegions={allRegions}
      createDeploymentData={createDeploymentData}
      onCreate={handleCreateDeployment}
    />
  );
};

export default DeploymentCreateContainer;
