import {
  MobileOutlined,
  LinkOutlined,
  EllipsisOutlined,
  SearchOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, List, Pagination, Avatar, Dropdown, Menu } from 'antd';
import { useState, useContext } from 'react';

import { getValueFromKey } from '../common/utils/app';
import MbxButton from '../components/base/MbxButton';
import CustomPageHeader from '../components/CustomPageHeader';
import DeploymentLinksModal from '../components/DeploymentLinksModal';
import DeploymentRegionTable from '../components/DeploymentRegionTable';
import QrCodeModal from '../components/QrCodeModal';
import { CurrentOsContext, useCurrentAppContext } from '../contexts';
import { formatDate } from '../utils/date';
import { serialize } from '../utils/url';

import type { Deployment } from '../../typings/deployments';
import type { Region } from '../reducers/RegionReducer';
import type { MenuProps } from 'antd';

type Props = {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  regions: Array<Region>;
  currentPageItems: Array<Deployment>;
  handleDeploymentCreate: () => void;
  handlePaginationChange: (page: number) => void;
  deleteDeployment: (deployment: Deployment) => Promise<void>;
  openDeploymentDetail: (deploymentId: number) => void;
};

//
//
const DeploymentList = (props: Props) => {
  const {
    regions,
    currentPageItems,
    currentPage,
    itemsPerPage,
    totalItems,
    handleDeploymentCreate,
    handlePaginationChange,
    deleteDeployment,
    openDeploymentDetail,
  } = props;

  const { currentApp } = useCurrentAppContext();
  const { os } = useContext(CurrentOsContext);
  const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState(false);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);

  const handlePreviewInApp = (url: string) => {
    if (os === 'iOS') {
      window.open(url, '_blank');
    } else {
      setIsQrCodeModalOpen(true);
    }
  };

  const handleShowLinks = () => {
    setIsLinkModalOpen(true);
  };

  const handleCloseModal = (whichModal: string) => {
    if (whichModal === 'links') {
      setIsLinkModalOpen(false);
    } else if (whichModal === 'qr') {
      setIsQrCodeModalOpen(false);
    }
  };

  const handleDeploymentDelete = async (deployment: Deployment) => {
    const dateStr = formatDate(deployment.createdAt);
    if (window.confirm(`Really delete deployment "${dateStr}"?`)) {
      await deleteDeployment(deployment);
    }
  };

  const handleDeploymentDetail = (deployment: Deployment) => {
    openDeploymentDetail(deployment.id);
  };

  const previewUrlSchema = getValueFromKey(
    currentApp.configuration,
    'adminData.previewUrlSchema',
    ''
  );

  const url = `${previewUrlSchema}://${currentApp.uid}/importBackendConfiguration?${serialize({
    appId: currentApp.uid,
    appFeedUrlTemplate: `${process.env.REACT_APP_API_ENDPOINT ?? ''}/apps/${
      currentApp.hash
    }/feeds/app.json`,
  })}`;

  const handleMenuClick: MenuProps['onClick'] = (info) => {
    if (info.key === 'preview') {
      handlePreviewInApp(url);
    } else if (info.key === 'show_links') {
      handleShowLinks();
    }
  };

  return (
    <div>
      <CustomPageHeader
        title={`Deployments ${currentApp.name} (last 10 only)`}
        extra={[
          <Dropdown
            key="btn-deployment-misc"
            overlay={
              <Menu onClick={handleMenuClick}>
                <Menu.Item key="preview">
                  <MobileOutlined /> preview
                </Menu.Item>
                <Menu.Item key="show_links">
                  <LinkOutlined /> show links
                </Menu.Item>
              </Menu>
            }
          >
            <MbxButton data-tid="detail-actions-dropdown-trigger" size="large">
              <EllipsisOutlined />
            </MbxButton>
          </Dropdown>,
          <MbxButton
            key="btn-deployment-add"
            mbxType="primary"
            onClick={handleDeploymentCreate}
            icon={<PlusOutlined />}
          >
            add deployment
          </MbxButton>,
        ]}
      />

      <DeploymentLinksModal
        currentApp={currentApp}
        allRegions={regions}
        visible={isLinkModalOpen}
        onClose={handleCloseModal}
      />
      <QrCodeModal url={url} visible={isQrCodeModalOpen} onClose={handleCloseModal} />

      <div
        style={{
          marginTop: '2em',
          marginBottom: '2em',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Pagination
          simple
          hideOnSinglePage
          current={currentPage}
          total={totalItems}
          pageSize={itemsPerPage}
          onChange={handlePaginationChange}
        />
      </div>

      <List
        itemLayout="vertical"
        bordered={false}
        dataSource={currentPageItems}
        renderItem={(deployment, index) => (
          <List.Item
            style={{
              backgroundColor: index === 0 ? 'rgba(149,232,90,0.15)' : 'inherit',
              borderRadius: 4,
              margin: '0 0 3em 0',
              padding: index === 0 ? '10px' : 0,
              borderBottom: 0,
              alignItems: 'flex-start',
            }}
            key={deployment.id}
            actions={[
              <Button
                key="btn-open-deploment"
                size="small"
                icon={<SearchOutlined />}
                onClick={() => {
                  handleDeploymentDetail(deployment);
                }}
              >
                show details
              </Button>,
              <Button
                key="btn-delete-deploment"
                ghost
                size="small"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  handleDeploymentDelete(deployment);
                }}
              >
                delete
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '0.75em',
                  }}
                >
                  <span style={{ fontSize: 20 }}>{formatDate(deployment.createdAt)} </span>
                  <div>
                    <span
                      style={{
                        marginLeft: '1em',
                        marginRight: '0.5em',
                        fontSize: 12,
                        fontWeight: 'normal',
                      }}
                    >
                      {`deployed by ${deployment.creator.firstname} ${deployment.creator.lastname}`}
                    </span>
                    <Avatar shape="square" size={24} src={deployment.creator.imageUrl} />
                  </div>
                </div>
              }
              description={
                <DeploymentRegionTable meta={deployment.meta ?? []} allRegions={regions} />
              }
            />
          </List.Item>
        )}
      />

      <div
        style={{
          marginTop: '2em',
          marginBottom: '2em',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Pagination
          simple
          hideOnSinglePage
          current={currentPage}
          total={totalItems}
          pageSize={itemsPerPage}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
};

export default DeploymentList;
