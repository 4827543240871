import { List, Button, Progress, Pagination, Skeleton } from 'antd';
import { useEffect, useState } from 'react';

import styles from './EpisodeList.module.scss';
import { JobStatus } from '../common/constants/job-status';
import { formatDate } from '../utils/date';
import { getJobTypeInfo } from '../utils/job';

import type { Job } from '../reducers/JobReducer';

type Props = Readonly<{
  jobs: Array<Job>;
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  newPageLoading: boolean;
  handleOpenDetails: (jobId: number) => void;
  handlePaginationChange: (page: number) => void;
}>;

//
//
const JobList = (props: Props) => {
  const {
    jobs,
    handleOpenDetails,
    currentPage,
    itemsPerPage,
    totalItems,
    newPageLoading,
    handlePaginationChange,
  } = props;

  const [showSkeletonLoader, setShowSkeletonLoader] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let timeout: NodeJS.Timeout;
    if (newPageLoading === true) {
      timeout = setTimeout(() => {
        setShowSkeletonLoader(newPageLoading);
      }, 100);
    } else {
      setShowSkeletonLoader(newPageLoading);
    }

    //Need to cleanup, sometimes page stucks on loader
    return () => {
      clearTimeout(timeout);
    };
  }, [newPageLoading]);

  return (
    <div>
      <div>
        <Pagination
          simple
          hideOnSinglePage
          current={currentPage}
          total={totalItems}
          pageSize={itemsPerPage}
          onChange={handlePaginationChange}
        />
      </div>

      <List
        id="mbx-job-list"
        header={<h4>All conversion jobs</h4>}
        bordered={false}
        dataSource={jobs}
        renderItem={(job) => {
          const jobInfo = getJobTypeInfo(job);
          const actions = [
            <Button
              key="btn-open-details"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenDetails(job.id);
              }}
            >
              details
            </Button>,
          ];

          const lastLog = job.logs?.[0] ?? {};

          if (lastLog.statusId === JobStatus.PROCESSING) {
            const percent = lastLog.meta?.jobProgress?.jobPercentComplete ?? 0;
            actions.unshift(<Progress type="circle" percent={percent} width={46} />);
          }

          return (
            <List.Item
              key={job.id}
              actions={actions}
              className={styles.listItem}
              onClick={() => {
                handleOpenDetails(job.id);
              }}
            >
              <Skeleton
                loading={showSkeletonLoader}
                paragraph={{ rows: 1, style: { margin: 5 } }}
                title={{ style: { margin: 5 } }}
                active={true}
                avatar
              >
                <List.Item.Meta
                  avatar={
                    <div style={{ display: 'flex', height: 50 }}>
                      <div
                        style={{
                          backgroundColor: jobInfo.color,
                          width: 12,
                          marginRight: 8,
                          position: 'relative',
                        }}
                      >
                        <p
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            fontSize: 10,
                            color: '#000',
                            margin: '2px 0 0 13px',
                            transformOrigin: '0 0',
                            transform: 'rotate(90deg)',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          &nbsp;
                        </p>
                      </div>
                      <jobInfo.icon
                        style={{
                          fontSize: '32px',
                          height: '32px',
                          alignSelf: 'center',
                          color: '#ccc',
                        }}
                      />
                    </div>
                  }
                  title={jobInfo.label}
                  description={`[${job.externalId}]  ${formatDate(job.createdAt)}`}
                />
              </Skeleton>
            </List.Item>
          );
        }}
      />

      <div style={{ marginTop: '2em', marginBottom: '2em' }}>
        <Pagination
          simple
          hideOnSinglePage
          current={currentPage}
          total={totalItems}
          pageSize={itemsPerPage}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
};

export default JobList;
