import { ContentType } from '../common/constants/content-type';
import { PlayerType, PlayerTypeShortNames } from '../common/constants/player-type';
import { ResourceType } from '../common/constants/resource-type';

import type { AllowedPlayerTypeKey } from '../common/constants/player-type';
import type { EpisodeLocalized } from '../reducers/EpisodeLocalizedReducer';
import type { Episode } from '../reducers/EpisodeReducer';

export type EpisodePlayerTypeInfo = {
  label: string;
  color: string;
};

export const PlayerTypeColors: Record<AllowedPlayerTypeKey, string> = {
  [PlayerType.VIDEOCLIP]: '#78DE86',
  [PlayerType.MUSIC]: '#3EBFFF',
  [PlayerType.HTML5]: '#FF8163',
  [PlayerType.UNITY]: '#FFC059',
  [PlayerType.AUDIO]: '#30E0EB',
};

//
//
export const getEpisodePlayerTypeInfo = (
  episode: Episode | null | undefined
): EpisodePlayerTypeInfo => {
  const playerType = episode?.playerTypeId ?? -1;
  const label = PlayerTypeShortNames[playerType as unknown as AllowedPlayerTypeKey];
  if (label == null) {
    return {
      label: '???',
      color: '#FF8FAE',
    };
  }
  return {
    label,
    color: PlayerTypeColors[playerType as unknown as AllowedPlayerTypeKey],
  };
};

//
//
export const getEpisodeContentStatus = (
  episode: Episode,
  appPlatformsCount: number
): { [k: string]: string } => {
  const codes: { [k: string]: string } = {};

  let maxNumberForAllContentElements = 2;
  if (episode.resourceTypeId === ResourceType.ARCHIVE_WEBPAGE) {
    maxNumberForAllContentElements = 3;
  } else if (episode.resourceTypeId === ResourceType.ARCHIVE_UNITYBUNDLE) {
    maxNumberForAllContentElements = 2 + appPlatformsCount;
  }

  // appLocales.forEach((loc) => (codes[loc.locale.shortcode] = null));
  episode.localizedEpisodes.forEach((le: EpisodeLocalized) => {
    const shortcode = le?.locale?.shortcode ?? null;
    if (shortcode !== null) {
      // we switch to just green/red: codes[shortcode] = 'gold';

      let howMany = 0;
      // TODO: maybe include other contenttypes at some point
      // TODO: it just checks if content is there but not what the status is
      le.content.forEach((ec) => {
        if (
          ec.contentTypeId === ContentType.EPISODE_THUMBNAIL ||
          ec.contentTypeId === ContentType.EPISODE_LAUNCHIMAGE ||
          ec.contentTypeId === ContentType.EPISODE_CONTENT
        ) {
          howMany += 1;
        }
      });

      if (howMany === maxNumberForAllContentElements) {
        codes[shortcode] = 'green';
      } else if (howMany > 0) {
        // we switch to just green/red: codes[shortcode] = 'lime';
      }
    }
  });

  return codes;
};
