import { Tag, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

import { UserRightIndexes } from '../common/constants/user-right';
import { getLabel } from '../utils/role';

import type { RightGroup, RightItem } from './RoleModal';
import type { Role } from '../reducers/RoleReducer';

const indexesAsArray = Object.keys(UserRightIndexes).map((rightName) => rightName);

type Props = {
  role: Role;
};

//
//
const RoleRightTags = (props: Props) => {
  const { role } = props;

  const [rightStatus, setRightStatus] = useState<Array<RightGroup>>([]);

  useEffect(() => {
    let index = -1;
    const indexGroups: Array<RightGroup> = [];
    indexesAsArray.forEach((name) => {
      const rightIndex = UserRightIndexes[name as keyof typeof UserRightIndexes];
      const splits = name.split('_');
      const groupIndex = Math.floor(rightIndex / 100);
      if (index === -1 || indexGroups[index].groupIndex !== groupIndex) {
        index += 1;
        indexGroups[index] = {
          groupIndex,
          name: splits[0],
          rights: [],
        };
      }
      indexGroups[index].rights.push({
        name,
        index: rightIndex,
        label: splits.slice(1).join(' '),
        isChecked: role.rights.includes(rightIndex),
      });
    });
    indexGroups.sort((a, b) => a.name.localeCompare(b.name));
    setRightStatus(() => indexGroups);
  }, [role]);

  return (
    <>
      {rightStatus.map((group) => {
        const checked: Array<RightItem> = [];
        const checkedCount = group.rights.reduce((sum, r) => {
          if (r.isChecked) {
            checked.push(r);
          }
          return r.isChecked ? sum + 1 : sum;
        }, 0);
        return (
          <Tooltip
            key={`tag-${group.name}`}
            title={checked.map((r) => getLabel(r)).join(', ')}
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            overlayStyle={{ fontSize: '11px', lineHeight: '1.1' }}
            destroyTooltipOnHide={{ keepParent: false }}
          >
            <Tag
              color={
                checkedCount === 0
                  ? undefined
                  : checkedCount === group.rights.length
                  ? 'green'
                  : 'gold'
              }
              style={{
                fontSize: 10,
                lineHeight: '14px',
                paddingRight: 4,
                paddingLeft: 4,
                opacity: checkedCount === 0 ? 0.5 : checkedCount === group.rights.length ? 1 : 0.75,
                // textDecoration: checkedCount === 0 ? 'line-through' : null
              }}
            >
              {group.name}
            </Tag>
          </Tooltip>
        );
      })}
    </>
  );
};

export default RoleRightTags;
