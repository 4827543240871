import { EditOutlined, DeleteOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons';
import { List, Tag, Dropdown, Menu, message } from 'antd';
import React, { useState } from 'react';

import MbxButton from './base/MbxButton';
import EpisodeErrorMarker from './EpisodeErrorMarker';
import EpisodeLocalizedAddModal from './EpisodeLocalizedAddModal';
import EpisodeThumbnail from './EpisodeLocalizedThumbnail';
import {
  updateEpisodeLocalized,
  createEpisodeLocalized,
  deleteEpisodeLocalized,
  removeEpisodeLocalizedContent,
} from '../actions/episodeLocalized';
import { updateEpisode } from '../actions/episodes';
import { addUploadToQueue, checkNextInQueue, removeFromQueue } from '../actions/queue';
import { rerunConversionOnResource } from '../actions/resources';
import { ContentType } from '../common/constants/content-type';
import { UserRight } from '../common/constants/user-right';
import { hasUserSufficientRights } from '../common/utils/user-right';
import { trendColors } from '../constants/analytics';
import styles from '../pages/EpisodeDetail.module.scss';
import { QueueItemType } from '../reducers/QueueReducer';
import { getAppPlatformsForApp } from '../selectors/appPlatformSelectors';
import { getUserProfile } from '../selectors/authSelectors';
import {
  getUniqueLocalesForApp,
  getRegionDefaultLocaleIdsForApp,
} from '../selectors/regionLocales/advanced';
import { getTrendString, getTrendType } from '../utils/analytics';
import { getEpisodeContentStatus } from '../utils/episode';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';
import { checkForSoftHyphens } from '../utils/text';

import type { EpisodeLocalizedFormValues } from './EpisodeLocalizedForm';
import type { TrendColorsType } from '../constants/analytics';
import type { App } from '../reducers/AppsReducer';
import type { AllowedContentTypes } from '../reducers/EpisodeContentReducer';
import type { EpisodeLocalized } from '../reducers/EpisodeLocalizedReducer';
import type { Episode } from '../reducers/EpisodeReducer';
import type { RegionLocale } from '../reducers/RegionLocaleReducer';
import type { MenuProps } from 'antd';

type Props = Readonly<{
  app: App;
  episode: Episode;
}>;

//
//
const EpisodeLocalizationList = (props: Props) => {
  const { app, episode } = props;
  const dispatch = useAppDispatch();

  const profile = useGlobalSelector(getUserProfile);
  const canUserRerunConversion = hasUserSufficientRights(
    profile,
    app.id,
    UserRight.RESOURCES_RERUN_CONVERSION
  );

  const appLocales = useGlobalSelector((state) => getUniqueLocalesForApp(state, app.id));
  const defaultAppLocales = useGlobalSelector((state) =>
    getRegionDefaultLocaleIdsForApp(state, app.id)
  );
  const appPlatforms = useGlobalSelector((state) => getAppPlatformsForApp(state, app.id));

  const [modalInfo, setModalInfo] = useState<{
    isOpen: boolean;
    selectedLocalization: null | RegionLocale;
    selectedLocalizedEpisodeId: null | number;
  }>({
    isOpen: false,
    selectedLocalization: null,
    selectedLocalizedEpisodeId: null,
  });

  const handleSetDefaultThumbnail = async (thumbnailId: number) => {
    try {
      console.log('handleSetDefaultThumbnail()', thumbnailId);
      if (episode !== null) {
        await dispatch(
          updateEpisode(
            episode.id,
            episode.name,
            episode.playerTypeId,
            episode.resourceTypeId,
            thumbnailId,
            episode.tags.map((et) => ({ id: et.tag.id, name: et.tag.name })),
            app.id
          )
        );
        message.success('updated');
      } else {
        throw new Error('episode not set');
      }
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleAddLocalization = (rl: RegionLocale, le?: EpisodeLocalized) => {
    console.log('handleAddLocalization()', rl, le);
    const changes = {
      isOpen: true,
      selectedLocalization: rl,
      selectedLocalizedEpisodeId: typeof le !== 'undefined' ? le.id : null,
    };
    setModalInfo({ ...modalInfo, ...changes });
  };

  const handleDeleteLocale = async (le: EpisodeLocalized) => {
    if (window.confirm(`Really delete ${le.name}?`)) {
      try {
        if (episode === null) {
          throw new Error('episode not set');
        }
        await dispatch(deleteEpisodeLocalized(episode.id, le.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      }
    }
  };

  const handleCloseModal = () => {
    setModalInfo({ ...modalInfo, isOpen: false });
  };

  const handleCloseModalDone = () => {
    setModalInfo({ ...modalInfo, selectedLocalization: null, selectedLocalizedEpisodeId: null });
  };

  const handleModalSubmit = async (
    data: EpisodeLocalized | null | undefined,
    values: EpisodeLocalizedFormValues
  ) => {
    try {
      const { name, data1, data2, data3, data4, data5 } = values;
      if (data == null) {
        const { selectedLocalization } = modalInfo;
        if (selectedLocalization === null) {
          throw new Error('RegionLocale not set');
        }
        const le = await dispatch(
          createEpisodeLocalized(
            episode.id,
            selectedLocalization.locale.id,
            name,
            data1,
            data2,
            data3,
            data4,
            data5,
            app.id
          )
        );
        message.success('created');
        setModalInfo({ ...modalInfo, selectedLocalizedEpisodeId: le.id });
      } else {
        await dispatch(
          updateEpisodeLocalized(data.id, name, data1, data2, data3, data4, data5, app.id)
        );
        message.success('saved');
      }
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleHyphens = (str: string) => {
    const { detected, parsedString } = checkForSoftHyphens(str);
    if (detected === true && parsedString.length > 0) {
      return (
        <span data-tid="hyphens-title-label" style={{ color: '#999', display: 'block' }}>
          {parsedString.map((h, index) => (
            <React.Fragment key={`x-${index}`}>
              {h}
              {index !== parsedString.length - 1 && <span style={{ color: 'red' }}>-</span>}
            </React.Fragment>
          ))}
        </span>
      );
    }
    return str;
  };

  const handleFileSelect = async (
    file: File,
    resourceType: number,
    contentType: AllowedContentTypes,
    episodeLocalizedId: number,
    appPlatformId: number | null
  ) => {
    try {
      if (episode === null) {
        throw new Error('episode not set');
      }
      dispatch(
        addUploadToQueue(
          {
            appId: app.id,
            episodeId: episode.id,
            episodeLocalizedId,
            resourceType,
            fileRef: {
              name: file.name,
              type: file.type,
              size: file.size,
            },
            contentType,
            appPlatformId,
            type: QueueItemType.EPISODE,
          },
          file
        )
      );
      await dispatch(checkNextInQueue());
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleContentRemove = async (episodelocalizedId: number, contentId: number) => {
    try {
      dispatch(removeFromQueue('episode', episodelocalizedId, contentId));
      await dispatch(removeEpisodeLocalizedContent(episodelocalizedId, contentId));
      dispatch(checkNextInQueue());
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
    return false;
  };

  const handleRerunConversion = async (resourceId: number) => {
    try {
      await dispatch(rerunConversionOnResource(resourceId, app.id));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleLocaleMenuClick: MenuProps['onClick'] = (info) => {
    const localeId = Number(info.key.split('-').pop());
    console.log('handleLocaleMenuClick()', localeId);
    if (!Number.isNaN(localeId)) {
      const rl = appLocales.find((al) => al.id === localeId);
      if (rl != null) {
        handleAddLocalization(rl);
      }
    }
  };

  const selectedLocalizedEpisode = episode.localizedEpisodes.find(
    (le) => le.id === modalInfo.selectedLocalizedEpisodeId
  );

  const listData: Array<{
    rl: RegionLocale;
    le: EpisodeLocalized | undefined | null;
    thumb: JSX.Element;
  }> = [];
  const addData: Array<RegionLocale> = [];
  const defaultThumbnailId = episode?.thumbnail?.id ?? null;

  const codes = getEpisodeContentStatus(episode, appPlatforms.length);

  appLocales.forEach((rl) => {
    const le = episode.localizedEpisodes.find(
      (le1) => le1.locale !== null && le1.locale.id === rl.locale.id
    );

    if (defaultAppLocales.includes(rl.id) || le != null) {
      let isDefaultThumbnail = false;

      if (defaultThumbnailId !== null) {
        const content = le?.content ?? [];
        const thumbnailContent = content.find(
          (ec) => ec.contentTypeId === ContentType.EPISODE_THUMBNAIL
        );
        const leThumbnailId = thumbnailContent?.resource?.id ?? null;
        if (defaultThumbnailId === leThumbnailId) {
          isDefaultThumbnail = true;
        }
      }

      const thumb = (
        <>
          <EpisodeThumbnail
            le={le}
            isDefault={isDefaultThumbnail}
            onSetDefaultThumbnail={handleSetDefaultThumbnail}
            disableSetThumbnail={false}
            displayTimeOverlay
          />
          <EpisodeErrorMarker localizedEpisode={le} />
        </>
      );

      listData.push({
        rl,
        le,
        thumb,
      });
    } else {
      addData.push(rl);
    }
  });

  return (
    <>
      <List
        className={styles.list}
        data-tid="episode-localization-list"
        header={
          <div className={styles.listHeader}>
            <h2>Languages</h2>
            <Dropdown
              disabled={addData.length === 0}
              overlay={
                <Menu onClick={handleLocaleMenuClick}>
                  {addData.map((rl) => (
                    <Menu.Item
                      key={`add-localization-menuitem-${rl.id}`}
                      // disabled={userRoles.find((r) => r.id === role.id) != null}
                    >
                      {rl?.locale?.name ?? rl.id}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <MbxButton icon={<PlusOutlined />} size="middle">
                add language
              </MbxButton>
            </Dropdown>
          </div>
        }
        bordered
        dataSource={listData}
        renderItem={({ rl, le, thumb }) => (
          <List.Item
            key={rl.id}
            actions={
              le
                ? [
                    <MbxButton
                      size="small"
                      data-tid="btn-edit-language"
                      key="btn-edit-language"
                      icon={<EditOutlined />}
                      onClick={() => {
                        handleAddLocalization(rl, le);
                      }}
                    />,
                    <MbxButton
                      key="btn-delete-language"
                      data-tid="btn-delete-language"
                      size="small"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        handleDeleteLocale(le);
                      }}
                    />,
                  ]
                : [
                    <MbxButton
                      key="btn-add-language"
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        handleAddLocalization(rl);
                      }}
                    >
                      add
                    </MbxButton>,
                  ]
            }
          >
            <List.Item.Meta
              title={
                <>
                  <span className={styles.listItemTitle}>{rl.locale.name}</span>
                  <Tag className={styles.listItemTag} color={codes[rl.locale.shortcode]}>
                    {rl.locale.shortcode}
                  </Tag>
                  {Array.isArray(defaultAppLocales) && defaultAppLocales.includes(rl.id) && (
                    <Tag color="cyan" className={styles.listItemTag}>
                      default
                    </Tag>
                  )}
                </>
              }
              description={
                le != null ? (
                  <span className={le.content === null ? styles.listItemDates : undefined}>
                    {handleHyphens(le.name)}
                  </span>
                ) : (
                  '_______'
                )
              }
              avatar={thumb}
            />
            {le != null && (
              <div
                style={{ marginLeft: 10, marginRight: -30, color: '#aeaeae', fontSize: 11 }}
                data-tid="analytics-localized-episode-views"
              >
                <EyeOutlined style={{ fontSize: 12, paddingRight: 6 }} />
                <span style={{ paddingRight: 4, color: 'black' }}>
                  {`${le.views?.toLocaleString() ?? 0}`}
                </span>
                <span style={{ paddingRight: 8, borderRight: '1px solid #aeaeae' }}>total</span>
                <span style={{ color: 'black', paddingLeft: 8, paddingRight: 4 }}>{`${
                  le.analytics.viewsIn28Days?.toLocaleString() || 0
                }`}</span>
                <span>last 28 days</span>
                <span
                  data-tid={`analytics-localized-episode-trend-color-${getTrendType(
                    le?.analytics.viewTrend
                  )}`}
                  style={{
                    paddingLeft: 4,
                    color:
                      trendColors[getTrendType(le?.analytics.viewTrend) as keyof TrendColorsType],
                  }}
                >
                  ({getTrendString(le?.analytics.viewTrend)})
                </span>
              </div>
            )}
          </List.Item>
        )}
      />

      <EpisodeLocalizedAddModal
        app={app}
        visible={modalInfo.isOpen}
        data={selectedLocalizedEpisode}
        episode={episode}
        onSubmit={handleModalSubmit}
        onClose={handleCloseModal}
        afterClose={handleCloseModalDone}
        selectedLocalization={modalInfo.selectedLocalization}
        onFileSelect={handleFileSelect}
        onContentRemove={handleContentRemove}
        canUserRerunConversion={canUserRerunConversion}
        onRerunConversion={handleRerunConversion}
      />
    </>
  );
};

// EpisodeLocalizationList.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: 'EpisodeLocalizationList',
// };

export default EpisodeLocalizationList;
// export default React.memo(EpisodeLocalizationList);
