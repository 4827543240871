import { DownloadOutlined } from '@ant-design/icons';
import { Checkbox, Form, Radio, Tooltip } from 'antd';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

import { BatchUploadComponentState } from './constants';
import { ResourceType } from '../../common/constants/resource-type';
import { getDataFieldNames, isValidEpisodeLocalizedDataAtrtibute } from '../../common/utils/app';
import { formatFileName } from '../../common/utils/batch-upload';
import { ROUTE_NAME_BATCH_UPOAD_TEMPLATE } from '../../navigation/routes';
import { getUrlByName } from '../../navigation/utils';
import { getAllRegionsForApp } from '../../selectors/regions/advanced';
import { useGlobalSelector } from '../../utils/hooks';

import type { EpisodeLocalizedDataIndexes } from '../../common/types/app';
import type { TemplateConfiguration } from '../../common/types/batch-upload';
import type { App } from '../../reducers/AppsReducer';

type Props = Readonly<{
  app: App;
  state: BatchUploadComponentState;
  setState: (state: BatchUploadComponentState) => void;
}>;

const TemplateGeneration = ({ app, state: componentState, setState }: Props) => {
  const allRegions = useGlobalSelector((state) => getAllRegionsForApp(state, app.id));

  const [resourceType, setResourceType] = useState();
  const [isCreate, setIsCreate] = useState();
  const [languages, setLanguages] = useState<Array<string>>([]);
  const [optionalColumns, setOptionalColumns] = useState<Array<string>>([]);

  const dataFieldMapping = getDataFieldNames(app.configuration ?? []);
  const dataFieldOptions = Object.entries(dataFieldMapping)
    .filter((entry): entry is [EpisodeLocalizedDataIndexes, string] => {
      const [key, value] = entry;
      return value !== null && isValidEpisodeLocalizedDataAtrtibute(key);
    })
    .map(([value, label]) => ({ value, label }));
  const appIsConfiguredForAdditionalData = !!dataFieldOptions.length;

  const uniqueLanguages = new Set();
  const languageChoices = allRegions
    .flatMap(({ regionLocales }) => regionLocales)
    .map(({ locale: { name, shortcode } }) => ({
      label: name,
      value: shortcode,
    }))
    .filter(({ value }) => !uniqueLanguages.has(value) && uniqueLanguages.add(value));

  const formatUrl = () => {
    return `${getUrlByName(ROUTE_NAME_BATCH_UPOAD_TEMPLATE)}?${queryString.stringify({
      appId: app.id,
      resourceType,
      isCreate,
      languages: languages.join(','),
      optionalColumns: optionalColumns.join(','),
    })}`;
  };

  const [downloadUrl, setDownloadUrl] = useState(formatUrl());
  const [fileName, setFileName] = useState(
    formatFileName({
      app,
      resourceType,
      isCreate,
      languages,
      optionalColumns,
    } as TemplateConfiguration)
  );

  useEffect(() => {
    setFileName(
      formatFileName({
        app,
        resourceType,
        isCreate,
        languages,
        optionalColumns,
      } as TemplateConfiguration)
    );
    setDownloadUrl(formatUrl());

    setState(
      languages.length > 0
        ? BatchUploadComponentState.VALID_TEMPLATE
        : BatchUploadComponentState.GENERATING_TEMPLATE
    );
  }, [app, resourceType, isCreate, languages, optionalColumns]);

  if (componentState === BatchUploadComponentState.TEMPLATE_GENERATED) {
    return (
      <div>
        <h2>Your Metadata Template has been generated</h2>
        <p>Please download your metadata spreadsheet template and fill it at your convenience.</p>
        <p>
          If anything is unclear, you can find additional information and instructions under ‘More
          information’ to guide you through the process.
        </p>
        <a href={downloadUrl} download data-tid="bu-template-url">
          <DownloadOutlined style={{ fontSize: '100px' }} />
          <br />
          {fileName}
        </a>
      </div>
    );
  }

  return (
    <div>
      <h2>Create your metadata spreadsheet template</h2>
      <p>
        The metadata sheet is the starting point of every batch upload. You can now tailor your
        template precisely to your needs.
      </p>
      <Form layout="vertical">
        <Form.Item label={<h2> Select the type of resource you want to upload.</h2>}>
          <Radio.Group onChange={(e) => setResourceType(e.target.value)} value={resourceType}>
            <Radio data-tid="bu-resource-cbx" value={ResourceType.VIDEO_MEDIABOX}>
              Video (mp4, avi, etc.)
            </Radio>
            <Tooltip title="Coming Soon!">
              <Radio value={ResourceType.ARCHIVE_WEBPAGE} disabled={true}>
                Web (website, url, online game)
              </Radio>
            </Tooltip>
            <Tooltip title="Coming Soon!">
              <Radio value={ResourceType.ARCHIVE_UNITYBUNDLE} disabled={true}>
                Unity
              </Radio>
            </Tooltip>
            <Tooltip title="Coming Soon!">
              <Radio value={ResourceType.AUDIO_ONLY} disabled={true}>
                Audio
              </Radio>
            </Tooltip>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={<h2>Please specify what you want to do</h2>}
          hidden={resourceType == null}
        >
          <Radio.Group onChange={(e) => setIsCreate(e.target.value)} value={isCreate}>
            <Radio data-tid="bu-action-cbx" value={true}>
              Create new episodes (and language versions)
            </Radio>
            <Radio value={false}>Add language versions to existing episodes</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={
            <div>
              <h2>Select all language versions you want to add</h2>At least one language is required
            </div>
          }
          hidden={isCreate == null}
        >
          <Checkbox.Group
            style={{ display: 'inline-block' }}
            data-tid="bu-language-cbx"
            options={languageChoices}
            onChange={(checked) => setLanguages(checked.map((value) => value.toString()))}
          />
        </Form.Item>
        <Form.Item
          label={
            <div>
              <h2>Select all additional information you would like to add</h2>
            </div>
          }
          hidden={!appIsConfiguredForAdditionalData || languages.length === 0}
        >
          <Checkbox.Group
            style={{ display: 'inline-block' }}
            data-tid="bu-optional-columns-cbx"
            options={dataFieldOptions}
            onChange={(checked) => setOptionalColumns(checked.map((value) => value.toString()))}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default TemplateGeneration;
