import { Column } from '@ant-design/charts';
import classNames from 'classnames';

import styles from './StackedColumnAndTable.module.scss';
import { monthLabelMapping } from '../../common/constants/months';
import { useOptionalCurrentAppContext } from '../../contexts';
import { getTrendString, getTrendType, TrendTypes } from '../../utils/analytics';

import type { AllowedMonthLabel } from '../../common/constants/months';
import type { MausRegion } from '../../reducers/AppAnalyticsReducer';

type Props = {
  regions: Array<MausRegion>;
};

const COLORS = [
  '#0070AB',
  '#EB8D00',
  '#9E338C',
  '#B4094B',
  '#019CA4',
  '#E3BA00',
  '#3EBFFF',
  '#FFC059',
  '#DB74C9',
  '#ED5689',
  '#30E0EB',
  '#FFE401',
];

const StackedColumnAndTable = ({ regions }: Props) => {
  const { currentApp } = useOptionalCurrentAppContext();
  const currentAppId = currentApp?.id;

  const graphData = regions
    .sort((regionA, regionB) => regionB.currentMonth - regionA.currentMonth)
    .map((region) =>
      region.months.map((month) => ({
        date: `${month.month}-${month.year}`,
        maus: month.maus,
        region: region.name,
        month: month.month,
        year: month.year,
      }))
    )
    .flat();

  // null check for this already happened on parent component
  const firstRegion = regions[0];

  const start = firstRegion.months[0];
  const end = firstRegion.months[12];
  const priorEnd = firstRegion.months[11];

  const { month: startMonth, year: startYear } = start;
  const { month: endMonth, year: endYear } = end;
  const { month: priorEndMonth } = priorEnd;

  const startMonthLabel = monthLabelMapping[startMonth];
  const endMonthLabel = monthLabelMapping[endMonth];

  const graphTitle = `Monthly active devices (${startMonthLabel} ${startYear} - ${endMonthLabel} ${endYear})`;

  return (
    <>
      <div className={styles.graph} data-tid="analytics-maus-graph">
        {/* We need to force re-render here, otherwise the graph might have 
        incorrect size when app is switched */}
        <div key={currentAppId}>
          <div className={styles.graphHeadline}>{graphTitle}</div>
          <Column
            data={graphData}
            isStack={true}
            xField="date"
            yField="maus"
            seriesField="region"
            xAxis={{
              label: {
                formatter: (v) => v.split('-')[0].toLocaleUpperCase(),
              },
            }}
            yAxis={{
              label: {
                formatter: (v) => {
                  const value = Number(v);
                  return Number.isInteger(value) ? value.toLocaleString() : '';
                },
              },
            }}
            tooltip={{
              offset: 30,
              title: (_, { month, year }) =>
                `${monthLabelMapping[month as AllowedMonthLabel]} ${year}`,
              formatter: ({ region, maus }) => ({
                name: region,
                value: maus.toLocaleString(),
              }),
            }}
            color={COLORS}
            legend={false}
            animation={false}
          />
        </div>
      </div>
      {regions.length > 1 && (
        <>
          <table className={classNames(styles.table)} data-tid="analytics-maus-box-table">
            <thead>
              <tr>
                <th>Market</th>
                <th>{priorEndMonth}</th>
                <th>{endMonth}</th>
                <th>Trend</th>
              </tr>
            </thead>
            <tbody>
              {regions
                .sort((regionA, regionB) => regionB.currentMonth - regionA.currentMonth)
                .map(({ name, currentMonth, priorMonth, trend }) => (
                  <tr key={name}>
                    <td>
                      <div className={styles.localeName}>{name}</div>
                    </td>
                    <td>{priorMonth.toLocaleString()}</td>
                    <td>{currentMonth.toLocaleString()}</td>
                    <td
                      data-tid={`analytics-maus-box-table-trend-color-${getTrendType(trend)}`}
                      className={classNames({
                        [styles.trendNA]: getTrendType(trend) === TrendTypes.NEUTRAL,
                        [styles.trendUp]: getTrendType(trend) === TrendTypes.UP,
                        [styles.trendDown]: getTrendType(trend) === TrendTypes.DOWN,
                      })}
                    >
                      {getTrendString(trend)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className={styles.break} />
        </>
      )}
    </>
  );
};

export default StackedColumnAndTable;
