import { Alert } from 'antd';
import { useHistory } from 'react-router-dom';

import ButtonLinked from './ButtonLinked';
import { ROUTE_NAME_INVITECONFIRMATION } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getUserProfileOptional } from '../selectors/authSelectors';
import { getAllInvitesForEmail } from '../selectors/inviteSelectors';
import { useGlobalSelector } from '../utils/hooks';

//
//
const AlertPendingInvites = () => {
  const history = useHistory();
  const user = useGlobalSelector(getUserProfileOptional);
  const userInvites = useGlobalSelector((state) => getAllInvitesForEmail(state, user?.email));

  if (history.location.pathname.indexOf('/invites/') !== 0 && userInvites?.length > 0) {
    return (
      <div style={{ margin: '2em', display: 'flex', justifyContent: 'center' }}>
        <Alert
          data-tid="alert-invites-pending"
          message="Pending invites"
          description={
            <>
              <p>You have pending invites for these apps:</p>
              <ul>
                {userInvites.map((invite) => (
                  <li key={`alert-invites-pending-${invite.id}`}>
                    <ButtonLinked
                      linkTo={getUrlByName(ROUTE_NAME_INVITECONFIRMATION, {
                        ':token': invite.hash,
                      })}
                      type="link"
                    >
                      {invite.appName}
                    </ButtonLinked>
                  </li>
                ))}
              </ul>
            </>
          }
          type="warning"
          showIcon
          style={{ minWidth: '320px', maxWidth: '640px' }}
        />
      </div>
    );
  }

  return null;
};

export default AlertPendingInvites;
