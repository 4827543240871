import queryString from 'query-string';

export type PathParams = {
  appUid?: string | null | undefined;
  regionId?: string | null | undefined;
  appId?: string | null | undefined;
  userId?: string | null | undefined;
  episodeId?: string | null | undefined;
  appPlatformId?: string | null | undefined;
  releaseId?: string | null | undefined;
  deploymentId?: string | null | undefined;
  jobId?: string | null | undefined;
  assetId?: string | null | undefined;
  categoryId?: string | null | undefined;
  token?: string | null | undefined;
};

export type EpisodeSortInfo = {
  sortBy: 'date' | 'name' | 'views';
  order: 'asc' | 'desc';
};

export type ReleaseSortInfo = {
  sortBy: 'date' | 'name' | 'publishDate';
  order: 'asc' | 'desc';
};

//
//
export const getAppId = (params: PathParams) => {
  const { appId } = params;
  if (appId == null) {
    throw new Error('appId should not be null or undefined');
  }
  const appIdAsNumber = Number(appId);
  if (Number.isNaN(appIdAsNumber)) {
    throw new Error('appId has to be int');
  }
  return appIdAsNumber;
};

//
//
export const getAppUid = (
  params: PathParams,
  { optional = false }: { optional?: boolean } = {}
) => {
  const { appUid } = params;
  if (appUid == null) {
    if (!optional) {
      throw new Error('appUid is not set');
    }
    return null;
  }
  return appUid;
};

//
//
export const getAssetId = (params: PathParams) => {
  const { assetId } = params;
  if (assetId == null) {
    throw new Error('assetId should not be null or undefined');
  }
  const assetIdAsNumber = Number(assetId);
  if (Number.isNaN(assetIdAsNumber)) {
    throw new Error('assetId has to be int');
  }
  return assetIdAsNumber;
};

//
//
export const getDeploymentId = (params: PathParams) => {
  const { deploymentId } = params;
  if (deploymentId == null) {
    throw new Error('deploymentId should not be null or undefined');
  }
  const deploymentIdAsNumber = Number(deploymentId);
  if (Number.isNaN(deploymentIdAsNumber)) {
    throw new Error('deploymentId has to be int');
  }
  return deploymentIdAsNumber;
};

//
//
export const getEpisodeId = (params: PathParams) => {
  const { episodeId } = params;
  if (episodeId == null) {
    throw new Error('episodeId should not be null or undefined');
  }
  const episodeIdAsNumber = Number(episodeId);
  if (Number.isNaN(episodeIdAsNumber)) {
    throw new Error('episodeId has to be int');
  }
  return episodeIdAsNumber;
};

//
//
export const getAppPlatformId = (params: PathParams) => {
  const { appPlatformId } = params;
  if (appPlatformId == null) {
    throw new Error('appPlatformId should not be null or undefined');
  }
  const appPlatformIdAsNumber = Number(appPlatformId);
  if (Number.isNaN(appPlatformIdAsNumber)) {
    throw new Error('appPlatformId has to be int');
  }
  return appPlatformIdAsNumber;
};

//
//
export const getJobId = (params: PathParams) => {
  const { jobId } = params;
  if (jobId == null) {
    throw new Error('jobId should not be null or undefined');
  }
  const jobIdAsNumber = Number(jobId);
  if (Number.isNaN(jobIdAsNumber)) {
    throw new Error('jobId has to be int');
  }
  return jobIdAsNumber;
};

//
//
export const getRegionId = (params: PathParams) => {
  const { regionId } = params;
  if (regionId == null) {
    throw new Error('regionId should not be null or undefined');
  }
  const regionIdAsNumber = Number(regionId);
  if (Number.isNaN(regionIdAsNumber)) {
    throw new Error('regionId has to be int');
  }
  return regionIdAsNumber;
};

//
//
export const getReleaseId = (params: PathParams) => {
  const { releaseId } = params;
  if (releaseId == null) {
    throw new Error('releaseId should not be null or undefined');
  }
  const releaseIdAsNumber = Number(releaseId);
  if (Number.isNaN(releaseIdAsNumber)) {
    throw new Error('releaseId has to be int');
  }
  return releaseIdAsNumber;
};

//
//
export const getToken = (params: PathParams) => {
  const { token } = params;
  if (typeof token !== 'string') {
    throw new Error('token has to be string');
  }
  return token;
};

//
//
export const getUserId = (params: PathParams) => {
  const { userId } = params;
  if (userId == null) {
    throw new Error('userId should not be null or undefined');
  }
  const userIdAsNumber = Number(userId);
  if (Number.isNaN(userIdAsNumber)) {
    throw new Error('userId has to be int');
  }
  return userIdAsNumber;
};

//
//
export const getCategoryId = (params: PathParams) => {
  const { categoryId } = params;
  if (categoryId == null) {
    throw new Error('categoryId should not be null or undefined');
  }
  const categoryIdAsNumber = Number(categoryId);
  if (Number.isNaN(categoryIdAsNumber)) {
    throw new Error('categoryId has to be int');
  }
  return categoryIdAsNumber;
};

//
//
export const getPageFromSearch = (search: string) => {
  const { page } = queryString.parse(search);
  if (typeof page === 'string') {
    const pageAsNumber = Number(page);
    if (!Number.isNaN(pageAsNumber)) {
      return pageAsNumber;
    }
  }
  return 1;
};

//
//
export const getQFromSearch = (search: string) => {
  const { q } = queryString.parse(search);
  if (typeof q === 'string') {
    return q;
  }
  return '';
};

//
//
export const getVFromSearch = (search: string) => {
  const { v } = queryString.parse(search);
  if (typeof v === 'string') {
    return v;
  }
  return '';
};

//
//
export const getPerPageFromSearch = (search: string, defaultValue: number) => {
  const { perPage } = queryString.parse(search);
  if (typeof perPage === 'string') {
    const perPageAsNumber = Number(perPage);
    if (!Number.isNaN(perPageAsNumber)) {
      return perPageAsNumber;
    }
  }
  return defaultValue;
};

//
//
export const getRegionFromSearch = (search: string) => {
  const { region } = queryString.parse(search);
  if (typeof region === 'string') {
    const regionAsNumber = Number(region);
    if (!Number.isNaN(regionAsNumber)) {
      return regionAsNumber;
    }
  }
  return -1;
};

//
//
export const getEpisodeFromSearch = (search: string) => {
  const { episode } = queryString.parse(search);
  if (typeof episode === 'string') {
    const episodeAsNumber = Number(episode);
    if (!Number.isNaN(episodeAsNumber)) {
      return episodeAsNumber;
    }
  }
  return -1;
};

//
//
export const getEmailFromSearch = (search: string) => {
  const { email } = queryString.parse(search);
  return typeof email === 'string' ? email : null;
};

//
//
export const getCategoriesFromSearch = (search: string) => {
  const { cats } = queryString.parse(search);
  if (typeof cats === 'string') {
    return cats
      .split('x')
      .map((t) => Number(t))
      .filter((t): t is number => !!t);
  }
  return [];
};

//
//
export const getTagsFromSearch = (search: string) => {
  const { tags } = queryString.parse(search);
  if (typeof tags === 'string') {
    return tags
      .split('x')
      .map((t) => Number(t))
      .filter((t): t is number => !!t);
  }
  return [];
};

//
//
export const getTypesFromSearch = (search: string) => {
  const { types } = queryString.parse(search);
  if (typeof types === 'string') {
    return types
      .split('x')
      .map((t) => Number(t))
      .filter((t): t is number => !!t);
  }
  return [];
};

//
//
export const getFreeFromSearch = (search: string) => {
  const { free } = queryString.parse(search);
  return free === 'true';
};

//
//
export const getEpisodeSortInfoFromSearch = (search: string): EpisodeSortInfo => {
  const { sort } = queryString.parse(search);
  if (typeof sort === 'string') {
    const [sortBy, order] = sort.split(':');
    if (
      (sortBy === 'date' || sortBy === 'name' || sortBy === 'views') &&
      (order === 'asc' || order === 'desc')
    ) {
      return { sortBy, order };
    }
  }
  return { sortBy: 'date', order: 'desc' };
};

//
//
export const getReleaseSortInfoFromSearch = (search: string): ReleaseSortInfo => {
  const { sort } = queryString.parse(search);
  if (typeof sort === 'string') {
    const [sortBy, order] = sort.split(':');
    if (
      (sortBy === 'date' || sortBy === 'publishDate' || sortBy === 'name') &&
      (order === 'asc' || order === 'desc')
    ) {
      return { sortBy, order };
    }
  }
  return { sortBy: 'publishDate', order: 'desc' };
};

//
//
export const getBacklinkFromSearch = (search: string): string | null => {
  const { backlink } = queryString.parse(search);
  if (Array.isArray(backlink)) {
    return null;
  }
  return backlink;
};
