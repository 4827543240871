import { Form, Select } from 'antd';
import uniqBy from 'lodash.uniqby';
import { useState, useRef, useEffect } from 'react';

import MbxButton from './base/MbxButton';
import { selectFilterFunc } from '../utils/filter';
import { logUnknownError } from '../utils/log';

import type { Category } from '../reducers/CategoryReducer';
import type { ReleaseCategory } from '../reducers/ReleaseCategoryReducer';
import type { Release } from '../reducers/ReleaseReducer';
import type { BaseSelectRef } from 'rc-select';
import type { MouseEventHandler } from 'react';

type Props = Readonly<{
  visible: boolean;
  release: Release;
  allCategoriesForApp: Array<Category>;
  handleOk: (categories: Array<{ id: number; name: string }>) => Promise<void>;
  handleCancel: MouseEventHandler<HTMLElement>;
}>;

//
//
const ReleaseCategoryInlineAddForm = (props: Props) => {
  const { visible, release, allCategoriesForApp, handleOk, handleCancel } = props;

  const [isSaving, setIsSaving] = useState(false);
  const inputRef = useRef<BaseSelectRef>(null);

  useEffect(() => {
    let to: number | null = null;
    if (visible === true) {
      // console.log('useEffect()', visible, inputRef.current);
      to = window.setTimeout(() => {
        if (inputRef.current != null) {
          inputRef.current.focus();
        }
      }, 100);
    }

    return () => {
      if (to != null) {
        window.clearTimeout(to);
      }
    };
  }, [visible]);

  const handleSubmit = async (values: { categories: Array<string> }) => {
    console.log('handleSubmit()', values);

    const categoryInfo = uniqBy(
      values.categories.map((categoryStr: string) => {
        const existingCategory = allCategoriesForApp.find(
          (c: Category) => c.name.toLowerCase() === categoryStr.toLowerCase()
        );
        if (typeof existingCategory !== 'undefined') {
          return { id: Number(existingCategory.id), name: existingCategory.name };
        }
        throw new Error('unknown category');
      }),
      'name'
    );

    console.log('converted categories to: ', categoryInfo);

    try {
      setIsSaving(true);
      await handleOk(categoryInfo);
    } catch (err) {
      logUnknownError(err);
    } finally {
      setIsSaving(false);
    }
  };

  /* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Form layout="inline" onFinish={handleSubmit} name="ReleaseCategoryInlineAddForm">
        <Form.Item
          name="categories"
          initialValue={(release?.categories ?? []).map(
            (rc: ReleaseCategory) => rc?.category?.name ?? `rc-${rc.id}`
          )}
        >
          <Select
            ref={inputRef}
            mode="multiple"
            size="small"
            // autoFocus  // buggy - see: https://github.com/ant-design/ant-design/issues/22864
            style={{ width: '176px', paddingTop: 4 }}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ maxWidth: '290px' }}
            tokenSeparators={[',']}
            filterOption={selectFilterFunc}
          >
            {allCategoriesForApp.map((c: Category) => (
              <Select.Option key={c.id} value={c.name}>
                <span
                  style={{
                    color: '#eb2f96',
                    opacity: c.isDraft ? 0.4 : 1,
                    fontWeight: 'bold',
                  }}
                >
                  {c.name}
                </span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item style={{ marginRight: 0 }}>
          <MbxButton mbxType="primary" size="small" htmlType="submit" loading={isSaving}>
            Ok
          </MbxButton>
          <MbxButton
            size="small"
            style={{ marginLeft: '10px' }}
            onClick={handleCancel}
            loading={isSaving}
          >
            Cancel
          </MbxButton>
        </Form.Item>
      </Form>
    </div>
  );
  /* eslint-enable */
};

export default ReleaseCategoryInlineAddForm;
