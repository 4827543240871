import { createSelector } from 'reselect';

import type { RootState } from '../../reducers';

//
//
export const authServicesById = (state: RootState) => state.authServices.byId;

//
//
export const getAllAuthServices = createSelector(authServicesById, (byId) => {
  return Object.values(byId);
});

//
//
export const getAuthServiceNormalizedByIdFunc = createSelector(
  authServicesById,
  //
  (byId) => (authServiceId: number) => {
    const authService = byId[authServiceId];
    if (authService == null) {
      return null;
    }
    return authService;
  }
);
