import { Form, Input, Modal, Checkbox, Row, Col } from 'antd';
import { useState, useEffect, Fragment } from 'react';

import styles from './RoleModal.module.scss';
import { DEFAULT_USER_RIGHTS, UserRight, UserRightIndexes } from '../common/constants/user-right';
import { includes } from '../common/utils/array';
import { getLabel } from '../utils/role';

import type { Role } from '../reducers/RoleReducer';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';

export type RightItem = {
  name: string;
  index: number;
  label: string;
  isChecked: boolean;
};

export type RightGroup = {
  groupIndex: number;
  name: string;
  rights: Array<RightItem>;
};

export type RoleModalMode = 'add' | 'edit';
export type RoleModalValues = {
  name: string;
  rights: Array<number>;
};

type Props = {
  role: Role | null | undefined;
  visible: boolean;
  isSaving: boolean;
  mode: RoleModalMode;
  onSubmit: (
    mode: RoleModalMode,
    values: RoleModalValues,
    roleId: number | null | undefined
  ) => Promise<void>;
  onClose: () => void;
};

const IGNORE_RIGHTS = [UserRight.JOBS_GET, UserRight.JOBS_GET_LIST];

let index = -1;
const indexGroups: Array<RightGroup> = [];

const indexesAsArray = Object.keys(UserRightIndexes).map((rightName) => rightName);
indexesAsArray.forEach((name) => {
  if (includes(IGNORE_RIGHTS, name)) {
    return;
  }
  const rightIndex = UserRightIndexes[name as keyof typeof UserRightIndexes];
  const splits = name.split('_');
  const groupIndex = Math.floor(rightIndex / 100);
  if (index === -1 || indexGroups[index].groupIndex !== groupIndex) {
    index += 1;
    indexGroups[index] = {
      groupIndex,
      name: splits[0],
      rights: [],
    };
  }
  indexGroups[index].rights.push({
    name,
    index: rightIndex,
    label: splits.slice(1).join(' '),
    isChecked: includes(DEFAULT_USER_RIGHTS, rightIndex),
  });
});
const defaultIndexGroups: Array<RightGroup> = JSON.parse(JSON.stringify(indexGroups));

//
//
const RoleModal = (props: Props) => {
  console.log('RoleModal()', props);
  const { onSubmit, onClose, visible, isSaving, mode, role } = props;
  const [form] = Form.useForm();

  const [checkboxState, setCheckboxState] = useState<Array<RightGroup>>(indexGroups);

  useEffect(() => {
    if (role != null) {
      setCheckboxState((state) => {
        const copy: Array<RightGroup> = JSON.parse(JSON.stringify(state));
        copy.forEach((group) => {
          group?.rights?.forEach((r) => {
            r.isChecked = role.rights.includes(r.index);
          });
        });
        return copy;
      });
    } else {
      const defaultValues = JSON.parse(JSON.stringify(defaultIndexGroups));
      setCheckboxState(() => defaultValues);
    }
  }, [role]);

  useEffect(() => {
    if (visible === true) {
      form.resetFields();
      form.setFieldsValue({
        name: role?.name ?? undefined,
      });
    }
  }, [role, form, visible]);

  let title = 'Edit Role';
  let okText = 'Save';
  if (mode === 'add') {
    title = 'Create Role';
    okText = 'Create';
  }

  const handleOk = () => {
    form.validateFields().then(async (values) => {
      const rights: Array<number> = [];
      checkboxState.forEach((group) => {
        group.rights.forEach((r) => {
          if (r.isChecked) {
            rights.push(r.index);
          }
        });
      });
      console.log('Received values of form: ', values, rights);
      await onSubmit(
        mode,
        {
          ...values,
          rights,
        },
        role?.id
      );
    });
  };

  const handleClose = () => {
    if (isSaving) {
      return;
    }
    onClose();
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const { target } = e;
    const { value, checked } = target;
    const copy: Array<RightGroup> = JSON.parse(JSON.stringify(checkboxState));

    if (typeof value === 'string') {
      copy.forEach((group) => {
        if (group.name === value) {
          group.rights.forEach((r) => {
            r.isChecked = checked;
          });
        }
      });
    } else {
      copy.forEach((group) => {
        group.rights.forEach((r) => {
          if (r.index === value) {
            r.isChecked = checked;
          }
        });
      });
    }
    setCheckboxState(copy);
  };

  return (
    <Modal
      title={title}
      centered
      open={visible}
      onOk={handleOk}
      onCancel={handleClose}
      width={880}
      okText={okText}
      okButtonProps={{ disabled: isSaving, loading: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
      closable={!isSaving}
    >
      <Form className={styles.form} form={form} name="RoleAddForm" layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please add a name!' }]}
        >
          <Input disabled={isSaving} />
        </Form.Item>
        <Form.Item label="Rights">
          <div style={{ width: '100%' }}>
            {checkboxState.map((group) => {
              if (group == null) {
                return null;
              }
              const checkedCount = group.rights.reduce(
                (sum, r) => (r.isChecked ? sum + 1 : sum),
                0
              );
              return (
                <Fragment key={`row-group-${group.name}`}>
                  <h3 style={{ borderBottom: '1px solid #efefef' }}>
                    <span style={{ marginRight: '18px' }}>{group.name}</span>
                    <Checkbox
                      indeterminate={checkedCount !== 0 && checkedCount < group.rights.length}
                      value={group.name}
                      onChange={handleCheckboxChange}
                      checked={checkedCount === group.rights.length}
                      disabled={isSaving}
                    >
                      all
                    </Checkbox>
                  </h3>
                  <Row style={{ marginBottom: '2em', lineHeight: 1.5 }}>
                    {group.rights.map((r) => (
                      <Col span={4} key={`right-check-${r.index}`}>
                        <Checkbox
                          value={r.index}
                          checked={r.isChecked}
                          onChange={handleCheckboxChange}
                          disabled={isSaving}
                        >
                          {getLabel(r)}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Fragment>
              );
            })}
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RoleModal;
