import { message, Alert } from 'antd';
import { useEffect, useState } from 'react';

import { createRole, deleteRole, editRole, getRoleList } from '../actions/roles';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { useOptionalCurrentAppContext } from '../contexts';
import RoleList from '../pages/RoleList';
import { getAllRolesForApp } from '../selectors/roleSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { RoleModalValues } from '../components/RoleModal';
import type { Role } from '../reducers/RoleReducer';

//
//
const UserRoleListContainer = () => {
  const dispatch = useAppDispatch();
  const { currentApp } = useOptionalCurrentAppContext();
  const currentAppId = currentApp?.id ?? null;

  const [error, setError] = useState('');

  const allRoles = useGlobalSelector((state) => getAllRolesForApp(state, currentAppId));

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getRoleList(currentAppId));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };

    fetchData();
  }, [dispatch, currentAppId]);

  const handleRoleAdd = async (values: RoleModalValues) => {
    try {
      await dispatch(createRole(currentAppId, values));
      message.success('Role created');
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
    return false;
  };

  const handleRoleEdit = async (roleId: number, values: RoleModalValues) => {
    try {
      await dispatch(editRole(roleId, values));
      message.success('Role edited');
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
    return false;
  };

  const handleRoleDelete = async (role: Role) => {
    try {
      await dispatch(deleteRole(role));
      message.success('Role deleted');
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
    return false;
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <RoleList
      roles={allRoles}
      handleRoleAdd={handleRoleAdd}
      handleRoleEdit={handleRoleEdit}
      handleRoleDelete={handleRoleDelete}
    />
  );
};

export default UserRoleListContainer;
