import type { AppReceiptValidator } from '../reducers/AppReceiptValidatorReducer';

export const APPRECEIPTVALIDATORS_CREATE = 'APPRECEIPTVALIDATORS_CREATE';
export const APPRECEIPTVALIDATORS_CREATE_OK = 'APPRECEIPTVALIDATORS_CREATE_OK';
export const APPRECEIPTVALIDATORS_CREATE_FAIL = 'APPRECEIPTVALIDATORS_CREATE_FAIL';

export const APPRECEIPTVALIDATORS_DELETE = 'APPRECEIPTVALIDATORS_DELETE';
export const APPRECEIPTVALIDATORS_DELETE_OK = 'APPRECEIPTVALIDATORS_DELETE_OK';
export const APPRECEIPTVALIDATORS_DELETE_FAIL = 'APPRECEIPTVALIDATORS_DELETE_FAIL';

export const APPRECEIPTVALIDATORS_GET_LIST = 'APPRECEIPTVALIDATORS_GET_LIST';
export const APPRECEIPTVALIDATORS_GET_LIST_OK = 'APPRECEIPTVALIDATORS_GET_LIST_OK';
export const APPRECEIPTVALIDATORS_GET_LIST_FAIL = 'APPRECEIPTVALIDATORS_GET_LIST_FAIL';

export const APPRECEIPTVALIDATORS_UPDATE = 'APPRECEIPTVALIDATORS_UPDATE';
export const APPRECEIPTVALIDATORS_UPDATE_OK = 'APPRECEIPTVALIDATORS_UPDATE_OK';
export const APPRECEIPTVALIDATORS_UPDATE_FAIL = 'APPRECEIPTVALIDATORS_UPDATE_FAIL';

export type AppReceiptValidatorsGetListOkAction = {
  type: 'APPRECEIPTVALIDATORS_GET_LIST_OK';
  payload: Array<AppReceiptValidator>;
  appId: number;
};

export type AppReceiptValidatorsCreateOkAction = {
  type: 'APPRECEIPTVALIDATORS_CREATE_OK';
  payload: AppReceiptValidator;
  appId: number;
};

export type AppReceiptValidatorsUpdateOkAction = {
  type: 'APPRECEIPTVALIDATORS_UPDATE_OK';
  payload: AppReceiptValidator;
};

export type AppReceiptValidatorsDeleteOkAction = {
  type: 'APPRECEIPTVALIDATORS_DELETE_OK';
  appId: number;
  appReceiptValidatorId: number;
};

export type AppReceiptValidatorsAction =
  | AppReceiptValidatorsGetListOkAction
  | AppReceiptValidatorsCreateOkAction
  | AppReceiptValidatorsUpdateOkAction
  | AppReceiptValidatorsDeleteOkAction;
