import type { LocationItem } from '../reducers/PageHistoryReducer';

export type PageHistoryAction = {
  type: 'HISTORY_PUSH' | 'HISTORY_POP' | 'HISTORY_REPLACE';
  location: LocationItem;
};

export const HISTORY_PUSH = 'HISTORY_PUSH';
export const HISTORY_POP = 'HISTORY_POP';
export const HISTORY_REPLACE = 'HISTORY_REPLACE';
