import { schema, normalize } from 'normalizr';

import {
  JOB_GET_LIST,
  JOB_GET_LIST_OK,
  JOB_GET_LIST_FAIL,
  JOB_GET_DETAILS,
  JOB_GET_DETAILS_OK,
  JOB_GET_DETAILS_FAIL,
  JOB_GET_METADATA,
  JOB_GET_METADATA_OK,
  JOB_GET_METADATA_FAIL,
  JOB_SWITCH_PAGE,
} from '../constants/jobs';
import { JobSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const getJobList =
  (page = 1) =>
  async (dispatch: AppDispatch) => {
    //Make switch before fetching, to load items from cache if possible
    dispatch({ type: JOB_SWITCH_PAGE, payload: page });

    const response = await wrapFetch({ url: `/jobs?page=${page}` }, dispatch, {
      init: JOB_GET_LIST,
      fail: JOB_GET_LIST_FAIL,
    });
    const normalizedData = normalize(response.data.jobs, new schema.Array(JobSchema));
    const paginationData = response.data.pagination;
    dispatch({ type: JOB_GET_LIST_OK, payload: normalizedData, paginationData });
    return normalizedData;
  };

//
//
export const getJobMetadata = (ids: Array<number>) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/jobs/metadata`,
      params: {
        // allow maximum of 25 ids (performance ...)
        ids: ids.slice(0, 25).join(','),
      },
    },
    dispatch,
    {
      init: JOB_GET_METADATA,
      fail: JOB_GET_METADATA_FAIL,
    }
  );
  const normalizedData = normalize(response.data, new schema.Array(JobSchema));
  dispatch({ type: JOB_GET_METADATA_OK, payload: normalizedData });
  return normalizedData;
};

//
//
export const getJobDetails = (jobId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/jobs/${jobId}` }, dispatch, {
    init: JOB_GET_DETAILS,
    fail: JOB_GET_DETAILS_FAIL,
  });
  const normalizedData = normalize(response.data, JobSchema);
  dispatch({ type: JOB_GET_DETAILS_OK, payload: normalizedData });
  return normalizedData;
};
