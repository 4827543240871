import isEqual from 'lodash.isequal';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';

import { fillPath, replace } from '../../navigation/utils';
import { formatDate } from '../../utils/date';
import { getAppPlatformByIdFunc } from '../appPlatformSelectors';
import { getAppByUidFunc, getAppByIdFunc } from '../apps/advanced';
import { getAssetByIdFunc } from '../assetSelectors';
import { getDeploymentByIdFunc } from '../deploymentSelectors';
import { getEpisodeByIdFunc } from '../episodeSelectors';
import { getRegionByIdFunc } from '../regions/advanced';
import { getReleaseByIdFunc } from '../releaseSelectors';
import { getUserByIdFunc } from '../userSelectors';

import { getParams, getCrumbs } from './index';

import type { RootState } from '../../reducers';
import type { Selector } from 'reselect';

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

type Crumb = { name: string; path: string };

//
//
export const getFilledCrumbs: Selector<RootState, Array<Crumb>> = createSelector(
  getParams,
  getCrumbs,
  getRegionByIdFunc,
  getUserByIdFunc,
  getEpisodeByIdFunc,
  getReleaseByIdFunc,
  getDeploymentByIdFunc,
  getAssetByIdFunc,
  getAppByIdFunc,
  getAppByUidFunc,
  getAppPlatformByIdFunc,
  //
  (
    params,
    crumbs,
    $getRegionById,
    $getUserById,
    $getEpisodeById,
    $getReleaseById,
    $getDeploymentById,
    $getAssetById,
    $getAppById,
    $getAppByUid,
    $getAppPlatformById
  ) => {
    const {
      appUid,
      regionId,
      appId,
      userId,
      episodeId,
      releaseId,
      deploymentId,
      jobId,
      assetId,
      appPlatformId,
    } = params;

    const selectedRegion = $getRegionById(Number(regionId));
    const selectedUser = $getUserById(Number(userId));
    const selectedEpisode = $getEpisodeById(Number(episodeId));
    const selectedRelease = $getReleaseById(Number(releaseId));
    const selectedDeployment = $getDeploymentById(Number(deploymentId));
    const selectedAsset = $getAssetById(Number(assetId));
    const selectedApp = $getAppById(Number(appId));
    const currentApp = $getAppByUid(appUid);
    const selectedAppPlatform = $getAppPlatformById(Number(appPlatformId));

    const replacements: Record<string, string> = {
      appPlatformName: selectedAppPlatform?.platform?.name ?? '??',
      appName: currentApp ? currentApp?.name ?? '??' : selectedApp?.name ?? '??',
      regionName: selectedRegion?.name ?? '??',
      episodeName: selectedEpisode?.name ?? '??',
      userName: `${selectedUser?.firstname ?? '??'} ${selectedUser?.lastname ?? '??'}`,
      releaseName:
        selectedRelease?.episode?.name != null && selectedRelease?.episode?.name !== ''
          ? `${selectedRelease?.episode?.name} ${'\u00A0'}(${selectedRelease.region.name})`
          : '??',
      assetName: selectedAsset?.name ?? '??',
      jobId: jobId ?? '??',
      deploymentDate:
        selectedDeployment !== null ? `${formatDate(selectedDeployment.createdAt)}` : '??',
    };

    // handle emptyObjects w/ empty strings as names
    Object.keys(replacements).forEach((key) => {
      if (replacements[key] === '') {
        replacements[key] = '??';
      }
    });

    const filledCrumbs = crumbs.map((p) => ({
      name: replace(p.name, replacements),
      path: fillPath(p.path, params),
    }));
    return filledCrumbs;
  }
);

//
//
export const getPathsForMainNav = createDeepEqualSelector(
  getFilledCrumbs,
  //
  (filledCrumbs) => {
    const pathsForMainNav = filledCrumbs.map((c) => c.path);
    return pathsForMainNav;
  }
);

//
//
export const getPageTitle = createDeepEqualSelector(
  getFilledCrumbs,
  //
  (filledCrumbs) => {
    const pageTitle = filledCrumbs
      .filter((c) => c.path !== '/')
      .map((c) => c.name)
      .join(' / ');

    return pageTitle;
  }
);
