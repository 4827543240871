import { message } from 'antd';
import { useHistory, Redirect } from 'react-router-dom';

import { createApp } from '../actions/apps';
import { APP_SETUP_VERSIONS } from '../common/constants/app';
import { ROUTE_NAME_INTERNAL_APPCREATE, ROUTE_NAME_INTERNAL_APPS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import AppCreateV1 from '../pages/AppCreateV1';
import AppCreateV1_1 from '../pages/AppCreateV1-1';
import { getAppUiState } from '../selectors/apps/advanced';
import { getVFromSearch } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { AppEditFormValues } from '../components/AppEditForm';

//
//
const AppCreateContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const appUi = useGlobalSelector(getAppUiState);

  const currentVersion = getVFromSearch(history.location.search);

  const handleSubmit = async (values: AppEditFormValues) => {
    try {
      console.log('handleSubmit()', values);
      await dispatch(createApp(values));
      history.push(getUrlByName(ROUTE_NAME_INTERNAL_APPS));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  if (currentVersion === '' || !APP_SETUP_VERSIONS.includes(currentVersion)) {
    return (
      <Redirect to={`${getUrlByName(ROUTE_NAME_INTERNAL_APPCREATE)}?v=${APP_SETUP_VERSIONS[0]}`} />
    );
  }

  if (currentVersion === '1.1') {
    return <AppCreateV1_1 handleSubmit={handleSubmit} isLoading={appUi.isCreatingApp} />;
  }

  return <AppCreateV1 handleSubmit={handleSubmit} isLoading={appUi.isCreatingApp} />;
};

export default AppCreateContainer;
