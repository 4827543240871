import union from 'lodash.union';
import { combineReducers } from 'redux';

import {
  EPISODE_CREATE_OK,
  EPISODE_UPDATE_OK,
  EPISODE_GET_DETAILS_OK,
  EPISODE_GET_METADATA_OK,
  EPISODE_GET_LIST_OK,
  EPISODE_TAGS_CLEANUP,
} from '../constants/episodes';
import { TAG_GET_LIST_OK } from '../constants/tags';

import type { Episode } from './EpisodeReducer';
import type { AnyAction } from 'redux';

export type MbxTag = {
  id: number;
  name: string;
};

export type MbxTagByIdState = {
  [k: string | number]: MbxTag;
};
export type MbxTagAllIdsState = {
  [k: string | number]: Array<number>;
};
export type MbxTagState = {
  byId: MbxTagByIdState;
  allIds: MbxTagAllIdsState;
};

const initialStateById: MbxTagByIdState = {};
const initialStateAllIds: MbxTagAllIdsState = {};

//
//
export const getEmptyTag = (id?: number): MbxTag => ({
  id: id ?? -1,
  name: '',
});

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): MbxTagByIdState => {
  switch (action.type) {
    case EPISODE_CREATE_OK:
    case EPISODE_UPDATE_OK:
    case EPISODE_GET_METADATA_OK:
    case EPISODE_GET_DETAILS_OK:
    case EPISODE_GET_LIST_OK:
    case TAG_GET_LIST_OK:
      return {
        ...state,
        ...action.payload.entities.tags,
      };

    case EPISODE_TAGS_CLEANUP: {
      const appTagIds: Array<number> = [];
      action.episodes.forEach((e: Episode) => {
        e.tags.forEach((et) => {
          appTagIds.push(et.tag.id);
        });
      });

      const newState: MbxTagByIdState = {};
      Object.keys(state).forEach((key) => {
        const tag = state[key];
        if (appTagIds.includes(tag.id)) {
          newState[key] = tag;
        }
      });
      return newState;
    }

    default:
      return state;
  }
};

//
//
// eslint-disable-next-line default-param-last
const allIds = (state = initialStateAllIds, action: AnyAction): MbxTagAllIdsState => {
  switch (action.type) {
    case EPISODE_CREATE_OK:
    case EPISODE_UPDATE_OK:
    case EPISODE_GET_DETAILS_OK:
    case EPISODE_GET_LIST_OK:
    case EPISODE_GET_METADATA_OK: {
      const tags = action?.payload?.entities?.tags ?? {};
      return {
        ...state,
        [action.appId]: union(
          state[action.appId],
          Object.keys(tags).map((idStr) => parseInt(idStr, 10))
        ),
      };
    }

    case TAG_GET_LIST_OK:
      return {
        ...state,
        [action.appId]: action.payload.result,
      };

    case EPISODE_TAGS_CLEANUP: {
      const appTagIds: Array<number> = [];
      action.episodes.forEach((e: Episode) => {
        e.tags.forEach((et) => {
          appTagIds.push(et.tag.id);
        });
      });

      return {
        ...state,
        [action.appId]: state[action.appId].filter((tId) => appTagIds.includes(tId)),
      };
    }

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
});

export default combined;
