import merge from 'lodash.merge';
import union from 'lodash.union';
import { combineReducers } from 'redux';

import { ContentType } from '../common/constants/content-type';
import { ResourceType } from '../common/constants/resource-type';
import {
  EPISODELOCALIZED_ADD_CONTENT_OK,
  EPISODELOCALIZED_CREATE_OK,
  EPISODELOCALIZED_DELETE_OK,
  EPISODELOCALIZED_REMOVE_CONTENT_OK,
} from '../constants/episodeLocalized';
import {
  EPISODE_GET_LIST_OK,
  EPISODE_GET_DETAILS_OK,
  EPISODE_CREATE_OK,
  EPISODE_UPDATE_OK,
  EPISODE_REMOVE_OK,
  EPISODE_GET_METADATA_OK,
} from '../constants/episodes';
import {
  EPISODEVERSIONREQUIREMENTS_CREATE_OK,
  EPISODEVERSIONREQUIREMENTS_DELETE_OK,
} from '../constants/episodeVersionRequirements';
import { RELEASE_GET_LIST_OK, RELEASE_GET_DETAILS_OK } from '../constants/releases';

import type { EpisodeLocalized } from './EpisodeLocalizedReducer';
import type { EpisodeTag } from './EpisodeTagReducer';
import type { EpisodeVersionRequirement } from './EpisodeVersionRequirementReducer';
import type { Resource } from './ResourceReducer';
import type { DayViews } from '../common/types/analytics';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type EpisodeBase = {
  id: number;
  appId: number;
  name: string;
  playerTypeId: number;
  resourceTypeId: ResourceType;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date | null;
  updatedBy: number | null;
  views: number;
  analytics: {
    viewsIn28Days: number | null;
    viewsInPrior28Days: number | null;
    viewTrend: number | null;
    viewDays: DayViews;
  } | null;
};

export type EpisodeNormalized = Merge<
  EpisodeBase,
  {
    localizedEpisodes: Array<number>;
    tags: Array<number>;
    requirements: Array<number>;
    thumbnail: number | null;
  }
>;

export type Episode = Merge<
  EpisodeBase,
  {
    localizedEpisodes: Array<EpisodeLocalized>;
    tags: Array<EpisodeTag>;
    requirements: Array<EpisodeVersionRequirement>;
    thumbnail: Resource | null;
  }
>;

export type EpisodeByIdState = {
  [k: string | number]: EpisodeNormalized;
};
export type EpisodeAllIdsState = {
  [k: string | number]: Array<number>;
};
export type EpisodeState = {
  byId: EpisodeByIdState;
  allIds: EpisodeAllIdsState;
};

//
//
export const getEmptyEpisode = (id?: number): Episode => ({
  id: id ?? -1,
  appId: -1,
  name: '',
  createdAt: new Date(),
  createdBy: -1,
  updatedAt: null,
  updatedBy: null,
  localizedEpisodes: [],
  playerTypeId: -1,
  resourceTypeId: ResourceType.IMAGE_MEDIABOX,
  thumbnail: null,
  tags: [],
  requirements: [],
  views: 0,
  analytics: null,
});

const initialStateById: EpisodeByIdState = {};
const initialStateAllIds: EpisodeAllIdsState = {};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): EpisodeByIdState => {
  switch (action.type) {
    // EPISODE_GET_LIST_OK needs to delete/exclude episodes that are no longer there (using appId)
    case EPISODE_GET_LIST_OK: {
      if (action.payload.entities.episodes == null) {
        return state;
      }
      const newState = { ...state };
      let keys = Object.keys(state);
      if (keys.length === 0) {
        return { ...action.payload.entities.episodes };
      }
      Object.keys(action.payload.entities.episodes).forEach((idStr) => {
        const id = Number(idStr);
        const newEpisode = action.payload.entities.episodes[id];
        const storedEpisode = newState[id];

        if (storedEpisode != null) {
          // found same episode in store -> update
          newState[id] = { ...storedEpisode, ...newEpisode };
          // remove from original keys so we can delete the remaining keys afterwards
          keys = keys.filter((id1) => id1 !== idStr);
        } else {
          // new episode -> just add it
          newState[id] = newEpisode;
        }
      });

      // remove episodes that are no longer part of the list
      keys.forEach((idStr) => {
        delete newState[idStr];
      });

      return newState;
    }

    case EPISODE_GET_METADATA_OK:
    case RELEASE_GET_DETAILS_OK:
      return merge({}, state, action.payload.entities.episodes);

    case RELEASE_GET_LIST_OK: {
      // add appId to episode objs
      const episodeObj: EpisodeByIdState = {};
      const normalizedEpisodes = action?.payload?.entities?.episodes ?? {};
      Object.keys(normalizedEpisodes).forEach((key) => {
        episodeObj[key] = { ...normalizedEpisodes[key], appId: action.appId };
      });

      return merge({}, state, episodeObj);
    }

    case EPISODE_UPDATE_OK:
    case EPISODE_CREATE_OK:
    case EPISODE_GET_DETAILS_OK: {
      const id = action.payload.result;
      return {
        ...state,
        [id]: { ...action.payload.entities.episodes[id] },
      };
    }

    case EPISODE_REMOVE_OK: {
      const { [action.episode.id]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case EPISODELOCALIZED_CREATE_OK:
      if (!Array.isArray(state[action.episodeId].localizedEpisodes)) {
        return {
          ...state,
          [action.episodeId]: {
            ...state[action.episodeId],
            localizedEpisodes: [action.payload.result],
          },
        };
      }
      return {
        ...state,
        [action.episodeId]: {
          ...state[action.episodeId],
          localizedEpisodes: [...state[action.episodeId].localizedEpisodes, action.payload.result],
        },
      };

    case EPISODEVERSIONREQUIREMENTS_CREATE_OK: {
      const { episodeId } = action;
      return {
        ...state,
        [episodeId]: {
          ...state[episodeId],
          requirements: [...state[episodeId].requirements, action.payload.result],
        },
      };
    }

    case EPISODEVERSIONREQUIREMENTS_DELETE_OK: {
      const { episodeVersionRequirementId } = action;

      const newState: EpisodeByIdState = {};

      Object.keys(state).forEach((idStr) => {
        const episode = state[idStr];
        if (Array.isArray(episode.requirements)) {
          episode.requirements = episode.requirements.filter(
            (id) => id !== episodeVersionRequirementId
          );
        }
        newState[idStr] = episode;
      });

      return newState;
    }

    case EPISODELOCALIZED_REMOVE_CONTENT_OK: {
      const { episodeLocalizedId, content } = action.payload;
      let updatedEpisode: EpisodeNormalized | undefined;
      Object.keys(state).forEach((idStr) => {
        const episode = state[idStr];
        if (
          episode.localizedEpisodes != null &&
          episode.localizedEpisodes.includes(episodeLocalizedId)
        ) {
          updatedEpisode = episode;
        }
      });
      if (updatedEpisode?.thumbnail == null || content.resource.id !== updatedEpisode.thumbnail) {
        return state;
      }
      return { ...state, [updatedEpisode.id]: { ...updatedEpisode, thumbnail: null } };
    }

    case EPISODELOCALIZED_ADD_CONTENT_OK: {
      const { payload, contentWasUsedForEpisodeThumbnail } = action;
      if (contentWasUsedForEpisodeThumbnail == null) {
        return state;
      }

      const episodeContentId = payload.result;
      const episodeContent = payload.entities.episodeContent[episodeContentId];
      const episode = state[contentWasUsedForEpisodeThumbnail];
      return {
        ...state,
        [episode.id]: { ...episode, thumbnail: episodeContent.resource },
      };
    }

    case EPISODELOCALIZED_DELETE_OK: {
      const episode = state[action.episodeId];
      const contents = (action.episodeLocalized as EpisodeLocalized).content;
      const foundContent = contents.find(
        (con) => con.contentTypeId === ContentType.EPISODE_THUMBNAIL
      );

      if (foundContent?.resource?.id === episode.thumbnail) {
        return { ...state, [episode.id]: { ...episode, thumbnail: null } };
      }
      return state;
    }

    default:
      return state;
  }
};

//
//
// eslint-disable-next-line default-param-last
const allIds = (state = initialStateAllIds, action: AnyAction): EpisodeAllIdsState => {
  switch (action.type) {
    case EPISODE_GET_LIST_OK:
      return {
        ...state,
        [action.appId]: action.payload.result,
      };

    case RELEASE_GET_LIST_OK: {
      if (action.payload.entities.episodes == null) {
        return state;
      }
      return {
        ...state,
        [action.appId]: union(
          state[action.appId],
          Object.keys(action.payload.entities.episodes).map((key: string) => parseInt(key, 10))
        ),
      };
    }

    case EPISODE_CREATE_OK:
    case EPISODE_GET_DETAILS_OK: {
      if (state[action.appId] == null) {
        return {
          ...state,
          [action.appId]: [action.payload.result],
        };
      } else {
        // this should only happen for EPISODE_GET_DETAILS_OK
        if (state[action.appId].includes(action.payload.result)) {
          return state;
        }
        return {
          ...state,
          [action.appId]: [...state[action.appId], action.payload.result],
        };
      }
    }

    case EPISODE_REMOVE_OK:
      return {
        ...state,
        [action.episode.appId]: state[action.episode.appId].filter(
          (episodeId) => episodeId !== action.episode.id
        ),
      };

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
});

export default combined;
