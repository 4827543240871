import { combineReducers } from 'redux';

import { CATEGORY_REMOVE_OK } from '../constants/categories';
import {
  RELEASECATEGORY_UPDATE_OK,
  RELEASECATEGORY_DELETE_OK,
  RELEASECATEGORY_BULK_DELETE_OK,
} from '../constants/releaseCategories';
import {
  RELEASE_GET_LIST_OK,
  RELEASE_UPDATE_OK,
  RELEASE_CREATE_OK,
  RELEASE_GET_DETAILS_OK,
} from '../constants/releases';

import type { Category } from './CategoryReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type ReleaseCategoryBase = {
  id: number;
  ordinal: number;
  createdAt: Date;
};

export type ReleaseCategoryNormalized = Merge<
  ReleaseCategoryBase,
  {
    category: number;
  }
>;

export type ReleaseCategory = Merge<
  ReleaseCategoryBase,
  {
    category: Category;
  }
>;

export type ReleaseCategoryByIdState = {
  [k: string | number]: ReleaseCategoryNormalized;
};
export type ReleaseCategoryState = {
  byId: ReleaseCategoryByIdState;
};

const initialStateById: ReleaseCategoryByIdState = {};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): ReleaseCategoryByIdState => {
  switch (action.type) {
    case RELEASE_UPDATE_OK:
    case RELEASE_CREATE_OK:
    case RELEASE_GET_LIST_OK:
    case RELEASE_GET_DETAILS_OK:
    case RELEASECATEGORY_UPDATE_OK:
      return {
        ...state,
        ...action.payload.entities.releaseCategories,
      };

    case RELEASECATEGORY_DELETE_OK: {
      const { [action.releaseCategoryId]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case RELEASECATEGORY_BULK_DELETE_OK: {
      const ids = action?.deletedIds ?? [];
      if (!Array.isArray(ids) || ids.length < 1) {
        return state;
      }
      const newState = { ...state };
      ids.forEach((rcId) => {
        delete newState[rcId];
      });
      return newState;
    }

    case CATEGORY_REMOVE_OK: {
      const { categoryId } = action;
      const newState = { ...state };
      Object.keys(newState).forEach((releaseCategoryId) => {
        if (newState[releaseCategoryId].category === categoryId) {
          delete newState[releaseCategoryId];
        }
      });
      return newState;
    }

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
});

export default combined;
