import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { logout } from '../actions/auth';
import { checkInviteToken, joinApp, removeUserInvite } from '../actions/users';
import { ROUTE_NAME_HOME } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import UserInviteConfirmationPage from '../pages/UserInviteConfirmation';
import { getToken } from '../selectors/urlSelectors';
import { useAppDispatch } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { CheckInviteTokenResponse } from '../reducers/InviteReducer';

//
//
const UserInviteConfirmationContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params = useParams();

  const inviteToken = getToken(params);
  const [invitationData, setInvitationData] = useState<null | Error | CheckInviteTokenResponse>(
    null
  );

  useEffect(() => {
    const check = async () => {
      try {
        if (inviteToken != null) {
          const result = await dispatch(checkInviteToken(inviteToken));
          setInvitationData(result);
        }
      } catch (err) {
        const { unknownError } = logUnknownError(err);
        setInvitationData(unknownError);
      }
    };

    check();
  }, [dispatch, inviteToken]);

  const handleJoinApp = async () => {
    console.log('handleJoinApp()', invitationData);
    try {
      if (invitationData == null || invitationData instanceof Error) {
        throw new Error('invite data not available');
      }
      await dispatch(joinApp(inviteToken));
      message.info('successfully joined');
      history.replace(getUrlByName(ROUTE_NAME_HOME));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleDeclineInvite = async () => {
    console.log('handleDeclineInvite()', invitationData);
    try {
      if (invitationData == null || invitationData instanceof Error) {
        throw new Error('invite data not available');
      }
      await dispatch(removeUserInvite(invitationData.id, inviteToken));
      history.replace(getUrlByName(ROUTE_NAME_HOME));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleLogout = async () => {
    await dispatch(logout());
  };

  return (
    <UserInviteConfirmationPage
      invitationData={invitationData}
      logout={handleLogout}
      joinApp={handleJoinApp}
      declineInvite={handleDeclineInvite}
    />
  );
};

export default UserInviteConfirmationContainer;
