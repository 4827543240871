import { Form, Input, Button } from 'antd';

import styles from './AppEditForm.module.scss';
import { ROLE_ARN_REGEX } from '../common/constants/app';

import type { App } from '../reducers/AppsReducer';

export type AppEditFormValues = {
  name: string;
  uid: string;
  contentBucket: string;
  archiveBucket: string;
  roleArn: string;
  cfIdPub: string;
  cfIdSec: string | null;
  setupVersion: string;
};
// export type AppEditFormValueNames = keyof AppEditFormValues;

type Props = {
  data?: App;
  isLoading?: boolean;
  onSubmit: (values: AppEditFormValues) => Promise<void>;
  onCancel: () => void;
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 6,
    },
  },
};

//
//
const AppEditForm = (props: Props) => {
  const { onSubmit, data, isLoading, onCancel } = props;
  const formMode = data?.id != null ? 'edit' : 'add';

  const handleSubmit = async (values: AppEditFormValues) => {
    console.log('Received values of form: ', values);
    try {
      await onSubmit(values);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Form
        name="appEditForm"
        {...formItemLayout}
        initialValues={data}
        onFinish={handleSubmit}
        className={styles.form}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the app's name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="UID"
          name="uid"
          rules={[
            {
              required: true,
              message: "Please input the app's UID!",
            },
            {
              pattern: new RegExp('^[a-z0-9](?:[a-z0-9-]{0,253}[a-z0-9])?$'), // eslint-disable-line prefer-regex-literals
              message: 'Wrong format!',
            },
          ]}
        >
          <Input disabled={formMode === 'edit'} />
        </Form.Item>
        <Form.Item
          label="S3 content bucket"
          name="contentBucket"
          rules={[
            {
              required: true,
              message:
                'Please input the name of the S3 bucket where the uploaded content should be stored!',
            },
            {
              pattern: new RegExp('^[a-z0-9](?:[a-z0-9-.]{0,253}[a-z0-9])?$', 'i'), // eslint-disable-line prefer-regex-literals
              message: 'Wrong format!',
            },
          ]}
        >
          <Input disabled={formMode === 'edit'} />
        </Form.Item>
        <Form.Item
          label="S3 archive bucket"
          name="archiveBucket"
          rules={[
            {
              required: true,
              message:
                'Please input the name of the S3 bucket where the archived content should be stored!',
            },
            {
              pattern: new RegExp('^[a-z0-9](?:[a-z0-9-.]{0,253}[a-z0-9])?$', 'i'), // eslint-disable-line prefer-regex-literals
              message: 'Wrong format!',
            },
          ]}
        >
          <Input disabled={formMode === 'edit'} />
        </Form.Item>
        <Form.Item
          label="S3 role ARN"
          name="roleArn"
          rules={[
            {
              required: true,
              message: 'Please input the ARN of the AWS role in the clients account!',
            },
            {
              pattern: ROLE_ARN_REGEX,
              message:
                'This is not a ARN for a Role. It should look something like this arn:aws:iam::1234567342:role/xxxxxx',
            },
          ]}
        >
          <Input disabled={formMode === 'edit'} />
        </Form.Item>
        <Form.Item
          label="CloudFront distribution ID (public)"
          name="cfIdPub"
          rules={[
            {
              required: true,
              message: 'Please input the ID',
            },
          ]}
        >
          <Input disabled={formMode === 'edit'} />
        </Form.Item>

        <Form.Item
          label="CloudFront distribution ID (secure)"
          name="cfIdSec"
          rules={[
            {
              required: data?.setupVersion === '1.0', // TODO: v1.1 does not require this
              message: 'Please input the ID',
            },
          ]}
        >
          <Input disabled={formMode === 'edit'} />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            data-tid="btn-save-form"
            type="primary"
            loading={isLoading}
            htmlType="submit"
            className={styles.loginFormButton}
          >
            Save
          </Button>
          <Button
            data-tid="btn-cancel-form"
            loading={isLoading}
            htmlType="button"
            className={styles.buttonFormCancel}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

AppEditForm.defaultProps = {
  data: null,
  isLoading: false,
};

export default AppEditForm;
