import { combineReducers } from 'redux';

import { REGION_GET_DETAILS_OK, REGION_GET_LIST_OK } from '../constants/regions';
import { TIMEZONE_GET_LIST_OK } from '../constants/timezones';

import type { AnyAction } from 'redux';

export type Timezone = {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date | null;
};

export type TimezoneByIdState = {
  [k: string | number]: Timezone;
};
export type TimezoneAllIdsState = Array<number>;
export type TimezoneState = {
  byId: TimezoneByIdState;
  allIds: TimezoneAllIdsState;
};

//
//
export const getEmptyTimezone = (id?: number): Timezone => ({
  id: id ?? -1,
  name: '',
  createdAt: new Date(),
  updatedAt: null,
});

const initialStateById: TimezoneByIdState = {};
const initialStateAllIds: TimezoneAllIdsState = [];

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): TimezoneByIdState => {
  switch (action.type) {
    case TIMEZONE_GET_LIST_OK:
      return action.payload.entities.timezones;

    case REGION_GET_DETAILS_OK:
    case REGION_GET_LIST_OK:
      return {
        ...state,
        ...action.payload.entities.timezones,
      };

    default:
      return state;
  }
};

//
//
// eslint-disable-next-line default-param-last
const allIds = (state = initialStateAllIds, action: AnyAction): TimezoneAllIdsState => {
  switch (action.type) {
    case TIMEZONE_GET_LIST_OK:
      return action.payload.result;

    // case REGION_GET_DETAILS_OK:

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
});

export default combined;
