import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getAppDetails, updateApp, updateAppConfig } from '../actions/apps';
import { rerunAllConversionForApp } from '../actions/resources';
import { ROUTE_NAME_INTERNAL_APPS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import AppDetail from '../pages/AppDetail';
import { getAppById } from '../selectors/apps/advanced';
import { getAppId } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { Configuration } from '../common/types/app';
import type { AppEditFormValues } from '../components/AppEditForm';

//
//
const AppDetailContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useParams();
  const appId = getAppId(params);

  const details = useGlobalSelector((state) => getAppById(state, appId));

  const [error, setError] = useState('');

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        await dispatch(getAppDetails(appId));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
        if (isMounted) {
          setError(() => msg);
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, [dispatch, appId]);

  const handleSubmit = async (values: AppEditFormValues) => {
    try {
      const { name } = values;
      await dispatch(updateApp(appId, name));
      message.success('app updated');
      history.push(getUrlByName(ROUTE_NAME_INTERNAL_APPS));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleCancel = () => {
    history.push(getUrlByName(ROUTE_NAME_INTERNAL_APPS));
  };

  const handleSubmitConfig = async (config: Configuration) => {
    console.log('handleSubmitConfig()', config);
    try {
      await dispatch(updateAppConfig(appId, config));
      message.success('app config updated');
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleRerunAllConversions = async (conversionType: string) => {
    console.log('handleRerunAllConversions()');
    try {
      await dispatch(rerunAllConversionForApp(appId, conversionType));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  return (
    <AppDetail
      error={error}
      details={details}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onSubmitConfiguration={handleSubmitConfig}
      onRerunAllConversions={handleRerunAllConversions}
    />
  );
};

export default AppDetailContainer;
