import { schema, normalize } from 'normalizr';

import {
  REGION_GET_LIST,
  REGION_GET_LIST_OK,
  REGION_GET_LIST_FAIL,
  REGION_GET_DETAILS,
  REGION_GET_DETAILS_OK,
  REGION_GET_DETAILS_FAIL,
  REGION_CREATE,
  REGION_CREATE_OK,
  REGION_CREATE_FAIL,
  REGION_UPDATE,
  REGION_UPDATE_OK,
  REGION_UPDATE_FAIL,
  REGION_REMOVE,
  REGION_REMOVE_OK,
  REGION_REMOVE_FAIL,
} from '../constants/regions';
import { RegionSchema } from '../schemas';
import { getAllRegionsForApp } from '../selectors/regions/advanced';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';
import type { GlobalStateGetter } from '../reducers';
import type { Region } from '../reducers/RegionReducer';

//
//
export const createRegion =
  (
    appId: number,
    name: string,
    isDraft: boolean,
    releaseTimezoneId: number,
    useDefaultAsFallback: boolean
  ) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch<{ id: number }>(
      {
        url: `/regions`,
        method: 'POST',
        data: {
          appId,
          name,
          releaseTimezoneId,
          isDraft: !!isDraft,
          useDefaultAsFallback: !!useDefaultAsFallback,
        },
      },
      dispatch,
      {
        init: REGION_CREATE,
        fail: REGION_CREATE_FAIL,
      },
      201
    );
    const normalizedData = normalize(response.data, RegionSchema);
    dispatch({ type: REGION_CREATE_OK, payload: normalizedData, appId });
    return response.data;
  };

//
//
export const getRegionDetails = (regionId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/regions/${regionId}` }, dispatch, {
    init: REGION_GET_DETAILS,
    fail: REGION_GET_DETAILS_FAIL,
  });
  const normalizedData = normalize(response.data, RegionSchema);
  dispatch({ type: REGION_GET_DETAILS_OK, regionId, payload: normalizedData });
  return response.data;
};

//
//
export const getRegionList =
  (appId: number) => async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const response = await wrapFetch(
      {
        url: `/regions`,
        params: {
          appId,
        },
      },
      dispatch,
      {
        init: REGION_GET_LIST,
        fail: REGION_GET_LIST_FAIL,
      }
    );
    const normalizedData = normalize(response.data, new schema.Array(RegionSchema));
    dispatch({ type: REGION_GET_LIST_OK, appId, payload: normalizedData });

    const state = getState();
    const allRegionsForApp = getAllRegionsForApp(state, appId);
    return allRegionsForApp;
  };

//
//
export const updateRegion =
  (
    regionId: number,
    name: string,
    isDraft: boolean,
    useDefaultAsFallback: boolean,
    releaseTimezoneId: number,
    defaultLocaleId: number | null
  ) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/regions/${regionId}`,
        method: 'PUT',
        data: {
          name,
          isDraft,
          useDefaultAsFallback,
          releaseTimezoneId,
          defaultLocaleId,
        },
      },
      dispatch,
      {
        init: REGION_UPDATE,
        fail: REGION_UPDATE_FAIL,
      }
    );
    const normalizedData = normalize(response.data, RegionSchema);
    dispatch({ type: REGION_UPDATE_OK, payload: normalizedData });
    return normalizedData;
  };

//
//
export const deleteRegion = (region: Region) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/regions/${region.id}`,
      method: 'DELETE',
    },
    dispatch,
    {
      init: REGION_REMOVE,
      fail: REGION_REMOVE_FAIL,
    },
    204
  );
  dispatch({ type: REGION_REMOVE_OK, region });
  return true;
};
