import type {
  Config,
  Configuration,
  AppData,
  ConfigurationGroup,
  ConfigurationItem,
} from '../types/app';

//
//
export const getItemByName = function getItemByName(appData: AppData, name: string) {
  let { configuration } = appData;
  if (Array.isArray(configuration) === false || configuration === null) {
    configuration = [];
  }
  if (typeof name !== 'string') {
    throw new Error('name_not_string');
  }
  const [firstLevel, secondLevel] = name.split('.');

  const groupOrItem = configuration.find((item) => item.name === firstLevel);
  if (groupOrItem != null) {
    if (typeof secondLevel !== 'undefined') {
      if (Array.isArray((groupOrItem as ConfigurationGroup).children)) {
        const item = (groupOrItem as ConfigurationGroup).children.find(
          (child) => child.name === secondLevel
        );
        return item;
      }
    } else {
      return groupOrItem;
    }
  }
  return null;
};

export const getValueByName = function getValueByName<
  T extends string | boolean | number | null | undefined
>(appData: AppData, name: string, defaultValue: T = null as T): T {
  const groupOrItem = getItemByName(appData, name);
  // see https://github.com/eslint/eslint/issues/7071
  if (groupOrItem != null && {}.hasOwnProperty.call(groupOrItem, 'value')) {
    return (groupOrItem as ConfigurationItem).value as T;
  }
  return defaultValue as T;
};

export const getAppRelevantTags = function getAppRelevantTags(app: AppData) {
  const appRelevantTagsValue = getValueByName(app, 'adminData.appRelevantTags') as string;
  const appRelevantTags =
    appRelevantTagsValue != null
      ? appRelevantTagsValue
          .split(',')
          .filter((str) => str !== '')
          .map((str) => str.trim())
      : [];
  return appRelevantTags;
};

//
//
export const customMerge = function customMerge(
  oldArr: Configuration | null,
  newArr: Configuration
) {
  if (Array.isArray(newArr) === false) {
    throw new Error('config_has_to_be_array');
  }

  const copy = JSON.parse(JSON.stringify(newArr));
  const result: Array<Config> = [];
  copy.forEach((item: Config) => {
    // console.log('item', item);
    if (item.type === 'group') {
      const groupname = item.name;
      const oldGroupValue = getItemByName({ configuration: oldArr }, groupname);
      // console.log('oldGroupValue', oldGroupValue);
      if (oldGroupValue != null) {
        const newGroup: ConfigurationGroup = {
          name: groupname,
          type: 'group',
          children: [],
        };
        (item as ConfigurationGroup).children.forEach((child) => {
          const oldItemValue = getValueByName(
            { configuration: oldArr },
            `${groupname}.${child.name}`
          );
          // console.log('oldItemValue 1', oldItemValue);
          if (oldItemValue != null) {
            newGroup.children.push({ ...child, value: oldItemValue });
          } else {
            newGroup.children.push(child);
          }
        });
        result.push(newGroup);
      } else {
        result.push(item);
      }
    } else {
      const oldItemValue = getValueByName({ configuration: oldArr }, item.name);
      // console.log('oldItemValue 2', oldItemValue);
      if (oldItemValue != null) {
        result.push({ ...item, value: oldItemValue });
      } else {
        result.push(item);
      }
    }
  });
  return result;
};

//
//
export const updateOldConfigValues = function updateOldConfigValues(
  currentConfig: Array<Config>,
  newConfig: Array<Config>
) {
  if (Array.isArray(currentConfig) === false) {
    throw new Error('config_has_to_be_array');
  }

  const updated = JSON.parse(JSON.stringify(currentConfig));
  updated.forEach((old: Config) => {
    const update = newConfig.find((configItem) => configItem.name === old.name);
    // console.log('update', old.name, update);
    if (update == null) {
      return;
    }
    if (old.type !== update.type || old.name !== update.name) {
      throw new Error('config_mismatch');
    }
    if (old.type === 'group') {
      if (
        Array.isArray((old as ConfigurationGroup).children) &&
        Array.isArray((update as ConfigurationGroup).children) &&
        (old as ConfigurationGroup).children.length ===
          (update as ConfigurationGroup).children.length
      ) {
        (old as ConfigurationGroup).children.forEach((oldChild) => {
          const updateChild = (update as ConfigurationGroup).children.find(
            (configItem) => configItem.name === oldChild.name
          );
          // console.log('updateChild', oldChild.name, updateChild);
          if (updateChild == null) {
            return;
          }

          if (oldChild.type !== updateChild.type || oldChild.name !== updateChild.name) {
            throw new Error('config_mismatch');
          }
          // eslint-disable-next-line valid-typeof
          if (typeof updateChild.value !== oldChild.type) {
            throw new Error('value_not_matching_type');
          }
          const { required = true } = getItemByName(
            { configuration: currentConfig },
            `${update.name}.${updateChild.name}`
          ) as ConfigurationItem;
          if (updateChild.value == null || (updateChild.value === '' && required !== false)) {
            throw new Error('value_has_to_be_set');
          }
          oldChild.value = updateChild.value;
        });
      } else {
        throw new Error('children_mismatch');
      }
    } else if (old.type === 'string' || old.type === 'boolean' || old.type === 'number') {
      // eslint-disable-next-line valid-typeof
      if (typeof (update as ConfigurationItem).value !== old.type) {
        throw new Error('value_not_matching_type');
      }
      const { required = true } = getItemByName(
        { configuration: currentConfig },
        update.name
      ) as ConfigurationItem;
      if (
        (update as ConfigurationItem).value == null ||
        ((update as ConfigurationItem).value === '' && required !== false)
      ) {
        throw new Error('value_has_to_be_set');
      }
      (old as ConfigurationItem).value = (update as ConfigurationItem).value;
    } else {
      throw new Error('unknown_config_type');
    }
  });
  return updated;
};
