import uniqBy from 'lodash.uniqby';
import { createSelector } from 'reselect';

import { getAppRelevantTags } from '../common/utils/configuration';
import { getEmptyTag } from '../reducers/TagReducer';
import { sortByName } from '../utils/sort';

import type { RootState } from '../reducers';
import type { App } from '../reducers/AppsReducer';
import type { MbxTag } from '../reducers/TagReducer';

// import { sortCategories } from '../utils/sort';

//
//
const getById = (state: RootState) => state.tags.byId;
const getAllIds = (state: RootState) => state.tags.allIds;

//
//
export const getTagByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => {
    const tag = byId[id];
    if (tag == null) {
      return getEmptyTag(id);
    }
    return tag;
  }
);

// //
// //
// export const getCategoriesByIds = (state: RootState, tagIds: Array<number>): Array<MbxTag> => {
//   if (!Array.isArray(tagIds)) {
//     return [];
//   }
//   return tagIds.map((id: number) => getTagById(state, id)).filter(Boolean);
// };

//
//
export const getAllTagsForAppFunc = createSelector(
  getAllIds,
  getTagByIdFunc,
  //
  (allIds, getTagById) => (appId: number) => {
    const tagIds = allIds[appId];
    if (!Array.isArray(tagIds)) {
      return [];
    }
    const tags = tagIds.map((id: number) => getTagById(id)).filter((t): t is MbxTag => !!t);
    // TODO: tags.sort(sortCategories);
    return tags;
  }
);

//
//
export const getAllTagsForApp = createSelector(
  getAllTagsForAppFunc,
  (_: unknown, id: number) => id,
  //
  ($getAllTagsForApp, id) => $getAllTagsForApp(id)
);

//
//
type MbxTagExtended = MbxTag & { isAppRelevant: boolean };
export const getAvailableTagsList = createSelector(
  getAllTagsForAppFunc,
  (_: unknown, app: App) => app,
  ($getAllTagsForApp, app) => {
    const appTags = $getAllTagsForApp(app.id).sort(sortByName);
    const appExtendedTags = appTags.map((t) => {
      const tag: MbxTagExtended = {
        ...t,
        isAppRelevant: false,
      };
      return tag;
    });

    const appRelevantTags = getAppRelevantTags(app)
      .map((tag, index) => {
        const alreadyAvailableTag = appExtendedTags.find((t) => t.name === tag);
        if (alreadyAvailableTag != null) {
          return { ...alreadyAvailableTag, isAppRelevant: true };
        }
        const result: MbxTagExtended = {
          id: appExtendedTags.length + index - 1,
          name: tag,
          isAppRelevant: true,
        };
        return result;
      })
      .sort(sortByName);
    const mergedTags = uniqBy([...appRelevantTags, ...appExtendedTags], 'name');
    return mergedTags;
  }
);
