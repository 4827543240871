import { normalize } from 'normalizr';

import {
  ASSETRESOURCE_CREATE,
  ASSETRESOURCE_CREATE_OK,
  ASSETRESOURCE_CREATE_FAIL,
  ASSETRESOURCE_UPDATE,
  ASSETRESOURCE_UPDATE_OK,
  ASSETRESOURCE_UPDATE_FAIL,
  ASSETRESOURCE_DELETE,
  ASSETRESOURCE_DELETE_OK,
  ASSETRESOURCE_DELETE_FAIL,
} from '../constants/assetresources';
import { AssetResourceSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';
import type { JsonValue } from 'type-fest';

//
//
export const addAssetResource =
  (assetId: number, resourceId: number) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch<{ id: number; [k: string]: JsonValue }>(
      {
        url: `/assetresources`,
        method: 'POST',
        data: {
          assetId,
          resourceId,
        },
      },
      dispatch,
      { init: ASSETRESOURCE_CREATE, fail: ASSETRESOURCE_CREATE_FAIL },
      201
    );
    const normalizedData = normalize(response.data, AssetResourceSchema);
    dispatch({
      type: ASSETRESOURCE_CREATE_OK,
      payload: normalizedData,
      assetId,
    });
    return response.data;
  };

//
//
export const deleteAssetResource = (assetResourceId: number) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/assetresources/${assetResourceId}`,
      method: 'DELETE',
    },
    dispatch,
    { init: ASSETRESOURCE_DELETE, fail: ASSETRESOURCE_DELETE_FAIL },
    204
  );
  dispatch({
    type: ASSETRESOURCE_DELETE_OK,
    assetResourceId,
  });
  return true;
};

//
//
export const updateAssetResource =
  (assetResourceId: number, resourceId: number | null) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/assetresources/${assetResourceId}`,
        method: 'PUT',
        data: {
          resourceId,
        },
      },
      dispatch,
      { init: ASSETRESOURCE_UPDATE, fail: ASSETRESOURCE_UPDATE_FAIL }
    );
    const normalizedData = normalize(response.data, AssetResourceSchema);
    dispatch({
      type: ASSETRESOURCE_UPDATE_OK,
      payload: normalizedData,
      resourceId,
    });
    return response.data;
  };
