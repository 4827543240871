const INCOMPLETE = 1;
const SCHEDULED = 2;
const PUBLISHED = 3;

export const FeedType = {
  INCOMPLETE,
  SCHEDULED,
  PUBLISHED,
  // ALL: 3, Exlude all for now
} as const;

const INCOMPLETE_NAME = 'incomplete';
const SCHEDULED_NAME = 'scheduled';
const PUBLISHED_NAME = 'published';

export const FeedTypeNames = {
  [INCOMPLETE]: INCOMPLETE_NAME,
  [SCHEDULED]: SCHEDULED_NAME,
  [PUBLISHED]: PUBLISHED_NAME,
} as const;

export type AllowedFeedType = keyof typeof FeedTypeNames;

export const ALL_FEED_NAMES = Object.keys(FeedTypeNames).map(
  (key) => FeedTypeNames[Number(key) as AllowedFeedType]
);

export type AllowedFeedTypeName = (typeof ALL_FEED_NAMES)[number];

export const isAllowedFeedTypeName = function isAllowedFeedTypeName(feedName: string) {
  return ALL_FEED_NAMES.includes(feedName as AllowedFeedTypeName);
};

export const UID_REGEX = /^UID.*$/gm;
export const DTSTAMP_REGEX = /^DTSTAMP.*$/gm;
