import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { List, Dropdown, Button, Menu, message } from 'antd';
import { useState, useEffect } from 'react';

import styles from './AppPlatformList.module.scss';
import ConfigurationForm from './ConfigurationForm';
import {
  createAppReceiptValidator,
  deleteAppReceiptValidator,
  fetchAppReceiptValidatorList,
  updateAppReceiptValidator,
} from '../actions/appreceiptvalidators';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import {
  CREATABLE_RECEIPT_TYPE_VALIDATORS,
  ReceiptTypeToName,
} from '../common/constants/receipt-type';
import { getAppReceiptValidatorForApp } from '../selectors/appReceiptValidatorSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { ReceiptType } from '../common/constants/receipt-type';
import type { Configuration } from '../common/types/app';
import type { AppReceiptValidator } from '../reducers/AppReceiptValidatorReducer';
import type { MenuProps } from 'antd';

type Props = {
  appId: number;
};

//
//
const AppReceiptValidatorList = (props: Props) => {
  const { appId } = props;

  const dispatch = useAppDispatch();
  const arvs = useGlobalSelector((state) => getAppReceiptValidatorForApp(state, appId));

  const [isUpdatingArv, setIsUpdatingArv] = useState(false);

  useEffect(() => {
    const loadPlatforms = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(fetchAppReceiptValidatorList(appId));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    loadPlatforms();
  }, [dispatch]);

  const handleAddReceiptValidator: MenuProps['onClick'] = async (info) => {
    const receiptTypeId = Number(info.key.split('-').pop());
    console.log('handleAddReceiptValidator()', receiptTypeId);
    if (CREATABLE_RECEIPT_TYPE_VALIDATORS.includes(receiptTypeId)) {
      try {
        setIsUpdatingArv(() => true);
        await dispatch(createAppReceiptValidator(appId, receiptTypeId));
        message.success('Receipt validator created');
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        setIsUpdatingArv(() => false);
      }
    }
  };

  const getReceiptTypeName = (receiptTypeId: ReceiptType): string => {
    const name = ReceiptTypeToName[receiptTypeId];
    if (name == null) {
      return '??';
    }
    return name;
  };

  const handleDeleteAppReceiptValidator = async (arv: AppReceiptValidator) => {
    if (
      window.confirm(`Really delete receipt validator '${getReceiptTypeName(arv.receiptTypeId)}'?`)
    ) {
      console.log('DELETE', appId, arv);
      try {
        await dispatch(deleteAppReceiptValidator(appId, arv.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      }
    }
  };

  const onSubmitConfiguration = async (arvId: number, config: Configuration) => {
    console.log('onSubmitConfiguration()');
    try {
      await dispatch(updateAppReceiptValidator(arvId, config));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  return (
    <div style={{ marginTop: '4em' }} data-tid="app-receiptvalidator-container">
      <div className={styles.header}>
        <h2>Receipt validators</h2>
        <div className={styles.headerExtras}>
          <Dropdown
            disabled={
              isUpdatingArv ||
              CREATABLE_RECEIPT_TYPE_VALIDATORS.length === 0 ||
              CREATABLE_RECEIPT_TYPE_VALIDATORS.length === arvs.length
            }
            overlay={
              <Menu onClick={handleAddReceiptValidator} data-tid="app-receiptvalidator-dropdown">
                {CREATABLE_RECEIPT_TYPE_VALIDATORS.map((receiptTypeId) => (
                  <Menu.Item
                    key={`add-receiptvalidator-menuitem-${receiptTypeId}`}
                    disabled={arvs.find((arv) => arv?.receiptTypeId === receiptTypeId) != null}
                  >
                    {getReceiptTypeName(receiptTypeId)}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              loading={isUpdatingArv}
              data-tid="btn-add-receiptvalidator"
            >
              Add a receipt validator
            </Button>
          </Dropdown>
        </div>
      </div>

      <div>
        <List
          data-tid="receiptvalidator-list"
          bordered
          itemLayout="vertical"
          dataSource={arvs}
          renderItem={(arv) => {
            return (
              <List.Item
                key={`receiptvalidator-item-${arv.id}`}
                className={styles.item}
                extra={
                  <Button
                    data-tid="btn-receiptvalidator-delete"
                    size="small"
                    icon={<DeleteOutlined />}
                    danger
                    ghost
                    onClick={() => {
                      handleDeleteAppReceiptValidator(arv);
                    }}
                  >
                    delete
                  </Button>
                }
              >
                <List.Item.Meta title={getReceiptTypeName(arv.receiptTypeId)} />
                <ConfigurationForm
                  data={arv.meta}
                  onUpdateConfig={async (config) => {
                    onSubmitConfiguration(arv.id, config);
                  }}
                  marginTop="1em"
                />
              </List.Item>
            );
          }}
        />
      </div>
    </div>
  );
};

export default AppReceiptValidatorList;
