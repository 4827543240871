import { normalize } from 'normalizr';

import {
  EPISODELOCALIZED_CREATE,
  EPISODELOCALIZED_CREATE_OK,
  EPISODELOCALIZED_CREATE_FAIL,
  EPISODELOCALIZED_UPDATE,
  EPISODELOCALIZED_UPDATE_OK,
  EPISODELOCALIZED_UPDATE_FAIL,
  EPISODELOCALIZED_DELETE,
  EPISODELOCALIZED_DELETE_OK,
  EPISODELOCALIZED_DELETE_FAIL,
  EPISODELOCALIZED_ADD_CONTENT,
  EPISODELOCALIZED_ADD_CONTENT_OK,
  EPISODELOCALIZED_ADD_CONTENT_FAIL,
  EPISODELOCALIZED_REMOVE_CONTENT,
  EPISODELOCALIZED_REMOVE_CONTENT_OK,
  EPISODELOCALIZED_REMOVE_CONTENT_FAIL,
} from '../constants/episodeLocalized';
import { LocalizedEpisodeSchema, EpisodeContentSchema } from '../schemas';
import { getEpisodeContentById } from '../selectors/episodeContentSelectors';
import { getEpisodeLocalizedById } from '../selectors/episodeLocalizedSelectors';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';
import type { GlobalStateGetter } from '../reducers';
import type { JsonValue } from 'type-fest';

const getData = (input: unknown): string | null => {
  if (input == null || input === '') {
    return null;
  }
  if (typeof input === 'string') {
    return input;
  }
  throw new Error(`wrong type for data: ${typeof input}`);
};

//
//
export const createEpisodeLocalized =
  (
    episodeId: number,
    localeId: number,
    name: string,
    data1: string,
    data2: string,
    data3: string,
    data4: string,
    data5: string,
    appId: number
  ) =>
  async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const response = await wrapFetch<{ id: number }>(
      {
        url: `/episodelocalized`,
        method: 'POST',
        data: {
          episodeId,
          localeId,
          name,
          appId,
          data1: getData(data1),
          data2: getData(data2),
          data3: getData(data3),
          data4: getData(data4),
          data5: getData(data5),
        },
      },
      dispatch,
      { init: EPISODELOCALIZED_CREATE, fail: EPISODELOCALIZED_CREATE_FAIL },
      201
    );
    const normalizedData = normalize(response.data, LocalizedEpisodeSchema);
    dispatch({ type: EPISODELOCALIZED_CREATE_OK, episodeId, payload: normalizedData });

    const state = getState();
    const el = getEpisodeLocalizedById(state, response.data.id);
    return el;
  };

//
//
export const deleteEpisodeLocalized =
  (episodeId: number, episodeLocalizedId: number) =>
  async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    await wrapFetch(
      {
        url: `/episodelocalized/${episodeLocalizedId}`,
        method: 'DELETE',
      },
      dispatch,
      { init: EPISODELOCALIZED_DELETE, fail: EPISODELOCALIZED_DELETE_FAIL },
      204
    );
    const episodeLocalized = getEpisodeLocalizedById(getState(), episodeLocalizedId);
    dispatch({
      type: EPISODELOCALIZED_DELETE_OK,
      episodeId,
      episodeLocalized: episodeLocalized,
    });
    return true;
  };

//
//
export const updateEpisodeLocalized =
  (
    episodelocalizedId: number,
    name: string,
    data1: string,
    data2: string,
    data3: string,
    data4: string,
    data5: string,
    appId: number
  ) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/episodelocalized/${episodelocalizedId}`,
        method: 'PUT',
        data: {
          name,
          appId,
          data1: getData(data1),
          data2: getData(data2),
          data3: getData(data3),
          data4: getData(data4),
          data5: getData(data5),
        },
      },
      dispatch,
      { init: EPISODELOCALIZED_UPDATE, fail: EPISODELOCALIZED_UPDATE_FAIL }
    );
    const normalizedData = normalize(response.data, LocalizedEpisodeSchema);
    dispatch({ type: EPISODELOCALIZED_UPDATE_OK, payload: normalizedData });
    return response.data;
  };

//
//
export const removeEpisodeLocalizedContent =
  (episodeLocalizedId: number, contentId: number) =>
  async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    await wrapFetch(
      {
        url: `/episodelocalized/${episodeLocalizedId}/content/${contentId}`,
        method: 'DELETE',
      },
      dispatch,
      { init: EPISODELOCALIZED_REMOVE_CONTENT, fail: EPISODELOCALIZED_REMOVE_CONTENT_FAIL },
      204
    );
    const content = getEpisodeContentById(getState(), contentId);
    dispatch({
      type: EPISODELOCALIZED_REMOVE_CONTENT_OK,
      payload: { episodeLocalizedId, content },
    });
    return true;
  };

//
//
export const addEpisodeLocalizedContent =
  (
    episodeLocalizedId: number,
    contentTypeId: number,
    resourceId: number,
    appPlatformId: number | null,
    appId: number
  ) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch<{ id: number; [k: string]: JsonValue }>(
      {
        url: `/episodelocalized/${episodeLocalizedId}/content`,
        method: 'POST',
        data: {
          appId,
          contentTypeId,
          resourceId,
          appPlatformId,
        },
      },
      dispatch,
      { init: EPISODELOCALIZED_ADD_CONTENT, fail: EPISODELOCALIZED_ADD_CONTENT_FAIL },
      201
    );
    const normalizedData = normalize(response.data.episodeContent, EpisodeContentSchema);
    dispatch({
      type: EPISODELOCALIZED_ADD_CONTENT_OK,
      payload: normalizedData,
      contentTypeId,
      episodeLocalizedId,
      resourceId,
      appPlatformId,
      contentWasUsedForEpisodeThumbnail: response.data.contentWasUsedForEpisodeThumbnail,
    });
    return response.data;
  };
