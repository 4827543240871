import { CloseCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { List, Progress, Collapse, Tooltip } from 'antd';
import classNames from 'classnames';

import styles from './UploadQueueList.module.scss';
import { formatStr } from '../utils/lang';

import type { QueueItem } from '../reducers/QueueReducer';

type Props = {
  queuedItems: Array<QueueItem>;
};

const { Panel } = Collapse;

const progressStatus = (state: string) => {
  switch (state) {
    case 'uploading':
      return 'active';
    case 'error':
      return 'exception';
    case 'done':
      return 'success';
    default:
      return 'normal';
  }
};

const queuedStates = ['added', 'initializing'];
const doneStates = ['done', 'error', 'canceled'];

const getIconForState = (item: QueueItem) => {
  if (item.state === 'canceled') {
    return (
      <div style={{ alignSelf: 'flex-start' }}>
        <Tooltip title={item.state}>
          <CloseCircleOutlined style={{ marginTop: 6, color: '#900' }} />
        </Tooltip>
      </div>
    );
  }

  if (item.state === 'error') {
    return (
      <div style={{ alignSelf: 'flex-start' }}>
        <Tooltip title={item.state}>
          <ExclamationCircleFilled style={{ marginTop: 6, color: '#900' }} />
        </Tooltip>
      </div>
    );
  }

  return null;
};

//
//
const UploadQueueList = (props: Props) => {
  const { queuedItems } = props;

  const currentlyUploadedItem = queuedItems.filter((item) => item.state === 'uploading');
  const currentlyQueuedItems = queuedItems.filter((item) => queuedStates.includes(item.state));

  let numberOfErrors = 0;
  const currentlyDoneItems = queuedItems.filter((item) => {
    if (item.state === 'error') {
      numberOfErrors += 1;
    }
    return doneStates.includes(item.state);
  });

  return (
    <div style={{ minWidth: 300, maxHeight: '85vh', overflowY: 'scroll' }}>
      <List
        data-tid="upload-window-uploading-list"
        locale={{ emptyText: 'No uploads currently' }}
        dataSource={currentlyUploadedItem}
        className={styles.uploadListUploadingItems}
        renderItem={(item: QueueItem) => (
          <List.Item key={item.id}>
            <List.Item.Meta
              title={item.fileRef.name}
              description={
                <Progress percent={item.percent} size="small" status={progressStatus(item.state)} />
              }
            />
          </List.Item>
        )}
      />
      <Collapse
        ghost
        className={classNames([styles.uploadListQueuedCollapse, 'TEST_upload-collapse'])}
        // data-* attributes are not working here - use class hack instead
        // data-tid="upload-collapse"
      >
        <Panel
          header={`${formatStr(
            currentlyQueuedItems.length,
            { '1': 'item', default: 'items' },
            true
          )} waiting for upload`}
          key="1"
          collapsible={currentlyQueuedItems.length > 0 ? 'header' : 'disabled'}
          className={classNames([styles.uploadListQueuedPanel, 'TEST_queued-panel'])}
          // data-* attributes are not working here - use class hack instead
          // data-tid="queued-panel"
        >
          <List
            data-tid="upload-window-queued-list"
            dataSource={currentlyQueuedItems}
            className={styles.uploadListQueuedItems}
            renderItem={(item: QueueItem) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={item.fileRef.name}
                  // description={<span>{labelsForStatus(item.state)}</span>}
                />
              </List.Item>
            )}
          />
        </Panel>
        <Panel
          header={
            <>
              <span>{`${formatStr(
                currentlyDoneItems.length,
                { '1': 'item', default: 'items' },
                true
              )} ready`}</span>
              {numberOfErrors > 0 && (
                <span style={{ paddingLeft: 8, color: '#900', textAlign: 'right' }}>
                  <ExclamationCircleFilled style={{ paddingRight: 4 }} />
                  {formatStr(numberOfErrors, { '1': 'error', default: 'errors' }, true)}
                </span>
              )}
            </>
          }
          key="2"
          collapsible={currentlyDoneItems.length > 0 ? 'header' : 'disabled'}
          // showArrow={currentlyDoneItems.length > 0}
          className="TEST_done-panel"
          // data-* attributes are not working here - use class hack instead
          // data-tid="done-panel"
        >
          <List
            data-tid="upload-window-done-list"
            dataSource={currentlyDoneItems}
            className={styles.uploadListDoneItems}
            renderItem={(item: QueueItem) => (
              <List.Item key={item.id} extra={getIconForState(item)}>
                <List.Item.Meta title={item.fileRef.name} />
              </List.Item>
            )}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

//
//
export default UploadQueueList;
