import { message, Alert } from 'antd';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import { getActiveUpload } from '../actions/batchUpload';
import { getEpisodeList, getEpisodesMetadata } from '../actions/episodes';
import { getRegionList as fetchRegionList } from '../actions/regions';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { getAppRelevantTags } from '../common/utils/configuration';
import { useCurrentAppContext } from '../contexts';
import {
  ROUTE_NAME_APP_BATCHUPLOADCREATE,
  ROUTE_NAME_APP_EPISODECREATE,
  ROUTE_NAME_APP_EPISODEDETAIL,
  ROUTE_NAME_APP_EPISODES,
} from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import EpisodeList from '../pages/EpisodeList';
import { getBatchUploadByAppId } from '../selectors/batchUploadSelectors';
import { getFilteredEpisodesInfo, getAllEpisodesForApp } from '../selectors/episodeSelectors';
import { getUniqueLocalesForApp } from '../selectors/regionLocales/advanced';
import { getAvailableTagsList } from '../selectors/tagSelectors';
import {
  getQFromSearch,
  getPageFromSearch,
  getTagsFromSearch,
  getTypesFromSearch,
  getEpisodeSortInfoFromSearch,
} from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { SearchItem } from '../components/ListSearchInput';
import type { Episode } from '../reducers/EpisodeReducer';

const DEFAULT_PER_PAGE = 25;

//
//
const EpisodeListContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentApp } = useCurrentAppContext();

  const appRelevantTags = getAppRelevantTags(currentApp);
  const availableTags = useGlobalSelector((state) => getAvailableTagsList(state, currentApp));

  // const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PER_PAGE);
  const [error, setError] = useState('');

  const currentSearch = getQFromSearch(history.location.search);
  const currentPage = getPageFromSearch(history.location.search);
  const currentTagIds = getTagsFromSearch(history.location.search);
  const currentTypeIds = getTypesFromSearch(history.location.search);
  const currentSortInfo = getEpisodeSortInfoFromSearch(history.location.search);
  const uploadInProgress = useGlobalSelector((state) =>
    getBatchUploadByAppId(state, currentApp.id)
  );

  const allEpisodes = useGlobalSelector((state) => getAllEpisodesForApp(state, currentApp.id));
  const filteredEpisodesInfo = useGlobalSelector((state) =>
    getFilteredEpisodesInfo(state, {
      appId: currentApp.id,
      currentSearch,
      page: currentPage,
      itemsPerPage: DEFAULT_PER_PAGE,
      currentTagIds,
      currentTypeIds,
      currentSortInfo,
    })
  );
  const appLocales = useGlobalSelector((state) => getUniqueLocalesForApp(state, currentApp.id));

  const { maxPage, itemsPerPage, currentIdsStr, episodes, max, sortedBy } = filteredEpisodesInfo;

  useEffect(() => {
    const fetchData = async () => {
      // console.log('EpisodeList.fetchData()');
      try {
        dispatch(counterIncrement());
        await dispatch(getEpisodeList(currentApp.id));
        await dispatch(fetchRegionList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentApp.id]);

  // duplicate this b/c we dont want to display error if getActiveUpload fails
  // TODO: fix this once the batchuploader is live
  useEffect(() => {
    const fetchData = async () => {
      // console.log('EpisodeList.fetchData()');
      try {
        dispatch(counterIncrement());
        await dispatch(getActiveUpload(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        console.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentApp.id]);

  useEffect(() => {
    const fetchMetadata = async () => {
      if (currentApp.id === null || currentApp.id === -1 || currentIdsStr === '') {
        return;
      }

      // console.log('fetchMetadata()', currentIdsStr);
      try {
        const ids = currentIdsStr.split(',');
        if (ids.length > 0) {
          const idsAsNumbers = ids.map((id) => Number(id)).filter((id): id is number => !!id);
          // console.log('ids w/o metadata', ids);
          dispatch(counterIncrement());
          await dispatch(getEpisodesMetadata(currentApp.id, idsAsNumbers));
        }
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
        // TODO: check if we want to display this error
      } finally {
        dispatch(counterDecrement());
      }
    };
    if (currentIdsStr !== '') {
      fetchMetadata();
    }
  }, [dispatch, currentApp.id, currentIdsStr]);

  const updateSearchParams = (opts: {
    page?: number;
    q?: string;
    sort?: string;
    tags?: string;
    types?: string;
  }) => {
    const options = {
      // set initial defaults
      page: currentPage,
      q: currentSearch,
      tags: currentTagIds.join('x'),
      types: currentTypeIds.join('x'),
      sort: `${currentSortInfo.sortBy}:${currentSortInfo.order}`,
      // overwrite defaults with new options
      ...opts,
    };
    history.push(
      `${getUrlByName(ROUTE_NAME_APP_EPISODES, {
        ':appUid': currentApp.uid,
      })}?${queryString.stringify(options)}`
    );
  };

  const handleBatchUpload = () => {
    history.push(
      getUrlByName(ROUTE_NAME_APP_BATCHUPLOADCREATE, {
        ':appUid': currentApp.uid,
      })
    );
  };

  const handleEpisodeAdd = () => {
    history.push(
      getUrlByName(ROUTE_NAME_APP_EPISODECREATE, {
        ':appUid': currentApp.uid,
      })
    );
  };

  const handleEpisodeEdit = (episode: Episode) => {
    history.push(
      getUrlByName(ROUTE_NAME_APP_EPISODEDETAIL, {
        ':appUid': currentApp.uid,
        ':episodeId': episode.id,
      })
    );
  };

  const handlePaginationChange = (page: number) => {
    updateSearchParams({ page });
  };

  const handleSelect = (value: string | number) => {
    console.log('handleSelect()', value);
    const result = /^(episode|tag)-([0-9]+)-.+$/.exec(value.toString());
    if (result !== null) {
      const [, type, idStr] = result;
      const id = parseInt(idStr, 10);

      console.log('handleSelect()', type, id);
      if (type === 'episode') {
        history.push(
          getUrlByName(ROUTE_NAME_APP_EPISODEDETAIL, {
            ':appUid': currentApp.uid,
            ':episodeId': id,
          })
        );
      } else if (type === 'tag') {
        updateSearchParams({ tags: [...currentTagIds, id].join('x'), page: 1 });
      } else {
        throw new Error(`unknown type in episode search: ${type}`);
      }
    }
  };

  const handleSelectTag = (tagId: number) => {
    updateSearchParams({ tags: tagId.toString(), page: 1 });
  };

  const handleFilter = (q: string) => {
    console.log('handleFilter()', q);
    updateSearchParams({ q, page: 1 });
  };

  const handleSort = (sortBy: string) => {
    console.log('handleSort()', sortBy);
    updateSearchParams({ sort: sortBy, page: 1 });
  };

  const handleTagsChanged = (ids: Array<string>) => {
    console.log('aaa handleTagsChanged()', ids);
    updateSearchParams({ tags: ids.join('x'), page: 1 });
  };

  const handleTypesChanged = (ids: Array<number>) => {
    console.log('aaa handleTypesChanged()', ids);
    updateSearchParams({ types: ids.join('x'), page: 1 });
  };

  const handleSearchClose = (item: SearchItem) => {
    console.log('handleSearchClose()', item);
    if (item.type === 'search') {
      updateSearchParams({ q: '', page: 1 });
    }
  };

  const remappedTagData = useMemo<Array<SearchItem>>(() => {
    return availableTags.map((tag) => ({
      text: tag.name,
      value: tag.id.toString(),
      key: `tag-${tag.id}-iuasdiusd`,
      type: tag.isAppRelevant ? 'app-relevant-tag' : 'tag',
    }));
  }, [availableTags]);

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  if (maxPage > 0 && currentPage > maxPage) {
    return (
      <Redirect
        to={`${getUrlByName(ROUTE_NAME_APP_EPISODES, {
          ':appUid': currentApp.uid,
        })}?page=${maxPage}`}
      />
    );
  }
  if (currentPage <= 0) {
    return (
      <Redirect
        to={getUrlByName(ROUTE_NAME_APP_EPISODES, {
          ':appUid': currentApp.uid,
        })}
      />
    );
  }

  const episodeData: Array<SearchItem> = [];

  allEpisodes.forEach((episode) => {
    episodeData.push({
      key: `episode-${episode.id}-hasduhaisudhui`,
      text: episode.name,
      value: `episode-${episode.id}-kyxjclyxckj`,
      type: 'episode',
    });
  });

  const currentFilterTags: Array<string> = [];
  currentTagIds.forEach((tagId: number) => {
    const tag = remappedTagData.find((obj) => obj.value === tagId.toString());
    if (tag != null) {
      currentFilterTags.push(tag.value);
    }
  });

  const searchItems: Array<SearchItem> = [];

  if (currentSearch !== '') {
    searchItems.push({ type: 'search', value: currentSearch, text: currentSearch });
  }

  return (
    <div>
      <EpisodeList
        currentPageItems={episodes}
        searchData={episodeData}
        tagData={remappedTagData}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        currentSearchItems={searchItems}
        currentFilterTags={currentFilterTags}
        currentFilterTypes={currentTypeIds}
        totalItems={max}
        appLocales={appLocales}
        sortedBy={sortedBy}
        appRelevantTags={appRelevantTags}
        uploadInProgress={uploadInProgress}
        //
        handleBatchUpload={handleBatchUpload}
        handleEpisodeAdd={handleEpisodeAdd}
        handleEpisodeEdit={handleEpisodeEdit}
        handlePaginationChange={handlePaginationChange}
        handleSelect={handleSelect}
        handleFilter={handleFilter}
        handleSelectTag={handleSelectTag}
        handleSearchClose={handleSearchClose}
        handleSort={handleSort}
        handleTagsChanged={handleTagsChanged}
        handleTypesChanged={handleTypesChanged}
      />
    </div>
  );
};

export default EpisodeListContainer;
