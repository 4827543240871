const AUDIO_TEMPLATE_MPEG4_192_AAC_1 = 'mpeg-4_192_aac-1';

export const AUDIO_VERSION_ORDER = [AUDIO_TEMPLATE_MPEG4_192_AAC_1] as const;
export type AllowedAudioTemplateName = (typeof AUDIO_VERSION_ORDER)[number];

export const AUDIO_VERSION_LABELS = {
  [AUDIO_TEMPLATE_MPEG4_192_AAC_1]: 'MPEG-4 (192kbit/s)',
} as const;

export const isAudioTemplateName = function isAudioTemplateName(label: string) {
  return Object.keys(AUDIO_VERSION_LABELS).includes(label);
};
