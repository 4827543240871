import { combineReducers } from 'redux';

import { LOGIN_OK } from '../constants/auth';
import {
  ROLE_GET_LIST_OK,
  ROLE_GET_DETAILS_OK,
  ROLE_CREATE_OK,
  ROLE_UPDATE_OK,
  ROLE_REMOVE_OK,
} from '../constants/roles';
import { USER_INVITE_ACCEPT_OK, USER_GET_DETAIL_OK } from '../constants/users';

import type { AnyAction } from 'redux';

export type Role = {
  id: number;
  appId: number | null;
  name: string;
  roleTypeId: number;
  createdAt: Date;
  updatedAt: Date | null;
  rights: Array<number>;
};

export type RoleStateById = { [k: string | number]: Role };
export type RoleStateAllIds = { [k: string | number]: Array<number> };

export type RoleState = {
  byId: RoleStateById;
  allIds: RoleStateAllIds;
};

//
//
export const getEmptyRole = (id?: number): Role => ({
  id: id ?? -1,
  appId: -1,
  name: '',
  roleTypeId: 0,
  createdAt: new Date(),
  updatedAt: null,
  rights: [],
});

const initialStateById: RoleStateById = {};
const initialStateAllIds: RoleStateAllIds = {};

//
//
const byId = (state = initialStateById, action: AnyAction): RoleStateById => {
  switch (action.type) {
    case ROLE_GET_LIST_OK: {
      const newState = { ...state };
      const { roles } = action;
      roles.forEach((role: Role) => {
        newState[role.id] = role;
      });
      return newState;
    }
    case LOGIN_OK:
    case USER_GET_DETAIL_OK: {
      const roles = action?.payload?.entities?.roles ?? {};
      return {
        ...state,
        ...roles,
      };
    }
    case USER_INVITE_ACCEPT_OK: {
      const roles = action?.updatedUser?.entities?.roles ?? {};
      return {
        ...state,
        ...roles,
      };
    }
    case ROLE_GET_DETAILS_OK:
    case ROLE_UPDATE_OK:
    case ROLE_CREATE_OK: {
      const { role } = action;
      return {
        ...state,
        [role.id]: role,
      };
    }
    case ROLE_REMOVE_OK: {
      const { [action.roleId]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }
    default:
      return state;
  }
};

//
//
const returnState = (state: RoleStateAllIds, appId: number, roles: Array<number>) => {
  const newState = { ...state };
  // const appIdFixed = appId === null ? 'null' : appId;
  newState[appId] = roles;
  return newState;
};

//
//
const getAppState = (state: RoleStateAllIds, appId: number) => {
  // const appIdFixed = appId === null ? 'null' : appId;
  return state[appId];
};

//
//
const allIds = (state = initialStateAllIds, action: AnyAction): RoleStateAllIds => {
  switch (action.type) {
    case ROLE_GET_LIST_OK: {
      // really undefined -> action.roles includes all roles, not for one app only
      if (action.appId === undefined) {
        const newState: RoleStateAllIds = {};
        action.roles.forEach((role: Role) => {
          const appIdFixed = role.appId === null ? 'null' : role.appId;
          if (!Array.isArray(newState[appIdFixed])) {
            newState[appIdFixed] = [];
          }
          newState[appIdFixed].push(role.id);
        });
        return newState;
      }
      return returnState(
        state,
        action.appId,
        action.roles.map((role: Role) => role.id)
      );
    }

    case LOGIN_OK: {
      const newState = { ...state };
      const roles = action?.payload?.entities?.roles ?? {};
      Object.keys(roles).forEach((roleIdStr) => {
        const role = roles[roleIdStr];
        if (Array.isArray(newState[role.appId])) {
          if (!newState[role.appId].includes(role.id)) {
            newState[role.appId] = [...newState[role.appId], role.id];
          }
        } else {
          newState[role.appId] = [role.id];
        }
      });
      return newState;
    }

    case USER_INVITE_ACCEPT_OK: {
      const newState = { ...state };
      const roles = action?.updatedUser?.entities?.roles ?? {};
      Object.keys(roles).forEach((roleIdStr) => {
        const role = roles[roleIdStr];
        if (Array.isArray(newState[role.appId])) {
          if (!newState[role.appId].includes(role.id)) {
            newState[role.appId] = [...newState[role.appId], role.id];
          }
        } else {
          newState[role.appId] = [role.id];
        }
      });
      return newState;
    }

    case ROLE_GET_DETAILS_OK:
    case ROLE_CREATE_OK: {
      const { role } = action;
      const { appId } = role;
      const appRoles = getAppState(state, appId);
      if (Array.isArray(appRoles)) {
        const found = appRoles.find((id) => id === role.id);
        if (found) {
          return state;
        }
        return returnState(state, appId, [...appRoles, role.id]);
      }
      return returnState(state, appId, [role.id]);
    }

    case ROLE_REMOVE_OK: {
      const appState = getAppState(state, action.appId);
      return returnState(
        state,
        action.appId,
        appState.filter((roleId) => roleId !== action.roleId)
      );
    }

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
});

export default combined;
