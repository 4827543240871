import type { Category } from '../reducers/CategoryReducer';
import type { RegionCountry } from '../reducers/RegionCountryReducer';
import type { RegionLocale } from '../reducers/RegionLocaleReducer';
import type { Release } from '../reducers/ReleaseReducer';

//
//
export const sortByName = (a: { name: string }, b: { name: string }): number => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

//
//
export const sortByViews = (a: { views: number }, b: { views: number }): number => {
  return a.views - b.views;
};

//
//
export const sortByText = (a: { text: string }, b: { text: string }): number => {
  if (a.text.toLowerCase() < b.text.toLowerCase()) {
    return -1;
  }
  if (a.text.toLowerCase() > b.text.toLowerCase()) {
    return 1;
  }
  return 0;
};

//
//
export const sortByFirstname = (a: { firstname: string }, b: { firstname: string }): number => {
  if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) {
    return -1;
  }
  if (a.firstname.toLowerCase() > b.firstname.toLowerCase()) {
    return 1;
  }
  return 0;
};

//
//
export const sortById = (a: { id: number }, b: { id: number }): number => a.id - b.id;

//
//
export const sortByIdDesc = (a: { id: number }, b: { id: number }): number => b.id - a.id;

//
//
export const sortByCreatedAt = (a: { createdAt: Date }, b: { createdAt: Date }): number =>
  (a.createdAt?.getTime() ?? 0) - (b.createdAt?.getTime() ?? 0);

//
//
export const sortByPublishDate = (a: { publishDate: Date }, b: { publishDate: Date }): number =>
  a.publishDate.getTime() - b.publishDate.getTime();

export const sortByPublishDateAndId = (
  a: { publishDate: Date; id: number },
  b: { publishDate: Date; id: number }
) => {
  if (a.publishDate.getTime() === b.publishDate.getTime()) {
    return a.id - b.id;
  }
  return a.publishDate.getTime() - b.publishDate.getTime();
};

//
//
export const sortReleaseByEpisodeName = (a: Release, b: Release): number => {
  if (a.episode.name.toLowerCase() < b.episode.name.toLowerCase()) {
    return -1;
  }
  if (a.episode.name.toLowerCase() > b.episode.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

//
//
export const sortRegionCountries = (a: RegionCountry, b: RegionCountry): number => {
  const aa = (a?.country?.shortcode ?? '').toLowerCase();
  const bb = (b?.country?.shortcode ?? '').toLowerCase();

  if (aa < bb) {
    return -1;
  }
  if (aa > bb) {
    return 1;
  }
  return 0;
};

//
//
export const sortRegionLocales =
  (defaultIds?: Array<number>): ((a: RegionLocale, b: RegionLocale) => number) =>
  (a, b) => {
    const aa = (a?.locale?.shortcode ?? '').toLowerCase();
    const bb = (b?.locale?.shortcode ?? '').toLowerCase();

    if (Array.isArray(defaultIds) && defaultIds.length > 0) {
      const idA = a?.id ?? -1;
      const idB = b?.id ?? -1;

      const isADefault = defaultIds.includes(idA);
      const isBDefault = defaultIds.includes(idB);

      if ((isADefault || isBDefault) && !(isADefault && isBDefault)) {
        return isADefault ? -1 : 1;
      }
    }

    if (aa < bb) {
      return -1;
    }
    if (aa > bb) {
      return 1;
    }
    return 0;
  };

//
//
export const sortCategories = (a: Category, b: Category): number => {
  const aa = a.name.toLowerCase();
  const bb = b.name.toLowerCase();

  const catA = a.isDraft;
  const catB = b.isDraft;

  if ((catA || catB) && !(catA && catB)) {
    return catA ? 1 : -1;
  }

  if (aa < bb) {
    return -1;
  }
  if (aa > bb) {
    return 1;
  }
  return 0;
};

//
//
export const sortNewestFirst = (a: { createdAt: Date }, b: { createdAt: Date }): number => {
  const aa = a?.createdAt ?? new Date(0);
  const bb = b?.createdAt ?? new Date(0);

  return bb.getTime() - aa.getTime();
};
