import { createSelector } from 'reselect';

import { getAppsNormalizedByIdsFunc } from './apps/';
import { getRolesByIdsFunc } from './roleSelectors';
import { getEmptyUser } from '../reducers/UsersReducer';
import { sortByFirstname } from '../utils/sort';

import type { RootState } from '../reducers';
import type { User } from '../reducers/UsersReducer';
import type { Selector } from 'reselect';

//
//
const getById = (state: RootState) => state.users.byId;
const getAllIds = (state: RootState) => state.users.allIds;
// const getUserApps = (state: RootState) => state.users.userApps;
const getUserRoles = (state: RootState) => state.users.userRoles;
const getAppUsers = (state: RootState) => state.appUser.byId;

//
//
export const getUserAppsByIdFunc = createSelector(
  getAppUsers,
  //
  (appUsers) => (userId: number) => {
    const appIds: Array<number> = [];
    Object.keys(appUsers).map((appUserIdStr) => {
      if (appUsers[appUserIdStr].user === userId) {
        appIds.push(appUsers[appUserIdStr].app);
      }
    });
    return appIds;
  }
);

//
//
export const getUserAppsById = createSelector(
  getUserAppsByIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getUserAppsById, id) => $getUserAppsById(id)
);

//
//
export const getUserRolesByIdFunc = createSelector(
  getUserRoles,
  //
  (userRoles) => (id: number) => userRoles[id] ?? []
);

//
//
export const getUserNormalizedByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => {
    const user = byId[id];
    if (user == null) {
      return getEmptyUser(id);
    }
    return user;
  }
);

//
//
export const getUserByIdFunc: Selector<RootState, (id: number) => User> = createSelector(
  getUserNormalizedByIdFunc,
  getAppsNormalizedByIdsFunc,
  getUserAppsByIdFunc,
  getRolesByIdsFunc,
  getUserRolesByIdFunc,
  //
  (
      $getUserNormalizedById,
      $getAppsNormalizedByIds,
      $getUserAppsById,
      $getRolesByIds,
      $getUserRolesById
    ) =>
    (id) => {
      const user = $getUserNormalizedById(id);
      return {
        ...user,
        apps: $getAppsNormalizedByIds($getUserAppsById(id)),
        roles: $getRolesByIds($getUserRolesById(id)),
      };
    }
);

//
//
export const getUserById = createSelector(
  getUserByIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getUserById, id) => $getUserById(id)
);

//
//
export const getUsersByIdsFunc = createSelector(
  getUserByIdFunc,
  //
  ($getUserById) => (userIds: Array<number>) => {
    if (!Array.isArray(userIds)) {
      return [];
    }
    return userIds.map((userId) => $getUserById(userId));
  }
);
//
//
export const getUsersByIds = createSelector(
  getUsersByIdsFunc,
  (_: unknown, userIds: Array<number>) => userIds,
  //
  ($getUsersByIds, userIds) => $getUsersByIds(userIds)
);

//
//
export const getAllUsers = createSelector(
  getAllIds,
  getUsersByIdsFunc,
  //
  (allIds, $getUsersByIds) => {
    const users = $getUsersByIds(allIds);
    users.sort(sortByFirstname);
    return users;
  }
);

//
//
export const getUserByEmail = createSelector(
  getById,
  getUserByIdFunc,
  (_: unknown, email: string | null | undefined) => email,
  //
  (byId, $getUserById, email) => {
    if (typeof email !== 'string') {
      return null;
    }

    let result: User | null = null;
    Object.keys(byId).some((userId) => {
      const user = $getUserById(Number(userId));
      if (user.email === email) {
        result = user;
        return true;
      }
      return false;
    });
    console.log(result);
    return result != null ? result : null;
  }
);
