// TODO: check how we can get rid of this or replace it with a guard
export type ContentTypesWithThumbnailPreview = 10001 | 10003 | 10004;

const EPISODE_THUMBNAIL = 10001;
const EPISODE_CONTENT = 10002;
const EPISODE_LAUNCHIMAGE = 10003;
const EPISODE_ARTWORKIMAGE = 10004;
const EPISODE_SUBTITLES = 10005;
const ASSET_CONTENT = 20001;

export const ContentType = {
  EPISODE_THUMBNAIL,
  EPISODE_CONTENT,
  EPISODE_LAUNCHIMAGE,
  EPISODE_ARTWORKIMAGE,
  EPISODE_SUBTITLES,
  ASSET_CONTENT,
} as const;

export const ContentTypeNames = {
  [EPISODE_THUMBNAIL]: 'episode/thumbnail',
  [EPISODE_CONTENT]: 'episode/content',
  [EPISODE_LAUNCHIMAGE]: 'episode/launchimage',
  [EPISODE_ARTWORKIMAGE]: 'episode/artworkimage',
  [EPISODE_SUBTITLES]: 'episode/subtitles',
  [ASSET_CONTENT]: 'asset/content',
} as const;

export const VALID_CONTENT_TYPES = Object.keys(ContentType).map(
  (key) => ContentType[key as keyof typeof ContentType]
);

export type AllowedContentType = (typeof VALID_CONTENT_TYPES)[number];

export const VALID_CONTENT_TYPES_NAMES = Object.keys(ContentTypeNames).map(
  (key) => ContentTypeNames[Number(key) as AllowedContentType]
);

export type AllowedContentTypeName = (typeof VALID_CONTENT_TYPES_NAMES)[number];
