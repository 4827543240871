export const ASSETRESOURCEVERSIONREQUIREMENTS_CREATE = 'ASSETRESOURCEVERSIONREQUIREMENTS_CREATE';
export const ASSETRESOURCEVERSIONREQUIREMENTS_CREATE_OK =
  'ASSETRESOURCEVERSIONREQUIREMENTS_CREATE_OK';
export const ASSETRESOURCEVERSIONREQUIREMENTS_CREATE_FAIL =
  'ASSETRESOURCEVERSIONREQUIREMENTS_CREATE_FAIL';

export const ASSETRESOURCEVERSIONREQUIREMENTS_DELETE = 'ASSETRESOURCEVERSIONREQUIREMENTS_DELETE';
export const ASSETRESOURCEVERSIONREQUIREMENTS_DELETE_OK =
  'ASSETRESOURCEVERSIONREQUIREMENTS_DELETE_OK';
export const ASSETRESOURCEVERSIONREQUIREMENTS_DELETE_FAIL =
  'ASSETRESOURCEVERSIONREQUIREMENTS_DELETE_FAIL';

export const ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE = 'ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE';
export const ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE_OK =
  'ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE_OK';
export const ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE_FAIL =
  'ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE_FAIL';
