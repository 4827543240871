import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Tag, Pagination, Select } from 'antd';
import classNames from 'classnames';
import * as React from 'react';

import styles from './EpisodeList.module.scss';
import { getValueFromKey } from '../common/utils/app';
import MbxButton from '../components/base/MbxButton';
import { isntMyUpload } from '../components/batchUpload/utils';
import CustomPageHeader from '../components/CustomPageHeader';
import EpisodeErrorMarker from '../components/EpisodeErrorMarker';
import EpisodeThumbnail from '../components/EpisodeLocalizedThumbnail';
import FilterDisplay from '../components/FilterDisplay';
import ListItemType from '../components/ListItemType';
import ListSearchInput from '../components/ListSearchInput';
import SelectPlayerType from '../components/SelectPlayerType';
import { useCurrentAppContext } from '../contexts';
import { getAppPlatformsForApp } from '../selectors/appPlatformSelectors';
import { getAuth, isUserSuperadmin } from '../selectors/authSelectors';
import { getEpisodeContentStatus } from '../utils/episode';
import { useGlobalSelector } from '../utils/hooks';

import type { SearchItem } from '../components/ListSearchInput';
import type { BatchUpload } from '../reducers/BatchUploadReducer';
import type { Episode } from '../reducers/EpisodeReducer';
import type { EpisodeTag } from '../reducers/EpisodeTagReducer';
import type { RegionLocale } from '../reducers/RegionLocaleReducer';

type Props = Readonly<{
  currentPageItems: Array<Episode>;
  searchData: Array<SearchItem>;
  tagData: Array<SearchItem>;
  currentFilterTags: Array<string>;
  currentFilterTypes: Array<number>;
  currentPage: number;
  currentSearchItems: Array<SearchItem>;
  sortedBy: string;
  itemsPerPage: number;
  totalItems: number;
  appLocales: Array<RegionLocale>;
  appRelevantTags: Array<string>;
  uploadInProgress: BatchUpload | null;

  handleBatchUpload: () => void;
  handleEpisodeAdd: () => void;
  handleEpisodeEdit: (episode: Episode) => void;
  handlePaginationChange: (page: number) => void;
  handleSelect: (value: string | number) => void;
  handleFilter: (q: string) => void;
  handleSelectTag: (tagId: number) => void;
  handleSort: (sortBy: string) => void;
  handleTagsChanged: (ids: Array<string>) => void;
  handleTypesChanged: (ids: Array<number>) => void;
  handleSearchClose: (item: SearchItem) => void;
}>;

//
//
const EpisodeList = (props: Props) => {
  const {
    currentPageItems,
    currentSearchItems,
    currentPage,
    itemsPerPage,
    totalItems,
    searchData,
    tagData,
    currentFilterTags,
    currentFilterTypes,
    appLocales,
    sortedBy,
    appRelevantTags,
    uploadInProgress,
    //
    handleBatchUpload,
    handleEpisodeAdd,
    handleEpisodeEdit,
    handlePaginationChange,
    handleSelect,
    handleFilter,
    handleSelectTag,
    handleSearchClose,
    handleSort,
    handleTagsChanged,
    handleTypesChanged,
  } = props;

  const { currentApp } = useCurrentAppContext();
  const { userId } = useGlobalSelector(getAuth);
  const appPlatforms = useGlobalSelector((state) => getAppPlatformsForApp(state, currentApp.id));

  const appConfig = currentApp.configuration ?? [];
  const displayBatchUploadButton = getValueFromKey<boolean>(
    appConfig,
    `adminData.displayBatchUploader`,
    false
  );

  const buttons = [
    <MbxButton
      data-tid="btn-add-episode"
      mbxType="primary"
      onClick={handleEpisodeAdd}
      icon={<PlusOutlined />}
      key="btn-add-episode"
    >
      add episode
    </MbxButton>,
  ];

  const isSuperAdmin = useGlobalSelector(isUserSuperadmin);
  const otherUserActive = isntMyUpload(uploadInProgress, userId);

  const batchUploadButton = (
    <MbxButton
      data-tid="btn-batch-upload"
      mbxType="tertiary"
      onClick={handleBatchUpload}
      key="btn-batch-upload"
      disabled={otherUserActive && isSuperAdmin === false}
    >
      Create Batch Upload
    </MbxButton>
  );

  if (isSuperAdmin === true || displayBatchUploadButton === true) {
    buttons.unshift(batchUploadButton);
  }

  return (
    <div>
      <CustomPageHeader title="List of all episodes" extra={buttons} />

      <div
        style={{
          marginTop: '2em',
          marginBottom: '2em',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div>
          <ListSearchInput
            dataSource={searchData}
            filterOption={(inputValue, option) => {
              const label = (option?.label as string) ?? '';
              return label.toLowerCase().indexOf(inputValue.toString().toLowerCase()) !== -1;
            }}
            handleSelect={handleSelect}
            handleButtonClick={handleFilter}
          />
          <FilterDisplay type="search" items={currentSearchItems} onClose={handleSearchClose} />
        </div>
      </div>

      <div className={styles.filterBar}>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '1em' }}>
          <Select
            placeholder="Sort by"
            value={sortedBy}
            data-tid="sorting-epissodes-dropdown"
            id="sorting-episodes-dropdown"
            onChange={handleSort}
            style={{ minWidth: 120, marginRight: 10, marginBottom: 10 }}
            className="mbx-round-select"
          >
            <Select.Option value="name:asc">{`A -> Z`}</Select.Option>
            <Select.Option value="name:desc">{`Z -> A`}</Select.Option>
            <Select.Option value="date:asc">Oldest first</Select.Option>
            <Select.Option value="date:desc">Newest first</Select.Option>
            <Select.Option value="views:desc">Most views</Select.Option>
          </Select>
          <Select
            mode="multiple"
            allowClear
            defaultActiveFirstOption={false}
            placeholder="Filter by tag"
            value={currentFilterTags}
            onChange={handleTagsChanged}
            style={{ minWidth: 120, marginRight: 10, marginBottom: 10 }}
            optionFilterProp="label"
            data-tid="filter-by-tag-dropdown"
            id="filter-by-tag-dropdown"
            dropdownMatchSelectWidth={false}
            className={classNames([styles.selectWithClear, 'mbx-round-select'])}
            // eslint-disable-next-line react/no-unstable-nested-components
            tagRender={(options) => {
              const { label, closable, onClose } = options;
              const text = (label as React.ReactElement)?.props?.children ?? '??';
              const color = appRelevantTags.includes(text) ? 'geekblue' : undefined;
              return (
                <Tag
                  color={color}
                  closable={closable}
                  onClose={onClose}
                  style={{ marginRight: 3, lineHeight: 1.4 }}
                >
                  {text}
                </Tag>
              );
            }}
          >
            {tagData.map((item) => (
              <Select.Option value={item.value} key={item.key} label={item.text}>
                <span
                  style={{
                    color: item.type === 'app-relevant-tag' ? '#2f54eb' : 'black',
                  }}
                >
                  {item.text}
                </span>
              </Select.Option>
            ))}
          </Select>
          <SelectPlayerType value={currentFilterTypes} onChange={handleTypesChanged} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', minWidth: '170px' }}>
          <Pagination
            simple
            hideOnSinglePage
            current={currentPage}
            total={totalItems}
            pageSize={itemsPerPage}
            onChange={handlePaginationChange}
            size="default"
          />
        </div>
      </div>

      <List
        data-tid="episode-list"
        bordered={false}
        dataSource={currentPageItems}
        renderItem={(episode: Episode) => {
          const codes = getEpisodeContentStatus(episode, appPlatforms.length);

          return (
            <List.Item
              key={`episode-list-item-${episode.id}`}
              onClick={() => {
                handleEpisodeEdit(episode);
              }}
              className={styles.listItem}
              actions={[
                <MbxButton
                  mbxType="secondary"
                  size="middle"
                  key="btn-edit-episode"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEpisodeEdit(episode);
                  }}
                >
                  edit
                </MbxButton>,
              ]}
            >
              <List.Item.Meta
                title={
                  <>
                    <span style={{ paddingRight: '0.5em' }} data-tid="episode-list-item-name">
                      {episode.name}
                    </span>
                    {episode.tags.map((et: EpisodeTag) => (
                      <Tag
                        color="geekblue"
                        style={{ opacity: appRelevantTags.includes(et.tag.name) ? 1 : 0.45 }}
                        className={styles.episodeTag}
                        key={`episode-${episode.id}-tag-${et.id}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectTag(et.tag.id);
                        }}
                      >
                        {et.tag.name !== '' ? et.tag.name : `...`}
                      </Tag>
                    ))}
                  </>
                }
                description={appLocales.map((loc) => (
                  <Tag
                    key={loc.id}
                    color={codes[loc.locale.shortcode] ?? null}
                    style={{
                      fontSize: 10,
                      lineHeight: '14px',
                      paddingRight: 4,
                      paddingLeft: 4,
                    }}
                  >{`${loc.locale.shortcode} `}</Tag>
                ))}
                avatar={
                  <div style={{ display: 'flex' }}>
                    <ListItemType episode={episode} />
                    <EpisodeThumbnail episode={episode} />
                    <EpisodeErrorMarker episode={episode} />
                  </div>
                }
              />
              <div style={{ width: 85, marginLeft: 10, marginRight: -30, color: '#999' }}>
                <EyeOutlined style={{ fontSize: 14 }} />
                <span style={{ paddingLeft: 4, fontSize: 11, display: 'inline-block' }}>
                  {episode.views.toLocaleString()}
                </span>
              </div>
            </List.Item>
          );
        }}
      />

      <div
        style={{
          marginTop: '2em',
          marginBottom: '2em',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Pagination
          simple
          hideOnSinglePage
          current={currentPage}
          total={totalItems}
          pageSize={itemsPerPage}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
};

export default EpisodeList;
