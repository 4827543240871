import { createSelector } from 'reselect';

import { getEmptyAppNormalized } from '../../reducers/AppsReducer';

import type { RootState } from '../../reducers';
import type { AppNormalized } from '../../reducers/AppsReducer';

//
//
const getById = (state: RootState) => state.apps.byId;
export const getAppUiState = (state: RootState) => state.apps.ui;

//
//
export const getAppNormalizedByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => {
    const app = byId[id];
    if (app == null) {
      return getEmptyAppNormalized(id);
    }
    return app;
  }
);

//
//
export const getAppsNormalizedByIdsFunc = createSelector(
  getAppNormalizedByIdFunc,
  //
  ($getAppNormalizedByIdFunc) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id) => $getAppNormalizedByIdFunc(id)).filter((a): a is AppNormalized => !!a);
  }
);
