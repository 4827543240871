import { combineReducers } from 'redux';

import {
  BATCH_UPLOAD_CREATE_OK,
  BATCH_UPLOAD_GET_OK,
  BATCH_UPLOAD_RESET,
  BATCH_UPLOAD_UPDATE_OK,
} from '../constants/batchUpload';

import type { User } from './UsersReducer';
import type { BatchUploadItemStatus, BatchUploadStatus } from '../common/constants/batch-upload';
import type { AllowedContentType } from '../common/constants/content-type';
import type { Issue, ValidatedMetadata } from '../common/types/batch-upload';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

export type BatchUploadItem = {
  id: number;
  status: BatchUploadItemStatus;
  url: string | null;
  contentTypeId: AllowedContentType;
  name: string;
  totalParts: number | null;
  missingParts?: Array<number>;
};

type BatchUploadBase = {
  id: number;
  appId: number;
  uuid: string;
  status: BatchUploadStatus;
  validationRequest: ValidatedMetadata;
  createdBy: number;
  updatedBy: number | null;
  createdAt: Date;
  updatedAt: Date | null;
  items: Array<BatchUploadItem>;
  issues?: Array<Issue>;
};

export type BatchUploadNormalized = BatchUploadBase;

export type BatchUpload = Merge<
  BatchUploadBase,
  {
    creator: User;
  }
>;

export type BatchUploadByAppIdState = {
  [k: string | number]: BatchUploadNormalized | null;
};

export type BatchUploadState = {
  byAppId: BatchUploadByAppIdState;
};

const initialStateById: BatchUploadByAppIdState = {};

const byAppId = (state = initialStateById, action: AnyAction): BatchUploadByAppIdState => {
  switch (action.type) {
    case BATCH_UPLOAD_RESET: {
      return initialStateById;
    }
    case BATCH_UPLOAD_UPDATE_OK:
    case BATCH_UPLOAD_GET_OK:
    case BATCH_UPLOAD_CREATE_OK: {
      const { appId, batchUploadId } = action;
      const noUpload = action.payload === null;

      return {
        ...state,
        [appId]: noUpload
          ? null
          : {
              ...state[appId],
              ...action.payload.entities.batchUploads[batchUploadId],
            },
      };
    }
    default:
      return state;
  }
};

const combined = combineReducers({
  byAppId,
});

export default combined;
