import { DownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AppIcon from './AppIcon';
import styles from './AppSwitcher.module.scss';
import { useOptionalCurrentAppContext } from '../contexts';
import {
  isRouteName,
  ROUTE_NAME_APP_HOME,
  ROUTE_NAME_HOME,
  ROUTE_NAME_INTERNAL_APPS,
  ROUTE_NAME_INTERNAL_AUTHSERVICES,
  ROUTE_NAME_INTERNAL_JOBS,
  ROUTE_NAME_INTERNAL_PLATFORMS,
  ROUTE_NAME_INTERNAL_USERS,
  ROUTE_NAME_PROFILE,
} from '../navigation/routes';
import { SITEMAP_FLAT } from '../navigation/sitemap';
import { getCurrentAppPage, matchRoute, getUrlByName } from '../navigation/utils';
import { getAllApps } from '../selectors/apps/advanced';
import { getUserApps, isUserSuperadmin } from '../selectors/authSelectors';
import { getPathname } from '../selectors/pageHistory';
import { useGlobalSelector } from '../utils/hooks';

import type { App } from '../reducers/AppsReducer';

const { Option } = Select;

const adminRoutes = [
  getUrlByName(ROUTE_NAME_INTERNAL_PLATFORMS),
  getUrlByName(ROUTE_NAME_INTERNAL_AUTHSERVICES),
  getUrlByName(ROUTE_NAME_INTERNAL_APPS),
  getUrlByName(ROUTE_NAME_INTERNAL_USERS),
  getUrlByName(ROUTE_NAME_INTERNAL_JOBS),
  getUrlByName(ROUTE_NAME_PROFILE),
];

const AppSwitcher = () => {
  const { currentApp: maybeCurrentApp } = useOptionalCurrentAppContext();
  const allApps = useGlobalSelector(getAllApps);
  const userApps = useGlobalSelector(getUserApps);
  const isSuperadmin = useGlobalSelector(isUserSuperadmin);

  const currentAppList = isSuperadmin ? allApps : userApps;
  const [currentApp, setCurrentApp] = useState<App | null>(null);
  const [latestApp, setLatestApp] = useState<App | null>(null);
  const [currentMainPage, setCurrentMainPage] = useState<string | null>(null);

  const history = useHistory();

  const pathname = useGlobalSelector(getPathname);
  const { matchedRoute } = matchRoute(SITEMAP_FLAT, pathname);

  useEffect(() => {
    // This is responsible for getting current app page and redirection user after app switch
    const mainPageName = getCurrentAppPage(matchedRoute);
    setCurrentMainPage(mainPageName);
    if (matchedRoute != null && adminRoutes.includes(matchedRoute?.url)) {
      setCurrentApp(null);
    }
    if (
      matchedRoute?.url === getUrlByName(ROUTE_NAME_HOME) &&
      (latestApp != null || currentAppList.length > 0)
    ) {
      history.replace(
        getUrlByName(ROUTE_NAME_APP_HOME, {
          ':appUid': currentApp?.uid ?? latestApp?.uid ?? currentAppList[0].uid,
        })
      );
    }
  }, [matchedRoute]);

  const handleAppChange = (appId: number) => {
    const app = currentAppList.find((a) => a.id === appId);
    if (app == null) {
      throw new Error(`App with id ${appId} not found`);
    }
    console.log('App switcher: ', maybeCurrentApp?.uid, ' -> ', app.uid);
    setCurrentApp(app);
    let link = getUrlByName(ROUTE_NAME_APP_HOME, { ':appUid': app.uid });
    if (isRouteName(currentMainPage)) {
      link = getUrlByName(currentMainPage, { ':appUid': app.uid });
    }
    history.push(link);
  };

  const handleSingleAppClick = () => {
    if (currentAppList.length < 2) {
      handleAppChange(currentAppList[0].id);
    }
  };

  useEffect(() => {
    if (currentAppList.length < 2 && maybeCurrentApp == null) {
      setCurrentApp(currentAppList[0]);
      return;
    }

    if (maybeCurrentApp != null) {
      setCurrentApp(maybeCurrentApp);
    }
  }, [maybeCurrentApp, currentAppList]);

  useEffect(() => {
    if (currentApp != null) {
      setLatestApp(currentApp);
    }
  }, [currentApp]);

  return (
    <Select
      className={styles.switcher}
      value={currentApp?.id ?? 0}
      onChange={handleAppChange}
      popupClassName={styles.switcherDropdown}
      listHeight={500}
      disabled={currentAppList.length <= 1}
      suffixIcon={currentAppList.length <= 1 ? null : <DownOutlined />}
      onClick={handleSingleAppClick}
      data-tid="app-switcher"
      id="app-switcher"
    >
      <Option hidden={true} disabled value={0} key={0}>
        Select app...
      </Option>
      {currentAppList.map((app) => (
        <Option key={app.id} value={app.id}>
          <AppIcon app={app} />
          <span className={styles.appName}>{app.name}</span>
        </Option>
      ))}
    </Select>
  );
};

export default AppSwitcher;
