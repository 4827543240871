import { createSelector } from 'reselect';

import { getEmptyAppAuthService } from '../../reducers/AppAuthServiceReducer';
import { getAuthServiceByIdFunc } from '../authServices/advanced';

import {
  getAppAuthServiceNormalizedByIdFunc,
  getAllAppAuthServicesNormalizedForAppFunc,
} from './index';

import type { AppAuthServiceNormalized } from '../../reducers/AppAuthServiceReducer';

//
//
const denormalizeAppAuthServiceFunc = createSelector(
  getAuthServiceByIdFunc,
  //
  ($getAuthServiceById) => (appAuthServicesNormalized: Array<AppAuthServiceNormalized>) =>
    appAuthServicesNormalized.map((aasn) => ({
      ...aasn,
      authService: $getAuthServiceById(aasn.authService),
    }))
);

//
//
export const getAppAuthServiceByIdFunc = createSelector(
  getAppAuthServiceNormalizedByIdFunc,
  denormalizeAppAuthServiceFunc,
  //
  ($getAppAuthServiceNormalizedById, $denormalizeAppAuthService) => (appAuthServiceId: number) => {
    const aas = $getAppAuthServiceNormalizedById(appAuthServiceId);
    if (aas == null) {
      return getEmptyAppAuthService(appAuthServiceId);
    }

    return $denormalizeAppAuthService([aas])[0];
  }
);
//
//
export const getAppAuthServiceById = createSelector(
  getAppAuthServiceByIdFunc,
  (_: unknown, appAuthServiceId: number) => appAuthServiceId,
  //
  ($getAppAuthServiceById, appAuthServiceId) => $getAppAuthServiceById(appAuthServiceId)
);

//
//
export const getAppAuthServicesByIdsFunc = createSelector(
  getAppAuthServiceByIdFunc,
  //
  ($getAppAuthServiceById) => (appAuthServiceIds: Array<number>) => {
    if (!Array.isArray(appAuthServiceIds)) {
      return [];
    }
    return appAuthServiceIds.map((appAuthServiceId) => $getAppAuthServiceById(appAuthServiceId));
  }
);
//
//
export const getAppAuthServicesByIds = createSelector(
  getAppAuthServicesByIdsFunc,
  (_: unknown, appAuthServiceIds: Array<number>) => appAuthServiceIds,
  //
  ($getAppAuthServicesByIds, appAuthServiceIds) => $getAppAuthServicesByIds(appAuthServiceIds)
);

//
//
export const getAllAppAuthServicesForAppFunc = createSelector(
  getAllAppAuthServicesNormalizedForAppFunc,
  denormalizeAppAuthServiceFunc,
  //
  ($getAllAppAuthServicesNormalizedForApp, $denormalizeAppAuthService) => (appId: number) => {
    const aass = $getAllAppAuthServicesNormalizedForApp(appId);
    return $denormalizeAppAuthService(aass);
  }
);
//
//
export const getAllAppAuthServicesForApp = createSelector(
  getAllAppAuthServicesForAppFunc,
  (_: unknown, appId: number) => appId,
  //
  ($getAllAppAuthServicesForApp, appId) => $getAllAppAuthServicesForApp(appId)
);
