import { combineReducers } from 'redux';

import { getEmptyAppNormalized } from './AppsReducer';
import { GET_APPS_LIST_OK, GET_APP_DETAILS_OK } from '../constants/apps';
import { ASSETRESOURCE_CREATE_OK } from '../constants/assetresources';
import { ASSET_GET_DETAILS_OK, ASSET_GET_METADATA_OK } from '../constants/assets';
import {
  EPISODE_GET_LIST_OK,
  EPISODE_GET_DETAILS_OK,
  EPISODE_GET_METADATA_OK,
} from '../constants/episodes';
import { JOB_GET_METADATA_OK, JOB_GET_DETAILS_OK } from '../constants/jobs';
import { RELEASE_GET_LIST_OK, RELEASE_GET_DETAILS_OK } from '../constants/releases';
import {
  RESOURCE_CREATE_OK,
  RESOURCE_REMOVE_OK,
  RESOURCE_RERUN_CONVERSION_OK,
} from '../constants/resources';

import type { AppNormalized } from './AppsReducer';
import type { ParentFile } from './FileReducer';
import type { AnyAction } from 'redux';
import type { JsonValue, Merge } from 'type-fest';

type ResourceBase = {
  id: number;
  appId: number;
  isPrivate: boolean;
  meta: JsonValue;
  updatedAt: Date | null;
  typeId: number;
};

export type ResourceNormalized = Merge<
  ResourceBase,
  {
    file: number | null;
  }
>;

export type Resource = Merge<
  ResourceBase,
  {
    file: ParentFile | null;
    app: AppNormalized;
  }
>;

export type Icon = Merge<
  ResourceBase,
  {
    file: ParentFile | null;
  }
>;

//
//
export const getEmptyResourceNormalized = (id?: number): ResourceNormalized => ({
  id: id ?? -1,
  appId: -1,
  isPrivate: false,
  meta: {},
  file: null,
  updatedAt: null,
  typeId: -1,
});

//
//
export const getEmptyResource = (id?: number): Resource => ({
  id: id ?? -1,
  appId: -1,
  isPrivate: false,
  meta: {},
  file: null,
  updatedAt: null,
  typeId: -1,
  app: getEmptyAppNormalized(),
});

type ResourceStateById = { [k: string | number]: ResourceNormalized };
export type ResourceState = {
  byId: ResourceStateById;
};

const initialState: ResourceStateById = {};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialState, action: AnyAction): ResourceStateById => {
  switch (action.type) {
    case GET_APP_DETAILS_OK:
    case GET_APPS_LIST_OK:
    case EPISODE_GET_DETAILS_OK:
    case EPISODE_GET_LIST_OK:
    case EPISODE_GET_METADATA_OK:
    case JOB_GET_METADATA_OK:
    case JOB_GET_DETAILS_OK:
    case RESOURCE_CREATE_OK:
    case RELEASE_GET_LIST_OK:
    case RELEASE_GET_DETAILS_OK:
    case ASSET_GET_DETAILS_OK:
    case ASSET_GET_METADATA_OK:
    case RESOURCE_RERUN_CONVERSION_OK:
    case ASSETRESOURCE_CREATE_OK:
      if (typeof action.payload.entities.resources === 'undefined') {
        return state;
      }
      return {
        ...state,
        ...action.payload.entities.resources,
      };

    case RESOURCE_REMOVE_OK: {
      const { [action.resource.id]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
});

export default combined;
