import { createSelector } from 'reselect';

import { getAssetsResourcesByIdsFunc } from './assetResourceSelectors';
import { getEmptyAsset } from '../reducers/AssetReducer';

import type { RootState } from '../reducers';
import type { Asset } from '../reducers/AssetReducer';
import type { Selector } from 'reselect';

export type AssetFilterResult = {
  max: number;
  assets: Array<Asset>;
  allActiveAssets: Array<Asset>;
  currentPage: number;
  currentSearch: string;
  itemsPerPage: number;
};

//
//
const getById = (state: RootState) => state.assets.byId;

//
//
export const getAssetByIdFunc: Selector<RootState, (id: number) => Asset> = createSelector(
  getById,
  getAssetsResourcesByIdsFunc,
  //
  (byId, $getAssetsResourcesByIds) => (id) => {
    const asset = byId[id];
    if (asset == null) {
      return getEmptyAsset(id);
    }

    return {
      ...asset,
      resources: $getAssetsResourcesByIds(asset.resources),
    };
  }
);

//
//
export const getAssetById = createSelector(
  (_: unknown, assetId: number) => assetId,
  getAssetByIdFunc,
  //
  (id, $getAssetById) => $getAssetById(id)
);

//
//
export const getAllAssetsForAppFunc = createSelector(
  getById,
  getAssetByIdFunc,
  //
  (byId, $getAssetById) => (appId: number) => {
    const ids = Object.keys(byId).map((idStr) => Number(idStr));
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id: number) => $getAssetById(id)).filter((asset) => asset.appId === appId);
  }
);

//
//
export const getAllAssetsForApp = createSelector(
  getAllAssetsForAppFunc,
  (_: unknown, id: number) => id,
  //
  ($getAllAssetsForApp, id) => $getAllAssetsForApp(id)
);

//
//
export const getFilteredAssetsInfo = createSelector(
  getAllAssetsForAppFunc,
  (_: unknown, settings: { appId: number; search: string; page: number; itemsPerPage: number }) =>
    settings,
  //
  ($getAllAssetsForApp, settings) => {
    const { appId, search, page, itemsPerPage } = settings;
    const assetsPerApp = $getAllAssetsForApp(appId);

    // sort assets by updatedAt DESC -> newest first
    assetsPerApp.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

    const allActiveAssets = assetsPerApp.filter((asset) => asset.isActive);
    allActiveAssets.sort((a, b) => b.ordinal - a.ordinal);

    let filteredItems = assetsPerApp;
    if (search !== '') {
      filteredItems = assetsPerApp.filter(
        (item) => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    }

    const max = filteredItems.length;
    const firstIndex = (page - 1) * itemsPerPage;
    const currentPageItems = filteredItems.slice(firstIndex, firstIndex + itemsPerPage);
    return {
      max,
      currentPage: page,
      currentSearch: search,
      itemsPerPage,
      assets: currentPageItems,
      allActiveAssets,
    };
  }
);
