import { getEmptyAppPlatform } from './AppPlatformReducer';
import { EPISODE_GET_DETAILS_OK, EPISODE_REMOVE_OK } from '../constants/episodes';
import {
  EPISODEVERSIONREQUIREMENTS_CREATE_OK,
  EPISODEVERSIONREQUIREMENTS_UPDATE_OK,
  EPISODEVERSIONREQUIREMENTS_DELETE_OK,
} from '../constants/episodeVersionRequirements';

import type { AppPlatform } from './AppPlatformReducer';
import type { AppVersion } from './AppVersionReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type EpisodeVersionRequirementBase = {
  id: number;
  exclude?: boolean;
};

export type EpisodeVersionRequirementNormalized = Merge<
  EpisodeVersionRequirementBase,
  {
    appPlatform: number;
    minVersion: number | null;
    maxVersion: number | null;
  }
>;

export type EpisodeVersionRequirement = Merge<
  EpisodeVersionRequirementBase,
  {
    appPlatform: AppPlatform;
    minVersion: AppVersion | null;
    maxVersion: AppVersion | null;
  }
>;

export type EpisodeVersionRequirementState = {
  [k: string | number]: EpisodeVersionRequirementNormalized;
};

export const getEmptyEpisodeVersionRequirement = (id?: number): EpisodeVersionRequirement => ({
  id: id ?? -1,
  appPlatform: getEmptyAppPlatform(),
  minVersion: null,
  maxVersion: null,
});

const initialStateById: EpisodeVersionRequirementState = {};

//
//
const byId = (state = initialStateById, action: AnyAction): EpisodeVersionRequirementState => {
  switch (action.type) {
    case EPISODE_GET_DETAILS_OK:
    case EPISODEVERSIONREQUIREMENTS_CREATE_OK:
    case EPISODEVERSIONREQUIREMENTS_UPDATE_OK: {
      const requirements = action?.payload?.entities?.episodeRequirements;
      if (requirements == null) {
        return state;
      }
      return {
        ...state,
        ...requirements,
      };
    }

    case EPISODEVERSIONREQUIREMENTS_DELETE_OK: {
      const { [action.episodeVersionRequirementId]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case EPISODE_REMOVE_OK: {
      // TODO: implement
      return state;
    }

    default:
      return state;
  }
};

export default byId;
