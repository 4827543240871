import { Alert, Button } from 'antd';

import { ConversionTypes } from '../common/constants/resource-type';
import AppEditForm from '../components/AppEditForm';
import AppPlatformList from '../components/AppPlatformList';
import AppReceiptValidatorList from '../components/AppReceiptValidatorList';
import ConfigurationForm from '../components/ConfigurationForm';
import CustomPageHeader from '../components/CustomPageHeader';
import { ROUTE_NAME_INTERNAL_APPS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

import type { Configuration } from '../common/types/app';
import type { AppEditFormValues } from '../components/AppEditForm';
import type { App } from '../reducers/AppsReducer';

type Props = Readonly<{
  error: string;
  details: App;
  onSubmit: (values: AppEditFormValues) => Promise<void>;
  onCancel: () => void;
  onSubmitConfiguration: (config: Configuration) => Promise<void>;
  onRerunAllConversions: (conversionType: string) => Promise<void>;
}>;

//
//
const AppDetail = (props: Props) => {
  const { error, details, onSubmit, onCancel, onSubmitConfiguration, onRerunAllConversions } =
    props;

  const handleRunConversion = (type: string) => {
    if (window.confirm('sure to start?')) {
      onRerunAllConversions(type);
    }
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <div>
      <CustomPageHeader
        title={`Admin settings for ${details.name}`}
        backLink={getUrlByName(ROUTE_NAME_INTERNAL_APPS)}
      />

      <AppEditForm data={details} onSubmit={onSubmit} onCancel={onCancel} />

      <AppPlatformList appId={details.id} />

      <AppReceiptValidatorList appId={details.id} />

      <div style={{ margin: '4em 0' }}>
        <h4>Run all _media_ encoding jobs (if all or one of the templates is missing)</h4>
        <Alert
          message="Warning"
          description="This action can be pretty costly - be sure you know what you do"
          type="warning"
          showIcon
          style={{ width: '66%' }}
        />
        <Button
          type="primary"
          danger
          style={{ marginTop: '0.5em', marginRight: '0.5em' }}
          onClick={() => handleRunConversion(ConversionTypes.ALL_PLAYABLE_MEDIA)}
        >
          Start ALL MEDIA conversion (videos & audios)
        </Button>
        <Button
          type="primary"
          danger
          style={{ marginTop: '0.5em', marginRight: '0.5em' }}
          onClick={() => handleRunConversion(ConversionTypes.VIDEOS)}
        >
          Start VIDEO ONLY conversion
        </Button>
        <Button
          type="primary"
          danger
          style={{ marginTop: '0.5em' }}
          onClick={() => handleRunConversion(ConversionTypes.AUDIOS)}
        >
          Start AUDIO ONLY conversion
        </Button>
      </div>

      <div style={{ margin: '4em 0' }}>
        <h4>Run all _image_ conversion jobs (if all or one of the templates is missing)</h4>
        <Button
          type="primary"
          danger
          style={{ marginTop: '0.5em' }}
          onClick={() => handleRunConversion(ConversionTypes.IMAGES)}
        >
          Start IMAGE conversion
        </Button>
      </div>

      <ConfigurationForm
        title="App specific configuration options"
        data={details.configuration}
        onUpdateConfig={onSubmitConfiguration}
      />
    </div>
  );
};

export default AppDetail;
