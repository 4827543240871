import { Tag, Tooltip } from 'antd';
import * as React from 'react';

import type { SearchItem, SearchItemType } from './ListSearchInput';

type Props = Readonly<{
  items: Array<SearchItem>;
  type: SearchItemType;
  onClose: (item: SearchItem) => void;
}>;

const FilterDisplay = (props: Props) => {
  const { items, type, onClose } = props;

  let tagColor = 'purple';
  const label = type === 'search' ? 'Search by: ' : 'Filtered by: ';

  return (
    <>
      {items.length > 0 && <span>{label}</span>}
      {items.map((item) => {
        const tagStyle: React.CSSProperties = {};
        if (type === 'category' || type === 'tag') {
          tagColor = type === 'category' ? 'pink' : 'geekblue';
          tagStyle.opacity = item?.isDraft ? 0.4 : 1;
        }
        console.log('FilterDisplay()', item, tagColor);

        const isLongLabel = item.value.length > 20;
        const tagElem = (
          <Tag
            color={tagColor}
            style={tagStyle}
            key={item.value}
            closable
            onClose={() => onClose(item)}
          >
            {isLongLabel ? `${item.value.slice(0, 20)}...` : item.value}
          </Tag>
        );
        return isLongLabel ? (
          <Tooltip title={item.value} key={item.value}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
    </>
  );
};

export default FilterDisplay;
