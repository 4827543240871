import CustomPageHeader from '../components/CustomPageHeader';
import EpisodeEditForm from '../components/EpisodeEditForm';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_EPISODES } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

import type { EpisodeEditFormValues } from '../components/EpisodeEditForm';

type Props = Readonly<{
  isBusy: boolean;
  createEpisode: (values: EpisodeEditFormValues) => Promise<boolean>;
}>;

//
//
const EpisodeCreate = (props: Props) => {
  const { createEpisode, isBusy } = props;

  const { currentApp } = useCurrentAppContext();
  const backLink = getUrlByName(ROUTE_NAME_APP_EPISODES, {
    ':appUid': currentApp.uid,
  });

  return (
    <div>
      <CustomPageHeader title="Create new episode" backLink={backLink} />

      <div style={{ marginTop: '2em', maxWidth: 600 }}>
        <EpisodeEditForm onSubmit={createEpisode} onCancelOrBacklink={backLink} isBusy={isBusy} />
      </div>
    </div>
  );
};

export default EpisodeCreate;
