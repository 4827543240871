import { Button } from 'antd';

const TestPage = () => (
  <div>
    <h1>Page for testing stuff</h1>

    <Button
      onClick={() => {
        throw new Error('test error');
      }}
    >
      throw error
    </Button>
  </div>
);

export default TestPage;
