import { ConfigProvider, DatePicker, List } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './TopTenEpisodesList.module.scss';
import dayjs from '../utils/date';

import type { EpisodeStats } from '../reducers/EpisodeTopTenReducer';
import type { DateWrapper } from '../utils/date';
import type { DatePickerProps } from 'antd';

type Props = {
  title: string;
  episodesData: Array<EpisodeStats> | null;
  currentAppUid?: string;
  onWeekChange?: (date: DateWrapper | null, dateString: string) => void;
};

//
//
const customWeekStartEndFormat: DatePickerProps['format'] = (value) => {
  const start = dayjs(value)
    .startOf('week')
    .toDate()
    .toLocaleDateString(undefined, { day: 'numeric', month: 'numeric' });
  const end = dayjs(value)
    .endOf('week')
    .toDate()
    .toLocaleDateString(undefined, { day: 'numeric', month: 'numeric', year: '2-digit' });
  return `${start} – ${end}`;
};

//
//
const TopTenEpisodesList: React.FunctionComponent<Props> = ({
  title,
  episodesData,
  currentAppUid,
  onWeekChange,
}) => {
  const loading = episodesData == null;
  return (
    <div className={styles.rankingContainer}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3 style={{ fontWeight: 700, display: 'inline-block' }}>{title}</h3>
        {onWeekChange != null && (
          <DatePicker
            size="small"
            allowClear={false}
            onChange={onWeekChange}
            format={customWeekStartEndFormat}
            defaultValue={dayjs().startOf('isoWeek')}
            picker="week"
            disabledDate={(currentDate) => {
              return dayjs().isBefore(currentDate);
            }}
            className={styles.weekInput}
          />
        )}
      </div>
      <hr />
      <ConfigProvider renderEmpty={() => <span>No data found</span>}>
        <List
          loading={loading}
          dataSource={episodesData?.sort((a, b) => b.totalViews - a.totalViews)}
          renderItem={(item, index) => (
            <List.Item
              style={{
                padding: `6px 8px`,
              }}
              className={styles.episodeListItem}
            >
              <List.Item.Meta
                title={
                  <Link to={`/app/${currentAppUid}/episodes/${item.episodeId}`}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '14px',
                      }}
                    >
                      <div
                        style={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        <span style={{ width: 32, display: 'inline-block' }}>{`${
                          index + 1
                        }. `}</span>
                        {item.episodeName}
                      </div>
                      <div style={{ paddingLeft: 10 }}>
                        <strong data-tid="episodeTotalViews">
                          {Number(item.totalViews).toLocaleString()}
                        </strong>
                      </div>
                    </div>
                  </Link>
                }
              />
            </List.Item>
          )}
        />
      </ConfigProvider>
    </div>
  );
};

export default TopTenEpisodesList;
