import axios from 'axios';

import { logUnknownError } from './log';
import { LOGOUT_OK } from '../constants/auth';

import type { AxiosResponseHeaders, Method, RawAxiosResponseHeaders } from 'axios';
import type { Dispatch as ReduxDispatch } from 'redux';

type FetchOptions = {
  url: string;
  method?: Method;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  signal?: AbortSignal;
};

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
if (API_ENDPOINT == null) {
  throw new Error('process.env.REACT_APP_API_ENDPOINT not set');
}

//
//
export const fetch = async ({
  url,
  method = 'GET',
  data = {},
  params = {},
  signal,
}: FetchOptions) => {
  const response = await axios.request({
    url: `${API_ENDPOINT}${url}`,
    method,
    params,
    // withCredentials: true,
    // mode: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'json',
    data,
    signal: signal,
    validateStatus: (status) => status < 500, // Reject only if the status code is greater than or equal to 500
  });

  // console.log('fetch() response', response);

  // TODO: dont know what the best way to dispatch another event here ...
  // if (response.status === 401) {
  //   // unauthorized -> redirect to login

  //   history.push(ROUTES.login);
  // }

  return response;
};

//
//
export const createUpload = (
  url: string,
  file: File,
  onProgress: (e: ProgressEvent) => void
): { xhr: XMLHttpRequest; upload: () => Promise<void> } => {
  const xhr = new XMLHttpRequest();

  return {
    xhr,
    upload: () =>
      new Promise((resolve, reject) => {
        xhr.open('PUT', url, true);
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.onload = () => {
          if (xhr.status === 200) {
            // success!
            console.log('success');
            resolve();
          } else {
            console.log('error', xhr.status, xhr.statusText);
            reject(new Error(xhr.statusText));
          }
        };
        xhr.onerror = (error) => {
          // error...
          console.log('onerror', error);
          reject(error);
        };
        xhr.upload.onprogress = onProgress;
        xhr.send(file);
      }),
  };
};

//
//
export const wrapFetch = async <ResponseData = Record<string, unknown>>(
  fetchOptions: FetchOptions,
  dispatch: ReduxDispatch,
  types: null | {
    init: string;
    fail: string;
  },
  expectedStatus: number | Array<number> = [200]
): Promise<{
  status: number;
  data: ResponseData;
  headers?: RawAxiosResponseHeaders | AxiosResponseHeaders;
}> => {
  let response;

  const { init: typeInit, fail: typeFail } = types ?? {};
  try {
    if (typeInit != null) {
      dispatch({ type: typeInit });
    }
    response = await fetch(fetchOptions);
  } catch (error) {
    const { msg } = logUnknownError(error);
    console.error('You have an error in your code or there are Network issues.', msg);
    if (typeFail != null) {
      dispatch({ type: typeFail, payload: msg });
    }
    throw error;
  }

  if (typeof expectedStatus === 'number') {
    expectedStatus = [expectedStatus];
  }

  if (expectedStatus.includes(response.status)) {
    return response;
  }

  if (response.status < 300) {
    console.warn('got good status but failed anyway ... maybe wrong status?', fetchOptions, {
      expectedStatus: JSON.stringify(expectedStatus),
      status: response.status,
    });
  }

  if (typeFail != null) {
    dispatch({ type: typeFail, payload: response.data });
  }

  if (response.status === 401) {
    dispatch({ type: LOGOUT_OK, redirectUrl: response.headers?.location ?? null });
  }

  if (response.data.message != null) {
    throw new Error(response.data.message);
  } else if (response.data.errors != null) {
    const keys = Object.keys(response.data.errors);
    if (keys.length === 1) {
      throw new Error(response.data.errors[keys[0]].msg);
    } else {
      throw new Error('multiple_validation_errors');
    }
  }
  throw new Error('error while trying to contact API');
};
