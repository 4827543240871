import merge from 'lodash.merge';
import union from 'lodash.union';
import { combineReducers } from 'redux';

import { GET_APP_DETAILS_OK } from '../constants/apps';
import { LOGIN_OK } from '../constants/auth';
import { BATCH_UPLOAD_GET_OK } from '../constants/batchUpload';
import { DEPLOYMENT_GET_LIST_OK, DEPLOYMENT_GET_DETAILS_OK } from '../constants/deployments';
import { JOB_GET_METADATA_OK, JOB_GET_DETAILS_OK } from '../constants/jobs';
import {
  USER_GET_LIST_OK,
  USER_DELETE_OK,
  USER_GET_DETAIL_OK,
  USER_UPDATE_OK,
  USER_ROLE_ADD_OK,
  USER_ROLE_REMOVE_OK,
  SEARCH_USER_OK,
  USER_INVITE_ACCEPT_OK,
} from '../constants/users';

import type { AppNormalized } from './AppsReducer';
import type { Role } from './RoleReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type UserBase = {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  twofaActivated: boolean;
  imageUrl: string;
  confirmedAt: Date | null;
  createdAt: Date;
  updatedAt: Date | null;
};

export type UserSignup = {
  id?: number | null;
  email: string;
  password: string;
  firstname: string;
  lastname: string;
};

export type UserNormalized = Merge<
  UserBase,
  {
    apps: Array<number>;
    roles: Array<number>;
  }
>;

export type User = Merge<
  UserBase,
  {
    apps: Array<AppNormalized>;
    roles: Array<Role>;
  }
>;

export type UserStateById = { [k: string | number]: UserNormalized };
// export type UserStateUserApps = { [k: string | number]: Array<number> };
export type UserStateUserRoles = { [k: string | number]: Array<number> };
export type UserStateAllIds = Array<number>;

export type UserState = {
  byId: UserStateById;
  // userApps: UserStateUserApps;
  userRoles: UserStateUserRoles;
  allIds: UserStateAllIds;
};

const initialStateById: UserStateById = {};
// const initialStateUserApps: UserStateUserApps = {};
const initialStateUserRoles: UserStateUserRoles = {};
const initialStateAllIds: UserStateAllIds = [];

//
//
export const getEmptyUser = (id?: number): User => ({
  id: id ?? -1,
  email: '',
  firstname: '',
  lastname: '',
  twofaActivated: false,
  imageUrl: '',
  confirmedAt: null,
  createdAt: new Date(),
  updatedAt: new Date(),
  apps: [],
  roles: [],
});

//
//
const byId = (state = initialStateById, action: AnyAction): UserStateById => {
  switch (action.type) {
    case USER_GET_LIST_OK:
    case SEARCH_USER_OK:
    case JOB_GET_METADATA_OK:
    case JOB_GET_DETAILS_OK:
    case DEPLOYMENT_GET_LIST_OK:
    case DEPLOYMENT_GET_DETAILS_OK:
    case GET_APP_DETAILS_OK:
      return merge({}, state, action.payload.entities?.users ?? {});

    // [GET_APP_DETAILS_OK]: (state: UserStateById, action: AnyAction) => {
    //   const appId = action.payload.result;
    //   const users = action.payload.entities?.users ?? {};

    //   const newState = {...state};

    //   Object.keys(state).forEach((userId) => {
    //     const oldUser = state[userId];
    //     const newUser = users[userId];
    //     if (newUser != null) {

    //       if (oldUser.apps == null) {
    //         apps = [appId];
    //       } else if (Array.isArray(oldUser.apps) && !oldUser.apps.includes(appId)) {
    //         apps = [...oldUser.apps, appId];
    //       }
    //       u.apps = apps;
    //     } else {
    //       if (u.apps == null) {
    //         u.apps = [appId];
    //       } else if (Array.isArray(u.apps) && !u.apps.includes(appId)) {
    //         u.apps.push(appId);
    //       }
    //     }
    //   });
    //   return newState;
    // },

    case USER_DELETE_OK: {
      const copy = { ...state };
      delete copy[action.payload];
      return copy;
    }

    case BATCH_UPLOAD_GET_OK: {
      const { batchUploadId } = action;
      const noUpload = action.payload === null;

      if (noUpload) {
        return state;
      }

      const upload = action.payload.entities.batchUploads[batchUploadId];

      return noUpload
        ? state
        : {
            ...state,
            [upload.createdBy]: upload.creator,
          };
    }

    case USER_GET_DETAIL_OK:
    case USER_UPDATE_OK:
    case LOGIN_OK: {
      const id = action.payload.result;
      // const { appId, allPreviousRoles } = action;
      // const newData = JSON.parse(JSON.stringify(action.payload.entities.users[id]));
      const user = action.payload.entities.users?.[id] ?? null;
      if (user == null) {
        throw new Error('user missing');
      }
      const newData = { ...user };

      delete newData.apps;
      delete newData.roles;

      return {
        ...state,
        [id]: newData,
      };

      // if we did not query based on appId -> overwrite complete data entry
      // if (appId == null) {
      //   return {
      //     ...state,
      //     [id]: newData,
      //   };
      // }

      // // merge apps+roles b/c result only contains app related entries
      // const user = state[id] != null ? { ...state[id] } : { ...newData };

      // const roleToApp = {};
      // allPreviousRoles.forEach((role) => {
      //   roleToApp[role.id] = role.appId;
      // });

      // let userRoles = user?.roles ?? [];
      // // remove all roles w/ current appId that are not included in the new ones
      // userRoles = userRoles.filter(
      //   (roleId) => roleToApp[roleId] !== appId || newData.roles.includes(roleId)
      // );
      // // add the new roles w/ current appId
      // userRoles = union(userRoles, newData.roles);

      // const userApps = union(user?.apps ?? [], newData.apps);

      // const newUserData = {
      //   ...user,
      //   apps: [...userApps],
      //   roles: [...userRoles],
      // };

      // return {
      //   ...state,
      //   [id]: newUserData,
      // };
    }

    case USER_INVITE_ACCEPT_OK: {
      const { updatedUser } = action;
      const { result, entities } = updatedUser;

      if (updatedUser != null) {
        const newData = { ...entities.users[result] };

        delete newData.apps;
        delete newData.roles;

        return {
          ...state,
          [result]: newData,
        };
      }
      return state;
    }

    default:
      return state;
  }
};

const userRoles = (state = initialStateUserRoles, action: AnyAction): UserStateUserRoles => {
  switch (action.type) {
    case USER_GET_DETAIL_OK:
    case USER_UPDATE_OK:
    case LOGIN_OK: {
      const userId = action.payload.result;
      const users = action.payload.entities?.users ?? {};

      const newState = { ...state };

      // if appId != null query was filtered by appId and only includes data for this app
      const { appId } = action;
      if (appId == null || newState[userId] == null) {
        // global user data => overwrite
        newState[userId] = users[userId].roles;
      } else if (Array.isArray(newState[userId])) {
        // filtered user data => add if not already included
        newState[userId] = union([...newState[userId]], users[userId].roles);
      }
      return newState;
    }

    case USER_ROLE_ADD_OK: {
      const roles = state[action.userId] ?? [];
      const newRoleId = action.roleId;

      if (roles.includes(newRoleId)) {
        return state;
      }

      return {
        ...state,
        [action.userId]: [...roles, newRoleId],
      };
    }

    case USER_ROLE_REMOVE_OK: {
      const roles = state[action.userId] ?? [];
      const removedRoleId = action.roleId;

      if (!roles.includes(removedRoleId)) {
        return state;
      }

      return {
        ...state,
        [action.userId]: roles.filter((roleId) => roleId !== removedRoleId),
      };
    }

    case USER_INVITE_ACCEPT_OK: {
      const { updatedUser } = action;
      const { result, entities } = updatedUser;

      if (updatedUser != null) {
        return {
          ...state,
          [result]: entities.users[result].roles,
        };
      }
      return state;
    }

    default:
      return state;
  }
};

//
//
const allIds = (state = initialStateAllIds, action: AnyAction): UserStateAllIds => {
  switch (action.type) {
    case USER_GET_LIST_OK:
      return action.payload.result;

    case USER_DELETE_OK:
      return state.filter((id) => id !== action.payload);

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  // userApps,
  userRoles,
  allIds,
});

export default combined;
