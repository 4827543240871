import { schema, normalize } from 'normalizr';

import {
  ASSET_GET_LIST,
  ASSET_GET_LIST_OK,
  ASSET_GET_LIST_FAIL,
  ASSET_GET_METADATA,
  ASSET_GET_METADATA_OK,
  ASSET_GET_METADATA_FAIL,
  ASSET_GET_DETAILS,
  ASSET_GET_DETAILS_OK,
  ASSET_GET_DETAILS_FAIL,
  ASSET_CREATE,
  ASSET_CREATE_OK,
  ASSET_CREATE_FAIL,
  ASSET_UPDATE,
  ASSET_UPDATE_OK,
  ASSET_UPDATE_FAIL,
  ASSET_BULK_UPDATE,
  ASSET_BULK_UPDATE_OK,
  ASSET_BULK_UPDATE_FAIL,
  ASSET_REMOVE,
  ASSET_REMOVE_OK,
  ASSET_REMOVE_FAIL,
} from '../constants/assets';
import { AssetSchema } from '../schemas';
import { getAssetById } from '../selectors/assetSelectors';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';
import type { GlobalStateGetter } from '../reducers';

//
//
export const createAsset =
  (appId: number, { name, isDraft }: { name: string; isDraft: boolean }) =>
  async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const response = await wrapFetch<{ id: number }>(
      {
        url: `/assets`,
        method: 'POST',
        data: {
          appId,
          name,
          isDraft,
        },
      },
      dispatch,
      { init: ASSET_CREATE, fail: ASSET_CREATE_FAIL },
      201
    );
    const normalizedData = normalize(response.data, AssetSchema);
    dispatch({ type: ASSET_CREATE_OK, payload: normalizedData, appId });

    const state = getState();
    const newAsset = getAssetById(state, response.data.id);
    return newAsset;
  };

//
//
export const getAssetList = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/assets`,
      params: {
        appId,
      },
    },
    dispatch,
    { init: ASSET_GET_LIST, fail: ASSET_GET_LIST_FAIL }
  );
  const normalizedData = normalize(response.data, new schema.Array(AssetSchema));
  dispatch({ type: ASSET_GET_LIST_OK, payload: normalizedData, appId });
  return normalizedData;
};

//
//
export const getAssetMetadata =
  (assetIds: Array<number>) => async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const state = getState();

    // allow maximum of 25 ids (performance ...)
    if (assetIds.length > 25) {
      throw new Error('getAssetMetadata(): asked for too many assets');
    }

    // check if all episodeIds belong to the same app
    let appId: null | number = null;
    assetIds.forEach((assetId) => {
      const asset = getAssetById(state, assetId);
      if (asset.id === -1) {
        throw new Error('unknown asset');
      }
      if (appId == null) {
        appId = asset.appId;
      } else if (appId !== asset.appId) {
        throw new Error('assets do not belong to same app');
      }
    });

    const response = await wrapFetch(
      {
        url: `/assets/metadata`,
        params: {
          appId,
          ids: assetIds.join(','),
        },
      },
      dispatch,
      { init: ASSET_GET_METADATA, fail: ASSET_GET_METADATA_FAIL }
    );
    const normalizedData = normalize(response.data, new schema.Array(AssetSchema));
    dispatch({ type: ASSET_GET_METADATA_OK, payload: normalizedData, appId });
    return normalizedData;
  };

//
//
export const getAssetDetails = (assetId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/assets/${assetId}` }, dispatch, {
    init: ASSET_GET_DETAILS,
    fail: ASSET_GET_DETAILS_FAIL,
  });
  const normalizedData = normalize(response.data, AssetSchema);
  dispatch({ type: ASSET_GET_DETAILS_OK, payload: normalizedData });
  return normalizedData;
};

//
//
export const updateAsset =
  (
    assetId: number,
    {
      name,
      ordinal,
      isActive,
      isDraft,
    }: { name: string; ordinal: number; isActive: boolean; isDraft: boolean }
  ) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/assets/${assetId}`,
        method: 'PUT',
        data: {
          name,
          ordinal,
          isActive,
          isDraft,
        },
      },
      dispatch,
      { init: ASSET_UPDATE, fail: ASSET_UPDATE_FAIL }
    );
    const normalizedData = normalize(response.data, AssetSchema);
    dispatch({
      type: ASSET_UPDATE_OK,
      payload: normalizedData,
      assetId,
    });
    return response.data;
  };

//
//
export const bulkUpdateAssets =
  (appId: number, assets: Array<{ id: number; ordinal: number }>) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/assets`,
        method: 'PUT',
        data: {
          appId,
          assets,
        },
      },
      dispatch,
      { init: ASSET_BULK_UPDATE, fail: ASSET_BULK_UPDATE_FAIL }
    );
    const normalizedData = normalize(response.data, new schema.Array(AssetSchema));
    dispatch({
      type: ASSET_BULK_UPDATE_OK,
      payload: normalizedData,
    });
    return response.data;
  };

//
//
export const deleteAsset = (appId: number, assetId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/assets/${assetId}`,
      method: 'DELETE',
    },
    dispatch,
    { init: ASSET_REMOVE, fail: ASSET_REMOVE_FAIL },
    204
  );

  dispatch({
    type: ASSET_REMOVE_OK,
    appId,
    assetId,
  });
  return response.data;
};
