import { SettingOutlined, UserAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { List, Button, Avatar, Tag } from 'antd';

import styles from './UserList.module.scss';
import ButtonLinked from '../components/ButtonLinked';
import PendingInvites from '../containers/PendingInvitesContainer';
import {
  ROUTE_NAME_INTERNAL_USERINVITE,
  ROUTE_NAME_INTERNAL_USERROLES,
} from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

import type { User } from '../reducers/UsersReducer';

type Props = {
  allUsers: Array<User>;
  deleteUser: (userId: number) => Promise<void>;
  openDetails: (userId: number) => void;
};

//
//
const UserList = (props: Props) => {
  const { allUsers, openDetails, deleteUser } = props;

  const handleUserDelete = (user: User) => {
    if (window.confirm(`Really delete user: ${user.firstname} ${user.lastname}`)) {
      deleteUser(user.id);
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <h1>List of all users</h1>
        <div className={styles.headerExtras}>
          <ButtonLinked
            data-tid="button-open-manage-roles"
            linkTo={getUrlByName(ROUTE_NAME_INTERNAL_USERROLES)}
            icon={<SettingOutlined />}
          >
            manage roles
          </ButtonLinked>
          <ButtonLinked
            data-tid="button-open-invite-user"
            linkTo={getUrlByName(ROUTE_NAME_INTERNAL_USERINVITE)}
            type="primary"
            icon={<UserAddOutlined />}
            style={{ marginLeft: '10px' }}
          >
            invite user
          </ButtonLinked>
        </div>
      </div>

      <PendingInvites appId={null} />

      <List
        id="mbx-user-list"
        bordered={false}
        dataSource={allUsers}
        renderItem={(user: User) => (
          <List.Item
            className={styles.item}
            onClick={() => {
              openDetails(user.id);
            }}
            key={user.id}
            actions={[
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  openDetails(user.id);
                }}
                icon={<EditOutlined />}
                size="small"
                data-tid="btn-edit-user"
                key="btn-edit-user"
              >
                edit
              </Button>,
              <Button
                ghost
                danger
                size="small"
                icon={<DeleteOutlined />}
                data-tid="btn-delete-user"
                key="btn-delete-user"
                onClick={(e) => {
                  e.stopPropagation();
                  handleUserDelete(user);
                }}
              >
                delete
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={user.imageUrl} shape="square" />}
              title={
                <>
                  <span
                    style={{ paddingRight: '10px' }}
                  >{`${user.firstname} ${user.lastname}`}</span>
                  {user.twofaActivated && <Tag color="green">2FA</Tag>}
                </>
              }
              description={user.email}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default UserList;
