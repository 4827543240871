import { UndoOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { List, Menu, Tag, Modal, Select, Checkbox, AutoComplete, Input } from 'antd';
import { useState, useEffect } from 'react';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Prompt } from 'react-router-dom';

import { getReleaseStatus } from '../common/utils/release';
import LocalReleaseDates, { LocalDateFootnote } from '../components/base/LocalReleaseDates';
import MbxButton from '../components/base/MbxButton';
import CustomPageHeader from '../components/CustomPageHeader';
import EpisodeThumbnail from '../components/EpisodeLocalizedThumbnail';
import ListItemType from '../components/ListItemType';
import { ROUTE_NAME_APP_CATEGORIES } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { logUnknownError } from '../utils/log';
import { sortReleaseByEpisodeName, sortByCreatedAt, sortByPublishDate } from '../utils/sort';

import type { App } from '../reducers/AppsReducer';
import type { Category } from '../reducers/CategoryReducer';
import type { Region } from '../reducers/RegionReducer';
import type { Release } from '../reducers/ReleaseReducer';
import type { DropResult, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

const { confirm } = Modal;

type Props = {
  currentApp: App;
  currentCategory: Category;
  currentRegionId: number | null;
  releasesNotInCategoryForRegion: Array<Release>;
  filterReleases: Array<Release>;
  regions: Array<Region>;
  isSaving: boolean;
  switchRegion: (regionId: number) => void;
  saveSortOrder: (sortOrder: Array<number>) => Promise<void>;
  removeCategoryFromRelease: (releaseId: number) => Promise<void>;
  addReleaseToCategory: (release: Release) => Promise<void>;
  clearCategoryForRegion: () => Promise<void>;
};

type SortByProp = 'name' | 'created' | 'released';

const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  isLastItem = false
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 0,
  // padding: `0 ${grid * 2}px`,
  margin: isLastItem === true ? 0 : `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? '#f0f0f0' : '#fcfcfc',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#f3f3f3' : '#fff',
  border: '1px solid #d9d9d9',
  borderRadius: 2,
  // padding: grid,
  // width: 250,
});

//
//
const CategorySort = (props: Props) => {
  const {
    currentApp,
    currentCategory,
    currentRegionId,
    releasesNotInCategoryForRegion,
    filterReleases,
    regions,
    isSaving,
    removeCategoryFromRelease,
    clearCategoryForRegion,
    addReleaseToCategory,
    switchRegion,
  } = props;

  const [currentSearch, setCurrentSearch] = useState('');

  const [sortInfo, setSortInfo] = useState<{
    sortOrder: null | Array<number>;
    isInverted: boolean;
    sortedBy: SortByProp | null | undefined;
  }>({
    sortOrder: null,
    isInverted: false,
    sortedBy: undefined,
  });

  const backLink = getUrlByName(ROUTE_NAME_APP_CATEGORIES, {
    ':appUid': currentApp.uid,
  });

  // use the ids as string to trigger useEffect whenever the release selection changed
  const releaseIdsStr = filterReleases.map((rl) => rl.id).join(',');
  useEffect(() => {
    const { sortOrder } = sortInfo;
    const x = releaseIdsStr.split(',').map((idStr) => Number(idStr));
    if (sortOrder != null) {
      // an item was added while sort order was changed
      if (sortOrder.length < x.length) {
        const newItems: Array<number> = [];
        x.forEach((id) => {
          if (!sortOrder.includes(id)) {
            // add every new id at the beginning
            newItems.unshift(id);
          }
        });

        setSortInfo((info) => {
          const newOrder = [...newItems, ...sortOrder];
          console.log('newOrder', newOrder);
          info.sortOrder = newOrder;
          return info;
        });
      }
      // an item was removed while sort order was changed
      if (sortOrder.length > x.length) {
        console.log('items manually sorted ...  item removed', { sortOrder, releaseIdsStr });

        const newOrder = sortOrder.filter((id) => x.includes(id));
        console.log('newOrder', newOrder);
        setSortInfo((info) => {
          if (newOrder.length === 0) {
            return { sortOrder: null, isInverted: false, sortedBy: undefined };
          }
          info.sortOrder = newOrder;
          return info;
        });
      }
    }
  }, [releaseIdsStr, sortInfo]);

  const selectedKeys = currentRegionId !== null ? [currentRegionId.toString()] : [];
  let originalOrder = filterReleases.map((r) => r.id);
  if (sortInfo.sortOrder !== null) {
    originalOrder = sortInfo.sortOrder;
  }

  const handleReset = () => {
    setSortInfo({ sortOrder: null, isInverted: false, sortedBy: undefined });
  };

  const handleClickRegion = (e: { key: string }) => {
    console.log('handleClickRegion()', e.key);

    const { sortOrder } = sortInfo;

    const handleSwitchRegion = () => {
      handleReset();
      switchRegion(parseInt(e.key, 10));
    };

    if (sortOrder !== null) {
      confirm({
        title: 'You have unsaved changes!',
        content: 'If you switch regions, you will lose them!',
        onOk() {
          console.log('OK');
          handleSwitchRegion();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      handleSwitchRegion();
    }
  };

  const handleDragEnd = (info: DropResult) => {
    console.log('handleDragEnd()', info);

    if (info.destination == null || info.destination.index === info.source.index) {
      return;
    }

    const { sortOrder } = sortInfo;

    let newOrder;

    if (sortOrder === null) {
      newOrder = filterReleases.map((r) => r.id);
    } else {
      newOrder = [...sortOrder];
    }

    const [item] = newOrder.splice(info.source.index, 1);
    newOrder.splice(info.destination.index, 0, item);
    setSortInfo({ sortOrder: newOrder, isInverted: false, sortedBy: undefined });
  };

  const handleSaveSort = async () => {
    const { sortOrder } = sortInfo;
    console.log('handleSaveSort()', sortOrder);
    if (sortOrder !== null) {
      try {
        const { saveSortOrder } = props;
        await saveSortOrder(sortOrder);
        handleReset();
      } catch (err) {
        logUnknownError(err);
      }
    }
  };

  const handleSort = (val: string) => {
    const { isInverted } = sortInfo;
    const copy = [...filterReleases];

    switch (val) {
      case 'name':
        copy.sort(sortReleaseByEpisodeName);
        break;
      case 'created':
        copy.sort(sortByCreatedAt);
        break;
      case 'released':
        copy.sort(sortByPublishDate);
        break;

      default:
        console.log('unknown sort order', val);
        return;
    }

    const newOrder = copy.map((r) => r.id);
    if (isInverted) {
      newOrder.reverse();
    }
    setSortInfo({ ...sortInfo, sortOrder: newOrder, sortedBy: val });
  };

  const handleInvert = () => {
    const { isInverted, sortOrder } = sortInfo;

    let newOrder;
    if (sortOrder === null) {
      newOrder = filterReleases.map((r) => r.id);
    } else {
      newOrder = sortOrder;
    }
    newOrder.reverse();
    setSortInfo({ ...sortInfo, isInverted: !isInverted, sortOrder: newOrder });
  };

  return (
    <div>
      <CustomPageHeader
        title={
          <>
            <span>{currentCategory.name}</span>
            {currentCategory.isDraft && (
              <Tag color="orange" style={{ marginLeft: 10 }}>
                is draft
              </Tag>
            )}
          </>
        }
        backLink={backLink}
      />

      <Prompt
        when={sortInfo.sortOrder !== null}
        message={(locationNext, historyAction) =>
          // console.log('############################## PROMPT ##############################', {
          //   historyAction,
          //   message: 'test message',
          //   locationNext,
          // });
          JSON.stringify({
            historyAction,
            message:
              'Are you sure you want to leave this page? There are unsaved changes to the sort order.',
            locationNext,
          })
        }
      />

      {Array.isArray(regions) && regions.length > 1 && (
        <Menu onClick={handleClickRegion} selectedKeys={selectedKeys} mode="horizontal">
          {regions.map((r: Region) => (
            <Menu.Item key={r.id}>{r.name}</Menu.Item>
          ))}
        </Menu>
      )}

      <div
        style={{
          marginTop: '2em',
          marginBottom: '1em',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Select
            placeholder="Sort by"
            value={sortInfo.sortedBy ?? undefined}
            onChange={handleSort}
            style={{ width: 150 }}
            disabled={originalOrder.length < 2}
          >
            <Select.Option value="name">name</Select.Option>
            <Select.Option value="created">created</Select.Option>
            <Select.Option value="released">released</Select.Option>
          </Select>
          <Checkbox
            data-tid="categorysort-checkbox-invert"
            onChange={handleInvert}
            checked={sortInfo.isInverted}
            disabled={originalOrder.length < 2}
            style={{ marginLeft: '1em' }}
          >
            invert
          </Checkbox>
        </div>
        <div>
          <MbxButton
            data-tid="sort-reset-btn"
            size="middle"
            disabled={sortInfo.sortOrder === null && !isSaving}
            icon={<UndoOutlined />}
            onClick={handleReset}
          >
            reset
          </MbxButton>
          <MbxButton
            type="primary"
            size="middle"
            data-tid="sort-save-btn"
            disabled={sortInfo.sortOrder === null && !isSaving}
            icon={<SaveOutlined />}
            htmlType="submit"
            onClick={() => {
              handleSaveSort();
            }}
            style={{ marginLeft: '1em' }}
          >
            save
          </MbxButton>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '4em',
          marginBottom: '1em',
        }}
      >
        <AutoComplete
          id="autocomplete-add-releasetocategory"
          data-tid="autocomplete-add-releasetocategory"
          className="global-search"
          defaultActiveFirstOption={false}
          disabled={releasesNotInCategoryForRegion.length === 0}
          style={{ width: 250 }}
          onSelect={async (value: string | number) => {
            setCurrentSearch('');
            const release = releasesNotInCategoryForRegion.find((rl) => rl.id.toString() === value);
            if (release != null) {
              await addReleaseToCategory(release);
            }
          }}
          onChange={(value: string | number) => {
            setCurrentSearch(value.toString());
          }}
          value={currentSearch}
          options={releasesNotInCategoryForRegion.map((rl) => ({
            label: rl.episode.name,
            value: `${rl.id}`,
          }))}
          filterOption={(inputValue, option) => {
            // console.log('filterOption', { inputValue, option });
            const splits = inputValue.toLowerCase().split(' ');
            let found = true;
            splits.forEach((str) => {
              const label = (option?.label as string) ?? '';
              found = found && label.toLowerCase().indexOf(str) !== -1;
            });
            return found;
          }}
        >
          <Input
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            autoCapitalize="off"
            placeholder="add release to category"
          />
        </AutoComplete>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="sortCategoryList">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              <List
                data-tid="category-sort-list"
                bordered={false}
                dataSource={originalOrder}
                renderItem={(releaseId: number, index: number) => {
                  const release = filterReleases.find((r) => r.id === releaseId);
                  if (release == null) {
                    console.warn('release not found');
                    return null;
                  }

                  const releaseStatus = getReleaseStatus(release, null);
                  const episodeName = release?.episode?.name ?? '...';

                  return (
                    <Draggable
                      key={release.id.toString()}
                      draggableId={release.id.toString()}
                      index={index}
                    >
                      {(provided2, snapshot2) => (
                        <div
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                          style={getItemStyle(
                            snapshot2.isDragging,
                            provided2.draggableProps.style,
                            index === originalOrder.length - 1
                          )}
                        >
                          <List.Item
                            style={{ paddingRight: 2, paddingLeft: 10 }}
                            actions={[
                              <MbxButton
                                key="btn-remove-release-from-category"
                                size="small"
                                icon={<CloseOutlined />}
                                danger
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      `Really remove "${episodeName}" from category "${currentCategory.name}"`
                                    )
                                  ) {
                                    removeCategoryFromRelease(release.id);
                                  }
                                }}
                              >
                                remove from category
                              </MbxButton>,
                            ]}
                          >
                            <List.Item.Meta
                              title={
                                <>
                                  <span style={{ paddingRight: '1em' }}>{episodeName}</span>
                                  {release.isDraft && <Tag color="orange">draft</Tag>}
                                  {release.isDemoContent && <Tag color="green">free</Tag>}
                                </>
                              }
                              description={
                                <LocalReleaseDates
                                  release={release}
                                  releaseStatus={releaseStatus}
                                />
                              }
                              avatar={
                                <div style={{ display: 'flex' }}>
                                  <div
                                    style={{
                                      width: 12,
                                      marginRight: 8,
                                      position: 'relative',
                                    }}
                                  >
                                    {index + 1}
                                  </div>
                                  <ListItemType episode={release.episode} />
                                  <EpisodeThumbnail episode={release.episode} />
                                </div>
                              }
                            />
                          </List.Item>
                        </div>
                      )}
                    </Draggable>
                  );
                }}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {originalOrder.length > 1 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1em',
            marginBottom: '2em',
          }}
        >
          <MbxButton
            data-tid="btn-remove-all"
            size="small"
            danger
            icon={<CloseOutlined />}
            onClick={() => {
              if (
                window.confirm('Really remove all releases from this category?\nCannot be undone!')
              ) {
                clearCategoryForRegion();
              }
            }}
          >
            Remove all releases in this region from category
          </MbxButton>
        </div>
      )}

      <LocalDateFootnote />
    </div>
  );
};

export default CategorySort;
