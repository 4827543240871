import { createSelector } from 'reselect';

import { sortByName } from '../../utils/sort';

import type { RootState } from '../../reducers';
import type { RegionNormalized } from '../../reducers/RegionReducer';

//
//
export const regionsById = (state: RootState) => state.regions.byId;
export const regionsAllIds = (state: RootState) => state.regions.allIds;

//
//
export const getRegionNormalizedByIdFunc = createSelector(
  regionsById,
  //
  (byId) => (regionId: number) => {
    const region = byId[regionId];
    if (region == null) {
      return null;
    }
    return region;
  }
);

//
//
// export const getRegionNormalizedById: (
//   GlobalState,
//   number
// ) => RegionNormalized | null = createSelector(
//   getRegionNormalizedByIdFunc,
//   (_, regionId) => regionId,
//   //
//   ($getRegionNormalizedById, regionId) => $getRegionNormalizedById(regionId)
// );

//
//
export const getAllRegionsNormalizedForAppFunc = createSelector(
  regionsAllIds,
  getRegionNormalizedByIdFunc,
  //
  (allIds, $getRegionNormalizedById) => (appId: number) => {
    const ids = allIds[appId];
    if (!Array.isArray(ids)) {
      return [];
    }
    const regions = ids
      .map((id) => $getRegionNormalizedById(id))
      .filter((rn): rn is RegionNormalized => !!rn);
    regions.sort(sortByName);
    return regions;
  }
);

//
//
// export const getAllRegionsForApp: (GlobalState, number) => Region[] = createSelector(
//   getAllRegionsForAppFunc,
//   (_, id) => id,
//   //
//   (getAllRegionsForApp, id) => getAllRegionsForApp(id)
// );
