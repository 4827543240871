import classNames from 'classnames';

import styles from './AnalyticsStats.module.scss';
import { getTrendString, getTrendType, TrendTypes } from '../../utils/analytics';

type Props = {
  box1Headline: string;
  box1Value: number;
  box2Headline: string;
  box2Value: number;
  box2Trend: number | null;
  box3Headline?: string;
  box3Value?: number;
  testIdPrefix: string;
};

const AnalyticsStats = ({
  box1Headline,
  box1Value,
  box2Headline,
  box2Value,
  box2Trend,
  box3Headline,
  box3Value,
  testIdPrefix,
}: Props) => (
  <div style={{ flexShrink: 0 }}>
    <div className={styles.box} data-tid={`${testIdPrefix}-total`}>
      <span className={styles.headline}>{box1Headline}</span>
      <span className={styles.totalNumber}>{box1Value.toLocaleString()}</span>
    </div>
    <div className={styles.box} data-tid={`${testIdPrefix}-trend`}>
      <span className={styles.headline}>{box2Headline}</span>
      <div className={styles.trendContainer}>
        <div>
          <span className={styles.trendNumber}>{box2Value.toLocaleString()}</span>
        </div>
        {box2Trend != null && box2Trend !== 0 && (
          <div data-tid={`${testIdPrefix}-trend-color-${getTrendType(box2Trend)}`}>
            <span
              className={classNames(styles.trend, {
                [styles.trendUp]: getTrendType(box2Trend) === TrendTypes.UP,
                [styles.trendDown]: getTrendType(box2Trend) === TrendTypes.DOWN,
              })}
            >
              {getTrendString(box2Trend)}
            </span>
          </div>
        )}
      </div>
      {box3Headline != null && box3Value != null && (
        <>
          <hr style={{ border: 'none', borderTop: '1px solid lightgrey' }} />
          <span className={styles.headline}>{box3Headline}</span>
          <div className={styles.trendContainer}>
            <div>
              <span className={styles.trendNumber}>{box3Value.toLocaleString()}</span>
            </div>
          </div>
        </>
      )}
    </div>
  </div>
);

export default AnalyticsStats;
