import { createAction } from '@reduxjs/toolkit';
import { schema, normalize } from 'normalizr';

import { TwofaForceReasons } from '../common/constants/auth';
import {
  AuthServiceType,
  AuthServiceTypeNames,
  DEFAULT_AUTHSERVICE_NAME,
} from '../common/constants/authservice-type';
import { GET_APPS_LIST_OK } from '../constants/apps';
import {
  LOGOUT,
  LOGOUT_OK,
  LOGOUT_FAIL,
  LOGIN,
  LOGIN_OK,
  LOGIN_FAIL,
  // LOGINCHECK,
  // LOGINCHECK_OK,
  // LOGINCHECK_FAIL,
  TWOFA_SETUP,
  TWOFA_SETUP_OK,
  TWOFA_SETUP_FAIL,
  TWOFA_ACTIVATE,
  TWOFA_ACTIVATE_OK,
  TWOFA_ACTIVATE_FAIL,
  TWOFA_DEACTIVATE,
  TWOFA_DEACTIVATE_OK,
  TWOFA_DEACTIVATE_FAIL,
  LOGIN_TWOFA_STEP,
  LOGIN_TWOFA_RESET,
} from '../constants/auth';
import { PLATFORMS_GET_LIST_OK } from '../constants/platforms';
import { UserSchema, AppSchema, PlatformSchema } from '../schemas';
import { isUserSuperadmin } from '../selectors/authSelectors';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';
import type { GlobalStateGetter } from '../reducers';
import type { App } from '../reducers/AppsReducer';
import type { UserInvite } from '../reducers/InviteReducer';
import type { Platform } from '../reducers/PlatformReducer';
import type { User } from '../reducers/UsersReducer';

//
//
export const openTwofaStep = createAction(LOGIN_TWOFA_STEP);

//
//
export const resetTwofa = createAction(LOGIN_TWOFA_RESET);

//
//
// export const loginV2 = createAsyncThunk(
//   LOGIN,
//   async (data: { email: string; password: string }, thunkAPI) => {
//     try {
//       const dispatch = thunkAPI.dispatch as AppDispatch;
//       const { email, password } = data;
//       const response = await wrapFetchV2<{ redirectTwofa?: boolean }>({
//         url: `/auth/login`,
//         method: 'POST',
//         data: {
//           loginType: AuthServiceTypeNames[AuthServiceType.USERNAME_PASSWORD],
//           authServiceName: DEFAULT_AUTHSERVICE_NAME,
//           email,
//           password,
//         },
//       });

//       // user has 2FA activated and we can't just log the user in but have to ask for a code first
//       if (response.data.redirectTwofa === true) {
//         dispatch(openTwofaStep());
//         // dispatch({ type: LOGIN_TWOFA_STEP, payload: response.data });
//         return;
//       }
//     } catch (err) {
//       console.error(err);
//       throw new Error('error fetching users');
//     }
//   }
// );

//
//
export const login = (email: string, password: string) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch<{
    redirectTwofa?: boolean;
    apps?: Array<App>;
    platforms?: Array<Platform>;
    user?: User;
    redirectForceTwofa?: number;
    accessToken?: string;
    authServiceName?: string;
    invites?: Array<UserInvite>;
  }>(
    {
      url: `/auth/login`,
      method: 'POST',
      data: {
        loginType: AuthServiceTypeNames[AuthServiceType.USERNAME_PASSWORD],
        authServiceName: DEFAULT_AUTHSERVICE_NAME,
        email,
        password,
      },
    },
    dispatch,
    { init: LOGIN, fail: LOGIN_FAIL }
  );

  // user has 2FA activated and we can't just log the user in but have to ask for a code first
  if (response.data.redirectTwofa === true) {
    dispatch({ type: LOGIN_TWOFA_STEP, payload: response.data });
    return;
  }

  // login was successful w/o 2FA
  if (typeof response.data.apps !== 'undefined') {
    const normalizedApps = normalize(response.data.apps, new schema.Array(AppSchema));
    dispatch({ type: GET_APPS_LIST_OK, payload: normalizedApps });
  }

  if (response.data.platforms != null) {
    const normalizedPlatforms = normalize(
      response.data.platforms,
      new schema.Array(PlatformSchema)
    );
    dispatch({ type: PLATFORMS_GET_LIST_OK, payload: normalizedPlatforms });
  }

  const normalizedData = normalize(response.data.user, UserSchema);
  let redirectForceTwofa = Number(response.data.redirectForceTwofa);
  if (Number.isNaN(redirectForceTwofa)) {
    redirectForceTwofa = 0;
  }

  dispatch({
    type: LOGIN_OK,
    payload: normalizedData,
    accessToken: response.data.accessToken,
    authServiceName: response.data.authServiceName,
    invites: response.data.invites ?? [],
    redirectForceTwofa,
  });
};

//
//
export const loginTwofa = (accessToken: string, code: string) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch<{
    accessToken?: string;
    apps?: Array<App>;
    authServiceName?: string;
    invites?: Array<UserInvite>;
    platforms?: Array<Platform>;
    user?: User;
  }>(
    {
      url: `/auth/login-2fa`,
      method: 'POST',
      data: {
        accessToken,
        code,
      },
    },
    dispatch,
    { init: LOGIN, fail: LOGIN_FAIL }
  );
  // login was successful w/ 2FA code
  if (typeof response.data.apps !== 'undefined') {
    const normalizedApps = normalize(response.data.apps, new schema.Array(AppSchema));
    dispatch({ type: GET_APPS_LIST_OK, payload: normalizedApps });
  }

  if (typeof response.data.platforms !== 'undefined') {
    const normalizedPlatforms = normalize(
      response.data.platforms,
      new schema.Array(PlatformSchema)
    );
    dispatch({ type: PLATFORMS_GET_LIST_OK, payload: normalizedPlatforms });
  }

  const normalizedData = normalize(response.data.user, UserSchema);
  dispatch({
    type: LOGIN_OK,
    payload: normalizedData,
    redirectForceTwofa: 0,
    accessToken: response.data.accessToken,
    authServiceName: response.data.authServiceName,
    invites: response.data.invites ?? [],
  });
};

//
//
export const getLoginData = () => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/auth/login-check`,
      method: 'POST',
    },
    dispatch,
    { init: LOGIN, fail: LOGIN_FAIL }
  );
  // login was successful w/ 2FA code
  if (typeof response.data.apps !== 'undefined') {
    const normalizedApps = normalize(response.data.apps, new schema.Array(AppSchema));
    dispatch({ type: GET_APPS_LIST_OK, payload: normalizedApps });
  }

  if (typeof response.data.platforms !== 'undefined') {
    const normalizedPlatforms = normalize(
      response.data.platforms,
      new schema.Array(PlatformSchema)
    );
    dispatch({ type: PLATFORMS_GET_LIST_OK, payload: normalizedPlatforms });
  }

  const normalizedData = normalize(response.data.user, UserSchema);
  dispatch({
    type: LOGIN_OK,
    loginUrl: response.data.loginUrl,
    payload: normalizedData,
    redirectForceTwofa: 0,
    accessToken: response.data.accessToken,
    authServiceName: response.data.authServiceName,
    invites: response.data.invites ?? [],
  });
};

//
//
export const logout = () => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/auth/logout`,
    },
    dispatch,
    { init: LOGOUT, fail: LOGOUT_FAIL },
    204
  );
  dispatch({ type: LOGOUT_OK, redirectUrl: response.headers?.location ?? null });
  return true;
};

//
//
export const twofaSetup = () => async (dispatch: AppDispatch) => {
  const response = await wrapFetch<{ url: string; overwrite: boolean }>(
    {
      url: `/auth/2fa`,
    },
    dispatch,
    { init: TWOFA_SETUP, fail: TWOFA_SETUP_FAIL }
  );
  dispatch({ type: TWOFA_SETUP_OK });
  return response.data;
};

//
//
export const twofaActivate = (code: string, password: string) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/auth/2fa`,
      method: 'POST',
      data: {
        password,
        code,
      },
    },
    dispatch,
    { init: TWOFA_ACTIVATE, fail: TWOFA_ACTIVATE_FAIL },
    201
  );
  dispatch({ type: TWOFA_ACTIVATE_OK });
  return true;
};

//
//
export const twofaDeactivate = () => async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
  await wrapFetch(
    {
      url: `/auth/2fa`,
      method: 'DELETE',
    },
    dispatch,
    { init: TWOFA_DEACTIVATE, fail: TWOFA_DEACTIVATE_FAIL },
    204
  );
  const state = getState();
  const isSuperadmin = isUserSuperadmin(state);
  dispatch({
    type: TWOFA_DEACTIVATE_OK,
    redirectForceTwofa: isSuperadmin ? TwofaForceReasons.IS_SUPERADMIN : 0,
  });
  return true;
};

//
//
export const checkPasswordToken = (token: string) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch<{ email: string }>(
    {
      url: `/auth/passwordreset/${token}`,
      method: 'GET',
    },
    dispatch,
    null,
    200
  );
  return response.data;
};

//
//
export const askForPasswordReset = (email: string) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/auth/passwordreset`,
      method: 'POST',
      data: {
        email,
      },
    },
    dispatch,
    null,
    201
  );
  return true;
};

//
//
export const resetPassword =
  (token: string, newPassword: string) => async (dispatch: AppDispatch) => {
    await wrapFetch(
      {
        url: `/auth/passwordreset/${token}`,
        method: 'PUT',
        data: {
          password: newPassword,
        },
      },
      dispatch,
      null,
      204
    );
    return true;
  };
