import { UploadOutlined, CloseOutlined } from '@ant-design/icons';
import { Modal, Input, Upload, Button, message } from 'antd';
import { fromBlob } from 'file-type/browser';
import { useState } from 'react';

import styles from './EpisodeLocalizedAddModal.module.scss';
import Thumbnail from './Thumbnail';
import { removeIcon } from '../actions/apps';
import { addUploadToQueue, checkNextInQueue } from '../actions/queue';
import { ResourceType } from '../common/constants/resource-type';
import { QueueItemType } from '../reducers/QueueReducer';
import { getAllQueueItems } from '../selectors/queueSelectors';
import { formatDate } from '../utils/date';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';
import { getFileUploadStatusForResource } from '../utils/queue';

import type { CustomEventTarget } from './EpisodeLocalizedForm';
import type { App } from '../reducers/AppsReducer';
import type { Resource } from '../reducers/ResourceReducer';
import type { DragEvent } from 'react';

//
//
const DragButton = ({
  disabled,
  onDrop,
}: {
  disabled: boolean;
  onDrop: (e: DragEvent) => void;
}) => {
  const [draggingState, setDraggingState] = useState<boolean>(false);
  return (
    <Button
      data-dragdropenabled
      onDragEnter={() => {
        console.log('onDragEnter()', disabled);
        if (!disabled) {
          setDraggingState(true);
        }
      }}
      onDragLeave={() => {
        console.log('onDragLeave()', disabled);
        if (disabled) {
          setDraggingState(false);
        }
      }}
      onDrop={(e) => {
        if (!disabled) {
          setDraggingState(false);
        }
        onDrop(e);
      }}
      style={{
        height: 90,
      }}
      className={draggingState === true ? styles.isDragging : undefined}
      disabled={disabled}
      icon={<UploadOutlined />}
    >
      select file
    </Button>
  );
};

type Props = Readonly<{
  app: App;
  visible: boolean;
  onClose: () => void;
}>;

//
//
const UploadIconModal = ({ app, visible, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [wrongFormatError, setWrongFormatError] = useState<string>('');
  const [isPreparingUpload, setIsPreparingUpload] = useState<boolean>(false);

  const { id: appId, icon } = app;
  const maxFileSizeInKb = 100000;
  const fileTypes: Array<string> = ['image/png', 'image/jpeg'];
  const contentUploadAccept: string = fileTypes.join(',');
  const thumbnailInfo = getFileUploadStatusForResource({
    id: icon?.id || null,
    resource: icon as Resource,
    queuedItems: useGlobalSelector(getAllQueueItems),
  });
  const uploadIsDisabled = icon !== null;

  const onRemove = async () => {
    try {
      await dispatch(removeIcon(appId));
      dispatch(checkNextInQueue());
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
    return false;
  };

  const validateFile = (mime: string, size: number) => {
    setWrongFormatError('');

    if (!fileTypes.includes(mime)) {
      console.error('selected wrong format for contentType', mime, fileTypes);
      setWrongFormatError(
        `Incorrect mime-type of file: ${
          mime != '' ? mime : 'UNKNOWN'
        }. Accepted mime-types: ${fileTypes.join(', ')}`
      );
      return false;
    }

    if (Math.round(size / 1024) > maxFileSizeInKb) {
      console.error('file too large', mime, fileTypes);
      setWrongFormatError(
        `File is too large for this field. Max file size is ${maxFileSizeInKb} Kb`
      );
      return false;
    }

    return true;
  };

  const queueFileUpload = async (file: File) => {
    console.log('queueFileUpload()', file);

    setIsPreparingUpload(true);
    try {
      dispatch(
        addUploadToQueue(
          {
            appId,
            resourceType: ResourceType.APP_ICON,
            fileRef: {
              name: file.name,
              type: file.type,
              size: file.size,
            },
            appPlatformId: null,
            type: QueueItemType.ICON,
          },
          file
        )
      );
      await dispatch(checkNextInQueue());
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    } finally {
      setIsPreparingUpload(false);
    }
  };

  const handleFileSelect = (file: File) => {
    if (!validateFile) {
      return;
    }
    queueFileUpload(file);
  };

  const handleClose = () => {
    setWrongFormatError('');
    onClose();
  };

  const onDrop = (e: DragEvent) => {
    const fileList = e.dataTransfer?.files;
    console.log('onDrop()', fileList);

    e.preventDefault();
    e.stopPropagation();

    if (fileList == null || fileList.length > 1) {
      message.error(`You can only drop one file of type ${contentUploadAccept}`);
      return;
    }

    const reader = new FileReader();
    reader.onload = async (ev) => {
      console.log('onload', ev);
      if (ev.currentTarget === null) {
        const { msg } = logUnknownError('onload() currentTarget not set');
        message.error(msg);
        return;
      }
      const blob = new Blob([(ev.currentTarget as CustomEventTarget).result]);
      const mime = (await fromBlob(blob))?.mime;
      if (!mime || !validateFile(mime, blob.size)) {
        return;
      }
      console.log('uploading');
      queueFileUpload(fileList[0]);
    };

    // we only need the first 4100 bytes to get the mime-type
    // taken from here: https://github.com/sindresorhus/file-type/blob/5de41fd05c9623e72585abb3c79ddbafafad07ea/core.js#L11
    const blob = fileList[0].slice(0, 4100);
    reader.readAsArrayBuffer(blob);
  };

  return (
    <Modal
      width={570}
      title={
        <>
          <span className={styles.listItemTitle}>Upload an icon</span>
        </>
      }
      centered
      open={visible}
      footer={null}
      onCancel={handleClose}
    >
      <div style={{ display: 'flex', position: 'relative' }}>
        <Thumbnail
          thumbnail={icon !== null ? { ...icon, isPrivate: true } : null}
          thumbnailInfo={thumbnailInfo}
          width={160}
          height={90}
        />
        <div style={{ marginLeft: 10 }}>
          <Upload
            accept={contentUploadAccept}
            disabled={uploadIsDisabled || isPreparingUpload}
            multiple={false}
            showUploadList={false}
            beforeUpload={handleFileSelect}
            customRequest={() => true}
          >
            <DragButton disabled={uploadIsDisabled || isPreparingUpload} onDrop={onDrop} />
          </Upload>
        </div>
        <div style={{ marginLeft: 10, lineHeight: 1, width: '100%' }}>
          <div style={{ marginBottom: 10 }}>
            <Input disabled value={icon?.file?.originalName} />
          </div>
          <Button
            onClick={onRemove}
            danger
            disabled={icon === null}
            icon={<CloseOutlined />}
            style={{ marginRight: 10 }}
            data-tid={'remove-app-icon-button'}
          >
            remove
          </Button>
        </div>

        {icon != null && (
          <span
            style={{
              display: 'inline-block',
              position: 'absolute',
              top: '-18px',
              right: 0,
              fontSize: '10px',
            }}
          >
            {`last updated ${formatDate(icon?.updatedAt)}`}
          </span>
        )}
      </div>
      {wrongFormatError != '' && (
        <div
          style={{
            display: 'block',
            width: '100%',
            marginTop: '4px',
            color: 'red',
            fontWeight: 'bold',
            fontSize: '10px',
          }}
        >
          {wrongFormatError}
        </div>
      )}
    </Modal>
  );
};

export default UploadIconModal;
