import { normalize } from 'normalizr';

import {
  EPISODEVERSIONREQUIREMENTS_CREATE,
  EPISODEVERSIONREQUIREMENTS_CREATE_OK,
  EPISODEVERSIONREQUIREMENTS_CREATE_FAIL,
  EPISODEVERSIONREQUIREMENTS_DELETE,
  EPISODEVERSIONREQUIREMENTS_DELETE_OK,
  EPISODEVERSIONREQUIREMENTS_DELETE_FAIL,
  EPISODEVERSIONREQUIREMENTS_UPDATE,
  EPISODEVERSIONREQUIREMENTS_UPDATE_OK,
  EPISODEVERSIONREQUIREMENTS_UPDATE_FAIL,
} from '../constants/episodeVersionRequirements';
import { EpisodeVersionRequirementSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const createEpisodeVersionRequirement =
  (
    episodeId: number,
    appPlatformId: number,
    exclude: boolean,
    minVersionId: number | null,
    maxVersionId: number | null
  ) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/episodeversionrequirements`,
        method: 'POST',
        data: {
          episodeId,
          appPlatformId,
          exclude,
          minVersionId,
          maxVersionId,
        },
      },
      dispatch,
      {
        init: EPISODEVERSIONREQUIREMENTS_CREATE,
        fail: EPISODEVERSIONREQUIREMENTS_CREATE_FAIL,
      },
      201
    );
    const normalizedData = normalize(response.data, EpisodeVersionRequirementSchema);
    dispatch({ type: EPISODEVERSIONREQUIREMENTS_CREATE_OK, payload: normalizedData, episodeId });
  };

//
//
export const updateEpisodeVersionRequirement =
  (
    episodeVersionRequirementId: number,
    exclude: boolean,
    minVersionId: number | null,
    maxVersionId: number | null
  ) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/episodeversionrequirements/${episodeVersionRequirementId}`,
        method: 'PUT',
        data: {
          exclude,
          minVersionId,
          maxVersionId,
        },
      },
      dispatch,
      {
        init: EPISODEVERSIONREQUIREMENTS_UPDATE,
        fail: EPISODEVERSIONREQUIREMENTS_UPDATE_FAIL,
      }
    );
    const normalizedData = normalize(response.data, EpisodeVersionRequirementSchema);
    dispatch({ type: EPISODEVERSIONREQUIREMENTS_UPDATE_OK, payload: normalizedData });
  };

//
//
export const deleteEpisodeVersionRequirement =
  (episodeVersionRequirementId: number) => async (dispatch: AppDispatch) => {
    await wrapFetch(
      {
        url: `/episodeversionrequirements/${episodeVersionRequirementId}`,
        method: 'DELETE',
      },
      dispatch,
      {
        init: EPISODEVERSIONREQUIREMENTS_DELETE,
        fail: EPISODEVERSIONREQUIREMENTS_DELETE_FAIL,
      },
      204
    );

    dispatch({ type: EPISODEVERSIONREQUIREMENTS_DELETE_OK, episodeVersionRequirementId });
  };
