import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { twofaSetup, twofaActivate } from '../actions/auth';
import { ROUTE_NAME_PROFILE } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import Profile2faSetup from '../pages/Profile2faSetup';
import { getUserProfile } from '../selectors/authSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

//
//
const Profile2faSetupContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const profile = useGlobalSelector(getUserProfile);

  const [qrCodeData, setQrCodeData] = useState<null | { url: string; overwrite: boolean }>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const qrCode = await dispatch(twofaSetup());
        setQrCodeData(qrCode);
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      }
    };
    fetchUserData();
  }, [dispatch]);

  const handleTwofaActivate = async (values: { code: string; password?: string }) => {
    try {
      const { code, password } = values;
      if (password == null) {
        throw new Error('password not set');
      }
      await dispatch(twofaActivate(code, password));
      message.success('2FA setup successfully');
      history.push(getUrlByName(ROUTE_NAME_PROFILE));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  return (
    <Profile2faSetup
      profile={profile}
      qrCodeData={qrCodeData}
      twofaActivate={handleTwofaActivate}
    />
  );
};

export default Profile2faSetupContainer;
