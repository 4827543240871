import { ContentType } from '../constants/content-type';
import { FileStatus } from '../constants/file-status';
import { ResourceType } from '../constants/resource-type';

import type {
  ReleaseMetadata,
  Region,
  Release,
  ReleaseStatus,
  Requirements,
  HowMany,
  ReleaseStatusState,
  LocalizedEpisode,
  Reason,
} from '../types/release';

//
//
export const SCHEDULED = 'scheduled';
export const LIVE = 'live';
export const EXPIRED = 'expired';

//
//
export function filterLiveFeedReleases(release: { metadata: ReleaseMetadata }) {
  const thumbnailId = release?.metadata?.thumbnailId ?? null;
  if (thumbnailId == null) {
    return false;
  }
  // XXX add more checks
  return true;
}

//
//
export function checkIfReleasable(
  requirements: Requirements,
  howMany: HowMany,
  isOptional = false
) {
  let releasable: ReleaseStatusState = true;
  try {
    (Object.keys(requirements) as Array<keyof Requirements>).forEach((k) => {
      const req = requirements[k];
      if (typeof req === 'number') {
        const check = req === howMany[k];
        if (check === false) {
          throw new Error(`check (1) failed for ${k}`);
        }
      } else if (req.min != null && req.max != null) {
        const check = howMany[k] >= req.min && howMany[k] <= req.max;
        if (check === false) {
          throw new Error(`check (2) failed for ${k}`);
        }
      } else {
        throw new Error(`unknown req check for ${k}`);
      }
    });
  } catch (err) {
    releasable = isOptional === true ? 'incomplete' : false;
  }
  return releasable;
}

export function checkReleasesRequirements({
  localizedEpisode,
  resourceTypeId,
  appPlatformsForAppCount,
  isFallback,
}: {
  localizedEpisode: LocalizedEpisode;
  resourceTypeId?: number;
  appPlatformsForAppCount: number;
  isFallback: boolean;
}) {
  const reasons: Array<Reason> = [];
  let releasable: ReleaseStatusState = false;
  const requirements = {
    [ContentType.EPISODE_THUMBNAIL]: 1,
    [ContentType.EPISODE_CONTENT]: {
      min: 1,
      max: 1,
    },
    [ContentType.EPISODE_LAUNCHIMAGE]: 0,
  };

  // ARCHIVE_UNITYBUNDLE has max content based on appPlatforms
  if (resourceTypeId === ResourceType.ARCHIVE_UNITYBUNDLE) {
    (requirements[ContentType.EPISODE_CONTENT] as { max: number }).max =
      appPlatformsForAppCount === 0 ? 1 : appPlatformsForAppCount;
  }

  // couple of resource-types need a launchImage
  if (
    resourceTypeId === ResourceType.ARCHIVE_WEBPAGE ||
    resourceTypeId === ResourceType.ARCHIVE_UNITYBUNDLE
  ) {
    requirements[ContentType.EPISODE_LAUNCHIMAGE] = 1;
  }

  const howMany = {
    [ContentType.EPISODE_THUMBNAIL]: 0,
    [ContentType.EPISODE_CONTENT]: 0,
    [ContentType.EPISODE_LAUNCHIMAGE]: 0,
  };

  let foundLeData = false;

  localizedEpisode.content?.forEach((episodeContent) => {
    foundLeData = true;
    if (
      episodeContent.contentTypeId === ContentType.EPISODE_THUMBNAIL ||
      episodeContent.contentTypeId === ContentType.EPISODE_LAUNCHIMAGE ||
      episodeContent.contentTypeId === ContentType.EPISODE_CONTENT
    ) {
      const versions = episodeContent.resource?.file?.versions ?? [];

      if (versions.length > 0) {
        let allVersionsReady = true;
        versions.some((v) => {
          const isReady = v.lastStatusId === FileStatus.READY;
          if (isReady === false) {
            reasons.push({
              versionId: v.id,
              msg: `${episodeContent.resource?.file?.originalName ?? '??'} not READY`,
            });
          }
          allVersionsReady = allVersionsReady && isReady;
          return !isReady;
        });

        if (allVersionsReady === true) {
          howMany[episodeContent.contentTypeId] += 1;
        }
      }
    }
  });

  if ((foundLeData as boolean) === true) {
    releasable = checkIfReleasable(requirements, howMany, isFallback === false);
  } else {
    releasable = false;
  }
  return { releasable, reasons };
}

//
//
export function getReleaseStatus(
  release: Release,
  region?: Region | null,
  appPlatformsCount = 0
): ReleaseStatus {
  const now = new Date();
  const start =
    typeof release.publishDate === 'string' ? new Date(release.publishDate) : release.publishDate;
  const end =
    typeof release.unpublishDate === 'string'
      ? new Date(release.unpublishDate)
      : release.unpublishDate;

  // console.log('release.episode', JSON.stringify(release.episode));

  const status: ReleaseStatus = {
    publishDateStatus: LIVE,
    // releaseStatus: release.episode?.localizedEpisodes?.length === 0 ? -1 : 0,
    releasable: 'unknown',
    localeStatus: [],
  };

  if (start !== null && start > now) {
    status.publishDateStatus = SCHEDULED;
  } else if (end === null) {
    status.publishDateStatus = LIVE;
  } else if (end < now) {
    status.publishDateStatus = EXPIRED;
  }

  if (region != null) {
    // console.log('region', region);
    const allNonDraftRegionLocales =
      region.regionLocales?.filter((rl) => rl.isDraft === false) ?? [];
    // console.log('allNonDraftRegionLocales', allNonDraftRegionLocales);
    allNonDraftRegionLocales.forEach((rl) => {
      let releasable: ReleaseStatusState =
        release.episode?.resourceTypeId != null ? false : 'unknown';
      let reasons: Array<Reason> = [];
      const isFallback = region.useDefaultAsFallback === true && region.defaultLocaleId === rl.id;

      const le = release?.episode?.localizedEpisodes?.find(
        (le1) => le1?.locale?.id === rl.locale?.id
      );
      if (le != null) {
        const checkedData = checkReleasesRequirements({
          appPlatformsForAppCount: appPlatformsCount,
          isFallback,
          localizedEpisode: le,
          resourceTypeId: release?.episode?.resourceTypeId,
        });

        if (checkedData.reasons.length > 0) {
          reasons = checkedData.reasons;
        }
        releasable = checkedData.releasable;
      }

      status.localeStatus.push({
        id: rl.id,
        shortcode: rl.locale?.shortcode ?? '??',
        releasable,
        isFallback,
        reasons,
        leCreated: le != null,
      });
    });

    // the episode's resourceTypeId attribute is only set _after_ we queried for the episode's metadata
    // and we have enough info to tell the user if this item is releasable or not. if it is not set
    // just use releasable=true to not flash the red exclamationmark for every release even though
    // everything is fine but the metadata is just not loaded yet
    if (release.episode?.resourceTypeId != null) {
      let isFallbackFilled = false;
      status.localeStatus.forEach((ls) => {
        if (isFallbackFilled === true) {
          return;
        }

        if (ls.releasable === true) {
          if (ls.isFallback === true) {
            isFallbackFilled = true;
            status.releasable = true;
          }

          if (status.releasable === 'unknown') {
            status.releasable = true;
          } else if (status.releasable === false) {
            status.releasable = 'incomplete';
          }
        }
        //
        else if (ls.releasable === 'unknown') {
          if (status.releasable !== 'unknown') {
            status.releasable = 'incomplete';
          }
        }
        //
        else if (ls.releasable === false) {
          if (status.releasable === 'unknown') {
            status.releasable = false;
          } else if (status.releasable === true) {
            status.releasable = 'incomplete';
          }
        }
      });
      // const foundUnreleasableLE = status.localeStatus.some(
      //   (ls) => ls.releasable === false || ls.releasable === 'unknown'
      //   // (ls) => ls.leCreated === true && (ls.releasable === false || ls.releasable === 'unknown')
      // );
      // status.releasable =
      //   release.episode?.localizedEpisodes?.length > 0 && foundUnreleasableLE === false;
    }
  }

  // console.log(`getReleaseStatus(${release.episode.name})`, status);
  return status;
}
