import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Tag, Pagination } from 'antd';

import styles from './AssetList.module.scss';
import { canBeSetActive } from '../common/utils/asset';
import ActiveAssetPatchList from '../components/ActiveAssetPatchList';
import MbxButton from '../components/base/MbxButton';
import CustomPageHeader from '../components/CustomPageHeader';
import FilterDisplay from '../components/FilterDisplay';
import ListSearchInput from '../components/ListSearchInput';
import { useCurrentAppContext } from '../contexts';
import { formatDate } from '../utils/date';

import type { SearchItem } from '../components/ListSearchInput';
import type { Asset } from '../reducers/AssetReducer';

type Props = Readonly<{
  data: Array<Asset>;
  dataActive: Array<Asset>;
  allAssets: Array<Asset>;
  currentPage: number;
  currentSearch: string;
  itemsPerPage: number;
  totalItems: number;
  searchData: Array<SearchItem>;
  handleOpenDetails: (assetId: number) => void;
  handleToggleActive: (asset: Asset) => Promise<void>;
  handleOpenCreate: () => void;
  handlePaginationChange: (page: number) => void;
  handleSearchClose: (item: SearchItem) => void;
  handleAssetDelete: (assetId: number) => Promise<void>;
  handleSelect: (value: string | number) => void;
  handleFilter: (q: string) => void;
  handleUpdateActiveSort: (assets: Array<{ id: number; ordinal: number }>) => Promise<void>;
}>;

//
//
const AssetList = (props: Props) => {
  const {
    data,
    dataActive,
    currentPage,
    currentSearch,
    totalItems,
    itemsPerPage,
    handlePaginationChange,
    handleSearchClose,
    handleOpenCreate,
    handleOpenDetails,
    handleToggleActive,
    handleAssetDelete,
    searchData,
    handleSelect,
    handleFilter,
    handleUpdateActiveSort,
  } = props;
  const { currentApp } = useCurrentAppContext();

  const searchItems: Array<SearchItem> = [];
  if (currentSearch !== '') {
    searchItems.push({ type: 'search', value: currentSearch, text: currentSearch });
  }

  const deleteAsset = (asset: Asset) => {
    if (window.confirm(`Really delete ${asset.name}?`)) {
      handleAssetDelete(asset.id);
    }
  };

  return (
    <div>
      <CustomPageHeader
        title={`Asset patches for ${currentApp.name}`}
        extra={
          <MbxButton
            mbxType="primary"
            onClick={handleOpenCreate}
            icon={<PlusOutlined />}
            data-tid="btn-add-patch"
          >
            add patch
          </MbxButton>
        }
      />

      <ActiveAssetPatchList
        dataActive={dataActive}
        handleOpenDetails={handleOpenDetails}
        handleToggleActive={handleToggleActive}
        handleUpdateActiveSort={handleUpdateActiveSort}
      />

      <div
        style={{
          marginTop: '2em',
          marginBottom: '2em',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Pagination
            simple
            hideOnSinglePage
            current={currentPage}
            total={totalItems}
            pageSize={itemsPerPage}
            onChange={handlePaginationChange}
          />
        </div>

        <ListSearchInput
          id="asset-list-search"
          dataSource={searchData}
          filterOption={(inputValue, option) => {
            const label = (option?.label as string) ?? '';
            return label.toLowerCase().indexOf(inputValue.toString().toLowerCase()) !== -1;
          }}
          handleSelect={handleSelect}
          handleButtonClick={handleFilter}
        />
      </div>

      <div
        data-tid="filter-options"
        style={{
          marginTop: '2em',
          marginBottom: '2em',
        }}
      >
        <FilterDisplay type="search" items={searchItems} onClose={handleSearchClose} />
      </div>

      <List
        data-tid="list-assets"
        bordered={false}
        dataSource={data}
        renderItem={(asset: Asset) => {
          const actions = [
            <MbxButton
              key="btn-edit-asset"
              data-tid="btn-edit-asset"
              size="small"
              icon={<EditOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                handleOpenDetails(asset.id);
              }}
            />,
            <MbxButton
              key="btn-delete-asset"
              data-tid="btn-delete-asset"
              size="small"
              danger
              icon={<DeleteOutlined />}
              disabled={asset.isActive}
              onClick={(e) => {
                e.stopPropagation();
                deleteAsset(asset);
              }}
            />,
          ];

          actions.unshift(
            <MbxButton
              data-tid="btn-toggle-activation-asset"
              size="small"
              disabled={canBeSetActive(asset) === false}
              onClick={(e) => {
                e.stopPropagation();
                handleToggleActive(asset);
              }}
            >
              {asset.isActive === true ? 'deactivate' : 'activate'}
            </MbxButton>
          );

          return (
            <List.Item
              className={styles.listItem}
              onClick={() => {
                handleOpenDetails(asset.id);
              }}
              key={asset.id}
              actions={actions}
            >
              <List.Item.Meta
                title={
                  <>
                    <span style={{ paddingRight: '1em' }}>{asset.name}</span>
                    {asset.isActive === true && <Tag color="cyan">is active</Tag>}
                    {asset.isDraft === true && <Tag color="orange">draft</Tag>}
                  </>
                }
                description={
                  <span style={{ fontSize: 12 }}>{`last updated: ${formatDate(
                    asset.updatedAt
                  )}`}</span>
                }
              />
            </List.Item>
          );
        }}
      />

      <div style={{ marginTop: '2em', marginBottom: '2em' }}>
        <Pagination
          simple
          hideOnSinglePage
          current={currentPage}
          total={totalItems}
          pageSize={itemsPerPage}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
};

AssetList.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'AssetList',
};

export default AssetList;
