import { normalize } from 'normalizr';

import { USER_GET_DETAIL, USER_GET_DETAIL_OK, USER_GET_DETAIL_FAIL } from '../constants/users';
import { UserSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const getUserData = () => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/users/me` }, dispatch, {
    init: USER_GET_DETAIL,
    fail: USER_GET_DETAIL_FAIL,
  });
  const normalizedData = normalize(response.data, UserSchema);
  dispatch({ type: USER_GET_DETAIL_OK, payload: normalizedData });
  return response.data;
};
