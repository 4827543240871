import { schema, normalize } from 'normalizr';

import {
  REGIONCOUNTRY_CREATE,
  REGIONCOUNTRY_CREATE_OK,
  REGIONCOUNTRY_CREATE_FAIL,
  REGIONCOUNTRY_REMOVE,
  REGIONCOUNTRY_REMOVE_OK,
  REGIONCOUNTRY_REMOVE_FAIL,
  REGIONCOUNTRY_UPDATE,
  REGIONCOUNTRY_UPDATE_OK,
  REGIONCOUNTRY_UPDATE_FAIL,
  REGIONCOUNTRY_ADD_ALL,
  REGIONCOUNTRY_ADD_ALL_OK,
  REGIONCOUNTRY_ADD_ALL_FAIL,
  REGIONCOUNTRY_REMOVE_ALL,
  REGIONCOUNTRY_REMOVE_ALL_OK,
  REGIONCOUNTRY_REMOVE_ALL_FAIL,
} from '../constants/regionCountries';
import { RegionCountrySchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const addRegionCountry =
  (regionId: number, countryId: number, isDraft: boolean) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/regioncountries`,
        method: 'POST',
        data: {
          regionId,
          countryId,
          isDraft,
        },
      },
      dispatch,
      {
        init: REGIONCOUNTRY_CREATE,
        fail: REGIONCOUNTRY_CREATE_FAIL,
      },
      201
    );
    const normalizedData = normalize(response.data, RegionCountrySchema);
    dispatch({ type: REGIONCOUNTRY_CREATE_OK, regionId, payload: normalizedData });
    return normalizedData;
  };

//
//
export const addRemainingRegionCountries = (regionId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/regioncountries/all`,
      method: 'POST',
      data: {
        regionId,
      },
    },
    dispatch,
    {
      init: REGIONCOUNTRY_ADD_ALL,
      fail: REGIONCOUNTRY_ADD_ALL_FAIL,
    },
    201
  );
  const normalizedData = normalize(response.data, new schema.Array(RegionCountrySchema));
  dispatch({ type: REGIONCOUNTRY_ADD_ALL_OK, regionId, payload: normalizedData });
  return normalizedData;
};

//
//
export const removeRegionCountry =
  (regionId: number, regioncountryId: number) => async (dispatch: AppDispatch) => {
    await wrapFetch(
      {
        url: `/regioncountries/${regioncountryId}`,
        method: 'DELETE',
      },
      dispatch,
      {
        init: REGIONCOUNTRY_REMOVE,
        fail: REGIONCOUNTRY_REMOVE_FAIL,
      },
      204
    );
    dispatch({ type: REGIONCOUNTRY_REMOVE_OK, regionId, regioncountryId });
    return true;
  };

//
//
export const removeRegionCountryAll = (regionId: number) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/regioncountries/all/${regionId}`,
      method: 'DELETE',
    },
    dispatch,
    {
      init: REGIONCOUNTRY_REMOVE_ALL,
      fail: REGIONCOUNTRY_REMOVE_ALL_FAIL,
    },
    204
  );
  dispatch({ type: REGIONCOUNTRY_REMOVE_ALL_OK, regionId });
  return true;
};

//
//
export const updateRegionCountry =
  (regionId: number, regioncountryId: number, isDraft: boolean) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/regioncountries/${regioncountryId}`,
        method: 'PUT',
        data: {
          isDraft,
        },
      },
      dispatch,
      {
        init: REGIONCOUNTRY_UPDATE,
        fail: REGIONCOUNTRY_UPDATE_FAIL,
      }
    );
    const normalizedData = normalize(response.data, RegionCountrySchema);
    dispatch({ type: REGIONCOUNTRY_UPDATE_OK, regionId, payload: normalizedData });
    return normalizedData;
  };
