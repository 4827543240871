import { createSelector } from 'reselect';

import { getEmptyAuthService } from '../../reducers/AuthServiceReducer';

import { getAuthServiceNormalizedByIdFunc } from './index';

//
//
export const getAuthServiceByIdFunc = createSelector(
  getAuthServiceNormalizedByIdFunc,
  //
  ($getAuthServiceNormalizedById) => (authServiceId: number) => {
    const authService = $getAuthServiceNormalizedById(authServiceId);
    if (authService == null) {
      return getEmptyAuthService(authServiceId);
    }

    return authService;
  }
);
//
//
export const getAuthServiceById = createSelector(
  getAuthServiceByIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getAuthServiceById, id) => $getAuthServiceById(id)
);
