import { createSelector } from 'reselect';

import { getAppsByIds } from './apps/advanced';
import { getUserById, getUserAppsById } from './userSelectors';
import { sortByName } from '../utils/sort';

import type { RootState } from '../reducers';
import type { App } from '../reducers/AppsReducer';
import type { User } from '../reducers/UsersReducer';

//
//
export const getAuth = (state: RootState) => state.auth;

//
//
export const getUserProfile = (state: RootState): User => {
  const { userId } = getAuth(state);
  if (userId == null) {
    throw new Error('no user profile');
  }
  return getUserById(state, userId);
};
//
//
export const getUserProfileOptional = (state: RootState): User | null => {
  const { userId } = getAuth(state);
  if (userId == null) {
    // throw new Error('user not set');
    return null;
  }

  return getUserById(state, userId);
};

//
//
export const getUserApps = (state: RootState): Array<App> => {
  const currentUser = getUserProfileOptional(state);
  if (currentUser == null) {
    return [];
  }
  const userAppIds = getUserAppsById(state, currentUser.id);
  return getAppsByIds(state, userAppIds).sort(sortByName);
};

//
//
export const isUserSuperadmin = (state: RootState): boolean => {
  const user = getUserProfileOptional(state);
  const roles = user?.roles ?? null;
  if (roles === null) {
    return false;
  }
  const found = roles.find((role) => role.appId === null && role.rights.length > 0);
  return found != null;
};

//
//
export const isUserLoggedIn = createSelector(getAuth, (auth) => {
  console.log('isUserLoggedIn?', JSON.stringify(auth));
  return auth.isLoggedIn;
});
