import { createSelector } from 'reselect';
import semverRcompare from 'semver/functions/rcompare';

import { getEmptyAppVersion } from '../reducers/AppVersionReducer';

import type { RootState } from '../reducers';
import type { AppVersion } from '../reducers/AppVersionReducer';

//
//
const getById = (state: RootState) => state.appVersions;

//
//
export const getAppVersionByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number | null | undefined) => {
    if (id == null) {
      return null;
    }

    const appVersion = byId[id];
    if (appVersion == null) {
      return getEmptyAppVersion(id);
    }

    return appVersion;
  }
);

//
//
export const getAppVersionsByIdsFunc = createSelector(
  getAppVersionByIdFunc,
  ($getAppVersionById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }

    const versions = ids.map((id) => $getAppVersionById(id)).filter((f): f is AppVersion => !!f);
    versions.sort((a, b) => semverRcompare(a.name, b.name));

    return versions;
  }
);
