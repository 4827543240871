import { HISTORY_PUSH, HISTORY_POP, HISTORY_REPLACE } from '../constants/pagehistory';

import type { AnyAction } from 'redux';

export type LocationItem = {
  pathname: string;
  search: string;
  key?: string | null | undefined;
};
export type HistoryItem = {
  path: string;
  key: string;
};

export type PageHistoryState = {
  previousPages: Array<HistoryItem>;
  pathname: string;
};

const initialState: PageHistoryState = {
  previousPages: [],
  pathname: window.location.pathname,
};

const pushNewLocation = (
  previous: Array<HistoryItem>,
  location: LocationItem
): Array<HistoryItem> => {
  let pushed: Array<HistoryItem> = [
    ...previous,
    { path: location.pathname + location.search, key: location.key ?? '' },
  ];

  // only store last 50 entries - browser doesn't store more than that
  if (pushed.length > 50) {
    pushed = pushed.slice(-50);
  }

  return pushed;
};

//
//
const reducer = (state = initialState, action: AnyAction): PageHistoryState => {
  switch (action.type) {
    case HISTORY_PUSH: {
      const { location } = action;
      return {
        ...state,
        previousPages: pushNewLocation(state.previousPages, location),
        pathname: location.pathname,
      };
    }

    case HISTORY_POP: {
      const { location } = action;
      const { previousPages } = state;
      if (previousPages.length > 1) {
        let found = null;
        for (let i = previousPages.length - 2; i >= 0; i--) {
          const prevKey = previousPages[i];
          if (prevKey != null && prevKey.key === location.key) {
            found = [...previousPages.slice(0, i + 1)];
            break;
          }
        }
        if (found !== null) {
          return {
            ...state,
            previousPages: found,
            pathname: location.pathname,
          };
        }
      }
      // push
      return {
        ...state,
        previousPages: pushNewLocation(previousPages, location),
        pathname: location.pathname,
      };
    }

    case HISTORY_REPLACE: {
      const { location } = action;

      if (state.previousPages.length === 1) {
        return {
          ...state,
          previousPages: [{ path: location.pathname + location.search, key: location.key }],
          pathname: location.pathname,
        };
      }
      return {
        ...state,
        previousPages: [
          ...state.previousPages.slice(0, state.previousPages.length - 1),
          { path: location.pathname + location.search, key: location.key },
        ],
        pathname: location.pathname,
      };
    }

    default:
      return state;
  }
};

export default reducer;
