import { Tooltip } from 'antd';

import type { Country } from '../reducers/CountryReducer';

type Props = Readonly<{
  regionCountry: { country: Country; isDraft?: boolean };
}>;

const CountryTag = ({
  regionCountry: {
    country: { shortcode, name },
    isDraft,
  },
}: Props) => {
  function codeToFlag(code: string) {
    if (code === '**') {
      return '🌍';
    }
    if (code.length !== 2) {
      return code;
    }
    return String.fromCodePoint(
      ...code.split('').map((char) => 127397 + (char.codePointAt(0) ?? 0))
    );
  }
  const color = isDraft ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 1)';
  return (
    <Tooltip title={name} placement="bottom">
      <span data-tid="country-tag" style={{ color, paddingRight: 5, fontSize: 22 }}>
        {codeToFlag(shortcode)}
      </span>
    </Tooltip>
  );
};

export default CountryTag;
