//
//
export const getLabel = (role: { label: string }): string => {
  switch (role.label) {
    case 'POST':
      return 'CREATE';
    case 'CONTENT POST':
      return 'CONTENT CREATE';
    case 'INVITE POST':
      return 'INVITE CREATE';
    case 'PUT':
      return 'UPDATE';
    default:
      return role.label;
  }
};
