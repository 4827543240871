import { List } from 'antd';

import type { BatchUploadRecord } from '../../common/types/batch-upload';

type Props = Readonly<{
  episodes: Array<BatchUploadRecord>;
  sheetName: string;
  wasResumed: boolean;
}>;

const organizeEpisodesByLanguageAndType = (
  mapping: Record<string, number>,
  { languages }: BatchUploadRecord
) => {
  languages.forEach(({ locale, thumbnail, video, subtitles }) => {
    const thumbnailKey = `${locale} thumbnail`;
    const videoKey = `${locale} video`;
    const subtitleKey = `${locale} subtitles`;

    if (thumbnail != null) {
      if (!mapping[thumbnailKey]) {
        mapping[thumbnailKey] = 0;
      }
      mapping[thumbnailKey] += 1;
    }

    if (video != null) {
      if (!mapping[videoKey]) {
        mapping[videoKey] = 0;
      }
      mapping[videoKey] += 1;
    }

    if (subtitles != null) {
      if (!mapping[subtitleKey]) {
        mapping[subtitleKey] = 0;
      }
      mapping[subtitleKey] += 1;
    }
  });
  return mapping;
};

const UploadSummary = ({ episodes, sheetName, wasResumed }: Props) => {
  const byLanguageAndType = episodes.reduce(
    organizeEpisodesByLanguageAndType,
    {} as Record<string, number>
  );

  return (
    <div>
      <h3>Upload Summary</h3>
      <div>
        {!wasResumed && (
          <div style={{ opacity: 0.5, margin: 5 }}>
            based on sheet:{' '}
            <span
              style={{
                backgroundColor: 'lightgrey',
                border: '1px transparent',
                borderRadius: 20,
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 10,
                paddingRight: 10,
                fontWeight: 'bold',
                fontSize: 15,
              }}
            >
              {sheetName}
            </span>
          </div>
        )}
      </div>

      <List>
        {Object.entries(byLanguageAndType).map(([name, total]) => {
          return (
            <List.Item key={name} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Language version {name}</span>
              <span>{total}</span>
            </List.Item>
          );
        })}
      </List>
    </div>
  );
};

export default UploadSummary;
