import React from 'react';

import { CLIENT_TIMEZONE } from '../../utils/date';
import { STATUS_STYLES } from '../../utils/release';
import { displayDateInDifferentTimezone } from '../../utils/timezone';

import type { ReleaseStatus } from '../../common/types/release';
import type { Release } from '../../reducers/ReleaseReducer';

type Props = Readonly<{
  style?: React.CSSProperties;
  release: Release;
  releaseStatus: ReleaseStatus;
}>;

const LocalReleaseDates = (props: Props) => {
  const { release, releaseStatus, style } = props;
  return (
    <div style={style}>
      <span
        style={{
          fontSize: 12,
          ...STATUS_STYLES[releaseStatus.publishDateStatus],
        }}
      >
        {displayDateInDifferentTimezone(release.publishDate, CLIENT_TIMEZONE)} —{' '}
        {release.unpublishDate !== null
          ? displayDateInDifferentTimezone(release.unpublishDate, CLIENT_TIMEZONE)
          : '∞'}
        <sup>1</sup>
      </span>
    </div>
  );
};

export default LocalReleaseDates;

export const LocalDateFootnote = () => (
  <div>
    <span style={{ fontSize: '10px' }}>
      <sup>1</sup>
      {` all dates are displayed in local time: ${CLIENT_TIMEZONE}`}
    </span>
  </div>
);
