import {
  QuestionOutlined,
  LoadingOutlined,
  CloseOutlined,
  CheckOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Progress } from 'antd';

import { FileStatus } from '../common/constants/file-status';
import { MANIFEST_FILENAME } from '../common/constants/resource-type';
import { useCurrentAppContext } from '../contexts';
import { getAllQueueItems } from '../selectors/queueSelectors';
import { useGlobalSelector } from '../utils/hooks';
import { getFileUploadStatusForResource } from '../utils/queue';

import type { Resource } from '../reducers/ResourceReducer';

const DEFAULT_ICON_STYLE = { fontSize: '24px', color: '#ccc' };
const DEFAULT_ICON_STYLE_SMALL = { fontSize: '14px', color: '#ccc' };

type Props = Readonly<{
  assetId: number;
  resource: Resource | null | undefined;
  width?: number;
  height?: number;
}>;

const getStatusIcon = (status: number, isSmall = false) => {
  const style = isSmall ? DEFAULT_ICON_STYLE_SMALL : DEFAULT_ICON_STYLE;
  if (status === FileStatus.ERRORED || status === FileStatus.TIMEDOUT) {
    return <WarningOutlined style={{ ...style, color: 'red' }} />;
  }
  if (status === FileStatus.UNKNOWN) {
    return <QuestionOutlined style={{ ...style, color: 'red' }} />;
  }
  return <LoadingOutlined style={style} />;
};

//
//
const AssetContentPreview = (props: Props) => {
  const { assetId, resource, width = 89, height = 50 } = props;
  const { currentApp } = useCurrentAppContext();
  let thumb;

  const queuedItems = useGlobalSelector(getAllQueueItems);

  const thumbnailInfo = getFileUploadStatusForResource({
    id: assetId,
    resource,
    queuedItems,
  });
  const { queued, percentUploaded } = thumbnailInfo;
  // console.log('ASSET PREVIEW queued?', thumbnailInfo);

  if (queued) {
    thumb = <Progress type="circle" percent={percentUploaded} width={40} />;
  } else if (resource == null) {
    thumb = <CloseOutlined style={DEFAULT_ICON_STYLE} />;
  } else {
    const versions = resource?.file?.versions ?? [];
    if (Array.isArray(versions) && versions.length > 0) {
      const content = versions.map((f) => {
        let status;
        const newestLogEntry = f.lastStatusId;
        if (newestLogEntry === FileStatus.READY) {
          const href =
            f.type === 'folder'
              ? `${currentApp.publicBaseUrl}/${f.name}/${MANIFEST_FILENAME}`
              : `${currentApp.publicBaseUrl}/${f.name}.${f.ext}`;
          status = (
            <span>
              <a href={href} target="_blank" rel="noopener noreferrer">
                <CheckOutlined style={{ ...DEFAULT_ICON_STYLE_SMALL, color: 'green' }} />
              </a>
            </span>
          );
        } else {
          status = getStatusIcon(newestLogEntry, true);
        }

        const description = f.type === 'folder' ? 'unzipped' : 'zip';
        return (
          <div key={`temp-${f.id}`} style={{ lineHeight: '20px' }}>
            <span>{`${f.id}: `}</span>
            <span style={{ fontSize: 10 }}>{`(${description}) `}</span>
            {status}
          </div>
        );
      });
      thumb = content;
    } else {
      thumb = <LoadingOutlined style={DEFAULT_ICON_STYLE} />;
    }
  }

  if (typeof thumb === 'undefined') {
    thumb = <QuestionOutlined style={{ ...DEFAULT_ICON_STYLE, color: 'orange' }} />;
  }

  return (
    <div
      style={{
        width,
        minWidth: width,
        height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#eee',
        overflowY: 'scroll',
      }}
    >
      {thumb}
    </div>
  );
};

export default AssetContentPreview;
