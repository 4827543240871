import { QuestionCircleOutlined } from '@ant-design/icons';
import { Input, Select, Tooltip, Form, Tag } from 'antd';
import { useState, useEffect } from 'react';

import BackButton from './BackButton';
import MbxButton from './base/MbxButton';
import { PlayerTypeNames } from '../common/constants/player-type';
import {
  ResourceType,
  ResourceTypeNames,
  isValidResourceType,
} from '../common/constants/resource-type';
import { getAppRelevantTags } from '../common/utils/configuration';
import {
  isResourceTypeCompatibleWithPlayerType,
  isPlayerAvailableForResourceType,
  getCompatibilePlayerTypes,
} from '../common/utils/episode';
import { useCurrentAppContext } from '../contexts';
import { getAppPlatformsForApp } from '../selectors/appPlatformSelectors';
import { getAvailableTagsList } from '../selectors/tagSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { AllowedPlayerTypeKey } from '../common/constants/player-type';
import type { Episode } from '../reducers/EpisodeReducer';

export type EpisodeEditFormValues = {
  name: string;
  playerType: number;
  resourceType: ResourceType;
  tags: Array<string>;
};

type Props = Readonly<{
  data?: Episode;
  isBusy: boolean;
  onCancelOrBacklink: string | (() => void);
  onSubmit: (values: EpisodeEditFormValues) => Promise<boolean>;
}>;

//
//
const EpisodeEditForm = (props: Props) => {
  const { data, onSubmit, onCancelOrBacklink, isBusy } = props;
  const [form] = Form.useForm();

  const resourceTypeId = data?.resourceTypeId ?? undefined;
  const playerTypeId = data?.playerTypeId ?? undefined;

  const editType = data == null ? 'add' : 'edit';

  const { currentApp } = useCurrentAppContext();
  const appRelevantTags = getAppRelevantTags(currentApp);
  const appPlatforms = useGlobalSelector((state) => getAppPlatformsForApp(state, currentApp.id));
  const availableTags = useGlobalSelector((state) => getAvailableTagsList(state, currentApp));

  const [currentResourceType, setCurrentResourceType] = useState(resourceTypeId);
  const [currentPlayerType, setCurrentPlayerType] = useState(playerTypeId);

  const [compatibilePlayerTypesState, setCompatibilePlayerTypesState] = useState<Array<number>>([]);

  useEffect(() => {
    form.setFieldsValue({
      name: data?.name ?? '',
      resourceType: data?.resourceTypeId ?? undefined,
      playerType: data?.playerTypeId ?? undefined,
      tags: (data?.tags ?? [])
        .filter((et) => et?.tag?.name != null && et?.tag?.name !== '')
        .map((et) => {
          const tagName = et?.tag?.name;
          if (tagName != null && tagName !== '') {
            return tagName;
          }
          return `et-${et.id}`;
        }),
    });
  }, [data, form]);

  const handleSubmit = async (values: EpisodeEditFormValues) => {
    console.log('Received values of form: ', values);
    await onSubmit(values);
  };

  const handlePlayerTypeChange = (val: number) => {
    setCurrentPlayerType(val);
  };

  const handleResourceTypeChange = (val: ResourceType) => {
    console.log('handleResourceTypeChange()', val, typeof val);
    setCurrentResourceType(val);

    if (
      typeof currentPlayerType !== 'undefined' &&
      isResourceTypeCompatibleWithPlayerType(val, currentPlayerType) === false
    ) {
      console.log('RESET PLAYERTYPE');
      form.resetFields(['playerType']);
    }

    const compatibilePlayerTypes = getCompatibilePlayerTypes(val);
    if (compatibilePlayerTypes.length === 1) {
      console.log('AUTO SELECT PLAYERTYPE', compatibilePlayerTypes);
      handlePlayerTypeChange(compatibilePlayerTypes[0]);
      form.setFieldsValue({ playerType: compatibilePlayerTypes[0] });
    }
    setCompatibilePlayerTypesState(compatibilePlayerTypes);
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        name="EpisodeEditForm"
        colon={false}
        layout="vertical"
      >
        <Form.Item
          label="(internal) name"
          name="name"
          rules={[{ required: true, message: "Please input the episode's name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="resource type"
          name="resourceType"
          rules={[{ required: true, message: 'Please input resource type!' }]}
          style={data !== null && typeof data !== 'undefined' ? { display: 'none' } : {}}
        >
          <Select
            showSearch
            disabled={data !== null && typeof data !== 'undefined'}
            placeholder="Select a resource type"
            onChange={handleResourceTypeChange}
            filterOption={(input, option) =>
              option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.keys(ResourceTypeNames).map((resourceTypeIdStr: string) => {
              const rtid = Number(resourceTypeIdStr);
              if (!isValidResourceType(rtid)) {
                throw new Error('resourcetype_invalid');
              }
              const isPlayerAvailable = isPlayerAvailableForResourceType(rtid);
              if (!isPlayerAvailable) {
                return null;
              }
              const disableUnityBcNoAppPlatform =
                rtid === ResourceType.ARCHIVE_UNITYBUNDLE && appPlatforms.length === 0;
              return (
                <Select.Option
                  key={rtid}
                  value={Number(rtid)}
                  disabled={disableUnityBcNoAppPlatform}
                  style={{
                    color: disableUnityBcNoAppPlatform ? 'red' : undefined,
                    backgroundColor: disableUnityBcNoAppPlatform ? '#f3f3f3' : undefined,
                  }}
                >
                  {`${ResourceTypeNames[rtid]}${
                    disableUnityBcNoAppPlatform ? ' (disabled b/c no AppPlatform available)' : ''
                  }`}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="player type"
          name="playerType"
          rules={[{ required: true, message: 'Please input player type!' }]}
        >
          <Select
            showSearch
            data-tid="episode-player-type-select"
            placeholder="Select a player type"
            disabled={
              (typeof data === 'undefined' && typeof currentResourceType === 'undefined') ||
              compatibilePlayerTypesState.length === 1
            }
            onChange={handlePlayerTypeChange}
            filterOption={(input, option) =>
              option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.keys(PlayerTypeNames).map((ptid) => (
              <Select.Option
                key={ptid}
                value={Number(ptid)}
                disabled={
                  !currentResourceType ||
                  !isResourceTypeCompatibleWithPlayerType(currentResourceType, parseInt(ptid, 10))
                }
              >
                {PlayerTypeNames[ptid as AllowedPlayerTypeKey]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Tags&nbsp;
              <Tooltip
                title={
                  <ul style={{ margin: 0 }}>
                    <li>
                      {`some pre-defined tags (${appRelevantTags.join(
                        ', '
                      )}) set the icon that is used for the list view inside the
                      app`}
                    </li>
                    <li>tags are searchable in the admin interface list view</li>
                    <li>you can create new tags on the fly</li>
                  </ul>
                }
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name="tags"
        >
          <Select
            mode="tags"
            id="edit-tags-select"
            data-tid="edit-tags-select"
            style={{ width: '100%' }}
            tokenSeparators={[',']}
            filterOption={(input, option) =>
              option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            // eslint-disable-next-line react/no-unstable-nested-components
            tagRender={(customTagProps) => {
              const { label, value, closable, onClose } = customTagProps;
              const color = appRelevantTags.includes(value.toString()) ? 'geekblue' : undefined;
              return (
                <Tag color={color} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                  {label}
                </Tag>
              );
            }}
          >
            {availableTags.map((tag) => (
              <Select.Option key={`episode-tag-${tag.id}`} value={tag.name}>
                <span style={{ color: tag.isAppRelevant ? 'blue' : undefined }}>{tag.name}</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item style={{ marginTop: '3em' }}>
          <MbxButton mbxType="primary" htmlType="submit" loading={isBusy}>
            {editType === 'edit' ? 'Save' : 'Create'}
          </MbxButton>

          {typeof onCancelOrBacklink === 'string' ? (
            <BackButton
              style={{ marginLeft: '1em' }}
              mbxType="secondary"
              size="large"
              linkTo={onCancelOrBacklink}
              disabled={isBusy}
            >
              Cancel
            </BackButton>
          ) : (
            <MbxButton
              mbxType="secondary"
              size="large"
              style={{ marginLeft: '1em' }}
              onClick={onCancelOrBacklink}
              disabled={isBusy}
            >
              Cancel
            </MbxButton>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default EpisodeEditForm;
