import { schema, normalize } from 'normalizr';

import {
  RELEASE_GET_LIST,
  RELEASE_GET_LIST_OK,
  RELEASE_GET_LIST_FAIL,
  RELEASE_GET_DETAILS,
  RELEASE_GET_DETAILS_OK,
  RELEASE_GET_DETAILS_FAIL,
  RELEASE_CREATE,
  RELEASE_CREATE_OK,
  RELEASE_CREATE_FAIL,
  RELEASE_UPDATE,
  RELEASE_UPDATE_OK,
  RELEASE_UPDATE_FAIL,
  RELEASE_REMOVE,
  RELEASE_REMOVE_OK,
  RELEASE_REMOVE_FAIL,
} from '../constants/releases';
import { ReleaseSchema } from '../schemas';
import { getReleasesByIds } from '../selectors/releaseSelectors';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';
import type { GlobalStateGetter } from '../reducers';
import type { Release } from '../reducers/ReleaseReducer';

//
//
export const createRelease =
  (
    appId: number,
    regionId: number,
    episodeId: number | Array<number>,
    publishDate: Date,
    unpublishDate: Date | undefined | null,
    isDraft: boolean,
    isDemoContent: boolean,
    categories: Array<{ id: string | number; name: string }>
  ) =>
  async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const response = await wrapFetch<Array<{ id: number }>>(
      {
        url: `/releases`,
        method: 'POST',
        data: {
          regionId,
          episodeId,
          publishDate,
          unpublishDate,
          isDraft,
          isDemoContent,
          categories,
        },
      },
      dispatch,
      {
        init: RELEASE_CREATE,
        fail: RELEASE_CREATE_FAIL,
      },
      201
    );
    const normalizedData = normalize(response.data, new schema.Array(ReleaseSchema));
    dispatch({ type: RELEASE_CREATE_OK, appId, regionId, payload: normalizedData });

    const state = getState();
    const newReleases = getReleasesByIds(
      state,
      response.data.map((r) => r.id)
    );
    return newReleases;
  };

//
//
export const getReleaseDetails =
  (appId: number, releaseId: number) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch<Release>({ url: `/releases/${releaseId}` }, dispatch, {
      init: RELEASE_GET_DETAILS,
      fail: RELEASE_GET_DETAILS_FAIL,
    });
    const normalizedData = normalize(response.data, ReleaseSchema);
    dispatch({ type: RELEASE_GET_DETAILS_OK, appId, releaseId, payload: normalizedData });
    return response.data;
  };

//
//
export const getReleaseList =
  (appId: number, regionId: number, categoryId?: number) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/releases`,
        params: {
          appId,
          regionId,
          categoryId,
        },
      },
      dispatch,
      {
        init: RELEASE_GET_LIST,
        fail: RELEASE_GET_LIST_FAIL,
      }
    );
    const normalizedData = normalize(response.data, new schema.Array(ReleaseSchema));
    dispatch({
      type: RELEASE_GET_LIST_OK,
      appId,
      regionId,
      categoryId,
      payload: normalizedData,
    });
    return normalizedData;
  };

//
//
export const updateRelease =
  (
    releaseId: number,
    isDemoContent: boolean,
    isDraft: boolean,
    publishDate: Date,
    unpublishDate: Date | undefined | null,
    categories: Array<{ id: number | string; name: string }>
  ) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/releases/${releaseId}`,
        method: 'PUT',
        data: {
          isDemoContent,
          isDraft,
          publishDate,
          unpublishDate: typeof unpublishDate === 'undefined' ? null : unpublishDate,
          // TODO: check if string | number for cat-ids is ok!?
          categories: categories.map((c) => ({ ...c, id: Number(c.id) })),
        },
      },
      dispatch,
      {
        init: RELEASE_UPDATE,
        fail: RELEASE_UPDATE_FAIL,
      }
    );
    const normalizedData = normalize(response.data, ReleaseSchema);
    dispatch({ type: RELEASE_UPDATE_OK, payload: normalizedData });
    return response.data;
  };

//
//
export const deleteRelease = (release: Release) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/releases/${release.id}`,
      method: 'DELETE',
    },
    dispatch,
    {
      init: RELEASE_REMOVE,
      fail: RELEASE_REMOVE_FAIL,
    },
    204
  );
  dispatch({ type: RELEASE_REMOVE_OK, release });
  return true;
};
