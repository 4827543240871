type Props = Readonly<{
  n: number;
  title: string | JSX.Element;
  color?: string;
  border?: boolean;
  testId?: string;
}>;

const NumberCard = ({ n, title, color, border, testId }: Props) => {
  if (color == null) {
    color = '#1890ff';
  }
  return (
    <div
      style={{
        ...(border === true ? { borderLeft: '1px solid lightgrey', paddingLeft: 25 } : {}),
        marginTop: 10,
      }}
    >
      <div style={{ fontSize: '35px', color }} data-tid={`${testId}-card-number`}>
        {n}
      </div>
      <div data-tid={`${testId}-card-title`}>{title}</div>
    </div>
  );
};

export default NumberCard;
