import { combineReducers } from 'redux';

import { getEmptyResourceNormalized } from './ResourceReducer';
import { FileStatus } from '../common/constants/file-status';
import { GET_APPS_LIST_OK, GET_APP_DETAILS_OK } from '../constants/apps';
import { ASSETRESOURCE_CREATE_OK } from '../constants/assetresources';
import { ASSET_GET_DETAILS_OK, ASSET_GET_METADATA_OK } from '../constants/assets';
import {
  EPISODE_GET_LIST_OK,
  EPISODE_GET_DETAILS_OK,
  EPISODE_GET_METADATA_OK,
} from '../constants/episodes';
import { JOB_GET_DETAILS_OK, JOB_GET_METADATA_OK } from '../constants/jobs';
import { FILE_STATUS_UPDATE_OK } from '../constants/queue';
import { RELEASE_GET_LIST_OK } from '../constants/releases';
import { RESOURCE_CREATE_OK, RESOURCE_RERUN_CONVERSION_OK } from '../constants/resources';

import type { ResourceNormalized } from './ResourceReducer';
import type { AllowedAudioTemplateName } from '../common/constants/audio-template';
import type { AllowedFileStatus } from '../common/constants/file-status';
import type { AllowedImageTemplateName } from '../common/constants/image-templates';
import type { AllowedSubtitlesTemplateName } from '../common/constants/subtitles-template';
import type { AllowedVideoTemplateName } from '../common/constants/video-template';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

export type AllowedTemplateNames =
  | AllowedVideoTemplateName
  | AllowedAudioTemplateName
  | AllowedImageTemplateName
  | AllowedSubtitlesTemplateName;

export type VersionFile = {
  id: number;
  parentId: number;
  name: string;
  type: string;
  ext: string;
  meta: {
    templateName?: AllowedTemplateNames;
    zipContents?: Array<{
      fileName: string;
      fileSize: number;
      lastModified: string; // ISO-date "2020-11-05T09:06:48.000Z"
    }>;
    [K: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  } | null;
  lastStatusId: AllowedFileStatus;
};

type ParentFileBase = {
  id: number;
  originalName: string;
  name: string;
  type: string;
  lastStatusId: AllowedFileStatus;
};

export type ParentFileNormalized = Merge<
  ParentFileBase,
  {
    resource: number;
    versions: Array<number>;
  }
>;

export type ParentFile = Merge<
  ParentFileBase,
  {
    resource: ResourceNormalized;
    versions: Array<VersionFile>;
  }
>;

export type FileNormalized = ParentFileNormalized | VersionFile;
export type File = ParentFile | VersionFile;

export type FileByIdState = {
  [k: string | number]: FileNormalized;
};
export type FileState = {
  byId: FileByIdState;
};

//
//
export const getEmptyFile = (id?: number): File => ({
  id: id ?? -1,
  resource: getEmptyResourceNormalized(),
  parentId: -1,
  name: '',
  type: '',
  ext: '',
  meta: {},
  lastStatusId: FileStatus.UNKNOWN,
});

const INITAL_BYID_STATE: FileByIdState = {};

//
//
const byId = (state = INITAL_BYID_STATE, action: AnyAction): FileByIdState => {
  switch (action.type) {
    case GET_APP_DETAILS_OK:
    case GET_APPS_LIST_OK:
    case EPISODE_GET_DETAILS_OK:
    case EPISODE_GET_LIST_OK:
    case EPISODE_GET_METADATA_OK:
    case RESOURCE_CREATE_OK:
    case RELEASE_GET_LIST_OK:
    case RESOURCE_RERUN_CONVERSION_OK:
    case JOB_GET_DETAILS_OK:
    case JOB_GET_METADATA_OK:
    case ASSET_GET_DETAILS_OK:
    case ASSET_GET_METADATA_OK:
    case ASSETRESOURCE_CREATE_OK: {
      const files = action?.payload?.entities?.files ?? {};
      return {
        ...state,
        ...files,
      };
    }
    //
    //
    case FILE_STATUS_UPDATE_OK: {
      const files = action?.payload?.entities?.files ?? {};
      const newState: FileByIdState = { ...state };
      let stateChanged = false as boolean;
      Object.keys(files).forEach((key) => {
        if (newState[key] != null) {
          const oldStatus = newState[key].lastStatusId;
          const newStatus = files[key].lastStatusId;
          const oldVersions = JSON.stringify((newState[key] as ParentFileNormalized).versions);
          const newVersions = JSON.stringify(files[key].versions);
          // console.log({
          //   oldVersions,
          //   newVersions,
          // });
          if (oldStatus !== newStatus || oldVersions !== newVersions) {
            newState[key] = files[key];
            stateChanged = true;
          }
        } else {
          newState[key] = files[key];
          stateChanged = true;
        }
      });
      return stateChanged === true ? newState : state;
    }
    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
});

export default combined;
