import { normalize } from 'normalizr';

import {
  ASSETRESOURCEVERSIONREQUIREMENTS_CREATE,
  ASSETRESOURCEVERSIONREQUIREMENTS_CREATE_OK,
  ASSETRESOURCEVERSIONREQUIREMENTS_CREATE_FAIL,
  ASSETRESOURCEVERSIONREQUIREMENTS_DELETE,
  ASSETRESOURCEVERSIONREQUIREMENTS_DELETE_OK,
  ASSETRESOURCEVERSIONREQUIREMENTS_DELETE_FAIL,
  ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE,
  ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE_OK,
  ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE_FAIL,
} from '../constants/assetResourceVersionRequirements';
import { AssetResourceVersionRequirementSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const createAssetResourceVersionRequirement =
  (
    assetResourceId: number,
    appPlatformId: number,
    exclude: boolean,
    minVersionId: number | null,
    maxVersionId: number | null
  ) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/assetresourceversionrequirements`,
        method: 'POST',
        data: {
          assetResourceId,
          appPlatformId,
          exclude,
          minVersionId,
          maxVersionId,
        },
      },
      dispatch,
      {
        init: ASSETRESOURCEVERSIONREQUIREMENTS_CREATE,
        fail: ASSETRESOURCEVERSIONREQUIREMENTS_CREATE_FAIL,
      },
      201
    );
    const normalizedData = normalize(response.data, AssetResourceVersionRequirementSchema);
    dispatch({
      type: ASSETRESOURCEVERSIONREQUIREMENTS_CREATE_OK,
      payload: normalizedData,
      assetResourceId,
    });
  };

//
//
export const updateAssetResourceVersionRequirement =
  (
    versionRequirementId: number,
    exclude: boolean,
    minVersionId: number | null,
    maxVersionId: number | null
  ) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/assetresourceversionrequirements/${versionRequirementId}`,
        method: 'PUT',
        data: {
          exclude,
          minVersionId,
          maxVersionId,
        },
      },
      dispatch,
      {
        init: ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE,
        fail: ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE_FAIL,
      }
    );
    const normalizedData = normalize(response.data, AssetResourceVersionRequirementSchema);
    dispatch({ type: ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE_OK, payload: normalizedData });
  };

//
//
export const deleteAssetResourceVersionRequirement =
  (versionRequirementId: number) => async (dispatch: AppDispatch) => {
    await wrapFetch(
      {
        url: `/assetresourceversionrequirements/${versionRequirementId}`,
        method: 'DELETE',
      },
      dispatch,
      {
        init: ASSETRESOURCEVERSIONREQUIREMENTS_DELETE,
        fail: ASSETRESOURCEVERSIONREQUIREMENTS_DELETE_FAIL,
      },
      204
    );

    dispatch({
      type: ASSETRESOURCEVERSIONREQUIREMENTS_DELETE_OK,
      versionRequirementId,
    });
  };
