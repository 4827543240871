import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { checkPasswordToken, resetPassword } from '../actions/auth';
import { ROUTE_NAME_LOGIN } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import PasswordResetPage from '../pages/PasswordReset';
import { getToken } from '../selectors/urlSelectors';
import { useAppDispatch } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

//
//
const PasswordResetContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params = useParams();

  const passwordToken = getToken(params);

  const [tokenData, setTokenData] = useState<null | { email: string } | Error>(null);
  const [sendData, setSendData] = useState({ isSending: false, error: '' });

  useEffect(() => {
    let isMounted = true;
    const check = async () => {
      try {
        if (passwordToken != null) {
          const result = await dispatch(checkPasswordToken(passwordToken));
          if (isMounted) {
            setTokenData(result);
          }
        }
      } catch (err) {
        const { unknownError } = logUnknownError(err);
        if (isMounted) {
          setTokenData(unknownError);
        }
      }
    };

    check();

    return () => {
      isMounted = false;
    };
  }, [dispatch, passwordToken]);

  const handleResetPassword = async (newPassword: string) => {
    if (tokenData != null && !(tokenData instanceof Error)) {
      try {
        setSendData({ isSending: true, error: '' });
        await dispatch(resetPassword(passwordToken, newPassword));
        history.replace(`${getUrlByName(ROUTE_NAME_LOGIN)}?email=${tokenData.email}`);
      } catch (err) {
        const { msg } = logUnknownError(err);
        setSendData({ isSending: false, error: msg });
      }
    } else {
      console.error('handleResetPassword() called but tokenData still null');
    }
  };

  return (
    <PasswordResetPage data={tokenData} sendData={sendData} resetPassword={handleResetPassword} />
  );
};

export default PasswordResetContainer;
