const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export function generate() {
  let str = '';
  for (let i = 0; i < 12; i++) {
    const rand = Math.floor(Math.random() * chars.length);
    str += chars[rand];
  }
  return str;
}
