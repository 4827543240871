import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getRoleList } from '../actions/roles';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { createUserInvite } from '../actions/users';
import { useOptionalCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_SETTINGS, ROUTE_NAME_INTERNAL_USERS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import UserInvitePage from '../pages/UserInvite';
import { getAllRolesForApp } from '../selectors/roleSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { UserInviteFormValues } from '../pages/UserInvite';

//
//
const UserInviteContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentApp } = useOptionalCurrentAppContext();
  const currentAppId = currentApp?.id ?? null;
  console.log('UserInviteContainer()', currentApp, currentAppId);

  const currentRoles = useGlobalSelector((state) => getAllRolesForApp(state, currentAppId));

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getRoleList(currentAppId));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };

    fetchData();
  }, [dispatch, currentAppId]);

  const handleInviteUser = async (values: UserInviteFormValues) => {
    try {
      setIsSaving(true);
      await dispatch(
        createUserInvite({
          email: values.email,
          roleIds: values.roleIds,
          hidden: values.hidden,
          appId: currentAppId,
        })
      );
      setIsSaving(false);
      if (currentApp == null) {
        history.push(getUrlByName(ROUTE_NAME_INTERNAL_USERS));
      } else {
        history.push(getUrlByName(ROUTE_NAME_APP_SETTINGS, { ':appUid': currentApp.uid }));
      }
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
      setIsSaving(false);
    }
  };

  return (
    <UserInvitePage
      app={currentApp}
      roles={currentRoles}
      inviteUser={handleInviteUser}
      isSaving={isSaving}
    />
  );
};

export default UserInviteContainer;
