import type { Platform } from '../reducers/PlatformReducer';

export type PlatformsGetListOkAction = {
  type: 'PLATFORMS_GET_LIST_OK';
  payload: {
    result: number;
    entities: {
      platforms: { [k: string | number]: Platform };
    };
  };
};

export const PLATFORMS_GET_LIST = 'PLATFORMS_GET_LIST';
export const PLATFORMS_GET_LIST_OK = 'PLATFORMS_GET_LIST_OK';
export const PLATFORMS_GET_LIST_FAIL = 'PLATFORMS_GET_LIST_FAIL';

export const PLATFORMS_UPDATE = 'PLATFORMS_UPDATE';
export const PLATFORMS_UPDATE_OK = 'PLATFORMS_UPDATE_OK';
export const PLATFORMS_UPDATE_FAIL = 'PLATFORMS_UPDATE_FAIL';

export const PLATFORMS_CREATE = 'PLATFORMS_CREATE';
export const PLATFORMS_CREATE_OK = 'PLATFORMS_CREATE_OK';
export const PLATFORMS_CREATE_FAIL = 'PLATFORMS_CREATE_FAIL';

export const PLATFORMS_DELETE = 'PLATFORMS_DELETE';
export const PLATFORMS_DELETE_OK = 'PLATFORMS_DELETE_OK';
export const PLATFORMS_DELETE_FAIL = 'PLATFORMS_DELETE_FAIL';
