import { ResourceType } from '../constants/resource-type';

const resourceTypes: { [k in ResourceType]?: Array<number> } = {
  // XXX: there is no image-player right now
  // [ResourceType.IMAGE_MEDIABOX]: [1],
  [ResourceType.VIDEO_MEDIABOX]: [1, 2],
  [ResourceType.ARCHIVE_WEBPAGE]: [3],
  [ResourceType.ARCHIVE_UNITYBUNDLE]: [4],
  [ResourceType.AUDIO_ONLY]: [5],
};

//
//
export const isResourceTypeCompatibleWithPlayerType = (
  resourceTypeId: ResourceType,
  playerTypeId: number
) => {
  const compatiblePlayerTypes = resourceTypes[resourceTypeId];
  if (compatiblePlayerTypes == null) {
    return false;
  }
  return compatiblePlayerTypes.includes(playerTypeId);
};

//
//
export const getCompatibilePlayerTypes = (resourceTypeId: ResourceType) => {
  const compatiblePlayerTypes = resourceTypes[resourceTypeId];
  if (compatiblePlayerTypes == null) {
    return [];
  }
  return compatiblePlayerTypes;
};

//
//
export const isPlayerAvailableForResourceType = (resourceTypeId: ResourceType) => {
  const compatiblePlayerTypes = resourceTypes[resourceTypeId];
  return compatiblePlayerTypes != null;
};
