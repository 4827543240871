import { combineReducers } from 'redux';

import { ReceiptType } from '../common/constants/receipt-type';
import {
  APPRECEIPTVALIDATORS_GET_LIST_OK,
  APPRECEIPTVALIDATORS_CREATE_OK,
  APPRECEIPTVALIDATORS_DELETE_OK,
  APPRECEIPTVALIDATORS_UPDATE_OK,
} from '../constants/appreceiptvalidators';

import type { Configuration } from '../common/types/app';
import type { AppReceiptValidatorsAction } from '../constants/appreceiptvalidators';

export type AppReceiptValidator = {
  id: number;
  receiptTypeId: ReceiptType;
  appId: number;
  meta: Configuration;
};

export type AppReceiptValidatorStateById = { [k: string | number]: AppReceiptValidator };
export type AppReceiptValidatorStateAllIds = { [k: string | number]: Array<number> };

export type AppReceiptValidatorState = {
  byId: AppReceiptValidatorStateById;
  allIds: AppReceiptValidatorStateAllIds;
};

export const getEmptyAppReceiptValidator = (id?: number): AppReceiptValidator => ({
  id: id ?? -1,
  appId: -1,
  receiptTypeId: ReceiptType.GOOGLE_PLAY_STORE,
  meta: [],
});

const initialStateById: AppReceiptValidatorStateById = {};
const initialStateAllIds: AppReceiptValidatorStateAllIds = {};

//
//
const byId = (
  state = initialStateById,
  action: AppReceiptValidatorsAction
): AppReceiptValidatorStateById => {
  switch (action.type) {
    case APPRECEIPTVALIDATORS_GET_LIST_OK: {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        const newState: AppReceiptValidatorStateById = { ...state };
        action.payload.forEach((arv) => {
          newState[arv.id] = arv;
        });
        return newState;
      }
      return state;
    }

    case APPRECEIPTVALIDATORS_CREATE_OK:
    case APPRECEIPTVALIDATORS_UPDATE_OK: {
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    }

    case APPRECEIPTVALIDATORS_DELETE_OK: {
      const { [action.appReceiptValidatorId]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    default:
      return state;
  }
};

//
//
const allIds = (
  state = initialStateAllIds,
  action: AppReceiptValidatorsAction
): AppReceiptValidatorStateAllIds => {
  switch (action.type) {
    case APPRECEIPTVALIDATORS_GET_LIST_OK: {
      return {
        ...state,
        [action.appId]: action.payload.map((arv) => arv.id),
      };
    }

    case APPRECEIPTVALIDATORS_CREATE_OK: {
      return {
        ...state,
        [action.appId]: [...state[action.appId], action.payload.id],
      };
    }

    case APPRECEIPTVALIDATORS_DELETE_OK: {
      return {
        ...state,
        [action.appId]: state[action.appId].filter(
          (appReceiptValidatorId) => appReceiptValidatorId !== action.appReceiptValidatorId
        ),
      };
    }

    default:
      return state;
  }
};

const reducers = combineReducers({
  byId,
  allIds,
});

export default reducers;
