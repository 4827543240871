import { schema, normalize } from 'normalizr';

import { LOCALE_GET_LIST, LOCALE_GET_LIST_OK, LOCALE_GET_LIST_FAIL } from '../constants/locales';
import { LocaleSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const getLocaleList = () => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/locales`,
    },
    dispatch,
    {
      init: LOCALE_GET_LIST,
      fail: LOCALE_GET_LIST_FAIL,
    }
  );
  const normalizedData = normalize(response.data, new schema.Array(LocaleSchema));
  dispatch({ type: LOCALE_GET_LIST_OK, payload: normalizedData });
  return normalizedData;
};
