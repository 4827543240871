import { combineReducers } from 'redux';

import { GET_APPS_LIST_OK, GET_APP_DETAILS_OK } from '../constants/apps';
import { GET_AUTH_SERVICES_OK, LOGIN_OK } from '../constants/auth';
import { USER_INVITE_ACCEPT_OK } from '../constants/users';

import type { AnyAction } from 'redux';

export type AuthService = {
  id: number;
  name: string;
  typeId: number;
};

export type AuthServiceStateById = { [k: string | number]: AuthService };

export type AuthServiceState = {
  byId: AuthServiceStateById;
};

export const getEmptyAuthService = (id?: number): AuthService => ({
  id: id ?? -1,
  name: '??',
  typeId: -1,
});

const initialStateById: AuthServiceStateById = {};

//
//
const byId = (state = initialStateById, action: AnyAction): AuthServiceStateById => {
  switch (action.type) {
    case LOGIN_OK:
    case GET_APPS_LIST_OK: {
      if (action.payload.entities.authServices == null) {
        return state;
      }
      return {
        ...action.payload.entities.authServices,
      };
    }

    case GET_APP_DETAILS_OK: {
      if (action.payload.entities.authServices == null) {
        return state;
      }
      return {
        ...state,
        ...action.payload.entities.authServices,
      };
    }

    case USER_INVITE_ACCEPT_OK: {
      if (action.updatedUser?.entities?.authServices == null) {
        return state;
      }
      return {
        ...state,
        ...action.updatedUser.entities.authServices,
      };
    }
    case GET_AUTH_SERVICES_OK:
      return { ...state, ...action.payload.entities.authServices };
    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
});

export default combined;
