import { Avatar } from 'antd';

import CustomPageHeader from '../components/CustomPageHeader';
import DeploymentDiff from '../components/DeploymentDiff';
import DeploymentRegionTable from '../components/DeploymentRegionTable';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_DEPLOYMENTS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { formatDate } from '../utils/date';

import type { Deployment, DeploymentFetchData } from '../../typings/deployments';
import type { Region } from '../reducers/RegionReducer';

type Props = {
  allRegions: Array<Region>;
  currentDeployment: Deployment;
  previousDeployment: DeploymentFetchData['latest'];
  isLoading: boolean;
  loadingPercent: number;
};

//
//
const DeploymentDetail = (props: Props) => {
  const { allRegions, currentDeployment, previousDeployment, isLoading, loadingPercent } = props;
  const { currentApp } = useCurrentAppContext();

  const backLink = getUrlByName(ROUTE_NAME_APP_DEPLOYMENTS, {
    ':appUid': currentApp.uid,
  });

  return (
    <div>
      <CustomPageHeader
        title={formatDate(currentDeployment.createdAt)}
        backLink={backLink}
        extra={[
          <div key="asdasd-769-xasx">
            <span
              style={{
                marginRight: '1em',
                fontSize: 12,
                fontWeight: 'normal',
              }}
            >
              {`deployed by ${currentDeployment.creator.firstname} ${currentDeployment.creator.lastname}`}
            </span>
            <Avatar shape="square" size={24} src={currentDeployment.creator.imageUrl} />
          </div>,
        ]}
      />

      <DeploymentRegionTable
        meta={currentDeployment.meta}
        oldMeta={previousDeployment != false ? previousDeployment?.meta ?? [] : []}
        allRegions={allRegions}
      />

      <DeploymentDiff
        allRegions={allRegions}
        isLoading={isLoading}
        loadingPercent={loadingPercent}
        last={previousDeployment}
        current={currentDeployment}
      />
    </div>
  );
};

export default DeploymentDetail;
