export const ImageOutputFormats = [
  {
    name: 'jpg',
    ext: 'jpg',
    mimeType: 'image/jpeg',
  },
  {
    name: 'webp',
    ext: 'webp',
    mimeType: 'image/webp',
  },
] as const;

const imageTypes = ['jpg', 'webp'] as const;
export type AllowedImageType = (typeof imageTypes)[number];

const imageTemplateNames = ['xs', 's', 'm', 'l', 'xl'] as const;
export type AllowedImageTemplateName = (typeof imageTemplateNames)[number];

//
//
export function isAllowedImageTemplateName(name: unknown): name is AllowedImageTemplateName {
  return imageTemplateNames.includes(name as AllowedImageTemplateName);
}

export const ImageTemplates = {
  xs: 'xs',
  s: 's',
  m: 'm',
  l: 'l',
  xl: 'xl',
} as const;

export const ImageSizes: { [k in AllowedImageTemplateName]: number } = {
  xs: 125,
  s: 250,
  m: 500,
  l: 1000,
  xl: 1920,
} as const;

export const ImageQualities: {
  [k in AllowedImageType]: { [l in AllowedImageTemplateName]?: number };
} = {
  jpg: {
    m: 85,
    l: 95,
    xl: 95,
  },
  webp: {
    m: 85,
    l: 90,
    xl: 90,
  },
} as const;
