const Checkmark = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4853 20.4853C15.799 25.1716 8.20101 25.1716 3.51472 20.4853C-1.17157 15.799 -1.17157 8.20101 3.51472 3.51472C8.20101 -1.17157 15.799 -1.17157 20.4853 3.51472C25.1716 8.20101 25.1716 15.799 20.4853 20.4853Z"
      fill="#78DE86"
    />
    <path
      d="M7.5 12.6429L10.1546 15.7308C10.4833 16.1132 11.0882 16.0688 11.3574 15.6424L16.5 7.5"
      stroke="#0E0E2C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default Checkmark;
