import type { Resource } from '../reducers/ResourceReducer';

//
//
export const getResourceDuration = (resource: Resource | null | undefined): number => {
  // const parentDuration = resource?.file?.meta?.durationInMs ?? -1;
  let duration = -1;
  const versions = resource?.file?.versions ?? [];
  if (Array.isArray(versions) && versions.length > 0) {
    versions.some((f) => {
      duration = f.meta?.durationInMs ?? -1;
      return duration !== -1;
    });
  }
  return duration;
};
