import semverCompare from 'semver/functions/compare';
import gte from 'semver/functions/gte';
import lt from 'semver/functions/lt';

import styles from './AssetVersionTable.module.scss';
import { getSortedResourceIds } from '../common/utils/asset';
import { useCurrentAppContext } from '../contexts';
import { getAppPlatformsForApp } from '../selectors/appPlatformSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { Asset } from '../reducers/AssetReducer';

type Props = Readonly<{
  data: Asset;
}>;

const GREEN = '#a6de7b';
const RED = '#f99'; // TODO: mark stuff red that is in conflict
const WHITE = '#fff';

//
//
const AssetVersionTable = (props: Props) => {
  const { data } = props;
  const { currentApp } = useCurrentAppContext();
  const appPlatforms = useGlobalSelector((state) => getAppPlatformsForApp(state, currentApp.id));

  const resources = data.resources ?? [];

  return (
    <div style={{ marginTop: '5em' }}>
      <h3>Which files from this patch are used for which app versions</h3>
      {appPlatforms.map((ap) => {
        const width = 150 + (1 + ap.versions.length) * 50;
        const sortedIds = getSortedResourceIds(resources, ap.id, semverCompare);

        const versionFilled: { [k: string]: boolean } = {};
        ap.versions.forEach((v) => {
          versionFilled[v.name] = false;
        });

        return (
          <div className={styles.versionTable} key={`versionTable-${ap.id}`}>
            <div className={styles.versionTableTitle}>{ap.platform.name}</div>
            <div className={styles.versionTableWrapper}>
              <table style={{ width }}>
                <thead>
                  <tr>
                    <th className={[styles.sticky, styles.versionLabel].join(' ')}>Versions</th>
                    {ap.versions.map((v) => (
                      <th key={`versionLabel-${ap.id}-${v.id}`} className={styles.versionTitleCell}>
                        {v.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {sortedIds.map((arId) => {
                    const ar = resources.find((res) => res.id === arId);
                    if (ar == null) {
                      console.error('AssetResource not found', arId);
                      return null;
                    }
                    const filename = ar.resource?.file?.originalName ?? '??';
                    const resourceId = ar.resource?.id ?? '??';

                    const requirements = ar.requirements ?? [];
                    const relevantRequirements = requirements.filter(
                      (req) => req.appPlatform.id === ap.id
                    );

                    const versionInfo: { [k: string]: string } = {};
                    if (relevantRequirements.length === 0) {
                      ap.versions.forEach((v) => {
                        if (versionFilled[v.name] === false) {
                          versionInfo[v.name] = GREEN;
                          versionFilled[v.name] = true;
                        } else {
                          versionInfo[v.name] = RED;
                        }
                      });
                    } else if (relevantRequirements.length === 1) {
                      const [req] = relevantRequirements;
                      if (req.exclude === true) {
                        return null;
                      }
                      ap.versions.forEach((v) => {
                        versionInfo[v.name] = WHITE;

                        let vGteMin = true;
                        let vLtMax = true;

                        if (req.minVersion != null) {
                          vGteMin = gte(v.name, req.minVersion.name);
                        }
                        if (req.maxVersion != null) {
                          vLtMax = lt(v.name, req.maxVersion.name);
                        }
                        if (vGteMin === true && vLtMax === true) {
                          if (versionFilled[v.name] === false) {
                            versionInfo[v.name] = GREEN;
                            versionFilled[v.name] = true;
                          } else {
                            versionInfo[v.name] = RED;
                          }
                        }
                      });
                    } else {
                      throw new Error('there should only be one requirement per platform');
                    }

                    return (
                      <tr className={styles.versionRow} key={`versioninfo-${ap.id}-${ar.id}`}>
                        <td
                          className={[styles.sticky, styles.zipname].join(' ')}
                        >{`${resourceId} ${filename}`}</td>
                        {ap.versions.map((v) => (
                          <td
                            key={`versioninfo-${ap.id}-${ar.id}-${v.id}`}
                            className={styles.versionCell}
                            style={{ backgroundColor: versionInfo[v.name] }}
                          />
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AssetVersionTable;
