import { Alert } from 'antd';
import { useHistory } from 'react-router-dom';

import { isntMyUpload } from './batchUpload/utils';
import { useOptionalCurrentAppContext } from '../contexts';
import { getAuth } from '../selectors/authSelectors';
import { getBatchUploadByAppId } from '../selectors/batchUploadSelectors';
import { useGlobalSelector } from '../utils/hooks';

//
//
const AlertRunningBatchupload = () => {
  const history = useHistory();
  const { userId } = useGlobalSelector(getAuth);
  const { currentApp } = useOptionalCurrentAppContext();

  const uploadInProgress = useGlobalSelector((state) =>
    getBatchUploadByAppId(state, currentApp?.id)
  );

  const onEpisodesSubpage = history.location.pathname.indexOf('/episodes') !== -1;
  const onBatchUploadSubpage = history.location.pathname.indexOf('/batchUploadCreate') !== -1;
  const otherUserUploadInProgress = isntMyUpload(uploadInProgress, userId, false);

  const content =
    otherUserUploadInProgress === true ? (
      <>
        <p>
          For the time running the episodes section will be blocked for all users to ensure that the
          upload will not be affected. You can still view episodes but not add or update them.
        </p>
        <p>
          For more information, please contact{' '}
          <a data-tid="bu-creator-email">{uploadInProgress?.creator.email}</a>
        </p>
      </>
    ) : (
      <>
        <p>
          Looks like you still have a batch upload running ... please finish it as soon as possible
          because while it is open no other user can create or update episodes. Thank you.
        </p>
      </>
    );

  // we are displaying the alert on every page but the batchupload-page if you have a batch upload
  // running or if someone else has an open batchupload and you are on the episodes pages
  if (
    uploadInProgress != null &&
    (onEpisodesSubpage === true ||
      (otherUserUploadInProgress === false && onBatchUploadSubpage === false))
  ) {
    return (
      <div style={{ margin: '2em', display: 'flex', justifyContent: 'center' }}>
        <Alert
          data-tid="alert-running-bu"
          message="Batch upload in progress"
          description={content}
          type="warning"
          showIcon
          style={{ maxWidth: '640px' }}
        />
      </div>
    );
  }

  return null;
};

export default AlertRunningBatchupload;
