import { message } from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { logout, twofaDeactivate } from '../actions/auth';
import { getUserData } from '../actions/user';
import { ROUTE_NAME_PROFILETWOFASETUP } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import Profile from '../pages/Profile';
import { getAppsByIds } from '../selectors/apps/advanced';
import { getUserProfile } from '../selectors/authSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

//
//
const ProfileContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const profile = useGlobalSelector(getUserProfile);
  const appIds = profile?.apps?.map((app) => app.id) ?? [];
  const apps = useGlobalSelector((state) => getAppsByIds(state, appIds));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getUserData());
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleOpenTwofaPage = () => {
    history.push(getUrlByName(ROUTE_NAME_PROFILETWOFASETUP));
  };

  const handleDeactivateTwofa = async () => {
    console.log('handleDeactivateTwofa()');
    try {
      await dispatch(twofaDeactivate());
      message.success('2FA deactivated');
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  return (
    <Profile
      apps={apps}
      profile={profile}
      logout={handleLogout}
      deactivateTwofa={handleDeactivateTwofa}
      openTwofaPage={handleOpenTwofaPage}
    />
  );
};

export default ProfileContainer;
