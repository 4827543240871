import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, List, Modal, Typography } from 'antd';
import { useState } from 'react';

import { ErrorCopy } from './constants';
import NumberCard from './NumberCard';
import { Severity, SeverityMapping, ValidationError } from '../../common/constants/batch-upload';

import type { Issue } from '../../common/types/batch-upload';
import type { PropsWithChildren } from 'react';

type Props = Readonly<{
  issues: Array<Issue>;
}>;

type ErrorDetailProps = Readonly<{
  issue: Issue;
  index: number;
  severity: Severity;
}>;

type WrapperProps = Readonly<PropsWithChildren & { tid: string; severity: Severity }>;

const Wrapper = (props: WrapperProps) => {
  const { children, tid, severity } = props;

  const type =
    severity === Severity.REJECTION ? (
      <CloseCircleOutlined style={{ color: 'red', padding: 5 }} />
    ) : (
      <ExclamationCircleOutlined style={{ color: 'orange', padding: 5 }} />
    );

  return (
    <div style={{ display: 'flex' }} data-tid={tid}>
      <div style={{ marginRight: 10 }}>{type}</div>
      <div data-tid="error-details">{children}</div>
    </div>
  );
};

const ErrorDetail = ({ issue, index, severity }: ErrorDetailProps) => {
  const erroredAddresses = issue.erroredCells?.map((ec) => ec.cell.address) ?? [];
  const tid = `bu-error-detail-${index}`;

  console.log({
    tid,
    copy: ErrorCopy[issue.key].title,
    erroredCells: issue.erroredCells,
    erroredAddresses,
  });

  switch (issue.key) {
    case ValidationError.LANGUAGE_NOT_FOUND:
      return (
        <Wrapper tid={tid} severity={severity}>
          <>
            {ErrorCopy[issue.key].title}{' '}
            <span style={{ fontStyle: 'italic', fontWeight: 600 }}>
              {issue.erroredCells[0]?.cell.value ?? '??'}
            </span>
          </>
        </Wrapper>
      );
    case ValidationError.RESOURCE_TYPE_NOT_FOUND:
    case ValidationError.TAG_NOT_FOUND:
      return (
        <Wrapper tid={tid} severity={severity}>
          <>
            {ErrorCopy[issue.key].title}{' '}
            {issue.erroredCells?.map((ec, index2) => (
              <span key={`issue-details-${tid}-${ec.name}-${ec.cell.address}`}>
                <Typography.Text code>{ec.cell.address}</Typography.Text>
                {index2 < issue.erroredCells.length - 1 ? ', ' : ''}
              </span>
            ))}
          </>
        </Wrapper>
      );
    case ValidationError.INTERNAL_NAME_USED_MULTIPLE_TIMES:
    case ValidationError.FILE_NAME_USED_MULTIPLE_TIMES:
      return (
        <Wrapper tid={tid} severity={severity}>
          <>
            {ErrorCopy[issue.key].title}{' '}
            <span style={{ fontStyle: 'italic', fontWeight: 600 }}>
              {issue.erroredCells[0]?.cell.value ?? '??'}
            </span>
            {issue.erroredCells?.map((ec, index2) => (
              <span key={`issue-details-${tid}-${ec.name}-${ec.cell.address}`}>
                <Typography.Text code>{ec.cell.address}</Typography.Text>
                {index2 < issue.erroredCells.length - 1 ? ', ' : ''}
              </span>
            ))}
          </>
        </Wrapper>
      );

    default:
      return (
        <Wrapper tid={tid} severity={severity}>
          <>
            {ErrorCopy[issue.key].title}{' '}
            {issue.erroredCells?.map((ec, index2) => (
              <span key={`issue-details-${tid}-${ec.name}-${ec.cell.address}`}>
                <span style={{ fontStyle: 'italic', fontWeight: 600 }}>{ec.name}</span>{' '}
                <Typography.Text code>{ec.cell.address}</Typography.Text>
                {index2 < issue.erroredCells.length - 1 ? ', ' : ''}
              </span>
            ))}
          </>
        </Wrapper>
      );
  }
};

const ValidationReport = ({ issues }: Props) => {
  const [selectedError, setSelectedError] = useState<ValidationError | null>(null);

  const rejections = issues.filter(({ key }) => SeverityMapping[key] === Severity.REJECTION).length;
  const warnings = issues.filter(({ key }) => SeverityMapping[key] === Severity.WARNING).length;

  const data = issues
    .map((issue, index) => {
      const severity = SeverityMapping[issue.key];
      const detail = <ErrorDetail issue={issue} index={index} severity={severity} />;

      return {
        key: index,
        detail,
        info: (
          <Button type="link" onClick={() => setSelectedError(issue.key)}>
            more info
          </Button>
        ),
        severity,
      };
    })
    .sort((a, b) => {
      if (a.severity === b.severity) {
        return 0;
      }
      return a.severity === Severity.REJECTION ? -1 : 1;
    });

  return (
    <div>
      {rejections > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '45%',
            marginBottom: 10,
          }}
        >
          <NumberCard n={rejections} title={'Rejections'} color={'red'} testId="bu-rejections" />
          <NumberCard
            n={warnings}
            title={'Warnings'}
            color={'orange'}
            border
            testId="bu-warnings"
          />
        </div>
      )}
      <h3 style={{ marginTop: 32, marginBottom: 5 }}>{rejections > 0 ? 'Report' : 'Warnings'}</h3>
      <List
        style={{
          width: '100%',
          overflow: 'auto',
          maxHeight: 316,
          marginBottom: 24,
        }}
      >
        <List.Item key="root" style={{ opacity: 0.5, alignItems: 'normal' }}>
          <span>TYPE</span>
          <span style={{ marginRight: '40px' }}>DETAIL</span>
        </List.Item>
      </List>
      <List
        style={{
          width: '100%',
          overflow: 'auto',
          maxHeight: 316,
          marginBottom: 24,
        }}
      >
        {data.map(({ key, detail, info }) => {
          return (
            <List.Item
              key={key}
              style={{ display: 'flex', justifyContent: 'space-between', padding: '2px 0' }}
            >
              {detail}
              {info}
            </List.Item>
          );
        })}
      </List>
      <Modal
        title={selectedError ? ErrorCopy[selectedError].title : ''}
        open={!!selectedError}
        footer={
          <Button type="primary" onClick={() => setSelectedError(null)}>
            Ok
          </Button>
        }
        closable={false}
        keyboard={false}
        maskClosable={false}
      >
        {selectedError ? ErrorCopy[selectedError].description : ''}
      </Modal>
    </div>
  );
};

export default ValidationReport;
