import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import BackButtonFunctionality from './components/BackButtonFunctionality';
import GlobalDragDropDisable from './components/GlobalDragDropDisable';
import PageLayoutContainer from './containers/PageLayoutContainer';
import CustomRoute from './navigation/CustomRoute';
import { SITEMAP_FLAT } from './navigation/sitemap';
import Forbidden from './pages/Forbidden';
import NotFound from './pages/NotFound';

//
//
const App = () => (
  <Router
    // trying to get confirm before navigate working - this is still buggy
    // see: https://github.com/remix-run/react-router/issues/5405
    getUserConfirmation={(payload, callback) => {
      console.log('############## getUserConfirmation ##########################');
      // https://github.com/ReactTraining/react-router/issues/5405#issuecomment-682730905
      // const { message, locationNext } = JSON.parse(payload);
      const { message } = JSON.parse(payload);

      // Check if the current location and the next are equal. If so, ignore. This prevents some double
      // confirmation alerts.
      // XXX: Is this really happening for us?
      // if (window.history?.location?.key === locationNext.key) {
      //   // callback(true);
      //   console.log('return');
      //   return;
      // }

      const confirmed = window.confirm(message);

      console.log('callback()', confirmed);
      callback(confirmed);

      // if (!confirmed && historyAction === 'POP') {
      // console.log('window.history.forward()');
      // window.history.forward();
      // }
    }}
  >
    <PageLayoutContainer>
      <Switch>
        <Route exact path="/403" component={Forbidden} />
        <Route exact path="/404" component={NotFound} />
        {SITEMAP_FLAT.map((p) => (
          <CustomRoute
            exact
            key={p.data.path}
            path={p.data.path}
            appSpecific={p.appSpecific}
            needsAdmin={p.needsAdmin}
            requiredAppRights={p.requiredAppRights}
            restrictAccessThroughAppSettings={p.restrictAccessThroughAppSettings}
            secure={p.secure}
            comp={p.Component}
          />
        ))}
        <Route component={NotFound} />
      </Switch>
    </PageLayoutContainer>
    <BackButtonFunctionality />
    <GlobalDragDropDisable />
  </Router>
);

export default App;
