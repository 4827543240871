const iOSRegexp = new RegExp('\\biPhone.*Mobile|\\biPod|\\biPad|AppleCoreMedia', 'i');
const isIPadOSRegexp = new RegExp('CPU OS 13', 'i');

//
//
export const isIOS = (ua: string): boolean => {
  return iOSRegexp.test(ua) || isIPadOSRegexp.test(ua);
};

//
//
export const getOS = (ua: string): string => {
  return isIOS(ua) ? 'iOS' : 'unknown';
};
