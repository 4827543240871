//
//
export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result != null
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

//
// see: https://stackoverflow.com/a/13542669/388026
export function rgbLinearShade(p: number, c: string) {
  const [a, b, C, d] = c.split(',');
  const P = p < 0;
  const t = P ? 0 : 255 * p;
  const P2 = P ? 1 + p : 1 - p;

  const rgb =
    'rgb' +
    (d != null ? 'a(' : '(') +
    Math.round(parseInt(a[3] == 'a' ? a.slice(5) : a.slice(4)) * P2 + t) +
    ',' +
    Math.round(parseInt(b) * P2 + t) +
    ',' +
    Math.round(parseInt(C) * P2 + t) +
    (d != null ? ',' + d : ')');
  return rgb;
}

//
//
export function hexLinearShade(p: number, c: string) {
  const cRgb = hexToRgb(c);
  if (cRgb == null) {
    throw new Error('no RGB found');
  }
  const cRgbStr = `rgb(${cRgb.r},${cRgb.g},${cRgb.b})`;
  return rgbLinearShade(p, cRgbStr);
}
