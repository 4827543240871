export enum Severity {
  REJECTION = 'REJECTION',
  WARNING = 'WARNING',
}

export enum ValidationError {
  PARSE_ERROR = 'PARSE_ERROR',
  SHEET_TEMPLATE_MODIFIED = 'SHEET_TEMPLATE_MODIFIED',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
  MANDATORY_FIELD_BLANK = 'MANDATORY_FIELD_BLANK',
  MANDATORY_FIELD_MARKED_WITH_IGNORE = 'MANDATORY_FIELD_MARKED_WITH_IGNORE',
  INTERNAL_NAME_USED_MULTIPLE_TIMES = 'INTERNAL_NAME_USED_MULTIPLE_TIMES',
  INTERNAL_NAME_ALREADY_EXISTS = 'INTERNAL_NAME_ALREADY_EXISTS',
  LANGUAGE_VERSION_ALREADY_EXISTS = 'LANGUAGE_VERSION_ALREADY_EXISTS',
  INTERNAL_NAME_DOES_NOT_EXIST = 'INTERNAL_NAME_DOES_NOT_EXIST',
  FILE_NAME_USED_MULTIPLE_TIMES = 'FILE_NAME_USED_MULTIPLE_TIMES',
  OPTIONAL_FIELD_BLANK = 'OPTIONAL_FIELD_BLANK',
  FILE_CONTENT_TYPE_MISMATCH = 'FILE_CONTENT_TYPE_MISMATCH',
  RESOURCE_TYPE_NOT_FOUND = 'RESOURCE_TYPE_NOT_FOUND',
  LANGUAGE_NOT_FOUND = 'LANGUAGE_NOT_FOUND',
  TAG_NOT_FOUND = 'TAG_NOT_FOUND',
}

export const SeverityMapping = {
  [ValidationError.PARSE_ERROR]: Severity.REJECTION,
  [ValidationError.SHEET_TEMPLATE_MODIFIED]: Severity.REJECTION,
  [ValidationError.LIMIT_EXCEEDED]: Severity.REJECTION,
  [ValidationError.MANDATORY_FIELD_BLANK]: Severity.REJECTION,
  [ValidationError.MANDATORY_FIELD_MARKED_WITH_IGNORE]: Severity.REJECTION,
  [ValidationError.INTERNAL_NAME_USED_MULTIPLE_TIMES]: Severity.REJECTION,
  [ValidationError.INTERNAL_NAME_ALREADY_EXISTS]: Severity.REJECTION,
  [ValidationError.LANGUAGE_VERSION_ALREADY_EXISTS]: Severity.REJECTION,
  [ValidationError.INTERNAL_NAME_DOES_NOT_EXIST]: Severity.REJECTION,
  [ValidationError.FILE_CONTENT_TYPE_MISMATCH]: Severity.REJECTION,
  [ValidationError.RESOURCE_TYPE_NOT_FOUND]: Severity.REJECTION,
  [ValidationError.LANGUAGE_NOT_FOUND]: Severity.REJECTION,
  [ValidationError.FILE_NAME_USED_MULTIPLE_TIMES]: Severity.WARNING,
  [ValidationError.OPTIONAL_FIELD_BLANK]: Severity.WARNING,
  [ValidationError.TAG_NOT_FOUND]: Severity.WARNING,
};

export const IGNORE = '#';

export const LIMIT = 500;

export const HEADERS = {
  SHEET_TITLE: 'Episode Metadata',
  NAME: 'Internal Name*',
  RESOURCE_TYPE: 'Resource type*',
  TAGS: 'Tags',
  EPISODE_TITLE: 'Title*',
  THUMBNAIL: 'Thumbnail file',
  VIDEO: 'Video file*',
  SUBTITLES: 'Subtitle file',
} as const;

export const MASTER_SHEET_NAME = 'Metadata Sheet Template Master';

export enum BatchUploadStatus {
  // POST
  CREATED = 'CREATED',
  // PUT
  CANCELED = 'CANCELED',
  UPLOADING = 'UPLOADING',
  CREATING_FILES = 'CREATING_FILES',
  UPLOAD_DONE = 'UPLOAD_DONE',
  MISSING_ITEMS = 'MISSING_ITEMS',
  MISSING_MULTIPART = 'MISSING_MULTIPART',
}

export const BatchUploadStatusGrouping = {
  DONE: [BatchUploadStatus.CANCELED, BatchUploadStatus.UPLOAD_DONE],
  IN_PROGRESS: [
    BatchUploadStatus.UPLOADING,
    BatchUploadStatus.MISSING_ITEMS,
    BatchUploadStatus.CREATING_FILES,
  ],
  STARTED: [BatchUploadStatus.CREATED],
};

export enum BatchUploadItemStatus {
  CREATED = 'CREATED',
  UPLOADED = 'UPLOADED',
  MISSING = 'MISSING',
  MULTIPART_START = 'MULTIPART_START',
  MULTIPART_END = 'MULTIPART_END',
  MULTIPART_ERROR = 'MULTIPART_ERROR',
}

export const BatchUploadServerErrors = {
  ACTIVE_UPLOAD: 'active_batch_upload',
  NOT_FOUND: 'not_found',
  INVALID_STATUS_CHANGE: 'invalid_status_change',
  VALIDATION_ERRORS: 'validation_errors',
  WRITE_ERROR: 'write_error',
  NOT_MULTIPART: 'not_multipart',
  MULTIPART_NOT_CREATED: 'multipart_not_created',
  MULTIPART_MISSING_PARTS: 'multipart_missing_parts',
  MULTIPART_HASH_MISMATCH: 'multipart_hash_mismatch',
};

export enum S3MultipartErrors {
  NoSuchUpload = 'NoSuchUpload',
  EntityTooSmall = 'EntityTooSmall',
  InvalidPart = 'InvalidPart',
  InvalidPartOrder = 'InvalidPartOrder',
}

export const HEADERS_PER_LANGUAGE = 9;

export const HEADERS_FOR_CREATE = 4;
export const HEADERS_FOR_UPDATE = 3;
