import { combineReducers } from 'redux';

import { getEmptyResource } from './ResourceReducer';
import {
  EPISODELOCALIZED_REMOVE_CONTENT_OK,
  EPISODELOCALIZED_ADD_CONTENT_OK,
} from '../constants/episodeLocalized';
import { EPISODE_GET_DETAILS_OK, EPISODE_GET_METADATA_OK } from '../constants/episodes';

import type { Resource } from './ResourceReducer';
import type { ContentType } from '../common/constants/content-type';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type EpisodeContentBase = {
  id: number;
  contentTypeId: number;
  appPlatformId: number | null;
};

export type EpisodeContentNormalized = Merge<
  EpisodeContentBase,
  {
    resource: number;
  }
>;

export type EpisodeContent = Merge<
  EpisodeContentBase,
  {
    resource: Resource;
  }
>;

export type EpisodeContentByIdState = {
  [k: string | number]: EpisodeContentNormalized;
};
export type EpisodeContentState = {
  byId: EpisodeContentByIdState;
};

// export type AllowedContentTypes = $Values<typeof ContentType>;
type AllowedContentTypesNames = keyof typeof ContentType;
export type AllowedContentTypes = (typeof ContentType)[AllowedContentTypesNames];

//
//
export const getEmptyEpisodeContent = (id?: number): EpisodeContent => ({
  id: id ?? -1,
  contentTypeId: -1,
  appPlatformId: null,
  resource: getEmptyResource(),
});

const initialStateById: EpisodeContentByIdState = {};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): EpisodeContentByIdState => {
  switch (action.type) {
    case EPISODE_GET_DETAILS_OK:
    case EPISODE_GET_METADATA_OK:
    case EPISODELOCALIZED_ADD_CONTENT_OK:
      if (typeof action.payload.entities.episodeContent === 'undefined') {
        return state;
      }
      return {
        ...state,
        ...action.payload.entities.episodeContent,
      };

    case EPISODELOCALIZED_REMOVE_CONTENT_OK: {
      const { [action.payload.content.id]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
});

export default combined;
