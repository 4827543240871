import unorm from 'unorm';

export const formatStr = (
  num: number,
  versions: Record<string, string> = {},
  includeNum = false
) => {
  const prefix = includeNum === true ? `${num.toString()} ` : '';
  if (versions?.[num] != null) {
    return `${prefix}${versions[num]}`;
  }
  if (versions?.default != null) {
    return `${prefix}${versions.default}`;
  }
  return `#FORMAT#${num}#FORMAT#`;
};

export const normalizeUnicode = (text: string) => unorm.nfc(unorm.nfd(text));
