import { IGNORE, LIMIT, ValidationError } from '../../common/constants/batch-upload';
import { ResourceType, ResourceTypeNames } from '../../common/constants/resource-type';

export const steps = [
  {
    name: 'Create Sheet',
  },
  {
    name: 'Validate Sheet',
  },
  {
    name: 'Collect Files',
  },
  {
    name: 'Upload Content',
  },
];

export enum BatchUploadComponentState {
  // Sheet Creation
  LANDING_PAGE,
  GENERATING_TEMPLATE,
  VALID_TEMPLATE,
  TEMPLATE_GENERATED,
  // Sheet Upload
  COLLECTING_METADATA,
  METADATA_FOUND,
  VALIDATING,
  VALIDATION_SUCCESS,
  VALIDATION_FAILURE,
  VALIDATION_WARNINGS,
  // File Collection
  READY_TO_COLLECT,
  COLLECTING_FILES,
  ALL_COLLECTED,
  // Uploading
  QUEUING_ITEMS,
  UPLOADING_CLIENT,
  UPLOAD_DONE_FROM_CLIENT,
  CREATING_FILES,
  UPLOAD_SUCCESS,
  UPLOAD_MISSING_FILES,
}

export enum BatchUploadModalState {
  CONFIRM_CANCEL,
  CONFIRM_UPLOAD,
  VALIDATION_WAS_PREVIOUSLY_SUCCESSFUL,
  MISC_ERROR,
  EXISTING_UPLOAD,
  MULTIPART_CONFIG,
}

export const helpLinks = [
  {
    link: 'https://wonderz.eu/resources/batch-upload-create/',
    name: 'HowTo - Batch Uploader',
  },
  // TODO
  // {
  //   link: '',
  //   name: 'How to fill the WonderBox metadata spreadsheet',
  //   header: <div style={{ fontSize: '8px' }}>Video</div>,
  // },
  // {
  //   link: '',
  //   name: 'Typical Errors',
  // },
];

export const cancelCopy = (buttonName: string) => `
All content collected or uploaded will be deleted. No new episode will be created. Click ‘${buttonName}’ to cancel the upload and return to the episodes. You can restart the process from there at any time.
   `;

export const ErrorCopy = {
  [ValidationError.PARSE_ERROR]: {
    title: 'File could not be parsed',
    description: 'Check if this is really a CSV- or Excel-file – we had trouble parsing the file.',
  },
  [ValidationError.SHEET_TEMPLATE_MODIFIED]: {
    title: 'Template modified',
    description:
      'The metadata sheet template has been modified and cannot be read. Please generate a new template and fill it out again. Do not change the structure of the sheet or delete the master data sheet. Do not add or delete columns or the naming of the columns.',
  },
  [ValidationError.LIMIT_EXCEEDED]: {
    title: 'Limit exceeded',
    description: `Your spreadsheet contains too many files. There is a limit of ${LIMIT} files. Please split the spreadsheet into two smaller parts`,
  },
  [ValidationError.MANDATORY_FIELD_BLANK]: {
    title: 'Mandatory field blank',
    description:
      'Mandatory fields (marked with * in column title) need to be filled. Please complete the fields and upload the sheet again.',
  },
  [ValidationError.MANDATORY_FIELD_MARKED_WITH_IGNORE]: {
    title: `Mandatory field marked with ${IGNORE}`,
    description: `Mandatory fields (marked with * in column title) need to be filled with proper entries. Mandatory fields cannot be marked with '${IGNORE}' to leave them blank, unless an entire language version is marked in this fashion. Please complete the fields and upload the sheet again.`,
  },
  [ValidationError.INTERNAL_NAME_USED_MULTIPLE_TIMES]: {
    title: 'Internal name used multiple times',
    description:
      'Internal names must be unique since they clearly identify an episode. Please correct the entries and upload the sheet again.',
  },
  [ValidationError.INTERNAL_NAME_ALREADY_EXISTS]: {
    title: 'Internal name already exists in CMS',
    description:
      'You are using a template to create new episodes, so all internal names must be new. You cannot edit an existing episode via the batch uploader. Please correct the entry and upload the sheet again.',
  },
  [ValidationError.LANGUAGE_VERSION_ALREADY_EXISTS]: {
    title: 'Language version already exists',
    description:
      'The language you try to add already exists for this episode. You cannot edit or overwrite an existing language version via the batch uploader. Please remove or correct the entry and upload the sheet again.',
  },
  [ValidationError.INTERNAL_NAME_DOES_NOT_EXIST]: {
    title: 'Internal name does not exist',
    description:
      'You are using a template to add new language versions only. The language version cannot be added, becaused the internal name does not exist within the CMS yet. Please correct the entry and upload the sheet again. If you want to create a new episode, please create a new sheet template first and define the settings according to that task.',
  },
  [ValidationError.FILE_NAME_USED_MULTIPLE_TIMES]: {
    title: 'File name used multiple times',
    description:
      'Same file names mean that the same file will be used for multiple episodes and versions. Please insure that this is intended.',
  },
  [ValidationError.OPTIONAL_FIELD_BLANK]: {
    title: 'Optional field blank',
    description: `Should this field be blank? If yes, you can ignore this message. Otherwise please correct the entry and upload the sheet again. To not see this message again, please mark all optional fields you want to leave blank on purpose with '${IGNORE}'.`,
  },
  [ValidationError.FILE_CONTENT_TYPE_MISMATCH]: {
    title: 'File - content type mismatch',
    description:
      "The file type of this file does does not match the content type (e.g. trying to upload a .jpeg for 'Video') or is not supported. Please upload a different file type.",
  },
  [ValidationError.RESOURCE_TYPE_NOT_FOUND]: {
    title: 'Resource type not found',
    description: `This resource type is not recognized or not supported. Currently supported resource types include '${
      ResourceTypeNames[ResourceType.VIDEO_MEDIABOX]
    }'`,
    // TODO WBP-1886 additional content types
  },
  [ValidationError.TAG_NOT_FOUND]: {
    title: 'Tag not found',
    description:
      'This tag was not found in the system. Please add it to an existing episode to use it, or ignore this message.',
  },
  [ValidationError.LANGUAGE_NOT_FOUND]: {
    title: 'Language not found',
    description:
      'This language was not found or is not supported. Please generate a new template and fill it out again. Do not change the structure of the sheet or add/delete columns or change the naming of the columns.',
  },
};

export enum InvalidHeaderReason {
  // common
  INVALID_TITLE = 'INVALID_TITLE',
  INVALID_LANGUAGE_TITLE_GROUP = 'INVALID_LANGUAGE_TITLE_GROUP',
  INVALID_COMMON_TITLES = 'INVALID_COMMON_TITLES',
  // languages
  NO_LANGUAGES = 'NO_LANGUAGES',
  NOT_ENOUGH_COMMON_HEADERS = 'NOT_ENOUGH_COMMON_HEADERS',
  INVALID_COMMON_HEADERS = 'INVALID_COMMON_HEADERS',
  DIFFERENT_HEADERS_PER_LANGUAGE = 'DIFFERENT_HEADERS_PER_LANGUAGE',
  NOT_VALID_FOR_APP_CONFIG = 'NOT_VALID_FOR_APP_CONFIG',
  NOT_IN_APP_CONFIG_ORDER = 'NOT_IN_APP_CONFIG_ORDER',
  DIFFERENT_ORDER_PER_LANGUAGE = 'DIFFERENT_ORDER_PER_LANGUAGE',
}
