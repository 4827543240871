import { createSelector } from 'reselect';

import { getAppVersionsByIdsFunc } from './appVersionSelectors';
import { getPlatformByIdFunc } from './platformSelectors';
import { getEmptyAppPlatform } from '../reducers/AppPlatformReducer';

import type { RootState } from '../reducers';

//
//
const getById = (state: RootState) => state.appPlatforms.byId;
const getAllIds = (state: RootState) => state.appPlatforms.allIds;

//
//
export const getAppPlatformByIdFunc = createSelector(
  getById,
  getAppVersionsByIdsFunc,
  getPlatformByIdFunc,
  //
  (byId, $getAppVersionsByIds, $getPlatformById) => (id: number) => {
    // if (id === null) {
    //   return null;
    // }

    const appPlatform = byId[id];
    if (appPlatform == null) {
      return getEmptyAppPlatform(id);
    }

    return {
      ...appPlatform,
      platform: $getPlatformById(appPlatform.platform),
      versions: $getAppVersionsByIds(appPlatform.versions),
    };
  }
);

//
//
export const getAppPlatformById = createSelector(
  getAppPlatformByIdFunc,
  (_: RootState, id: number) => id,
  //
  ($getAppPlatformById, id) => $getAppPlatformById(id)
);

//
//
export const getAppPlatformsByIdsFunc = createSelector(
  getAppPlatformByIdFunc,
  //
  ($getAppPlatformById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id) => $getAppPlatformById(id));
  }
);

//
//
export const getAppPlatformsByIds = createSelector(
  getAppPlatformsByIdsFunc,
  (_: RootState, ids: Array<number>) => ids,
  //
  ($getAppPlatformsByIds, ids) => $getAppPlatformsByIds(ids)
);

//
//
export const getAppPlatformsForAppFunc = createSelector(
  getAllIds,
  getAppPlatformsByIdsFunc,
  //
  (allIds, $getAppPlatformsByIds) => (appId: number | null) => {
    if (appId == null) {
      return [];
    }
    const ids = allIds[appId];
    const appPlatforms = $getAppPlatformsByIds(ids);
    appPlatforms.sort(
      (a, b) =>
        a.platform.name.toLowerCase().charCodeAt(0) - b.platform.name.toLowerCase().charCodeAt(0)
    );
    return appPlatforms;
  }
);

//
//
export const getAppPlatformsForApp = createSelector(
  getAppPlatformsForAppFunc,
  (_: RootState, appId: number) => appId,
  //
  ($getAppPlatformsForApp, appId) => $getAppPlatformsForApp(appId)
);
