// import ExifImg from 'react-fix-image-orientation';

import Thumbnail from './Thumbnail';
import { ContentType } from '../common/constants/content-type';
import { getAllQueueItems } from '../selectors/queueSelectors';
import { getResourceByContentType } from '../utils/episodeLocalized';
import { useGlobalSelector } from '../utils/hooks';
import { getFileUploadStatus } from '../utils/queue';

import type { ContentTypesWithThumbnailPreview } from '../common/constants/content-type';
import type { EpisodeLocalized } from '../reducers/EpisodeLocalizedReducer';
import type { Episode } from '../reducers/EpisodeReducer';
import type { Resource } from '../reducers/ResourceReducer';

type Props = Readonly<{
  le?: EpisodeLocalized | null;
  episode?: Episode;
  width?: number;
  height?: number;
  isDefault?: boolean | null;
  disableSetThumbnail?: boolean | null;
  thumbnailContentType?: ContentTypesWithThumbnailPreview;
  onSetDefaultThumbnail?: (thumbnailId: number) => Promise<void>;
  displayTimeOverlay?: boolean;
  currentAppPlatform?: number | null;
  thumbContainerBackgroundColor?: string;
}>;

//
//
const EpisodeLocalizedThumbnail = (props: Props) => {
  const {
    le,
    episode,
    isDefault = null,
    thumbnailContentType = ContentType.EPISODE_THUMBNAIL,
    onSetDefaultThumbnail,
    displayTimeOverlay = false,
    disableSetThumbnail = true,
    currentAppPlatform = null,
    thumbContainerBackgroundColor = '#eee',
  } = props;
  let { width, height } = props;
  width = width != null ? width : 89;
  height = height != null ? height : 50;
  const queuedItems = useGlobalSelector(getAllQueueItems);
  let thumbnail: Resource | null = null;
  let content;

  if (episode != null) {
    ({ thumbnail } = episode);
  } else {
    thumbnail = getResourceByContentType(le, thumbnailContentType, null);
    content = getResourceByContentType(le, ContentType.EPISODE_CONTENT, currentAppPlatform);
  }

  const thumbnailInfo = getFileUploadStatus(
    le,
    queuedItems,
    thumbnailContentType,
    currentAppPlatform
  );

  return (
    <Thumbnail
      thumbnail={thumbnail}
      thumbnailInfo={thumbnailInfo}
      content={content}
      width={width}
      height={height}
      isDefault={isDefault}
      disableSetThumbnail={disableSetThumbnail}
      onSetDefaultThumbnail={onSetDefaultThumbnail}
      displayTimeOverlay={displayTimeOverlay}
      thumbContainerBackgroundColor={thumbContainerBackgroundColor}
    />
  );
};

export default EpisodeLocalizedThumbnail;
