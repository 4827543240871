import { createSelector } from 'reselect';

import { getUserByIdFunc } from './userSelectors';
import { getEmptyDeployment } from '../reducers/DeploymentReducer';
import { sortNewestFirst } from '../utils/sort';

import type { Deployment } from '../../typings/deployments';
import type { RootState } from '../reducers';
import type { Selector } from 'reselect';

//
//
const getById = (state: RootState) => state.deployments.byId;
const getAllIds = (state: RootState) => state.deployments.allIds;

//
//
export const getCreateDeploymentData = (state: RootState) => state.deployments.newDeploymentData;

//
//
export const getDeploymentDiff = (state: RootState) => state.deployments.deploymentDiff;

//
//
export const getDeploymentByIdFunc: Selector<RootState, (id: number) => Deployment> =
  createSelector(
    getById,
    getUserByIdFunc,
    //
    (byId, $getUserById) => (id: number) => {
      const deployment = byId[id];
      if (deployment == null) {
        return getEmptyDeployment(id);
      }

      return {
        ...deployment,
        creator: $getUserById(deployment.creator),
      };
    }
  );

//
//
export const getDeploymentById = createSelector(
  getDeploymentByIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getDeploymentById, id) => $getDeploymentById(id)
);

//
//
export const getAllDeploymentForApp = createSelector(
  getAllIds,
  getDeploymentByIdFunc,
  (_: unknown, appId: number) => appId,
  //
  (allIds, $getDeploymentById, appId) => {
    const ids = allIds[appId];
    if (!Array.isArray(ids)) {
      return [];
    }
    const arr = ids.map((id) => $getDeploymentById(id));
    arr.sort(sortNewestFirst);
    return arr;
  }
);
