import { combineReducers } from 'redux';

import { EPISODE_GET_VIEWS_OK } from '../constants/analytics';

import type { DayViews } from '../common/types/analytics';
import type { AnyAction } from 'redux';

type AnalyticsViewsEpisodedLocalized = {
  viewTrend: number | null;
  viewsIn28Days: number | null;
  viewsInPrior28Days: number | null;
  viewDays: DayViews;
};

export type AnalyticsViewsEpisodedLocalizedByIdState = {
  [k: string | number]: AnalyticsViewsEpisodedLocalized;
};

export type AnalyticsViewsEpisodedLocalizedState = {
  byId: AnalyticsViewsEpisodedLocalizedByIdState;
};

export const getEmptyAnalyticsViewsEpisodedLocalized = (): AnalyticsViewsEpisodedLocalized => ({
  viewTrend: null,
  viewsIn28Days: null,
  viewsInPrior28Days: null,
  viewDays: [],
});

const initialStateById: AnalyticsViewsEpisodedLocalizedByIdState = {};

const byId = (
  state = initialStateById,
  action: AnyAction
): AnalyticsViewsEpisodedLocalizedByIdState => {
  switch (action.type) {
    case EPISODE_GET_VIEWS_OK: {
      return { ...state, ...action.payload.entities.localizedEpisodes };
    }

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
});

export default combined;
