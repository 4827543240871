import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import AdminUiVersionReducer from './AdminUiVersionReducer';
import AnalyticsReducer from './AnalyticsReducer';
import AnalyticsViewsEpisodesLocalizedReducer from './AnalyticsViewsEpisodesLocalizedReducer';
import AnalyticsViewsEpisodesReducer from './AnalyticsViewsEpisodesReducer';
import AppAnalyticsReducer from './AppAnalyticsReducer';
import AppAuthServiceReducer from './AppAuthServiceReducer';
import AppPlatformReducer from './AppPlatformReducer';
import AppReceiptValidatorReducer from './AppReceiptValidatorReducer';
import AppsReducer from './AppsReducer';
import AppUserReducer from './AppUsersReducer';
import AppVersionReducer from './AppVersionReducer';
import AssetReducer from './AssetReducer';
import AssetResourceReducer from './AssetResourceReducer';
import AssetResourceVersionRequirementReducer from './AssetResourceVersionRequirementReducer';
import AuthReducer from './AuthReducer';
import AuthServiceReducer from './AuthServiceReducer';
import BatchUploadReducer from './BatchUploadReducer';
import calendarFeedReducer from './CalendarFeedReducer';
import CategoryReducer from './CategoryReducer';
import CountryReducer from './CountryReducer';
import DeploymentReducer from './DeploymentReducer';
import EpisodeContentReducer from './EpisodeContentReducer';
import EpisodeLocalizedReducer from './EpisodeLocalizedReducer';
import EpisodeReducer from './EpisodeReducer';
import EpisodeTagReducer from './EpisodeTagReducer';
import episodeTopTenReducer from './EpisodeTopTenReducer';
import EpisodeVersionRequirementReducer from './EpisodeVersionRequirementReducer';
import FileReducer from './FileReducer';
import InviteReducer from './InviteReducer';
import JobLogReducer from './JobLogReducer';
import JobReducer from './JobReducer';
import LocaleReducer from './LocaleReducer';
import PageHistoryReducer from './PageHistoryReducer';
import PlatformReducer from './PlatformReducer';
import QueueReducer from './QueueReducer';
import RegionCountryReducer from './RegionCountryReducer';
import RegionLocaleReducer from './RegionLocaleReducer';
import RegionReducer from './RegionReducer';
import ReleaseCategoryReducer from './ReleaseCategoryReducer';
import ReleaseReducer from './ReleaseReducer';
import ResourceReducer from './ResourceReducer';
import RoleReducer from './RoleReducer';
import SyncingReducer from './SyncingReducer';
import TagReducer from './TagReducer';
import TimezoneReducer from './TimezoneReducer';
import UsersReducer from './UsersReducer';
import { LOGOUT_OK } from '../constants/auth';
import { isUserLoggedIn } from '../selectors/authSelectors';

import type { AdminUiVersionState } from './AdminUiVersionReducer';
import type { AnalyticsExportState } from './AnalyticsReducer';
import type { AnalyticsViewsEpisodedLocalizedState } from './AnalyticsViewsEpisodesLocalizedReducer';
import type { AnalyticsViewsEpisodesState } from './AnalyticsViewsEpisodesReducer';
import type { AppAnalyticsState } from './AppAnalyticsReducer';
import type { AppAuthServiceState } from './AppAuthServiceReducer';
import type { AppPlatformState } from './AppPlatformReducer';
import type { AppReceiptValidatorState } from './AppReceiptValidatorReducer';
import type { AppState } from './AppsReducer';
import type { AppUsersState } from './AppUsersReducer';
import type { AppVersionState } from './AppVersionReducer';
import type { AssetState } from './AssetReducer';
import type { AssetResourceState } from './AssetResourceReducer';
import type { AssetResourceVersionRequirementState } from './AssetResourceVersionRequirementReducer';
import type { AuthState } from './AuthReducer';
import type { AuthServiceState } from './AuthServiceReducer';
import type { BatchUploadState } from './BatchUploadReducer';
import type { CalendarFeedState } from './CalendarFeedReducer';
import type { CategoryState } from './CategoryReducer';
import type { CountryState } from './CountryReducer';
import type { EpisodeContentState } from './EpisodeContentReducer';
import type { EpisodeLocalizedState } from './EpisodeLocalizedReducer';
import type { EpisodeState } from './EpisodeReducer';
import type { EpisodeTagState } from './EpisodeTagReducer';
import type { EpisodeTopTenCombinedState } from './EpisodeTopTenReducer';
import type { EpisodeVersionRequirementState } from './EpisodeVersionRequirementReducer';
import type { FileState } from './FileReducer';
import type { InviteState } from './InviteReducer';
import type { JobLogState } from './JobLogReducer';
import type { JobState } from './JobReducer';
import type { LocaleState } from './LocaleReducer';
import type { PageHistoryState } from './PageHistoryReducer';
import type { PlatformState } from './PlatformReducer';
import type { QueueState } from './QueueReducer';
import type { RegionCountryState } from './RegionCountryReducer';
import type { RegionLocaleState } from './RegionLocaleReducer';
import type { RegionState } from './RegionReducer';
import type { ReleaseCategoryState } from './ReleaseCategoryReducer';
import type { ReleaseState } from './ReleaseReducer';
import type { ResourceState } from './ResourceReducer';
import type { RoleState } from './RoleReducer';
import type { SyncingState } from './SyncingReducer';
import type { MbxTagState } from './TagReducer';
import type { TimezoneState } from './TimezoneReducer';
import type { UserState } from './UsersReducer';
import type { DeploymentState } from '../../typings/deployments';
import type { AnyAction } from 'redux';

export type RootState = {
  auth: AuthState;
  users: UserState;
  apps: AppState;
  batchUploads: BatchUploadState;
  regions: RegionState;
  regionCountries: RegionCountryState;
  regionLocales: RegionLocaleState;
  timezones: TimezoneState;
  countries: CountryState;
  locales: LocaleState;
  episodes: EpisodeState;
  localizedEpisodes: EpisodeLocalizedState;
  resources: ResourceState;
  files: FileState;
  queue: QueueState;
  releases: ReleaseState;
  categories: CategoryState;
  releaseCategories: ReleaseCategoryState;
  deployments: DeploymentState;
  jobs: JobState;
  jobLogs: JobLogState;
  assets: AssetState;
  assetResources: AssetResourceState;
  episodeContent: EpisodeContentState;
  episodeTags: EpisodeTagState;
  tags: MbxTagState;
  adminUiVersion: AdminUiVersionState;
  roles: RoleState;
  invites: InviteState;
  syncing: SyncingState;
  pageHistory: PageHistoryState;
  platforms: PlatformState;
  appPlatforms: AppPlatformState;
  appVersions: AppVersionState;
  episodeVersionRequirements: EpisodeVersionRequirementState;
  assetResourceVersionRequirements: AssetResourceVersionRequirementState;
  appAuthServices: AppAuthServiceState;
  authServices: AuthServiceState;
  analyticsExports: AnalyticsExportState;
  appUser: AppUsersState;
  calendarFeed: CalendarFeedState;
  appReceiptValidators: AppReceiptValidatorState;
  episodeTopTen: EpisodeTopTenCombinedState;
  analyticsViewsEpisodes: AnalyticsViewsEpisodesState;
  analyticsViewsEpisodesLocalized: AnalyticsViewsEpisodedLocalizedState;
  appAnalytics: AppAnalyticsState;
};

export const rootReducers = {
  auth: AuthReducer,
  users: UsersReducer,
  apps: AppsReducer,
  batchUploads: BatchUploadReducer,
  regions: RegionReducer,
  regionCountries: RegionCountryReducer,
  regionLocales: RegionLocaleReducer,
  timezones: TimezoneReducer,
  countries: CountryReducer,
  locales: LocaleReducer,
  episodes: EpisodeReducer,
  localizedEpisodes: EpisodeLocalizedReducer,
  resources: ResourceReducer,
  files: FileReducer,
  queue: QueueReducer,
  releases: ReleaseReducer,
  categories: CategoryReducer,
  releaseCategories: ReleaseCategoryReducer,
  deployments: DeploymentReducer,
  jobs: JobReducer,
  jobLogs: JobLogReducer,
  assets: AssetReducer,
  assetResources: AssetResourceReducer,
  episodeContent: EpisodeContentReducer,
  episodeTags: EpisodeTagReducer,
  tags: TagReducer,
  adminUiVersion: AdminUiVersionReducer,
  roles: RoleReducer,
  invites: InviteReducer,
  syncing: SyncingReducer,
  pageHistory: PageHistoryReducer,
  platforms: PlatformReducer,
  appPlatforms: AppPlatformReducer,
  appVersions: AppVersionReducer,
  episodeVersionRequirements: EpisodeVersionRequirementReducer,
  assetResourceVersionRequirements: AssetResourceVersionRequirementReducer,
  appAuthServices: AppAuthServiceReducer,
  authServices: AuthServiceReducer,
  analyticsExports: AnalyticsReducer,
  appUser: AppUserReducer,
  calendarFeed: calendarFeedReducer,
  appReceiptValidators: AppReceiptValidatorReducer,
  episodeTopTen: episodeTopTenReducer,
  analyticsViewsEpisodes: AnalyticsViewsEpisodesReducer,
  analyticsViewsEpisodesLocalized: AnalyticsViewsEpisodesLocalizedReducer,
  appAnalytics: AppAnalyticsReducer,
};

const appReducer = combineReducers(rootReducers);
export type AppReducerState = ReturnType<typeof appReducer>;

export type GlobalStateGetter = () => RootState;

export default function setupReducers(state: AppReducerState, action: AnyAction) {
  if (action.type === LOGOUT_OK && isUserLoggedIn(state)) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');

    // Primary login url comes from store
    if (state?.auth?.loginUrl != null) {
      window.open(state.auth.loginUrl, '_self');
    } // check if we should redirect after logout
    else if (action.redirectUrl != null) {
      window.open(action.redirectUrl, '_self');
    }
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}
