import { createSelector } from 'reselect';

import type { RootState } from '../reducers';
import type { EpisodeStats } from '../reducers/EpisodeTopTenReducer';
import type { Selector } from 'reselect';

const getCurrentWeek = (state: RootState) => state.episodeTopTen.currentWeek;
const getAllTime = (state: RootState) => state.episodeTopTen.allTime;

//
//
const getEpisodeTopTenCurrentWeekByAppIdFunc: Selector<
  RootState,
  (id?: number) => Array<EpisodeStats> | null
> = createSelector(getCurrentWeek, ($getCurrentWeek) => (appId) => {
  if (appId == null) {
    return [];
  }
  const items = $getCurrentWeek[appId];
  return items == null ? null : items;
});

//
//
export const getEpisodeTopTenCurrentWeek = createSelector(
  getEpisodeTopTenCurrentWeekByAppIdFunc,
  (_: unknown, appId?: number) => appId,
  ($getByAppId, appId) => $getByAppId(appId)
);

//
//
const getEpisodeTopTenAllTimeByAppIdFunc: Selector<
  RootState,
  (id?: number) => Array<EpisodeStats> | null
> = createSelector(getAllTime, ($getAllTime) => (appId) => {
  if (appId == null) {
    return [];
  }
  const items = $getAllTime[appId];
  return items == null ? null : items;
});

//
//
//
//
export const getEpisodeTopTenAllTime = createSelector(
  getEpisodeTopTenAllTimeByAppIdFunc,
  (_: unknown, appId?: number) => appId,
  ($getByAppId, appId) => $getByAppId(appId)
);
