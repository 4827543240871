export const BATCH_UPLOAD_CREATE = 'BATCH_UPLOAD_CREATE';
export const BATCH_UPLOAD_CREATE_OK = 'BATCH_UPLOAD_CREATE_OK';
export const BATCH_UPLOAD_CREATE_FAIL = 'BATCH_UPLOAD_CREATE_FAIL';

export const BATCH_UPLOAD_UPDATE = 'BATCH_UPLOAD_UPDATE';
export const BATCH_UPLOAD_UPDATE_OK = 'BATCH_UPLOAD_UPDATE_OK';
export const BATCH_UPLOAD_UPDATE_FAIL = 'BATCH_UPLOAD_UPDATE_FAIL';

export const BATCH_UPLOAD_RESET = 'BATCH_UPLOAD_RESET';

export const BATCH_UPLOAD_GET = 'BATCH_UPLOAD_GET';
export const BATCH_UPLOAD_GET_OK = 'BATCH_UPLOAD_GET_OK';
export const BATCH_UPLOAD_GET_FAIL = 'BATCH_UPLOAD_GET_FAIL';
