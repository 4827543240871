import { LogoutOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';

import { getValueByName } from '../common/utils/configuration';
import MbxButton from '../components/base/MbxButton';
import { ROUTE_NAME_APP_HOME } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { isUserSuperadmin } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { App } from '../reducers/AppsReducer';
import type { User } from '../reducers/UsersReducer';

type Props = Readonly<{
  profile: User;
  apps: Array<App>;
  logout: () => void;
  openTwofaPage: () => void;
  deactivateTwofa: () => Promise<void>;
}>;

//
//
const Profile = (props: Props) => {
  const { apps, profile, logout, openTwofaPage, deactivateTwofa } = props;
  const { firstname, lastname, email, imageUrl, twofaActivated } = profile;

  const isSuperadmin = useGlobalSelector(isUserSuperadmin);

  return (
    <div style={{ width: '80%', display: 'flex' }}>
      <div style={{ width: 150, marginRight: '2em' }}>
        <Avatar size={150} src={imageUrl} shape="square" style={{ marginBottom: '1em' }} />
        <MbxButton
          data-tid="btn-logout"
          onClick={logout}
          icon={<LogoutOutlined />}
          style={{ width: '100%' }}
        >
          Logout
        </MbxButton>
      </div>
      <div>
        <h1>{`${firstname} ${lastname}`}</h1>
        <p className="lead">{email}</p>

        <div style={{ marginTop: '2em', marginBottom: '2em' }}>
          <h3 style={{ marginBottom: 0 }}>2FA settings</h3>
          <p
            data-tid="profile-twofa-status"
            style={{ marginTop: 0, marginBottom: '0.5em' }}
          >{`status: ${twofaActivated ? 'on' : 'off'}`}</p>
          <MbxButton size="small" onClick={openTwofaPage}>
            edit
          </MbxButton>
          <MbxButton
            data-tid="btn-twofa-disable"
            disabled={!twofaActivated}
            size="small"
            danger
            onClick={() => {
              deactivateTwofa();
            }}
            style={{ marginLeft: 10 }}
          >
            disable
          </MbxButton>
        </div>

        <span>{`Member of ${apps.length} app(s)`}</span>
        {Array.isArray(apps) && apps.length > 0 && (
          <ul data-tid="profile-app-list">
            {apps.map((app) => {
              const appForcingTwofa = getValueByName(app, 'appData.forceTwofa', false);
              const isDisabledBcTwofaForce = (appForcingTwofa || isSuperadmin) && !twofaActivated;

              return (
                <li key={app.uid}>
                  {isDisabledBcTwofaForce && (
                    <span style={{ color: 'red' }}>{`${app.name} (needs 2FA)`}</span>
                  )}
                  {!isDisabledBcTwofaForce && (
                    <Link
                      to={getUrlByName(ROUTE_NAME_APP_HOME, {
                        ':appUid': app.uid,
                      })}
                    >
                      {app.name}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Profile;
