import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useState } from 'react';

import styles from './EpisodeDetail.module.scss';
import AnalyticsEpisode from '../components/AnalyticsEpisode';
import { LocalDateFootnote } from '../components/base/LocalReleaseDates';
import MbxButton from '../components/base/MbxButton';
import CustomPageHeader from '../components/CustomPageHeader';
import EpisodeEditForm from '../components/EpisodeEditForm';
import EpisodeInfo from '../components/EpisodeInfo';
import EpisodeLocalizationList from '../components/EpisodeLocalizationList';
import EpisodeReleaseStatus from '../components/EpisodeReleaseStatus';
import ItemVersionRequirementsList from '../components/ItemVersionRequirementsList';
import PlayerTypeBubble from '../components/PlayerTypeBubble';
import { ROUTE_NAME_APP_EPISODES } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

import type { EpisodeEditFormValues } from '../components/EpisodeEditForm';
import type { App } from '../reducers/AppsReducer';
import type { Episode } from '../reducers/EpisodeReducer';

type Props = Readonly<{
  currentApp: App;
  episodeDetails: Episode;
  isBusy: boolean;
  handleEpisodeUpdate: (values: EpisodeEditFormValues) => Promise<boolean>;
  handleEpisodeDelete: (episode: Episode) => Promise<void>;
}>;

//
//
const EpisodeDetail = (props: Props) => {
  const { currentApp, episodeDetails, handleEpisodeUpdate, handleEpisodeDelete, isBusy } = props;

  const backLink = getUrlByName(ROUTE_NAME_APP_EPISODES, {
    ':appUid': currentApp.uid,
  });

  const [editMode, setEditMode] = useState(false);

  const handleActivateEditMode = () => {
    setEditMode(true);
  };

  const handleSave = async (values: EpisodeEditFormValues) => {
    const successful = await handleEpisodeUpdate(values);
    if (successful === true) {
      setEditMode(false);
    }
    return true;
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const deleteEpisode = () => {
    if (window.confirm(`Really delete ${episodeDetails.name}?`)) {
      handleEpisodeDelete(episodeDetails);
    }
  };

  return (
    <div>
      <CustomPageHeader
        backLink={backLink}
        title={
          <div style={{ display: 'flex' }}>
            <PlayerTypeBubble episode={episodeDetails} />
            <span style={{ marginLeft: 4 }}>{`${episodeDetails.name}`}</span>
          </div>
        }
        extra={[
          <MbxButton
            size="large"
            onClick={handleActivateEditMode}
            icon={<EditOutlined />}
            data-tid="episodeDetailInfoEditBtn"
            key="btn-edit-episode"
            disabled={editMode === true}
          >
            edit
          </MbxButton>,
          <MbxButton
            danger
            size="large"
            onClick={deleteEpisode}
            icon={<DeleteOutlined />}
            data-tid="btn-delete-episode"
            key="btn-delete-episode"
          >
            delete episode
          </MbxButton>,
        ]}
      />

      <div className={styles.formContainer}>
        {editMode === true && (
          <div className={styles.episodeEditbox}>
            <div style={{ maxWidth: 600 }}>
              <EpisodeEditForm
                data={episodeDetails}
                onCancelOrBacklink={handleCancel}
                onSubmit={handleSave}
                isBusy={isBusy}
              />
            </div>
          </div>
        )}
        {editMode === false && <EpisodeInfo data={episodeDetails} />}

        <EpisodeLocalizationList episode={episodeDetails} app={currentApp} />
        <AnalyticsEpisode episode={episodeDetails} />
        <EpisodeReleaseStatus episode={episodeDetails} app={currentApp} />
        <ItemVersionRequirementsList item={episodeDetails} app={currentApp} />
      </div>

      <LocalDateFootnote />
    </div>
  );
};

// EpisodeDetail.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: 'EpisodeDetail',
// };

export default EpisodeDetail;
