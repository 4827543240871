import { Alert } from 'antd';

const Forbidden = () => (
  <div>
    <Alert
      showIcon
      type="error"
      message="403"
      description="You don't have permission to access this page."
    />
  </div>
);

export default Forbidden;
