import { combineReducers } from 'redux';

import { getEmptyTimezone } from './TimezoneReducer';
import { REGIONLOCALE_CREATE_OK } from '../constants/regionLocales';
import {
  REGION_GET_LIST_OK,
  REGION_GET_DETAILS_OK,
  REGION_CREATE_OK,
  REGION_UPDATE_OK,
  REGION_REMOVE_OK,
} from '../constants/regions';
import { RELEASE_GET_DETAILS_OK } from '../constants/releases';

import type { RegionCountry } from './RegionCountryReducer';
import type { RegionLocale } from './RegionLocaleReducer';
import type { Timezone } from './TimezoneReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type RegionBase = {
  id: number;
  appId: number;
  name: string;
  defaultLocaleId: number;
  isDraft: boolean;
  useDefaultAsFallback: boolean;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date | null;
};

export type RegionNormalized = Merge<
  RegionBase,
  {
    releaseTimezone: number;
    regionCountries: Array<number>;
    regionLocales: Array<number>;
  }
>;

export type Region = Merge<
  RegionBase,
  {
    releaseTimezone: Timezone;
    regionCountries: Array<RegionCountry>;
    regionLocales: Array<RegionLocale>;
  }
>;

export type RegionByIdState = {
  [k: string | number]: RegionNormalized;
};
export type RegionAllIdsState = {
  [k: string | number]: Array<number>;
};
export type RegionState = {
  byId: RegionByIdState;
  allIds: RegionAllIdsState;
};

const initialStateById: RegionByIdState = {};
const initialStateAllIds: RegionAllIdsState = {};

//
//
export const getEmptyRegion = (id?: number): Region => ({
  id: id ?? -1,
  appId: -1,
  name: '',
  isDraft: false,
  useDefaultAsFallback: true,
  createdAt: new Date(),
  createdBy: -1,
  updatedAt: null,
  releaseTimezone: getEmptyTimezone(),
  defaultLocaleId: -1,
  regionCountries: [],
  regionLocales: [],
});

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): RegionByIdState => {
  switch (action.type) {
    case REGION_GET_LIST_OK:
    case RELEASE_GET_DETAILS_OK: {
      if (typeof action.payload.entities.regions === 'undefined') {
        return state;
      }

      const newState = { ...state };

      Object.keys(action.payload.entities.regions).forEach((key) => {
        const region = { ...action.payload.entities.regions[key] };
        delete region.regionCountries;
        delete region.regionLocales;
        newState[key] = region;
      });

      return newState;
    }

    case REGION_UPDATE_OK:
    case REGION_GET_DETAILS_OK: {
      const id = action.payload.result;
      const copy = { ...action.payload.entities.regions[id] };
      delete copy.regionCountries;
      delete copy.regionLocales;
      return {
        ...state,
        [id]: copy,
      };
    }

    case REGION_CREATE_OK: {
      const id = action.payload.result;
      return {
        ...state,
        [id]: action.payload.entities.regions[id],
      };
    }

    case REGION_REMOVE_OK: {
      const { [action.region.id]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case REGIONLOCALE_CREATE_OK: {
      const localeId = action.payload.result;
      const { regionId } = action;
      const region = state[regionId];
      if (
        typeof region !== 'undefined' &&
        (region.defaultLocaleId === null || typeof region.defaultLocaleId === 'undefined')
      ) {
        return {
          ...state,
          [regionId]: {
            ...state[regionId],
            defaultLocaleId: localeId,
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
};

//
//
// eslint-disable-next-line default-param-last
const allIds = (state = initialStateAllIds, action: AnyAction): RegionAllIdsState => {
  switch (action.type) {
    case REGION_GET_LIST_OK:
      return {
        ...state,
        [action.appId]: action.payload.result,
      };

    case REGION_CREATE_OK: {
      return {
        ...state,
        [action.appId]: [...state[action.appId], action.payload.result],
      };
    }

    case REGION_REMOVE_OK:
      return {
        ...state,
        [action.region.appId]: state[action.region.appId].filter(
          (regionId) => regionId !== action.region.id
        ),
      };

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
});

export default combined;
