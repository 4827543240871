export const RESOURCE_CREATE = 'RESOURCE_CREATE';
export const RESOURCE_CREATE_OK = 'RESOURCE_CREATE_OK';
export const RESOURCE_CREATE_FAIL = 'RESOURCE_CREATE_FAIL';

export const RESOURCE_REMOVE = 'RESOURCE_REMOVE';
export const RESOURCE_REMOVE_OK = 'RESOURCE_REMOVE_OK';
export const RESOURCE_REMOVE_FAIL = 'RESOURCE_REMOVE_FAIL';

export const RESOURCE_RERUN_CONVERSION = 'RESOURCE_RERUN_CONVERSION';
export const RESOURCE_RERUN_CONVERSION_OK = 'RESOURCE_RERUN_CONVERSION_OK';
export const RESOURCE_RERUN_CONVERSION_FAIL = 'RESOURCE_RERUN_CONVERSION_FAIL';
