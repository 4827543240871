import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useState } from 'react';

const { Search } = Input;

type Props = {
  value: string;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  label?: string;
  id?: string;
  ['data-tid']?: string;
};

export const CopyInput = (props: Props) => {
  const { value, icon: propsIcon, style, label, id: propsId, 'data-tid': dataTid } = props;
  const [icon, setIcon] = useState(propsIcon);
  const [id, setId] = useState(propsId);
  const [originalIcon, setOriginalIcon] = useState(icon);

  if (icon == null) {
    setIcon(<CopyOutlined />);
  }

  if (id == null) {
    setId(`copy-input-${Math.random()}`);
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setOriginalIcon(icon);
    setIcon(<CheckOutlined style={{ color: 'green' }} />);

    setTimeout(() => {
      setIcon(originalIcon);
    }, 5000);
  };

  return (
    <>
      {label != null && (
        <label htmlFor={id} style={{ fontSize: '12px' }}>
          {label}
        </label>
      )}
      <Search
        data-tid={dataTid}
        id={id}
        style={style}
        value={value}
        enterButton={icon}
        onSearch={handleCopy}
        onFocus={(e) => e.target.select()}
      />
    </>
  );
};
