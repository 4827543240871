import { combineReducers } from 'redux';

import { EPISODE_GET_TOPTEN_ALL_TIME_OK, EPISODE_GET_TOPTEN_WEEK_OK } from '../constants/analytics';

import type { AnyAction } from 'redux';

export type EpisodeStats = { totalViews: number; episodeId: number; episodeName: string };

type EpisodeTopTenState = Record<number, Array<EpisodeStats>>;

export type EpisodeTopTenCombinedState = {
  currentWeek: EpisodeTopTenState;
  allTime: EpisodeTopTenState;
};

const initialState: EpisodeTopTenState = {};

const currentWeekByAppId = (state = initialState, action: AnyAction): EpisodeTopTenState => {
  switch (action.type) {
    case EPISODE_GET_TOPTEN_WEEK_OK: {
      const { appId } = action;
      const items = action.payload as Array<EpisodeStats>;
      const newState = { ...state };
      newState[appId] = items.sort((a, b) => a.totalViews - b.totalViews);

      return newState;
    }
    default:
      return state;
  }
};

const allTimeByAppId = (state = initialState, action: AnyAction): EpisodeTopTenState => {
  switch (action.type) {
    case EPISODE_GET_TOPTEN_ALL_TIME_OK: {
      const { appId } = action;
      const items = action.payload as Array<EpisodeStats>;
      const newState = { ...state };
      newState[appId] = items.sort((a, b) => a.totalViews - b.totalViews);

      return newState;
    }
    default:
      return state;
  }
};

const combined = combineReducers({
  currentWeek: currentWeekByAppId,
  allTime: allTimeByAppId,
});

export default combined;
