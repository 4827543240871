import { EditOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Tag, Tooltip } from 'antd';

import styles from './RegionList.module.scss';
import MbxButton from '../components/base/MbxButton';
import CountryTag from '../components/CountryTag';
import CustomPageHeader from '../components/CustomPageHeader';
import RegionLocaleDefaultTag from '../components/RegionLocaleDefaultTag';

import type { Region } from '../reducers/RegionReducer';

type Props = Readonly<{
  allRegions: Array<Region>;

  addRegion: () => void;
  editRegion: (region: Region) => void;
}>;

//
//
const RegionList = (props: Props) => {
  const { allRegions, addRegion, editRegion } = props;

  return (
    <div>
      <CustomPageHeader
        title="List of all markets"
        extra={[
          <MbxButton
            mbxType="primary"
            onClick={addRegion}
            icon={<PlusOutlined />}
            data-tid="btn-add-region"
            key="btn-add-region"
          >
            add market
          </MbxButton>,
        ]}
      />

      <List
        style={{
          marginTop: '2em',
        }}
        bordered={false}
        dataSource={allRegions}
        renderItem={(region) => {
          const { defaultLocaleId } = region;
          return (
            <List.Item
              className={styles.item}
              onClick={() => {
                editRegion(region);
              }}
              key={region.id}
              actions={[
                <MbxButton
                  key="btn-edit-region"
                  mbxType="secondary"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    editRegion(region);
                  }}
                >
                  edit
                </MbxButton>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  region.regionCountries.length === 0 || region.regionLocales.length === 0 ? (
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          backgroundColor: '#FF8163',
                          width: 24,
                          padding: '10px 2px',
                          borderRadius: 4,
                          fontSize: 20,
                          color: '#fff',
                        }}
                        data-tid="unreleasable-release-mark"
                      >
                        <Tooltip
                          title={
                            <>
                              <h3>Market incomplete</h3>
                              <p>
                                Add at least one country and one language to start releasing content
                                in this market.
                              </p>
                            </>
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    </div>
                  ) : null
                }
                title={
                  <>
                    <span style={{ paddingRight: '1em' }}>{region.name}</span>
                    {region.isDraft && <Tag color="orange">draft</Tag>}
                  </>
                }
                description={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <span
                      style={{
                        paddingRight: 10,
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      {region.regionCountries.map((rc) => (
                        <CountryTag regionCountry={rc} key={`rc-${rc.id}`} />
                      ))}
                    </span>
                    {region.regionLocales.map((rl) => {
                      if (rl.id === defaultLocaleId) {
                        return (
                          <RegionLocaleDefaultTag
                            key={`rl-${rl.id}`}
                            className={styles.tag}
                            label={`${rl.locale.shortcode} `}
                            useDefaultAsFallback={region.useDefaultAsFallback}
                          />
                        );
                      }
                      return (
                        <Tag
                          key={`rl-${rl.id}`}
                          className={styles.tag}
                          color={rl.isDraft === true ? 'orange' : undefined}
                        >{`${rl.locale.shortcode} `}</Tag>
                      );
                    })}
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default RegionList;
