export enum TrendTypes {
  UP = 'up',
  DOWN = 'down',
  NEUTRAL = 'neutral',
}

export const getTrendType = (trend: null | undefined | number): string => {
  if (trend == null || trend === 0) {
    return TrendTypes.NEUTRAL;
  }

  if (trend > 0) {
    return TrendTypes.UP;
  }

  return TrendTypes.DOWN;
};

export const getTrendString = (trend: null | undefined | number): string => {
  if (trend == null) {
    return 'n/a';
  }

  if (trend === 0) {
    return '0%';
  }

  if (trend > 0) {
    return `+${trend}%`;
  }

  return `${trend}%`;
};
