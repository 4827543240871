import type { AllowedContentTypes } from '../reducers/EpisodeContentReducer';
import type { EpisodeLocalized } from '../reducers/EpisodeLocalizedReducer';
import type { QueueItem } from '../reducers/QueueReducer';
import type { Resource } from '../reducers/ResourceReducer';

export type FileInfo = {
  name: string;
  queued: boolean;
  percentUploaded: number;
};

//
//
export const getFileUploadStatus = (
  le: EpisodeLocalized | null | undefined,
  queuedItems: Array<QueueItem>,
  type: AllowedContentTypes,
  appPlatformId: number | null
): FileInfo => {
  const fileInfo = {
    name: '',
    queued: false,
    percentUploaded: -1,
  };

  if (le == null) {
    return fileInfo;
  }

  const item = queuedItems.find(
    (i) =>
      le.id === i.episodeLocalizedId &&
      i.contentType === type &&
      i.appPlatformId === appPlatformId &&
      i.state !== 'done'
  );
  if (
    item != null &&
    (item.state === 'added' || item.state === 'initializing' || item.state === 'uploading')
  ) {
    fileInfo.name = item?.fileRef?.name ?? '??';
    fileInfo.queued = true;
    fileInfo.percentUploaded = item?.percent ?? -1;
  } else {
    // TODO: check if this needs cleanup
    // const file = le?.type ?? null;
    // if (file != null) {
    //   fileInfo.name = file?.file?.originalName ?? '??';
    // }
  }

  return fileInfo;
};

//
//
export const getFileUploadStatusForResource = ({
  id,
  resource,
  queuedItems,
}: {
  id: number | null;
  resource: Resource | null | undefined;
  queuedItems: Array<QueueItem>;
}): FileInfo => {
  const fileInfo = {
    name: '',
    queued: false,
    percentUploaded: -1,
  };

  if (resource == null) {
    return fileInfo;
  }

  const item = queuedItems.find((i) => id === i.resultId && i.resourceType === resource.typeId);
  if (
    typeof item !== 'undefined' &&
    (item.state === 'added' || item.state === 'initializing' || item.state === 'uploading')
  ) {
    fileInfo.name = item?.fileRef?.name ?? '??';
    fileInfo.queued = true;
    fileInfo.percentUploaded = item?.percent ?? -1;
  } else if (resource != null) {
    fileInfo.name = resource.file?.originalName ?? '??';
  }

  return fileInfo;
};
