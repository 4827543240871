import { SYNC_COUNTER_INCREMENT, SYNC_COUNTER_DECREMENT, SYNC_ERROR } from '../constants/syncing';

//
export const counterIncrement = () => ({
  type: SYNC_COUNTER_INCREMENT,
});

//
export const counterDecrement = () => ({
  type: SYNC_COUNTER_DECREMENT,
});

//
export const displayError = (errMsg: string) => ({
  type: SYNC_ERROR,
  error: {
    id: Math.random().toString(),
    message: errMsg,
  },
});
