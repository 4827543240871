import { combineReducers } from 'redux';

import { getEmptyResource } from './ResourceReducer';
import { ASSETRESOURCE_CREATE_OK, ASSETRESOURCE_DELETE_OK } from '../constants/assetresources';
import {
  ASSETRESOURCEVERSIONREQUIREMENTS_CREATE_OK,
  ASSETRESOURCEVERSIONREQUIREMENTS_DELETE_OK,
} from '../constants/assetResourceVersionRequirements';
import { ASSET_GET_METADATA_OK, ASSET_GET_DETAILS_OK } from '../constants/assets';

import type { AssetResourceVersionRequirement } from './AssetResourceVersionRequirementReducer';
import type { Resource } from './ResourceReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type AssetResourceBase = {
  id: number;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date | null;
};

export type AssetResourceNormalized = Merge<
  AssetResourceBase,
  {
    resource: number;
    requirements: Array<number>;
  }
>;

export type AssetResource = Merge<
  AssetResourceBase,
  {
    resource: Resource;
    requirements: Array<AssetResourceVersionRequirement>;
  }
>;

export type AssetResourceStateById = { [k: string | number]: AssetResourceNormalized };

export type AssetResourceState = {
  byId: AssetResourceStateById;
};

//
//
export const getEmptyAssetResource = (id?: number): AssetResource => ({
  id: id ?? -1,
  resource: getEmptyResource(),
  createdAt: new Date(),
  createdBy: -1,
  updatedAt: null,
  requirements: [],
});

const initialStateById: AssetResourceStateById = {};

//
//
const byId = (state = initialStateById, action: AnyAction): AssetResourceStateById => {
  switch (action.type) {
    case ASSET_GET_METADATA_OK:
    case ASSET_GET_DETAILS_OK:
    case ASSETRESOURCE_CREATE_OK: {
      const assetResources = action?.payload?.entities?.assetResources ?? {};
      return {
        ...state,
        ...assetResources,
      };
    }

    case ASSETRESOURCEVERSIONREQUIREMENTS_CREATE_OK: {
      const { assetResourceId, payload } = action;
      const { result: newRequirementId } = payload;
      const oldData = state[assetResourceId];
      return {
        ...state,
        [assetResourceId]: {
          ...oldData,
          requirements: [...oldData.requirements, newRequirementId],
        },
      };
    }

    case ASSETRESOURCEVERSIONREQUIREMENTS_DELETE_OK: {
      const { versionRequirementId } = action;
      const arIdStr = Object.keys(state).find((idStr) => {
        const currentAr = state[idStr];
        return currentAr.requirements.includes(versionRequirementId);
      });

      if (arIdStr == null) {
        return state;
      }

      const oldData = state[arIdStr];
      return {
        ...state,
        [arIdStr]: {
          ...oldData,
          requirements: oldData.requirements.filter((req) => req !== versionRequirementId),
        },
      };
    }

    case ASSETRESOURCE_DELETE_OK: {
      const { [action.assetResourceId]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
});

export default combined;
