import { Form, Input, Modal } from 'antd';
import { useEffect } from 'react';

import type { Platform } from '../reducers/PlatformReducer';

type Props = Readonly<{
  data: Platform | null;
  visible: boolean;
  onClose: () => void;
  onSubmit: (id: number | null | undefined, name: string, subcategories?: string) => Promise<void>;
}>;

//
//
const PlatformAddModal = (props: Props) => {
  const { visible, data, onSubmit, onClose } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible === true) {
      form.setFieldsValue({
        name: data?.name ?? undefined,
        subcategories: data?.subcategories ?? undefined,
      });
    }
  }, [data, form, visible]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      console.log('Received values of form: ', values);
      const id = data?.id ?? null;
      onSubmit(id, values.name, values.subcategories);
    });
  };

  const title = data != null ? 'Edit a platform' : 'Create a platform';

  return (
    <Modal
      title={title}
      centered
      open={visible}
      okText={data != null ? 'Save' : 'Create'}
      onOk={handleOk}
      onCancel={onClose}
    >
      <Form layout="vertical" form={form} name="PlatformAddModal">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please give the platform a name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Subcategories" name="subcategories">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PlatformAddModal;
