import { createSelector } from 'reselect';

import { getEmptyAppReceiptValidator } from '../reducers/AppReceiptValidatorReducer';

import type { RootState } from '../reducers';

//
//
const getById = (state: RootState) => state.appReceiptValidators.byId;
const getAllIds = (state: RootState) => state.appReceiptValidators.allIds;

//
//
export const getAppReceiptValidatorByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => {
    // if (id === null) {
    //   return null;
    // }

    const arv = byId[id];
    if (arv == null) {
      return getEmptyAppReceiptValidator(id);
    }

    return arv;
  }
);

//
//
export const getAppReceiptValidatorById = createSelector(
  getAppReceiptValidatorByIdFunc,
  (_: RootState, id: number) => id,
  //
  ($getAppReceiptValidatorById, id) => $getAppReceiptValidatorById(id)
);

//
//
export const getAppReceiptValidatorByIdsFunc = createSelector(
  getAppReceiptValidatorByIdFunc,
  //
  ($getAppReceiptValidatorById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id) => $getAppReceiptValidatorById(id));
  }
);

//
//
export const getAppReceiptValidatorByIds = createSelector(
  getAppReceiptValidatorByIdsFunc,
  (_: RootState, ids: Array<number>) => ids,
  //
  ($getAppReceiptValidatorByIds, ids) => $getAppReceiptValidatorByIds(ids)
);

//
//
export const getAppReceiptValidatorForAppFunc = createSelector(
  getAllIds,
  getAppReceiptValidatorByIdsFunc,
  //
  (allIds, $getAppReceiptValidatorByIds) => (appId: number | null) => {
    if (appId == null) {
      return [];
    }
    const ids = allIds[appId];
    const arvs = $getAppReceiptValidatorByIds(ids);
    // XXX: do we want to sort these? if yes, how? createdAt?
    // arvs.sort(
    //   (a, b) =>
    //     a.platform.name.toLowerCase().charCodeAt(0) - b.platform.name.toLowerCase().charCodeAt(0)
    // );
    return arvs;
  }
);

//
//
export const getAppReceiptValidatorForApp = createSelector(
  getAppReceiptValidatorForAppFunc,
  (_: RootState, appId: number) => appId,
  //
  ($getAppReceiptValidatorForApp, appId) => $getAppReceiptValidatorForApp(appId)
);
