import { Collapse } from 'antd';
import * as React from 'react';

import { isUserSuperadmin } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { JsonArray } from 'type-fest';

const { Panel } = Collapse;

type Props = Readonly<{
  data: JsonArray;
  header?: string | React.ReactNode;
  style?: React.CSSProperties;
  forceDisplay?: boolean;
}>;

//
//
const JSONDataVisualizer = (props: Props) => {
  const { data, header = 'Data', style = {}, forceDisplay = false } = props;
  const isSuperadmin = useGlobalSelector(isUserSuperadmin);

  const customPanelStyle = {
    background: '#f7f7f7',
    borderRadius: 4,
    marginBottom: 24,
    marginTop: 42,
    border: 0,
    overflow: 'hidden',
    ...style,
  };

  if (!isSuperadmin && !forceDisplay) {
    return null;
  }

  return (
    <Collapse bordered={false}>
      <Panel header={header} key="1" style={customPanelStyle}>
        {data.map((d, index) => (
          <pre
            style={{
              fontSize: '10px',
              WebkitFontSmoothing: 'subpixel-antialiased',
              MozOsxFontSmoothing: 'grayscale',
            }}
            key={`jv-${index}`}
          >
            {JSON.stringify(d, null, 2)}
          </pre>
        ))}
      </Panel>
    </Collapse>
  );
};

export default JSONDataVisualizer;
