export const JobStatus = {
  INITIALISED: 0,
  CREATED: 101,
  PROCESSING: 102,
  COMPLETED: 201,
  ERRORED: 301,
  TIMEDOUT: 302,
  UNKNOWN: 999,
} as const;

export const VALID_JOB_STATUS = Object.keys(JobStatus).map(
  (key) => JobStatus[key as keyof typeof JobStatus]
);

export type AllowedJobStatus = (typeof VALID_JOB_STATUS)[number];
