import {
  CloseOutlined,
  PushpinOutlined,
  ExclamationOutlined,
  QuestionOutlined,
  LoadingOutlined,
  LinkOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { Button, Tooltip, Progress } from 'antd';

import styles from './Thumbnail.module.scss';
import { FileStatus, FileStatusNames } from '../common/constants/file-status';
import { ImageTemplates } from '../common/constants/image-templates';
import {
  SUBTITLES_TEMPLATE_WEBVTT_TEXT_VTT,
  SUBTITLES_VERSION_LABELS,
} from '../common/constants/subtitles-template';
import { useCurrentAppContext } from '../contexts';
import { getResourceDuration } from '../utils/resource';

import type { Resource, Icon } from '../reducers/ResourceReducer';
import type { FileInfo } from '../utils/queue';

type Props = Readonly<{
  thumbnail: Resource | Icon | null;
  thumbnailInfo: FileInfo;
  content?: Resource | null;
  width?: number;
  height?: number;
  isDefault?: boolean | null;
  disableSetThumbnail?: boolean | null;
  onSetDefaultThumbnail?: (thumbnailId: number) => Promise<void>;
  displayTimeOverlay?: boolean;
  thumbContainerBackgroundColor?: string;
}>;

const DEFAULT_ICON_STYLE = { fontSize: '24px', color: '#ccc' };
const DEFAULT_ICON_STYLE_SMALL = { fontSize: '14px', color: '#ccc' };

const msToTime = (duration: number) => {
  const seconds = Math.floor(Number((duration / 1000) % 60));
  const minutes = Math.floor(Number(duration / (1000 * 60)));
  const minutesAsStr = minutes < 10 ? `0${minutes}` : minutes;
  const secondsAsStr = seconds < 10 ? `0${seconds}` : seconds;
  return `${minutesAsStr}:${secondsAsStr}`;
};

//
//
const Thumbnail = (props: Props) => {
  const {
    thumbnail,
    thumbnailInfo,
    content,
    isDefault,
    onSetDefaultThumbnail,
    displayTimeOverlay = false,
    disableSetThumbnail = true,
    thumbContainerBackgroundColor = '#eee',
  } = props;

  let { width, height } = props;

  width = width != null ? width : 89;
  height = height != null ? height : 50;

  const { currentApp } = useCurrentAppContext();
  let thumb;
  let imageFound = false;

  const baseUrl = thumbnail?.isPrivate ? currentApp.privateBaseUrl : currentApp.publicBaseUrl;

  const handleSetDefaultThumbnail = () => {
    const thumbnailResourceId = thumbnail?.id ?? null;
    console.log('handleSetDefaultThumbnail', thumbnailResourceId);
    if (typeof onSetDefaultThumbnail === 'function' && thumbnailResourceId !== null) {
      onSetDefaultThumbnail(thumbnailResourceId);
    }
  };
  const { queued, percentUploaded } = thumbnailInfo;
  if (queued === true) {
    thumb = <Progress type="circle" percent={percentUploaded} width={40} />;
  } else if (thumbnail == null) {
    // if (typeof le === 'undefined' || le === null || le.thumbnail === null) {
    thumb = <CloseOutlined style={DEFAULT_ICON_STYLE} />;
  } else {
    const versions = thumbnail?.file?.versions ?? [];
    const t300 = versions.find((f) => {
      const templateName = f?.meta?.templateName ?? '';
      return templateName === ImageTemplates.s;
    });

    const subtitlesTemplateName = versions.find((f) => {
      const templateName = f?.meta?.templateName ?? '';
      return templateName === SUBTITLES_TEMPLATE_WEBVTT_TEXT_VTT;
    });

    if (typeof t300 !== 'undefined') {
      const status = t300.lastStatusId;
      if (status === FileStatus.READY) {
        imageFound = true;
        thumb = (
          <img
            key={`${baseUrl}/${t300.name}.${t300.ext}`}
            alt="thumbnail"
            style={{ maxHeight: '100%', maxWidth: '100%' }}
            loading="lazy"
            src={`${baseUrl}/${t300.name}.${t300.ext}`}
          />
        );
      } else if (status === FileStatus.ERRORED || status === FileStatus.TIMEDOUT) {
        thumb = <ExclamationOutlined style={{ ...DEFAULT_ICON_STYLE, color: 'red' }} />;
      } else {
        thumb = <LoadingOutlined style={DEFAULT_ICON_STYLE} />;
      }
    } else if (typeof subtitlesTemplateName !== 'undefined') {
      const status = thumbnail.file?.lastStatusId ?? FileStatus.UNKNOWN;
      if (status === FileStatus.ERRORED || status === FileStatus.TIMEDOUT) {
        thumb = (
          <Tooltip title={FileStatusNames[status]} placement="right">
            <ExclamationOutlined style={{ ...DEFAULT_ICON_STYLE, color: 'red' }} />
          </Tooltip>
        );
      } else {
        if (SUBTITLES_VERSION_LABELS[SUBTITLES_TEMPLATE_WEBVTT_TEXT_VTT] != null) {
          thumb = (
            <>
              {SUBTITLES_VERSION_LABELS[SUBTITLES_TEMPLATE_WEBVTT_TEXT_VTT]}:&nbsp;
              <a
                href={`${currentApp.privateBaseUrl}/${subtitlesTemplateName.name}.${subtitlesTemplateName.ext}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CheckOutlined style={{ ...DEFAULT_ICON_STYLE_SMALL, color: 'green' }} />
              </a>
            </>
          );
        } else {
          thumb = <LoadingOutlined style={DEFAULT_ICON_STYLE} />;
        }
      }
    } else {
      const status = thumbnail.file?.lastStatusId ?? FileStatus.UNKNOWN;
      if (status === FileStatus.ERRORED || status === FileStatus.TIMEDOUT) {
        thumb = (
          <Tooltip title={FileStatusNames[status]} placement="right">
            <ExclamationOutlined style={{ ...DEFAULT_ICON_STYLE, color: 'red' }} />
          </Tooltip>
        );
      } else {
        thumb = <LoadingOutlined style={DEFAULT_ICON_STYLE} />;
      }
    }
  }

  if (typeof thumb === 'undefined') {
    thumb = <QuestionOutlined style={{ ...DEFAULT_ICON_STYLE, color: 'orange' }} />;
  }

  const durationInMs = getResourceDuration(content);

  const elem = (
    <div
      onClick={
        isDefault === true || disableSetThumbnail === true ? undefined : handleSetDefaultThumbnail
      }
      style={{
        width,
        minWidth: width,
        height,
        backgroundColor: thumbContainerBackgroundColor,
        cursor: imageFound === true ? (isDefault === true ? 'not-allowed' : 'pointer') : undefined,
      }}
      className={styles.thumbnailContainer}
      data-isdefault={isDefault === true ? true : undefined}
    >
      {thumb}
      {displayTimeOverlay && durationInMs !== -1 && (
        <span className={styles.timeLabel}>{msToTime(durationInMs)}</span>
      )}
      {isDefault != null && imageFound === true && (
        <Button
          size="small"
          shape="circle"
          disabled={isDefault}
          data-default={isDefault}
          icon={isDefault === true ? <LinkOutlined /> : <PushpinOutlined />}
          style={{
            position: 'absolute',
            bottom: 2,
            right: 2,
            backgroundColor: 'rgba(255,255,255,0.75)',
          }}
        />
      )}
    </div>
  );

  if (imageFound === true && disableSetThumbnail === false) {
    return (
      <Tooltip placement="top" title={isDefault ? 'is default image' : 'set as default image'}>
        {elem}
      </Tooltip>
    );
  }

  return elem;
};

export default Thumbnail;
