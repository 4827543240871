import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Dropdown, Menu, message } from 'antd';
import { useState } from 'react';

import MbxButton from './base/MbxButton';
import ItemVersionRequirementsModal from './ItemVersionRequirementsModal';
import {
  createVersionRequirement,
  updateVersionRequirement,
  deleteVersionRequirement,
} from '../actions/versionRequirements';
import styles from '../pages/EpisodeDetail.module.scss';
import { getAppPlatformsForApp } from '../selectors/appPlatformSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { ItemVersionRequirementFormValues } from './ItemVersionRequirementForm';
import type { AppPlatform } from '../reducers/AppPlatformReducer';
import type { App } from '../reducers/AppsReducer';
import type { AssetResource } from '../reducers/AssetResourceReducer';
import type { Episode } from '../reducers/EpisodeReducer';
import type { EpisodeVersionRequirement } from '../reducers/EpisodeVersionRequirementReducer';
import type { MenuProps } from 'antd';

type Props = Readonly<{
  item: Episode | AssetResource;
  app: App;
}>;

const ItemVersionRequirementsListTypes = {
  EPISODE: 'episode',
  ASSETRESOURCE: 'assetResource',
};

//
//
const ItemVersionRequirementsList = (props: Props) => {
  const { item, app } = props;
  const dispatch = useAppDispatch();

  let type = ItemVersionRequirementsListTypes.EPISODE;
  if (Object.prototype.hasOwnProperty.call(item, 'resource')) {
    type = ItemVersionRequirementsListTypes.ASSETRESOURCE;
  }
  // console.log('ItemVersionRequirementsList()', type);

  const appPlatforms = useGlobalSelector((state) => getAppPlatformsForApp(state, app.id));

  const [modalInfo, setModalInfo] = useState<{
    isOpen: boolean;
    selectedAppPlatform: AppPlatform | null;
    selectedRequirement: EpisodeVersionRequirement | null;
  }>({
    isOpen: false,
    selectedAppPlatform: null,
    selectedRequirement: null,
  });

  const handleReleaseMenuClick: MenuProps['onClick'] = (info) => {
    const selectedId = Number(info.key.split('-').pop());
    console.log('handleReleaseMenuClick()', selectedId);
    if (!Number.isNaN(selectedId)) {
      const appPlatform = appPlatforms.find((ap) => ap.id === selectedId);
      if (appPlatform != null) {
        setModalInfo({
          ...modalInfo,
          isOpen: true,
          selectedAppPlatform: appPlatform,
          selectedRequirement: null,
        });
      }
    }
  };

  const handleEditRequirement = (evr: EpisodeVersionRequirement) => {
    setModalInfo({
      ...modalInfo,
      isOpen: true,
      selectedRequirement: evr,
      selectedAppPlatform: null,
    });
  };

  const handleCloseModal = () => {
    setModalInfo({
      ...modalInfo,
      isOpen: false,
    });
  };

  const handleCreateRequirement = async (
    appPlatformId: number,
    values: ItemVersionRequirementFormValues
  ) => {
    try {
      const { isExcluded = false, minVersion, maxVersion } = values;
      await dispatch(
        createVersionRequirement(type, item.id, appPlatformId, isExcluded, minVersion, maxVersion)
      );
      handleCloseModal();
      message.success('updated successfully');
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
    return false;
  };

  const handleUpdateRequirement = async (
    episodeVersionRequirementId: number,
    values: ItemVersionRequirementFormValues
  ) => {
    try {
      const { isExcluded = false, minVersion, maxVersion } = values;
      await dispatch(
        updateVersionRequirement(
          type,
          episodeVersionRequirementId,
          isExcluded,
          minVersion,
          maxVersion
        )
      );
      message.success('updated successfully');
      handleCloseModal();
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
    return false;
  };

  const handleDeleteRequirement = async (episodeVersionRequirement: EpisodeVersionRequirement) => {
    try {
      await dispatch(deleteVersionRequirement(type, episodeVersionRequirement.id));
      message.success(`${episodeVersionRequirement?.appPlatform?.platform?.name} deleted`);
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const unusedAppPlatforms: Array<AppPlatform> = [];
  const appPlatformIds = item.requirements.map((evr) => evr.appPlatform.id);
  appPlatforms.forEach((ap) => {
    if (!appPlatformIds.includes(ap.id)) {
      unusedAppPlatforms.push(ap);
    }
  });

  return (
    <>
      <List
        locale={{ emptyText: 'No Data' }}
        header={
          <div className={styles.listHeader}>
            <h2>App version requirements</h2>
            <Dropdown
              disabled={unusedAppPlatforms.length === 0}
              overlay={
                <Menu onClick={handleReleaseMenuClick}>
                  {unusedAppPlatforms.map((ap) => (
                    <Menu.Item key={`add-versionrequirement-menuitem-${ap.id}`}>
                      {ap.platform.name}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <MbxButton
                data-tid="btn-add-versionrequirement"
                icon={<PlusOutlined />}
                size="middle"
              >
                add requirement
              </MbxButton>
            </Dropdown>
          </div>
        }
        bordered
        className={styles.list}
        dataSource={item.requirements}
        renderItem={(evr) => {
          const actions = [];
          actions.push(
            <MbxButton
              size="small"
              icon={<EditOutlined />}
              onClick={() => {
                handleEditRequirement(evr);
              }}
            />
          );
          actions.push(
            <MbxButton
              size="small"
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                if (window.confirm(`Really delete ${evr?.appPlatform?.platform?.name ?? '??'}?`)) {
                  handleDeleteRequirement(evr);
                }
              }}
            />
          );

          const minVersion = evr.minVersion != null ? evr.minVersion : null;
          const maxVersion = evr.maxVersion != null ? evr.maxVersion : null;

          const minLabel = minVersion == null ? '____' : `${minVersion.name} ≤`;
          const maxLabel = maxVersion == null ? '____' : `< ${maxVersion.name}`;

          return (
            <List.Item key={evr.id} actions={actions}>
              <List.Item.Meta
                title={
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '30%' }}>{evr.appPlatform?.platform?.name ?? '??'}</div>
                    {evr.exclude === true && (
                      <div style={{ width: '70%' }}>
                        <span className={`listItemDate ${styles.listItemDate} expired`}>
                          EXCLUDED
                        </span>
                      </div>
                    )}
                    {evr.exclude === false && (
                      <>
                        <div style={{ width: '30%' }}>{minLabel}</div>
                        <div style={{ width: '30%' }}>{maxLabel}</div>
                      </>
                    )}
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />

      <ItemVersionRequirementsModal
        visible={modalInfo.isOpen}
        onCreate={handleCreateRequirement}
        onUpdate={handleUpdateRequirement}
        onClose={handleCloseModal}
        selectedAppPlatform={modalInfo.selectedAppPlatform}
        selectedVersionRequirement={modalInfo.selectedRequirement}
      />
    </>
  );
};

export default ItemVersionRequirementsList;
