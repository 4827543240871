const APPS_GET = 'APPS_GET';
const APPS_GET_LIST = 'APPS_GET_LIST';
const APPS_POST = 'APPS_POST';
const APPS_PUT = 'APPS_PUT';
const APPS_DELETE = 'APPS_DELETE';

const APPS_RERUN_CONVERSION = 'APPS_RERUN_CONVERSION';

const REGIONS_GET = 'REGIONS_GET';
const REGIONS_GET_LIST = 'REGIONS_GET_LIST';
const REGIONS_POST = 'REGIONS_POST';
const REGIONS_PUT = 'REGIONS_PUT';
const REGIONS_DELETE = 'REGIONS_DELETE';

const REGIONLOCALES_POST = 'REGIONLOCALES_POST';
const REGIONLOCALES_PUT = 'REGIONLOCALES_PUT';
const REGIONLOCALES_DELETE = 'REGIONLOCALES_DELETE';

const REGIONCOUNTRIES_POST = 'REGIONCOUNTRIES_POST';
const REGIONCOUNTRIES_PUT = 'REGIONCOUNTRIES_PUT';
const REGIONCOUNTRIES_DELETE = 'REGIONCOUNTRIES_DELETE';

const EPISODES_GET = 'EPISODES_GET';
const EPISODES_GET_LIST = 'EPISODES_GET_LIST';
const EPISODES_POST = 'EPISODES_POST';
const EPISODES_PUT = 'EPISODES_PUT';
const EPISODES_DELETE = 'EPISODES_DELETE';

const RELEASES_GET = 'RELEASES_GET';
const RELEASES_GET_LIST = 'RELEASES_GET_LIST';
const RELEASES_POST = 'RELEASES_POST';
const RELEASES_PUT = 'RELEASES_PUT';
const RELEASES_DELETE = 'RELEASES_DELETE';

const CATEGORIES_GET = 'CATEGORIES_GET';
const CATEGORIES_GET_LIST = 'CATEGORIES_GET_LIST';
const CATEGORIES_POST = 'CATEGORIES_POST';
const CATEGORIES_PUT = 'CATEGORIES_PUT';
const CATEGORIES_DELETE = 'CATEGORIES_DELETE';

const RELEASECATEGORIES_POST = 'RELEASECATEGORIES_POST';
const RELEASECATEGORIES_PUT = 'RELEASECATEGORIES_PUT';
const RELEASECATEGORIES_DELETE = 'RELEASECATEGORIES_DELETE';

const EPISODELOCALIZED_GET = 'EPISODELOCALIZED_GET';
const EPISODELOCALIZED_POST = 'EPISODELOCALIZED_POST';
const EPISODELOCALIZED_PUT = 'EPISODELOCALIZED_PUT';
const EPISODELOCALIZED_DELETE = 'EPISODELOCALIZED_DELETE';

const EPISODELOCALIZED_CONTENT_POST = 'EPISODELOCALIZED_CONTENT_POST';
const EPISODELOCALIZED_CONTENT_DELETE = 'EPISODELOCALIZED_CONTENT_DELETE';

const RESOURCES_GET = 'RESOURCES_GET';
const RESOURCES_GET_LIST = 'RESOURCES_GET_LIST';
const RESOURCES_POST = 'RESOURCES_POST';
const RESOURCES_DELETE = 'RESOURCES_DELETE';

const RESOURCES_RERUN_CONVERSION = 'RESOURCES_RERUN_CONVERSION';

const APPUSERS_POST = 'APPUSERS_POST';
const APPUSERS_PUT = 'APPUSERS_PUT';
const APPUSERS_DELETE = 'APPUSERS_DELETE';

const USERS_GET = 'USERS_GET';
const USERS_GET_LIST = 'USERS_GET_LIST';
const USERS_PUT = 'USERS_PUT';
const USERS_DELETE = 'USERS_DELETE';

const USERS_INVITE_GET_LIST = 'USERS_INVITE_GET_LIST';
const USERS_INVITE_POST = 'USERS_INVITE_POST';
const USERS_INVITE_DELETE = 'USERS_INVITE_DELETE';

const APPLOCALES_GET_LIST = 'APPLOCALES_GET_LIST';

const DEPLOYMENTS_GET = 'DEPLOYMENTS_GET';
const DEPLOYMENTS_GET_LIST = 'DEPLOYMENTS_GET_LIST';
const DEPLOYMENTS_POST = 'DEPLOYMENTS_POST';
const DEPLOYMENTS_DELETE = 'DEPLOYMENTS_DELETE';

const JOBS_GET = 'JOBS_GET';
const JOBS_GET_LIST = 'JOBS_GET_LIST';

const ASSETS_GET = 'ASSETS_GET';
const ASSETS_GET_LIST = 'ASSETS_GET_LIST';
const ASSETS_POST = 'ASSETS_POST';
const ASSETS_PUT = 'ASSETS_PUT';
const ASSETS_DELETE = 'ASSETS_DELETE';

const TAGS_GET_LIST = 'TAGS_GET_LIST';

const ROLES_GET = 'ROLES_GET';
const ROLES_GET_LIST = 'ROLES_GET_LIST';
const ROLES_POST = 'ROLES_POST';
const ROLES_PUT = 'ROLES_PUT';
const ROLES_DELETE = 'ROLES_DELETE';

const PLATFORMS_GET = 'PLATFORMS_GET';
const PLATFORMS_GET_LIST = 'PLATFORMS_GET_LIST';
const PLATFORMS_POST = 'PLATFORMS_POST';
const PLATFORMS_PUT = 'PLATFORMS_PUT';
const PLATFORMS_DELETE = 'PLATFORMS_DELETE';

const APPPLATFORMS_GET = 'APPPLATFORMS_GET';
const APPPLATFORMS_GET_LIST = 'APPPLATFORMS_GET_LIST';
const APPPLATFORMS_POST = 'APPPLATFORMS_POST';
const APPPLATFORMS_DELETE = 'APPPLATFORMS_DELETE';

const APPVERSIONS_POST = 'APPVERSIONS_POST';
const APPVERSIONS_PUT = 'APPVERSIONS_PUT';
const APPVERSIONS_DELETE = 'APPVERSIONS_DELETE';

const EPISODEVERSIONREQUIREMENTS_POST = 'EPISODEVERSIONREQUIREMENTS_POST';
const EPISODEVERSIONREQUIREMENTS_PUT = 'EPISODEVERSIONREQUIREMENTS_PUT';
const EPISODEVERSIONREQUIREMENTS_DELETE = 'EPISODEVERSIONREQUIREMENTS_DELETE';

const ASSETRESOURCEVERSIONREQUIREMENTS_POST = 'ASSETRESOURCEVERSIONREQUIREMENTS_POST';
const ASSETRESOURCEVERSIONREQUIREMENTS_PUT = 'ASSETRESOURCEVERSIONREQUIREMENTS_PUT';
const ASSETRESOURCEVERSIONREQUIREMENTS_DELETE = 'ASSETRESOURCEVERSIONREQUIREMENTS_DELETE';

const ANALYTICSREPORTS_GET_LIST = 'ANALYTICSREPORTS_GET_LIST';
const ANALYTICSREPORTS_DOWNLOAD = 'ANALYTICSREPORTS_DOWNLOAD';

const AUTHSERVICES_GET_LIST = 'AUTHSERVICES_GET_LIST';

const APPRECEIPTVALIDATORS_GET_LIST = 'APPRECEIPTVALIDATORS_GET_LIST';
const APPRECEIPTVALIDATORS_POST = 'APPRECEIPTVALIDATORS_POST';
const APPRECEIPTVALIDATORS_PUT = 'APPRECEIPTVALIDATORS_PUT';
const APPRECEIPTVALIDATORS_DELETE = 'APPRECEIPTVALIDATORS_DELETE';

const BATCH_UPLOAD_ALL = 'BATCH_UPLOAD_ALL';

export const UserRight = {
  APPS_GET,
  APPS_GET_LIST,
  APPS_POST,
  APPS_PUT,
  APPS_DELETE,
  APPS_RERUN_CONVERSION,
  REGIONS_GET,
  REGIONS_GET_LIST,
  REGIONS_POST,
  REGIONS_PUT,
  REGIONS_DELETE,
  REGIONLOCALES_POST,
  REGIONLOCALES_PUT,
  REGIONLOCALES_DELETE,
  REGIONCOUNTRIES_POST,
  REGIONCOUNTRIES_PUT,
  REGIONCOUNTRIES_DELETE,
  EPISODES_GET,
  EPISODES_GET_LIST,
  EPISODES_POST,
  EPISODES_PUT,
  EPISODES_DELETE,
  RELEASES_GET,
  RELEASES_GET_LIST,
  RELEASES_POST,
  RELEASES_PUT,
  RELEASES_DELETE,
  CATEGORIES_GET,
  CATEGORIES_GET_LIST,
  CATEGORIES_POST,
  CATEGORIES_PUT,
  CATEGORIES_DELETE,
  RELEASECATEGORIES_POST,
  RELEASECATEGORIES_PUT,
  RELEASECATEGORIES_DELETE,
  EPISODELOCALIZED_GET,
  EPISODELOCALIZED_POST,
  EPISODELOCALIZED_PUT,
  EPISODELOCALIZED_DELETE,
  EPISODELOCALIZED_CONTENT_POST,
  EPISODELOCALIZED_CONTENT_DELETE,
  RESOURCES_GET,
  RESOURCES_GET_LIST,
  RESOURCES_POST,
  RESOURCES_DELETE,
  RESOURCES_RERUN_CONVERSION,
  APPUSERS_POST,
  APPUSERS_PUT,
  APPUSERS_DELETE,
  USERS_GET,
  USERS_GET_LIST,
  USERS_PUT,
  USERS_DELETE,
  USERS_INVITE_GET_LIST,
  USERS_INVITE_POST,
  USERS_INVITE_DELETE,
  APPLOCALES_GET_LIST,
  DEPLOYMENTS_GET,
  DEPLOYMENTS_GET_LIST,
  DEPLOYMENTS_POST,
  DEPLOYMENTS_DELETE,
  JOBS_GET,
  JOBS_GET_LIST,
  ASSETS_GET,
  ASSETS_GET_LIST,
  ASSETS_POST,
  ASSETS_PUT,
  ASSETS_DELETE,
  TAGS_GET_LIST,
  ROLES_GET,
  ROLES_GET_LIST,
  ROLES_POST,
  ROLES_PUT,
  ROLES_DELETE,
  PLATFORMS_GET,
  PLATFORMS_GET_LIST,
  PLATFORMS_POST,
  PLATFORMS_PUT,
  PLATFORMS_DELETE,
  APPPLATFORMS_GET,
  APPPLATFORMS_GET_LIST,
  APPPLATFORMS_POST,
  APPPLATFORMS_DELETE,
  APPVERSIONS_POST,
  APPVERSIONS_PUT,
  APPVERSIONS_DELETE,
  EPISODEVERSIONREQUIREMENTS_POST,
  EPISODEVERSIONREQUIREMENTS_PUT,
  EPISODEVERSIONREQUIREMENTS_DELETE,
  ASSETRESOURCEVERSIONREQUIREMENTS_POST,
  ASSETRESOURCEVERSIONREQUIREMENTS_PUT,
  ASSETRESOURCEVERSIONREQUIREMENTS_DELETE,
  ANALYTICSREPORTS_GET_LIST,
  ANALYTICSREPORTS_DOWNLOAD,
  AUTHSERVICES_GET_LIST,
  APPRECEIPTVALIDATORS_GET_LIST,
  APPRECEIPTVALIDATORS_POST,
  APPRECEIPTVALIDATORS_PUT,
  APPRECEIPTVALIDATORS_DELETE,
  BATCH_UPLOAD_ALL,
} as const;

const VALID_USER_RIGHTS = Object.keys(UserRight).map(
  (key) => UserRight[key as keyof typeof UserRight]
);

export type AllowedUserRight = (typeof VALID_USER_RIGHTS)[number];

export const UserRightIndexes = {
  [APPS_GET]: 101,
  [APPS_GET_LIST]: 102,
  [APPS_POST]: 103,
  [APPS_PUT]: 104,
  [APPS_DELETE]: 105,

  [APPS_RERUN_CONVERSION]: 110,

  [REGIONS_GET]: 201,
  [REGIONS_GET_LIST]: 202,
  [REGIONS_POST]: 203,
  [REGIONS_PUT]: 204,
  [REGIONS_DELETE]: 205,

  [REGIONLOCALES_POST]: 303,
  [REGIONLOCALES_PUT]: 304,
  [REGIONLOCALES_DELETE]: 305,

  [REGIONCOUNTRIES_POST]: 403,
  [REGIONCOUNTRIES_PUT]: 404,
  [REGIONCOUNTRIES_DELETE]: 405,

  [EPISODES_GET]: 501,
  [EPISODES_GET_LIST]: 502,
  [EPISODES_POST]: 503,
  [EPISODES_PUT]: 504,
  [EPISODES_DELETE]: 505,

  [RELEASES_GET]: 601,
  [RELEASES_GET_LIST]: 602,
  [RELEASES_POST]: 603,
  [RELEASES_PUT]: 604,
  [RELEASES_DELETE]: 605,

  [CATEGORIES_GET]: 701,
  [CATEGORIES_GET_LIST]: 702,
  [CATEGORIES_POST]: 703,
  [CATEGORIES_PUT]: 704,
  [CATEGORIES_DELETE]: 705,

  [RELEASECATEGORIES_POST]: 803,
  [RELEASECATEGORIES_PUT]: 804,
  [RELEASECATEGORIES_DELETE]: 805,

  [EPISODELOCALIZED_GET]: 901,
  [EPISODELOCALIZED_POST]: 903,
  [EPISODELOCALIZED_PUT]: 904,
  [EPISODELOCALIZED_DELETE]: 905,

  [EPISODELOCALIZED_CONTENT_POST]: 913,
  [EPISODELOCALIZED_CONTENT_DELETE]: 915,

  [RESOURCES_GET]: 1001,
  [RESOURCES_GET_LIST]: 1002,
  [RESOURCES_POST]: 1003,
  [RESOURCES_DELETE]: 1005,

  [RESOURCES_RERUN_CONVERSION]: 1010,

  [APPUSERS_POST]: 1103,
  [APPUSERS_PUT]: 1104,
  [APPUSERS_DELETE]: 1105,

  [USERS_GET]: 1201,
  [USERS_GET_LIST]: 1202,
  [USERS_PUT]: 1204,
  [USERS_DELETE]: 1205,

  [USERS_INVITE_GET_LIST]: 1212,
  [USERS_INVITE_POST]: 1213,
  [USERS_INVITE_DELETE]: 1215,

  [APPLOCALES_GET_LIST]: 1302,

  [DEPLOYMENTS_GET]: 1401,
  [DEPLOYMENTS_GET_LIST]: 1402,
  [DEPLOYMENTS_POST]: 1403,
  [DEPLOYMENTS_DELETE]: 1405,

  [JOBS_GET]: 1501,
  [JOBS_GET_LIST]: 1502,

  [ASSETS_GET]: 1601,
  [ASSETS_GET_LIST]: 1602,
  [ASSETS_POST]: 1603,
  [ASSETS_PUT]: 1604,
  [ASSETS_DELETE]: 1605,

  [TAGS_GET_LIST]: 1702,

  [ROLES_GET]: 1801,
  [ROLES_GET_LIST]: 1802,
  [ROLES_POST]: 1803,
  [ROLES_PUT]: 1804,
  [ROLES_DELETE]: 1805,

  [PLATFORMS_GET]: 1901,
  [PLATFORMS_GET_LIST]: 1902,
  [PLATFORMS_POST]: 1903,
  [PLATFORMS_PUT]: 1904,
  [PLATFORMS_DELETE]: 1905,

  [APPPLATFORMS_GET]: 2001,
  [APPPLATFORMS_GET_LIST]: 2002,
  [APPPLATFORMS_POST]: 2003,
  [APPPLATFORMS_DELETE]: 2005,

  [APPVERSIONS_POST]: 2103,
  [APPVERSIONS_PUT]: 2104,
  [APPVERSIONS_DELETE]: 2105,

  [EPISODEVERSIONREQUIREMENTS_POST]: 2203,
  [EPISODEVERSIONREQUIREMENTS_PUT]: 2204,
  [EPISODEVERSIONREQUIREMENTS_DELETE]: 2205,

  [ASSETRESOURCEVERSIONREQUIREMENTS_POST]: 2303,
  [ASSETRESOURCEVERSIONREQUIREMENTS_PUT]: 2304,
  [ASSETRESOURCEVERSIONREQUIREMENTS_DELETE]: 2305,

  [ANALYTICSREPORTS_GET_LIST]: 2502,
  [ANALYTICSREPORTS_DOWNLOAD]: 2510,

  [AUTHSERVICES_GET_LIST]: 2602,

  [APPRECEIPTVALIDATORS_GET_LIST]: 2702,
  [APPRECEIPTVALIDATORS_POST]: 2703,
  [APPRECEIPTVALIDATORS_PUT]: 2704,
  [APPRECEIPTVALIDATORS_DELETE]: 2705,

  [BATCH_UPLOAD_ALL]: 2810,
} as const;

export const DEFAULT_USER_RIGHTS = [
  UserRightIndexes[APPS_GET],
  UserRightIndexes[REGIONS_GET],
  UserRightIndexes[REGIONS_GET_LIST],
  UserRightIndexes[EPISODES_GET],
  UserRightIndexes[EPISODES_GET_LIST],
  UserRightIndexes[RELEASES_GET],
  UserRightIndexes[RELEASES_GET_LIST],
  UserRightIndexes[CATEGORIES_GET],
  UserRightIndexes[CATEGORIES_GET_LIST],
  UserRightIndexes[EPISODELOCALIZED_GET],
  UserRightIndexes[RESOURCES_GET],
  UserRightIndexes[RESOURCES_GET_LIST],
  UserRightIndexes[USERS_GET],
  UserRightIndexes[USERS_GET_LIST],
  UserRightIndexes[USERS_INVITE_GET_LIST],
  UserRightIndexes[APPLOCALES_GET_LIST],
  UserRightIndexes[DEPLOYMENTS_GET],
  UserRightIndexes[DEPLOYMENTS_GET_LIST],
  UserRightIndexes[ASSETS_GET],
  UserRightIndexes[ASSETS_GET_LIST],
  UserRightIndexes[TAGS_GET_LIST],
  UserRightIndexes[ROLES_GET],
  UserRightIndexes[ROLES_GET_LIST],
  UserRightIndexes[PLATFORMS_GET],
  UserRightIndexes[PLATFORMS_GET_LIST],
  UserRightIndexes[APPPLATFORMS_GET],
  UserRightIndexes[APPPLATFORMS_GET_LIST],
  UserRightIndexes[ANALYTICSREPORTS_GET_LIST],
  UserRightIndexes[ANALYTICSREPORTS_DOWNLOAD],
  UserRightIndexes[AUTHSERVICES_GET_LIST],
] as const;

export const VALID_USER_RIGHT_INDEXES = Object.keys(UserRightIndexes).map(
  (key) => UserRightIndexes[key as AllowedUserRight]
);

export type AllowedUserRightIndexes = (typeof VALID_USER_RIGHT_INDEXES)[number];
