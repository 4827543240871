import { createSelector } from 'reselect';

import { getEmptyResourceNormalized } from '../../reducers/ResourceReducer';

import type { RootState } from '../../reducers';

//
//
export const getById = (state: RootState) => state.resources.byId;

//
//
export const getResourceNormalizedByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => {
    const resource = byId[id];
    if (resource == null) {
      return getEmptyResourceNormalized(id);
    }
    return resource;
  }
);
