import type { Region } from '../reducers/RegionReducer';

//
//
export const getLabelFromKey = (key: string, regions: Array<Region>): string => {
  const result = /^(content|app)(\.([0-9]+)\.([a-z0-9_]{2,}))?\.json$/i.exec(key);
  if (result === null) {
    return '??? unknown key pattern';
  }

  const [, feedType, , regionId, locale] = result;
  if (feedType === 'app') {
    return key;
  }

  const region = regions.find((r) => r.id === parseInt(regionId, 10));
  if (typeof region === 'undefined') {
    return '??? Region not found';
  }
  return `${region.name} ${locale}`;
};
