import CustomPageHeader from '../components/CustomPageHeader';
import RegionEditForm from '../components/RegionEditForm';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_REGIONS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

import type { RegionEditFormValues } from '../components/RegionEditForm';
import type { Timezone } from '../reducers/TimezoneReducer';

type Props = Readonly<{
  timezones: Array<Timezone>;
  onSubmit: (values: RegionEditFormValues) => Promise<boolean>;
}>;

//
//
const RegionCreate = (props: Props) => {
  const { timezones, onSubmit } = props;

  const { currentApp } = useCurrentAppContext();
  const backLink = getUrlByName(ROUTE_NAME_APP_REGIONS, {
    ':appUid': currentApp.uid,
  });

  return (
    <div>
      <CustomPageHeader title="Create new market" backLink={backLink} />

      <div style={{ marginTop: '2em', maxWidth: 600 }}>
        <RegionEditForm onSubmit={onSubmit} timezones={timezones} backLink={backLink} />
      </div>
    </div>
  );
};

export default RegionCreate;
