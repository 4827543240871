import type { AdditionalInfo } from '../types/batch-upload';

// eslint-disable-next-line prefer-regex-literals
export const ROLE_ARN_REGEX = new RegExp('^arn:aws:iam::[0-9]+:role/[A-Za-z0-9_-]+$', '');

//
//
export const MAX_EPISODE_LOCALIZED_DATA_FIELDS = 5;

//
//
export const ValidDataAttributeNames = ['data1', 'data2', 'data3', 'data4', 'data5'] as const;

//
// If field has fallback: true - this is first place, where app search for value for field
export const APP_CONFIG_FALLBACK_VALUES = {
  previewUrlSchema: 'eu.wonderz.mediabox.templateapp',
} as const;

// TODO: this only freezes the array but not the containing objects - do it recursively
export const APP_DEFAULT_CONFIGURATION = [
  {
    type: 'group',
    name: 'episodeData',
    children: [
      {
        type: 'string',
        name: 'data1Label',
        value: 'Data 1',
      },
      {
        type: 'string',
        name: 'data1FeedName',
        value: 'data1',
      },
      {
        type: 'boolean',
        name: 'data1Visible',
        value: false,
      },
      {
        type: 'boolean',
        name: 'data1IncludeInAnalytics',
        value: false,
      },
      {
        type: 'string',
        name: 'data2Label',
        value: 'Data 2',
      },
      {
        type: 'string',
        name: 'data2FeedName',
        value: 'data2',
      },
      {
        type: 'boolean',
        name: 'data2Visible',
        value: false,
      },
      {
        type: 'boolean',
        name: 'data2IncludeInAnalytics',
        value: false,
      },
      {
        type: 'string',
        name: 'data3Label',
        value: 'Data 3',
      },
      {
        type: 'string',
        name: 'data3FeedName',
        value: 'data3',
      },
      {
        type: 'boolean',
        name: 'data3Visible',
        value: false,
      },
      {
        type: 'boolean',
        name: 'data3IncludeInAnalytics',
        value: false,
      },
      {
        type: 'string',
        name: 'data4Label',
        value: 'Data 4',
      },
      {
        type: 'string',
        name: 'data4FeedName',
        value: 'data4',
      },
      {
        type: 'boolean',
        name: 'data4Visible',
        value: false,
      },
      {
        type: 'boolean',
        name: 'data4IncludeInAnalytics',
        value: false,
      },
      {
        type: 'string',
        name: 'data5Label',
        value: 'Data 5',
      },
      {
        type: 'string',
        name: 'data5FeedName',
        value: 'data5',
      },
      {
        type: 'boolean',
        name: 'data5Visible',
        value: false,
      },
      {
        type: 'boolean',
        name: 'data5IncludeInAnalytics',
        value: false,
      },
    ],
  },
  {
    name: 'appData',
    type: 'group',
    children: [
      {
        name: 'forceTwofa',
        type: 'boolean',
        value: false,
      },
    ],
  },
  {
    name: 'adminData',
    type: 'group',
    children: [
      {
        name: 'displayRoles',
        type: 'boolean',
        value: false,
      },
      {
        name: 'appRelevantTags',
        type: 'string',
        value: '',
        required: false,
      },
      {
        name: 'exportAnalyticsDataMonthly',
        type: 'boolean',
        value: false,
      },
      {
        name: 'appUsingCalendarFeed',
        type: 'boolean',
        value: false,
      },
      {
        name: 'previewUrlSchema',
        type: 'string',
        value: '',
        fallback: true,
        required: false,
      },
      {
        name: 'addPaidOnVerificationError',
        type: 'boolean',
        value: true,
      },
      {
        name: 'videoStreamingFormatThresholdInSeconds',
        type: 'number',
        value: 600,
      },
      {
        name: 'displayBatchUploader',
        type: 'boolean',
        value: false,
      },
    ],
  },
] as const;

//
//
export const APP_SETUP_VERSIONS = ['1.1', '1.0'];

//
//
export const EMPTY_ADDITIONAL_INFO: <T>() => AdditionalInfo<T> = () => ({
  data1: null,
  data2: null,
  data3: null,
  data4: null,
  data5: null,
});
