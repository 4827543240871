import { createSelector } from 'reselect';

import { getEmptyRegionLocale } from '../../reducers/RegionLocaleReducer';
import { sortRegionLocales } from '../../utils/sort';
import { getLocaleByIdFunc } from '../localeSelectors';
import { getRegionNormalizedByIdFunc, getAllRegionsNormalizedForAppFunc } from '../regions/index';

import { getRegionLocaleNormalizedByIdFunc, getRegionLocalesIdsForRegionFunc } from './index';

import type { RegionLocale } from '../../reducers/RegionLocaleReducer';

//
//
export const getRegionLocaleByIdFunc = createSelector(
  getRegionLocaleNormalizedByIdFunc,
  getLocaleByIdFunc,
  //
  ($getRegionLocaleNormalizedById, $getLocaleById) => (id: number) => {
    const rl = $getRegionLocaleNormalizedById(id);
    if (rl == null) {
      return getEmptyRegionLocale(id);
    }
    return {
      ...rl,
      locale: $getLocaleById(rl.locale),
    };
  }
);

//
//
export const getRegionLocalesByIdsFunc = createSelector(
  getRegionLocaleByIdFunc,
  //
  ($getRegionLocaleById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }

    return ids.map((id: number) => $getRegionLocaleById(id));
  }
);

//
//
export const getRegionLocalesForIdFunc = createSelector(
  getRegionLocalesIdsForRegionFunc,
  getRegionLocalesByIdsFunc,
  getRegionNormalizedByIdFunc,
  //
  ($getRegionLocalesIdsForRegion, $getRegionLocalesByIds, $getRegionNormalizedById) =>
    (regionId: number) => {
      const rls = $getRegionLocalesByIds($getRegionLocalesIdsForRegion(regionId));
      const region = $getRegionNormalizedById(regionId);
      const defaultIds = [];
      if (region != null && region.defaultLocaleId !== null) {
        defaultIds.push(region.defaultLocaleId);
      }
      rls.sort(sortRegionLocales(defaultIds));
      return rls;
    }
);
//
//
// export const getRegionLocalesForId: (GlobalState, number) => RegionLocale[] = createSelector(
//   getRegionLocalesForIdFunc,
//   (_, id) => id,
//   //
//   ($getRegionLocalesForId, id) => $getRegionLocalesForId(id)
// );

//
//
export const getRegionDefaultLocaleIdsForAppFunc = createSelector(
  getAllRegionsNormalizedForAppFunc,
  //
  ($getAllRegionsNormalizedForApp) => (appId: number) => {
    const regions = $getAllRegionsNormalizedForApp(appId);
    const ids = regions.map((r) => r.defaultLocaleId);
    return ids;
  }
);
//
//
export const getRegionDefaultLocaleIdsForApp = createSelector(
  getRegionDefaultLocaleIdsForAppFunc,
  (_: unknown, id: number) => id,
  //
  ($getRegionDefaultLocaleIdsForApp, id) => $getRegionDefaultLocaleIdsForApp(id)
);

//
//
export const getUniqueLocalesForAppFunc = createSelector(
  getAllRegionsNormalizedForAppFunc,
  getRegionLocalesForIdFunc,
  //
  ($getAllRegionsNormalizedForApp, $getRegionLocalesForId) => (id: number) => {
    const regions = $getAllRegionsNormalizedForApp(id);
    const regionLocales: Array<RegionLocale> = [];
    const defaultRegionLocales: Array<RegionLocale> = [];
    regions.forEach((r) => {
      const rls = $getRegionLocalesForId(r.id);
      rls.forEach((rl) => {
        if (r.defaultLocaleId === rl.id) {
          defaultRegionLocales.push(rl);
        } else {
          regionLocales.push(rl);
        }
      });
    });

    const uniqueLocales: Array<RegionLocale> = [];
    [...defaultRegionLocales, ...regionLocales].forEach((rl) => {
      const duplicate = uniqueLocales.find((rl2) => rl2.locale.shortcode === rl.locale.shortcode);
      if (duplicate == null) {
        uniqueLocales.push(rl);
      }
    });
    const defaultLocales = defaultRegionLocales.map((rl) => rl.id);
    uniqueLocales.sort(sortRegionLocales(defaultLocales));

    return uniqueLocales;
  }
);

//
//
export const getUniqueLocalesForApp = createSelector(
  getUniqueLocalesForAppFunc,
  (_: unknown, appId: number) => appId,
  //
  ($getUniqueLocalesForApp, appId) => $getUniqueLocalesForApp(appId)
);
