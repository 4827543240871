import { combineReducers } from 'redux';

import { getEmptyPlatform } from './PlatformReducer';
import {
  APPPLATFORMS_CREATE_OK,
  APPPLATFORMS_DELETE_OK,
  APPPLATFORMS_GET_LIST_OK,
} from '../constants/appplatforms';
import { GET_APPS_LIST_OK, GET_APP_DETAILS_OK } from '../constants/apps';
import { APPVERSIONS_CREATE_OK, APPVERSIONS_DELETE_OK } from '../constants/appversions';
import { LOGIN_OK } from '../constants/auth';
import { EPISODE_GET_DETAILS_OK } from '../constants/episodes';

import type { AppVersion } from './AppVersionReducer';
import type { Platform } from './PlatformReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type BaseAppPlatform = {
  id: number;
};

export type AppPlatformNormalized = Merge<
  BaseAppPlatform,
  {
    platform: number;
    versions: Array<number>;
  }
>;

export type AppPlatform = Merge<
  BaseAppPlatform,
  {
    platform: Platform;
    versions: Array<AppVersion>;
  }
>;

export type AppPlatformStateById = { [k: string | number]: AppPlatformNormalized };
export type AppPlatformStateAllIds = { [k: string | number]: Array<number> };

export type AppPlatformState = {
  byId: AppPlatformStateById;
  allIds: AppPlatformStateAllIds;
};

export const getEmptyAppPlatform = (id?: number): AppPlatform => ({
  id: id ?? -1,
  platform: getEmptyPlatform(),
  versions: [],
});

const initialStateById: AppPlatformStateById = {};
const initialStateAllIds: AppPlatformStateAllIds = {};

//
//
const byId = (state = initialStateById, action: AnyAction): AppPlatformStateById => {
  switch (action.type) {
    case GET_APPS_LIST_OK: {
      if (action.payload.entities.appPlatforms == null) {
        return state;
      }
      return {
        ...action.payload.entities.appPlatforms,
      };
    }

    case LOGIN_OK:
    case GET_APP_DETAILS_OK:
    case APPPLATFORMS_CREATE_OK:
    case APPPLATFORMS_GET_LIST_OK:
    case EPISODE_GET_DETAILS_OK: {
      if (action?.payload?.entities?.appPlatforms == null) {
        return state;
      }
      return {
        ...state,
        ...action.payload.entities.appPlatforms,
      };
    }

    case APPPLATFORMS_DELETE_OK: {
      const { [action.appPlatformId]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case APPVERSIONS_CREATE_OK: {
      const { appPlatformId, payload } = action;
      const appPlatform = state[appPlatformId];
      return {
        ...state,
        [appPlatformId]: {
          ...appPlatform,
          versions: [...appPlatform.versions, payload.result],
        },
      };
    }

    case APPVERSIONS_DELETE_OK: {
      const { appPlatformId, appVersionId } = action;
      const appPlatform = state[appPlatformId];
      return {
        ...state,
        [appPlatformId]: {
          ...appPlatform,
          versions: appPlatform.versions.filter((id) => id !== appVersionId),
        },
      };
    }

    default:
      return state;
  }
};

//
//
const allIds = (state = initialStateAllIds, action: AnyAction): AppPlatformStateAllIds => {
  switch (action.type) {
    case GET_APPS_LIST_OK: {
      const apps = action?.payload?.result;
      if (apps == null) {
        return state;
      }

      const newState: AppPlatformStateAllIds = {};

      apps.forEach((appId: string) => {
        const app = action?.payload?.entities?.apps?.[appId];
        if (app != null) {
          newState[appId] = app.platforms;
        }
      });

      return newState;
    }

    case LOGIN_OK: {
      const apps = action?.payload?.entities?.apps;
      if (apps == null) {
        return state;
      }

      const newState = { ...state };
      Object.keys(apps).forEach((appIdStr) => {
        newState[appIdStr] = apps[appIdStr].platforms;
      });
      return newState;
    }

    case GET_APP_DETAILS_OK: {
      const app = action?.payload?.entities?.apps?.[action?.payload?.result];
      if (app == null) {
        return state;
      }

      const newState = { ...state };
      newState[app.id] = app.platforms;
      return newState;
    }

    case APPPLATFORMS_GET_LIST_OK: {
      const { appId } = action;
      const appPlatforms = action.payload?.entities?.appPlatforms;
      if (appPlatforms == null) {
        return {
          ...state,
          [appId]: [],
        };
      }

      return {
        ...state,
        [appId]: Object.keys(appPlatforms).map((apId) => parseInt(apId, 10)),
      };
    }

    case APPPLATFORMS_CREATE_OK: {
      const appPlatform = action.payload?.entities?.appPlatforms?.[action.payload?.result];
      if (appPlatform == null) {
        return state;
      }

      const { appId } = action;
      const previousAppState = state?.[appId] ?? [];
      return {
        ...state,
        [appId]: [...previousAppState, action.payload?.result],
      };
    }

    case APPPLATFORMS_DELETE_OK: {
      const { appId } = action;
      const previousAppState = state?.[appId] ?? [];
      return {
        ...state,
        [appId]: previousAppState.filter((ap) => ap !== action.appPlatformId),
      };
    }

    default:
      return state;
  }
};

const reducers = combineReducers({
  byId,
  allIds,
});

export default reducers;
