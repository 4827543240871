import styles from './Content.module.scss';
import TemplateGeneration from './TemplateGeneration';
import { BatchUploadItemStatus } from '../../common/constants/batch-upload';
import { toRecord } from '../../common/utils/batch-upload';
import { BatchUploadComponentState } from '../../components/batchUpload/constants';
import EpisodeFilePicker from '../../components/batchUpload/EpisodeFilesPicker';
import MetadataPicker from '../../components/batchUpload/MetadataPicker';
import UploadProgress from '../../components/batchUpload/UploadProgress';
import UploadStatus from '../../components/batchUpload/UploadStatus';
import UploadSummary from '../../components/batchUpload/UploadSummary';
import ValidationReport from '../../components/batchUpload/ValidationReport';
import ValidationSummary from '../../components/batchUpload/ValidationSummary';

import type { BatchUploadRecord, Issue } from '../../common/types/batch-upload';
import type { App } from '../../reducers/AppsReducer';
import type { BatchUploadItem, BatchUpload } from '../../reducers/BatchUploadReducer';

type Props = Readonly<{
  state: BatchUploadComponentState;
  app: App;
  workbook: File | null;
  issues: Array<Issue>;
  files: Array<File>;
  batchUpload: BatchUpload | null;
  wasResumed: boolean;
  setFiles: (files: Array<File>) => void;
  setWorkbook: (workbook: File) => void;
  setState: (state: BatchUploadComponentState) => void;
}>;

const BatchUploadContent = ({
  batchUpload,
  app,
  state,
  setState,
  issues,
  files,
  wasResumed,
  setFiles,
  workbook,
  setWorkbook,
}: Props) => {
  let records: Array<BatchUploadRecord> = [];
  let fileNames: Array<string> = [];
  let fileData: Array<BatchUploadItem> = [];
  let showProgress = true;
  let newEpisodes = true;

  if (batchUpload != null) {
    const { items, validationRequest } = batchUpload;

    if (validationRequest != null) {
      const { rows, isCreate } = validationRequest;
      records = rows.map(toRecord);
      newEpisodes = !!isCreate;
    }

    fileData = items.filter(({ status }) => status !== BatchUploadItemStatus.UPLOADED);
    fileNames = fileData.map(({ name }) => name);

    if (fileData.length === 0 && wasResumed) {
      showProgress = false;
    }
  }

  const missingFilesCopy = `
      The following files were not uploaded successfuly. Please replace the missing files below per
      drag and drop into the file collector. Afterwards, you can restart the upload.
  `;

  switch (state) {
    case BatchUploadComponentState.LANDING_PAGE:
      return (
        <div className={styles.content}>
          <h2 data-tid="bu-title">Create new Batch Upload</h2>
          <p>
            With the batch upload it is possible to create many episodes and upload up to 500 files
            at once.
          </p>
          <p>
            To do this, you need to create a metadata spreadsheet and fill it with all the
            episode-related data. The sheet ensures that all content such as thumbnails or tags are
            correctly linked to the episodes. You can now create a template for your metadata
            spreadsheet step by step.
          </p>
          <p>You already have a completed sheet? Then upload it straight away.</p>
        </div>
      );
    case BatchUploadComponentState.GENERATING_TEMPLATE:
    case BatchUploadComponentState.VALID_TEMPLATE:
    case BatchUploadComponentState.TEMPLATE_GENERATED: {
      return (
        <div className={styles.content}>
          <TemplateGeneration app={app} state={state} setState={setState} />
        </div>
      );
    }
    case BatchUploadComponentState.COLLECTING_METADATA:
    case BatchUploadComponentState.METADATA_FOUND:
    case BatchUploadComponentState.VALIDATING:
      return (
        <div className={styles.content}>
          <h2 data-tid="bu-title">Upload your sheet to and start the validation</h2>
          <p>
            You completed your metadata spreadsheet? Then it’s time to validate it to avoid
            inconsistencies. Please upload the file below.
          </p>
          <MetadataPicker
            state={state}
            setState={setState}
            metadata={workbook}
            setMetadata={setWorkbook}
          />
        </div>
      );
    case BatchUploadComponentState.VALIDATION_SUCCESS:
    case BatchUploadComponentState.VALIDATION_WARNINGS:
      return (
        <div className={styles.content}>
          <h2 data-tid="bu-title">Validation Successful</h2>
          <p>Your metadata spreadsheet looks correct. Please continue and collect your files.</p>
          <ValidationSummary records={records} newEpisodes={newEpisodes} issues={issues} />
          {state === BatchUploadComponentState.VALIDATION_WARNINGS && (
            <div>
              <ValidationReport issues={issues} />
            </div>
          )}
        </div>
      );
    case BatchUploadComponentState.VALIDATION_FAILURE:
      return (
        <div className={styles.content}>
          <h2 data-tid="bu-title">Validation Report</h2>
          <p>
            Some inconsistencies were found during validation. Please check all notes and update
            your sheet. Then upload it again to repeat the validation.
          </p>
          <ValidationReport issues={issues} />
        </div>
      );
    case BatchUploadComponentState.READY_TO_COLLECT:
    case BatchUploadComponentState.COLLECTING_FILES:
    case BatchUploadComponentState.ALL_COLLECTED:
    case BatchUploadComponentState.QUEUING_ITEMS:
      return (
        <div className={styles.content}>
          <h2 data-tid="bu-title">
            {wasResumed ? 'Missing Files' : 'Collect files for batch upload'}
          </h2>
          <p>
            {wasResumed
              ? missingFilesCopy
              : `Please drag all referenced files from your metadata sheet into the file collector below.
          Files must be complete to start the batch upload.`}
          </p>
          <EpisodeFilePicker
            state={state}
            setState={setState}
            files={files}
            setFiles={setFiles}
            fileNames={fileNames}
          />
        </div>
      );
    case BatchUploadComponentState.UPLOADING_CLIENT:
    case BatchUploadComponentState.UPLOAD_DONE_FROM_CLIENT:
    case BatchUploadComponentState.CREATING_FILES:
    case BatchUploadComponentState.UPLOAD_SUCCESS: {
      return (
        <div className={styles.content}>
          <h2 data-tid="bu-title">
            {state === BatchUploadComponentState.UPLOAD_SUCCESS
              ? 'Batch upload successful'
              : 'Batch upload in progress'}
          </h2>
          {showProgress && <UploadProgress state={state} allFiles={files} fileData={fileData} />}
          <UploadStatus state={state} />
          {state === BatchUploadComponentState.UPLOAD_SUCCESS && (
            <UploadSummary
              episodes={records}
              sheetName={workbook?.name ?? ''}
              wasResumed={wasResumed}
            />
          )}
        </div>
      );
    }
    case BatchUploadComponentState.UPLOAD_MISSING_FILES: {
      return (
        <div className={styles.content}>
          <h2 data-tid="bu-title">Missing Files</h2>
          {missingFilesCopy}
          <EpisodeFilePicker
            state={state}
            setState={setState}
            files={files}
            setFiles={setFiles}
            fileNames={fileNames}
          />
        </div>
      );
    }
    default:
      return <div />;
  }
};

export default BatchUploadContent;
