import { createSelector } from 'reselect';

import { getAnalyticsViewsForEpisodeLocalizedFunc } from './analyticsViewsSelectors';
import { getEpisodeContentByIdsFunc } from './episodeContentSelectors';
import { getLocaleByIdFunc } from './localeSelectors';
import { getEmptyEpisodeLocalized } from '../reducers/EpisodeLocalizedReducer';

import type { RootState } from '../reducers';

//
//
const getById = (state: RootState) => state.localizedEpisodes.byId;
const getAllIds = (state: RootState) => state.localizedEpisodes.allIds;

//
//
export const getEpisodeLocalizedByIdFunc = createSelector(
  getById,
  getLocaleByIdFunc,
  getEpisodeContentByIdsFunc,
  getAnalyticsViewsForEpisodeLocalizedFunc,
  //
  (byId, $getLocaleById, $getEpisodeContentByIds, $getAnalyticsViewsForEpisodeLocalizedFunc) =>
    (id: number) => {
      const episodeLocalized = byId[id];
      if (episodeLocalized == null) {
        return getEmptyEpisodeLocalized(id);
      }
      return {
        ...episodeLocalized,
        locale: $getLocaleById(episodeLocalized.locale),
        content: $getEpisodeContentByIds(episodeLocalized.content),
        analytics: $getAnalyticsViewsForEpisodeLocalizedFunc(id),
      };
    }
);

//
//
export const getEpisodeLocalizedById = createSelector(
  getEpisodeLocalizedByIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getEpisodeLocalizedById, id) => $getEpisodeLocalizedById(id)
);

//
//
export const getEpisodeLocalizedByIdsFunc = createSelector(
  getEpisodeLocalizedByIdFunc,
  //
  ($getEpisodeLocalizedById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id) => $getEpisodeLocalizedById(id)).filter(Boolean);
  }
);

//
//
export const getEpisodeLocalizedForId = createSelector(
  getAllIds,
  getEpisodeLocalizedByIdsFunc,
  //
  (allIds, $getEpisodeLocalizedByIds) => (episodeId: number) => {
    const ids = allIds[episodeId];
    return $getEpisodeLocalizedByIds(ids);
  }
);
