import { schema, normalize } from 'normalizr';
import { forEachSeries } from 'p-iteration';

import {
  DEPLOYMENT_GET_LIST,
  DEPLOYMENT_GET_LIST_OK,
  DEPLOYMENT_GET_LIST_FAIL,
  DEPLOYMENT_GET_DETAILS,
  DEPLOYMENT_GET_DETAILS_OK,
  DEPLOYMENT_GET_DETAILS_FAIL,
  DEPLOYMENT_GET_PREVIOUS,
  DEPLOYMENT_GET_PREVIOUS_OK,
  DEPLOYMENT_GET_PREVIOUS_FAIL,
  DEPLOYMENT_GET_LATEST,
  DEPLOYMENT_GET_LATEST_OK,
  DEPLOYMENT_GET_LATEST_FAIL,
  DEPLOYMENT_CREATE,
  DEPLOYMENT_CREATE_OK,
  DEPLOYMENT_CREATE_FAIL,
  DEPLOYMENT_REMOVE,
  DEPLOYMENT_REMOVE_OK,
  DEPLOYMENT_REMOVE_FAIL,
  DEPLOYMENT_FETCH_DATA,
  DEPLOYMENT_FETCH_DATA_OK,
  DEPLOYMENT_FETCH_DATA_FAIL,
  DEPLOYMENT_FETCH_DATA_COMPLETE,
  DEPLOYMENT_FETCH_DATA_RESET,
  DEPLOYMENT_CALC_DIFF,
  DEPLOYMENT_CALC_DIFF_OK,
  DEPLOYMENT_CALC_DIFF_CANCELED,
} from '../constants/deployments';
import { DeploymentSchema } from '../schemas';
import { getAppById } from '../selectors/apps/advanced';
import { getDeploymentById } from '../selectors/deploymentSelectors';
import { fetch, wrapFetch } from '../utils/api';
import { sortByName, sortRegionLocales } from '../utils/sort';

import type {
  AppJsonContent,
  ContentJsonContent,
  Deployment,
  DiffChange,
} from '../../typings/deployments';
import type { AppDispatch } from '../configureStore';
import type { GlobalStateGetter } from '../reducers';
import type { Region } from '../reducers/RegionReducer';
import type { JsonObject } from 'type-fest';

//
//
export const createDeployment =
  (appId: number, content: string, password: string) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/deployments`,
        method: 'POST',
        data: {
          appId,
          content,
          password,
        },
      },
      dispatch,
      { init: DEPLOYMENT_CREATE, fail: DEPLOYMENT_CREATE_FAIL },
      201
    );
    const normalizedData = normalize(response.data, DeploymentSchema);
    dispatch({ type: DEPLOYMENT_CREATE_OK, payload: normalizedData, appId });
    return response.data;
  };

//
//
export const getDeploymentDetails = (deploymentId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/deployments/${deploymentId}` }, dispatch, {
    init: DEPLOYMENT_GET_DETAILS,
    fail: DEPLOYMENT_GET_DETAILS_FAIL,
  });
  const normalizedData = normalize(response.data, DeploymentSchema);
  dispatch({ type: DEPLOYMENT_GET_DETAILS_OK, deploymentId, payload: normalizedData });
  return response.data;
};

//
//
export const getPreviousDeploymentForId =
  (deploymentId: number) => async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const response = await wrapFetch<{ id: number } | null>(
      { url: `/deployments/${deploymentId}/previous` },
      dispatch,
      {
        init: DEPLOYMENT_GET_PREVIOUS,
        fail: DEPLOYMENT_GET_PREVIOUS_FAIL,
      }
    );
    if (response.data != null) {
      const normalizedData = normalize(response.data, DeploymentSchema);
      dispatch({ type: DEPLOYMENT_GET_PREVIOUS_OK, deploymentId, payload: normalizedData });

      const state = getState();
      const deployment = getDeploymentById(state, response.data.id);
      return deployment;
    }

    return false;
  };

//
//
export const fetchLatestDeploymentForApp = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/deployments/latest`,
      params: {
        appId,
      },
    },
    dispatch,
    {
      init: DEPLOYMENT_GET_LATEST,
      fail: DEPLOYMENT_GET_LATEST_FAIL,
    },
    [200, 204]
  );

  if (response.status === 200) {
    dispatch({ type: DEPLOYMENT_GET_LATEST_OK, payload: response.data });
    return response.data;
  }

  console.log('DEPLOYMENT_GET_LATEST_OK => LAST = null');
  dispatch({ type: DEPLOYMENT_GET_LATEST_OK, payload: false });
  return null;
};

//
//
export const getDeploymentList = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/deployments`,
      params: {
        appId,
      },
    },
    dispatch,
    {
      init: DEPLOYMENT_GET_LIST,
      fail: DEPLOYMENT_GET_LIST_FAIL,
    }
  );
  const normalizedData = normalize(response.data, new schema.Array(DeploymentSchema));
  dispatch({ type: DEPLOYMENT_GET_LIST_OK, appId, payload: normalizedData });
  return response.data;
};

//
//
export const deleteDeployment = (deployment: Deployment) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/deployments/${deployment.id}`,
      method: 'DELETE',
    },
    dispatch,
    {
      init: DEPLOYMENT_REMOVE,
      fail: DEPLOYMENT_REMOVE_FAIL,
    },
    204
  );
  dispatch({ type: DEPLOYMENT_REMOVE_OK, deployment });
  return true;
};

//
//
export const fetchDataForDeployment =
  (appId: number, regions: Array<Region>, abortSignal: AbortSignal) =>
  async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const content: { [k: string]: null | JsonObject } = {
      'app.json': null,
    };

    const sortedRegions = [...regions].filter((r) => !r.isDraft).sort(sortByName);

    sortedRegions.forEach((region) => {
      const sortedLocales = [...region.regionLocales].sort(sortRegionLocales());
      sortedLocales.forEach((regionLocale) => {
        content[`content.${region.id}.${regionLocale.locale.shortcode}.json`] = null;
      });
    });

    dispatch({ type: DEPLOYMENT_FETCH_DATA, appId, content });

    // TODO: const app = getAppById(getState(), appId, false);
    const app = getAppById(getState(), appId);

    const contentKeys = Object.keys(content);
    await forEachSeries(contentKeys, async (key, index) => {
      const response = await fetch({
        url: `/apps/${app.hash}/feeds/${key}`,
        signal: abortSignal,
      });

      if (response.status === 200) {
        // XXX: maybe move this to the page not here
        if (key === 'app.json') {
          const { data }: { data: AppJsonContent } = response;
          const notDraftRegions = data.regions.filter((r) => !r.isDraft);
          const cleanedUpRegions = notDraftRegions.map((r) => ({
            id: r.id,
            defaultLocale: r.defaultLocale,
            locales: r.locales.filter((l) => !r.draftLocales.includes(l)),
            countries: r.countries.filter((c) => !r.draftCountries.includes(c)),
          }));
          const regionsWithContent = cleanedUpRegions.filter(
            (r) => r.countries.length > 0 && r.locales.length > 0
          );
          response.data.regions = regionsWithContent;
        } else {
          const { data }: { data: ContentJsonContent } = response;
          const noDraftReleases = data.releases.filter((rl) => !rl.metadata.isDraft);
          const cleanedUpReleases = noDraftReleases.map(
            (rl) =>
              // TODO: app still needs this - remove at some later point
              // delete rl.metadata.isDraft;
              rl
          );
          response.data.releases = cleanedUpReleases;
        }

        dispatch({
          type: DEPLOYMENT_FETCH_DATA_OK,
          payload: { [key]: response.data },
          percent: Math.round(((index + 1) / contentKeys.length) * 100),
        });
      } else {
        dispatch({ type: DEPLOYMENT_FETCH_DATA_FAIL, payload: key });
        throw new Error('reponse not 200');
      }
    })
      .then(() => {
        dispatch({ type: DEPLOYMENT_FETCH_DATA_COMPLETE });
      })
      .catch((error) => {
        console.error('Error fetching Deployment data.', error);
        throw error;
      });
  };

//
//
export const resetDeploymentData = () => ({ type: DEPLOYMENT_FETCH_DATA_RESET });

//
//
export const startDeploymentDiff = () => ({ type: DEPLOYMENT_CALC_DIFF });
export const finishDeploymentDiff = (diffData: Array<DiffChange>) => ({
  type: DEPLOYMENT_CALC_DIFF_OK,
  payload: diffData,
});
export const cancelDeploymentDiff = () => ({ type: DEPLOYMENT_CALC_DIFF_CANCELED });
