import { Route, Redirect } from 'react-router-dom';

import styles from './CustomRoute.module.scss';
import { ROUTES } from './routes';
import { getValueFromKey } from '../common/utils/app';
import { hasUserSufficientRights } from '../common/utils/user-right';
import { useOptionalCurrentAppContext } from '../contexts';
import Forbidden from '../pages/Forbidden';
import {
  getUserProfileOptional,
  isUserLoggedIn,
  isUserSuperadmin,
} from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { AllowedUserRight } from '../common/constants/user-right';
import type { User } from '../reducers/UsersReducer';
import type { ComponentType } from 'react';
import type { Merge } from 'type-fest';

type RouteProps = {
  exact?: boolean;
  path?: string;
  location?: Location;
  computedMatch?: { params: { appUid?: string } };
};

type Props = Merge<
  RouteProps,
  {
    secure: boolean;
    appSpecific: boolean;
    needsAdmin: boolean;
    requiredAppRights: Array<string>;
    restrictAccessThroughAppSettings: string | null;
    comp: ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
>;

const doesUserBelongToApp = (
  user: User | null | undefined,
  appId: number | null | undefined
): boolean => {
  if (user == null || appId == null) {
    return false;
  }
  const userAppIds = user?.apps?.map((app) => app.id);
  if (userAppIds == null) {
    return false;
  }
  return userAppIds.includes(appId);
};

//
//
const CustomRoute = (props: Props) => {
  const {
    comp: PageComponent,
    secure,
    appSpecific,
    needsAdmin,
    requiredAppRights,
    restrictAccessThroughAppSettings,
    location,
    computedMatch,
    ...rest
  } = props;

  const user = useGlobalSelector(getUserProfileOptional);
  const isSuperadmin = useGlobalSelector(isUserSuperadmin);
  const { currentApp } = useOptionalCurrentAppContext();
  const userLoggedIn = useGlobalSelector(isUserLoggedIn);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (secure) {
          if (!userLoggedIn) {
            console.log(`user not logged in -> redirect to ${ROUTES.login.path}`);
            return (
              <Redirect
                to={{
                  pathname: ROUTES.login.path,
                  state: { from: routeProps.location },
                }}
              />
            );
          }
        }

        if (
          (appSpecific === true && currentApp === null) ||
          (appSpecific === true && !isSuperadmin && !doesUserBelongToApp(user, currentApp?.id)) ||
          (needsAdmin && !isSuperadmin)
        ) {
          console.log('yyy REDIRECT 404');
          return <Redirect to="/404" />;
        }

        if (!isSuperadmin && restrictAccessThroughAppSettings !== null) {
          if (typeof restrictAccessThroughAppSettings !== 'string') {
            throw new Error('restrictAccessThroughAppSettings has to be string');
          }

          const appConfig = currentApp?.configuration ?? [];
          const check = getValueFromKey<boolean>(
            appConfig,
            restrictAccessThroughAppSettings,
            false
          );
          if (!check) {
            return (
              <div className={styles.container}>
                <Forbidden />
              </div>
            );
          }
        }

        if (requiredAppRights.length > 0) {
          let sufficientRights = true;
          requiredAppRights.forEach((right) => {
            if (sufficientRights) {
              const x = hasUserSufficientRights(
                user,
                currentApp?.id ?? null,
                right as AllowedUserRight
              );
              sufficientRights = sufficientRights && x;
            }
          });
          if (!sufficientRights) {
            return (
              <div className={styles.container}>
                <Forbidden />
              </div>
            );
          }
        }

        return (
          <div className={styles.container}>
            <PageComponent />
          </div>
        );
      }}
    />
  );
};

export default CustomRoute;
