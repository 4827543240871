import * as React from 'react';
import { useContext } from 'react';

import type { App } from './reducers/AppsReducer';

type AppContext = { currentApp: App };
type OptionalAppContext = { currentApp: App | null };
type OsContext = { os: string | null };
// type UserContext = {| user: User | null, isSuperadmin: boolean |};

const defaultAppContext: OptionalAppContext = { currentApp: null };

const isAppContext = (obj: unknown): obj is AppContext => (obj as AppContext).currentApp != null;

export const CurrentAppContext: React.Context<AppContext | OptionalAppContext> =
  React.createContext(defaultAppContext);
export const useOptionalCurrentAppContext = () => useContext(CurrentAppContext);
export const useCurrentAppContext = (): AppContext => {
  const context = useOptionalCurrentAppContext();
  if (!isAppContext(context)) {
    throw new Error('currentApp not set');
  }
  return context;
};

const defaultOsContext: OsContext = { os: null };

export const CurrentOsContext: React.Context<OsContext> = React.createContext(defaultOsContext);
export const useCurrentOsContext = () => useContext(CurrentOsContext);

// const defaultUserContext: UserContext = { user: null, isSuperadmin: false };

// export const CurrentUserContext: React.Context<UserContext> = React.createContext(
//   defaultUserContext
// );
// export const useCurrentUserContext = () => useContext(CurrentUserContext);
