import { Modal, Input, Button } from 'antd';
import { Fragment } from 'react';

import type { App } from '../reducers/AppsReducer';
import type { Region } from '../reducers/RegionReducer';

type Props = Readonly<{
  currentApp: App;
  visible: boolean;
  allRegions: Array<Region>;
  onClose: (modalName: string) => void;
}>;

//
//
const DeploymentLinksModal = (props: Props) => {
  const { visible, currentApp, allRegions, onClose } = props;

  return (
    <Modal
      title="Feed preview links"
      centered
      open={visible}
      footer={null}
      onOk={() => onClose('links')}
      onCancel={() => onClose('links')}
      width="80%"
    >
      <h4>Root feed</h4>
      <Button
        type="primary"
        onClick={() => {
          window.open(`/api/apps/${currentApp.hash}/feeds/app.json`, '_blank');
        }}
      >
        open
      </Button>
      <Input
        value={`/api/apps/${currentApp.hash}/feeds/app.json`}
        style={{ width: 'calc(100% - 100px)' }}
      />
      <h4>content feeds</h4>
      <ul>
        {allRegions.map((r) => (
          <li key={`region-${r.id}`}>
            <p>{r.name}</p>
            {r.regionLocales.map((rl) => (
              <Fragment key={`rl-${rl.id}`}>
                <Button
                  type="primary"
                  key={`region-btn-${r.id}-${rl.id}`}
                  onClick={() => {
                    window.open(
                      `/api/apps/${currentApp.hash}/feeds/content.${r.id}.${rl.locale.shortcode}.json`,
                      '_blank'
                    );
                  }}
                >
                  open
                </Button>
                <Input
                  key={`region-${r.id}-${rl.id}`}
                  value={`/api/apps/${currentApp.hash}/feeds/content.${r.id}.${rl.locale.shortcode}.json`}
                  style={{ width: 'calc(100% - 100px)' }}
                />
              </Fragment>
            ))}
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default DeploymentLinksModal;
