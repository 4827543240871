import { combineReducers } from 'redux';

import {
  APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS_OK,
  APP_GET_ANALYTICS_MAUS_OK,
  APP_GET_ANALYTICS_TOTAL_VIEWS_OK,
} from '../constants/analytics';

import type { AllowedMonthLabel } from '../common/constants/months';
import type { AnyAction } from 'redux';

type MonthArray<T> = [T, T, T, T, T, T, T, T, T, T, T, T, T];

type MausRegionMonth = {
  month: AllowedMonthLabel;
  maus: number;
  year: number;
};

export type MausRegion = {
  name: string;
  currentMonth: number;
  priorMonth: number;
  trend: number;
  months: MonthArray<MausRegionMonth>;
};

export type Maus = {
  month: number;
  trendMaus: number;
  trendChange: number;
  regions: Array<MausRegion>;
};

export type AppAnalytics = {
  maus: Maus | null;
  allTime: { total: number; videos: number; audios: number; html: number; unity: number };
  last28Days: {
    total: number;
    trend: number;
    videos: number;
    audios: number;
    html: number;
    unity: number;
    days: Array<{ day: string; views: number }>;
    languages: Array<{
      name: string;
      views: number;
      trend: number;
      days: Array<{ day: string; views: number }>;
    }>;
  };
};

type AppAnalyticsByIdState = {
  [k: number]: AppAnalytics;
};

export type AppAnalyticsState = {
  byId: AppAnalyticsByIdState;
};

const initialStateById: AppAnalyticsByIdState = {};

const byId = (state = initialStateById, action: AnyAction): AppAnalyticsByIdState => {
  switch (action.type) {
    case APP_GET_ANALYTICS_TOTAL_VIEWS_OK: {
      const { appId, payload } = action;

      return {
        ...state,
        [appId]: {
          ...state[appId],
          allTime: payload,
        },
      };
    }

    case APP_GET_ANALYTICS_LAST_28_DAYS_VIEWS_OK: {
      const { appId, payload } = action;

      return {
        ...state,
        [appId]: {
          ...state[appId],
          last28Days: payload,
        },
      };
    }

    case APP_GET_ANALYTICS_MAUS_OK: {
      const { appId, payload } = action;

      return {
        ...state,
        [appId]: {
          ...state[appId],
          maus: payload,
        },
      };
    }

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
});

export default combined;
