import { combineReducers } from 'redux';

import { getEmptyAuthService } from './AuthServiceReducer';
import { GET_APPS_LIST_OK, GET_APP_DETAILS_OK } from '../constants/apps';
import { LOGIN_OK } from '../constants/auth';
import { USER_INVITE_ACCEPT_OK } from '../constants/users';

import type { AuthService } from './AuthServiceReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type BaseAppAuthService = {
  id: number;
  ordinal: number;
};

export type AppAuthServiceNormalized = Merge<
  BaseAppAuthService,
  {
    authService: number;
  }
>;

export type AppAuthService = Merge<
  BaseAppAuthService,
  {
    authService: AuthService;
  }
>;

export type AppAuthServiceStateById = { [k: string | number]: AppAuthServiceNormalized };
export type AppAuthServiceStateAllIds = { [k: string | number]: Array<number> };

export type AppAuthServiceState = {
  byId: AppAuthServiceStateById;
  allIds: AppAuthServiceStateAllIds;
};

export const getEmptyAppAuthService = (id?: number): AppAuthService => ({
  id: id ?? -1,
  ordinal: 0,
  authService: getEmptyAuthService(),
});

const initialStateById: AppAuthServiceStateById = {};
const initialStateAllIds: AppAuthServiceStateAllIds = {};

//
//
// const byIdSlice = createSlice({
//   name: 'appAuthService/byId',
//   initialState: {},
//   reducers: {},
//   extraReducers: (builder) => {
//     builder.addCase(fetchUserById.fulfilled, (state, action) => {
//       // Add user to the state array
//       state.entities.push(action.payload);
//     });
//   },
// });

//
//
const byId = (state = initialStateById, action: AnyAction): AppAuthServiceStateById => {
  switch (action.type) {
    case GET_APPS_LIST_OK: {
      if (action.payload.entities.appAuthServices == null) {
        return state;
      }
      return {
        ...action.payload.entities.appAuthServices,
      };
    }

    case LOGIN_OK:
    case GET_APP_DETAILS_OK: {
      if (action?.payload?.entities?.appAuthServices == null) {
        return state;
      }
      return {
        ...state,
        ...action.payload.entities.appAuthServices,
      };
    }

    case USER_INVITE_ACCEPT_OK: {
      if (action?.updatedUser?.entities?.appAuthServices == null) {
        return state;
      }
      return {
        ...state,
        ...action.updatedUser.entities.appAuthServices,
      };
    }

    default:
      return state;
  }
};

//
//
const allIds = (state = initialStateAllIds, action: AnyAction): AppAuthServiceStateAllIds => {
  switch (action.type) {
    case GET_APPS_LIST_OK: {
      const apps = action?.payload?.result;
      if (apps == null) {
        return state;
      }

      const newState: AppAuthServiceStateAllIds = {};

      apps.forEach((appId: number) => {
        const app = action?.payload?.entities?.apps?.[appId];
        if (app != null) {
          newState[appId] = app.appAuthServices;
        }
      });

      return newState;
    }

    case LOGIN_OK: {
      const apps = action?.payload?.entities?.apps;
      if (apps == null) {
        return state;
      }

      const newState = { ...state };
      Object.keys(apps).forEach((appIdStr) => {
        newState[appIdStr] = apps[appIdStr].appAuthServices;
      });
      return newState;
    }

    case USER_INVITE_ACCEPT_OK: {
      const apps = action?.updatedUser?.entities?.apps;
      if (apps == null) {
        return state;
      }

      const newState = { ...state };
      Object.keys(apps).forEach((appIdStr) => {
        newState[appIdStr] = apps[appIdStr].authServices;
      });
      return newState;
    }

    case GET_APP_DETAILS_OK: {
      const app = action?.payload?.entities?.apps?.[action?.payload?.result];
      if (app == null) {
        return state;
      }

      const newState = { ...state };
      newState[app.id] = app.appAuthServices;
      return newState;
    }

    default:
      return state;
  }
};

const reducers = combineReducers({
  byId,
  allIds,
});

export default reducers;
