import { message, Alert } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { createRegion } from '../actions/regions';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { getTimezoneList } from '../actions/timezones';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_REGIONDETAIL } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import RegionCreate from '../pages/RegionCreate';
import { getAllTimezones } from '../selectors/timezoneSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { RegionEditFormValues } from '../components/RegionEditForm';

//
//
const RegionCreateContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentApp } = useCurrentAppContext();
  const timezones = useGlobalSelector(getAllTimezones);

  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getTimezoneList());
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch]);

  const handleSubmit = async (values: RegionEditFormValues) => {
    try {
      const { name, isDraft, releaseTimezone, useDefaultAsFallback } = values;
      console.log('handleSubmit()', { name, isDraft, releaseTimezone, useDefaultAsFallback });
      const region = await dispatch(
        createRegion(currentApp.id, name, isDraft, releaseTimezone, useDefaultAsFallback)
      );
      history.push(
        getUrlByName(ROUTE_NAME_APP_REGIONDETAIL, {
          ':appUid': currentApp.uid,
          ':regionId': region.id,
        })
      );
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
    return false;
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return <RegionCreate timezones={timezones} onSubmit={handleSubmit} />;
};

export default RegionCreateContainer;
