import { HomeOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';

import styles from './AppListItem.module.scss';
import { useCurrentOsContext } from '../contexts';
import { serialize } from '../utils/url';

import type { App } from '../reducers/AppsReducer';

type Props = Readonly<{
  app: App;
  handleAppHome: (app: App) => void;
  handleAppEdit: (app: App) => void;
}>;

//
//
const AppsListItem = (props: Props) => {
  const { os } = useCurrentOsContext();
  const { app, handleAppHome, handleAppEdit } = props;

  const TEST_APP_UID = process.env.REACT_APP_TEST_APP_UID ?? null;
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT ?? null;

  const actions = [
    <Button
      data-tid="btn-app-home"
      key="btn-app-home"
      size="small"
      icon={<HomeOutlined />}
      onClick={(e) => {
        e.stopPropagation();
        handleAppHome(app);
      }}
    >
      Go to app
    </Button>,
    <Button
      data-tid="btn-app-edit"
      key="btn-app-edit"
      size="small"
      icon={<SettingOutlined />}
      onClick={(e) => {
        e.stopPropagation();
        handleAppEdit(app);
      }}
    >
      Admin settings
    </Button>,
  ];

  if (os === 'iOS' && TEST_APP_UID != null && API_ENDPOINT != null) {
    actions.splice(
      0,
      0,
      <Button
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          window.open(
            `eu.wonderz.mediabox://${TEST_APP_UID}/importBackendConfiguration?${serialize({
              appId: app.uid,
              appFeedUrlTemplate: `${API_ENDPOINT}/apps/${app.hash}/feeds/app.json`,
            })}`,
            '_blank'
          );
        }}
      >
        Preview in App
      </Button>
    );
  }
  return (
    <List.Item
      key={app.id}
      actions={actions}
      id={`app-item-${app.id}`}
      className={styles.item}
      onClick={() => {
        handleAppEdit(app);
      }}
    >
      <List.Item.Meta title={app.name} description={app.uid} />
    </List.Item>
  );
};

export default AppsListItem;
