import { combineReducers } from 'redux';

import { getEmptyFile } from './FileReducer';
import {
  JOB_GET_LIST_OK,
  JOB_GET_DETAILS_OK,
  JOB_GET_METADATA_OK,
  JOB_SWITCH_PAGE,
} from '../constants/jobs';

import type { ParentFile, VersionFile } from './FileReducer';
import type { JobLog } from './JobLogReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type JobBase = {
  id: number;
  serviceId: number;
  externalId: string | null;
  createdAt: Date;
  updatedAt: Date | null;
};

export type JobNormalized = Merge<
  JobBase,
  {
    logs: Array<number>;
    input: number;
    outputs: Array<number> | null;
  }
>;

export type Job = Merge<
  JobBase,
  {
    logs: Array<JobLog>;
    input: ParentFile;
    outputs: Array<VersionFile> | null;
  }
>;

export type JobsPaginationState = {
  itemsPerPage: Record<number, Array<number>>;
  total: number;
  currentPage: number;
  newPageLoading: boolean;
};

export type JobStateById = { [k: string | number]: JobNormalized };
export type JobStateAllIds = Array<number>;

export type JobState = {
  byId: JobStateById;
  allIds: JobStateAllIds;
  pagination: JobsPaginationState;
};

const initialStateById: JobStateById = {};
const initialStateAllIds: JobStateAllIds = [];
const initialStatePagination: JobsPaginationState = {
  itemsPerPage: {},
  total: 0,
  currentPage: 1,
  newPageLoading: false,
};

//
//
export const getEmptyJob = (id?: number): Job => ({
  id: id ?? -1,
  serviceId: -1,
  externalId: null,
  createdAt: new Date(),
  updatedAt: new Date(),
  logs: [],
  input: getEmptyFile(-1) as ParentFile,
  outputs: null,
});

//
//
const byId = (state = initialStateById, action: AnyAction): JobStateById => {
  switch (action.type) {
    case JOB_GET_LIST_OK: {
      const newState: JobStateById = {};
      const jobs = action?.payload?.entities?.jobs ?? {};
      Object.keys(jobs).forEach((jobId) => {
        newState[jobId] = {
          ...state[jobId],
          ...jobs[jobId],
        };
      });
      return { ...state, ...newState };
    }

    case JOB_GET_DETAILS_OK:
    case JOB_GET_METADATA_OK: {
      const newState = { ...state };
      const jobs = action?.payload?.entities?.jobs ?? {};
      Object.keys(jobs).forEach((jobId) => {
        newState[jobId] = {
          ...state[jobId],
          ...jobs[jobId],
        };
      });
      return newState;
    }

    default:
      return state;
  }
};

const allIds = (state = initialStateAllIds, action: AnyAction): JobStateAllIds => {
  switch (action.type) {
    case JOB_GET_LIST_OK:
      return [...new Set(state.concat(action.payload.result))];
    default:
      return state;
  }
};

const pagination = (state = initialStatePagination, action: AnyAction): JobsPaginationState => {
  switch (action.type) {
    case JOB_SWITCH_PAGE: {
      const currentPage: number = action.payload;
      if (state.itemsPerPage[currentPage] == null) {
        // No results in cached state -> start loader with no other changes
        // Don't start loader, when grabbing from cache
        return { ...state, newPageLoading: true };
      }

      return { ...state, currentPage, newPageLoading: false };
    }
    case JOB_GET_LIST_OK: {
      const { currentPage } = action.paginationData;
      const jobsOnPage = action.payload.result;
      const result = {
        ...state.itemsPerPage,
        [currentPage]: jobsOnPage,
      };

      // Switch current page only if it comes from api
      // if it comes from cache - it's already switched
      const page = state.newPageLoading === true ? currentPage : state.currentPage;
      return {
        ...action.paginationData,
        currentPage: page,
        itemsPerPage: result,
        newPageLoading: false,
      };
    }
    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
  pagination,
});

export default combined;
