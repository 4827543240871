import { setupVersionCheck } from '../actions/appversion';

import type { AppDispatch } from '../configureStore';
import type { Middleware } from 'redux';

let hasRehydrated = false;

export const onRehydrationMiddleware: Middleware = (store) => (next) => (action) => {
  // if this Action happens to be 'persist/REHYDRATE' then follow it up with your
  // desired Action
  if (!hasRehydrated && action.type === 'persist/REHYDRATE') {
    hasRehydrated = true;
    // this is to make sure you get the Rehydrated State before making
    next(action);

    console.log(
      'onRehydrationMiddleware()'
      // {
      //   REACT_APP_VERSION_CHECK: process.env.REACT_APP_VERSION_CHECK,
      // },
      // JSON.stringify(store.getState(), null, 2)
    );
    if (process.env.REACT_APP_VERSION_CHECK === 'true') {
      const dispatch = store.dispatch as AppDispatch;
      dispatch(setupVersionCheck());
    }
  } else {
    // make a call to the next action
    next(action);
  }
};
