import { schema, normalize } from 'normalizr';

import { GET_AUTH_SERVICES_OK, GET_AUTH_SERVICES_FAIL, GET_AUTH_SERVICES } from '../constants/auth';
import { AuthServiceSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const getAuthServices = () => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/authservices`,
      method: 'GET',
    },
    dispatch,
    { init: GET_AUTH_SERVICES, fail: GET_AUTH_SERVICES_FAIL }
  );

  const normalizedData = normalize(response.data, new schema.Array(AuthServiceSchema));
  dispatch({ type: GET_AUTH_SERVICES_OK, payload: normalizedData });
};
