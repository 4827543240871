import { List } from 'antd';
import classNames from 'classnames';

import AnalyticsStats from './analytics/AnalyticsStats';
import GraphAndTable from './analytics/GraphAndTable';
import styles from './AnalyticsEpisode.module.scss';
import episodeStyles from '../pages/EpisodeDetail.module.scss';

import type { GraphAndTableData } from './analytics/GraphAndTable';
import type { Episode } from '../reducers/EpisodeReducer';

const AnalyticsEpisode = ({ episode }: { episode: Episode }) => (
  <List
    locale={{ emptyText: 'No Data' }}
    loading={episode.analytics == null}
    header={
      <div id="analytics_section" className={episodeStyles.listHeader}>
        <h2>Analytics</h2>
      </div>
    }
    bordered
    className={episodeStyles.list}
    // Only provide dataSource when analytics is available, otheriwse renderItem()
    // is run and rendering will be broken.
    dataSource={
      episode.analytics != null && episode.views > 0 ? [{ analytics: episode.analytics }] : []
    }
    renderItem={({ analytics }) => {
      const graphAndTableData: GraphAndTableData = {
        days: analytics.viewDays,
        languages: episode.localizedEpisodes
          // Selector returns empty localizedEpisodes when not received yet,
          // so filter them out.
          .filter(({ name }) => name !== '')
          .map((le) => ({
            name: le.locale.name,
            total: le.views,
            views: le.analytics.viewsIn28Days,
            viewsPrior: le.analytics.viewsInPrior28Days,
            trend: le.analytics.viewTrend,
            days: le.analytics.viewDays,
          }))
          .sort((leA, leB) => leB.total - leA.total),
      };

      return (
        <div
          className={classNames(styles.container, {
            [styles.containerWithMoreThenOneLanguage]: episode.localizedEpisodes.length > 1,
          })}
        >
          <AnalyticsStats
            box1Headline="Total views"
            box1Value={episode.views}
            box2Headline="Last 28 days"
            box2Value={analytics.viewsIn28Days ?? 0}
            box3Headline="Previous 28 days"
            box3Value={analytics.viewsInPrior28Days ?? 0}
            box2Trend={analytics.viewTrend}
            testIdPrefix="analytics-stats"
          />
          {Boolean(analytics.viewsIn28Days) && <div className={styles.separator} />}
          <GraphAndTable
            renderGraph={Boolean(analytics.viewsIn28Days)}
            graphTitle="Views per day (last 28 days)"
            graphAndTableData={graphAndTableData}
          />
        </div>
      );
    }}
  />
);

export default AnalyticsEpisode;
