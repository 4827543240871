import {
  EditOutlined,
  SettingOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import { List, Avatar, Tag } from 'antd';

import MbxButton from './base/MbxButton';
import ButtonLinked from './ButtonLinked';
import styles from './UserList.module.scss';
import { getValueFromKey } from '../common/utils/app';
import PendingInvites from '../containers/PendingInvitesContainer';
import { ROUTE_NAME_APP_ROLES, ROUTE_NAME_APP_USERINVITE } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getAppUsers } from '../selectors/appUserSelectors';
import { isUserSuperadmin } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { App } from '../reducers/AppsReducer';

type Props = Readonly<{
  data: App;
  handleUserRemove: (userId: number) => Promise<void>;
  handleUserOpenDetails: (userId: number) => void;
}>;

//
//
const UserList = (props: Props) => {
  const { data, handleUserRemove, handleUserOpenDetails } = props;
  const appConfig = data.configuration ?? [];

  const isSuperadmin = useGlobalSelector(isUserSuperadmin);
  const displayRoles = getValueFromKey<boolean>(appConfig, `adminData.displayRoles`, false);
  const appUsers = useGlobalSelector((state) => getAppUsers(state, data.id));

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>All users that belong to this app</h3>
        {(isSuperadmin || displayRoles) && (
          <div className={styles.headerExtras}>
            <ButtonLinked
              data-tid="button-roles-manage"
              size="middle"
              linkTo={getUrlByName(ROUTE_NAME_APP_ROLES, { ':appUid': data.uid })}
              icon={<SettingOutlined />}
            >
              manage roles
            </ButtonLinked>
            <ButtonLinked
              data-tid="button-open-invite-user"
              linkTo={getUrlByName(ROUTE_NAME_APP_USERINVITE, { ':appUid': data.uid })}
              size="middle"
              icon={<UserAddOutlined />}
              style={{ marginLeft: '10px' }}
            >
              invite user
            </ButtonLinked>
          </div>
        )}
      </div>

      <PendingInvites appId={data.id} />

      <List
        data-tid="user-list"
        bordered
        dataSource={appUsers}
        renderItem={(appUser) => {
          const userActions = [
            <MbxButton
              danger
              size="small"
              icon={<UserDeleteOutlined />}
              data-tid="btn-delete-user"
              key="btn-delete-user"
              onClick={(e) => {
                e.stopPropagation();
                if (
                  window.confirm(
                    `Really remove user ${appUser.user.firstname} ${appUser.user.lastname} from project?`
                  )
                ) {
                  handleUserRemove(appUser.user.id);
                }
              }}
            />,
          ];

          const hasDetailLink = isSuperadmin || displayRoles;

          if (hasDetailLink) {
            userActions.unshift(
              <MbxButton
                size="small"
                data-tid="btn-edit-user"
                icon={<EditOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleUserOpenDetails(appUser.user.id);
                }}
              />
            );
          }

          return (
            <List.Item
              className={hasDetailLink ? styles.item : undefined}
              onClick={
                hasDetailLink
                  ? () => {
                      handleUserOpenDetails(appUser.user.id);
                    }
                  : undefined
              }
              key={appUser.user.id}
              actions={userActions}
            >
              <List.Item.Meta
                avatar={<Avatar src={appUser.user.imageUrl} shape="square" />}
                title={
                  <>
                    <span
                      style={{ paddingRight: '10px' }}
                    >{`${appUser.user.firstname} ${appUser.user.lastname}`}</span>
                    {appUser.user.twofaActivated && <Tag color="green">2FA</Tag>}
                    {appUser.hidden && <Tag color="cyan">HIDDEN</Tag>}
                  </>
                }
                description={appUser.user.email}
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default UserList;
