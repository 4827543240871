import type { Meta, DeploymentContent } from '../types/deployment';
//
//
export const getMetadataForDeployment = (d: DeploymentContent | null) => {
  if (!d) {
    return [];
  }
  const meta: Array<Meta> = [];
  const regions = d['app.json']?.regions ?? [];

  regions.forEach((r) => {
    const locales = r?.locales ?? [];
    const countries = r?.countries ?? [];

    const info: Meta = {
      id: parseInt(r.id, 10),
      locales: locales.length,
      countries: countries.length,
      releaseCount: 0,
    };

    let releaseCount = 0;
    locales.forEach((l) => {
      const localeReleases = (d[`content.${r.id}.${l}.json`] as { releases: [] })?.releases ?? [];
      releaseCount += localeReleases.length;
    });

    info.releaseCount = releaseCount;
    meta.push(info);
  });

  return meta;
};
