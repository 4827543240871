import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { List, Dropdown, Button, Menu, message } from 'antd';
import { useState, useEffect } from 'react';

import styles from './AppPlatformList.module.scss';
import ButtonLinked from './ButtonLinked';
import { createAppPlatform, deleteAppPlatform } from '../actions/appplatforms';
import { fetchPlatformList } from '../actions/platforms';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { ROUTE_NAME_INTERNAL_APPPLATFORMDETAIL } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getAppPlatformsForApp } from '../selectors/appPlatformSelectors';
import { getAllPlatforms } from '../selectors/platformSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { AppPlatform } from '../reducers/AppPlatformReducer';
import type { MenuProps } from 'antd';

type Props = {
  appId: number;
};

//
//
const AppPlatformList = (props: Props) => {
  const { appId } = props;

  const dispatch = useAppDispatch();
  const platforms = useGlobalSelector(getAllPlatforms);
  const appPlatforms = useGlobalSelector((state) => getAppPlatformsForApp(state, appId));

  const [isUpdatingPlatform, setIsUpdatingPlatform] = useState(false);

  useEffect(() => {
    const loadPlatforms = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(fetchPlatformList());
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    loadPlatforms();
  }, [dispatch]);

  const handleAddPlatform: MenuProps['onClick'] = async (info) => {
    const platformId = Number(info.key.split('-').pop());
    if (!Number.isNaN(platformId)) {
      try {
        setIsUpdatingPlatform(() => true);
        await dispatch(createAppPlatform(appId, platformId));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        setIsUpdatingPlatform(() => false);
      }
    }
  };

  const handleDeletePlatform = async (appPlatform: AppPlatform) => {
    if (window.confirm(`Really delete platform '${appPlatform.platform.name}'`)) {
      console.log('DELETE', appId, appPlatform);
      try {
        await dispatch(deleteAppPlatform(appId, appPlatform.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      }
    }
  };

  return (
    <div style={{ marginTop: '4em' }} data-tid="app-platform-container">
      <div className={styles.header}>
        <h2>Platforms</h2>
        <div className={styles.headerExtras}>
          <Dropdown
            disabled={
              isUpdatingPlatform ||
              platforms.length === 0 ||
              platforms.length === appPlatforms.length
            }
            overlay={
              <Menu onClick={handleAddPlatform} data-tid="app-platform-dropdown">
                {platforms.map((platform) => (
                  <Menu.Item
                    key={`add-platform-menuitem-${platform.id}`}
                    disabled={appPlatforms.find((apf) => apf?.platform?.id === platform.id) != null}
                  >
                    {platform.name}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              loading={isUpdatingPlatform}
              data-tid="btn-add-appplatform"
            >
              Add a platform
            </Button>
          </Dropdown>
        </div>
      </div>

      <div>
        <List
          data-tid="appplatform-list"
          bordered
          dataSource={appPlatforms}
          renderItem={(appPlatform) => {
            const actions = [];

            actions.push(
              <ButtonLinked
                linkTo={getUrlByName(ROUTE_NAME_INTERNAL_APPPLATFORMDETAIL, {
                  ':appId': appId.toString(),
                  ':appPlatformId': appPlatform.id.toString(),
                })}
                data-tid="btn-appplatform-versions"
                size="small"
              >
                Versions
              </ButtonLinked>
            );

            actions.push(
              <Button
                data-tid="btn-appplatform-delete"
                size="small"
                icon={<DeleteOutlined />}
                danger
                ghost
                onClick={() => {
                  handleDeletePlatform(appPlatform);
                }}
              >
                delete
              </Button>
            );

            return (
              <List.Item
                key={`appplatform-item-${appPlatform.id}`}
                actions={actions}
                className={styles.item}
              >
                <List.Item.Meta title={appPlatform?.platform?.name ?? '??'} />
              </List.Item>
            );
          }}
        />
      </div>
    </div>
  );
};

export default AppPlatformList;
