import { Tag } from 'antd';

import type { DeploymentMeta } from '../../typings/deployments';
import type { Region } from '../reducers/RegionReducer';

type Props = Readonly<{
  meta: Array<DeploymentMeta> | null | undefined;
  oldMeta?: Array<DeploymentMeta> | null;
  allRegions: Array<Region>;
}>;

//
//
const getColor = (oldNum: number, newNum: number) => {
  const diff = oldNum - newNum;
  if (diff === 0) {
    return '#ccc';
  }
  if (diff < 0) {
    return '#a8d493';
  }
  return '#ff8fae';
};

//
//
const getDiff = (oldNum: number, newNum: number) => {
  const diff = newNum - oldNum;
  if (diff > 0) {
    return `+${diff}`;
  }
  if (diff === 0) {
    return `±${diff}`;
  }
  return diff;
};

//
//
const DeploymentRegionTable = (props: Props) => {
  const { allRegions } = props;

  /* eslint-disable react/destructuring-assignment */
  const meta = props.meta ?? [];
  const oldMeta = props.oldMeta ?? [];
  /* eslint-enable */

  return (
    <div
      style={{
        margin: '1em 0',
        padding: 8,
        fontSize: 12,
        border: '1px solid #ccc',
        borderRadius: 4,
      }}
      data-tid="deployment-region-table"
    >
      <table style={{ width: '100%' }}>
        <thead
          style={
            {
              /* borderBottom: '1px solid #ccc' */
            }
          }
        >
          <tr style={{ fontWeight: 'bold' }}>
            <td style={{ padding: '4px 0' }}>Market</td>
            <td>Locales</td>
            <td>Countries</td>
            <td>Releases</td>
          </tr>
        </thead>
        <tbody>
          {meta.map((item) => {
            const oldItem = oldMeta.find((o) => o.id === item.id);
            const region = allRegions.find((r) => r.id === item.id);
            const regionName = region?.name ?? item.id;

            return (
              <tr key={`table-${item.id}`}>
                <td style={{ /* lineBreak: 'none', */ padding: '8px 0 0' }}>
                  <Tag>{regionName}</Tag>
                </td>
                <td>
                  <span>{item.locales}</span>
                  {oldItem != null && (
                    <span
                      style={{ marginLeft: 4, color: getColor(oldItem.locales, item.locales) }}
                    >{`(${getDiff(oldItem.locales, item.locales)})`}</span>
                  )}
                </td>
                <td>
                  <span>{item.countries}</span>
                  {oldItem != null && (
                    <span
                      style={{ marginLeft: 4, color: getColor(oldItem.countries, item.countries) }}
                    >{`(${getDiff(oldItem.countries, item.countries)})`}</span>
                  )}
                </td>
                <td>
                  <span>{item.releaseCount}</span>
                  {oldItem != null && (
                    <span
                      style={{
                        marginLeft: 4,
                        color: getColor(oldItem.releaseCount, item.releaseCount),
                      }}
                    >{`(${getDiff(oldItem.releaseCount, item.releaseCount)})`}</span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DeploymentRegionTable;
