import { message, Alert } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getDeploymentDetails, getPreviousDeploymentForId } from '../actions/deployments';
import { getRegionList } from '../actions/regions';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { useCurrentAppContext } from '../contexts';
import DeploymentDetail from '../pages/DeploymentDetail';
import { getDeploymentById } from '../selectors/deploymentSelectors';
import { getAllRegionsForApp } from '../selectors/regions/advanced';
import { getDeploymentId } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { DeploymentFetchData } from '../../typings/deployments';

//
//
const DeploymentDetailContainer = () => {
  const dispatch = useAppDispatch();
  const { currentApp } = useCurrentAppContext();

  const params = useParams();
  const currentDeploymentId = getDeploymentId(params);

  const currentDeployment = useGlobalSelector((state) =>
    getDeploymentById(state, currentDeploymentId)
  );
  const allRegions = useGlobalSelector((state) => getAllRegionsForApp(state, currentApp.id));

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [previousDeployment, setPreviousDeployment] = useState<DeploymentFetchData['latest']>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      console.log('DeploymentDetailContainer.fetchData()', currentApp.id);
      if (currentApp.id === -1) {
        return;
      }
      try {
        dispatch(counterIncrement());
        await dispatch(getRegionList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };

    fetchData();
  }, [dispatch, currentApp.id]);

  useEffect(() => {
    const fetchCurrentData = async () => {
      console.log('DeploymentDetailContainer.fetchCurrentData()');
      try {
        dispatch(counterIncrement());
        if (currentDeploymentId != null) {
          setIsDataLoading(true);
          await dispatch(getDeploymentDetails(currentDeploymentId));
          const prev = await dispatch(getPreviousDeploymentForId(currentDeploymentId));
          setPreviousDeployment(prev);
          setLoadingPercent(100);
        }
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        setIsDataLoading(false);
        dispatch(counterDecrement());
      }
    };
    fetchCurrentData();
  }, [dispatch, currentDeploymentId]);

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <DeploymentDetail
      currentDeployment={currentDeployment}
      previousDeployment={previousDeployment}
      allRegions={allRegions}
      isLoading={isDataLoading}
      loadingPercent={loadingPercent}
    />
  );
};

export default DeploymentDetailContainer;
