import type { QueueData, QueueItem } from '../reducers/QueueReducer';
import type { Merge } from 'type-fest';

export const ADD_TO_QUEUE = 'ADD_TO_QUEUE';

export const CHECK_NEXT_IN_QUEUE = 'CHECK_NEXT_IN_QUEUE';
export const QUEUE_ITEM_FOR_UPLOAD = 'QUEUE_ITEM_FOR_UPLOAD';

export const UPLOAD_START = 'UPLOAD_START';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_DONE = 'UPLOAD_DONE';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';
export const UPLOAD_CANCELED = 'UPLOAD_CANCELED';

export const FILE_STATUS_UPDATE = 'FILE_STATUS_UPDATE';
export const FILE_STATUS_UPDATE_OK = 'FILE_STATUS_UPDATE_OK';
export const FILE_STATUS_UPDATE_FAIL = 'FILE_STATUS_UPDATE_FAIL';

//
//
export type AddToQueueAction = {
  type: 'ADD_TO_QUEUE';
  payload: Merge<QueueData, QueueItem>;
};

//
//
export type CheckNextInQueueAction = {
  type: 'CHECK_NEXT_IN_QUEUE';
};

//
//
export type QueueItemForUploadAction = {
  type: 'QUEUE_ITEM_FOR_UPLOAD';
  payload: number;
};

//
//
export type UploadStartAction = {
  type: 'UPLOAD_START';
  payload: number;
  xhr: XMLHttpRequest;
  resultId: number;
};

//
//
export type UploadProgressAction = {
  type: 'UPLOAD_PROGRESS';
  payload: {
    id: number;
    percent: number;
  };
};

//
//
export type UploadDoneAction = {
  type: 'UPLOAD_DONE';
  payload: number;
};

//
//
export type UploadFailedAction = {
  type: 'UPLOAD_FAILED';
  payload: number;
  error: Error;
};

//
//
export type UploadCanceledAction = {
  type: 'UPLOAD_CANCELED';
  payload: number;
};

//
//
export type QueueAction =
  | AddToQueueAction
  | CheckNextInQueueAction
  | QueueItemForUploadAction
  | UploadStartAction
  | UploadProgressAction
  | UploadDoneAction
  | UploadFailedAction
  | UploadCanceledAction;

//
//
export type FileStatusUpdateAction = {
  type: 'FILE_STATUS_UPDATE';
};

//
//
export type FileStatusUpdateFailAction = {
  type: 'FILE_STATUS_UPDATE_FAIL';
};

//
//
export type FileStatusUpdateOkAction = {
  type: 'FILE_STATUS_UPDATE_OK';
};

//
//
export type FileStatusAction =
  | FileStatusUpdateAction
  | FileStatusUpdateFailAction
  | FileStatusUpdateOkAction;
