import { Modal } from 'antd';
import { useRef } from 'react';

import ItemVersionRequirementForm from './ItemVersionRequirementForm';

import type { Handle, ItemVersionRequirementFormValues } from './ItemVersionRequirementForm';
import type { AppPlatform } from '../reducers/AppPlatformReducer';
import type { AssetResourceVersionRequirement } from '../reducers/AssetResourceVersionRequirementReducer';
import type { EpisodeVersionRequirement } from '../reducers/EpisodeVersionRequirementReducer';

type Props = Readonly<{
  visible: boolean;
  selectedAppPlatform: AppPlatform | null;
  selectedVersionRequirement: EpisodeVersionRequirement | AssetResourceVersionRequirement | null;
  onCreate: (appPlatformId: number, values: ItemVersionRequirementFormValues) => Promise<boolean>;
  onUpdate: (
    episodeVersionRequirementId: number,
    values: ItemVersionRequirementFormValues
  ) => Promise<boolean>;
  onClose: () => void;
}>;

//
//
const ItemVersionRequirementsModal = (props: Props) => {
  const {
    visible,
    selectedAppPlatform: ap,
    selectedVersionRequirement: vr,
    onCreate,
    onUpdate,
    onClose,
  } = props;

  const type = vr != null ? 'edit' : 'create';

  const formCompRef = useRef<Handle>(null);

  const handleSubmit = async (values: ItemVersionRequirementFormValues) => {
    let success = false;
    if (type === 'edit') {
      const episodeVersionRequirementId = vr?.id;
      if (episodeVersionRequirementId == null) {
        throw new Error('episodeVersionRequirementId not set');
      }
      success = await onUpdate(episodeVersionRequirementId, values);
    } else {
      const appPlatformId = ap?.id;
      if (appPlatformId == null) {
        throw new Error('appPlatformId not set');
      }
      success = await onCreate(appPlatformId, values);
    }
    return success;
  };

  const handleClose = () => {
    onClose();
    // try resetting form when modal is closed
    const reset = formCompRef?.current?.reset ?? null;
    if (typeof reset === 'function') {
      reset();
    }
  };

  return (
    <Modal
      title={
        type === 'edit'
          ? `Edit ${vr?.appPlatform?.platform?.name ?? '??'}`
          : `Create requirement for ${ap?.platform?.name ?? '??'}`
      }
      centered
      open={visible}
      footer={null}
      onCancel={handleClose}
      width={570}
    >
      <ItemVersionRequirementForm
        ref={formCompRef}
        selectedAppPlatform={ap}
        selectedVersionRequirement={vr}
        onSubmit={handleSubmit}
        onCancel={handleClose}
      />
    </Modal>
  );
};

export default ItemVersionRequirementsModal;
