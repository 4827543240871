import { UploadOutlined } from '@ant-design/icons';
import { Button, Badge, Popover } from 'antd';
import classNames from 'classnames';
import { useRef, useEffect, useState } from 'react';

import styles from './UploadQueue.module.scss';
import UploadQueueList from './UploadQueueList';
import { checkFilesStatus as checkFilesStatusAction } from '../actions/queue';
import { FileStatus } from '../common/constants/file-status';
import { getAllFiles } from '../selectors/fileSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';

import type { File } from '../reducers/FileReducer';
import type { QueueItem } from '../reducers/QueueReducer';

// this has to live outside of the actual component so that we are adding/removing the same instance
// of the method on different runthroughs
const confirmBeforeClose = (event: BeforeUnloadEvent) => {
  // Cancel the event as stated by the standard.
  event.preventDefault();
  // Chrome requires returnValue to be set.
  event.returnValue = '';
};

type Props = {
  queuedItems: Array<QueueItem>;
};

//
//
const UploadQueue = (props: Props) => {
  const { queuedItems } = props;

  // const [fileStatusInterval, setFileStatusInterval] = useState<null | number>(null);
  const timeoutRef = useRef<number | null>(null);
  const [timeoutCounter, setTimeoutCounter] = useState(0);

  const dispatch = useAppDispatch();
  const allFiles = useGlobalSelector(getAllFiles);

  const checkFilesStatus = async (fileIds: Array<number>) => {
    await dispatch(checkFilesStatusAction(fileIds));
  };

  const checkStatusDone = async (ids: Array<number>) => {
    if (timeoutRef.current != null) {
      window.clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = null;
    // setFileStatusInterval(null);
    await checkFilesStatus(ids);
    // checkStatus(allFiles);

    setTimeoutCounter((c) => c + 1);
  };

  const checkStatusStartTimeout = (ids: Array<number>) => {
    console.log('checkStatusStartTimeout', timeoutRef.current);
    if (timeoutRef.current != null) {
      window.clearTimeout(timeoutRef.current);
    }
    const to = window.setTimeout(checkStatusDone, 5000, ids);
    // setFileStatusInterval(to);
    timeoutRef.current = to;
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const checkStatus = (files: Array<File>) => {
      const idsThatAreStillProcessing: Array<number> = [];
      files.forEach((f) => {
        const status = f.lastStatusId;
        if (
          status !== FileStatus.READY &&
          status !== FileStatus.ERRORED &&
          status !== FileStatus.TIMEDOUT &&
          status !== FileStatus.ARCHIVED
        ) {
          idsThatAreStillProcessing.push(f.id);
        }
      });

      if (idsThatAreStillProcessing.length > 0) {
        checkStatusStartTimeout(idsThatAreStillProcessing);
      }
    };

    if (timeoutRef.current === null) {
      checkStatus(allFiles);
    }
  }, [allFiles, timeoutCounter]);

  const currentlyUploadingItems = queuedItems.filter(
    (item) => item.state === 'added' || item.state === 'initializing' || item.state === 'uploading'
  );

  if (currentlyUploadingItems.length > 0) {
    window.addEventListener('beforeunload', confirmBeforeClose);
  } else {
    window.removeEventListener('beforeunload', confirmBeforeClose);
  }

  return (
    <Popover
      placement="bottomRight"
      content={<UploadQueueList queuedItems={queuedItems} />}
      trigger="click"
      align={{ offset: [12, 0] }}
      overlayClassName={classNames([styles.uploadQueuePopover, 'TEST_upload-popover'])} // "uploadQueuePopover"
      // data-* attributes are not working here - use class hack instead
      // data-tid="upload-popover"
    >
      <Badge
        data-tid="upload-window-badge"
        count={currentlyUploadingItems.length}
        offset={[-4, 4]}
        style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }}
      >
        <Button
          data-tid="upload-window-btn"
          ghost
          type="dashed"
          shape="circle"
          icon={<UploadOutlined />}
        />
      </Badge>
    </Popover>
  );
};

export default UploadQueue;
