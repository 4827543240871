import { getEpisodePlayerTypeInfo } from '../utils/episode';

import type { Episode } from '../reducers/EpisodeReducer';

type Props = Readonly<{
  episode: Episode;
}>;

//
//
const ListItemType = (props: Props) => {
  const { episode } = props;

  const resourceInfo = getEpisodePlayerTypeInfo(episode);

  return (
    <div
      style={{
        backgroundColor: `${resourceInfo.color}4C`, // 4C => ~30% (see https://stackoverflow.com/a/8254129/388026)
        width: 18,
        marginRight: 12,
        position: 'relative',
        borderRadius: 8,
      }}
    >
      <p
        style={{
          position: 'absolute',
          top: 50,
          left: 3,
          fontSize: 11,
          color: '#000',
          margin: '0 0 0 -2px',
          transformOrigin: '0 0',
          transform: 'rotate(-90deg)',
          textTransform: 'uppercase',
          width: 50,
          textAlign: 'center',
        }}
      >
        {resourceInfo.label}
      </p>
    </div>
  );
};

export default ListItemType;
