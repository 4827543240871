import { Form, Input, Button } from 'antd';

import type { RuleObject, StoreValue } from 'rc-field-form/lib/interface';

type Props = Readonly<{
  email: string;
  onSubmit: (password: string) => Promise<void>;
  isSending: boolean;
}>;

type PasswordResetFormValues = {
  email: string;
  password: string;
  passwordConfirm: string;
};

//
//
const PasswordResetForm = (props: Props) => {
  const { isSending, email, onSubmit } = props;
  const [form] = Form.useForm();

  const handleSubmit = (values: PasswordResetFormValues) => {
    onSubmit(values.password);
  };

  const compareToFirstPassword = (rule: RuleObject, value: StoreValue) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject("The passwords don't match!");
    }
    return Promise.resolve();
  };

  return (
    <div>
      <Form onFinish={handleSubmit} noValidate form={form} name="PasswordResetForm">
        <Form.Item
          label="Email"
          name="email"
          initialValue={email}
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Has to be valid email!' },
          ]}
          validateTrigger="onSubmit"
        >
          <Input
            data-tid="passwordreset-input-email"
            autoFocus
            type="email"
            disabled
            style={{ width: 420 }}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          hasFeedback
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password data-tid="register-input-password" style={{ width: 420 }} />
        </Form.Item>
        <Form.Item
          label="Password confirm"
          hasFeedback
          name="passwordConfirm"
          rules={[
            { required: true, message: 'Please confirm your password!' },
            {
              validator: compareToFirstPassword,
            },
          ]}
        >
          <Input.Password data-tid="register-input-password-repeat" style={{ width: 420 }} />
        </Form.Item>

        <Form.Item>
          <Button
            data-tid="passwordreset-input-submit"
            loading={isSending}
            type="primary"
            htmlType="submit"
          >
            Send
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordResetForm;
