import { Alert } from 'antd';
import QRCode from 'qrcode.react'; // eslint-disable-line import/no-named-as-default

import TwofaCodeForm from '../components/TwofaCodeForm';

import type { TwofaCodeFormValues } from '../components/TwofaCodeForm';
import type { User } from '../reducers/UsersReducer';

type Props = Readonly<{
  profile: User;
  qrCodeData: { url: string; overwrite: boolean } | null;
  twofaActivate: (data: TwofaCodeFormValues) => Promise<void>;
}>;

//
//
const Profile = (props: Props) => {
  const { profile, qrCodeData, twofaActivate } = props;
  const { email } = profile;

  return (
    <div>
      <h1>Setup 2FA for the following account/email</h1>
      <p className="lead">{email}</p>
      {qrCodeData != null && (
        <div>
          <QRCode value={qrCodeData.url} size={240} />

          <p style={{ marginTop: '2em', maxWidth: '420px', fontSize: '9px' }}>{qrCodeData.url}</p>

          <p style={{ marginTop: '2em', maxWidth: '420px' }}>
            Scan this code with your 2FA app and enter a generated code below to proceed and
            activate 2FA for your account
          </p>

          {qrCodeData.overwrite && (
            <Alert
              style={{ maxWidth: '420px' }}
              message="Warning"
              description="You’re about to change your two-factor authentication device. This will invalidate your current two-factor devices."
              type="warning"
              showIcon
            />
          )}

          <TwofaCodeForm onSubmitCode={twofaActivate} askForPassword isLoading={false} />
        </div>
      )}
    </div>
  );
};

export default Profile;
