//

import { QrcodeOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  getAppAnalyticsLast28DaysViews,
  getAppAnalyticsMaus,
  getAppAnalyticsTotalViews,
  getTopTenEpisodesAllTime,
  getTopTenEpisodesSelectedWeek,
} from '../actions/analytics';
import { counterDecrement, counterIncrement } from '../actions/syncing';
import { getValueByName } from '../common/utils/configuration';
import AnalyticsApp from '../components/AnalyticsApp';
import TopTenEpisodesList from '../components/TopTenEpisodesList';
import { useOptionalCurrentAppContext } from '../contexts';
import { ROUTE_NAME_PROFILETWOFASETUP } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getAuth, getUserProfileOptional, isUserSuperadmin } from '../selectors/authSelectors';
import {
  getEpisodeTopTenAllTime,
  getEpisodeTopTenCurrentWeek,
} from '../selectors/episodeTopTenSelectors';
import dayjs from '../utils/date';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

//
const AppHome = () => {
  const { currentApp } = useOptionalCurrentAppContext();
  const isSuperadmin = useGlobalSelector(isUserSuperadmin);
  const { authServiceName } = useGlobalSelector(getAuth);
  const currentUser = useGlobalSelector(getUserProfileOptional);
  const [appAuthServiceNames, setAppAuthServiceName] = useState(
    currentApp?.authServices.map((aas) => aas.authService?.name) ?? []
  );
  const [selectedWeek, setSelectedWeek] = useState<string>(
    dayjs().startOf('isoWeek').format('YYYY-MM-DD')
  );

  const topTenEpisodesCurrentWeek = useGlobalSelector((state) =>
    getEpisodeTopTenCurrentWeek(state, currentApp?.id)
  );
  const topTenEpisodesAllTime = useGlobalSelector((state) =>
    getEpisodeTopTenAllTime(state, currentApp?.id)
  );

  const dispatch = useAppDispatch();

  const [errorsSwitchers, setErrorsSwitchers] = useState({
    showWrongAuthServiceError: false,
    showTwofaError: false,
  });

  useEffect(() => {
    setAppAuthServiceName(currentApp?.authServices.map((aas) => aas.authService?.name) ?? []);
  }, [currentApp]);

  const currentAppId = currentApp?.id ?? null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentAppId != null) {
          dispatch(counterIncrement());
          await dispatch(getTopTenEpisodesSelectedWeek(currentAppId, selectedWeek));
        }
      } catch (err) {
        logUnknownError(err);
      } finally {
        dispatch(counterDecrement());
      }
    };

    fetchData();
  }, [selectedWeek, currentAppId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentAppId != null) {
          dispatch(counterIncrement());
          await Promise.all([
            dispatch(getTopTenEpisodesAllTime(currentAppId)),
            dispatch(getAppAnalyticsTotalViews(currentAppId)),
            dispatch(getAppAnalyticsMaus(currentAppId)),
          ]);
          await dispatch(getAppAnalyticsLast28DaysViews(currentAppId));
        }
      } catch (err) {
        logUnknownError(err);
      } finally {
        dispatch(counterDecrement());
      }
    };

    fetchData();
  }, [currentAppId]);

  useEffect(() => {
    if (!isSuperadmin && appAuthServiceNames.includes(authServiceName) === false) {
      setErrorsSwitchers({
        ...errorsSwitchers,
        showWrongAuthServiceError: true,
      });
    } else {
      setErrorsSwitchers({
        ...errorsSwitchers,
        showWrongAuthServiceError: false,
      });
    }
  }, [isSuperadmin, authServiceName, appAuthServiceNames]);

  useEffect(() => {
    if (currentApp != null) {
      const appForcingTwofa = getValueByName<boolean>(currentApp, 'appData.forceTwofa', false);
      const userHasTwofaActivated = currentUser?.twofaActivated ?? false;
      const isDisabledBcTwofaForce = appForcingTwofa && !userHasTwofaActivated;
      if (isDisabledBcTwofaForce === true) {
        setErrorsSwitchers({
          ...errorsSwitchers,
          showTwofaError: true,
        });
      } else {
        setErrorsSwitchers({
          ...errorsSwitchers,
          showTwofaError: false,
        });
      }
    } else {
      setErrorsSwitchers({
        ...errorsSwitchers,
        showTwofaError: false,
      });
    }
  }, [currentApp, currentUser]);

  if (
    errorsSwitchers.showWrongAuthServiceError === false &&
    errorsSwitchers.showTwofaError === false
  ) {
    return (
      <>
        <Row gutter={15}>
          {currentApp?.id != null && <AnalyticsApp currentAppId={currentApp.id} />}
        </Row>
        <Row gutter={15}>
          <Col data-tid="topTenEpisodesCurrentWeek" md={{ span: 12 }} xs={{ span: 24 }}>
            <TopTenEpisodesList
              title="Most viewed episodes (by week)"
              episodesData={topTenEpisodesCurrentWeek}
              currentAppUid={currentApp?.uid}
              onWeekChange={(d) =>
                setSelectedWeek(d?.startOf('isoWeek').format('YYYY-MM-DD') ?? selectedWeek)
              }
            />
          </Col>
          <Col data-tid="topTenEpisodesAllTime" md={{ span: 12 }} xs={{ span: 24 }}>
            <TopTenEpisodesList
              title="Most viewed episodes (all time)"
              episodesData={topTenEpisodesAllTime}
              currentAppUid={currentApp?.uid}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <div
      style={{
        border: '1px solid red',
        borderRadius: '4px',
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
        padding: '8px',
        marginRight: 16,
        fontSize: 12,
        lineHeight: 1.3,
      }}
      data-tid="home-warnings"
    >
      {errorsSwitchers.showWrongAuthServiceError && (
        <>
          <h4>Auth services: </h4>
          <p>This app requires you to login via one of the following Auth-Services:</p>
          {appAuthServiceNames.length === 0 && (
            <p style={{ fontWeight: 'bold' }}>No Auth-Services available!</p>
          )}
          {appAuthServiceNames.length > 0 && (
            <>
              <ol data-tid="auth-service-error-app-list" style={{ margin: '1em 0' }}>
                {appAuthServiceNames.map((name) => (
                  <li key={`aas-${name}`} style={{ fontWeight: 'bold' }}>
                    {name}
                  </li>
                ))}
              </ol>
              <p>
                As long as you don&#39;t do that, you don&#39;t have access to the app&#39;s
                resources.
              </p>
            </>
          )}
        </>
      )}
      {errorsSwitchers.showTwofaError && (
        <div data-tid="twofa-warning">
          <h4>TWOFA:</h4>
          This app requires you to have two-factor authentication activated. As long as you
          don&#39;t do that, you don&#39;t have access to the app&#39;s resources.
          <Link to={getUrlByName(ROUTE_NAME_PROFILETWOFASETUP)} style={{ color: 'red' }}>
            <QrcodeOutlined />
            <span
              style={{
                margin: '4px 0',
                fontSize: '14px',
                paddingLeft: 8,
              }}
            >
              Setup 2FA
            </span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default AppHome;
