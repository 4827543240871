import { Spin, Alert } from 'antd';
import { Redirect } from 'react-router-dom';

import PasswordResetForm from '../components/PasswordResetForm';
import { ROUTE_NAME_HOME } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getUserProfileOptional } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

type Props = Readonly<{
  data: { email: string } | Error | null;
  sendData: { isSending: boolean; error: string };
  resetPassword: (password: string) => Promise<void>;
}>;

//
//
const PasswordReset = (props: Props) => {
  const { data, resetPassword, sendData } = props;
  const user = useGlobalSelector(getUserProfileOptional);

  if (user != null) {
    return <Redirect to={getUrlByName(ROUTE_NAME_HOME)} />;
  }

  if (data == null) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  if (data instanceof Error) {
    let errorMessage;
    switch (data.message) {
      case 'token_not_found':
      case 'token_invalid':
        errorMessage = 'This is not a valid password reset token';
        break;
      case 'token_expired':
        errorMessage = 'This password reset token already expired';
        break;
      default:
        errorMessage = data.message;
    }

    return (
      <div>
        <Alert
          message="Error"
          description={errorMessage}
          type="error"
          showIcon
          style={{ width: '66%' }}
        />
      </div>
    );
  }

  return (
    <div>
      <h1>Password reset</h1>

      <PasswordResetForm
        email={data.email}
        onSubmit={resetPassword}
        isSending={sendData.isSending}
      />

      {sendData.error !== '' && <p style={{ color: '#f00' }}>{sendData.error}</p>}
    </div>
  );
};

export default PasswordReset;
