import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { List, Button, message, Alert } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import styles from './AppPlatformDetail.module.scss';
import { getAppDetails } from '../actions/apps';
import { createAppVersion, updateAppVersion, deleteAppVersion } from '../actions/appversions';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import AppVersionAddModal from '../components/AppVersionAddModal';
import CustomPageHeader from '../components/CustomPageHeader';
import { ROUTE_NAME_INTERNAL_APPDETAIL } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getAppPlatformById } from '../selectors/appPlatformSelectors';
import { getAppById } from '../selectors/apps/advanced';
import { getAppPlatformId, getAppId } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { AppVersion } from '../reducers/AppVersionReducer';

//
//
const AppPlatformDetail = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const appId = getAppId(params);
  const app = useGlobalSelector((state) => getAppById(state, appId));
  const appPlatformId = getAppPlatformId(params);
  const appPlatform = useGlobalSelector((state) => getAppPlatformById(state, appPlatformId));

  const [modalInfo, setModalInfo] = useState<{
    isOpen: boolean;
    selectedVersion: null | AppVersion;
  }>({ isOpen: false, selectedVersion: null });
  const [error, setError] = useState('');

  useEffect(() => {
    const loadData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getAppDetails(appId));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    loadData();
  }, [dispatch, appId]);

  const handleVersionAdd = () => {
    setModalInfo({ isOpen: true, selectedVersion: null });
  };

  const handleVersionAddCancel = () => {
    setModalInfo({ isOpen: false, selectedVersion: null });
  };

  const handleVersionOpenDetails = (version: AppVersion) => {
    setModalInfo({ isOpen: true, selectedVersion: version });
  };

  const handleOnSubmit = async (
    appVersionId: number | null | undefined,
    values: { name: string; description: string | null | undefined }
  ) => {
    try {
      console.log('handleOnSubmit()', { appVersionId, values });
      if (appVersionId != null) {
        await dispatch(updateAppVersion(appVersionId, values));
      } else {
        await dispatch(createAppVersion(appPlatformId, values.name, values.description));
      }
      handleVersionAddCancel();
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
    return false;
  };

  const handleVersionDelete = async (version: AppVersion) => {
    try {
      await dispatch(deleteAppVersion(appPlatformId, version.id));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <div>
      <CustomPageHeader
        title={`App versions for ${app?.name ?? '??'} / ${appPlatform?.platform?.name ?? '??'}`}
        backLink={getUrlByName(ROUTE_NAME_INTERNAL_APPDETAIL, {
          ':appId': appId,
        })}
        extra={[
          <Button
            type="primary"
            onClick={handleVersionAdd}
            icon={<PlusCircleOutlined />}
            key="btn-add-version"
            data-tid="btn-add-version"
          >
            add version
          </Button>,
        ]}
      />

      <List
        data-tid="app-version-list"
        className={styles.list}
        bordered={false}
        dataSource={appPlatform.versions}
        renderItem={(version: AppVersion) => {
          const actions = [
            <Button
              size="small"
              icon={<EditOutlined />}
              data-tid="btn-edit-version"
              key="btn-edit-version"
              onClick={(e) => {
                e.stopPropagation();
                handleVersionOpenDetails(version);
              }}
            >
              edit
            </Button>,
            <Button
              ghost
              size="small"
              danger
              icon={<DeleteOutlined />}
              data-tid="btn-delete-version"
              key="btn-delete-version"
              onClick={(e) => {
                e.stopPropagation();
                if (window.confirm(`Really delete version '${version.name}'`)) {
                  handleVersionDelete(version);
                }
              }}
            >
              delete
            </Button>,
          ];

          return (
            <List.Item
              className={styles.listItem}
              onClick={() => {
                handleVersionOpenDetails(version);
              }}
              key={`appversion-item-${version.id}`}
              actions={actions}
            >
              <List.Item.Meta title={version.name} description={version.description} />
            </List.Item>
          );
        }}
      />

      <AppVersionAddModal
        visible={modalInfo.isOpen}
        onSubmit={handleOnSubmit}
        onClose={handleVersionAddCancel}
        data={modalInfo.selectedVersion}
      />
    </div>
  );
};

export default AppPlatformDetail;
