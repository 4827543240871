import { createSelector } from 'reselect';

import { getFileByIdFunc, getFilesByIdsFunc } from './fileSelectors';
import { getJobLogsByIdsFunc } from './jobLogSelectors';
import { getEmptyJob } from '../reducers/JobReducer';
import { sortByIdDesc } from '../utils/sort';
import { notUndefined } from '../utils/typeGuards';

import type { RootState } from '../reducers';
import type { ParentFile, VersionFile } from '../reducers/FileReducer';
import type { Job } from '../reducers/JobReducer';

//
//
const getById = (state: RootState) => state.jobs.byId;
const allIds = (state: RootState) => state.jobs.allIds;
const getPaginationData = (state: RootState) => state.jobs.pagination;

//
//
export const getJobByIdFunc = createSelector(
  getById,
  getFileByIdFunc,
  getJobLogsByIdsFunc,
  getFilesByIdsFunc,
  //
  (byId, getFileById, getJobLogsByIds, getFilesByIds) => (id: number) => {
    // console.log('getJobById()', jobId);
    const jobNorma = byId[id];
    if (jobNorma == null) {
      return getEmptyJob(id);
    }

    const job = {
      ...jobNorma,
      input: getFileById(jobNorma.input) as ParentFile,
      logs: getJobLogsByIds(jobNorma.logs),
      outputs:
        jobNorma.outputs == null ? null : (getFilesByIds(jobNorma.outputs) as Array<VersionFile>),
    };

    return job;
  }
);

//
//
export const getJobById = createSelector(
  getJobByIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getJobById, id) => $getJobById(id)
);

//
//
export const getJobsByIdsFunc = createSelector(
  getJobByIdFunc,
  //
  ($getJobById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id) => $getJobById(id)).filter((j): j is Job => !!j);
  }
);

//
//
export const getJobsByIds = createSelector(
  getJobsByIdsFunc,
  (_: unknown, ids: Array<number>) => ids,
  //
  ($getJobsByIds, ids) => $getJobsByIds(ids)
);

//
//
export const getAllJobsFunc = createSelector(
  getJobsByIdsFunc,
  allIds,
  //
  //
  ($getJobsByIds, ids) => {
    const jobs = $getJobsByIds(ids);
    jobs.sort(sortByIdDesc);
    return jobs;
  }
);

//
//
export const getFilteredJobsInfo = createSelector(
  getAllJobsFunc,
  getPaginationData,
  (_: unknown, options: { itemsPerPageCount: number }) => options,
  //
  (allJobs, pagination, options) => {
    const { itemsPerPageCount } = options;
    const { total, itemsPerPage, currentPage, newPageLoading } = pagination;
    const currentPageItemIds = itemsPerPage == null ? [] : itemsPerPage[currentPage];

    const currentPageItems =
      currentPageItemIds?.map((id) => allJobs.find((j) => j.id === id)).filter(notUndefined) ?? [];

    return {
      max: total,
      maxPage: Math.ceil(total / itemsPerPageCount),
      jobs: currentPageItems,
      itemsPerPageCount,
      newPageLoading,
    };
  }
);
