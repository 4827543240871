import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import PageLayout from '../components/PageLayout';
import PageTitle from '../components/PageTitle';
import { CurrentAppContext } from '../contexts';
import { SITEMAP_FLAT } from '../navigation/sitemap';
import { matchRoute } from '../navigation/utils';
import { getAppByUid } from '../selectors/apps/advanced';
import { isUserSuperadmin } from '../selectors/authSelectors';
import { getPathname } from '../selectors/pageHistory';
import { useGlobalSelector } from '../utils/hooks';

import type { PropsWithChildren } from 'react';

//
//
const PageLayoutContainer = (props: PropsWithChildren<unknown>) => {
  const { children } = props;
  const pathname = useGlobalSelector(getPathname);
  const { matchedRoute } = matchRoute(SITEMAP_FLAT, pathname);
  const isSuperAdmin = useGlobalSelector(isUserSuperadmin);

  const location = useLocation();

  useEffect(() => {
    if (isSuperAdmin === true) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tracking = (window as any)._paq || [];
    tracking.push(['setCustomUrl', window.location.href]);
    tracking.push(['trackPageView']);
  }, [location, isSuperAdmin]);

  const currentApp = useGlobalSelector((state) =>
    getAppByUid(state, matchedRoute?.params?.appUid ?? null)
  );

  const currentAppProviderObj = useMemo(() => ({ currentApp }), [currentApp]);

  console.log('yyy PageLayoutContainer()', { pathname, matchedRoute, currentApp });
  return (
    <CurrentAppContext.Provider value={currentAppProviderObj}>
      <PageTitle />
      <PageLayout>{children}</PageLayout>
    </CurrentAppContext.Provider>
  );
};

export default PageLayoutContainer;
