import {
  MAX_EPISODE_LOCALIZED_DATA_FIELDS,
  APP_CONFIG_FALLBACK_VALUES,
  ValidDataAttributeNames,
  EMPTY_ADDITIONAL_INFO,
} from '../constants/app';

import type {
  AppData,
  Config,
  Configuration,
  ConfigurationGroup,
  ConfigurationItem,
  EpisodeLocalizedDataIndexes,
} from '../types/app';
import type { LocalizedEpisode } from '../types/release';

//
//
export const getValueFromKey = <T extends string | boolean | number | null | undefined>(
  config: Array<Config>,
  key: string,
  fallback: T
): T => {
  const keys = key.split('.');

  if (keys.length > 2) {
    throw new Error('config can only handle keys one and two level deep');
  }

  if (Array.isArray(config) === false) {
    throw new Error('config needs to be array');
  }

  const lookForKey = (arr: Array<Config>, k: string) => arr.find((item) => item.name === k);

  try {
    let arr = config;
    let value;
    let hasPredefinedFallback = false;
    let selectedKeyName = '';
    keys.forEach((k, index) => {
      const item = lookForKey(arr, k);
      if (typeof item === 'undefined') {
        throw new Error(`key not found: ${k}`);
      }
      if (index < keys.length - 1) {
        if (!Array.isArray((item as ConfigurationGroup).children)) {
          throw new Error(`item is missing children`);
        }
        arr = (item as ConfigurationGroup).children;
      } else if (index === keys.length - 1) {
        if (typeof (item as ConfigurationItem).value === 'undefined') {
          throw new Error(`item is missing value`);
        }
        value = (item as ConfigurationItem).value;
        hasPredefinedFallback = (item as ConfigurationItem).fallback ?? false;
        selectedKeyName = item.name;
      }
    });

    if ((hasPredefinedFallback as boolean) === true && (value == null || value === '')) {
      const preDefinedFallback =
        APP_CONFIG_FALLBACK_VALUES[selectedKeyName as keyof typeof APP_CONFIG_FALLBACK_VALUES];

      if (typeof preDefinedFallback !== 'undefined') {
        return preDefinedFallback as T;
      }
      throw new Error(`no predefined fallback found for ${selectedKeyName}`);
    }

    return value as T;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn((err as { message: string }).message, err);
  }

  if (typeof fallback === 'undefined') {
    throw new Error('no value for key found and no fallback defined');
  }
  return fallback;
};

//
//
export const getCustomVariables = (app: AppData, el: LocalizedEpisode) => {
  let { configuration } = app;
  if (!Array.isArray(configuration)) {
    configuration = [];
  }

  const obj: Record<string, LocalizedEpisode[keyof LocalizedEpisode]> = {};

  for (let i = 1; i <= MAX_EPISODE_LOCALIZED_DATA_FIELDS; i++) {
    const dataName = getValueFromKey(configuration, `episodeData.data${i}FeedName`, `data${i}`);
    const dataVisible = getValueFromKey(configuration, `episodeData.data${i}Visible`, false);
    if ((dataVisible as boolean) === true && dataName != null) {
      obj[dataName.toString()] = el[`data${i}` as keyof LocalizedEpisode];
    }
  }

  return obj;
};

//
//
const getItem = (arr: Array<Config>, name: string) => arr.find((item) => item.name === name);

//
//
export const setValueForKey = (
  config: Array<Config>,
  key: string,
  value: string | boolean | null
) => {
  const keys = key.split('.');

  let currentItem;
  let currentItems = config;
  let lastKey: string;

  keys.forEach((k) => {
    if (currentItems == null) {
      throw new Error(`no children found for ${lastKey}`);
    }
    currentItem = getItem(currentItems, k);
    if (currentItem != null) {
      currentItems = (currentItem as ConfigurationGroup).children;
    } else {
      throw new Error(`item not found ${k}`);
    }
    lastKey = k;
  });

  if (currentItem != null) {
    (currentItem as ConfigurationItem).value = value;
  }
};

//
//
export const sortByName = (a: Config, b: Config) => {
  if (a?.name == null || b?.name == null) {
    return 0;
  }

  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

//
//
export const sortByAppName = (a: { app: { name: string } }, b: { app: { name: string } }) => {
  if (a?.app?.name == null || b?.app?.name == null) {
    return 0;
  }

  if (a.app.name.toLowerCase() < b.app.name.toLowerCase()) {
    return -1;
  }
  if (a.app.name.toLowerCase() > b.app.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const isValidEpisodeLocalizedDataAtrtibute = (
  val: unknown
): val is EpisodeLocalizedDataIndexes =>
  typeof val === 'string' && ValidDataAttributeNames.includes(val as EpisodeLocalizedDataIndexes);

export const getDataFieldKeys = () =>
  Array.from(Array(MAX_EPISODE_LOCALIZED_DATA_FIELDS).keys())
    .map((n) => `data${n + 1}`)
    .filter(isValidEpisodeLocalizedDataAtrtibute);

export const getDataFieldNames = (config: Configuration) =>
  getDataFieldKeys().reduce((all, key) => {
    const visible = getValueFromKey<boolean>(config, `episodeData.${key}Visible`, false);
    if (!visible) {
      return all;
    }
    const value = getValueFromKey<string>(config, `episodeData.${key}Label`, key);
    all[key] = value;
    return all;
  }, EMPTY_ADDITIONAL_INFO<string>());
