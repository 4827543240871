import { Checkbox } from 'antd';
import { useState } from 'react';

import { BatchUploadComponentState, BatchUploadModalState } from './constants';
import { currentStep } from './utils';
import MbxButton from '../base/MbxButton';

type Props = Readonly<{
  state: BatchUploadComponentState;
  setState: (state: BatchUploadComponentState) => void;
  setModalState: (state: BatchUploadModalState) => void;
  reset: () => void;
  submitDone: () => void;
  cancel: () => void;
  submitMetadata: () => void;
}>;

const Buttons = ({
  state,
  setState,
  submitMetadata,
  setModalState,
  reset,
  cancel,
  submitDone,
}: Props) => {
  const [acceptedWarnings, setAcceptedWarnings] = useState(false);

  const submitButton = () => {
    let prompt = '';
    let method: () => void | Promise<void> = () => {};
    let disabled = false;
    let visible = true;

    switch (state) {
      case BatchUploadComponentState.LANDING_PAGE: {
        prompt = 'Create spreadsheet template';
        method = () => {
          setState(BatchUploadComponentState.GENERATING_TEMPLATE);
        };
        break;
      }
      case BatchUploadComponentState.GENERATING_TEMPLATE:
      case BatchUploadComponentState.VALID_TEMPLATE: {
        prompt = 'Generate template';
        method = () => {
          setState(BatchUploadComponentState.TEMPLATE_GENERATED);
        };
        disabled = state === BatchUploadComponentState.GENERATING_TEMPLATE;
        break;
      }
      case BatchUploadComponentState.TEMPLATE_GENERATED: {
        prompt = 'Ok, back to episodes';
        method = submitDone;
        break;
      }
      case BatchUploadComponentState.COLLECTING_METADATA: {
        prompt = 'Start Validation';
        disabled = true;
        break;
      }
      case BatchUploadComponentState.METADATA_FOUND: {
        prompt = 'Start Validation';
        method = submitMetadata;
        break;
      }
      case BatchUploadComponentState.VALIDATING: {
        prompt = 'Continue';
        disabled = true;
        break;
      }
      case BatchUploadComponentState.VALIDATION_SUCCESS:
      case BatchUploadComponentState.VALIDATION_WARNINGS: {
        prompt = 'Continue and collect files';
        method = () => {
          setState(BatchUploadComponentState.READY_TO_COLLECT);
        };
        disabled = state === BatchUploadComponentState.VALIDATION_WARNINGS && !acceptedWarnings;
        break;
      }
      case BatchUploadComponentState.VALIDATION_FAILURE: {
        prompt = 'Update sheet and validate again';
        method = reset;
        break;
      }
      case BatchUploadComponentState.READY_TO_COLLECT:
      case BatchUploadComponentState.COLLECTING_FILES: {
        prompt = 'Start batch upload';
        disabled = true;
        break;
      }
      case BatchUploadComponentState.ALL_COLLECTED: {
        prompt = 'Start batch upload';
        method = () => setModalState(BatchUploadModalState.CONFIRM_UPLOAD);
        break;
      }
      case BatchUploadComponentState.QUEUING_ITEMS:
      case BatchUploadComponentState.UPLOADING_CLIENT:
      case BatchUploadComponentState.UPLOAD_DONE_FROM_CLIENT:
      case BatchUploadComponentState.CREATING_FILES: {
        visible = false;
        break;
      }
      case BatchUploadComponentState.UPLOAD_SUCCESS: {
        prompt = 'Close and back to episodes';
        method = submitDone;
        break;
      }
      case BatchUploadComponentState.UPLOAD_MISSING_FILES: {
        prompt = 'Upload';
        disabled = true;
        break;
      }
    }
    return visible === true ? (
      <MbxButton mbxType="primary" disabled={disabled} onClick={method} data-tid="bu-submit-btn">
        {prompt}
      </MbxButton>
    ) : null;
  };

  const secondaryButton = () => {
    if (
      [
        BatchUploadComponentState.TEMPLATE_GENERATED,
        BatchUploadComponentState.UPLOAD_SUCCESS,
      ].includes(state)
    ) {
      return;
    }

    if (state === BatchUploadComponentState.LANDING_PAGE) {
      return (
        <MbxButton
          key="bu-secondary-btn-1"
          mbxType="secondary"
          onClick={() => setState(BatchUploadComponentState.COLLECTING_METADATA)}
          data-tid="bu-secondary-btn"
          style={{ marginLeft: 10 }}
        >
          Continue with exisiting spreadsheet
        </MbxButton>
      );
    }

    return (
      <MbxButton
        key="bu-secondary-btn-2"
        mbxType="tertiary"
        onClick={() => {
          if (currentStep(state) < 3) {
            cancel();
            return;
          }
          setModalState(BatchUploadModalState.CONFIRM_CANCEL);
        }}
        data-tid="bu-secondary-btn"
        style={{ marginLeft: 10 }}
      >
        Cancel
      </MbxButton>
    );
  };

  return (
    <div style={{ marginTop: '2em' }}>
      {state === BatchUploadComponentState.VALIDATION_WARNINGS && (
        <Checkbox
          checked={acceptedWarnings}
          onChange={() => setAcceptedWarnings(!acceptedWarnings)}
        >
          I have seen the warnings but would like to continue
        </Checkbox>
      )}
      <div style={{ marginTop: 10 }}>
        {submitButton()}
        {secondaryButton()}
      </div>
    </div>
  );
};

export default Buttons;
