import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { List } from 'antd';

import MbxButton from './base/MbxButton';
import RoleRightTags from './RoleRightTags';
import { RoleType } from '../common/constants/role-type';
import { getAppNameById } from '../selectors/apps/advanced';
import { useGlobalSelector } from '../utils/hooks';

import type { Role } from '../reducers/RoleReducer';

type Props = {
  role: Role;
  displayAppName?: boolean;
  onEdit?: (role: Role) => void;
  onDelete?: (role: Role) => void;
  onRemove?: (role: Role) => void;
};

//
//
const RoleListItem = (props: Props) => {
  const { role, onEdit, onDelete, onRemove, displayAppName = false } = props;
  const actions = [];

  const appName = useGlobalSelector((state) => getAppNameById(state, role.appId));

  if (role.roleTypeId === RoleType.NORMAL) {
    if (typeof onEdit === 'function') {
      actions.push(
        <MbxButton
          data-tid="btn-role-edit"
          size="small"
          icon={<EditOutlined />}
          onClick={() => onEdit(role)}
        />
      );
    }
    if (typeof onDelete === 'function') {
      actions.push(
        <MbxButton
          data-tid="btn-role-delete"
          size="small"
          icon={<DeleteOutlined />}
          danger
          onClick={() => onDelete(role)}
        />
      );
    }
  }

  if (typeof onRemove === 'function') {
    actions.push(
      <MbxButton
        data-tid="btn-role-remove"
        size="small"
        icon={<DeleteOutlined />}
        danger
        onClick={() => onRemove(role)}
      />
    );
  }

  let desc: string | JSX.Element = '';

  switch (role.roleTypeId) {
    case RoleType.ADMIN:
      desc = 'all rights';
      break;
    case RoleType.READ_ONLY:
      desc = 'minimum rights you need to view the admin interface';
      break;
    default:
      desc = <RoleRightTags role={role} />;
  }

  return (
    <List.Item key={role.id} actions={actions}>
      <List.Item.Meta
        title={`${displayAppName ? `[${appName}] ` : ''}${role.name}`}
        description={desc}
      />
    </List.Item>
  );
};

export default RoleListItem;
