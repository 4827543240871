import AssetEditForm from '../components/AssetEditForm';
import CustomPageHeader from '../components/CustomPageHeader';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_ASSETS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

type Props = {
  isSaving: boolean;
  onSubmit: (options: { name: string; isDraft: boolean }) => Promise<void>;
};

//
//
const AssetCreate = (props: Props) => {
  const { onSubmit, isSaving } = props;
  const { currentApp } = useCurrentAppContext();
  const backLink = getUrlByName(ROUTE_NAME_APP_ASSETS, {
    ':appUid': currentApp.uid,
  });

  return (
    <div>
      <CustomPageHeader title="Create new asset patch" backLink={backLink} />

      <div style={{ marginTop: '2em' }}>
        <AssetEditForm onSubmit={onSubmit} isSaving={isSaving} backLink={backLink} />
      </div>
    </div>
  );
};

export default AssetCreate;
