import { combineReducers } from 'redux';

import { ASSETRESOURCE_CREATE_OK, ASSETRESOURCE_DELETE_OK } from '../constants/assetresources';
import {
  ASSET_GET_LIST_OK,
  ASSET_GET_METADATA_OK,
  ASSET_GET_DETAILS_OK,
  ASSET_UPDATE_OK,
  ASSET_BULK_UPDATE_OK,
  ASSET_REMOVE_OK,
  ASSET_CREATE_OK,
} from '../constants/assets';

import type { AssetResource } from './AssetResourceReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type AssetBase = {
  id: number;
  appId: number;
  name: string;
  ordinal: number;
  isActive: boolean;
  isDraft: boolean;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date | null;
};

export type AssetNormalized = Merge<
  AssetBase,
  {
    resources: Array<number>;
  }
>;

export type Asset = Merge<
  AssetBase,
  {
    resources: Array<AssetResource>;
  }
>;

type AssetStateById = { [k: string | number]: AssetNormalized };

export type AssetState = {
  byId: AssetStateById;
};

//
//
export const getEmptyAsset = (id?: number): Asset => ({
  id: id ?? -1,
  appId: -1,
  name: '',
  ordinal: 0,
  isActive: false,
  isDraft: false,
  createdAt: new Date(),
  createdBy: -1,
  updatedAt: null,
  resources: [],
});

const initialStateById: AssetStateById = {};

//
//
const byId = (state = initialStateById, action: AnyAction): AssetStateById => {
  switch (action.type) {
    case ASSET_GET_LIST_OK:
    case ASSET_GET_METADATA_OK:
    case ASSET_GET_DETAILS_OK:
    case ASSET_UPDATE_OK:
    case ASSET_CREATE_OK: {
      const newState = { ...state };
      const assets = action?.payload?.entities?.assets ?? {};
      Object.keys(assets).forEach((assetId) => {
        newState[assetId] = {
          ...state[assetId],
          ...assets[assetId],
        };
      });
      return newState;
    }

    case ASSET_REMOVE_OK: {
      const { [action.assetId]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case ASSET_BULK_UPDATE_OK: {
      const assets = action?.payload?.entities?.assets ?? {};
      const result = action?.payload?.result ?? [];
      const newState = { ...state };
      result.forEach((asId: number) => {
        const as = assets[asId];
        if (newState[asId] != null) {
          newState[asId] = {
            ...newState[asId],
            ordinal: as.ordinal,
            updatedAt: as.updatedAt,
          };
        }
      });
      return newState;
    }

    case ASSETRESOURCE_CREATE_OK: {
      const { assetId, payload } = action;
      const oldData = state[assetId];
      const oldResources = oldData?.resources ?? [];
      const newResources = [payload.result, ...oldResources];

      return {
        ...state,
        [assetId]: {
          ...oldData,
          resources: newResources,
        },
      };
    }

    case ASSETRESOURCE_DELETE_OK: {
      const { assetResourceId } = action;
      const assetIdIncludingResource = Object.keys(state).find((key) => {
        const asset = state[key];
        return Array.isArray(asset.resources) ? asset.resources.includes(assetResourceId) : false;
      });

      if (assetIdIncludingResource == null) {
        return state;
      }

      const oldData = state[assetIdIncludingResource];

      return {
        ...state,
        [assetIdIncludingResource]: {
          ...oldData,
          resources: Array.isArray(oldData.resources)
            ? oldData.resources.filter((arId) => arId !== assetResourceId)
            : [],
        },
      };
    }

    default:
      return state;
  }
};

const reducers = combineReducers({
  byId,
});

export default reducers;
