import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

// import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  UI_COMMIT_HASH,
  CHECK_API_VERSION_SETUP,
  CHECK_API_VERSION,
  CHECK_API_VERSION_FAIL,
  CHECK_API_VERSION_OK,
  RELOAD_UI,
  RELOAD_UI_ASK,
  RELOAD_UI_ASK_CLOSE,
  RELOAD_UI_ASK_RESET,
} from '../constants/appversion';
import {
  getApiCommitHash,
  getLastTimeChecked,
  getIsModalOpen,
  getLastTimeAsked,
} from '../selectors/adminUiVersionSelectors';
import { wrapFetch } from '../utils/api';
import { logUnknownError } from '../utils/log';

import type { AppDispatch } from '../configureStore';
import type { GlobalStateGetter } from '../reducers';

const { confirm } = Modal;

let checkTimer: ReturnType<typeof setInterval> | null = null;
let listener: (() => void) | null = null;

const onUnload = (dispatch: AppDispatch) => {
  if (listener == null) {
    listener = () => {
      dispatch(teardownVersionCheck()); // eslint-disable-line @typescript-eslint/no-use-before-define
    };
  }
  return listener;
};

//
//
// const setupVersionCheck = createAsyncThunk(CHECK_API_VERSION_SETUP, async (arg, thunk) => {
//   console.log('setupVersionCheck()');
//   if (checkTimer != null) {
//     clearInterval(checkTimer);
//   }
//   checkTimer = setInterval(() => thunk.dispatch(checkApiVersion()), 120000);
//   // dispatch({ type: CHECK_API_VERSION_SETUP });
//   dispatch(checkApiVersion());

//   window.addEventListener('beforeunload', onUnload(dispatch));
// });

//
//
export const setupVersionCheck = () => (dispatch: AppDispatch) => {
  console.log('setupVersionCheck()');
  if (checkTimer != null) {
    clearInterval(checkTimer);
  }
  checkTimer = setInterval(() => dispatch(checkApiVersion()), 120000); // eslint-disable-line @typescript-eslint/no-use-before-define
  dispatch({ type: CHECK_API_VERSION_SETUP });
  dispatch(checkApiVersion()); // eslint-disable-line @typescript-eslint/no-use-before-define

  window.addEventListener('beforeunload', onUnload(dispatch));
};

//
//
export const teardownVersionCheck = () => (dispatch: AppDispatch, getState: GlobalStateGetter) => {
  console.log('teardownVersionCheck()');
  window.removeEventListener('beforeunload', onUnload(dispatch));
  if (checkTimer != null) {
    clearInterval(checkTimer);
  }

  const state = getState();
  const isModalOpen = getIsModalOpen(state);
  if (isModalOpen) {
    dispatch({ type: RELOAD_UI_ASK_RESET });
  }
};

//
//
export const checkApiVersion = () => async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
  const now = new Date();
  const state = getState();

  const isModalOpen = getIsModalOpen(state);
  const lastTimeChecked = getLastTimeChecked(state);
  const timeSinceLastCheck =
    lastTimeChecked === null
      ? Number.POSITIVE_INFINITY
      : (now.getTime() - lastTimeChecked.getTime()) / 1000;

  console.log('checkApiVersion()', timeSinceLastCheck, isModalOpen);

  if (isModalOpen) {
    return;
  }

  const storedApiCommitHash = getApiCommitHash(state);

  if (
    storedApiCommitHash !== null &&
    UI_COMMIT_HASH !== 'unknown' &&
    storedApiCommitHash !== 'unknown' &&
    UI_COMMIT_HASH !== storedApiCommitHash &&
    timeSinceLastCheck < 60
  ) {
    dispatch(askForReload()); // eslint-disable-line @typescript-eslint/no-use-before-define
    return;
  }

  try {
    const response = await wrapFetch<{ commit: string }>(
      {
        url: `/info`,
      },
      dispatch,
      { init: CHECK_API_VERSION, fail: CHECK_API_VERSION_FAIL }
    );

    const apiCommitHash = response.data?.commit ?? 'unknown';

    console.log('checkApiVersion()', apiCommitHash, UI_COMMIT_HASH);
    dispatch({ type: CHECK_API_VERSION_OK, apiCommitHash });

    if (
      UI_COMMIT_HASH !== 'unknown' &&
      apiCommitHash !== 'unknown' &&
      apiCommitHash !== UI_COMMIT_HASH
    ) {
      dispatch(askForReload()); // eslint-disable-line @typescript-eslint/no-use-before-define
    }
  } catch (err) {
    logUnknownError(err);
  }
};

//
//
export const askForReload = () => (dispatch: AppDispatch, getState: GlobalStateGetter) => {
  const now = new Date();
  const state = getState();

  const isModalOpen = getIsModalOpen(state);
  const lastTimeAsked = getLastTimeAsked(state);
  const timeSinceLastAsk =
    lastTimeAsked === null
      ? Number.POSITIVE_INFINITY
      : (now.getTime() - lastTimeAsked.getTime()) / 1000;

  console.log('askForReload()', timeSinceLastAsk, isModalOpen);

  // only ask once every 10 minutes
  if (timeSinceLastAsk < 600 || isModalOpen) {
    return;
  }

  dispatch({ type: RELOAD_UI_ASK });
  confirm({
    icon: <InfoCircleOutlined />,
    title: 'We updated the Admin-Interface',
    content:
      'Seems like we did some work in the background and you are not using the most up-to-date version of the admin-interface. Please reload the page after you finished everything important.',
    okText: 'Reload now',
    cancelText: 'Reload later',
    onOk() {
      dispatch({ type: RELOAD_UI });
      // @ts-expect-error reload only has an attribute in some browsers!?
      window.location.reload(true);
    },
    onCancel() {
      dispatch({ type: RELOAD_UI_ASK_CLOSE });
    },
  });
};
