import { createSelector } from 'reselect';

import { getEmptyRegion } from '../../reducers/RegionReducer';
import { getRegionCountriesForIdFunc } from '../regionCountrySelectors';
import { getRegionLocalesForIdFunc } from '../regionLocales/advanced';
import { getTimezoneByIdFunc } from '../timezoneSelectors';

import { getRegionNormalizedByIdFunc, getAllRegionsNormalizedForAppFunc } from './index';

import type { RootState } from '../../reducers';
import type { RegionNormalized, Region } from '../../reducers/RegionReducer';
import type { Selector } from 'reselect';

//
//
const denormalizeRegionFunc = createSelector(
  getRegionCountriesForIdFunc,
  getRegionLocalesForIdFunc,
  getTimezoneByIdFunc,
  //
  ($getRegionCountriesForId, $getRegionLocalesForId, $getTimezoneById) =>
    (regions: Array<RegionNormalized>) =>
      regions.map((region) => ({
        ...region,
        regionCountries: $getRegionCountriesForId(region.id),
        regionLocales: $getRegionLocalesForId(region.id),
        releaseTimezone: $getTimezoneById(region.releaseTimezone),
      }))
);

//
//
export const getRegionByIdFunc: Selector<RootState, (regionId: number) => Region> = createSelector(
  getRegionNormalizedByIdFunc,
  denormalizeRegionFunc,
  //
  ($getRegionNormalizedById, $denormalizeRegion) => (regionId) => {
    const region = $getRegionNormalizedById(regionId);
    if (region == null) {
      return getEmptyRegion(regionId);
    }

    return $denormalizeRegion([region])[0];
  }
);
//
//
export const getRegionById = createSelector(
  getRegionByIdFunc,
  (_: unknown, regionId: number) => regionId,
  //
  ($getRegionById, regionId) => $getRegionById(regionId)
);

//
//
export const getAllRegionsForAppFunc = createSelector(
  getAllRegionsNormalizedForAppFunc,
  denormalizeRegionFunc,
  //
  ($getAllRegionsNormalizedForApp, $denormalizeRegion) => (appId: number) => {
    const regions = $getAllRegionsNormalizedForApp(appId);
    return $denormalizeRegion(regions);
  }
);
//
//
export const getAllRegionsForApp = createSelector(
  getAllRegionsForAppFunc,
  (_: unknown, appId: number) => appId,
  //
  ($getAllRegionsForApp, appId) => $getAllRegionsForApp(appId)
);
