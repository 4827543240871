import { createSelector } from 'reselect';

import { getEmptyLocale } from '../reducers/LocaleReducer';

import type { RootState } from '../reducers';

//
//
const getById = (state: RootState) => state.locales;

//
//
export const getLocaleByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => {
    let locale = byId[id];
    if (locale == null) {
      // throw new Error('unknown locale');
      locale = getEmptyLocale(id);
    }
    return locale;
  }
);

//
//
export const getLocalesByIdsFunc = createSelector(
  getLocaleByIdFunc,
  //
  (getLocaleById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id) => getLocaleById(id));
  }
);

//
//
export const getAllLocales = createSelector(
  getById,
  getLocaleByIdFunc,
  //
  (byId, getLocaleById) => Object.keys(byId).map((key: string) => getLocaleById(Number(key)))
);
