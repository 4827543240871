import { createSelector } from 'reselect';

import { getAssetResourceVersionRequirementsByIdsFunc } from './assetResourceVersionRequirementsSelectors';
import { getResourceByIdFunc } from './resourceSelectors';
import { getEmptyAssetResource } from '../reducers/AssetResourceReducer';

import type { RootState } from '../reducers';
import type { AssetResource, AssetResourceStateById } from '../reducers/AssetResourceReducer';
import type { Selector } from 'reselect';

//
//
const getById: Selector<RootState, AssetResourceStateById> = (state) => state.assetResources.byId;

//
//
export const getAssetResourceByIdFunc: Selector<RootState, (id: number) => AssetResource | null> =
  createSelector(
    getById,
    getResourceByIdFunc,
    getAssetResourceVersionRequirementsByIdsFunc,
    (byId, $getResourceById, $getAssetResourceVersionRequirementsByIds) => (id) => {
      if (id === null) {
        return null;
      }

      const assetResource = byId[id];
      if (assetResource == null) {
        return getEmptyAssetResource(id);
      }

      return {
        ...assetResource,
        resource: $getResourceById(assetResource.resource),
        requirements: $getAssetResourceVersionRequirementsByIds(assetResource.requirements),
      };
    }
  );

//
//
export const getAssetResourceById = createSelector(
  getAssetResourceByIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getAssetResourceById, id) => $getAssetResourceById(id)
);

//
//
export const getAssetsResourcesByIdsFunc = createSelector(
  getAssetResourceByIdFunc,
  //
  ($getAssetResourceById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id) => $getAssetResourceById(id)).filter((ar): ar is AssetResource => !!ar);
  }
);

//
//
export const getAssetsResourcesByIds = createSelector(
  getAssetsResourcesByIdsFunc,
  (_: unknown, ids: Array<number>) => ids,
  //
  ($getAssetsResourcesByIds, ids) =>
    $getAssetsResourcesByIds(ids).filter((a): a is AssetResource => !!a)
);
