import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Form, Input, Upload, Divider } from 'antd';
import * as React from 'react';
import { useEffect } from 'react';

import AssetContentPreview from './AssetContentPreview';
import AssetResourceContentTree from './AssetResourceContentTree';
import MbxButton from './base/MbxButton';
import ItemVersionRequirementsList from './ItemVersionRequirementsList';

import type { App } from '../reducers/AppsReducer';
import type { Asset } from '../reducers/AssetReducer';
import type { AssetResource } from '../reducers/AssetResourceReducer';

type Props = Readonly<{
  data: Asset;
  currentApp: App;
  onFileSelected: (file: File, assetId: number) => Promise<void>;
  onResourceDelete: (assetId: number, assetResourceId: number) => Promise<void>;
}>;

//
//
const AssetResourceList = (props: Props) => {
  const { data, onFileSelected, onResourceDelete, currentApp } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const handleFileSelect = (file: File) => {
    console.log('handleFileSelect()', file);
    onFileSelected(file, data.id);
    return false;
  };

  const handleResourceDelete = (assetResource: AssetResource) => {
    if (
      window.confirm(
        `Really delete asset patch resource '${assetResource.resource.file?.originalName ?? '??'}'?`
      )
    ) {
      onResourceDelete(data.id, assetResource.id);
    }
  };

  return (
    <div>
      {data != null && (
        <div style={{ marginTop: '5em' }}>
          <h3>You can upload a zip file including assets that should be overwritten in the app</h3>

          <Form layout="vertical" form={form}>
            <Form.Item label="add a new resource" style={{ border: '1px solid gray', padding: 8 }}>
              <div style={{ display: 'flex' }}>
                <Upload
                  accept="application/zip,application/x-zip-compressed"
                  multiple={false}
                  showUploadList={false}
                  beforeUpload={(file) => handleFileSelect(file)}
                  style={{ marginRight: 10 }}
                >
                  <MbxButton icon={<UploadOutlined />}>select file</MbxButton>
                </Upload>
              </div>
            </Form.Item>
            <Divider />
            {data.resources.map((ar) => {
              const isAssetActive = data?.isActive ?? false;
              const canItemBeDeleted =
                ar != null && !(isAssetActive && data.resources.length === 1);

              return (
                <Form.Item
                  key={`form-item-resource-upload-${ar.id}`}
                  style={{ border: '1px solid gray', padding: 8 }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 12,
                    }}
                  >
                    <AssetContentPreview
                      assetId={ar.id}
                      resource={ar.resource}
                      width={150}
                      height={32}
                    />
                    <div style={{ flexGrow: 1 }}>
                      <Input disabled value={ar?.resource?.file?.originalName ?? ''} />
                    </div>
                    <MbxButton
                      icon={<DeleteOutlined />}
                      danger
                      disabled={!canItemBeDeleted}
                      onClick={() => {
                        handleResourceDelete(ar);
                      }}
                    >
                      delete
                    </MbxButton>
                  </div>
                  <AssetResourceContentTree data={ar} />
                  <ItemVersionRequirementsList item={ar} app={currentApp} />
                </Form.Item>
              );
            })}
          </Form>
        </div>
      )}
    </div>
  );
};

export default AssetResourceList;
