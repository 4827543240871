import { Form, Input, Checkbox, Switch } from 'antd';

import styles from './UserInvite.module.scss';
import { RoleType } from '../common/constants/role-type';
import BackButton from '../components/BackButton';
import MbxButton from '../components/base/MbxButton';
import RoleRightTags from '../components/RoleRightTags';
import { ROUTE_NAME_APP_SETTINGS, ROUTE_NAME_INTERNAL_USERS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { isUserSuperadmin } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { App } from '../reducers/AppsReducer';
import type { Role } from '../reducers/RoleReducer';

type Props = {
  roles: Array<Role>;
  app: App | null;
  inviteUser: (values: UserInviteFormValues) => Promise<void>;
  isSaving: boolean;
};

export type UserInviteFormValues = {
  email: string;
  roleIds: Array<number>;
  hidden: boolean;
};

//
//
const UserInvite = (props: Props) => {
  const { app, roles, inviteUser, isSaving } = props;

  const isSuperadmin = useGlobalSelector(isUserSuperadmin);

  const handleSubmit = async (values: UserInviteFormValues) => {
    console.log('Received values of form: ', values);
    try {
      await inviteUser(values);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h1>{`Invite user to ${app?.name ?? 'Global'}`}</h1>

      <div>
        <Form onFinish={handleSubmit} name="userInviteForm" layout="vertical">
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input email!' }]}
          >
            <Input type="email" disabled={isSaving} data-tid="invite-user-input" />
          </Form.Item>
          <Form.Item label="Roles" name="roleIds" initialValue={[]}>
            <Checkbox.Group disabled={isSaving} className={styles.rolesContainer}>
              {roles.map((role) => (
                <div key={`invite-checkbox-role-${role.id}`} className={styles.rolesItem}>
                  <Checkbox value={role.id} style={{ fontWeight: 500 }}>
                    {role.name}
                  </Checkbox>
                  <div>
                    {role.roleTypeId === RoleType.ADMIN && (
                      <span style={{ color: '#00000073' }}>all rights</span>
                    )}
                    {role.roleTypeId === RoleType.READ_ONLY && (
                      <span style={{ color: '#00000073' }}>
                        minimum rights you need to view the admin interface
                      </span>
                    )}
                    {role.roleTypeId === RoleType.NORMAL && <RoleRightTags role={role} />}
                  </div>
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
          {isSuperadmin === true && (
            <Form.Item label="Hidden" name="hidden" initialValue={false}>
              <Switch data-tid="invite-user-hidden-switch" disabled={isSaving} />
            </Form.Item>
          )}
          <Form.Item style={{ marginTop: '3em' }}>
            <MbxButton
              data-tid="button-submit-invite"
              loading={isSaving}
              mbxType="primary"
              htmlType="submit"
            >
              Send out invite
            </MbxButton>
            <BackButton
              linkTo={
                app == null
                  ? getUrlByName(ROUTE_NAME_INTERNAL_USERS)
                  : getUrlByName(ROUTE_NAME_APP_SETTINGS, {
                      ':appUid': app.uid,
                    })
              }
              style={{ marginLeft: '10px' }}
              size="large"
            >
              Cancel
            </BackButton>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UserInvite;
