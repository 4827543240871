import { Alert, message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getCategoryList } from '../actions/categories';
import { getEpisodeDetails } from '../actions/episodes';
import { getRegionList } from '../actions/regions';
import { getReleaseDetails, updateRelease, deleteRelease } from '../actions/releases';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { getTimezoneList } from '../actions/timezones';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_EPISODEDETAIL, ROUTE_NAME_APP_RELEASES } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import ReleaseDetail from '../pages/ReleaseDetail';
import { getAllCategoriesForApp } from '../selectors/categorySelectors';
import { getEpisodeById } from '../selectors/episodeSelectors';
import { getPreviousPages } from '../selectors/pageHistory';
import { getRegionById } from '../selectors/regions/advanced';
import { getReleaseById, getAllReleasedEpisodeIdsPerRegion } from '../selectors/releaseSelectors';
import { getReleaseId } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { ReleaseFormSubmitValues } from '../components/ReleaseEditForm';
import type { Episode } from '../reducers/EpisodeReducer';
import type { Release } from '../reducers/ReleaseReducer';

//
//
const ReleaseDetailContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentApp } = useCurrentAppContext();

  const params = useParams();
  const releaseId = getReleaseId(params);

  const [error, setError] = useState('');

  const currentRelease = useGlobalSelector((state) => getReleaseById(state, releaseId));
  // const regions = useGlobalSelector((state) => getAllRegionsForApp(state, currentApp.id));
  const releasedEpisodeIdsPerRegion = useGlobalSelector(getAllReleasedEpisodeIdsPerRegion);
  const categories = useGlobalSelector((state) => getAllCategoriesForApp(state, currentApp.id));
  // const episodes = useGlobalSelector((state) => getAllEpisodesForApp(state, currentApp.id));
  const releaseEpisode = useGlobalSelector((state) =>
    getEpisodeById(state, currentRelease.episode.id)
  );
  const releaseRegion = useGlobalSelector((state) =>
    getRegionById(state, currentRelease.region.id)
  );

  const previousPages = useGlobalSelector(getPreviousPages);
  let linkForAfterSave = getUrlByName(ROUTE_NAME_APP_RELEASES, {
    ':appUid': currentApp.uid,
  });
  if (previousPages.length > 1) {
    const prev = previousPages[previousPages.length - 2];
    const [path] = prev.path.split('?');
    if (path === linkForAfterSave) {
      linkForAfterSave = prev.path;
    }
  }

  console.log('ReleaseDetailContainer()', { currentRelease, releaseEpisode, releaseRegion });

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        const release = await dispatch(getReleaseDetails(currentApp.id, currentRelease.id));
        await dispatch(getEpisodeDetails(currentApp.id, release.episode.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentApp.id, currentRelease.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getRegionList(currentApp.id));
        await dispatch(getCategoryList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentApp.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getTimezoneList());
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch]);

  const handleReleaseUpdate = async (values: ReleaseFormSubmitValues) => {
    try {
      console.log('handleReleaseUpdate()', values);
      await dispatch(
        updateRelease(
          currentRelease.id,
          values.isDemoContent,
          values.isDraft,
          values.publishDate,
          values.unpublishDate,
          values.categories
        )
      );

      history.push(linkForAfterSave);
      message.success('saved');
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleOpenEpisode = (episode: Episode) => {
    history.push(
      getUrlByName(ROUTE_NAME_APP_EPISODEDETAIL, {
        ':appUid': currentApp.uid,
        ':episodeId': episode.id,
      })
    );
  };

  const handleReleaseDelete = async (release: Release) => {
    try {
      await dispatch(deleteRelease(release));
      message.success('Release deleted.');
      history.replace(linkForAfterSave);
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <ReleaseDetail
      currentRelease={currentRelease}
      /// TODO: check if/how I can use these without throwing errors in ReleaseEditForm
      appRegions={[releaseRegion]}
      appEpisodes={[releaseEpisode]}
      appCategories={categories}
      releasedEpisodeIdsPerRegion={releasedEpisodeIdsPerRegion}
      selectedRegionId={currentRelease.region.id}
      //
      updateRelease={handleReleaseUpdate}
      deleteRelease={handleReleaseDelete}
      openEpisode={handleOpenEpisode}
    />
  );
};

export default ReleaseDetailContainer;
