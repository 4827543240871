import { EditOutlined, CaretDownOutlined } from '@ant-design/icons';
import { List, Tag } from 'antd';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import MbxButton from './base/MbxButton';

import type { Asset } from '../reducers/AssetReducer';
import type { DraggingStyle, DropResult, NotDraggingStyle } from 'react-beautiful-dnd';

type Props = {
  dataActive: Array<Asset>;
  handleOpenDetails: (assetId: number) => void;
  handleToggleActive: (asset: Asset) => Promise<void>;
  handleUpdateActiveSort: (assets: Array<{ id: number; ordinal: number }>) => Promise<void>;
};

const grid = 8;

const sortPatches = (patches: Array<Asset>) => {
  const p = patches?.slice(0) ?? [];
  p.sort((a, b) => b.ordinal - a.ordinal);
  return p;
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: `0 ${grid * 2}px`,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? '#f0f0f0' : '#fcfcfc',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#f3f3f3' : '#fff',
  // padding: grid,
  border: '1px solid #f0f0f0',
  marginTop: '2em',
  marginBottom: '5em',
  // width: 250,
});

//
//
const ActiveAssetPatchList = (props: Props) => {
  const { dataActive, handleOpenDetails, handleToggleActive, handleUpdateActiveSort } = props;
  const [sortedPatches, setSortedPatches] = useState(() => sortPatches(dataActive));

  useEffect(() => {
    setSortedPatches(() => sortPatches(dataActive));
  }, [dataActive]);

  const handleDragEnd = async (info: DropResult) => {
    console.log('handleDragEnd()', info);

    if (info.destination == null || info.destination.index === info.source.index) {
      return;
    }

    const newOrder = sortedPatches.slice(0);
    console.log(
      'old',
      newOrder.map((p) => p.id)
    );

    const [item] = newOrder.splice(info.source.index, 1);
    newOrder.splice(info.destination.index, 0, item);
    console.log(
      'new',
      newOrder.map((p) => p.id)
    );
    setSortedPatches(newOrder);

    await handleUpdateActiveSort(
      newOrder.map((p, index, arr) => ({ id: p.id, ordinal: arr.length - 1 - index }))
    );
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="sortCategoryList">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            <p style={{ padding: grid, margin: 0, fontWeight: 'bold' }}>All active asset patches</p>
            <div style={{ padding: grid }}>
              <List
                data-tid="list-active-assets"
                bordered={false}
                dataSource={sortedPatches}
                renderItem={(asset: Asset, index: number) => {
                  const actions = [
                    <MbxButton
                      data-tid="btn-toggle-activation-asset"
                      key="btn-toggle-activation-asset"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleActive(asset);
                      }}
                    >
                      {asset.isActive === true ? 'deactivate' : 'activate'}
                    </MbxButton>,
                    <MbxButton
                      data-tid="btn-edit-asset"
                      key="btn-edit-asset"
                      size="small"
                      icon={<EditOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDetails(asset.id);
                      }}
                    />,
                  ];

                  return (
                    <Draggable
                      key={asset.id.toString()}
                      draggableId={asset.id.toString()}
                      index={index}
                    >
                      {(provided1, snapshot1) => (
                        <div
                          ref={provided1.innerRef}
                          {...provided1.draggableProps}
                          {...provided1.dragHandleProps}
                          style={getItemStyle(snapshot1.isDragging, provided1.draggableProps.style)}
                        >
                          <List.Item key={asset.id} actions={actions}>
                            <List.Item.Meta
                              title={
                                <>
                                  <span style={{ paddingRight: '1em' }}>{asset.name}</span>
                                  {asset.isDraft === true && <Tag color="orange">draft</Tag>}
                                </>
                              }
                            />
                          </List.Item>
                          <div>
                            <small>
                              overrides assets from <CaretDownOutlined />
                            </small>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                }}
              />
              {provided.placeholder}
              <div style={{ padding: 12, backgroundColor: '#f0f0f0' }}>
                base asset patch (always active)
              </div>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

ActiveAssetPatchList.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'ActiveAssetPatchList',
};

export default ActiveAssetPatchList;
