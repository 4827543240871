import { createSelector } from 'reselect';

import type { RootState } from '../../reducers';

//
//
export const regionLocalesById = (state: RootState) => state.regionLocales.byId;
export const regionLocalesAllIds = (state: RootState) => state.regionLocales.allIds;

//
//
export const getRegionLocaleNormalizedByIdFunc = createSelector(
  regionLocalesById,
  //
  (byId) => (regionLocaleId: number) => {
    const regionLocale = byId[regionLocaleId];
    if (regionLocale == null) {
      return null;
    }
    return regionLocale;
  }
);

// XXX: do we need this? base selectors should maybe only called form other advanced selectors!?
//
// export const getRegionLocaleNormalizedById: (
//   GlobalState,
//   number
// ) => RegionLocaleNormalized | null = createSelector(
//   getRegionLocaleNormalizedByIdFunc,
//   (_, regionLocaleId) => regionLocaleId,
//   //
//   ($getRegionLocaleNormalizedById, regionLocaleId) => $getRegionLocaleNormalizedById(regionLocaleId)
// );

//
//
export const getRegionLocalesIdsForRegionFunc = createSelector(
  regionLocalesAllIds,
  //
  (allIds) => (regionId: number) => {
    const regionLocales = allIds[regionId];
    if (regionLocales == null) {
      return [];
    }
    return regionLocales;
  }
);
