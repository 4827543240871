import { useEffect, useCallback } from 'react';

//
//
const GlobalDragDropDisable = () => {
  const eventListener = useCallback((event: DragEvent) => {
    if (event?.target instanceof Element) {
      const check = event.target.closest('[data-dragdropenabled="true"]');
      if (check == null) {
        event.preventDefault();
        if (event.dataTransfer != null) {
          event.dataTransfer.effectAllowed = 'none';
          event.dataTransfer.dropEffect = 'none';
        }
      } else {
        if (event.dataTransfer != null) {
          event.dataTransfer.effectAllowed = 'copy';
          event.dataTransfer.dropEffect = 'copy';
        }
      }
    }
  }, []);

  useEffect(() => {
    console.log('add GlobalDragDropDisable functionality');
    window.addEventListener('dragover', eventListener, false);
    window.addEventListener('drop', eventListener, false);

    return () => {
      console.log('remove GlobalDragDropDisable functionality');
      window.removeEventListener('dragover', eventListener);
      window.removeEventListener('drop', eventListener);
    };
  }, [eventListener]);

  return <div />;
};

export default GlobalDragDropDisable;
