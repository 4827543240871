import type { AssetResource } from '../reducers/AssetResourceReducer';

export type ContentTreeItem = {
  key: string;
  title: string;
  children?: Array<ContentTreeItem>;
  isLeaf?: boolean;
};

const getByName = (parent: Array<ContentTreeItem> | undefined | null, name: string) => {
  if (parent == null) {
    throw new Error('getByName() parent not set');
  }
  const found = parent.find((cti: ContentTreeItem) => cti.title === name);
  if (found != null) {
    return found;
  }
  return null;
};

const getParent = (
  currentItems: Array<ContentTreeItem>,
  names: Array<string>
): ContentTreeItem | null => {
  let currentParent: ContentTreeItem | null = null;
  let currentKey = '';

  names.forEach((name) => {
    const found = getByName(currentParent == null ? currentItems : currentParent.children, name);
    // console.log('look for name', name, found);
    if (found != null) {
      currentParent = found;
      currentKey = currentParent.key;
      return;
    }

    const newFolder: ContentTreeItem = { title: name, key: '', children: [] };
    if (currentParent == null) {
      currentKey += `${currentItems.length}`;
      newFolder.key = currentKey;
      currentItems.push(newFolder);
    } else if (Array.isArray(currentParent.children)) {
      currentKey = `${currentKey}-${currentParent.children.length}`;
      newFolder.key = currentKey;
      currentParent.children.push(newFolder);
    }
    currentParent = newFolder;
  });

  return currentParent;
};

//
//
export const getContentFrom = (ar: AssetResource) => {
  const items: Array<ContentTreeItem> = [];

  const versions = ar.resource?.file?.versions ?? [];
  if (versions.length > 0) {
    const [version] = versions;
    const contents = version?.meta?.zipContents;
    if (contents == null || !Array.isArray(contents)) {
      // no content info found, just add one dummy item and return
      items.push({ title: 'Content info not found or malformed', key: '0' });
      return items;
    }

    contents.forEach((c) => {
      const fileNameSplits = c.fileName.split('/');
      // console.log('content', index1, 'fileNameSplits', fileNameSplits);

      let fileName;
      let parentFolder: ContentTreeItem | null = null;
      // const parentIsArray: null | Array<ContentTreeItem> = null;
      // let parentIsItem: null | ContentTreeItem = null;
      // if there are more than one item it's a folder or file in a folder
      if (fileNameSplits.length > 1) {
        fileName = fileNameSplits.pop();
        parentFolder = getParent(items, fileNameSplits);
      } else {
        [fileName] = fileNameSplits;
      }

      // console.log('fileName', fileName);
      // console.log('parentFolder', parentFolder);

      if (fileName == null || fileName === '') {
        return;
      }

      // if (fileName != null && fileName !== '') {
      const newItem = { title: fileName, key: '', isLeaf: true };
      if (parentFolder == null) {
        newItem.key = `${items.length}`;
        items.push(newItem);
        return;
      } // else if (Array.isArray(parentFolder?.children)) {

      if (parentFolder.children == null) {
        throw new Error('children not set');
      }

      newItem.key = `${parentFolder.key}-${parentFolder.children.length}`;
      parentFolder.children.push(newItem);
    });
  }

  // console.log(JSON.stringify(items));
  return items;
};
