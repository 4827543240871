import {
  CALENDARFEED_GENERATE_URLS,
  CALENDARFEED_GENERATE_URLS_FAIL,
  CALENDARFEED_GENERATE_URLS_OK,
  CALENDARFEED_GET_URLS,
  CALENDARFEED_GET_URLS_FAIL,
  CALENDARFEED_GET_URLS_OK,
} from '../constants/calendarFeed';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

export const generateCalendarFeedData = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    { url: '/calendarfeed/generate', method: 'POST', data: { appId } },
    dispatch,
    {
      init: CALENDARFEED_GENERATE_URLS,
      fail: CALENDARFEED_GENERATE_URLS_FAIL,
    },
    201
  );
  dispatch({ type: CALENDARFEED_GENERATE_URLS_OK, payload: response.data, appId });
};

export const getCalendarFeedData = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/calendarfeed/${appId}`, method: 'GET' }, dispatch, {
    init: CALENDARFEED_GET_URLS,
    fail: CALENDARFEED_GET_URLS_FAIL,
  });
  dispatch({ type: CALENDARFEED_GET_URLS_OK, payload: response.data, appId });
};
