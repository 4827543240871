import { Spin } from 'antd';

import { BatchUploadComponentState } from './constants';
import { getUserProfile } from '../../selectors/authSelectors';
import { useGlobalSelector } from '../../utils/hooks';

type Props = Readonly<{
  state: BatchUploadComponentState;
}>;

const UploadStatus = ({ state }: Props) => {
  const profile = useGlobalSelector(getUserProfile);

  return (
    <div
      style={{
        backgroundColor: 'lightgrey',
        border: '1px transparent',
        borderRadius: 20,
        padding: 20,
        fontSize: 15,
        margin: 10,
      }}
    >
      {state === BatchUploadComponentState.UPLOADING_CLIENT && (
        <div>
          <b>Batch upload in progress</b>
          <p>
            While the upload is being processed the episodes section will be blocked for all users
            to ensure that the upload will not be affected.
          </p>
        </div>
      )}
      {(state === BatchUploadComponentState.UPLOAD_DONE_FROM_CLIENT ||
        state === BatchUploadComponentState.CREATING_FILES) && (
        <div>
          <b>Crunching the numbers...</b>
          <p>The upload is complete, but we need to finish up a few things.</p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spin spinning />
          </div>
        </div>
      )}
      {state === BatchUploadComponentState.UPLOAD_SUCCESS && (
        <p>
          All uploaded files are now converted. This happens in the background, but you cannot
          create releases that contain these episodes until the conversion is complete.
        </p>
      )}
      <span>
        Once the upload is finished you will receive an email at: <a>{profile.email}</a>.
      </span>
    </div>
  );
};

export default UploadStatus;
