import { Alert, message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getUserList, deleteUser } from '../actions/users';
import { ROUTE_NAME_INTERNAL_USERDETAIL } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import UserList from '../pages/UserList';
import { getAllUsers } from '../selectors/userSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

type LoadingInfo = { isLoading: boolean; error: string | null };

//
//
const UserListContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const allUsers = useGlobalSelector(getAllUsers);

  const [loadingInfo, setLoadingInfo] = useState<LoadingInfo>({
    isLoading: false,
    error: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingInfo(() => ({ isLoading: true, error: null }));
        await dispatch(getUserList());
        setLoadingInfo((info: LoadingInfo) => ({ ...info, isLoading: false }));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setLoadingInfo(() => ({ isLoading: false, error: msg }));
      }
    };
    fetchData();
  }, [dispatch]);

  const handleUserDelete = async (userId: number) => {
    try {
      await dispatch(deleteUser(userId));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleUserOpenDetails = (userId: number) => {
    history.push(getUrlByName(ROUTE_NAME_INTERNAL_USERDETAIL, { ':userId': userId.toString() }));
  };

  if (loadingInfo.error !== null) {
    return <Alert showIcon type="error" message="Error" description={loadingInfo.error} />;
  }

  return (
    <UserList
      allUsers={allUsers}
      deleteUser={handleUserDelete}
      openDetails={handleUserOpenDetails}
    />
  );
};

export default UserListContainer;
