import { CHUNK_SIZE } from './multipart-constants';

export async function blobToUint8Array(blob: Blob) {
  return new Uint8Array(await blob.arrayBuffer());
}

export function chunkFile(file: File, totalParts: number) {
  return Array.from(Array(totalParts).keys()).map((index) => {
    const start = index * CHUNK_SIZE;
    const end = start + CHUNK_SIZE;
    return file.slice(start, end);
  });
}
