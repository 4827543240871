import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useMemo } from 'react';

import DeploymentChangeItem from './DeploymentChangeItem';
import {
  cancelDeploymentDiff,
  finishDeploymentDiff,
  startDeploymentDiff,
} from '../actions/deployments';
import { MAX_EPISODE_LOCALIZED_DATA_FIELDS } from '../common/constants/app';
import { getValueByName } from '../common/utils/configuration';
import { useCurrentAppContext } from '../contexts';
import { getDeploymentDiff } from '../selectors/deploymentSelectors';
import dayjs from '../utils/date';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';

import type { Deployment, DeploymentFetchData, DiffChange } from '../../typings/deployments';
import type { Region } from '../reducers/RegionReducer';

type Props = Readonly<{
  allRegions: Array<Region>;
  last: DeploymentFetchData['latest'];
  current: Deployment | null;
  isLoading: boolean;
  loadingPercent: number;
}>;

type StatusProps = Readonly<{
  diff: Array<DiffChange> | null;
  allRegions: Array<Region>;
  isLoading: boolean;
  loadingPercent: number;
  isCalculatingDiff: boolean;
  previousDeployment: DeploymentFetchData['latest'];
}>;

//
//
const DiffStatus = ({
  allRegions,
  isLoading,
  isCalculatingDiff,
  diff,
  previousDeployment,
}: StatusProps) => {
  if (isLoading === true) {
    return (
      <div style={{ marginTop: 10 }}>
        <span style={{ paddingRight: 10 }}>loading deployment data</span>
        <LoadingOutlined />
      </div>
    );
  }
  if (diff == null || isCalculatingDiff == true) {
    return (
      <div style={{ marginTop: 10 }}>
        <span style={{ paddingRight: 10 }}>calculating changes</span>
        <LoadingOutlined />
      </div>
    );
  }

  let changedAnything = false;
  if (Array.isArray(diff)) {
    const result = diff.find((entry) => {
      if (entry.changes?.length > 0) {
        return true;
      }
      let x = false;
      if (Array.isArray(entry.groups)) {
        const result2 = entry.groups.find((group) => {
          let xx = false;
          if (Array.isArray(group.items)) {
            const result3 = group.items.find((item) => item.changes?.length > 0);
            if (result3 != null) {
              xx = true;
            }
          }
          return xx;
        });
        if (result2 != null) {
          x = true;
        }
      }
      return x;
    });
    if (result != null) {
      changedAnything = true;
    }
  }

  if (changedAnything === false) {
    if (previousDeployment === false) {
      return <div style={{ marginTop: 10 }}>No previous deployment</div>;
    }
    return <div style={{ marginTop: 10 }}>Nothing changed</div>;
  }

  return (
    <ul style={{ listStyle: 'none' }}>
      {diff.map((info) => {
        if (info.changes.length === 0 && info.groups.length === 0) {
          return null;
        }

        let title;
        switch (info.id) {
          case '--assetpatch':
            title = 'Asset Patches';
            break;
          default:
            title = `Market: ${
              allRegions.find((region) => region.id === parseInt(info.id, 10))?.name ?? info.id
            }`;
        }

        return (
          <li key={`region-${info.id}`}>
            <h3 style={{ marginTop: '2em', marginBottom: '1em' }}>{title}</h3>
            <ol>
              {info.changes.map((change) => (
                <DeploymentChangeItem
                  key={`region-${info.id}-change-${change.type}-${change.data}`}
                  change={change}
                />
              ))}
              {info.groups?.length > 0 && (
                <li>
                  <p>Changes to content</p>
                  <ol>
                    {info.groups.map((group) => {
                      if (group.items.length === 0) {
                        return null;
                      }

                      return (
                        <li key={`group-${group.id}`}>
                          <h4>{group.id}</h4>
                          <ul>
                            {group.items.map((item) => (
                              <li key={`group-${group.id}-item-${item.id}`}>
                                <h5>{item.metadata?.title ?? '__'}</h5>
                                <ul>
                                  {item.changes.map((change) => (
                                    <DeploymentChangeItem
                                      key={`group-${group.id}-item-${item.id}-change-${change.type}${change.additionalIndex}-${change.data}`}
                                      change={change}
                                    />
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </li>
                      );
                    })}
                  </ol>
                </li>
              )}
            </ol>
          </li>
        );
      })}
    </ul>
  );
};

//
//
const DeploymentDiff = (props: Props) => {
  const { allRegions, isLoading, loadingPercent, last, current } = props;
  const { currentApp } = useCurrentAppContext();
  const dispatch = useAppDispatch();

  const deploymentDiff = useGlobalSelector(getDeploymentDiff);
  const { isCalculatingDiff, diff } = deploymentDiff;

  const handler = (e: MessageEvent) => {
    console.log('result received from WORKER', e);
    dispatch(finishDeploymentDiff(e.data));
  };

  const diffWorker: Worker = useMemo(() => {
    console.log('WORKER create useMemo()');
    const w = new Worker(new URL('../workers/diff.worker.ts', import.meta.url));
    w.addEventListener('message', handler);
    return w;
  }, []);

  useEffect(() => {
    return () => {
      console.log('clean up WORKER');
      dispatch(cancelDeploymentDiff());
      diffWorker.removeEventListener('message', handler);
      diffWorker.terminate();
    };
  }, []);

  useEffect(() => {
    console.log(
      'DIFF useEffect'
      // { loadingPercent, diff, isCalculatingDiff, current, last }
    );
    if (
      loadingPercent === 100 &&
      diff === null &&
      isCalculatingDiff === false &&
      current !== null &&
      current.content?.['app.json'] != null &&
      last !== null
    ) {
      console.log('DIFF useEffect xxxx', JSON.stringify({ loadingPercent, diff }, null, 2));
      try {
        const customDataIncludes: Array<string> = [];
        for (let num = 1; num <= MAX_EPISODE_LOCALIZED_DATA_FIELDS; num++) {
          const dataIsVisible: boolean = getValueByName(
            currentApp,
            `episodeData.data${num}Visible`,
            false
          );
          if (dataIsVisible) {
            const dataFeedName: boolean | string = getValueByName(
              currentApp,
              `episodeData.data${num}FeedName`,
              false
            );
            if (dataFeedName !== false && typeof dataFeedName === 'string') {
              customDataIncludes.push(dataFeedName);
            }
          }
        }
        console.log('calling getDiff()', { workerSupport: window.Worker, customDataIncludes });
        // if (window.Worker) {
        dispatch(startDeploymentDiff());
        diffWorker.postMessage({ last, current, options: { customDataIncludes } });
        // }
      } catch (err) {
        console.error(err);
      }
    }
  }, [loadingPercent, current, last, diff, isCalculatingDiff]);

  return (
    <div data-tid="deployment-diff" style={{ marginTop: '2em', marginBottom: '1em' }}>
      <h2>
        <span>Changes</span>
        {last != null && last !== false && (
          <i style={{ fontSize: 11, marginLeft: '20px' }}>
            {`compared to deployment from ${dayjs(last.createdAt).format('YYYY-MM-DD HH:mm')}`}
          </i>
        )}
      </h2>
      <DiffStatus
        diff={diff}
        allRegions={allRegions}
        isLoading={isLoading}
        loadingPercent={loadingPercent}
        isCalculatingDiff={isCalculatingDiff}
        previousDeployment={last}
      />
    </div>
  );
};

export default DeploymentDiff;
