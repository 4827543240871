import type { EpisodeContent, AllowedContentTypes } from '../reducers/EpisodeContentReducer';
import type { EpisodeLocalized } from '../reducers/EpisodeLocalizedReducer';
import type { Resource } from '../reducers/ResourceReducer';

//
//
export const getContentByContentType = (
  el: EpisodeLocalized | null | undefined,
  contentType: AllowedContentTypes,
  appPlatformId: number | null
): EpisodeContent | null => {
  const content = el?.content ?? [];
  const foundContent = content.find(
    (ec) => ec.contentTypeId === contentType && ec.appPlatformId === appPlatformId
  );
  return foundContent ?? null;
};

//
//
export const getResourceByContentType = (
  el: EpisodeLocalized | null | undefined,
  contentType: AllowedContentTypes,
  appPlatformId: number | null
): Resource | null => {
  const foundContent = getContentByContentType(el, contentType, appPlatformId);
  return foundContent?.resource ?? null;
};
