import { Spin } from 'antd';

import PieAndTable from './analytics/PieAndTable';
import styles from './AnalyticsApp.module.scss';
import { getAppAnalyticsById } from '../selectors/appAnalyticsSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { FunctionComponent } from 'react';

type Props = {
  currentAppId: number;
};

const AnalyticsAppAllTime: FunctionComponent<Props> = ({ currentAppId }) => {
  const appAnalytics = useGlobalSelector((state) => getAppAnalyticsById(state, currentAppId));

  const allTime = appAnalytics?.allTime;

  return (
    <div className={styles.box} data-tid="analytics-all-time-box">
      <div className={styles.boxStatsContainer} style={{ width: '230px' }}>
        <h3 className={styles.headline}>Total Views (all time)</h3>
        {allTime == null && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
            <Spin />
          </div>
        )}
        {allTime != null && (
          <>
            <div className={styles.greyBox}>{allTime.total.toLocaleString()}</div>
            <PieAndTable pieAndTableData={allTime} testIdPrefix="analytics-all-time-box" />
          </>
        )}
      </div>
    </div>
  );
};

export default AnalyticsAppAllTime;
