import { combineReducers } from 'redux';

import { GET_APP_DETAILS_OK, REMOVE_USER_FROM_APP_OK } from '../constants/apps';
import { UPDATE_APP_USER_OK } from '../constants/appUsers';
import { LOGIN_OK } from '../constants/auth';
import { USER_GET_DETAIL_OK, USER_INVITE_ACCEPT_OK } from '../constants/users';

import type { User } from './UsersReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

export type AppUsersNormalized = {
  id: number;
  user: number;
  app: number;
  hidden: boolean;
};

export type AppUser = Merge<AppUsersNormalized, { user: User }>;

export type AppUsersByIdState = {
  [x: string | number]: AppUsersNormalized;
};

export type AppUsersState = {
  byId: AppUsersByIdState;
};

const initialStateById: AppUsersByIdState = {};

const byId = (state = initialStateById, action: AnyAction): AppUsersByIdState => {
  switch (action.type) {
    case GET_APP_DETAILS_OK: {
      const appUsers = action.payload.entities?.appUsers;
      if (appUsers == null) {
        return state;
      }
      const result = { ...appUsers };
      Object.keys(appUsers).map((appUserIdStr) => {
        result[appUserIdStr] = {
          ...appUsers[appUserIdStr],
          app: action.payload.result,
        };
      });
      return { ...state, ...result };
    }
    case USER_GET_DETAIL_OK:
    case LOGIN_OK: {
      const userApps = action.payload.entities?.userApps;
      if (userApps == null) {
        return state;
      }

      const result = { ...userApps };
      Object.keys(userApps).map((userAppIdStr) => {
        result[userAppIdStr] = {
          ...userApps[userAppIdStr],
          user: action.payload.result,
        };
      });
      return { ...state, ...result };
    }
    case UPDATE_APP_USER_OK: {
      const { appUserId, hidden } = action;
      const newState = { ...state };
      newState[appUserId] = { ...newState[appUserId], hidden };
      return newState;
    }
    case REMOVE_USER_FROM_APP_OK: {
      const { appId, userId } = action.payload;

      console.log('XDD', action.payload);

      const appUserId = Object.keys(state).find((appUserIdStr) => {
        const au = state[appUserIdStr];
        if (au.app === appId && au.user === userId) {
          return true;
        }
        return false;
      });

      if (appUserId == null) {
        return state;
      }

      // Return new state without removed user
      const newState = Object.keys(state)
        .filter((appUserIdStr) => appUserId !== appUserIdStr)
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: state[key],
          };
        }, {});

      return newState;
    }
    case USER_INVITE_ACCEPT_OK: {
      const newAppUser = action.updatedUser.entities?.userApps;
      const userId = action.updatedUser?.result;
      if (newAppUser == null || userId == null) {
        return state;
      }

      Object.keys(newAppUser).map((appUserIdStr) => {
        newAppUser[appUserIdStr].user = userId;
      });

      return { ...state, ...newAppUser };
    }
    default:
      return state;
  }
};

const reducers = combineReducers({
  byId,
});

export default reducers;
