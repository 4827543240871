import classNames from 'classnames';

import AnalyticsStats from './analytics/AnalyticsStats';
import StackedColumnAndTable from './analytics/StackedColumnAndTable';
import styles from './AnalyticsAppMaus.module.scss';
import { monthLabelMapping } from '../common/constants/months';

import type { Maus } from '../reducers/AppAnalyticsReducer';
import type { FunctionComponent } from 'react';

type Props = {
  maus: Maus;
};

const AnalyticsAppMausContent: FunctionComponent<Props> = ({ maus }) => {
  const firstRegion = maus.regions[0];

  if (firstRegion == null) {
    return null;
  }

  const currentMonth = firstRegion.months[12].month;
  const priorMonth = firstRegion.months[11].month;

  const trendTitle = `Trend (${priorMonth} vs ${currentMonth})`;

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerWithMoreThenOneRegion]: maus.regions.length > 1,
      })}
    >
      <AnalyticsStats
        box1Headline={monthLabelMapping[currentMonth]}
        box1Value={maus.month}
        box2Headline={trendTitle}
        box2Value={maus.trendMaus ?? 0}
        box2Trend={maus.trendChange}
        testIdPrefix="analytics-maus-stats"
      />
      <div className={styles.separator} />
      <StackedColumnAndTable regions={maus.regions} />
    </div>
  );
};

export default AnalyticsAppMausContent;
