import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Tag } from 'antd';
import { useState } from 'react';

import MbxButton from '../components/base/MbxButton';
import CountryTag from '../components/CountryTag';
import CustomPageHeader from '../components/CustomPageHeader';
import RegionAddCountryModal from '../components/RegionAddCountryModal';
import RegionEditForm from '../components/RegionEditForm';
import RegionLocaleDefaultTag from '../components/RegionLocaleDefaultTag';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_REGIONS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { isUserSuperadmin } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { RegionEditFormValues } from '../components/RegionEditForm';
import type { Country } from '../reducers/CountryReducer';
import type { Locale } from '../reducers/LocaleReducer';
import type { RegionCountry } from '../reducers/RegionCountryReducer';
import type { RegionLocale } from '../reducers/RegionLocaleReducer';
import type { Region } from '../reducers/RegionReducer';
import type { Timezone } from '../reducers/TimezoneReducer';

type ModalInfo = {
  isOpen: boolean;
  type: 'country' | 'locale' | '';
};

type Props = Readonly<{
  currentRegion: Region;
  timezones: Array<Timezone>;
  countries: Array<Country>;
  locales: Array<Locale>;

  removeRegionCountry: (rcId: number) => Promise<void>;
  removeRegionLocale: (rlId: number) => Promise<void>;
  updateRegion: (values: RegionEditFormValues) => Promise<boolean>;
  updateCountry: (rc: RegionCountry) => Promise<void>;
  updateLocale: (rl: RegionLocale) => Promise<void>;
  updateDefaultLocale: (rlId: number) => Promise<void>;
  addRegionChild: (type: string, childId: number, isDraft: boolean) => Promise<boolean>;
  addRemainingCountries: () => Promise<void>;
  removeRegionCountryAll: () => Promise<void>;
  deleteRegion: (region: Region) => Promise<void>;
}>;

//
//
const RegionDetail = (props: Props) => {
  const {
    currentRegion,
    timezones,
    countries,
    locales,
    updateRegion,
    updateCountry,
    updateLocale,
    updateDefaultLocale,
    addRegionChild,
    deleteRegion,
    removeRegionCountry,
    removeRegionLocale,
    addRemainingCountries,
    removeRegionCountryAll,
  } = props;

  const isSuperadmin = useGlobalSelector(isUserSuperadmin);

  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    isOpen: false,
    type: '',
  });

  const handleRegionDelete = async () => {
    if (window.confirm(`Really delete '${currentRegion.name}'?`)) {
      await deleteRegion(currentRegion);
    }
  };

  const handleCountryRemove = async (rc: RegionCountry) => {
    if (window.confirm(`Really remove country ${rc.country.name}?`)) {
      await removeRegionCountry(rc.id);
    }
  };

  const handleLocaleRemove = async (rl: RegionLocale) => {
    if (window.confirm(`Really remove locale ${rl.locale.name}?`)) {
      await removeRegionLocale(rl.id);
    }
  };

  const handleCountryModalOpen = (type: 'country' | 'locale') => {
    setModalInfo({ isOpen: true, type });
  };

  const handleModalClose = () => {
    setModalInfo({ isOpen: false, type: '' });
  };

  const handleAddRemainingCountries = async () => {
    if (window.confirm(`Really add all remaining countries?`)) {
      await addRemainingCountries();
    }
  };

  const handleRemoveAllCountry = async () => {
    if (window.confirm(`Really remove all countries?`)) {
      await removeRegionCountryAll();
    }
  };

  const { currentApp } = useCurrentAppContext();
  const backLink = getUrlByName(ROUTE_NAME_APP_REGIONS, {
    ':appUid': currentApp.uid,
  });

  let list: Array<Country | Locale> = [];
  let excludes: Array<number> = [];
  if (modalInfo.type !== null) {
    list = modalInfo.type === 'country' ? countries : locales;
    excludes =
      modalInfo.type === 'country'
        ? currentRegion.regionCountries.map((rc) => rc.country.id)
        : currentRegion.regionLocales.map((rl) => rl.locale.id);
  }

  return (
    <div>
      <CustomPageHeader
        title={`Market Detail ${currentRegion.name}`}
        backLink={backLink}
        extra={[
          <MbxButton
            key="btn-delete-region"
            data-tid="btn-delete-region"
            size="large"
            danger
            icon={<DeleteOutlined />}
            onClick={handleRegionDelete}
          >
            delete market
          </MbxButton>,
        ]}
      />

      <div style={{ marginTop: '2em' }}>
        <RegionEditForm
          onSubmit={updateRegion}
          data={currentRegion}
          timezones={timezones}
          backLink={backLink}
        />
      </div>
      <div style={{ marginTop: '4em' }}>
        <List
          data-tid="list-countries"
          header={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4>Countries belonging to this market</h4>
              <div>
                <MbxButton
                  icon={<PlusOutlined />}
                  onClick={() => handleCountryModalOpen('country')}
                  data-tid="btn-add-country"
                >
                  add country
                </MbxButton>
              </div>
            </div>
          }
          footer={
            isSuperadmin === true ? (
              <>
                <span style={{ marginRight: '1em' }}>Superadmin options</span>
                <MbxButton
                  icon={<PlusOutlined />}
                  onClick={handleAddRemainingCountries}
                  data-tid="btn-add-remaining-countries"
                  style={{ marginRight: 8 }}
                >
                  add remaining countries
                </MbxButton>

                <MbxButton
                  icon={<DeleteOutlined />}
                  danger
                  onClick={handleRemoveAllCountry}
                  data-tid="btn-remove-all-country"
                >
                  remove all countries
                </MbxButton>
              </>
            ) : null
          }
          bordered
          dataSource={currentRegion.regionCountries}
          renderItem={(rc) => (
            <List.Item
              key={rc.id}
              actions={[
                <MbxButton
                  key="btn-update-country"
                  size="small"
                  onClick={() => {
                    updateCountry(rc);
                  }}
                >
                  toggle draft
                </MbxButton>,
                <MbxButton
                  key="btn-delete-country"
                  danger
                  icon={<DeleteOutlined />}
                  size="small"
                  onClick={() => {
                    handleCountryRemove(rc);
                  }}
                />,
              ]}
            >
              <List.Item.Meta
                title={
                  <>
                    <span style={{ paddingRight: '1em' }}>
                      {<CountryTag regionCountry={rc} />}
                      {` ${rc.country.name}`}
                    </span>
                    {rc.isDraft && <Tag color="orange">draft</Tag>}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
      <div style={{ marginTop: '2em' }}>
        <List
          data-tid="list-locales"
          header={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4>Languages belonging to this market</h4>
              <MbxButton
                icon={<PlusOutlined />}
                onClick={() => handleCountryModalOpen('locale')}
                data-tid="btn-add-locale"
              >
                add language
              </MbxButton>
            </div>
          }
          bordered
          dataSource={currentRegion.regionLocales}
          renderItem={(rl) => {
            const isDefault = rl.id === currentRegion.defaultLocaleId;
            let actions;

            if (!isDefault) {
              actions = [
                <MbxButton
                  key="btn-update-locale"
                  size="small"
                  onClick={() => {
                    updateLocale(rl);
                  }}
                >
                  toggle draft
                </MbxButton>,
                <MbxButton
                  key="btn-delete-locale"
                  danger
                  icon={<DeleteOutlined />}
                  size="small"
                  onClick={() => {
                    handleLocaleRemove(rl);
                  }}
                />,
              ];
              if (!rl.isDraft) {
                actions.unshift(
                  <MbxButton
                    key="btn-update-defaultlocale"
                    size="small"
                    onClick={() => {
                      updateDefaultLocale(rl.id);
                    }}
                  >
                    set as default
                  </MbxButton>
                );
              }
            }

            return (
              <List.Item key={rl.id} actions={actions}>
                <List.Item.Meta
                  title={
                    <>
                      {rl.locale != null && (
                        <span
                          style={{ paddingRight: '1em' }}
                        >{`${rl.locale.shortcode.toUpperCase()} ${rl.locale.name}`}</span>
                      )}
                      {isDefault === true && (
                        <RegionLocaleDefaultTag
                          label="is default"
                          useDefaultAsFallback={currentRegion.useDefaultAsFallback}
                        />
                      )}
                      {rl.isDraft === true && <Tag color="orange">draft</Tag>}
                    </>
                  }
                />
              </List.Item>
            );
          }}
        />
      </div>

      <RegionAddCountryModal
        what={modalInfo.type}
        list={list}
        excludes={excludes}
        visible={modalInfo.isOpen}
        onSubmit={addRegionChild}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default RegionDetail;
