import { configureStore } from '@reduxjs/toolkit';
import localforage from 'localforage';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import immutableTransform from 'redux-persist-transform-immutable';

import { onRehydrationMiddleware } from './middleware/rehydrate';
import rootReducer from './reducers';

import type { Reducer } from 'redux';

if (process.env.REACT_APP_FORCE_LOCALSTORAGE === 'true') {
  localforage.config({
    keyPrefix: '',
    driver: localforage.LOCALSTORAGE,
    disableSerializeOnAccess: true,
  });
}

const persistConfig = {
  key: 'root',
  storage: localforage,
  transforms: [immutableTransform()],
  stateReconciler: autoMergeLevel2,
  // XXX: we're only storing the necessary reducers
  //      before, when storing all reducers, we ran into localStorage quota exceed errors
  // whitelist: [
  //   'auth',
  //   'users',
  //   'apps',
  //   'authServices',
  //   'appAuthServices',
  //   'platforms',
  //   'appPlatforms',
  //   'roles',
  //   'regions',
  //   'adminUiVersion',
  //   'episodes',
  //   'releases',
  //   'appUser',
  //   'jobs',
  // ],
  blacklist: ['queue', 'deployments', 'syncing', 'pageHistory', 'adminUiVersion', 'fileLogs'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer as Reducer);
// const persistedReducer = persistReducer<RootState, any>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // reducer: {
  //   apps: appsReducer,
  //   users: usersReducer,
  // },
  // middleware: (getDefaultMiddleware) => [
  //   // getDefaultMiddleware needs to be called with the state type
  //   ...getDefaultMiddleware<RootState>(),
  //   // correctly typed middlewares can just be used
  //   additionalMiddleware,
  // ],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }).concat(onRehydrationMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

// const middleware = [];

// middleware.push(onRehydrationMiddleware);

// if (process.env.REACT_APP_REDUX_LOGGING === 'true') {
//   middleware.push(logger);
// }

// const store = createStore(persistedReducer, {}, applyMiddleware(...middleware));
// const persistor = persistStore(store);

// export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
