import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { confirmEmailToken } from '../actions/users';
import EmailConfirmationPage from '../pages/EmailConfirmationPage';
import { getToken } from '../selectors/urlSelectors';
import { useAppDispatch } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

//
//
const EmailConfirmationContainer = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const emailToken = getToken(params);
  const [confirmationData, setConfirmationData] = useState<null | Error | boolean>(null);

  useEffect(() => {
    const check = async () => {
      try {
        if (emailToken != null) {
          await dispatch(confirmEmailToken(emailToken));
          setConfirmationData(true);
        }
      } catch (err) {
        const { unknownError } = logUnknownError(err);
        setConfirmationData(unknownError);
      }
    };

    check();
  }, [dispatch, emailToken]);

  return <EmailConfirmationPage info={confirmationData} />;
};

export default EmailConfirmationContainer;
