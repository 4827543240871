export const FileStatus = {
  CREATED: 101,
  UPLOADED: 102,
  PROCESSED: 103,
  READY: 201,
  ARCHIVED: 202,
  ERRORED: 301,
  TIMEDOUT: 302,
  UNKNOWN: 999,
} as const;

export const FileStatusNames = {
  [FileStatus.CREATED]: 'created',
  [FileStatus.UPLOADED]: 'uploaded',
  [FileStatus.PROCESSED]: 'processed',
  [FileStatus.READY]: 'ready',
  [FileStatus.ARCHIVED]: 'archived',
  [FileStatus.ERRORED]: 'errored',
  [FileStatus.TIMEDOUT]: 'timedout',
  [FileStatus.UNKNOWN]: 'unknown',
} as const;

export const VALID_FILE_STATUS = Object.keys(FileStatus).map(
  (key) => FileStatus[key as keyof typeof FileStatus]
);

export type AllowedFileStatus = (typeof VALID_FILE_STATUS)[number];

export const ERROR_STATUS_ABOVE_THIS = 300;
