import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, message, List } from 'antd';
import { useEffect } from 'react';

import { getAuthServices } from '../actions/authservices';
import CustomPageHeader from '../components/CustomPageHeader';
import { getAllAuthServices } from '../selectors/authServices';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

const AuthServiceList = () => {
  const dispatch = useAppDispatch();

  const allAuthServices = useGlobalSelector(getAllAuthServices);

  useEffect(() => {
    const fetch = () => {
      try {
        dispatch(getAuthServices());
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      }
    };
    fetch();
  }, [dispatch]);

  return (
    <div>
      <CustomPageHeader
        title="Auth Service List"
        extra={[
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            key="btn-add-auth-service"
            data-tid="btn-add-auth-service"
            disabled
          >
            add auth service
          </Button>,
        ]}
      />

      <List
        data-tid="auth-services-list"
        bordered={false}
        dataSource={allAuthServices}
        renderItem={(authService) => {
          return <List.Item key={authService.id}>{authService.name}</List.Item>;
        }}
      />
    </div>
  );
};

export default AuthServiceList;
