import { createSelector } from 'reselect';

import type { RootState } from '../reducers';
import type { QueueItem } from '../reducers/QueueReducer';

//
//
const getById = (state: RootState) => state.queue.byId;
const getAllIds = (state: RootState) => state.queue.allIds;

//
//
export const getQueueItemByIdFunc = createSelector(
  getById,
  //
  (byId) => (queueId: string) => {
    const item = byId[queueId];
    if (item == null) {
      return null;
    }
    return item;
  }
);

//
//
export const getQueueItemById = createSelector(
  getQueueItemByIdFunc,
  (_: unknown, queueId: string) => queueId,
  //
  ($getQueueItemById, queueId) => $getQueueItemById(queueId)
);

//
//
export const getAllQueueItems = createSelector(
  getAllIds,
  getQueueItemByIdFunc,
  //
  (allIds, $getQueueItemById) =>
    allIds.map((queueId: string) => $getQueueItemById(queueId)).filter((q): q is QueueItem => !!q)
);
