import { Spin, Alert } from 'antd';

type Props = Readonly<{
  info: null | Error | boolean;
}>;

//
//
const EmailConfirmation = (props: Props) => {
  const { info } = props;

  if (info === null) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  if (info instanceof Error) {
    let errorMessage;
    switch (info.message) {
      case 'token_not_found':
      case 'token_invalid':
        errorMessage = 'This is not a valid confirmation token';
        break;
      case 'token_expired':
        errorMessage = 'This invite token already expired';
        break;
      default:
        errorMessage = info.message;
    }

    return (
      <div>
        <Alert
          message="Error"
          description={errorMessage}
          type="error"
          showIcon
          style={{ width: '66%' }}
        />
      </div>
    );
  }

  return (
    <div>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold' }}>
        Thank you for confirming your email-address! 👍
      </h1>
    </div>
  );
};

export default EmailConfirmation;
