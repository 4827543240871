export const EPISODEVERSIONREQUIREMENTS_CREATE = 'EPISODEVERSIONREQUIREMENTS_CREATE';
export const EPISODEVERSIONREQUIREMENTS_CREATE_OK = 'EPISODEVERSIONREQUIREMENTS_CREATE_OK';
export const EPISODEVERSIONREQUIREMENTS_CREATE_FAIL = 'EPISODEVERSIONREQUIREMENTS_CREATE_FAIL';

export const EPISODEVERSIONREQUIREMENTS_DELETE = 'EPISODEVERSIONREQUIREMENTS_DELETE';
export const EPISODEVERSIONREQUIREMENTS_DELETE_OK = 'EPISODEVERSIONREQUIREMENTS_DELETE_OK';
export const EPISODEVERSIONREQUIREMENTS_DELETE_FAIL = 'EPISODEVERSIONREQUIREMENTS_DELETE_FAIL';

export const EPISODEVERSIONREQUIREMENTS_UPDATE = 'EPISODEVERSIONREQUIREMENTS_UPDATE';
export const EPISODEVERSIONREQUIREMENTS_UPDATE_OK = 'EPISODEVERSIONREQUIREMENTS_UPDATE_OK';
export const EPISODEVERSIONREQUIREMENTS_UPDATE_FAIL = 'EPISODEVERSIONREQUIREMENTS_UPDATE_FAIL';
