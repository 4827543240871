import type { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';

type B = Omit<DefaultOptionType, 'children'> & {
  children?: Array<Omit<DefaultOptionType, 'children'>> | string | { props: { children: string } };
};

/**
 * After antd package update, typings changed and from now it's
 * necessary to make more complex checks in basic select
 */
export const selectFilterFunc: FilterFunc<B> = (input, option) => {
  // console.log('selectFilterFunc()', input, option);
  const children = option?.children ?? null;
  if (children == null) {
    return false;
  }

  if (Array.isArray(children)) {
    children.forEach((ch) => {
      if (ch.stoLowerCase().indexOf(input.toLowerCase()) >= 0) {
        return true;
      }
    });
  } else if (typeof children === 'string') {
    return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  } else if (typeof children === 'object') {
    return children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
  return false;
};
