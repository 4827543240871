import { Form, Input } from 'antd';
import { useEffect } from 'react';

import MbxButton from './base/MbxButton';
import styles from './UserCreateForm.module.scss';

import type { User } from '../reducers/UsersReducer';

export type UserCreateFormValues = {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
};

type Props = {
  data: User | null;
  onSubmit?: (values: UserCreateFormValues) => Promise<void> | null;
  onCancel?: () => void | null;
};

//
//
const UserCreateForm = (props: Props) => {
  const { onSubmit, onCancel, data } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const handleSubmit = (values: UserCreateFormValues) => {
    console.log('Received values of form: ', values);
    if (typeof onSubmit === 'function') {
      try {
        onSubmit(values);
      } catch (err) {
        console.error(err);
      }
    } else {
      console.error('onSubmit not set');
    }
  };

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  const viewOnly = onSubmit == null;

  return (
    <Form
      onFinish={handleSubmit}
      className={styles.form}
      name="userCreateForm"
      form={form}
      layout="vertical"
    >
      <Form.Item
        label="Email"
        name="email"
        initialValue={data ? data.email : undefined}
        rules={[{ required: true, message: 'Please input email!' }]}
      >
        <Input disabled={viewOnly || data !== null} type="email" />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        initialValue={data ? 'xxxx' : undefined}
        rules={[{ required: true, message: 'Please input password!' }]}
      >
        <Input disabled={viewOnly || data !== null} type="password" />
      </Form.Item>
      <Form.Item
        label="Firstname"
        name="firstname"
        initialValue={data ? data.firstname : undefined}
        rules={[{ required: true, message: 'Please input firstname!' }]}
      >
        <Input disabled={viewOnly} />
      </Form.Item>
      <Form.Item
        label="Lastname"
        name="lastname"
        initialValue={data ? data.lastname : undefined}
        rules={[{ required: true, message: 'Please input lastname!' }]}
      >
        <Input disabled={viewOnly} />
      </Form.Item>
      {!viewOnly && (
        <Form.Item style={{ marginTop: '3em' }}>
          <MbxButton
            mbxType="primary"
            htmlType="submit"
            className={styles.addButton}
            data-tid="btn-userdetail-form-submit"
          >
            {data ? 'Save' : 'Create'}
          </MbxButton>
          <MbxButton
            className={styles.cancelButton}
            onClick={handleCancel}
            data-tid="btn-userdetail-form-cancel"
            size="large"
          >
            Cancel
          </MbxButton>
        </Form.Item>
      )}
    </Form>
  );
};

UserCreateForm.defaultProps = {
  onSubmit: null,
  onCancel: null,
};

export default UserCreateForm;
