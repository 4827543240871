import {
  LOGIN_FAIL,
  LOGIN_OK,
  LOGIN_TWOFA_RESET,
  LOGIN_TWOFA_STEP,
  LOGOUT_OK,
  TWOFA_ACTIVATE_OK,
  TWOFA_DEACTIVATE_OK,
} from '../constants/auth';

import type { AnyAction } from 'redux';

// import { openTwofaStep } from '../actions/auth';

export type TwofaData = {
  redirectTwofa: boolean;
  userData: {
    accessToken: string;
    email: string;
  };
};

export type AuthState = {
  isLoggedIn: boolean;
  authServiceName: string;
  twofa: null | TwofaData;
  redirectForceTwofa: number;
  userId: null | number;
  loginUrl?: string;
};

const initialState: AuthState = {
  isLoggedIn: false,
  twofa: null,
  redirectForceTwofa: 0,
  authServiceName: '',
  userId: null,
};

// export const authSlice = createSlice({
//   name: 'auth',
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     // Add reducers for additional action types here, and handle loading state as needed
//     builder
//       .addCase(LOGIN_TWOFA_STEP, (state, action) => {
//         console.log('################ ACTION LOGIN_TWOFA_STEP ##################', action);
//         // state.twofa = action.payload;
//         // state.token = null;
//       })
//       .addCase(LOGIN_OK, (state, action) => {
//         console.log('################ ACTION LOGIN_OK ##################', action);
//         // state.twofa = action.payload;
//         // state.token = null;
//       });
//   },
// });

/* eslint-disable react/destructuring-assignment */
const reducer = (state = initialState, action: AnyAction): AuthState => {
  switch (action.type) {
    case LOGIN_TWOFA_STEP:
      return {
        ...state,
        twofa: action.payload,
        isLoggedIn: false,
      };

    case LOGIN_TWOFA_RESET:
      return {
        ...state,
        twofa: null,
      };

    case LOGIN_OK:
      return {
        ...state,
        twofa: null,
        isLoggedIn: action.accessToken != null,
        authServiceName: action.authServiceName,
        redirectForceTwofa: action.redirectForceTwofa,
        userId: action.payload.entities.users[action.payload.result].id,
        loginUrl: action.loginUrl ?? null,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };

    case TWOFA_ACTIVATE_OK:
      return {
        ...state,
        redirectForceTwofa: 0,
      };

    case TWOFA_DEACTIVATE_OK:
      return {
        ...state,
        redirectForceTwofa: action.redirectForceTwofa,
      };

    case LOGOUT_OK:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
/* eslint-enable */

export default reducer;
// export default authSlice.reducer;
