import { combineReducers } from 'redux';

import { getEmptyLocale } from './LocaleReducer';
import {
  EPISODELOCALIZED_CREATE_OK,
  EPISODELOCALIZED_UPDATE_OK,
  EPISODELOCALIZED_DELETE_OK,
  EPISODELOCALIZED_ADD_CONTENT_OK,
  EPISODELOCALIZED_REMOVE_CONTENT_OK,
} from '../constants/episodeLocalized';
import {
  EPISODE_GET_DETAILS_OK,
  EPISODE_REMOVE_OK,
  EPISODE_GET_METADATA_OK,
} from '../constants/episodes';

import type { EpisodeContent } from './EpisodeContentReducer';
import type { Locale } from './LocaleReducer';
import type { DayViews } from '../common/types/analytics';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type EpisodeLocalizedBase = {
  id: number;
  name: string;
  data1: string | null;
  data2: string | null;
  data3: string | null;
  data4: string | null;
  data5: string | null;
  views: number;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date | null;
  updatedBy: number | null;
  analytics: {
    viewsIn28Days: number | null;
    viewsInPrior28Days: number | null;
    viewTrend: number | null;
    viewDays: DayViews;
  };
};

export type EpisodeLocalizedNormalized = Merge<
  EpisodeLocalizedBase,
  {
    locale: number;
    content: Array<number>;
  }
>;

export type EpisodeLocalized = Merge<
  EpisodeLocalizedBase,
  {
    locale: Locale;
    content: Array<EpisodeContent>;
  }
>;

export type EpisodeLocalizedByIdState = {
  [k: string | number]: EpisodeLocalizedNormalized;
};
export type EpisodeLocalizedAllIdsState = {
  [k: string | number]: Array<number>;
};
export type EpisodeLocalizedState = {
  byId: EpisodeLocalizedByIdState;
  allIds: EpisodeLocalizedAllIdsState;
};

//
//
export const getEmptyEpisodeLocalized = (id?: number): EpisodeLocalized => ({
  id: id ?? -1,
  name: '',
  data1: null,
  data2: null,
  data3: null,
  data4: null,
  data5: null,
  views: 0,
  createdAt: new Date(),
  createdBy: -1,
  updatedAt: null,
  updatedBy: null,
  locale: getEmptyLocale(),
  content: [],
  analytics: {
    viewsIn28Days: null,
    viewsInPrior28Days: null,
    viewTrend: null,
    viewDays: [],
  },
});

const initialStateById: EpisodeLocalizedByIdState = {};
const initialStateAllIds: EpisodeLocalizedAllIdsState = {};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): EpisodeLocalizedByIdState => {
  switch (action.type) {
    // case EPISODE_GET_LIST_OK: // this just gives us incomplete data
    case EPISODE_GET_DETAILS_OK:
    case EPISODE_GET_METADATA_OK:
    case EPISODELOCALIZED_CREATE_OK:
    case EPISODELOCALIZED_UPDATE_OK:
      if (typeof action.payload.entities.localizedEpisodes === 'undefined') {
        return state;
      }
      return {
        ...state,
        ...action.payload.entities.localizedEpisodes,
      };

    case EPISODELOCALIZED_DELETE_OK: {
      const { [action.episodeLocalized.id]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case EPISODE_REMOVE_OK: {
      const copy = { ...state };
      action.episode.localizedEpisodes.forEach((le: EpisodeLocalized) => delete copy[le.id]);
      return copy;
    }

    case EPISODELOCALIZED_ADD_CONTENT_OK: {
      return {
        ...state,
        [action.episodeLocalizedId]: {
          ...state[action.episodeLocalizedId],
          content: state[action.episodeLocalizedId].content.concat(action.payload.result),
        },
      };
    }

    case EPISODELOCALIZED_REMOVE_CONTENT_OK: {
      return {
        ...state,
        [action.payload.episodeLocalizedId]: {
          ...state[action.payload.episodeLocalizedId],
          content: state[action.payload.episodeLocalizedId].content.filter(
            (cId) => cId !== action.payload.content.id
          ),
        },
      };
    }

    default:
      return state;
  }
};

//
//
// eslint-disable-next-line default-param-last
const allIds = (state = initialStateAllIds, action: AnyAction): EpisodeLocalizedAllIdsState => {
  switch (action.type) {
    case EPISODE_GET_DETAILS_OK:
      if (typeof action.payload.entities.localizedEpisodes === 'undefined') {
        return state;
      }
      return {
        ...state,
        [action.episodeId]: Object.keys(action.payload.entities.localizedEpisodes).map(
          (key: string) => parseInt(key, 10)
        ),
      };

    case EPISODE_REMOVE_OK: {
      const { [action.episode.id]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case EPISODELOCALIZED_DELETE_OK:
      return {
        ...state,
        [action.episodeId]: state[action.episodeId].filter(
          (id) => id !== action.episodeLocalizedId
        ),
      };

    case EPISODELOCALIZED_CREATE_OK:
      if (!Array.isArray(state[action.episodeId])) {
        return {
          ...state,
          [action.episodeId]: [action.payload.result],
        };
      }
      return {
        ...state,
        [action.episodeId]: [...state[action.episodeId], action.payload.result],
      };

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
});

export default combined;
