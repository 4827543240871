import { getEmptyInvite } from '../reducers/InviteReducer';
import { sortById } from '../utils/sort';

import type { RootState } from '../reducers';
import type { UserInvite } from '../reducers/InviteReducer';

//
//
export const getInviteById = (state: RootState, inviteId: number): UserInvite => {
  const invite = state.invites.byId[inviteId];
  if (typeof invite === 'undefined') {
    return getEmptyInvite(inviteId);
  }
  return { ...invite };
};

//
//
export const getInvitesById = (state: RootState, ids: Array<number>): Array<UserInvite> => {
  if (!Array.isArray(ids)) {
    ids = [];
  }
  return ids.map((id) => getInviteById(state, id));
};

//
//
export const getAllInvitesForApp = (state: RootState, appId: number | null): Array<UserInvite> => {
  const key = appId == null ? 'null' : appId;
  const inviteIds = getInvitesById(state, state.invites.allIds[key]);
  if (!Array.isArray(inviteIds)) {
    return [];
  }
  const sorted = [...inviteIds].sort(sortById);
  return sorted;
};

//
//
export const getAllInvitesForEmail = (
  state: RootState,
  email: string | null | undefined
): Array<UserInvite> => {
  if (typeof email !== 'string') {
    return [];
  }

  const invites: Array<UserInvite> = [];
  Object.keys(state.invites.byId).forEach((inviteId) => {
    const invite = state.invites.byId[inviteId];
    if (invite.email === email) {
      invites.push(invite);
    }
  });
  return invites;
};

//
//
export const getInviteByHash = (state: RootState, hash: string): UserInvite | null => {
  let result = null;
  Object.keys(state.invites.byId).some((inviteId) => {
    const invite = state.invites.byId[inviteId];
    if (invite.hash === hash) {
      result = invite;
      return true;
    }
    return false;
  });
  return result;
};
