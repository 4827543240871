import union from 'lodash.union';
import { combineReducers } from 'redux';

import {
  CATEGORY_GET_OK,
  CATEGORY_GET_LIST_OK,
  CATEGORY_CREATE_OK,
  CATEGORY_REMOVE_OK,
  CATEGORY_UPDATE_OK,
} from '../constants/categories';
import {
  RELEASE_CREATE_OK,
  RELEASE_GET_LIST_OK,
  RELEASE_UPDATE_OK,
  RELEASE_GET_DETAILS_OK,
} from '../constants/releases';

import type { AnyAction } from 'redux';

export type Category = {
  id: number;
  appId: number;
  name: string;
  isDraft: boolean;
  isLocked: boolean;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date | null;
};

export type CategoryByIdState = {
  [k: string | number]: Category;
};
export type CategoryAllIdsState = {
  [k: string | number]: Array<number>;
};
export type CategoryState = {
  byId: CategoryByIdState;
  allIds: CategoryAllIdsState;
};

//
//
export const getEmptyCategory = (id?: number): Category => ({
  id: id ?? -1,
  appId: -1,
  name: '',
  isDraft: false,
  isLocked: false,
  createdAt: new Date(),
  createdBy: -1,
  updatedAt: null,
});

const initialStateById: CategoryByIdState = {};
const initialStateAllIds: CategoryAllIdsState = {};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): CategoryByIdState => {
  switch (action.type) {
    case CATEGORY_GET_OK:
    case CATEGORY_GET_LIST_OK:
    case CATEGORY_CREATE_OK:
    case CATEGORY_UPDATE_OK:
    case RELEASE_CREATE_OK:
    case RELEASE_UPDATE_OK:
    case RELEASE_GET_LIST_OK:
    case RELEASE_GET_DETAILS_OK:
      return {
        ...state,
        ...action.payload.entities.categories,
      };

    case CATEGORY_REMOVE_OK: {
      const { categoryId } = action;
      if (typeof categoryId === 'string' || typeof categoryId === 'number') {
        const { [categoryId]: remove, ...stateWithoutDeleted } = state;
        return stateWithoutDeleted;
      }
      return state;
    }

    default:
      return state;
  }
};

//
//
// eslint-disable-next-line default-param-last
const allIds = (state = initialStateAllIds, action: AnyAction): CategoryAllIdsState => {
  switch (action.type) {
    case CATEGORY_GET_LIST_OK:
      return {
        ...state,
        [action.appId]: action.payload.result,
      };

    case CATEGORY_CREATE_OK:
      return {
        ...state,
        [action.appId]: state[action.appId].concat(action.payload.result),
      };

    case RELEASE_CREATE_OK:
    case RELEASE_GET_DETAILS_OK: {
      const categories = action?.payload?.entities?.categories ?? {};
      return {
        ...state,
        [action.appId]: union(
          state[action.appId],
          Object.keys(categories).map((idStr) => parseInt(idStr, 10))
        ),
      };
    }

    case CATEGORY_REMOVE_OK:
      return {
        ...state,
        [action.appId]: state[action.appId].filter((id) => id !== action.categoryId),
      };

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
});

export default combined;
