import { Alert, message } from 'antd';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getRoleList } from '../actions/roles';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { getUserDetails, addUserRole, removeUserRole } from '../actions/users';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_INTERNAL_USERS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import UserDetail from '../pages/UserDetail';
import { getAllRolesForApp } from '../selectors/roleSelectors';
import { getUserId } from '../selectors/urlSelectors';
import { getUserById } from '../selectors/userSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

//
//
const AppUserDetailContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { currentApp } = useCurrentAppContext();

  const userId = getUserId(params);
  const user = useGlobalSelector((state) => getUserById(state, userId));
  const allAppRoles = useGlobalSelector((state) => getAllRolesForApp(state, currentApp.id));

  const [isUpdatingRole, setIsUpdatingRole] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      console.log('AppUserDetailContainer.fetchData()');
      try {
        dispatch(counterIncrement());
        await dispatch(getUserDetails(userId, currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };

    fetchData();
  }, [dispatch, userId, currentApp.id]);

  useEffect(() => {
    const fetchData = async () => {
      console.log('AppUserDetailContainer.fetchRoles()');
      try {
        dispatch(counterIncrement());
        await dispatch(getRoleList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };

    fetchData();
  }, [dispatch, currentApp.id]);

  const handleCancel = () => {
    history.push(getUrlByName(ROUTE_NAME_INTERNAL_USERS));
  };

  const handleRoleAdd = async (roleId: number) => {
    try {
      setIsUpdatingRole(true);
      await dispatch(addUserRole(user.id, roleId));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    } finally {
      setIsUpdatingRole(false);
    }
  };

  const handleRoleRemove = async (roleId: number) => {
    try {
      setIsUpdatingRole(true);
      await dispatch(removeUserRole(user.id, roleId));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    } finally {
      setIsUpdatingRole(false);
    }
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <UserDetail
      user={user}
      roles={allAppRoles}
      isUpdatingRole={isUpdatingRole}
      onCancel={handleCancel}
      addRoleToUser={handleRoleAdd}
      removeRoleFromUser={handleRoleRemove}
    />
  );
};

export default AppUserDetailContainer;
