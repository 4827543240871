import { CloseOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import classNames from 'classnames';
import * as React from 'react';

import styles from './SelectPlayerType.module.scss';
import { PlayerTypeShortNames, VALID_PLAYER_TYPES } from '../common/constants/player-type';
import { PlayerTypeColors } from '../utils/episode';

import type { SelectProps } from 'rc-select';

type Props = Readonly<{
  value: Array<number>;
  onChange: SelectProps['onChange'];
}>;

const isReactElement = (obj: unknown): obj is React.ReactElement => {
  return 'props' in (obj as React.ReactElement);
};

const SelectPlayerType = (props: Props) => {
  const { value, onChange } = props;

  return (
    <Select
      mode="multiple"
      allowClear
      data-tid="filter-by-type-dropdown"
      placeholder="Filter by type"
      value={value}
      onChange={onChange}
      style={{ minWidth: 140, marginRight: 10, marginBottom: 10 }}
      className={classNames([styles.selectWithClear, 'mbx-round-select'])}
      popupClassName={styles.selectWithBubblesPopup}
      tagRender={(tagProps) => {
        if (!isReactElement(tagProps.label)) {
          throw new Error(`tagRender is expecting ReactElement but got ${typeof tagProps.label}`);
        }
        const { onClose, closable, label } = tagProps;
        const { children, style, ...rest } = label.props;
        return (
          <span style={{ ...style, marginRight: 8 }} {...rest}>
            {children}
            {closable === true && (
              <span
                className="ant-select-selection-item-remove"
                style={{ userSelect: 'none', verticalAlign: '0.1em', marginLeft: 4 }}
                aria-hidden="true"
                onClick={onClose}
              >
                <CloseOutlined />
              </span>
            )}
          </span>
        );
      }}
    >
      {VALID_PLAYER_TYPES.map((playerTypeId) => (
        <Select.Option
          value={playerTypeId}
          key={`filter-playertype-${playerTypeId}`}
          className={styles.selectOption}
        >
          <span
            className={styles.bubble}
            style={{
              backgroundColor: `${PlayerTypeColors[playerTypeId]}4C`, // 4C => ~30% (see https://stackoverflow.com/a/8254129/388026)
            }}
          >
            {PlayerTypeShortNames[playerTypeId]}
          </span>
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectPlayerType;
