//
//
export const getUnknownError = (err: unknown): { msg: string; unknownError: Error | null } => {
  if (err instanceof Error) {
    return {
      msg: err.message,
      unknownError: err,
    };
  }
  return {
    msg: (err as string).toString(),
    unknownError: null,
  };
};

//
//
export const logUnknownError = (err: unknown): { msg: string; unknownError: Error | null } => {
  const { msg, unknownError } = getUnknownError(err);
  console.error(msg);
  return { msg, unknownError };
};
