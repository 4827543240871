import { createSelector } from 'reselect';

import { getCountryByIdFunc } from './countrySelectors';
import { sortRegionCountries } from '../utils/sort';

import type { RootState } from '../reducers';
import type {
  RegionCountryAllIdsState,
  RegionCountryByIdState,
} from '../reducers/RegionCountryReducer';
import type { Selector } from 'reselect';

//
//
const getById: Selector<RootState, RegionCountryByIdState> = (state) => state.regionCountries.byId;
const getAllIds: Selector<RootState, RegionCountryAllIdsState> = (state) =>
  state.regionCountries.allIds;

//
//
export const getRegionCountryByIdFunc = createSelector(
  getById,
  getCountryByIdFunc,
  //
  (byId, getCountryById) => (id: number) => {
    const rc = byId[id];
    return {
      ...rc,
      country: getCountryById(rc.country),
    };
  }
);

//
//
export const getRegionCountriesByIdsFunc = createSelector(
  getRegionCountryByIdFunc,
  //
  (getRegionCountryById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }

    return ids.map((id) => getRegionCountryById(id));
  }
);

//
//
export const getRegionCountriesForIdFunc = createSelector(
  getAllIds,
  getRegionCountriesByIdsFunc,
  //
  (allIds, getRegionCountriesByIds) => (id: number) => {
    const rcs = getRegionCountriesByIds(allIds[id]);
    rcs.sort(sortRegionCountries);
    return rcs;
  }
);

//
//
export const getRegionCountriesForId = createSelector(
  getRegionCountriesForIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getRegionCountriesForId, id) => $getRegionCountriesForId(id)
);
