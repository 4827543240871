import { schema, normalize } from 'normalizr';

import {
  GET_APPS_LIST,
  GET_APPS_LIST_OK,
  GET_APPS_LIST_FAIL,
  GET_APP_DETAILS,
  GET_APP_DETAILS_OK,
  GET_APP_DETAILS_FAIL,
  REMOVE_USER_FROM_APP,
  REMOVE_USER_FROM_APP_OK,
  REMOVE_USER_FROM_APP_FAIL,
  APP_CREATE,
  APP_CREATE_OK,
  APP_CREATE_FAIL,
  APP_UPDATE,
  APP_UPDATE_OK,
  APP_UPDATE_FAIL,
  APP_UPDATE_CONFIG,
  APP_UPDATE_CONFIG_OK,
  APP_UPDATE_CONFIG_FAIL,
  APP_DELETE_ICON,
  APP_DELETE_ICON_FAIL,
  APP_DELETE_ICON_OK,
  APP_UPDATE_ICON,
  APP_UPDATE_ICON_FAIL,
  APP_UPDATE_ICON_OK,
} from '../constants/apps';
import { AppSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { Configuration } from '../common/types/app';
import type { AppEditFormValues } from '../components/AppEditForm';
import type { AppDispatch } from '../configureStore';

//
//
export const createApp = (values: AppEditFormValues) => async (dispatch: AppDispatch) => {
  const { name, uid, contentBucket, archiveBucket, roleArn, cfIdPub, cfIdSec, setupVersion } =
    values;
  const response = await wrapFetch(
    {
      url: `/apps`,
      method: 'POST',
      data: {
        name,
        uid,
        contentBucket,
        archiveBucket,
        roleArn,
        cfIdPub,
        cfIdSec,
        setupVersion,
      },
    },
    dispatch,
    {
      init: APP_CREATE,
      fail: APP_CREATE_FAIL,
    },
    201
  );
  const normalizedData = normalize(response.data, AppSchema);
  dispatch({ type: APP_CREATE_OK, payload: normalizedData });
  return normalizedData;
};

//
//
export const getAppDetails = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/apps/${appId}` }, dispatch, {
    init: GET_APP_DETAILS,
    fail: GET_APP_DETAILS_FAIL,
  });
  const normalizedData = normalize(response.data, AppSchema);
  dispatch({ type: GET_APP_DETAILS_OK, payload: normalizedData });
};

//
//
export const getAppList = () => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/apps` }, dispatch, {
    init: GET_APPS_LIST,
    fail: GET_APPS_LIST_FAIL,
  });
  const normalizedData = normalize(response.data, new schema.Array(AppSchema));
  dispatch({ type: GET_APPS_LIST_OK, payload: normalizedData });
};

//
//
export const removeUserFromApp =
  (appId: number, userId: number) => async (dispatch: AppDispatch) => {
    await wrapFetch(
      {
        url: `/apps/${appId}/users/${userId}`,
        method: 'DELETE',
      },
      dispatch,
      {
        init: REMOVE_USER_FROM_APP,
        fail: REMOVE_USER_FROM_APP_FAIL,
      },
      204
    );
    dispatch({ type: REMOVE_USER_FROM_APP_OK, payload: { appId, userId } });
  };

//
//
export const removeIcon = (appId: number) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/apps/${appId}/icon`,
      method: 'DELETE',
    },
    dispatch,
    {
      init: APP_DELETE_ICON,
      fail: APP_DELETE_ICON_FAIL,
    },
    200
  );
  dispatch({ type: APP_DELETE_ICON_OK, payload: { appId } });
};

//
//
export const changeIcon = (appId: number, iconId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/apps/${appId}/icon`,
      method: 'PUT',
      data: {
        iconId,
      },
    },
    dispatch,
    {
      init: APP_UPDATE_ICON,
      fail: APP_UPDATE_ICON_FAIL,
    },
    200
  );
  const normalizedData = normalize(response.data, AppSchema);
  dispatch({ type: APP_UPDATE_ICON_OK, payload: normalizedData });
  return iconId;
};

//
//
export const updateApp = (appId: number, name: string) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/apps/${appId}`,
      method: 'PUT',
      data: {
        name,
      },
    },
    dispatch,
    {
      init: APP_UPDATE,
      fail: APP_UPDATE_FAIL,
    }
  );

  const normalizedData = normalize(response.data, AppSchema);
  dispatch({ type: APP_UPDATE_OK, payload: normalizedData });
  return normalizedData;
};

//
//
export const updateAppConfig =
  (appId: number, config: Configuration) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/apps/${appId}/config`,
        method: 'PUT',
        data: { config },
      },
      dispatch,
      {
        init: APP_UPDATE_CONFIG,
        fail: APP_UPDATE_CONFIG_FAIL,
      }
    );
    const normalizedData = normalize(response.data, AppSchema);
    dispatch({ type: APP_UPDATE_CONFIG_OK, payload: normalizedData });
    return normalizedData;
  };
