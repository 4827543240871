export const ROUTE_NAME_HOME = 'home';
export const ROUTE_NAME_REGISTER = 'register';
export const ROUTE_NAME_INVITECONFIRMATION = 'inviteConfirmation';
export const ROUTE_NAME_EMAILCONFIRMATION = 'emailConfirmation';
export const ROUTE_NAME_CHECKLOGIN = 'checkLogin';
export const ROUTE_NAME_LOGIN = 'login';
export const ROUTE_NAME_LOGINTWOFA = 'loginTwofa';
export const ROUTE_NAME_PASSWORDFORGOT = 'passwordForgot';
export const ROUTE_NAME_PASSWORDRESET = 'passwordReset';
export const ROUTE_NAME_PROFILE = 'profile';
export const ROUTE_NAME_PROFILETWOFASETUP = 'profile2faSetup';
export const ROUTE_NAME_ABOUT = 'about';
export const ROUTE_NAME_IMPRINT = 'imprint';
export const ROUTE_NAME_TESTPAGE = 'testPage';
export const ROUTE_NAME_INTERNAL_APPS = 'apps';
export const ROUTE_NAME_INTERNAL_APPCREATE = 'appCreate';
export const ROUTE_NAME_INTERNAL_APPDETAIL = 'appDetail';
export const ROUTE_NAME_INTERNAL_APPPLATFORMDETAIL = 'appPlatformDetail';
export const ROUTE_NAME_INTERNAL_PLATFORMS = 'platforms';
export const ROUTE_NAME_INTERNAL_AUTHSERVICES = 'authServices';
export const ROUTE_NAME_INTERNAL_USERS = 'users';
export const ROUTE_NAME_INTERNAL_USERROLES = 'userRoles';
export const ROUTE_NAME_INTERNAL_USERINVITE = 'userInvite';
export const ROUTE_NAME_INTERNAL_USERDETAIL = 'userDetail';
export const ROUTE_NAME_INTERNAL_JOBS = 'jobs';
export const ROUTE_NAME_INTERNAL_JOBDETAIL = 'jobDetail';
export const ROUTE_NAME_APP_HOME = 'appHome';
export const ROUTE_NAME_APP_SETTINGS = 'appSettings';
export const ROUTE_NAME_APP_ROLES = 'appRoles';
export const ROUTE_NAME_APP_USERDETAIL = 'appUserDetail';
export const ROUTE_NAME_APP_USERINVITE = 'appUserInvite';
export const ROUTE_NAME_APP_REGIONS = 'regions';
export const ROUTE_NAME_APP_REGIONCREATE = 'regionCreate';
export const ROUTE_NAME_APP_REGIONDETAIL = 'regionDetail';
export const ROUTE_NAME_APP_BATCHUPLOADCREATE = 'batchUploadCreate';
export const ROUTE_NAME_APP_EPISODES = 'episodes';
export const ROUTE_NAME_APP_EPISODECREATE = 'episodeCreate';
export const ROUTE_NAME_APP_EPISODEDETAIL = 'episodeDetail';
export const ROUTE_NAME_APP_RELEASES = 'releases';
export const ROUTE_NAME_APP_RELEASECREATE = 'releaseCreate';
export const ROUTE_NAME_APP_RELEASEDETAIL = 'releaseDetail';
export const ROUTE_NAME_APP_CATEGORIES = 'categories';
export const ROUTE_NAME_APP_CATEGORYSORT = 'categorySort';
export const ROUTE_NAME_APP_DEPLOYMENTS = 'deployments';
export const ROUTE_NAME_APP_DEPLOYMENTDETAIL = 'deploymentDetail';
export const ROUTE_NAME_APP_DEPLOYMENTCREATE = 'deploymentCreate';
export const ROUTE_NAME_APP_ASSETS = 'assets';
export const ROUTE_NAME_APP_ASSETCREATE = 'assetCreate';
export const ROUTE_NAME_APP_ASSETDETAIL = 'assetDetail';
export const ROUTE_NAME_APP_ANALYTICS = 'appAnalytics';
export const ROUTE_NAME_APP_ANALYTICSVIEWSREPORTS = 'appAnalyticsViewsReports';
export const ROUTE_NAME_APP_ANALYTICSMAUSREPORTS = 'appAnalyticsMausReports';
export const ROUTE_NAME_BATCH_UPOAD_TEMPLATE = 'batchUploadTemplate';

export const ALLOWED_ROUTE_NAMES = [
  ROUTE_NAME_HOME,
  ROUTE_NAME_REGISTER,
  ROUTE_NAME_INVITECONFIRMATION,
  ROUTE_NAME_EMAILCONFIRMATION,
  ROUTE_NAME_CHECKLOGIN,
  ROUTE_NAME_LOGIN,
  ROUTE_NAME_LOGINTWOFA,
  ROUTE_NAME_PASSWORDFORGOT,
  ROUTE_NAME_PASSWORDRESET,
  ROUTE_NAME_PROFILE,
  ROUTE_NAME_PROFILETWOFASETUP,
  ROUTE_NAME_ABOUT,
  ROUTE_NAME_IMPRINT,
  ROUTE_NAME_TESTPAGE,
  ROUTE_NAME_INTERNAL_APPS,
  ROUTE_NAME_INTERNAL_APPCREATE,
  ROUTE_NAME_INTERNAL_APPDETAIL,
  ROUTE_NAME_INTERNAL_APPPLATFORMDETAIL,
  ROUTE_NAME_INTERNAL_PLATFORMS,
  ROUTE_NAME_INTERNAL_AUTHSERVICES,
  ROUTE_NAME_INTERNAL_USERS,
  ROUTE_NAME_INTERNAL_USERROLES,
  ROUTE_NAME_INTERNAL_USERINVITE,
  ROUTE_NAME_INTERNAL_USERDETAIL,
  ROUTE_NAME_INTERNAL_JOBS,
  ROUTE_NAME_INTERNAL_JOBDETAIL,
  ROUTE_NAME_APP_HOME,
  ROUTE_NAME_APP_SETTINGS,
  ROUTE_NAME_APP_ROLES,
  ROUTE_NAME_APP_USERDETAIL,
  ROUTE_NAME_APP_USERINVITE,
  ROUTE_NAME_APP_REGIONS,
  ROUTE_NAME_APP_REGIONCREATE,
  ROUTE_NAME_APP_REGIONDETAIL,
  ROUTE_NAME_APP_BATCHUPLOADCREATE,
  ROUTE_NAME_APP_EPISODES,
  ROUTE_NAME_APP_EPISODECREATE,
  ROUTE_NAME_APP_EPISODEDETAIL,
  ROUTE_NAME_APP_RELEASES,
  ROUTE_NAME_APP_RELEASECREATE,
  ROUTE_NAME_APP_RELEASEDETAIL,
  ROUTE_NAME_APP_CATEGORIES,
  ROUTE_NAME_APP_CATEGORYSORT,
  ROUTE_NAME_APP_DEPLOYMENTS,
  ROUTE_NAME_APP_DEPLOYMENTDETAIL,
  ROUTE_NAME_APP_DEPLOYMENTCREATE,
  ROUTE_NAME_APP_ASSETS,
  ROUTE_NAME_APP_ASSETCREATE,
  ROUTE_NAME_APP_ASSETDETAIL,
  ROUTE_NAME_APP_ANALYTICS,
  ROUTE_NAME_APP_ANALYTICSVIEWSREPORTS,
  ROUTE_NAME_APP_ANALYTICSMAUSREPORTS,
  ROUTE_NAME_BATCH_UPOAD_TEMPLATE,
] as const;

export type RouteName = (typeof ALLOWED_ROUTE_NAMES)[number];

export function isRouteName(name: unknown): name is RouteName {
  return ALLOWED_ROUTE_NAMES.includes(name as RouteName);
}

export type Routes = { [k in RouteName]: RouteData };

export type RouteData = {
  path: string;
  name: string;
};

//
//
export const ROUTES: Routes = {
  [ROUTE_NAME_HOME]: {
    path: '/',
    name: 'Home',
  },
  [ROUTE_NAME_REGISTER]: {
    path: '/register',
    name: 'Create Mediabox account',
  },
  [ROUTE_NAME_INVITECONFIRMATION]: {
    path: '/invites/:token',
    name: 'Join Mediabox',
  },
  [ROUTE_NAME_EMAILCONFIRMATION]: {
    path: '/confirm/:token',
    name: 'Email Confirmation',
  },
  [ROUTE_NAME_CHECKLOGIN]: {
    path: '/checklogin',
    name: 'Check Login',
  },
  [ROUTE_NAME_LOGIN]: {
    path: '/login',
    name: 'Login',
  },
  [ROUTE_NAME_LOGINTWOFA]: {
    path: '/login-2fa',
    name: 'Login 2FA',
  },
  [ROUTE_NAME_PASSWORDFORGOT]: {
    path: '/login/password/forgot',
    name: 'Forgot password',
  },
  [ROUTE_NAME_PASSWORDRESET]: {
    path: '/login/password/reset/:token',
    name: 'Reset password',
  },
  [ROUTE_NAME_PROFILE]: {
    path: '/profile',
    name: 'Profile',
  },
  [ROUTE_NAME_PROFILETWOFASETUP]: {
    path: '/profile/2fa-setup',
    name: '2FA Setup',
  },
  [ROUTE_NAME_ABOUT]: {
    path: '/pages/about',
    name: 'About',
  },
  [ROUTE_NAME_IMPRINT]: {
    path: '/pages/imprint',
    name: 'Imprint',
  },
  [ROUTE_NAME_TESTPAGE]: {
    path: '/pages/test8273498',
    name: 'Test stuff',
  },
  [ROUTE_NAME_INTERNAL_APPS]: {
    path: '/internal/apps',
    name: 'Apps',
  },
  [ROUTE_NAME_INTERNAL_APPCREATE]: {
    path: '/internal/apps/create',
    name: 'Create new app',
  },
  [ROUTE_NAME_INTERNAL_APPDETAIL]: {
    path: '/internal/apps/:appId',
    name: ':appName settings',
  },
  [ROUTE_NAME_INTERNAL_APPPLATFORMDETAIL]: {
    path: '/internal/apps/:appId/appplatforms/:appPlatformId',
    name: ':appPlatformName versions',
  },
  [ROUTE_NAME_INTERNAL_PLATFORMS]: {
    path: '/internal/platforms',
    name: 'Platforms',
  },
  [ROUTE_NAME_INTERNAL_AUTHSERVICES]: {
    path: '/internal/auth-services',
    name: 'Auth Services',
  },
  [ROUTE_NAME_INTERNAL_USERS]: {
    path: '/internal/users',
    name: 'Users',
  },
  [ROUTE_NAME_INTERNAL_USERROLES]: {
    path: '/internal/users/roles',
    name: 'Roles',
  },
  [ROUTE_NAME_INTERNAL_USERINVITE]: {
    path: '/internal/users/invites',
    name: 'Invite user',
  },
  [ROUTE_NAME_INTERNAL_USERDETAIL]: {
    path: '/internal/users/details/:userId',
    name: ':userName',
  },
  [ROUTE_NAME_INTERNAL_JOBS]: {
    path: '/internal/jobs',
    name: 'Jobs',
  },
  [ROUTE_NAME_INTERNAL_JOBDETAIL]: {
    path: '/internal/jobs/:jobId',
    name: ':jobId',
  },
  [ROUTE_NAME_APP_HOME]: {
    path: '/app/:appUid',
    name: ':appName',
  },
  [ROUTE_NAME_APP_SETTINGS]: {
    path: '/app/:appUid/settings',
    name: 'Settings',
  },
  [ROUTE_NAME_APP_ROLES]: {
    path: '/app/:appUid/settings/roles',
    name: 'Roles',
  },
  [ROUTE_NAME_APP_USERDETAIL]: {
    path: '/app/:appUid/settings/users/details/:userId',
    name: ':userName',
  },
  [ROUTE_NAME_APP_USERINVITE]: {
    path: '/app/:appUid/settings/users/invites',
    name: 'Invite user',
  },
  [ROUTE_NAME_APP_REGIONS]: {
    path: '/app/:appUid/markets',
    name: 'Markets',
  },
  [ROUTE_NAME_APP_REGIONCREATE]: {
    path: '/app/:appUid/markets/create',
    name: 'Create',
  },
  [ROUTE_NAME_APP_REGIONDETAIL]: {
    path: '/app/:appUid/markets/:regionId',
    name: ':regionName',
  },
  [ROUTE_NAME_APP_BATCHUPLOADCREATE]: {
    path: '/app/:appUid/batchUploadCreate',
    name: 'Batch Upload',
  },
  [ROUTE_NAME_APP_EPISODES]: {
    path: '/app/:appUid/episodes',
    name: 'Episodes',
  },
  [ROUTE_NAME_APP_EPISODECREATE]: {
    path: '/app/:appUid/episodes/create',
    name: 'Episode create',
  },
  [ROUTE_NAME_APP_EPISODEDETAIL]: {
    path: '/app/:appUid/episodes/:episodeId',
    name: ':episodeName',
  },
  [ROUTE_NAME_APP_RELEASES]: {
    path: '/app/:appUid/releases',
    name: 'Releases',
  },
  [ROUTE_NAME_APP_RELEASECREATE]: {
    path: '/app/:appUid/releases/create',
    name: 'Release create',
  },
  [ROUTE_NAME_APP_RELEASEDETAIL]: {
    path: '/app/:appUid/releases/:releaseId',
    name: ':releaseName',
  },
  [ROUTE_NAME_APP_CATEGORIES]: {
    path: '/app/:appUid/categories',
    name: 'Categories',
  },
  [ROUTE_NAME_APP_CATEGORYSORT]: {
    path: '/app/:appUid/categories/:categoryId/sort',
    name: 'Category sorting',
  },
  [ROUTE_NAME_APP_DEPLOYMENTS]: {
    path: '/app/:appUid/deployments',
    name: 'Deployments',
  },
  [ROUTE_NAME_APP_DEPLOYMENTDETAIL]: {
    path: '/app/:appUid/deployments/:deploymentId',
    name: ':deploymentDate',
  },
  [ROUTE_NAME_APP_DEPLOYMENTCREATE]: {
    path: '/app/:appUid/deployments/create',
    name: 'Create Deployment',
  },
  [ROUTE_NAME_APP_ASSETS]: {
    path: '/app/:appUid/assets',
    name: 'Asset patches',
  },
  [ROUTE_NAME_APP_ASSETCREATE]: {
    path: '/app/:appUid/assets/create',
    name: 'Create',
  },
  [ROUTE_NAME_APP_ASSETDETAIL]: {
    path: '/app/:appUid/assets/:assetId',
    name: ':assetName',
  },
  [ROUTE_NAME_APP_ANALYTICS]: {
    path: '/app/:appUid/reports',
    name: 'Reports',
  },
  [ROUTE_NAME_APP_ANALYTICSVIEWSREPORTS]: {
    path: '/app/:appUid/reports/views',
    name: 'Views Reports',
  },
  [ROUTE_NAME_APP_ANALYTICSMAUSREPORTS]: {
    path: '/app/:appUid/reports/maus',
    name: 'MAU Reports',
  },
  [ROUTE_NAME_BATCH_UPOAD_TEMPLATE]: {
    path: '/api/batchupload/template',
    name: 'Batch Upload Template',
  },
};
