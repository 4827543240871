import { List } from 'antd';

import NumberCard from './NumberCard';
import { Severity, SeverityMapping } from '../../common/constants/batch-upload';

import type { BatchUploadRecord, Issue } from '../../common/types/batch-upload';

type Props = Readonly<{
  records: Array<BatchUploadRecord>;
  newEpisodes: boolean;
  issues?: Array<Issue>;
}>;

const ValidationSummary = ({ records, newEpisodes, issues = [] }: Props) => {
  const totalLanguageVersions = records
    .map(({ languages }) => languages.length)
    .reduce((sum, len) => {
      return sum + len;
    }, 0);
  const byLanguage = records.reduce((mapping, { languages }) => {
    languages.forEach(({ locale }) => {
      if (!locale) {
        return;
      }
      if (!mapping[locale]) {
        mapping[locale] = 0;
      }
      mapping[locale] += 1;
    });
    return mapping;
  }, {} as Record<string, number>);
  const pluralize = (n: number) => (n > 1 ? 's' : '');
  const warnings = issues.filter(({ key }) => SeverityMapping[key] === Severity.WARNING).length;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '45%',
          marginBottom: 10,
        }}
      >
        {newEpisodes === true && (
          <NumberCard
            n={records.length}
            title={`New episode${pluralize(records.length)}`}
            testId="bu-new-episodes"
          />
        )}
        <NumberCard
          n={totalLanguageVersions}
          title={`New language version${pluralize(totalLanguageVersions)}`}
          border={newEpisodes}
          testId="bu-new-versions"
        />
        {warnings > 0 && <NumberCard n={warnings} title={'Warnings'} color={'orange'} border />}
      </div>
      <h3 style={{ marginTop: 32 }}>Summary</h3>
      <List>
        {newEpisodes === true && (
          <List.Item key="root" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{`New episode${pluralize(records.length)}`}</span>
            <span>{records.length}</span>
          </List.Item>
        )}
        {Object.entries(byLanguage).map(([name, total]) => {
          return (
            <List.Item key={name} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Language version {name}</span>
              <span>{total}</span>
            </List.Item>
          );
        })}
      </List>
    </div>
  );
};

export default ValidationSummary;
