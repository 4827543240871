import {
  CheckOutlined,
  LoadingOutlined,
  CloudUploadOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  QuestionOutlined,
} from '@ant-design/icons';

import { JobService } from '../common/constants/job-service';
import { JobStatus } from '../common/constants/job-status';

import type { Job } from '../reducers/JobReducer';

type ResourceInfo = {
  label: string;
  color: string;
  icon: typeof QuestionOutlined;
};

//
//
export const getJobTypeInfo = (job: Job) => {
  const resourceInfo: ResourceInfo = { label: '', color: '#F00', icon: QuestionOutlined };

  const service = job?.serviceId ?? -1;
  switch (service) {
    case JobService.AWS_MEDIACONVERT:
      resourceInfo.label = 'AWS MediaConvert';
      resourceInfo.color = '#FFE18E';
      break;
    case JobService.CUSTOM_IMAGE_CONVERTER:
      resourceInfo.label = 'Custom Image Converter';
      resourceInfo.color = '#A3FDFF';
      break;
    case JobService.SUBTITLES_CONVERT:
      resourceInfo.label = 'Subtitles Internal Conversion';
      resourceInfo.color = '#0032A8';
      break;
    default:
      resourceInfo.label = '???';
      resourceInfo.color = '#FF8FAE';
  }

  const status = job?.logs?.[0]?.statusId ?? -1;
  switch (status) {
    case JobStatus.COMPLETED:
      resourceInfo.icon = CheckOutlined;
      break;
    case JobStatus.PROCESSING:
      resourceInfo.icon = LoadingOutlined;
      break;
    case JobStatus.CREATED:
      resourceInfo.icon = CloudUploadOutlined;
      break;
    case JobStatus.INITIALISED:
      resourceInfo.icon = ClockCircleOutlined;
      break;
    case JobStatus.ERRORED:
    case JobStatus.TIMEDOUT:
      resourceInfo.icon = ExclamationCircleOutlined;
      break;
    case JobStatus.UNKNOWN:
    default:
      resourceInfo.icon = QuestionOutlined;
  }

  return resourceInfo;
};
