export const RELEASECATEGORY_UPDATE = 'RELEASECATEGORY_UPDATE';
export const RELEASECATEGORY_UPDATE_OK = 'RELEASECATEGORY_UPDATE_OK';
export const RELEASECATEGORY_UPDATE_FAIL = 'RELEASECATEGORY_UPDATE_FAIL';

export const RELEASECATEGORY_DELETE = 'RELEASECATEGORY_DELETE';
export const RELEASECATEGORY_DELETE_OK = 'RELEASECATEGORY_DELETE_OK';
export const RELEASECATEGORY_DELETE_FAIL = 'RELEASECATEGORY_DELETE_FAIL';

export const RELEASECATEGORY_BULK_DELETE = 'RELEASECATEGORY_BULK_DELETE';
export const RELEASECATEGORY_BULK_DELETE_OK = 'RELEASECATEGORY_BULK_DELETE_OK';
export const RELEASECATEGORY_BULK_DELETE_FAIL = 'RELEASECATEGORY_BULK_DELETE_FAIL';
