import { message } from 'antd';
import { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import { login } from '../actions/auth';
import { resendConfirmationEmail } from '../actions/users';
import { ROUTE_NAME_LOGINTWOFA, ROUTE_NAME_PROFILETWOFASETUP } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import Login from '../pages/Login';
import { getAuth, isUserLoggedIn } from '../selectors/authSelectors';
import { getEmailFromSearch } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { LoginFormValues } from '../components/LoginForm';

type Props = {
  forceEmail?: string;
  comebackAfterLogin?: boolean;
};

//
//
const LoginContainer = (props: Props) => {
  const history = useHistory<{ from: { pathname: string } }>();
  const dispatch = useAppDispatch();

  const { forceEmail, comebackAfterLogin } = props;

  const prefilledEmail = getEmailFromSearch(history.location.search);
  const auth = useGlobalSelector(getAuth);
  const userLoggedIn = useGlobalSelector(isUserLoggedIn);

  console.log('location', history.location);
  let redirectTo = history.location.state?.from ?? { pathname: '/' };
  if (comebackAfterLogin) {
    redirectTo = { pathname: history.location.pathname };
  }

  const [loginInfo, setLoginInfo] = useState<{
    isLoggingIn: boolean;
    errorMessage: string;
    data: { email: string } | null;
  }>({ isLoggingIn: false, errorMessage: '', data: null });

  const handleLogin = async (values: LoginFormValues) => {
    const { email, password } = values;
    try {
      setLoginInfo({ ...loginInfo, isLoggingIn: true, errorMessage: '' });
      await dispatch(login(email, password));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
      setLoginInfo({ isLoggingIn: false, errorMessage: msg, data: { email } });
    }
  };

  const handleResendConfirmation = async (email: string) => {
    try {
      await dispatch(resendConfirmationEmail(email));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  if (auth.twofa !== null) {
    return (
      <Redirect
        to={{
          pathname: getUrlByName(ROUTE_NAME_LOGINTWOFA),
          state: { referrer: redirectTo },
        }}
      />
    );
  }

  if (auth.redirectForceTwofa > 0) {
    return (
      <Redirect
        to={{
          pathname: getUrlByName(ROUTE_NAME_PROFILETWOFASETUP),
        }}
      />
    );
  }

  console.log('Redirect via tag', redirectTo);
  if (userLoggedIn) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Login
      login={handleLogin}
      loginInfo={loginInfo}
      prefilledEmail={prefilledEmail}
      forceEmail={forceEmail}
      resendConfirmationEmail={handleResendConfirmation}
    />
  );
};

LoginContainer.defaultProps = {
  forceEmail: null,
  comebackAfterLogin: false,
};

export default LoginContainer;
