import { FrownOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/browser';
import { Modal as IModal } from 'antd';

import { BatchUploadModalState, cancelCopy } from './constants';
import { getButtonProps } from '../base/MbxButton';

import type { ModalProps } from 'antd';
import type { CSSProperties } from 'react';

type Props = Readonly<{
  state: BatchUploadModalState | null;
  setModalState: (state: BatchUploadModalState | null) => void;
  handleCancel: (returnToEpisodes?: boolean) => void;
  submitFiles: () => Promise<void>;
  resumeProgress: () => void;
}>;

const Modal = ({ state, setModalState, handleCancel, submitFiles, resumeProgress }: Props) => {
  let title: string | JSX.Element = '';
  let okMethod: () => void = () => {};
  let cancelMethod: () => void = () => {};
  let content: string | JSX.Element = '';
  let okText = '';
  let cancelText = '';

  const customCancelButtonStyle: CSSProperties = {};

  switch (state) {
    case BatchUploadModalState.CONFIRM_UPLOAD: {
      okText = 'Ok, start batch upload';
      cancelText = 'Cancel';
      cancelMethod = () => setModalState(null);
      okMethod = () => {
        submitFiles();
        setModalState(null);
      };
      title = <span data-tid="bu-modal-title">Please note...</span>;
      content = (
        <div>
          <b>To ensure that the upload can run successfully, please do not:</b>
          <ul>
            <li>close your laptop or turn off your computer during the process</li>
            <li>
              <b>don’t change the specified location of the files you want to upload</b>
              (otherwise the reference to the file could be lost and the file cannot be uploaded)
            </li>
          </ul>
        </div>
      );
      break;
    }
    case BatchUploadModalState.CONFIRM_CANCEL: {
      okText = 'Yes, cancel the upload';
      cancelText = 'No, I want to continue the upload';
      cancelMethod = () => setModalState(null);
      okMethod = handleCancel;
      title = (
        <span data-tid="bu-modal-title">Are you sure you want to cancel the batch upload?</span>
      );
      content = cancelCopy('yes');
      break;
    }
    case BatchUploadModalState.VALIDATION_WAS_PREVIOUSLY_SUCCESSFUL: {
      cancelMethod = handleCancel;
      okMethod = () => setModalState(null);
      title = <span data-tid="bu-modal-title">Validation was previously successful</span>;
      content = `
        Unfortunately, additional data has been added/changed since the last validation of the
        metadata sheet which has rendered it invalid. Please try again or press 'cancel'
        to cancel the batch upload.
          `;
      break;
    }
    case BatchUploadModalState.MISC_ERROR: {
      Sentry.captureMessage(`Error modal was shown`);

      customCancelButtonStyle.display = 'none';

      cancelMethod = handleCancel;
      okMethod = () => setModalState(null);
      title = (
        <span data-tid="bu-modal-title">
          <FrownOutlined /> Sorry, your batch upload failed and cannot be fixed
        </span>
      );
      content = `
The upload failed due to a major problem. This can happen if your WIFI connection is interrupted or unstable.
Please start a new batch upload and continue with your existing sheet. Make sure your device is connected to a power source during the upload and do not close it.
          `;
      break;
    }
    case BatchUploadModalState.EXISTING_UPLOAD: {
      okText = 'Continue where I left off';
      cancelText = 'Cancel the upload';
      cancelMethod = () => handleCancel(false);
      okMethod = () => {
        resumeProgress();
        setModalState(null);
      };
      title = <span data-tid="bu-modal-title">Unfinished Upload Detected</span>;
      content = `
        We have detected an existing upload. What would you like to do?
          `;
      break;
    }
  }

  const { className, ...buttonStyle } = getButtonProps({ size: 'middle' });
  const okButtonProps: ModalProps['okButtonProps'] = {
    className,
    ...buttonStyle,
    'data-tid': 'bu-modal-submit-btn',
  };

  const { className: cancelClassName, ...cancelButtonStyle } = getButtonProps({
    mbxType: 'secondary',
    size: 'middle',
  });
  const cancelButtonProps: ModalProps['cancelButtonProps'] = {
    className: cancelClassName,
    ...cancelButtonStyle,
    style: customCancelButtonStyle,
    'data-tid': 'bu-modal-cancel-btn',
  };

  return (
    <IModal
      title={title}
      open={state !== null}
      onOk={okMethod}
      onCancel={cancelMethod}
      okText={okText}
      cancelText={cancelText}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      closable={false}
      keyboard={false}
      maskClosable={false}
    >
      {content}
    </IModal>
  );
};

export default Modal;
