import { Form, Input } from 'antd';

import MbxButton from './base/MbxButton';

type Props = {
  isLoading: boolean;
  askForPassword?: boolean;
  onSubmitCode: (options: TwofaCodeFormValues) => Promise<void>;
};

export type TwofaCodeFormValues = {
  password?: string;
  code: string;
};

//
//
const TwofaCodeForm = (props: Props) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values: TwofaCodeFormValues) => {
    try {
      const { onSubmitCode } = props;
      await onSubmitCode(values);
    } catch (err) {
      console.error(err);
    }
  };

  const { askForPassword, isLoading } = props;

  return (
    <div>
      <Form form={form} onFinish={handleSubmit} name="twofaCodeForm">
        {askForPassword && (
          <Form.Item
            label="Password"
            name="password"
            style={{ marginTop: '1em', marginBottom: '1em' }}
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input type="password" style={{ maxWidth: '240px' }} />
          </Form.Item>
        )}
        <Form.Item
          label="2FA Code"
          name="code"
          rules={[
            { required: true, message: 'Please enter code!' },
            { pattern: /^[0-9]{6}$/, message: 'Code has to be 6 digits long.' },
          ]}
        >
          <Input
            data-tid="twofa-input-code"
            autoFocus={askForPassword === false}
            placeholder="123 456"
            maxLength={6}
            style={{ maxWidth: '240px' }}
          />
        </Form.Item>

        <Form.Item>
          <MbxButton
            data-tid="twofa-input-submit"
            mbxType="primary"
            loading={isLoading}
            htmlType="submit"
          >
            Ok
          </MbxButton>
        </Form.Item>
      </Form>
    </div>
  );
};

TwofaCodeForm.defaultProps = {
  askForPassword: false,
};

export default TwofaCodeForm;
