import { schema, normalize } from 'normalizr';

import {
  RELEASECATEGORY_UPDATE,
  RELEASECATEGORY_UPDATE_OK,
  RELEASECATEGORY_UPDATE_FAIL,
  RELEASECATEGORY_DELETE,
  RELEASECATEGORY_DELETE_OK,
  RELEASECATEGORY_DELETE_FAIL,
  RELEASECATEGORY_BULK_DELETE,
  RELEASECATEGORY_BULK_DELETE_OK,
  RELEASECATEGORY_BULK_DELETE_FAIL,
} from '../constants/releaseCategories';
import { ReleaseCategorySchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';
import type { ReleaseCategoryNormalized } from '../reducers/ReleaseCategoryReducer';

//
//
export const clearReleaseCategoriesForRegion =
  (categoryId: number, regionId: number) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/releasecategories`,
        method: 'DELETE',
        params: {
          categoryId,
          regionId,
        },
      },
      dispatch,
      {
        init: RELEASECATEGORY_BULK_DELETE,
        fail: RELEASECATEGORY_BULK_DELETE_FAIL,
      }
    );
    dispatch({ type: RELEASECATEGORY_BULK_DELETE_OK, deletedIds: response.data });
    return true;
  };

//
//
export const deleteReleaseCategories =
  (releaseCategoryId: number) => async (dispatch: AppDispatch) => {
    await wrapFetch(
      {
        url: `/releasecategories/${releaseCategoryId}`,
        method: 'DELETE',
      },
      dispatch,
      {
        init: RELEASECATEGORY_DELETE,
        fail: RELEASECATEGORY_DELETE_FAIL,
      },
      204
    );
    dispatch({ type: RELEASECATEGORY_DELETE_OK, releaseCategoryId });
    return true;
  };

//
//
export const updateReleaseCategories =
  (appId: number, releaseCategories: Array<ReleaseCategoryNormalized>) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/releasecategories`,
        method: 'PUT',
        data: {
          appId,
          releaseCategories,
        },
      },
      dispatch,
      {
        init: RELEASECATEGORY_UPDATE,
        fail: RELEASECATEGORY_UPDATE_FAIL,
      }
    );
    const normalizedData = normalize(response.data, new schema.Array(ReleaseCategorySchema));
    dispatch({ type: RELEASECATEGORY_UPDATE_OK, payload: normalizedData });
    return response.data;
  };
