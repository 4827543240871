import { getDataFieldKeys } from './app';
import { getAcceptedUploadTypes, mimeTypeExceptions } from './upload';
import { ValidationError, IGNORE } from '../constants/batch-upload';
import { ContentType } from '../constants/content-type';
import { IMAGE_MIME_TYPES, VIDEO_MIME_TYPES } from '../constants/mime';
import {
  ResourceType,
  ResourceTypeNames,
  ResourceTypeShortNames,
} from '../constants/resource-type';

import type {
  Metadata,
  Metadatum,
  Cell,
  Issue,
  BatchUploadRecord,
  Language,
  TemplateConfiguration,
  ValidatedMetadatum,
  AdditionalInfo,
  CellError,
} from '../types/batch-upload';

export const formatFileName = ({
  app: { uid },
  resourceType,
  isCreate,
  languages,
}: TemplateConfiguration) => {
  return `${uid}_${
    resourceType != null ? ResourceTypeShortNames[resourceType] : ''
  }_${languages?.join('_')}_${isCreate === true ? 'create' : 'update'}_metadata.xlsx`;
};

export const getFileNames = <T>({ video, subtitles, thumbnail }: Language<T>) => [
  video,
  subtitles,
  thumbnail,
];

export const splitFilename = (value: string | null) => {
  const parts = (value ?? '').split('.');
  if (parts.length === 1) {
    return { fileExt: '', fileName: value };
  }
  return {
    fileExt: parts[parts.length - 1] ?? '',
    fileName: parts.slice(0, parts.length - 1).join('.'),
  };
};

export const getTotalFiles = (rows: Array<Metadatum<Cell>>) => {
  return rows
    .flatMap(({ languages }) => languages)
    .flatMap((language) => getFileNames<Cell>(language))
    .map(({ value }) => value)
    .filter((value): value is string => !!value && value !== IGNORE).length;
};

export const validAdditionalInfoColumns = (
  optionalColumns: AdditionalInfo<string>,
  dataFieldNames: AdditionalInfo<string>
) => {
  return getDataFieldKeys().every((key) => {
    const userColumn = optionalColumns[key];
    return userColumn === null || userColumn === dataFieldNames[key];
  });
};

/* shared between ui and api for unit tests */
export const setupRowValidation = (
  { isCreate, rows, optionalColumns }: Metadata,
  existing: Record<string, Array<string>>,
  existingLanguages: Array<string>,
  existingTags: Array<string>
) => {
  /* helpers */

  const setupCondition = (condition: (cell: Cell) => boolean) => {
    return (cell: Cell, name: string): Array<CellError> =>
      condition(cell) ? [{ name, cell }] : [];
  };
  const isActualValue = (cell: Cell): cell is { value: string; address: string } =>
    !!cell.value && cell.value !== IGNORE;
  const toIssue = (key: ValidationError, erroredCells: Array<CellError>): Array<Issue> => {
    return erroredCells.length > 0
      ? [
          {
            key,
            erroredCells,
          },
        ]
      : [];
  };
  const uniqueIssuesForRow = (issues: Array<Issue>): Array<Issue> => {
    return [...new Set(issues.map((i) => JSON.stringify(i)))].map((i) => JSON.parse(i));
  };
  const testLanguageFields =
    (condition: (c: Cell) => Array<Issue>, getFields: (m: Language<Cell>) => Array<Cell>) =>
    ({ languages }: Metadatum<Cell>) =>
      languages.flatMap(getFields).flatMap(condition);

  /* shared values */

  const existingNames = Object.keys(existing) ?? [];
  const newInternalNames: Array<Cell> = rows
    .map(({ internalName }) => internalName)
    .filter(isActualValue);
  const newFileNames = rows
    .flatMap(({ languages }) => languages)
    .flatMap(getFileNames)
    .filter(isActualValue);

  /* tests */

  const testAdditionalInfoFields = (): ((m: Metadatum<Cell>) => Array<Issue>) => {
    return ({ languages }: Metadatum<Cell>) => {
      return languages.some(({ additionalInfo }) => {
        return getDataFieldKeys().some((key) => {
          return optionalColumns[key] === null && additionalInfo?.[key] != null;
        });
      })
        ? [
            {
              key: ValidationError.SHEET_TEMPLATE_MODIFIED,
              erroredCells: [],
            },
          ]
        : [];
    };
  };

  const testMandatoryFieldBlank = () => {
    const test = setupCondition(({ value }) => !value);
    return ({ internalName, resourceType, languages }: Metadatum<Cell>) => {
      const erroredCells: Array<CellError> = [];
      erroredCells.push(...test(internalName, 'internalName'));

      if (isCreate === true && resourceType?.value == null) {
        if (resourceType == null) {
          // can this actually happen?!
          throw new Error('resourceType should not be null');
        }
        erroredCells.push({ cell: resourceType, name: 'resourceType' });
      }

      erroredCells.push(
        ...languages.flatMap(({ title, video }) => [
          ...test(title, 'title'),
          ...test(video, 'video'),
        ])
      );

      return toIssue(ValidationError.MANDATORY_FIELD_BLANK, erroredCells);
    };
  };

  const testMandatoryFieldMarkedWithIgnore = () => {
    const test = setupCondition(({ value }) => value === IGNORE);
    return ({ internalName, resourceType, languages }: Metadatum<Cell>) => {
      const erroredCells: Array<CellError> = [];
      erroredCells.push(...test(internalName, 'internalName'));

      if (isCreate === true && resourceType?.value === IGNORE) {
        erroredCells.push({ cell: resourceType, name: 'resourceType' });
      }

      erroredCells.push(
        ...languages.flatMap(({ title, video }) => [
          ...test(title, 'title'),
          ...test(video, 'video'),
        ])
      );
      return toIssue(ValidationError.MANDATORY_FIELD_MARKED_WITH_IGNORE, erroredCells);
    };
  };

  const testInternalNameUsedMultipleTimes =
    () =>
    ({ internalName }: Metadatum<Cell>) => {
      const sameInternalName = newInternalNames.filter(({ value }) => value === internalName.value);
      return toIssue(
        ValidationError.INTERNAL_NAME_USED_MULTIPLE_TIMES,
        isCreate === true && isActualValue(internalName) && sameInternalName.length > 1
          ? sameInternalName.map((cell) => ({ cell, name: 'internalName' }))
          : []
      );
    };

  const testInternalNameAlreadyExists = () => {
    const test = setupCondition(
      (cell) => !!isCreate && isActualValue(cell) && existingNames.includes(cell.value)
    );
    return ({ internalName }: Metadatum<Cell>) =>
      toIssue(ValidationError.INTERNAL_NAME_ALREADY_EXISTS, test(internalName, 'internalName'));
  };

  const testLanguageVersionAlreadyExists =
    () =>
    ({ internalName, languages }: Metadatum<Cell>) => {
      const existingLanguageVersions =
        isCreate || !internalName.value ? [] : existing[internalName.value] ?? [];

      return toIssue(
        ValidationError.LANGUAGE_VERSION_ALREADY_EXISTS,
        isCreate === true
          ? []
          : languages
              .map(({ locale: { value }, title: { address } }) => {
                return { value, address };
              })
              .filter(({ value }) => value != null && existingLanguageVersions.includes(value))
              .map((cell) => ({ cell, name: cell.value ?? '??' }))
      );
    };

  const testInternalNameDoesNotExist = () => {
    const test = setupCondition(
      (cell) => !isCreate && isActualValue(cell) && !existingNames.includes(cell.value)
    );
    return ({ internalName }: Metadatum<Cell>) =>
      toIssue(ValidationError.INTERNAL_NAME_DOES_NOT_EXIST, test(internalName, 'internalName'));
  };

  const testOptionalFieldBlank = () => {
    const test = setupCondition(({ value }) => !value);
    return ({ tags, languages }: Metadatum<Cell>) =>
      toIssue(ValidationError.OPTIONAL_FIELD_BLANK, [
        ...test(tags, 'tags'),
        ...languages.flatMap(({ thumbnail, subtitles, additionalInfo }) => {
          const additionalInfoMatches = getDataFieldKeys().flatMap((key) => {
            const entry = additionalInfo?.[key] ?? null;
            return optionalColumns[key] !== null && entry !== null ? test(entry, key) : [];
          });

          return [
            ...test(thumbnail, 'thumbnail'),
            ...test(subtitles, 'subtitles'),
            ...additionalInfoMatches,
          ];
        }),
      ]);
  };

  const testFileNameUsedMultipleTimes = () => {
    const fileNameNoted = new Set();
    return testLanguageFields((fileName) => {
      const { value: nameInQuestion } = fileName;

      if (fileNameNoted.has(nameInQuestion) || !isActualValue(fileName)) {
        return [];
      }

      const erroredCells = newFileNames
        .filter(({ value }) => value === nameInQuestion)
        .map((cell) => ({ cell, name: cell.value }));

      fileNameNoted.add(nameInQuestion);

      return erroredCells.length > 1
        ? [
            {
              key: ValidationError.FILE_NAME_USED_MULTIPLE_TIMES,
              erroredCells,
            },
          ]
        : [];
    }, getFileNames);
  };

  const testContentFileTypeMismatch = () => {
    const acceptedVideoTypes =
      getAcceptedUploadTypes(ContentType.EPISODE_CONTENT, ResourceType.VIDEO_MEDIABOX) ?? [];
    const acceptedThumbnailTypes =
      getAcceptedUploadTypes(ContentType.EPISODE_THUMBNAIL, ResourceType.IMAGE_MEDIABOX) ?? [];
    const acceptedSubtitleTypes = mimeTypeExceptions[0].acceptedExtensions;

    const testMime =
      (acceptedTypes: Array<string>, mapping: Record<string, string>) => (cell: Cell) => {
        if (!isActualValue(cell)) {
          return false;
        }

        const { fileExt: extension } = splitFilename(cell.value);

        if (!extension || !Object.keys(mapping).includes(extension)) {
          return true;
        }
        const mime = mapping[extension as keyof typeof mapping];
        return !acceptedTypes.includes(mime);
      };

    const testVideo = setupCondition(testMime(acceptedVideoTypes, VIDEO_MIME_TYPES));
    const testThumbnail = setupCondition(testMime(acceptedThumbnailTypes, IMAGE_MIME_TYPES));
    const testSubtitles = setupCondition(
      (cell) =>
        isActualValue(cell) && !acceptedSubtitleTypes.includes(splitFilename(cell.value).fileExt)
    );

    return ({ languages }: Metadatum<Cell>) =>
      languages.flatMap(({ video, thumbnail, subtitles }) =>
        toIssue(ValidationError.FILE_CONTENT_TYPE_MISMATCH, [
          ...testVideo(video, 'video'),
          ...testThumbnail(thumbnail, 'thumbnail'),
          ...testSubtitles(subtitles, 'subtitles'),
        ])
      );
  };

  const testLanguageNotFound = () => {
    const test = setupCondition(({ value }) => !value || !existingLanguages.includes(value));
    const uniqueLanguages = new Set();
    const isUnique = (name: string | null) =>
      !uniqueLanguages.has(name) && uniqueLanguages.add(name);

    return testLanguageFields(
      (name) => toIssue(ValidationError.LANGUAGE_NOT_FOUND, test(name, 'language')),
      ({ locale }) => (isUnique(locale.value) ? [locale] : [])
    );
  };

  const testTagsNotFound = () => {
    const test = setupCondition((cell) => {
      return isActualValue(cell)
        ? !cell.value
            .split(',')
            .map((tag) => tag.trim())
            .every((tag) => existingTags.includes(tag))
        : false;
    });

    return ({ tags }: Metadatum<Cell>) =>
      toIssue(ValidationError.TAG_NOT_FOUND, test(tags, 'tags'));
  };

  const testInvalidResourceType =
    () =>
    ({ resourceType }: Metadatum<Cell>) => {
      const erroredCells: Array<CellError> = [];

      if (
        isCreate === true &&
        (resourceType == null || resourceType.value !== ResourceTypeNames[201])
      ) {
        if (resourceType == null) {
          // can this actually happen?!
          throw new Error('resourceType should not be null');
        }
        erroredCells.push({ cell: resourceType, name: 'resourceType' });
      }

      return toIssue(ValidationError.RESOURCE_TYPE_NOT_FOUND, erroredCells);
    };

  const suite = [
    testAdditionalInfoFields,
    testMandatoryFieldBlank,
    testMandatoryFieldMarkedWithIgnore,
    testInternalNameUsedMultipleTimes,
    testInternalNameAlreadyExists,
    testLanguageNotFound,
    testLanguageVersionAlreadyExists,
    testInternalNameDoesNotExist,
    testOptionalFieldBlank,
    testFileNameUsedMultipleTimes,
    testContentFileTypeMismatch,
    testTagsNotFound,
    testInvalidResourceType,
  ].map((test) => test());

  return (row: Metadatum<Cell>) => uniqueIssuesForRow(suite.flatMap((test) => test(row)));
};

const cellToValue = ({ value }: Cell) => (value === IGNORE || value === null ? null : value);

export const toRecord = ({
  internalName,
  resourceType,
  tags,
  languages,
}: ValidatedMetadatum): BatchUploadRecord => {
  return {
    internalName: internalName.value,
    resourceType: resourceType?.value ?? null,
    tags: cellToValue(tags),
    languages: languages.map(({ locale, title, thumbnail, video, subtitles, additionalInfo }) => {
      return {
        locale: cellToValue(locale),
        title: title.value,
        thumbnail: cellToValue(thumbnail),
        video: cellToValue(video),
        subtitles: cellToValue(subtitles),
        additionalInfo: {
          data1: additionalInfo?.data1 != null ? cellToValue(additionalInfo.data1) : null,
          data2: additionalInfo?.data2 != null ? cellToValue(additionalInfo.data2) : null,
          data3: additionalInfo?.data3 != null ? cellToValue(additionalInfo.data3) : null,
          data4: additionalInfo?.data4 != null ? cellToValue(additionalInfo.data4) : null,
          data5: additionalInfo?.data5 != null ? cellToValue(additionalInfo.data5) : null,
        },
      };
    }),
  };
};

export const sortByPartNumber = (
  { PartNumber: a }: { PartNumber?: number },
  { PartNumber: b }: { PartNumber?: number }
) => (a ?? 0) - (b ?? 0);

export const getPartNumbers = (totalParts: number) =>
  Array.from(Array(totalParts).keys()).map((n) => n + 1);
