import { Select, Modal, Switch, Form } from 'antd';
import { useEffect } from 'react';

import MbxButton from './base/MbxButton';
import CountryTag from './CountryTag';
import styles from './LoginForm.module.scss';
import { selectFilterFunc } from '../utils/filter';

import type { Country } from '../reducers/CountryReducer';

type Props = Readonly<{
  what: 'country' | 'locale' | '';
  list: Array<{ id: number; shortcode: string; name: string }>;
  excludes: Array<number>;
  visible: boolean;
  onSubmit: (type: string, childId: number, isDraft: boolean) => Promise<boolean>;
  onClose: () => void;
}>;

//
//
const RegionAddCountryModal = (props: Props) => {
  const { onSubmit, what, onClose, visible, list, excludes = [] } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible === true) {
      form.resetFields();
    }
  }, [visible, form]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      console.log('Received values of form: ', values);
      const success = await onSubmit(what, values.addId, !!values.isDraft);
      if (success === true) {
        form.resetFields();
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (what === null) {
    return null;
  }

  let title = '';
  if (what === 'locale') {
    title = 'language';
  } else {
    title = what;
  }

  return (
    <Modal
      title={`Add ${title}`}
      centered
      open={visible}
      footer={null}
      // onOk={handleOk}
      onCancel={onClose}
    >
      <Form
        onFinish={handleOk}
        form={form}
        className={styles.loginForm}
        name="RegionAddCountryForm"
      >
        <Form.Item name="addId" rules={[{ required: true, message: 'Please select one!' }]}>
          <Select
            showSearch
            placeholder={`Select a ${title}`}
            filterOption={
              what === 'country'
                ? (input, option) => option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                : selectFilterFunc
            }
          >
            {list
              .filter((t) => !excludes.includes(t.id))
              .map((t) => (
                <Select.Option key={t.id} value={t.id} name={t.name}>
                  {what === 'country' ? (
                    <span>
                      <CountryTag regionCountry={{ country: t as Country }} />
                      {t.name}
                    </span>
                  ) : (
                    `[${t.shortcode}] ${t.name}`
                  )}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="is draft" name="isDraft" valuePropName="checked">
          <Switch disabled={what === 'locale' && excludes.length === 0} />
        </Form.Item>

        <Form.Item style={{ marginTop: '2em', marginBottom: 0, textAlign: 'right' }}>
          <MbxButton style={{ marginRight: 10 }} onClick={onClose} size="large">
            Cancel
          </MbxButton>
          <MbxButton mbxType="primary" htmlType="submit">
            Add
          </MbxButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RegionAddCountryModal;
