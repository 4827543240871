import { Button } from 'antd';

import LoginForm from '../components/LoginForm';

import type { LoginFormValues } from '../components/LoginForm';

type Props = Readonly<{
  forceEmail?: string | null;
  prefilledEmail?: string | null;
  login: (values: LoginFormValues) => Promise<void>;
  resendConfirmationEmail: (email: string) => Promise<void>;
  loginInfo: { isLoggingIn: boolean; errorMessage: string; data: { email: string } | null };
}>;

//
//
const Login = (props: Props) => {
  const { login, loginInfo, prefilledEmail, forceEmail, resendConfirmationEmail } = props;
  const { isLoggingIn, errorMessage, data } = loginInfo;

  return (
    <div data-tid="form-login-container">
      <LoginForm
        onSubmit={login}
        isLoggingIn={isLoggingIn}
        forceEmail={forceEmail}
        prefilledEmail={prefilledEmail}
      />
      {errorMessage !== '' && (
        <div data-tid="login-error-label" style={{ color: 'red' }}>
          {errorMessage}
        </div>
      )}
      {errorMessage === 'user_email_not_confirmed' && data?.email != null && (
        <div style={{ marginTop: '2em' }}>
          <Button
            onClick={() => {
              resendConfirmationEmail(data.email);
            }}
          >
            Resend confirmation-email
          </Button>
        </div>
      )}
    </div>
  );
};

export default Login;
