import { Form, Input, Modal } from 'antd';
import { useEffect } from 'react';

import type { AppVersion } from '../reducers/AppVersionReducer';

const { TextArea } = Input;

type Props = Readonly<{
  data: AppVersion | null;
  visible: boolean;
  onClose: () => void;
  onSubmit: (
    id: number | null | undefined,
    values: { name: string; description: string | null | undefined }
  ) => Promise<boolean>;
}>;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

//
//
const AppVersionAddModal = (props: Props) => {
  const { visible, data, onSubmit, onClose } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible === true) {
      form.setFieldsValue({
        name: data?.name ?? undefined,
        description: data?.description ?? undefined,
      });
    }
  }, [data, form, visible]);

  const handleOk = async () => {
    try {
      const values: { name: string; description: string | null | undefined } =
        await form.validateFields();
      console.log('Received values of form: ', values);
      const id = data?.id ?? null;
      await onSubmit(id, values);
    } catch (err) {
      console.error(err);
    }
  };

  const title = data !== null ? 'Edit a version' : 'Create a version';

  return (
    <Modal
      title={title}
      centered
      open={visible}
      okText={data !== null ? 'Save' : 'Create'}
      onOk={handleOk}
      onCancel={onClose}
    >
      <Form layout="horizontal" form={form} name="AppVersionAddModal">
        <Form.Item
          label="Name"
          {...formItemLayout}
          name="name"
          rules={[
            { required: true, message: 'Please give the version a name!' },
            {
              // prettier-ignore
              pattern: /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/,
              message: 'Please use semver',
            },
          ]}
        >
          <Input placeholder="1.1.1" />
        </Form.Item>
        <Form.Item label="Description" {...formItemLayout} name="description">
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AppVersionAddModal;
