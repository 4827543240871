import { createSelector } from 'reselect';

import { getAppPlatformByIdFunc } from './appPlatformSelectors';
import { getAppVersionByIdFunc } from './appVersionSelectors';
import { getEmptyAssetResourceVersionRequirement } from '../reducers/AssetResourceVersionRequirementReducer';

import type { RootState } from '../reducers';
import type {
  AssetResourceVersionRequirement,
  AssetResourceVersionRequirementState,
} from '../reducers/AssetResourceVersionRequirementReducer';
import type { Selector } from 'reselect';

//
//
const getByIdObject: Selector<RootState, AssetResourceVersionRequirementState> = (state) =>
  state.assetResourceVersionRequirements;

//
//
export const getAssetResourceVersionRequirementsByIdFunc = createSelector(
  getByIdObject,
  getAppPlatformByIdFunc,
  getAppVersionByIdFunc,
  //
  (byId, $getAppPlatformById, $getAppVersionById) => {
    return (id: number | null | undefined) => {
      if (id == null) {
        return null;
      }

      const evr = byId[id];
      if (evr == null) {
        return getEmptyAssetResourceVersionRequirement(id);
      }

      return {
        ...evr,
        appPlatform: $getAppPlatformById(evr.appPlatform),
        minVersion: $getAppVersionById(evr.minVersion),
        maxVersion: $getAppVersionById(evr.maxVersion),
      };
    };
  }
);

//
//
export const getAssetResourceVersionRequirementsByIdsFunc = createSelector(
  getAssetResourceVersionRequirementsByIdFunc,
  ($getAssetResourceVersionRequirementsById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }

    return ids
      .map((id) => $getAssetResourceVersionRequirementsById(id))
      .filter((arvr): arvr is AssetResourceVersionRequirement => !!arvr);
  }
);
