import { ROUTES } from './routes';
import { getSitemapFlat } from './utils';
import AppCreateContainer from '../containers/AppCreateContainer';
import AppDetailContainer from '../containers/AppDetailContainer';
import AppSettingsContainer from '../containers/AppSettingsContainer';
import AppsListContainer from '../containers/AppsListContainer';
import AppUserDetailContainer from '../containers/AppUserDetailContainer';
import AssetCreateContainer from '../containers/AssetCreateContainer';
import AssetDetailContainer from '../containers/AssetDetailContainer';
import AssetListContainer from '../containers/AssetListContainer';
import CategoryListContainer from '../containers/CategoryListContainer';
import CategorySortContainer from '../containers/CategorySortContainer';
import CheckLoginContainer from '../containers/CheckLoginContainer';
import DeploymentCreateContainer from '../containers/DeploymentCreateContainer';
import DeploymentDetailContainer from '../containers/DeploymentDetailContainer';
import DeploymentListContainer from '../containers/DeploymentListContainer';
import EmailConfirmationContainer from '../containers/EmailConfirmationContainer';
import EpisodeCreateContainer from '../containers/EpisodeCreateContainer';
import EpisodeDetailContainer from '../containers/EpisodeDetailContainer';
import EpisodeListContainer from '../containers/EpisodeListContainer';
import JobDetailContainer from '../containers/JobDetailContainer';
import JobListContainer from '../containers/JobListContainer';
import LoginContainer from '../containers/LoginContainer';
import LoginTwofaContainer from '../containers/LoginTwofaContainer';
import PasswordForgotContainer from '../containers/PasswordForgotContainer';
import PasswordResetContainer from '../containers/PasswordResetContainer';
import Profile2faSetupContainer from '../containers/Profile2faSetupContainer';
import ProfileContainer from '../containers/ProfileContainer';
import RegionCreateContainer from '../containers/RegionCreateContainer';
import RegionDetailContainer from '../containers/RegionDetailContainer';
import RegionListContainer from '../containers/RegionListContainer';
import ReleaseCreateContainer from '../containers/ReleaseCreateContainer';
import ReleaseDetailContainer from '../containers/ReleaseDetailContainer';
import ReleaseListContainer from '../containers/ReleaseListContainer';
import RoleListContainer from '../containers/RoleListContainer';
import UserDetailContainer from '../containers/UserDetailContainer';
import UserInviteConfirmationContainer from '../containers/UserInviteConfirmationContainer';
import UserInviteContainer from '../containers/UserInviteContainer';
import UserListContainer from '../containers/UserListContainer';
import AppAnalytics from '../pages/AppAnalytics';
import AppAnalyticsMaus from '../pages/AppAnalyticsMaus';
import AppAnalyticsViews from '../pages/AppAnalyticsViews';
import AppHome from '../pages/AppHome';
import AppPlatformDetail from '../pages/AppPlatformDetail';
import AuthServiceList from '../pages/AuthServiceList';
import BatchUploadCreate from '../pages/BatchUploadCreate';
import Home from '../pages/Home';
import PageImprint from '../pages/PageImprint';
import PlatformList from '../pages/PlatformList';
import RegisterPage from '../pages/RegisterPage';
import TestPage from '../pages/TestPage';

import type { RouteData } from './routes';
import type { ComponentType } from 'react';

//
//
export type RouteInfo = {
  data: RouteData;
  Component: ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  secure?: boolean;
  needsAdmin?: boolean;
  appSpecific?: boolean;
  routes?: Array<RouteInfo>;
  include?: boolean;
  requiredAppRights?: Array<string>;
  restrictAccessThroughAppSettings?: string;
};

export type FlatRoute = {
  data: RouteData;
  Component: ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  secure: boolean;
  appSpecific: boolean;
  needsAdmin: boolean;
  requiredAppRights: Array<string>;
  restrictAccessThroughAppSettings: string | null;
};

//
//
export const SITEMAP: Array<RouteInfo> = [
  {
    data: ROUTES.register,
    Component: RegisterPage,
  },
  {
    data: ROUTES.emailConfirmation,
    Component: EmailConfirmationContainer,
  },
  {
    data: ROUTES.inviteConfirmation,
    Component: UserInviteConfirmationContainer,
  },
  {
    data: ROUTES.passwordForgot,
    Component: PasswordForgotContainer,
  },
  {
    data: ROUTES.passwordReset,
    Component: PasswordResetContainer,
  },
  {
    data: ROUTES.home,
    Component: Home,
    secure: true,
    routes: [
      {
        data: ROUTES.checkLogin,
        Component: CheckLoginContainer,
      },
      {
        data: ROUTES.login,
        Component: LoginContainer,
      },
      {
        data: ROUTES.loginTwofa,
        Component: LoginTwofaContainer,
      },
      {
        data: ROUTES.profile,
        Component: ProfileContainer,
        secure: true,
        routes: [
          {
            data: ROUTES.profile2faSetup,
            Component: Profile2faSetupContainer,
            secure: true,
          },
        ],
      },
      {
        data: ROUTES.imprint,
        Component: PageImprint,
        secure: false,
      },
      {
        data: ROUTES.testPage,
        Component: TestPage,
        secure: true,
      },
      {
        data: ROUTES.apps,
        Component: AppsListContainer,
        secure: true,
        needsAdmin: true,
        routes: [
          {
            data: ROUTES.appCreate,
            Component: AppCreateContainer,
            secure: true,
            needsAdmin: true,
          },
          {
            data: ROUTES.appDetail,
            Component: AppDetailContainer,
            secure: true,
            needsAdmin: true,
            routes: [
              {
                data: ROUTES.appPlatformDetail,
                Component: AppPlatformDetail,
                secure: true,
                needsAdmin: true,
              },
            ],
          },
        ],
      },
      {
        data: ROUTES.platforms,
        Component: PlatformList,
        secure: true,
        needsAdmin: true,
      },
      {
        data: ROUTES.users,
        Component: UserListContainer,
        secure: true,
        needsAdmin: true,
        routes: [
          {
            data: ROUTES.userRoles,
            Component: RoleListContainer,
            secure: true,
            needsAdmin: true,
          },
          {
            data: ROUTES.userInvite,
            Component: UserInviteContainer,
            secure: true,
            needsAdmin: true,
          },
          {
            data: ROUTES.userDetail,
            Component: UserDetailContainer,
            secure: true,
            needsAdmin: true,
          },
        ],
      },
      {
        data: ROUTES.jobs,
        Component: JobListContainer,
        secure: true,
        needsAdmin: true,
        routes: [
          {
            data: ROUTES.jobDetail,
            Component: JobDetailContainer,
            secure: true,
            needsAdmin: true,
          },
        ],
      },
      {
        data: ROUTES.authServices,
        Component: AuthServiceList,
        secure: true,
        needsAdmin: true,
      },
      {
        data: ROUTES.appHome,
        Component: AppHome,
        secure: true,
        appSpecific: true,
        routes: [
          {
            data: ROUTES.appSettings,
            Component: AppSettingsContainer,
            secure: true,
            appSpecific: true,
            include: process.env.REACT_APP_SETTINGS === 'true',
            routes: [
              {
                data: ROUTES.appRoles,
                Component: RoleListContainer,
                secure: true,
                appSpecific: true,
                // requiredAppRights: [UserRight.ROLES_GET_LIST],
                restrictAccessThroughAppSettings: `adminData.displayRoles`,
              },
              {
                data: ROUTES.appUserInvite,
                Component: UserInviteContainer,
                secure: true,
                appSpecific: true,
                // requiredAppRights: [UserRight.USERS_INVITE_POST],
                restrictAccessThroughAppSettings: `adminData.displayRoles`,
              },
              {
                data: ROUTES.appUserDetail,
                Component: AppUserDetailContainer,
                secure: true,
                appSpecific: true,
                restrictAccessThroughAppSettings: `adminData.displayRoles`,
              },
            ],
          },
          {
            data: ROUTES.assets,
            Component: AssetListContainer,
            secure: true,
            appSpecific: true,
            routes: [
              {
                data: ROUTES.assetCreate,
                Component: AssetCreateContainer,
                secure: true,
                appSpecific: true,
              },
              {
                data: ROUTES.assetDetail,
                Component: AssetDetailContainer,
                secure: true,
                appSpecific: true,
              },
            ],
          },
          {
            data: ROUTES.regions,
            Component: RegionListContainer,
            secure: true,
            appSpecific: true,
            routes: [
              {
                data: ROUTES.regionCreate,
                Component: RegionCreateContainer,
                secure: true,
                appSpecific: true,
              },
              {
                data: ROUTES.regionDetail,
                Component: RegionDetailContainer,
                secure: true,
                appSpecific: true,
              },
            ],
          },
          {
            data: ROUTES.batchUploadCreate,
            Component: BatchUploadCreate,
            secure: true,
            appSpecific: true,
          },
          {
            data: ROUTES.episodes,
            Component: EpisodeListContainer,
            secure: true,
            appSpecific: true,
            routes: [
              {
                data: ROUTES.episodeCreate,
                Component: EpisodeCreateContainer,
                secure: true,
                appSpecific: true,
              },
              {
                data: ROUTES.episodeDetail,
                Component: EpisodeDetailContainer,
                secure: true,
                appSpecific: true,
              },
            ],
          },
          {
            data: ROUTES.releases,
            Component: ReleaseListContainer,
            secure: true,
            appSpecific: true,
            routes: [
              {
                data: ROUTES.releaseCreate,
                Component: ReleaseCreateContainer,
                secure: true,
                appSpecific: true,
              },
              {
                data: ROUTES.releaseDetail,
                Component: ReleaseDetailContainer,
                secure: true,
                appSpecific: true,
              },
            ],
          },
          {
            data: ROUTES.categories,
            Component: CategoryListContainer,
            secure: true,
            appSpecific: true,
            routes: [
              {
                data: ROUTES.categorySort,
                Component: CategorySortContainer,
                secure: true,
                appSpecific: true,
              },
            ],
          },
          {
            data: ROUTES.deployments,
            Component: DeploymentListContainer,
            secure: true,
            appSpecific: true,
            routes: [
              {
                data: ROUTES.deploymentCreate,
                Component: DeploymentCreateContainer,
                secure: true,
                appSpecific: true,
              },
              {
                data: ROUTES.deploymentDetail,
                Component: DeploymentDetailContainer,
                secure: true,
                appSpecific: true,
              },
            ],
          },
          {
            data: ROUTES.appAnalytics,
            Component: AppAnalytics,
            secure: true,
            appSpecific: true,
            routes: [
              {
                data: ROUTES.appAnalyticsViewsReports,
                Component: AppAnalyticsViews,
                secure: true,
                appSpecific: true,
              },
              {
                data: ROUTES.appAnalyticsMausReports,
                Component: AppAnalyticsMaus,
                secure: true,
                appSpecific: true,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const SITEMAP_FLAT = getSitemapFlat(SITEMAP);
