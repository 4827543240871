import { CLIENT_TIMEZONE } from '../utils/date';
import { displayDateInDifferentTimezone } from '../utils/timezone';

type Props = Readonly<{
  time: Date | null;
  selectedTimezone: string | null;
}>;

//
//
const ClientTimezoneLabel = (props: Props) => {
  const { selectedTimezone, time } = props;

  if (selectedTimezone === CLIENT_TIMEZONE || time == null) {
    return null;
  }

  return (
    <span
      style={{ whiteSpace: 'nowrap', fontSize: '11px' }}
    >{`Your time (${CLIENT_TIMEZONE}): ${displayDateInDifferentTimezone(
      time,
      CLIENT_TIMEZONE
    )}`}</span>
  );
};

export default ClientTimezoneLabel;
