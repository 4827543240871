import { message } from 'antd';
import { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import { loginTwofa, resetTwofa } from '../actions/auth';
import { ROUTE_NAME_LOGIN } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import LoginTwofa from '../pages/LoginTwofa';
import { getAuth, isUserLoggedIn } from '../selectors/authSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { TwofaCodeFormValues } from '../components/TwofaCodeForm';

//
//
const LoginTwofaContainer = () => {
  const history = useHistory<{ referrer?: string }>();
  const dispatch = useAppDispatch();

  const auth = useGlobalSelector(getAuth);
  const userLoggedIn = useGlobalSelector(isUserLoggedIn);
  const [loginInfo, setLoginInfo] = useState({ isLoggingIn: false, errorMessage: '' });

  const handleCodeSubmit = async (values: TwofaCodeFormValues) => {
    try {
      setLoginInfo({ isLoggingIn: true, errorMessage: '' });
      const { userData } = auth.twofa ?? {};
      await dispatch(loginTwofa(userData?.accessToken ?? '', values.code));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
      setLoginInfo({ isLoggingIn: false, errorMessage: msg });
    }
  };

  const handleReset = () => {
    dispatch(resetTwofa());
  };

  if (userLoggedIn) {
    const from = history.location.state?.referrer ?? '/';
    return <Redirect to={from} />;
  }

  if (auth.twofa === null) {
    return <Redirect to={getUrlByName(ROUTE_NAME_LOGIN)} />;
  }

  return (
    <LoginTwofa
      login={handleCodeSubmit}
      resetTwofa={handleReset}
      loginInfo={loginInfo}
      twofaData={auth.twofa}
    />
  );
};

export default LoginTwofaContainer;
