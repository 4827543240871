import { createSelector } from 'reselect';

import { getEmptyTimezone } from '../reducers/TimezoneReducer';

import type { RootState } from '../reducers';
import type { Timezone } from '../reducers/TimezoneReducer';

//
//
const getById = (state: RootState) => state.timezones.byId;
const getAllIds = (state: RootState) => state.timezones.allIds;

//
//
export const getTimezoneByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => {
    const timezone = byId[id];
    if (timezone == null) {
      return getEmptyTimezone(id);
    }
    return timezone;
  }
);

//
//
export const getAllTimezones = createSelector(
  getAllIds,
  getTimezoneByIdFunc,
  //
  (allIds, getTimezoneById) =>
    allIds.map((id) => getTimezoneById(id)).filter((tz): tz is Timezone => !!tz)
);
