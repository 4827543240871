import { schema, normalize } from 'normalizr';

import {
  APPPLATFORMS_CREATE,
  APPPLATFORMS_CREATE_OK,
  APPPLATFORMS_CREATE_FAIL,
  APPPLATFORMS_DELETE,
  APPPLATFORMS_DELETE_OK,
  APPPLATFORMS_DELETE_FAIL,
  APPPLATFORMS_GET_LIST,
  APPPLATFORMS_GET_LIST_OK,
  APPPLATFORMS_GET_LIST_FAIL,
} from '../constants/appplatforms';
import { AppPlatformSchema } from '../schemas';
import { getAppPlatformById } from '../selectors/appPlatformSelectors';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';
import type { GlobalStateGetter } from '../reducers';

//
//
export const createAppPlatform =
  (appId: number, platformId: number) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/appplatforms`,
        method: 'POST',
        data: {
          appId,
          platformId,
        },
      },
      dispatch,
      {
        init: APPPLATFORMS_CREATE,
        fail: APPPLATFORMS_CREATE_FAIL,
      },
      201
    );
    const normalizedData = normalize(response.data, AppPlatformSchema);
    dispatch({ type: APPPLATFORMS_CREATE_OK, payload: normalizedData, appId });
  };

//
//
export const deleteAppPlatform =
  (appId: number, appPlatformId: number) =>
  async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    await wrapFetch(
      {
        url: `/appplatforms/${appPlatformId}`,
        method: 'DELETE',
      },
      dispatch,
      {
        init: APPPLATFORMS_DELETE,
        fail: APPPLATFORMS_DELETE_FAIL,
      },
      204
    );
    const deletedAppPlatform = getAppPlatformById(getState(), appPlatformId);
    dispatch({ type: APPPLATFORMS_DELETE_OK, appId, appPlatformId, deletedAppPlatform });
  };

//
//
export const fetchAppPlatformList = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/appplatforms`,
      method: 'GET',
      params: {
        appId,
      },
    },
    dispatch,
    {
      init: APPPLATFORMS_GET_LIST,
      fail: APPPLATFORMS_GET_LIST_FAIL,
    }
  );
  const normalizedData = normalize(response.data, new schema.Array(AppPlatformSchema));
  dispatch({ type: APPPLATFORMS_GET_LIST_OK, payload: normalizedData, appId });
};
