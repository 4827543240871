import { createSelector } from 'reselect';

import type { RootState } from '../../reducers';
import type {
  AppAuthServiceNormalized,
  AppAuthServiceStateById,
  AppAuthServiceStateAllIds,
} from '../../reducers/AppAuthServiceReducer';

//
//
export const appAuthServicesById = (state: RootState): AppAuthServiceStateById =>
  state.appAuthServices.byId;
export const appAuthServicesAllIds = (state: RootState): AppAuthServiceStateAllIds =>
  state.appAuthServices.allIds;

//
//
export const getAppAuthServiceNormalizedByIdFunc = createSelector(
  appAuthServicesById,
  //
  (byId) => (appAuthServiceId: number) => {
    const aas = byId[appAuthServiceId];
    if (aas == null) {
      return null;
    }
    return aas;
  }
);

//
//
export const getAllAppAuthServicesNormalizedForAppFunc = createSelector(
  appAuthServicesAllIds,
  getAppAuthServiceNormalizedByIdFunc,
  //
  (allIds, $getAppAuthServiceNormalizedById) => (appId: number) => {
    const ids = allIds[appId];
    if (!Array.isArray(ids)) {
      return [];
    }
    const appAuthServices = ids
      .map((id) => $getAppAuthServiceNormalizedById(id))
      .filter((aasn): aasn is AppAuthServiceNormalized => !!aasn);
    return appAuthServices;
  }
);
