export const RELEASE_GET_LIST = 'RELEASE_GET_LIST';
export const RELEASE_GET_LIST_OK = 'RELEASE_GET_LIST_OK';
export const RELEASE_GET_LIST_FAIL = 'RELEASE_GET_LIST_FAIL';

export const RELEASE_GET_DETAILS = 'RELEASE_GET_DETAILS';
export const RELEASE_GET_DETAILS_OK = 'RELEASE_GET_DETAILS_OK';
export const RELEASE_GET_DETAILS_FAIL = 'RELEASE_GET_DETAILS_FAIL';

export const RELEASE_CREATE = 'RELEASE_CREATE';
export const RELEASE_CREATE_OK = 'RELEASE_CREATE_OK';
export const RELEASE_CREATE_FAIL = 'RELEASE_CREATE_FAIL';

export const RELEASE_UPDATE = 'RELEASE_UPDATE';
export const RELEASE_UPDATE_OK = 'RELEASE_UPDATE_OK';
export const RELEASE_UPDATE_FAIL = 'RELEASE_UPDATE_FAIL';

export const RELEASE_REMOVE = 'RELEASE_REMOVE';
export const RELEASE_REMOVE_OK = 'RELEASE_REMOVE_OK';
export const RELEASE_REMOVE_FAIL = 'RELEASE_REMOVE_FAIL';
