import { combineReducers } from 'redux';

import { getEmptyUser } from './UsersReducer';
import {
  DEPLOYMENT_GET_LIST_OK,
  DEPLOYMENT_GET_DETAILS_OK,
  DEPLOYMENT_GET_PREVIOUS_OK,
  DEPLOYMENT_CREATE,
  DEPLOYMENT_CREATE_OK,
  DEPLOYMENT_CREATE_FAIL,
  DEPLOYMENT_GET_LATEST_OK,
  DEPLOYMENT_REMOVE_OK,
  DEPLOYMENT_FETCH_DATA,
  DEPLOYMENT_FETCH_DATA_OK,
  DEPLOYMENT_FETCH_DATA_FAIL,
  DEPLOYMENT_FETCH_DATA_COMPLETE,
  DEPLOYMENT_FETCH_DATA_RESET,
  DEPLOYMENT_CALC_DIFF,
  DEPLOYMENT_CALC_DIFF_OK,
  DEPLOYMENT_CALC_DIFF_CANCELED,
} from '../constants/deployments';

import type {
  Deployment,
  DeploymentAllIdsState,
  DeploymentByIdState,
  DeploymentDiffState,
  DeploymentFetchData,
} from '../../typings/deployments';
import type { AnyAction } from 'redux';

//
//
export const getEmptyDeployment = (id?: number): Deployment => ({
  id: id ?? -1,
  appId: -1,
  createdAt: new Date(),
  creator: getEmptyUser(),
  updatedAt: null,
  content: { 'app.json': { regions: [] } },
});

const initialStateById: DeploymentByIdState = {};
const initialStateAllIds: DeploymentAllIdsState = {};
const initialStateNewDeploymentData: DeploymentFetchData = {
  appId: -1,
  latest: null,
  startCreatingDeployment: new Date(),
  error: null,
  isLoading: false,
  loadingPercent: 0,
  isSaving: false,
  content: { 'app.json': { regions: [] } },
};

const initialStateDeploymentDiff: DeploymentDiffState = {
  isCalculatingDiff: false,
  diff: null,
};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): DeploymentByIdState => {
  switch (action.type) {
    case DEPLOYMENT_GET_LIST_OK: {
      const newState: DeploymentByIdState = {};
      action.payload.result.forEach((id: string) => {
        newState[id] = {
          ...(state[id] ?? {}),
          ...action.payload.entities.deployments[id],
        };
      });
      return newState;
    }

    case DEPLOYMENT_CREATE_OK:
    case DEPLOYMENT_GET_DETAILS_OK:
    case DEPLOYMENT_GET_PREVIOUS_OK: {
      const id = action.payload.result;
      return {
        ...state,
        [id]: { ...(state[id] ?? {}), ...action.payload.entities.deployments[id] },
      };
    }

    case DEPLOYMENT_REMOVE_OK: {
      const { [action.deployment.id]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    default:
      return state;
  }
};

//
//
// eslint-disable-next-line default-param-last
const allIds = (state = initialStateAllIds, action: AnyAction): DeploymentAllIdsState => {
  switch (action.type) {
    case DEPLOYMENT_GET_LIST_OK:
      return {
        ...state,
        [action.appId]: action.payload.result,
      };

    case DEPLOYMENT_CREATE_OK: {
      return {
        ...state,
        [action.appId]: [...(state[action.appId] ?? []), action.payload.result],
      };
    }

    case DEPLOYMENT_REMOVE_OK:
      return {
        ...state,
        [action.deployment.appId]: state[action.deployment.appId].filter(
          (deploymentId) => deploymentId !== action.deployment.id
        ),
      };

    default:
      return state;
  }
};

//
//
// eslint-disable-next-line default-param-last
const newDeploymentData = (
  state = initialStateNewDeploymentData,
  action: AnyAction
): DeploymentFetchData => {
  switch (action.type) {
    case DEPLOYMENT_FETCH_DATA:
      return {
        ...state,
        appId: action.appId,
        loadingPercent: 0,
        content: action.content,
        startCreatingDeployment: new Date(),
        error: null,
        isLoading: true,
        isSaving: false,
      };

    case DEPLOYMENT_GET_LATEST_OK:
      return {
        ...state,
        latest: action.payload,
      };

    case DEPLOYMENT_FETCH_DATA_OK:
      return {
        ...state,
        loadingPercent: action.percent,
        content: {
          ...state.content,
          ...action.payload,
        },
      };

    case DEPLOYMENT_FETCH_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DEPLOYMENT_FETCH_DATA_COMPLETE:
      return {
        ...state,
        isLoading: false,
      };

    case DEPLOYMENT_FETCH_DATA_RESET:
      return { ...initialStateNewDeploymentData };

    case DEPLOYMENT_CREATE:
      return {
        ...state,
        isSaving: true,
      };

    case DEPLOYMENT_CREATE_OK:
    case DEPLOYMENT_CREATE_FAIL:
      return {
        ...state,
        isSaving: false,
      };

    default:
      return state;
  }
};

//
//
// eslint-disable-next-line default-param-last
const deploymentDiff = (
  state = initialStateDeploymentDiff,
  action: AnyAction
): DeploymentDiffState => {
  switch (action.type) {
    case DEPLOYMENT_CALC_DIFF:
      return {
        ...state,
        isCalculatingDiff: true,
        diff: null,
      };

    case DEPLOYMENT_CALC_DIFF_OK:
      return {
        ...state,
        isCalculatingDiff: false,
        diff: action.payload,
      };

    case DEPLOYMENT_CALC_DIFF_CANCELED:
      return {
        ...state,
        isCalculatingDiff: false,
        diff: null,
      };

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
  newDeploymentData,
  deploymentDiff,
});

export default combined;
