import { Form, Input, InputNumber, Checkbox } from 'antd';
import { useEffect } from 'react';

import styles from './AppEditForm.module.scss';
import MbxButton from './base/MbxButton';
import { APP_CONFIG_FALLBACK_VALUES } from '../common/constants/app';

import type { Configuration, ConfigurationGroup, ConfigurationItem } from '../common/types/app';

type Props = Readonly<{
  title?: string;
  data: Configuration;
  appOnly?: boolean;
  onUpdateConfig: (config: Configuration) => Promise<void>;
  marginTop?: string;
}>;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

//
//
const ConfigurationForm = (props: Props) => {
  const { data, title, appOnly = false, onUpdateConfig, marginTop = '3em' } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const handleSubmit = async (
    values: Record<string, Record<string, number | boolean | string> | number | boolean | string>
  ) => {
    console.log('Received values of form: ', values);

    const getType = (type: string) => {
      switch (type) {
        case 'number':
        case 'boolean':
        case 'string':
          return type;
        default:
          throw new Error('unsupported_type');
      }
    };

    const updatedConfig: Configuration = [];
    Object.keys(values).forEach((key) => {
      const obj = values[key];
      if (typeof obj === 'object') {
        const group: ConfigurationGroup = {
          type: 'group',
          name: key,
          children: [],
        };

        Object.keys(obj).forEach((key2) => {
          const item = obj[key2];
          const aci: ConfigurationItem = {
            type: getType(typeof item),
            name: key2,
            value: item,
          };
          group.children.push(aci);
        });

        updatedConfig.push(group);
      } else {
        const aci: ConfigurationItem = {
          type: getType(typeof obj),
          name: key,
          value: obj,
        };
        updatedConfig.push(aci);
      }
    });

    try {
      await onUpdateConfig(updatedConfig);
    } catch (err) {
      console.error(err);
    }
  };

  const getFallbackLabel = (fieldName: string) => {
    // NOTE: this is a hack to get the label from the fallback values
    const dataClone: Record<string, string> = { ...APP_CONFIG_FALLBACK_VALUES };
    const fallback = dataClone[fieldName];

    if (fallback == null) {
      return <span>When this field is empty fallback value is loaded.</span>;
    }
    return <span>Fallback value: {fallback}</span>;
  };

  const renderItem = (item: ConfigurationItem, groupName = '') => {
    let InputElement;

    switch (item.type) {
      case 'string':
        InputElement = Input;
        break;
      case 'boolean':
        InputElement = Checkbox;
        break;
      case 'number':
        InputElement = InputNumber;
        break;
      default:
        throw new Error('unknown config item type');
    }

    let { required } = item;
    if (required == null) {
      required = item.type !== 'boolean';
    }

    const name = [];
    if (groupName != null && groupName !== '') {
      name.push(groupName);
    }
    name.push(item.name);

    return (
      <Form.Item
        label={item.name}
        key={`formitem-${item.name}`}
        style={{ marginBottom: 0 }}
        name={name}
        tooltip={item.fallback === true ? getFallbackLabel(item.name) : null}
        rules={[
          {
            required,
            message: `Please input ${item.name}`,
          },
        ]}
        valuePropName={item.type === 'boolean' ? 'checked' : 'value'}
        initialValue={item.value}
      >
        <InputElement />
      </Form.Item>
    );
  };

  let configuration = data ?? [];

  if (appOnly === true) {
    configuration = configuration.filter(
      (configItem) => configItem.type === 'group' && configItem.name === 'appData'
    );
  }

  return (
    <div
      style={{ padding: '1em', marginTop, border: '1px solid #ccc' }}
      data-tid="configuration-container"
    >
      {title != null && <h4 style={{ marginBottom: '1em' }}>{title}</h4>}
      <Form
        {...formItemLayout}
        form={form}
        onFinish={handleSubmit}
        className={styles.form}
        name="configurationForm"
      >
        {configuration.map((configItem) => {
          if (configItem.type === 'group') {
            return (
              <div
                style={{ border: '1px solid #ccc', padding: '1em', marginBottom: '1em' }}
                key={`group-${configItem.name}`}
                data-tid="form-configuration-group"
              >
                <h5>{configItem.name}</h5>
                {(configItem as ConfigurationGroup).children.map((item) =>
                  renderItem(item, configItem.name)
                )}
              </div>
            );
          }
          return renderItem(configItem as ConfigurationItem);
        })}

        <Form.Item style={{ marginTop: '1em' }}>
          <MbxButton
            data-tid="btn-save-configuration"
            disabled={configuration.length === 0}
            mbxType="primary"
            htmlType="submit"
            className={styles.loginFormButton}
          >
            Save
          </MbxButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ConfigurationForm;
