export const UI_COMMIT_HASH: string = process.env.REACT_APP_GIT_COMMIT_HASH ?? 'unknown';

export const CHECK_API_VERSION_SETUP = 'CHECK_API_VERSION_SETUP';

export const CHECK_API_VERSION = 'CHECK_API_VERSION';
export const CHECK_API_VERSION_OK = 'CHECK_API_VERSION_OK';
export const CHECK_API_VERSION_FAIL = 'CHECK_API_VERSION_FAIL';

export const RELOAD_UI_ASK = 'RELOAD_UI_ASK';
export const RELOAD_UI_ASK_RESET = 'RELOAD_UI_ASK_RESET';
export const RELOAD_UI_ASK_CLOSE = 'RELOAD_UI_ASK_CLOSE';
export const RELOAD_UI = 'RELOAD_UI';
