import { createSelector } from 'reselect';

import { getEmptyCategory } from '../reducers/CategoryReducer';
import { sortCategories } from '../utils/sort';

import type { RootState } from '../reducers';
import type { Category } from '../reducers/CategoryReducer';

//
//
const getById = (state: RootState) => state.categories.byId;
const getAllIds = (state: RootState) => state.categories.allIds;

//
//
export const getCategoryByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => {
    const category = byId[id];
    if (category == null) {
      return getEmptyCategory(id);
    }
    return category;
  }
);

//
//
export const getCategoryById = createSelector(
  getCategoryByIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getCategoryById, id) => $getCategoryById(id)
);

//
//
export const getCategoriesByIdsFunc = createSelector(
  getCategoryByIdFunc,
  //
  ($getCategoryById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }

    return ids.map((id: number) => $getCategoryById(id)).filter((c): c is Category => !!c);
  }
);

//
//
export const getAllCategoriesForAppFunc = createSelector(
  getAllIds,
  getCategoryByIdFunc,
  //
  (allIds, $getCategoryById) => (appId: number) => {
    const ids = allIds[appId];
    if (!Array.isArray(ids)) {
      return [];
    }
    const categories = ids
      .map((id: number) => $getCategoryById(id))
      .filter((c): c is Category => !!c);
    categories.sort(sortCategories);
    return categories;
  }
);

//
//
export const getAllCategoriesForApp = createSelector(
  getAllCategoriesForAppFunc,
  (_: unknown, id: number) => id,
  //
  ($getAllCategoriesForApp, id) => $getAllCategoriesForApp(id)
);
