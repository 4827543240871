import { Timeline, Progress } from 'antd';

import { JobStatus } from '../common/constants/job-status';
import CustomPageHeader from '../components/CustomPageHeader';
import JSONDataVisualizer from '../components/JSONDataVisualizer';
import { ROUTE_NAME_INTERNAL_JOBS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { formatDate } from '../utils/date';
import { getJobTypeInfo } from '../utils/job';

import type { Job } from '../reducers/JobReducer';

type Props = {
  details: Job;
};

//
//
const JobDetail = (props: Props) => {
  const { details } = props;

  const { versions: remove, ...inputWithoutVersions } = details.input;

  const info = getJobTypeInfo(details);
  const logs = details.logs ?? [];

  const lastLog = logs[0] ?? {};

  let percent;
  if (lastLog.statusId === JobStatus.PROCESSING) {
    percent = lastLog.meta?.jobProgress?.jobPercentComplete ?? 0;
  }

  let lastStatus: number;
  // this looks at the logs and returns an array that indicates the first/last PROCESSING log and
  // those in between => [0, 0, 0, 1, 2, 3, 4, 1, 0]
  const hideProcessingSteps = logs.map((l, index) => {
    let shouldItemBeHidden = 0;
    const nextStatus = logs[index + 1]?.statusId ?? -1;
    if (l.statusId === JobStatus.PROCESSING) {
      if (nextStatus === JobStatus.PROCESSING) {
        shouldItemBeHidden = lastStatus + 1;
      } else {
        shouldItemBeHidden = 1;
      }
    }
    lastStatus = shouldItemBeHidden;
    return shouldItemBeHidden;
  });

  return (
    <div>
      <CustomPageHeader title="Job details" backLink={getUrlByName(ROUTE_NAME_INTERNAL_JOBS)} />

      <p>{`${info.label} [${details.externalId ?? 'external id not set'}]`}</p>

      {percent != null && <Progress type="circle" percent={percent} width={80} />}

      <Timeline style={{ marginTop: '2em' }}>
        {logs.map((l, index) => {
          const label =
            Object.keys(JobStatus).find(
              (k: string) => JobStatus[k as keyof typeof JobStatus] === l.statusId
            ) ?? '???';
          let color = 'blue';

          if (l.statusId === JobStatus.COMPLETED) {
            color = 'green';
          } else if (l.statusId === JobStatus.ERRORED || l.statusId === JobStatus.TIMEDOUT) {
            color = 'red';
          } else if (l.statusId === JobStatus.UNKNOWN) {
            color = 'orange';
          } else if (hideProcessingSteps[index] === 2) {
            color = 'gray';
            return (
              <Timeline.Item key={`item-${l.id}`} color={color}>
                ...
              </Timeline.Item>
            );
          }

          if (hideProcessingSteps[index] > 2) {
            return null;
          }

          return (
            <Timeline.Item key={`item-${l.id}`} color={color}>
              <p>{`${label} on ${formatDate(l.createdAt, 'YYYY-MM-DD HH:mm:ss')} `}</p>
              <JSONDataVisualizer data={[l.meta]} style={{ marginTop: 0, marginBottom: 0 }} />
            </Timeline.Item>
          );
        })}
      </Timeline>

      <h4>input</h4>
      <pre>{JSON.stringify({ inputWithoutVersions }, null, 2)}</pre>

      <h4>outputs</h4>
      <pre>{JSON.stringify(details.input.versions ?? [], null, 2)}</pre>

      <h4>more info</h4>
    </div>
  );
};

export default JobDetail;
