// import './wdyr';

import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { ConfigProvider } from 'antd';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { store, persistor } from './configureStore';
import { UI_COMMIT_HASH } from './constants/appversion';
import { CurrentOsContext } from './contexts';
import { isIOS } from './utils/browser';
import pkg from '../package.json';

import 'antd/dist/reset.css';
import './index.css';

const sentryKey = process.env.REACT_APP_SENTRY_KEY ?? '';
if (sentryKey !== '') {
  console.log('init sentry');
  Sentry.init({
    dsn: sentryKey,
    release: `admin-ui@${pkg.version}+${UI_COMMIT_HASH}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.33,
  });
}

const os = { os: isIOS(window.navigator.userAgent) ? 'iOS' : 'unknown' };

const node = document.getElementById('root');
if (node === null) {
  throw new Error('HTML node #root not found');
}

const root = createRoot(node);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <CurrentOsContext.Provider value={os}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily:
                "'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
              colorPrimary: '#3ebfff',
              colorPrimaryBg: '#3ebfff',
              colorPrimaryBgHover: '#00000006',
              colorPrimaryBorder: '#FFC059',
              colorPrimaryText: '#0E0E2C',
              colorPrimaryTextActive: '#0E0E2C',
              colorPrimaryTextHover: '#0E0E2C',
              colorPrimaryActive: '#0E0E2C',
              colorPrimaryBorderHover: '#78DE86',
              colorPrimaryHover: '#579ED7',
              wireframe: false,
            },
          }}
        >
          <App />
        </ConfigProvider>
      </CurrentOsContext.Provider>
    </PersistGate>
  </Provider>
);
