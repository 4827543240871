// import type { Reducer, Action } from 'redux';
// import { createSlice } from '@reduxjs/toolkit';

import {
  CHECK_API_VERSION_SETUP,
  CHECK_API_VERSION_OK,
  RELOAD_UI_ASK,
  RELOAD_UI_ASK_CLOSE,
  RELOAD_UI,
  RELOAD_UI_ASK_RESET,
} from '../constants/appversion';

import type { AnyAction } from 'redux';

export type AdminUiVersionState = {
  appVersion: string;
  apiCommitHash: string | null;
  lastTimeChecked: Date | null;
  isModalOpen: boolean;
  lastTimeAsked: Date | null;
  lastTimeReloaded: Date | null;
};

//
//
// const slice = createSlice({
//   name: 'adminUiVersion',
//   initialState: {},
//   reducers: {},
//   extraReducers: (builder) => {
//     builder.addCase(fetchUserById.fulfilled, (state, action) => {
//       // Add user to the state array
//       state.entities.push(action.payload)
//     })
//   },
// });

const initialState: AdminUiVersionState = {
  appVersion: process.env.REACT_APP_VERSION ?? 'unknown',
  apiCommitHash: null,
  lastTimeChecked: null,
  isModalOpen: false,
  lastTimeAsked: null,
  lastTimeReloaded: null,
};

//
//
// const reducer: Reducer<AdminUiVersionState, Action<any>> = handleActions(
const reducer = (state = initialState, action: AnyAction): AdminUiVersionState => {
  switch (action.type) {
    case CHECK_API_VERSION_SETUP:
      return {
        ...state,
        isModalOpen: false,
      };

    case CHECK_API_VERSION_OK:
      return {
        ...state,
        lastTimeChecked: new Date(),
        apiCommitHash: action.apiCommitHash,
      };

    case RELOAD_UI_ASK:
      return {
        ...state,
        isModalOpen: true,
        lastTimeAsked: new Date(),
      };

    case RELOAD_UI_ASK_CLOSE:
      return {
        ...state,
        isModalOpen: false,
        lastTimeAsked: new Date(),
      };

    case RELOAD_UI_ASK_RESET:
      return {
        ...state,
        isModalOpen: false,
        lastTimeAsked: null,
      };
    case RELOAD_UI:
      return {
        ...state,
        isModalOpen: false,
        lastTimeAsked: new Date(),
        lastTimeReloaded: new Date(),
      };
    default:
      return state;
  }
};

export default reducer;
