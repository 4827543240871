import { Alert, message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  getCategoryList,
  createCategory,
  deleteCategory,
  updateCategory,
} from '../actions/categories';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_CATEGORYSORT } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import CategoryList from '../pages/CategoryList';
import { getAllCategoriesForApp } from '../selectors/categorySelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { Category } from '../reducers/CategoryReducer';

//
//
const CategoryListContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { currentApp } = useCurrentAppContext();

  const allCategories = useGlobalSelector((state) => getAllCategoriesForApp(state, currentApp.id));
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (currentApp.id < 0) {
        return;
      }

      try {
        dispatch(counterIncrement());
        await dispatch(getCategoryList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [currentApp.id, dispatch]);

  const handleCategoryAddSubmit = async (
    id: number | null | undefined,
    name: string,
    isDraft: boolean,
    isLocked: boolean
  ) => {
    try {
      console.log('handleCategoryAddSubmit()', name, isLocked);
      if (id !== null && typeof id !== 'undefined') {
        await dispatch(updateCategory(id, name, isDraft, isLocked));
      } else {
        await dispatch(createCategory(currentApp.id, name, isDraft, isLocked));
      }
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
      throw err;
    }
  };

  const handleCategorySort = (category: Category) => {
    console.log('sort', category);
    history.push(
      getUrlByName(ROUTE_NAME_APP_CATEGORYSORT, {
        ':appUid': currentApp.uid,
        ':categoryId': category.id,
      })
    );
  };

  const handleCategoryDelete = async (categoryId: number) => {
    try {
      await dispatch(deleteCategory(currentApp.id, categoryId));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <CategoryList
      allCategories={allCategories}
      createOrUpdateCategory={handleCategoryAddSubmit}
      deleteCategory={handleCategoryDelete}
      openCategorySort={handleCategorySort}
    />
  );
};

export default CategoryListContainer;
