import {
  createAssetResourceVersionRequirement,
  updateAssetResourceVersionRequirement,
  deleteAssetResourceVersionRequirement,
} from './assetResourceVersionRequirements';
import {
  createEpisodeVersionRequirement,
  updateEpisodeVersionRequirement,
  deleteEpisodeVersionRequirement,
} from './episodeVersionRequirements';

//
//
export const createVersionRequirement = (
  type: string,
  id: number,
  appPlatformId: number,
  exclude: boolean,
  minVersionId: number | null,
  maxVersionId: number | null
) => {
  let func;
  if (type === 'episode') {
    func = createEpisodeVersionRequirement;
  } else if (type === 'assetResource') {
    func = createAssetResourceVersionRequirement;
  }

  if (func == null) {
    throw new Error('unknown type');
  }
  return func(id, appPlatformId, exclude, minVersionId, maxVersionId);
};

//
//
export const updateVersionRequirement = (
  type: string,
  versionRequirementId: number,
  exclude: boolean,
  minVersionId: number | null,
  maxVersionId: number | null
) => {
  let func;
  if (type === 'episode') {
    func = updateEpisodeVersionRequirement;
  } else if (type === 'assetResource') {
    func = updateAssetResourceVersionRequirement;
  }

  if (func == null) {
    throw new Error('unknown type');
  }
  return func(versionRequirementId, exclude, minVersionId, maxVersionId);
};

//
//
export const deleteVersionRequirement = (type: string, versionRequirementId: number) => {
  let func;
  if (type === 'episode') {
    func = deleteEpisodeVersionRequirement;
  } else if (type === 'assetResource') {
    func = deleteAssetResourceVersionRequirement;
  }

  if (func == null) {
    throw new Error('unknown type');
  }
  return func(versionRequirementId);
};
