import styles from './AnalyticsApp.module.scss';
import AnalyticsAppAllTime from './AnalyticsAppAllTime';
import AnalyticsAppLast28Days from './AnalyticsAppLast28Days';
import AnalyticsAppMaus from './AnalyticsAppMaus';

import type { FunctionComponent } from 'react';

type Props = {
  currentAppId: number;
};

const AnalyticsApp: FunctionComponent<Props> = ({ currentAppId }) => {
  return (
    <>
      <div className={styles.section}>
        <AnalyticsAppMaus currentAppId={currentAppId} />
      </div>
      <div className={styles.section} data-tid="analytics">
        <AnalyticsAppAllTime currentAppId={currentAppId} />
        <AnalyticsAppLast28Days currentAppId={currentAppId} />
      </div>
    </>
  );
};

export default AnalyticsApp;
