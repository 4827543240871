import { DeleteOutlined, LinkOutlined } from '@ant-design/icons';

import MbxButton from '../components/base/MbxButton';
import CustomPageHeader from '../components/CustomPageHeader';
import PlayerTypeBubble from '../components/PlayerTypeBubble';
import ReleaseEditForm from '../components/ReleaseEditForm';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_RELEASES } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

import type { ReleaseFormSubmitValues } from '../components/ReleaseEditForm';
import type { Category } from '../reducers/CategoryReducer';
import type { Episode } from '../reducers/EpisodeReducer';
import type { Region } from '../reducers/RegionReducer';
import type { Release } from '../reducers/ReleaseReducer';

type Props = Readonly<{
  currentRelease: Release;
  appRegions: Array<Region>;
  appEpisodes: Array<Episode>;
  appCategories: Array<Category>;
  releasedEpisodeIdsPerRegion: { [k: string | number]: Array<number> };
  selectedRegionId: number | null;

  updateRelease: (values: ReleaseFormSubmitValues) => Promise<void>;
  openEpisode: (episode: Episode) => void;
  deleteRelease: (release: Release) => Promise<void>;
}>;

//
//
const ReleaseDetail = (props: Props) => {
  const {
    currentRelease,
    appRegions,
    appEpisodes,
    appCategories,
    releasedEpisodeIdsPerRegion,
    updateRelease,
    openEpisode,
    deleteRelease,
    selectedRegionId,
  } = props;

  const { currentApp } = useCurrentAppContext();
  const backLink = getUrlByName(ROUTE_NAME_APP_RELEASES, {
    ':appUid': currentApp.uid,
  });

  const handleDeleteRelease = () => {
    if (window.confirm(`Really delete ${currentRelease.episode.name}?`)) {
      deleteRelease(currentRelease);
    }
  };

  const handleOpenEpisode = () => {
    if (currentRelease.episode != null && typeof openEpisode === 'function') {
      openEpisode(currentRelease.episode);
    }
  };

  return (
    <div>
      <CustomPageHeader
        backLink={backLink}
        title={
          <div style={{ display: 'flex' }}>
            <PlayerTypeBubble episode={currentRelease?.episode} />
            <span style={{ marginLeft: 4 }}>{`${currentRelease.episode.name}`}</span>
            <span
              style={{ marginLeft: '0.5em', fontWeight: 500 }}
            >{`(${currentRelease.region.name})`}</span>
          </div>
        }
        extra={[
          <MbxButton
            size="large"
            onClick={handleOpenEpisode}
            icon={<LinkOutlined />}
            data-tid="btn-open-episode"
            key="btn-open-episode"
          >
            open episode
          </MbxButton>,
          <MbxButton
            danger
            size="large"
            onClick={handleDeleteRelease}
            icon={<DeleteOutlined />}
            data-tid="btn-delete-release"
            key="btn-delete-release"
          >
            delete release
          </MbxButton>,
        ]}
      />

      <div style={{ marginTop: '2em', maxWidth: 600 }}>
        <ReleaseEditForm
          type="edit"
          backLink={backLink}
          onSubmit={updateRelease}
          data={currentRelease}
          appEpisodes={appEpisodes}
          appRegions={appRegions}
          selectedRegionId={selectedRegionId}
          appCategories={appCategories}
          releasedEpisodeIdsPerRegion={releasedEpisodeIdsPerRegion}
        />
      </div>
    </div>
  );
};

export default ReleaseDetail;
