export const AuthServiceType = {
  USERNAME_PASSWORD: 1,
  OIDC: 2,
} as const;

export const AuthServiceTypeNames = {
  [AuthServiceType.USERNAME_PASSWORD]: 'username-password',
  [AuthServiceType.OIDC]: 'oidc',
} as const;

export const VALID_AUTHSERVICE_TYPES = Object.keys(AuthServiceType).map(
  (key) => AuthServiceType[key as keyof typeof AuthServiceType]
);

export type AllowedAuthServiceType = (typeof VALID_AUTHSERVICE_TYPES)[number];
export type AllowedAuthServiceTypeKey = `${AllowedAuthServiceType}`;

export const VALID_AUTHSERVICE_NAMES = Object.keys(AuthServiceTypeNames).map(
  (key) => AuthServiceTypeNames[key as AllowedAuthServiceTypeKey]
);

export const DEFAULT_AUTHSERVICE_NAME = 'mediabox-login';

export const DEFAULT_AUTHSERVICE_SETTINGS = {
  endpointUrl: '/api/auth/login',
  resetPasswordUrl: '/login/password/forgot',
} as const;
