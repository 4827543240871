import { combineReducers } from 'redux';

import { LOGIN_OK } from '../constants/auth';
import {
  USER_INVITE_OK,
  USER_INVITE_GET_LIST_OK,
  USER_INVITE_REMOVE_OK,
  USER_INVITE_ACCEPT_OK,
} from '../constants/users';

import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

export type UserInviteData = {
  email: string;
  appId: number | null;
  appName?: string;
  hidden?: boolean;
  roleIds?: Array<number>;
};

export type CheckInviteTokenResponse = {
  id: number;
  appId: number;
  email: string;
  appName: string;
};

export type UserInvite = Merge<
  UserInviteData,
  {
    id: number;
    hash: string;
  }
>;

type InviteStateById = { [k: string | number]: UserInvite };
type InviteStateAllIds = { [k: string | number]: Array<number> };

export type InviteState = {
  byId: InviteStateById;
  allIds: InviteStateAllIds;
};

const initialStateById: InviteStateById = {};
const initialStateAllIds: InviteStateAllIds = {};

//
//
export const getEmptyInvite = (id?: number): UserInvite => ({
  id: id ?? -1,
  email: '',
  hash: '',
  appId: -1,
  appName: '',
  roleIds: [],
});

//
//
const byId = (state = initialStateById, action: AnyAction): InviteStateById => {
  switch (action.type) {
    case USER_INVITE_OK:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    case USER_INVITE_GET_LIST_OK: {
      const arrayToObj: InviteStateById = {};
      action.payload.forEach((inv: UserInvite) => {
        arrayToObj[inv.id] = inv;
      });
      return {
        ...state,
        ...arrayToObj,
      };
    }

    case LOGIN_OK: {
      if (action.invites.length === 0) {
        return state;
      }
      const arrayToObj: InviteStateById = {};
      action.invites.forEach((inv: UserInvite) => {
        arrayToObj[inv.id] = inv;
      });
      return {
        ...state,
        ...arrayToObj,
      };
    }

    case USER_INVITE_REMOVE_OK: {
      const { [action.inviteId]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case USER_INVITE_ACCEPT_OK: {
      const { inviteId } = action;
      if (inviteId === null) {
        return state;
      }
      const { [inviteId]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    default:
      return state;
  }
};

const allIds = (state = initialStateAllIds, action: AnyAction): InviteStateAllIds => {
  switch (action.type) {
    case USER_INVITE_OK:
      return {
        ...state,
        [action.payload.appId]: [...(state[action.payload.appId] ?? []), action.payload.id],
      };

    case USER_INVITE_GET_LIST_OK:
      return {
        ...state,
        [action.appId]: action.payload.map((inv: UserInvite) => inv.id),
      };

    case LOGIN_OK: {
      if (action.invites.length === 0) {
        return state;
      }
      const newState = { ...state };
      action.invites.forEach((invite: UserInvite) => {
        const appIdFixed = invite.appId === null ? 'null' : invite.appId;
        if (Array.isArray(newState[appIdFixed])) {
          if (!newState[appIdFixed].includes(invite.id)) {
            newState[appIdFixed] = [...newState[appIdFixed], invite.id];
          }
        } else {
          newState[appIdFixed] = [invite.id];
        }
      });
      return newState;
    }

    case USER_INVITE_REMOVE_OK: {
      const updatedState = {
        ...state,
      };
      Object.keys(updatedState).forEach((appId) => {
        const invitesPerApp = updatedState[appId];
        if (invitesPerApp.includes(action.inviteId)) {
          updatedState[appId] = invitesPerApp.filter((id) => id !== action.inviteId);
        }
      });
      return updatedState;
    }

    case USER_INVITE_ACCEPT_OK: {
      const { inviteId, appId } = action;
      if (inviteId === null) {
        return state;
      }

      if (Array.isArray(state[appId])) {
        return {
          ...state,
          [appId]: state[appId].filter((iId) => iId !== inviteId),
        };
      }
      return state;
    }

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
});

export default combined;
