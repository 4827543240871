import { CloudUploadOutlined } from '@ant-design/icons';
import { List, Modal, Upload } from 'antd';
import { useEffect, useState } from 'react';

import { BatchUploadComponentState } from './constants';
import NumberCard from './NumberCard';
import { MAX_UPLOAD_SIZE } from '../../common/constants/upload';
import { humanFileSize } from '../../common/utils/bytes';
import { normalizeUnicode } from '../../utils/lang';
import MbxButton, { getButtonProps } from '../base/MbxButton';
import FilePicker from '../FilePicker';

import type { ModalProps, UploadFile } from 'antd';

type Props = Readonly<{
  fileNames: Array<string>;
  files: Array<File>;
  setFiles: (files: Array<File>) => void;
  state: BatchUploadComponentState;
  setState: (state: BatchUploadComponentState) => void;
}>;

type FilePickerError = {
  filename: string;
  reason: string;
  addedAt: Date;
};

const EpisodeFilePicker = ({
  fileNames: fileNamesFromSheet,
  files,
  setFiles,
  state,
  setState,
}: Props) => {
  const [filePickerErrors, setFilePickerErrors] = useState<Array<FilePickerError>>([]);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const [duplicateUploads, setDuplicateUploads] = useState<Array<File>>([]);
  const [alreadyCollectedFilenames, setAlreadyCollectedFilenames] = useState<Array<string>>([]);

  useEffect(() => {
    if (files.length > 0) {
      setAlreadyCollectedFilenames(files.map(({ name }) => normalizeUnicode(name)));

      setState(
        files.length === fileNamesFromSheet.length
          ? BatchUploadComponentState.ALL_COLLECTED
          : BatchUploadComponentState.COLLECTING_FILES
      );
    }
  }, [files]);

  const accept = '*';

  const fileChange = (newFiles: Array<File>) => {
    console.log('fileChange()', newFiles);
    const now = new Date();
    const errors: Array<FilePickerError> = [];

    const acceptedFiles = newFiles.filter(({ name, size }) => {
      const normalized = normalizeUnicode(name);
      const onSheet = fileNamesFromSheet.includes(normalized);
      const alreadyCollected = alreadyCollectedFilenames.includes(normalized);
      const fileIsTooBig = size >= MAX_UPLOAD_SIZE;

      if (onSheet === false) {
        errors.push({ filename: name, reason: 'File not on sheet', addedAt: now });
      }
      if (fileIsTooBig === true) {
        errors.push({
          filename: name,
          reason: `File too big (${humanFileSize(
            size
          )}). We support a max upload size of ${humanFileSize(MAX_UPLOAD_SIZE)}`,
          addedAt: now,
        });
      }
      return onSheet === true && alreadyCollected === false && fileIsTooBig === false;
    });
    console.log('fileChange() accepted', acceptedFiles);
    if (errors.length > 0) {
      setFilePickerErrors((e) => [...e, ...errors]);
    }

    const duplicates = newFiles.filter(({ name }) =>
      alreadyCollectedFilenames.includes(normalizeUnicode(name))
    );

    setDuplicateUploads(duplicates);
    setFiles([...files, ...acceptedFiles]);
  };

  const handleOneDuplicate = (replace: boolean) => {
    return () => {
      if (replace === true) {
        const fileToReplace = duplicateUploads.at(0);
        if (fileToReplace == null) {
          return;
        }
        setFiles([
          ...files.filter(
            ({ name }) => normalizeUnicode(name) !== normalizeUnicode(fileToReplace.name)
          ),
          fileToReplace,
        ]);
      }

      setDuplicateUploads([...duplicateUploads.slice(1)]);
    };
  };

  const cancelAllDuplicates = () => {
    setDuplicateUploads([]);
  };

  const replaceAllDuplicates = () => {
    const duplicateNames = duplicateUploads.map(({ name }) => normalizeUnicode(name));
    const notDuplicates = files.filter(
      ({ name }) => !duplicateNames.includes(normalizeUnicode(name))
    );
    setFiles([...notDuplicates, ...duplicateUploads]);
    setDuplicateUploads([]);
  };

  const content = () => {
    const onChange = ({ fileList }: { fileList: Array<UploadFile> }) =>
      fileChange(fileList.map(({ originFileObj }) => originFileObj as File));

    const fileCollectedFilter = (isCollected: boolean) => (fileName: string) =>
      isCollected === alreadyCollectedFilenames.includes(fileName);

    return (
      <div>
        {state !== BatchUploadComponentState.READY_TO_COLLECT && (
          <div>
            <List
              style={{
                width: '100%',
              }}
            >
              <List.Item
                key="root"
                style={{ display: 'flex', justifyContent: 'space-between', opacity: 0.5 }}
              >
                <span>FILE</span>
                <span style={{ marginRight: '10px' }}>COLLECTED</span>
              </List.Item>
            </List>
            <List
              style={{
                width: '100%',
                overflow: 'auto',
                maxHeight: 328,
              }}
            >
              {fileNamesFromSheet.filter(fileCollectedFilter(true)).map((fileName) => {
                return (
                  <List.Item
                    key={fileName}
                    style={{ display: 'flex', justifyContent: 'space-between', padding: '4px 0' }}
                  >
                    <span>{fileName}</span>
                    <span style={{ marginRight: '10px' }} data-tid={`bu-file-list-${fileName}`}>
                      OK
                    </span>
                  </List.Item>
                );
              })}
              {fileNamesFromSheet.filter(fileCollectedFilter(false)).map((fileName) => {
                return (
                  <List.Item
                    key={fileName}
                    style={{ display: 'flex', justifyContent: 'space-between', padding: '4px 0' }}
                  >
                    <span>{fileName}</span>
                    <span style={{ marginRight: '10px' }} data-tid={`bu-file-list-${fileName}`}>
                      {''}
                    </span>
                  </List.Item>
                );
              })}
            </List>
          </div>
        )}
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {state === BatchUploadComponentState.READY_TO_COLLECT && (
            <CloudUploadOutlined style={{ display: 'block', fontSize: '70px', opacity: 0.25 }} />
          )}
          <div style={{ marginTop: '1em' }}>
            <span>Please drop your files here or</span>
            <Upload
              data-tid="bu-content-input"
              accept={accept}
              showUploadList={false}
              multiple={true}
              customRequest={() => true}
              onChange={onChange}
              fileList={[]}
            >
              <MbxButton mbxType="tertiary" linkColor="#3ebfff">
                browse your files
              </MbxButton>
            </Upload>
          </div>
        </div>
      </div>
    );
  };

  const { className, ...buttonStyle } = getButtonProps({ size: 'middle' });
  const okButtonProps: ModalProps['okButtonProps'] = {
    className,
    ...buttonStyle,
  };

  const { className: cancelClassName, ...cancelButtonStyle } = getButtonProps({
    mbxType: 'secondary',
    size: 'middle',
  });
  const cancelButtonProps: ModalProps['cancelButtonProps'] = {
    className: cancelClassName,
    ...cancelButtonStyle,
  };

  const openWarningModal = () => {
    setIsWarningModalOpen(true);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 30,
          marginTop: 10,
          gap: 40,
        }}
      >
        <NumberCard
          n={files.length}
          title={`Files of ${fileNamesFromSheet.length}`}
          testId="bu-files"
        />
        {filePickerErrors.length > 0 && (
          <NumberCard
            n={filePickerErrors.length}
            title={
              <>
                <span>Warnings</span>&nbsp;<a onClick={openWarningModal}>↗</a>
              </>
            }
            testId="bu-filewarnings"
            color={'#ff8163'}
          />
        )}
      </div>
      <FilePicker content={content} setFiles={fileChange} accepted={accept} />
      <Modal
        title={`Warnings`}
        okText="close"
        onOk={() => {
          setIsWarningModalOpen(false);
        }}
        onCancel={() => {
          setIsWarningModalOpen(false);
        }}
        open={isWarningModalOpen}
        width={800}
        footer={[]}
      >
        <div
          style={{
            borderRadius: 10,
            border: `1px dashed red`,
            padding: '10px 20px',
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          {filePickerErrors.length === 0 && <span>No warnings</span>}
          {filePickerErrors.length > 0 && (
            <List>
              {filePickerErrors.map((err) => {
                return (
                  <List.Item
                    key={JSON.stringify(err)}
                    style={{ display: 'flex', padding: '4px 0' }}
                  >
                    <span style={{ width: 300 }}>{err.filename}</span>
                    <span style={{ marginLeft: 10 }}>{err.reason}</span>
                  </List.Item>
                );
              })}
            </List>
          )}
        </div>
      </Modal>
      <Modal
        title={`Are you sure you want to replace ${duplicateUploads.at(0)?.name ?? ''}?`}
        okText="Yes, replace"
        cancelText="No, I want to keep the original"
        onOk={handleOneDuplicate(true)}
        onCancel={handleOneDuplicate(false)}
        open={duplicateUploads.length > 0}
        width={600}
        closable={false}
        keyboard={false}
        maskClosable={false}
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}
      >
        <div>
          {duplicateUploads.length === 1 && (
            <div data-tid="bu-duplicate-file-modal-single">
              The file you want to upload already exists. Please confirm to replace the file.
            </div>
          )}
          {duplicateUploads.length > 1 && (
            <div data-tid="bu-duplicate-file-modal-double">
              The files you want to upload already exist.
              <br />
              Please confirm to replace the file, or apply an action to all.
              <div style={{ textAlign: 'end', marginTop: '12px' }}>
                <MbxButton size="middle" onClick={cancelAllDuplicates}>
                  Cancel All
                </MbxButton>
                <MbxButton
                  onClick={replaceAllDuplicates}
                  mbxType="primary"
                  size="middle"
                  style={{ marginInlineStart: '8px', marginInlineEnd: '5px' }}
                  data-tid="bu-duplicate-file-modal-ok"
                >
                  Replace All
                </MbxButton>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default EpisodeFilePicker;
