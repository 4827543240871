import CustomPageHeader from '../components/CustomPageHeader';
import ReleaseEditForm from '../components/ReleaseEditForm';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_RELEASES } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

import type { ReleaseFormSubmitValues } from '../components/ReleaseEditForm';
import type { Category } from '../reducers/CategoryReducer';
import type { Episode } from '../reducers/EpisodeReducer';
import type { Region } from '../reducers/RegionReducer';

type Props = Readonly<{
  currentRegionId: number;
  currentEpisodeId: number;
  appEpisodes: Array<Episode>;
  appRegions: Array<Region>;
  appCategories: Array<Category>;
  selectedRegionId: number | null;
  releasedEpisodeIdsPerRegion: { [k: string | number]: Array<number> };

  createRelease: (values: ReleaseFormSubmitValues) => Promise<void>;
  // handleReleaseListUpdate: (regionId: number) => Promise<void>,
  setSelectedRegionId: (newRegionId: number) => void;
}>;

//
//
const ReleaseCreate = (props: Props) => {
  const {
    releasedEpisodeIdsPerRegion,
    appEpisodes,
    appRegions,
    selectedRegionId,
    appCategories,
    createRelease,
    currentEpisodeId,
    // handleReleaseListUpdate,
    setSelectedRegionId,
  } = props;

  const { currentApp } = useCurrentAppContext();
  const backLink = getUrlByName(ROUTE_NAME_APP_RELEASES, {
    ':appUid': currentApp.uid,
  });

  return (
    <div>
      <CustomPageHeader title="Create new release" backLink={backLink} />

      <div style={{ marginTop: '2em', maxWidth: 600 }}>
        <ReleaseEditForm
          type="add"
          backLink={backLink}
          onSubmit={createRelease}
          setSelectedRegionId={setSelectedRegionId}
          releasedEpisodeIdsPerRegion={releasedEpisodeIdsPerRegion}
          appEpisodes={appEpisodes}
          appRegions={appRegions}
          selectedRegionId={selectedRegionId}
          appCategories={appCategories}
          currentEpisodeId={currentEpisodeId}
        />
      </div>
    </div>
  );
};

export default ReleaseCreate;
