import { Form, Select, Switch } from 'antd';
import { useState, useEffect, useImperativeHandle, forwardRef, useCallback } from 'react';

import MbxButton from './base/MbxButton';
import styles from '../pages/EpisodeDetail.module.scss';

import type { AppPlatform } from '../reducers/AppPlatformReducer';
import type { AppVersion } from '../reducers/AppVersionReducer';
import type { AssetResourceVersionRequirement } from '../reducers/AssetResourceVersionRequirementReducer';
import type { EpisodeVersionRequirement } from '../reducers/EpisodeVersionRequirementReducer';
import type { ForwardedRef } from 'react';

// import styles from './EpisodeLocalizedAddModal.module.scss';

type Props = Readonly<{
  selectedVersionRequirement: EpisodeVersionRequirement | AssetResourceVersionRequirement | null;
  selectedAppPlatform: AppPlatform | null;
  onSubmit: (values: ItemVersionRequirementFormValues) => Promise<boolean>;
  onCancel: () => void;
}>;

export type ItemVersionRequirementFormValues = {
  isExcluded?: boolean;
  minVersion: number | null;
  maxVersion: number | null;
};

export type Handle = {
  reset: () => void;
};

//
//
const ItemVersionRequirementForm = (props: Props, ref: ForwardedRef<Handle>) => {
  const { selectedVersionRequirement: vr, selectedAppPlatform: ap, onCancel, onSubmit } = props;
  const [isExcluded, setIsExcluded] = useState(vr?.exclude ?? false);
  const [form] = Form.useForm();

  let availableVersions: Array<AppVersion> = [];
  let platformName = '??';
  if (vr != null) {
    availableVersions = vr.appPlatform.versions;
    platformName = vr.appPlatform.platform.name;
  } else if (ap != null) {
    availableVersions = ap.versions;
    platformName = ap.platform.name;
  }

  const resetForm = useCallback(() => {
    const isExcluded1 = vr?.exclude ?? false;
    setIsExcluded(() => isExcluded1);
    form.setFieldsValue({
      minVersion: vr?.minVersion?.id ?? undefined,
      maxVersion: vr?.maxVersion?.id ?? undefined,
    });
  }, [vr, form]);

  useEffect(() => {
    resetForm();
  }, [ap, resetForm]);

  // using this, the parent component can call the reset function using this comp's ref
  // see: https://reactjs.org/docs/hooks-reference.html#useimperativehandle
  useImperativeHandle(ref, () => ({
    reset() {
      resetForm();
    },
  }));

  const handleSubmit = async (values: ItemVersionRequirementFormValues) => {
    console.log('Received values of form: ', isExcluded, values);

    values.isExcluded = isExcluded;
    if (isExcluded === true) {
      values.minVersion = null;
      values.maxVersion = null;
    }
    values.minVersion =
      values.minVersion === -1 || values.minVersion == null ? null : values.minVersion;
    values.maxVersion =
      values.maxVersion === -1 || values.maxVersion == null ? null : values.maxVersion;
    await onSubmit(values);
  };

  const handleExcludeChange = (value: boolean) => {
    setIsExcluded(value);
  };

  return (
    <Form onFinish={handleSubmit} form={form} name="ItemVersionRequirementForm">
      <Form.Item
        label={`Exclude from ${platformName} platform?`}
        name="exclude"
        style={{ marginBottom: '2em' }}
      >
        <Switch checked={isExcluded} onChange={handleExcludeChange} />
      </Form.Item>
      <Form.Item label="min version" name="minVersion">
        <Select
          // showSearch
          disabled={isExcluded === true}
          placeholder="Select min version"
          // onChange={handleResourceTypeChange}
          filterOption={(input, option) =>
            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Select.Option value={-1}>None</Select.Option>
          {availableVersions.map((v) => (
            <Select.Option key={v.id} value={v.id}>
              {v.name}
              {v.description != null && (
                <span className={styles.inlineAppDescription}> - {v.description}</span>
              )}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="max version" name="maxVersion">
        <Select
          // showSearch
          disabled={isExcluded === true}
          placeholder="Select max version"
          // onChange={handleResourceTypeChange}
          filterOption={(input, option) =>
            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Select.Option value={-1}>None</Select.Option>
          {availableVersions.map((v) => (
            <Select.Option key={v.id} value={v.id}>
              {v.name}
              {v.description != null && (
                <span className={styles.inlineAppDescription}> - {v.description}</span>
              )}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item style={{ marginTop: '2em', marginBottom: 0, textAlign: 'right' }}>
        <MbxButton style={{ marginRight: 10 }} onClick={onCancel} size="large">
          Cancel
        </MbxButton>
        <MbxButton mbxType="primary" htmlType="submit">
          {vr === null ? 'Create' : 'Save'}
        </MbxButton>
      </Form.Item>
    </Form>
  );
};

export default forwardRef(ItemVersionRequirementForm);
