export const ASSET_GET_LIST = 'ASSET_GET_LIST';
export const ASSET_GET_LIST_OK = 'ASSET_GET_LIST_OK';
export const ASSET_GET_LIST_FAIL = 'ASSET_GET_LIST_FAIL';

export const ASSET_GET_DETAILS = 'ASSET_GET_DETAILS';
export const ASSET_GET_DETAILS_OK = 'ASSET_GET_DETAILS_OK';
export const ASSET_GET_DETAILS_FAIL = 'ASSET_GET_DETAILS_FAIL';

export const ASSET_CREATE = 'ASSET_CREATE';
export const ASSET_CREATE_OK = 'ASSET_CREATE_OK';
export const ASSET_CREATE_FAIL = 'ASSET_CREATE_FAIL';

export const ASSET_UPDATE = 'ASSET_UPDATE';
export const ASSET_UPDATE_OK = 'ASSET_UPDATE_OK';
export const ASSET_UPDATE_FAIL = 'ASSET_UPDATE_FAIL';

export const ASSET_BULK_UPDATE = 'ASSET_BULK_UPDATE';
export const ASSET_BULK_UPDATE_OK = 'ASSET_BULK_UPDATE_OK';
export const ASSET_BULK_UPDATE_FAIL = 'ASSET_BULK_UPDATE_FAIL';

export const ASSET_REMOVE = 'ASSET_REMOVE';
export const ASSET_REMOVE_OK = 'ASSET_REMOVE_OK';
export const ASSET_REMOVE_FAIL = 'ASSET_REMOVE_FAIL';

// export const ASSET_SET_RESOURCE = 'ASSET_SET_RESOURCE';
// export const ASSET_SET_RESOURCE_OK = 'ASSET_SET_RESOURCE_OK';
// export const ASSET_SET_RESOURCE_FAIL = 'ASSET_SET_RESOURCE_FAIL';

export const ASSET_GET_METADATA = 'ASSET_GET_METADATA';
export const ASSET_GET_METADATA_OK = 'ASSET_GET_METADATA_OK';
export const ASSET_GET_METADATA_FAIL = 'ASSET_GET_METADATA_FAIL';
