export type TimeSpan = {
  hours: number;
  minutes: number;
};

export const formatTimeSpan = ({ hours, minutes }: TimeSpan) =>
  `${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')}`;

export const toTimespan = (s: number) => {
  if (s === Infinity || Number.isNaN(s)) {
    return undefined;
  }

  const secondsToMinutes = Math.floor(s / 60);
  const hours = Math.floor(secondsToMinutes / 60);
  const minutes = secondsToMinutes % 60;

  return minutes === 0 ? { hours: 0, minutes: 1 } : { hours, minutes };
};
