import { message, Alert } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getCountryList } from '../actions/countries';
import { getLocaleList } from '../actions/locales';
import {
  addRegionCountry,
  addRemainingRegionCountries,
  removeRegionCountry,
  removeRegionCountryAll,
  updateRegionCountry,
} from '../actions/regionCountries';
import { addRegionLocale, removeRegionLocale, updateRegionLocale } from '../actions/regionLocales';
import { deleteRegion, getRegionDetails, updateRegion } from '../actions/regions';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { getTimezoneList } from '../actions/timezones';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_REGIONS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import RegionDetail from '../pages/RegionDetail';
import { getAllCountries } from '../selectors/countrySelectors';
import { getAllLocales } from '../selectors/localeSelectors';
import { getPreviousPages } from '../selectors/pageHistory';
import { getRegionById } from '../selectors/regions/advanced';
import { getAllTimezones } from '../selectors/timezoneSelectors';
import { getRegionId } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { RegionEditFormValues } from '../components/RegionEditForm';
import type { RegionCountry } from '../reducers/RegionCountryReducer';
import type { RegionLocale } from '../reducers/RegionLocaleReducer';
import type { Region } from '../reducers/RegionReducer';

//
//
const RegionDetailContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useParams();
  const { currentApp } = useCurrentAppContext();

  const currentRegionId = getRegionId(params);

  const currentRegion = useGlobalSelector((state) => getRegionById(state, currentRegionId));
  const timezones = useGlobalSelector(getAllTimezones);
  const countries = useGlobalSelector(getAllCountries);
  const locales = useGlobalSelector(getAllLocales);

  const [error, setError] = useState('');

  const previousPages = useGlobalSelector(getPreviousPages);
  let linkForAfterSave = getUrlByName(ROUTE_NAME_APP_REGIONS, {
    ':appUid': currentApp.uid,
  });
  if (previousPages.length > 1) {
    const prev = previousPages[previousPages.length - 2];
    const [path] = prev.path.split('?');
    if (path === linkForAfterSave) {
      linkForAfterSave = prev.path;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getRegionDetails(currentRegion.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch, currentRegion.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getTimezoneList());
        await dispatch(getCountryList());
        await dispatch(getLocaleList());
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };
    fetchData();
  }, [dispatch]);

  const handleRegionUpdate = async (values: RegionEditFormValues) => {
    try {
      const { name, isDraft, releaseTimezone, useDefaultAsFallback } = values;
      console.log('handleRegionUpdate()', name, isDraft, releaseTimezone);
      await dispatch(
        updateRegion(
          currentRegion.id,
          name,
          isDraft,
          useDefaultAsFallback,
          releaseTimezone,
          currentRegion.defaultLocaleId
        )
      );
      history.push(linkForAfterSave);
      message.success('saved');
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
    return false;
  };

  const handleRegionUpdateDefaultLocale = async (regionLocaleId: number) => {
    try {
      console.log('handleRegionUpdateDefaultLocale()', regionLocaleId);
      await dispatch(
        updateRegion(
          currentRegion.id,
          currentRegion.name,
          currentRegion.isDraft,
          currentRegion.useDefaultAsFallback,
          currentRegion.releaseTimezone.id,
          regionLocaleId
        )
      );
      message.success('saved');
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleAddChild = async (type: string, childId: number, isDraft: boolean) => {
    try {
      if (type === 'country') {
        await dispatch(addRegionCountry(currentRegion.id, childId, isDraft));
      } else if (type === 'locale') {
        await dispatch(addRegionLocale(currentRegion.id, childId, isDraft));
      }
      message.success('added');
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
    return false;
  };

  const handleAddRemainingCountries = async () => {
    try {
      await dispatch(addRemainingRegionCountries(currentRegion.id));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleCountryRemove = async (rcId: number) => {
    try {
      await dispatch(removeRegionCountry(currentRegion.id, rcId));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleRemoveAllCountries = async () => {
    try {
      await dispatch(removeRegionCountryAll(currentRegion.id));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleCountryUpdate = async (rc: RegionCountry) => {
    try {
      await dispatch(updateRegionCountry(currentRegion.id, rc.id, !rc.isDraft));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleLocaleUpdate = async (rl: RegionLocale) => {
    try {
      await dispatch(updateRegionLocale(currentRegion.id, rl.id, !rl.isDraft));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleLocaleRemove = async (rlId: number) => {
    try {
      await dispatch(removeRegionLocale(currentRegion.id, rlId));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleRegionDelete = async (region: Region) => {
    try {
      await dispatch(deleteRegion(region));
      history.push(linkForAfterSave);
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return (
    <RegionDetail
      currentRegion={currentRegion}
      timezones={timezones}
      countries={countries}
      locales={locales}
      //
      removeRegionLocale={handleLocaleRemove}
      removeRegionCountry={handleCountryRemove}
      updateRegion={handleRegionUpdate}
      updateCountry={handleCountryUpdate}
      updateLocale={handleLocaleUpdate}
      updateDefaultLocale={handleRegionUpdateDefaultLocale}
      addRegionChild={handleAddChild}
      addRemainingCountries={handleAddRemainingCountries}
      removeRegionCountryAll={handleRemoveAllCountries}
      deleteRegion={handleRegionDelete}
    />
  );
};

export default RegionDetailContainer;
