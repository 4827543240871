import { Form, Input, Modal, Switch } from 'antd';
import { useEffect } from 'react';

import MbxButton from './base/MbxButton';

import type { Category } from '../reducers/CategoryReducer';

type Props = Readonly<{
  isUserSuperadmin: boolean;
  data: Category | null;
  visible: boolean;
  onClose: () => void;
  onSubmit: (
    id: number | null | undefined,
    name: string,
    isDraft: boolean,
    isLocked: boolean
  ) => Promise<void>;
}>;

//
//
const CategoryAddModal = (props: Props) => {
  const { visible, data, isUserSuperadmin, onClose, onSubmit } = props;
  const title = data !== null ? 'Edit a category' : 'Create a category';

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible === true) {
      form.setFieldsValue({
        name: data?.name ?? undefined,
        isDraft: data?.isDraft ?? false,
        isLocked: data?.isLocked ?? false,
      });
    }
  }, [data, form, visible]);

  const handleOk = async () => {
    try {
      const values: {
        name: string;
        isDraft: boolean | null | undefined;
        isLocked: boolean | null | undefined;
      } = await form.validateFields();
      console.log('Received values of form: ', values);
      const id = data?.id ?? null;
      await onSubmit(id, values.name, !!values.isDraft, !!values.isLocked);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal title={title} centered open={visible} onCancel={onClose} footer={null}>
      <Form layout="vertical" name="CategoryAddModal" form={form} onFinish={handleOk}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please give the category a name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="is draft" name="isDraft" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="is locked" name="isLocked" valuePropName="checked">
          <Switch disabled={!isUserSuperadmin} />
        </Form.Item>

        <Form.Item style={{ marginTop: '2em', marginBottom: 0, textAlign: 'right' }}>
          <MbxButton style={{ marginRight: 10 }} onClick={onClose} size="large">
            Cancel
          </MbxButton>
          <MbxButton mbxType="primary" htmlType="submit">
            {data !== null ? 'Save' : 'Create'}
          </MbxButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CategoryAddModal;
