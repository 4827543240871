import { schema, normalize } from 'normalizr';

import { TAG_GET_LIST, TAG_GET_LIST_OK, TAG_GET_LIST_FAIL } from '../constants/tags';
import { TagSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const getTagList = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/tags`,
      params: {
        appId,
      },
    },
    dispatch,
    {
      init: TAG_GET_LIST,
      fail: TAG_GET_LIST_FAIL,
    }
  );
  const normalizedData = normalize(response.data, new schema.Array(TagSchema));
  dispatch({ type: TAG_GET_LIST_OK, appId, payload: normalizedData });
  return normalizedData;
};
