import { combineReducers } from 'redux';

import { getEmptyLocale } from './LocaleReducer';
import {
  REGIONLOCALE_CREATE_OK,
  REGIONLOCALE_REMOVE_OK,
  REGIONLOCALE_UPDATE_OK,
} from '../constants/regionLocales';
import { REGION_GET_LIST_OK, REGION_GET_DETAILS_OK, REGION_REMOVE_OK } from '../constants/regions';

import type { Locale } from './LocaleReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

export type RegionLocaleBase = {
  id: number;
  isDraft: boolean;
  regionId: number;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date | null;
};

export type RegionLocaleNormalized = Merge<
  RegionLocaleBase,
  {
    locale: number;
  }
>;

export type RegionLocale = Merge<
  RegionLocaleBase,
  {
    locale: Locale;
  }
>;

export type RegionLocaleByIdState = {
  [k: string | number]: RegionLocaleNormalized;
};
export type RegionLocaleAllIdsState = {
  [k: string | number]: Array<number>;
};
export type RegionLocaleState = {
  byId: RegionLocaleByIdState;
  allIds: RegionLocaleAllIdsState;
};

const initialStateById: RegionLocaleByIdState = {};
const initialStateAllIds: RegionLocaleAllIdsState = {};

//
//
export const getEmptyRegionLocale = (id?: number): RegionLocale => ({
  id: id ?? -1,
  isDraft: false,
  regionId: -1,
  locale: getEmptyLocale(),
  createdAt: new Date(),
  createdBy: -1,
  updatedAt: null,
});

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): RegionLocaleByIdState => {
  switch (action.type) {
    case REGION_GET_LIST_OK:
    case REGION_GET_DETAILS_OK:
      return {
        ...state,
        ...action.payload.entities.regionLocales,
      };

    case REGION_REMOVE_OK: {
      const newState = { ...state };
      action.region.regionLocales.forEach((rc: RegionLocale) => delete newState[rc.id]);
      return newState;
    }

    case REGIONLOCALE_CREATE_OK:
    case REGIONLOCALE_UPDATE_OK: {
      const id = action.payload.result;
      return {
        ...state,
        [id]: action.payload.entities.regionLocales[id],
      };
    }

    case REGIONLOCALE_REMOVE_OK: {
      const newState = { ...state };
      delete newState[action.localeId];
      return newState;
    }

    default:
      return state;
  }
};

//
//
// eslint-disable-next-line default-param-last
const allIds = (state = initialStateAllIds, action: AnyAction): RegionLocaleAllIdsState => {
  switch (action.type) {
    case REGION_GET_LIST_OK: {
      const newState = { ...state };
      action.payload.result.forEach((regionId: number) => {
        const region = { ...action.payload.entities.regions[regionId] };
        newState[region.id] = region.regionLocales;
      });
      return newState;
    }

    case REGION_GET_DETAILS_OK:
      if (action.payload.entities.regionLocales == null) {
        return {
          ...state,
          [action.regionId]: [],
        };
      }
      return {
        ...state,
        [action.regionId]: Object.keys(action.payload.entities.regionLocales).map((id) =>
          parseInt(id, 10)
        ),
      };

    case REGION_REMOVE_OK: {
      const { [action.region.id]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case REGIONLOCALE_CREATE_OK:
      return {
        ...state,
        [action.regionId]: state[action.regionId].concat(action.payload.result),
      };

    case REGIONLOCALE_REMOVE_OK:
      return {
        ...state,
        [action.regionId]: state[action.regionId].filter((id) => id !== action.localeId),
      };

    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
  allIds,
});

export default combined;
