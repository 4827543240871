import { message } from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getAppList } from '../actions/apps';
import {
  ROUTE_NAME_APP_HOME,
  ROUTE_NAME_INTERNAL_APPCREATE,
  ROUTE_NAME_INTERNAL_APPDETAIL,
} from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import AppsList from '../pages/AppsList';
import { getAllApps, getAppUiState } from '../selectors/apps/advanced';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { App } from '../reducers/AppsReducer';

//
//
const AppsListContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const allApps = useGlobalSelector(getAllApps);
  const appUiState = useGlobalSelector(getAppUiState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAppList());
      } catch (err) {
        const { msg } = logUnknownError(err);
        message.error(msg);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleAddApp = (setupVersion: string) => {
    history.push(`${getUrlByName(ROUTE_NAME_INTERNAL_APPCREATE)}?v=${setupVersion}`);
  };

  const handleAppHome = (app: App) => {
    history.push(getUrlByName(ROUTE_NAME_APP_HOME, { ':appUid': app.uid }));
  };

  const handleAppEdit = (app: App) => {
    history.push(getUrlByName(ROUTE_NAME_INTERNAL_APPDETAIL, { ':appId': app.id }));
  };

  return (
    <AppsList
      allApps={allApps}
      handleAddApp={handleAddApp}
      handleAppHome={handleAppHome}
      handleAppEdit={handleAppEdit}
      isLoading={appUiState.isFetchingAppList}
      error={appUiState.isFetchingAppListError}
    />
  );
};

export default AppsListContainer;
