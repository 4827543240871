import { ResourceType } from './resource-type';

export const SPREADSHEET_MIME_TYPES = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
};

export const VIDEO_MIME_TYPES = {
  mp4: 'video/mp4',
  h263: 'video/H263',
  h264: 'video/H264',
  h265: 'video/H265',
  ogg: 'video/ogg',
  mov: 'video/quicktime',
  qt: 'video/quicktime',
  webm: 'video/VP8',
};

export const AUDIO_MIME_TYPES = {
  mpeg: 'audio/mpeg',
  mp3: 'audio/mp3',
  ogg: 'audio/ogg',
  wav: 'audio/wav',
};

export const IMAGE_MIME_TYPES = {
  png: 'image/png',
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  gif: 'image/gif',
  tiff: 'image/tiff',
  heic: 'image/heic',
  heif: 'image/heif',
  bmp: 'image/bmp',
};

export const SUBTITLE_MIME_TYPES = {
  vtt: 'text/vtt',
  srt: 'text/srt',
};

export const MIME_TYPES = {
  [ResourceType.IMAGE_MEDIABOX]: IMAGE_MIME_TYPES,
  [ResourceType.VIDEO_MEDIABOX]: VIDEO_MIME_TYPES,
  [ResourceType.SUBTITLES_MEDIABOX]: SUBTITLE_MIME_TYPES,
};
