import { schema } from 'normalizr';

const defaultOptions = {
  processStrategy: (obj: {
    createdAt: string | null | undefined;
    updatedAt: string | null | undefined;
  }) => ({
    ...obj,
    createdAt: typeof obj.createdAt === 'string' ? new Date(obj.createdAt) : null,
    updatedAt: typeof obj.updatedAt === 'string' ? new Date(obj.updatedAt) : null,
  }),
};

export const UserSchema = new schema.Entity('users');
export const UserAppSchema = new schema.Entity('userApps');
export const AppUserSchema = new schema.Entity('appUsers');
export const AppSchema = new schema.Entity('apps', {}, defaultOptions);
export const RoleSchema = new schema.Entity('roles', {}, defaultOptions);

export const AppPlatformSchema = new schema.Entity('appPlatforms');
export const PlatformSchema = new schema.Entity('platforms');
export const AppVersionSchema = new schema.Entity('appVersions', {}, defaultOptions);

export const AppAuthServiceSchema = new schema.Entity('appAuthServices');
export const AuthServiceSchema = new schema.Entity('authServices');

export const BatchUploadSchema = new schema.Entity('batchUploads', {}, defaultOptions);
export const BatchUploadItemSchema = new schema.Entity('batchUploadItems', {}, defaultOptions);

export const RegionSchema = new schema.Entity('regions', {}, defaultOptions);
export const RegionLocaleSchema = new schema.Entity('regionLocales', {}, defaultOptions);
export const RegionCountrySchema = new schema.Entity('regionCountries', {}, defaultOptions);
export const LocaleSchema = new schema.Entity('locales');
export const CountrySchema = new schema.Entity('countries');
export const TimezoneSchema = new schema.Entity('timezones');
export const EpisodeSchema = new schema.Entity('episodes', {}, defaultOptions);
export const EpisodeContentSchema = new schema.Entity('episodeContent');
export const EpisodeTagSchema = new schema.Entity('episodeTags');
export const EpisodeVersionRequirementSchema = new schema.Entity('episodeRequirements');
export const ResourceSchema = new schema.Entity('resources', {}, defaultOptions);
export const FileSchema = new schema.Entity('files', {}, defaultOptions);
// export const LogStatusSchema = new schema.Entity('status', {}, { idAttribute: 'statusId' });
export const LocalizedEpisodeSchema = new schema.Entity('localizedEpisodes', {}, defaultOptions);
export const ReleaseCategorySchema = new schema.Entity('releaseCategories');
export const CategorySchema = new schema.Entity('categories', {}, defaultOptions);
export const TagSchema = new schema.Entity('tags');
export const JobSchema = new schema.Entity('jobs', {}, defaultOptions);
export const JobLogSchema = new schema.Entity('jobLogs', {}, defaultOptions);
export const DeploymentSchema = new schema.Entity('deployments', {}, defaultOptions);
export const AssetSchema = new schema.Entity('assets', {}, defaultOptions);
export const AssetResourceSchema = new schema.Entity('assetResources', {}, defaultOptions);
export const AssetResourceVersionRequirementSchema = new schema.Entity('assetResourceRequirements');
export const AnalyticsExport = new schema.Entity(
  'analyticsExports',
  {},
  {
    processStrategy: (obj: { createdAt: string | null | undefined }) => ({
      ...obj,
      createdAt: typeof obj.createdAt === 'string' ? new Date(obj.createdAt) : null,
    }),
  }
);
export const ReleaseSchema = new schema.Entity(
  'releases',
  {},
  {
    processStrategy: (obj: {
      createdAt: string | null | undefined;
      updatedAt: string | null | undefined;
      publishDate: string | null | undefined;
      unpublishDate: string | null | undefined;
    }) => ({
      ...obj,
      createdAt: typeof obj.createdAt === 'string' ? new Date(obj.createdAt) : null,
      updatedAt: typeof obj.updatedAt === 'string' ? new Date(obj.updatedAt) : null,
      publishDate: typeof obj.publishDate === 'string' ? new Date(obj.publishDate) : null,
      unpublishDate: typeof obj.unpublishDate === 'string' ? new Date(obj.unpublishDate) : null,
    }),
  }
);

BatchUploadSchema.define({
  episodes: [EpisodeSchema],
});

UserSchema.define({
  apps: [UserAppSchema],
  roles: [RoleSchema],
});

UserAppSchema.define({
  app: AppSchema,
});

AppUserSchema.define({
  user: UserSchema,
});

AppSchema.define({
  users: [AppUserSchema],
  platforms: [AppPlatformSchema],
  // createdBy: user, // is id right now
  authServices: [AppAuthServiceSchema],
  icon: ResourceSchema,
});

AppAuthServiceSchema.define({
  authService: AuthServiceSchema,
});

AppPlatformSchema.define({
  platform: PlatformSchema,
  versions: [AppVersionSchema],
});

RegionCountrySchema.define({
  country: CountrySchema,
  // createdBy: UserSchema,
});

RegionLocaleSchema.define({
  locale: LocaleSchema,
});

RegionSchema.define({
  regionCountries: [RegionCountrySchema],
  regionLocales: [RegionLocaleSchema],
  releaseTimezone: TimezoneSchema,
  // defaultLocale: RegionLocaleSchema,
});

EpisodeVersionRequirementSchema.define({
  appPlatform: AppPlatformSchema,
  minVersion: AppVersionSchema,
  maxVersion: AppVersionSchema,
});

EpisodeSchema.define({
  localizedEpisodes: [LocalizedEpisodeSchema],
  thumbnail: ResourceSchema,
  tags: [EpisodeTagSchema],
  requirements: [EpisodeVersionRequirementSchema],
});

EpisodeContentSchema.define({
  resource: ResourceSchema,
});

EpisodeTagSchema.define({
  tag: TagSchema,
});

LocalizedEpisodeSchema.define({
  locale: LocaleSchema,
  content: [EpisodeContentSchema],
});

ResourceSchema.define({
  file: FileSchema,
});

FileSchema.define({
  versions: [FileSchema],
  resource: ResourceSchema,
});

// LogSchema.define({
//   status: LogStatusSchema,
// });

ReleaseCategorySchema.define({
  category: CategorySchema,
});

ReleaseSchema.define({
  episode: EpisodeSchema,
  region: RegionSchema,
  categories: [ReleaseCategorySchema],
});

DeploymentSchema.define({
  creator: UserSchema,
});

JobSchema.define({
  input: FileSchema,
  outputs: [FileSchema],
  logs: [JobLogSchema],
});

AssetSchema.define({
  resources: [AssetResourceSchema],
});

AssetResourceSchema.define({
  resource: ResourceSchema,
  requirements: [AssetResourceVersionRequirementSchema],
});

AssetResourceVersionRequirementSchema.define({
  appPlatform: AppPlatformSchema,
  minVersion: AppVersionSchema,
  maxVersion: AppVersionSchema,
});
