import { FileSearchOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

import { BatchUploadComponentState } from './constants';
import { SPREADSHEET_MIME_TYPES } from '../../common/constants/mime';
import MbxButton from '../base/MbxButton';
import FilePicker from '../FilePicker';

import type { UploadFile } from 'antd';

type Props = Readonly<{
  metadata: File | null;
  setMetadata: (files: File) => void;
  state: BatchUploadComponentState;
  setState: (state: BatchUploadComponentState) => void;
}>;

const MetadataPicker = ({ metadata, setMetadata, state, setState }: Props) => {
  const list = () => {
    return metadata ? (
      <div
        style={{
          backgroundColor: 'lightgrey',
          border: '1px transparent',
          borderRadius: 20,
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 10,
          paddingRight: 10,
          margin: 4,
        }}
      >
        {metadata.name}
      </div>
    ) : (
      <div></div>
    );
  };

  const message = () => {
    if (state === BatchUploadComponentState.VALIDATING) {
      return;
    }
    const prefix =
      state === BatchUploadComponentState.COLLECTING_METADATA ? (
        <span style={{ marginRight: 4 }}>Please drop your metadata spreadsheet here or</span>
      ) : null;
    const prompt =
      state === BatchUploadComponentState.COLLECTING_METADATA
        ? 'browse your files'
        : 'Browse files again';
    const onChange = ({ file }: { file: UploadFile }) => {
      setMetadata(file.originFileObj as File);
    };
    return (
      <div style={{ marginTop: '0.5em' }}>
        {prefix}
        <Upload
          data-tid="bu-metadata-input"
          accept={Object.keys(SPREADSHEET_MIME_TYPES)
            .map((ext) => `.${ext}`)
            .join(',')}
          showUploadList={false}
          multiple={false}
          customRequest={() => setState(BatchUploadComponentState.METADATA_FOUND)}
          onChange={onChange}
        >
          <MbxButton mbxType="tertiary" linkColor="#3ebfff" size="small">
            {prompt}
          </MbxButton>
        </Upload>
      </div>
    );
  };

  const icon = () => {
    switch (state) {
      case BatchUploadComponentState.COLLECTING_METADATA:
      case BatchUploadComponentState.METADATA_FOUND:
      case BatchUploadComponentState.VALIDATING:
      default:
        return <FileSearchOutlined style={{ display: 'block', fontSize: '70px', opacity: 0.25 }} />;
    }
  };

  const subtitle = () => {
    switch (state) {
      case BatchUploadComponentState.COLLECTING_METADATA:
        return (
          <div style={{ opacity: 0.25 }}>
            supports {Object.keys(SPREADSHEET_MIME_TYPES).join(', ')}
          </div>
        );
      case BatchUploadComponentState.VALIDATING:
        return <div style={{ marginTop: '0.5em', opacity: 0.25 }}>Validating...</div>;
      default:
        return <div></div>;
    }
  };

  const content = () => {
    return (
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {icon()}
        {list()}
        {message()}
        {subtitle()}
      </div>
    );
  };

  return (
    <FilePicker
      content={content}
      setFiles={(files) => {
        setMetadata(files[0]);
        setState(BatchUploadComponentState.METADATA_FOUND);
      }}
      accepted={Object.values(SPREADSHEET_MIME_TYPES)}
      disabled={state === BatchUploadComponentState.VALIDATING}
      oneFile
    />
  );
};

export default MetadataPicker;
