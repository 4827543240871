import { Redirect } from 'react-router-dom';

import RegisterFormContainer from '../containers/RegisterFormContainer';
import { ROUTE_NAME_HOME } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getUserProfileOptional } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

//
//
const RegisterPage = () => {
  const user = useGlobalSelector(getUserProfileOptional);

  if (user != null) {
    return <Redirect to={getUrlByName(ROUTE_NAME_HOME)} />;
  }

  return (
    <div>
      <h1>Create a new account for Mediabox</h1>
      <RegisterFormContainer />
    </div>
  );
};

export default RegisterPage;
