import { Modal, Tag } from 'antd';

import EpisodeLocalizedForm from './EpisodeLocalizedForm';
import styles from '../pages/EpisodeDetail.module.scss';

import type { EpisodeLocalizedFormValues } from './EpisodeLocalizedForm';
import type { App } from '../reducers/AppsReducer';
import type { AllowedContentTypes } from '../reducers/EpisodeContentReducer';
import type { EpisodeLocalized } from '../reducers/EpisodeLocalizedReducer';
import type { Episode } from '../reducers/EpisodeReducer';
import type { RegionLocale } from '../reducers/RegionLocaleReducer';

type Props = Readonly<{
  data: EpisodeLocalized | null | undefined;
  episode: Episode;
  app: App;
  visible: boolean;
  selectedLocalization: RegionLocale | null;
  canUserRerunConversion: boolean;

  onSubmit: (
    data: EpisodeLocalized | null | undefined,
    values: EpisodeLocalizedFormValues
  ) => Promise<void>;
  onClose: () => void;
  afterClose: () => void;
  onFileSelect: (
    fileRef: File,
    resourceType: number,
    contentType: AllowedContentTypes,
    episodeLocalizedId: number,
    appPlatformId: number | null
  ) => Promise<void>;
  onContentRemove: (episodelocalizedId: number, contentId: number) => Promise<boolean>;
  onRerunConversion: (resourceId: number) => Promise<void>;
}>;

//
//
const EpisodeLocalizedAddModal = (props: Props) => {
  const {
    data,
    visible,
    selectedLocalization,
    afterClose,
    episode,
    canUserRerunConversion,
    app,
    onSubmit,
    onContentRemove,
    onFileSelect,
    onRerunConversion,
  } = props;

  const handleClose = () => {
    const { onClose } = props;
    onClose();
    // TODO: check if we can remove this after moving form to own comp: form.resetFields();
  };

  if (selectedLocalization === null) {
    return null;
  }

  const shortcode = selectedLocalization.locale?.shortcode ?? '';
  const title = data === null ? `Add language` : `Edit language`;

  return (
    <Modal
      width={570}
      title={
        <>
          <span className={styles.listItemTitle}>{title}</span>
          <Tag className={styles.listItemTag}>{shortcode}</Tag>
        </>
      }
      centered
      open={visible}
      footer={null}
      // onOk={handleOk}
      onCancel={handleClose}
      afterClose={afterClose}
    >
      <EpisodeLocalizedForm
        data={data}
        episode={episode}
        canUserRerunConversion={canUserRerunConversion}
        app={app}
        onSubmit={onSubmit}
        onContentRemove={onContentRemove}
        onFileSelect={onFileSelect}
        onRerunConversion={onRerunConversion}
      />
    </Modal>
  );
};

export default EpisodeLocalizedAddModal;
