import {
  QuestionOutlined,
  LoadingOutlined,
  CloseOutlined,
  CheckOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Progress } from 'antd';

import { AUDIO_VERSION_LABELS, isAudioTemplateName } from '../common/constants/audio-template';
import { ContentType } from '../common/constants/content-type';
import { FileStatus } from '../common/constants/file-status';
import { MANIFEST_FILENAME, ResourceType } from '../common/constants/resource-type';
import { VIDEO_VERSION_LABELS, isVideoTemplateName } from '../common/constants/video-template';
import { useCurrentAppContext } from '../contexts';
import { getAllQueueItems } from '../selectors/queueSelectors';
import { getResourceByContentType } from '../utils/episodeLocalized';
import { useGlobalSelector } from '../utils/hooks';
import { getFileUploadStatus } from '../utils/queue';

import type { EpisodeLocalized } from '../reducers/EpisodeLocalizedReducer';
import type { AllowedTemplateNames } from '../reducers/FileReducer';

const DEFAULT_ICON_STYLE = { fontSize: '24px', color: '#ccc' };
const DEFAULT_ICON_STYLE_SMALL = { fontSize: '12px', color: '#ccc' };

type Props = Readonly<{
  le: EpisodeLocalized | null | undefined;
  width?: number;
  height?: number;
  resourceTypeId: ResourceType;
  currentAppPlatform: number | null;
}>;

const getStatusIcon = (status: number, isSmall = false) => {
  const style = isSmall ? DEFAULT_ICON_STYLE_SMALL : DEFAULT_ICON_STYLE;
  if (status === FileStatus.ERRORED || status === FileStatus.TIMEDOUT) {
    return <WarningOutlined style={{ ...style, color: 'red' }} />;
  }
  if (status === FileStatus.UNKNOWN) {
    return <QuestionOutlined style={{ ...style, color: 'red' }} />;
  }
  return <LoadingOutlined style={style} />;
};

const getLabelForTemplate = (resourceTypeId: ResourceType, templateName: AllowedTemplateNames) => {
  if (resourceTypeId === ResourceType.VIDEO_MEDIABOX) {
    if (isVideoTemplateName(templateName)) {
      return VIDEO_VERSION_LABELS[templateName as keyof typeof VIDEO_VERSION_LABELS];
    }
  }
  if (resourceTypeId === ResourceType.AUDIO_ONLY) {
    if (isAudioTemplateName(templateName)) {
      return AUDIO_VERSION_LABELS[templateName as keyof typeof AUDIO_VERSION_LABELS];
    }
  }
  return '??';
};

//
//
const EpisodeLocalizedContentPreview = (props: Props) => {
  const { le, width = 89, height = 50, resourceTypeId, currentAppPlatform } = props;
  const { currentApp } = useCurrentAppContext();
  const queuedItems = useGlobalSelector(getAllQueueItems);
  let thumb;

  const thumbnailInfo = getFileUploadStatus(
    le,
    queuedItems,
    ContentType.EPISODE_CONTENT,
    currentAppPlatform
  );
  const { queued, percentUploaded } = thumbnailInfo;
  const content = getResourceByContentType(le, ContentType.EPISODE_CONTENT, currentAppPlatform);

  if (queued === true) {
    thumb = <Progress type="circle" percent={percentUploaded} width={40} />;
  } else if (typeof le === 'undefined' || le === null) {
    thumb = <CloseOutlined style={DEFAULT_ICON_STYLE} />;
  } else if (content !== null) {
    const versions = content?.file?.versions ?? [];

    if (
      resourceTypeId === ResourceType.VIDEO_MEDIABOX ||
      resourceTypeId === ResourceType.AUDIO_ONLY ||
      resourceTypeId === ResourceType.SUBTITLES_MEDIABOX
    ) {
      if (Array.isArray(versions) && versions.length > 0) {
        // video/mediabox or audio/mediabox
        // look for different versions
        const contentX = versions.map((f) => {
          const templateName = f?.meta?.templateName;

          if (templateName == null) {
            throw new Error('template name not set');
          }

          let status;
          const newestLogEntry = f.lastStatusId;
          if (newestLogEntry === FileStatus.READY) {
            status = (
              <span>
                <a
                  href={`${currentApp.privateBaseUrl}/${f.name}.${f.ext}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CheckOutlined style={{ ...DEFAULT_ICON_STYLE_SMALL, color: 'green' }} />
                </a>
              </span>
            );
          } else {
            status = getStatusIcon(newestLogEntry, true);
          }

          return (
            <div key={`temp-${templateName}`} style={{ lineHeight: '12px' }}>
              <span style={{ fontSize: '9px' }}>{`${getLabelForTemplate(
                resourceTypeId,
                templateName
              )}: `}</span>
              {status}
            </div>
          );
        });
        thumb = contentX;
      } else {
        thumb = getStatusIcon(content?.file?.lastStatusId ?? FileStatus.UNKNOWN);
      }
    } else if (
      resourceTypeId === ResourceType.ARCHIVE_WEBPAGE ||
      resourceTypeId === ResourceType.ARCHIVE_UNITYBUNDLE
    ) {
      if (Array.isArray(versions) && versions.length > 0) {
        // video/mediabox
        // look for different versions
        const contentX = versions.map((f) => {
          let status;
          if (f.lastStatusId === FileStatus.READY) {
            const href =
              f.type === 'folder'
                ? `${currentApp.privateBaseUrl}/${f.name}/${MANIFEST_FILENAME}`
                : `${currentApp.privateBaseUrl}/${f.name}.${f.ext}`;
            status = (
              <span>
                <a href={href} target="_blank" rel="noopener noreferrer">
                  <CheckOutlined style={{ ...DEFAULT_ICON_STYLE_SMALL, color: 'green' }} />
                </a>
              </span>
            );
          } else {
            status = getStatusIcon(f.lastStatusId, true);
          }

          const description = f.type === 'folder' ? 'unzipped' : 'zip';
          return (
            <div key={`temp-${f.id}`} style={{ lineHeight: '20px' }}>
              <span>{`${f.id}: `}</span>
              <span style={{ fontSize: 10 }}>{`(${description}) `}</span>
              {status}
            </div>
          );
        });
        thumb = contentX;
      } else {
        thumb = <LoadingOutlined style={DEFAULT_ICON_STYLE} />;
      }
    }
  } else {
    thumb = <CloseOutlined style={DEFAULT_ICON_STYLE} />;
  }

  if (typeof thumb === 'undefined') {
    thumb = <QuestionOutlined style={{ ...DEFAULT_ICON_STYLE, color: 'orange' }} />;
  }

  return (
    <div
      style={{
        width,
        minWidth: width,
        height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#eee',
        overflowY: 'scroll',
      }}
    >
      {thumb}
    </div>
  );
};

export default EpisodeLocalizedContentPreview;
