import { message } from 'antd';
import { Workbook } from 'exceljs';

import { BatchUploadComponentState } from './constants';
import {
  addUploadToQueue,
  checkNextInQueue,
  removeBatchUploadFromQueue,
} from '../../actions/queue';
import { BatchUploadStatusGrouping } from '../../common/constants/batch-upload';
import { ContentType } from '../../common/constants/content-type';
import { ROUTE_NAME_APP_EPISODES } from '../../navigation/routes';
import { getUrlByName } from '../../navigation/utils';
import { QueueItemType } from '../../reducers/QueueReducer';
import { setupParser } from '../../utils/excel';
import { normalizeUnicode } from '../../utils/lang';
import { logUnknownError } from '../../utils/log';
import { CHUNK_SIZE, FILE_THRESHOLD } from '../../utils/multipart-constants';

import type { AppDispatch } from '../../configureStore';
import type { BatchUploadItem, BatchUpload } from '../../reducers/BatchUploadReducer';
import type { QueueItem } from '../../reducers/QueueReducer';
import type { History } from 'history';

export const isntMyUpload = (
  upload: BatchUpload | null,
  userId: number | null,
  countOnlyIfInProgress = false
) => {
  const otherUserUploadExists = upload !== null && upload.createdBy !== userId;

  return countOnlyIfInProgress
    ? otherUserUploadExists && BatchUploadStatusGrouping.IN_PROGRESS.includes(upload.status)
    : otherUserUploadExists;
};

// https://github.com/Inist-CNRS/node-csv-string/blob/master/src/types.ts
const detectCsvDelimiter = (input: string) => {
  const separators = [',', ';', '|', '\t'];
  const idx = separators
    .map((separator) => input.indexOf(separator))
    .reduce((prev, cur) => (prev === -1 || (cur !== -1 && cur < prev) ? cur : prev));
  return (input[idx] || ',') as ',' | ';' | '|' | '\t';
};

function loadFileAsBuffer(f: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(f);
    reader.onload = async () => {
      const buffer = reader.result;
      if (buffer == null || typeof buffer === 'string') {
        reject(new Error('buffer wrong type'));
        return;
      }
      resolve(buffer);
    };
  });
}

export function isFile(input: unknown): input is File {
  return (input as File).name != null;
}

// wrapper to get a exceljs readable stream, as well as the char used as the delimiter in the file
// see https://github.com/exceljs/exceljs/issues/832
export const { parseCSV, parseXLSX } = setupParser(
  new Workbook(),
  async (input: File | ArrayBuffer): Promise<{ buffer: ArrayBuffer; delimiter: string }> => {
    let buffer;
    let delimiter = '';
    if (isFile(input)) {
      buffer = await loadFileAsBuffer(input);
      delimiter = detectCsvDelimiter(await input.text());
    } else {
      buffer = input;
    }
    return { buffer, delimiter };
  }
);

// convert component state to numerical step
export const currentStep = (state: BatchUploadComponentState) => {
  switch (state) {
    case BatchUploadComponentState.LANDING_PAGE:
    case BatchUploadComponentState.GENERATING_TEMPLATE:
    case BatchUploadComponentState.VALID_TEMPLATE:
    case BatchUploadComponentState.TEMPLATE_GENERATED:
      return 1;
    case BatchUploadComponentState.COLLECTING_METADATA:
    case BatchUploadComponentState.METADATA_FOUND:
    case BatchUploadComponentState.VALIDATING:
    case BatchUploadComponentState.VALIDATION_SUCCESS:
    case BatchUploadComponentState.VALIDATION_FAILURE:
    case BatchUploadComponentState.VALIDATION_WARNINGS:
      return 2;
    case BatchUploadComponentState.READY_TO_COLLECT:
    case BatchUploadComponentState.COLLECTING_FILES:
    case BatchUploadComponentState.ALL_COLLECTED:
      return 3;
    case BatchUploadComponentState.QUEUING_ITEMS:
    case BatchUploadComponentState.UPLOADING_CLIENT:
    case BatchUploadComponentState.UPLOAD_DONE_FROM_CLIENT:
    case BatchUploadComponentState.CREATING_FILES:
    case BatchUploadComponentState.UPLOAD_SUCCESS:
    case BatchUploadComponentState.UPLOAD_MISSING_FILES:
      return 4;
  }
};

export const returnToEpisodes = (uid: string, history: History) => () => {
  history.push(
    getUrlByName(ROUTE_NAME_APP_EPISODES, {
      ':appUid': uid,
    })
  );
};

export const getCurrentlyUploadingItems = (queuedItems: Array<QueueItem>) =>
  queuedItems.filter(
    ({ state: itemState, type }) =>
      ['added', 'initializing', 'uploading'].includes(itemState) &&
      type === QueueItemType.BATCH_CONTENT
  );

// put episodes first to get more accurate estimates of upload speed
const sortByContentType = (
  { contentTypeId: a }: BatchUploadItem,
  { contentTypeId: b }: BatchUploadItem
) => {
  if (a === ContentType.EPISODE_CONTENT) {
    return -1;
  }
  if (b === ContentType.EPISODE_CONTENT) {
    return 1;
  }
  return 0;
};

export const setupUploadQueue = (appId: number, dispatch: AppDispatch) => {
  const addBatchUploadToQueue = (files: Array<File>, items: Array<BatchUploadItem>) => {
    try {
      for (const item of items.sort(sortByContentType)) {
        const file = files.find(({ name }) => item.name === normalizeUnicode(name));

        if (file == null) {
          throw new Error(`File ${item.name} was deleted`);
        }

        const MULTIPART_ENABLED = process.env.REACT_APP_MULTIPART_ENABLED === 'true';
        /*
          The current chunk size is 5MB, with a AWS limit of 1000 total chunks
        */
        const multipartEnabled =
          MULTIPART_ENABLED && file.size > FILE_THRESHOLD && file.size < CHUNK_SIZE * 1000;

        console.log({ multipartEnabled });

        dispatch(
          addUploadToQueue(
            {
              appId,
              fileRef: {
                name: file.name,
                type: file.type,
                size: file.size,
                blob: file,
              },
              appPlatformId: null,
              type: QueueItemType.BATCH_CONTENT,
              batchContent: item,
              multipartEnabled,
            },
            file
          )
        );
        dispatch(checkNextInQueue());
      }
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const removeFromQueue = () => {
    dispatch(removeBatchUploadFromQueue(appId));
    dispatch(checkNextInQueue());
  };

  return { addBatchUploadToQueue, removeFromQueue };
};
