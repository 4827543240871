import type { AppNormalized } from '../reducers/AppsReducer';

export type AppsGetListOkAction = {
  type: 'GET_APPS_LIST_OK';
  payload: {
    result: number;
    entities: {
      apps: { [k: string | number]: AppNormalized };
    };
  };
};

export const GET_APPS_LIST = 'GET_APPS_LIST';
export const GET_APPS_LIST_OK = 'GET_APPS_LIST_OK';
export const GET_APPS_LIST_FAIL = 'GET_APPS_LIST_FAIL';

export const GET_APP_DETAILS = 'GET_APP_DETAILS';
export const GET_APP_DETAILS_OK = 'GET_APP_DETAILS_OK';
export const GET_APP_DETAILS_FAIL = 'GET_APP_DETAILS_FAIL';

export const REMOVE_USER_FROM_APP = 'REMOVE_USER_FROM_APP';
export const REMOVE_USER_FROM_APP_OK = 'REMOVE_USER_FROM_APP_OK';
export const REMOVE_USER_FROM_APP_FAIL = 'REMOVE_USER_FROM_APP_FAIL';

export const APP_CREATE = 'APP_CREATE';
export const APP_CREATE_OK = 'APP_CREATE_OK';
export const APP_CREATE_FAIL = 'APP_CREATE_FAIL';

export const APP_UPDATE = 'APP_UPDATE';
export const APP_UPDATE_OK = 'APP_UPDATE_OK';
export const APP_UPDATE_FAIL = 'APP_UPDATE_FAIL';

export const APP_UPDATE_CONFIG = 'APP_UPDATE_CONFIG';
export const APP_UPDATE_CONFIG_OK = 'APP_UPDATE_CONFIG_OK';
export const APP_UPDATE_CONFIG_FAIL = 'APP_UPDATE_CONFIG_FAIL';

export const APP_DELETE_ICON = 'APP_DELETE_ICON';
export const APP_DELETE_ICON_OK = 'APP_DELETE_ICON_OK';
export const APP_DELETE_ICON_FAIL = 'APP_DELETE_ICON_FAIL';

export const APP_UPDATE_ICON = 'APP_UPDATE_ICON';
export const APP_UPDATE_ICON_OK = 'APP_UPDATE_ICON_OK';
export const APP_UPDATE_ICON_FAIL = 'APP_UPDATE_ICON_FAIL';
