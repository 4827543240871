export const REGIONLOCALE_CREATE = 'REGIONLOCALE_CREATE';
export const REGIONLOCALE_CREATE_OK = 'REGIONLOCALE_CREATE_OK';
export const REGIONLOCALE_CREATE_FAIL = 'REGIONLOCALE_CREATE_FAIL';

export const REGIONLOCALE_REMOVE = 'REGIONLOCALE_REMOVE';
export const REGIONLOCALE_REMOVE_OK = 'REGIONLOCALE_REMOVE_OK';
export const REGIONLOCALE_REMOVE_FAIL = 'REGIONLOCALE_REMOVE_FAIL';

export const REGIONLOCALE_UPDATE = 'REGIONLOCALE_UPDATE';
export const REGIONLOCALE_UPDATE_OK = 'REGIONLOCALE_UPDATE_OK';
export const REGIONLOCALE_UPDATE_FAIL = 'REGIONLOCALE_UPDATE_FAIL';
