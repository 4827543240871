export enum ResourceType {
  IMAGE_MEDIABOX = 101,
  VIDEO_MEDIABOX = 201,
  ARCHIVE_WEBPAGE = 301,
  ARCHIVE_UNITYBUNDLE = 302,
  ARCHIVE_ASSETS = 390,
  AUDIO_ONLY = 401,
  SUBTITLES_MEDIABOX = 501,
  APP_ICON = 601,
}

export type ResourceTypeKey = `${ResourceType}`;

export const ResourceTypeNames = {
  [ResourceType.IMAGE_MEDIABOX]: 'image/mediabox',
  [ResourceType.VIDEO_MEDIABOX]: 'video/mediabox',
  [ResourceType.ARCHIVE_WEBPAGE]: 'archive/webpage',
  [ResourceType.ARCHIVE_UNITYBUNDLE]: 'archive/unitybundle',
  [ResourceType.ARCHIVE_ASSETS]: 'archive/assets',
  [ResourceType.AUDIO_ONLY]: 'audio/mediabox',
  [ResourceType.SUBTITLES_MEDIABOX]: 'subtitles/mediabox',
  [ResourceType.APP_ICON]: 'icon/mediabox',
} as const;

export function isValidResourceType(t: unknown): t is ResourceType {
  return typeof t === 'number' && (t as number) in ResourceType;
}

export const VALID_RESOURCE_TYPES = Object.values(ResourceType).reduce((prev, curr) => {
  if (isValidResourceType(curr)) {
    prev.push(curr);
  }
  return prev;
}, [] as Array<ResourceType>);

export const ResourceTypeShortNames = {
  [ResourceType.VIDEO_MEDIABOX]: 'Video',
} as const;

export const ConversionTypes = {
  IMAGES: 'images',
  VIDEOS: 'videos',
  AUDIOS: 'audios',
  ALL_PLAYABLE_MEDIA: 'playable-media', // means audios and videos
} as const;

export const VALID_CONVERSION_TYPES = Object.keys(ConversionTypes).map(
  (key) => ConversionTypes[key as keyof typeof ConversionTypes]
);

export type AllowedConversionType = (typeof VALID_CONVERSION_TYPES)[number];
export type AllowedConversionTypeKey = `${AllowedConversionType}`;

export enum SubfolderName {
  ARCHIVES = 'archives',
  ASSETS = 'assets',
  IMAGES = 'images',
  VIDEOS = 'videos',
  AUDIOS = 'audio',
  SUBTITLES = 'subtitles',
}

export const ResourceSubfolderName = {
  [ResourceType.IMAGE_MEDIABOX]: SubfolderName.IMAGES,
  [ResourceType.VIDEO_MEDIABOX]: SubfolderName.VIDEOS,
  [ResourceType.ARCHIVE_WEBPAGE]: SubfolderName.ARCHIVES,
  [ResourceType.ARCHIVE_UNITYBUNDLE]: SubfolderName.ARCHIVES,
  [ResourceType.ARCHIVE_ASSETS]: SubfolderName.ASSETS,
  [ResourceType.AUDIO_ONLY]: SubfolderName.AUDIOS,
  [ResourceType.SUBTITLES_MEDIABOX]: SubfolderName.SUBTITLES,
  [ResourceType.APP_ICON]: SubfolderName.IMAGES,
} as const;

export const MANIFEST_FILENAME = 'manifest.txt';
