import { normalize } from 'normalizr';

import {
  RESOURCE_CREATE,
  RESOURCE_CREATE_OK,
  RESOURCE_CREATE_FAIL,
  RESOURCE_REMOVE,
  RESOURCE_REMOVE_OK,
  RESOURCE_REMOVE_FAIL,
  RESOURCE_RERUN_CONVERSION,
  RESOURCE_RERUN_CONVERSION_OK,
  RESOURCE_RERUN_CONVERSION_FAIL,
} from '../constants/resources';
import { ResourceSchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';
import type { Resource } from '../reducers/ResourceReducer';

//
//
export const createResource =
  (appId: number, name: string, type: number, fileType: string, contentTypeId?: number) =>
  async (dispatch: AppDispatch) => {
    const index = name.lastIndexOf('.');
    const fileExt = name.substr(index + 1);
    const response = await wrapFetch(
      {
        url: `/resources`,
        method: 'POST',
        data: {
          appId,
          typeId: type,
          fileType,
          fileExt,
          fileName: name,
          contentTypeId,
        },
      },
      dispatch,
      {
        init: RESOURCE_CREATE,
        fail: RESOURCE_CREATE_FAIL,
      },
      201
    );
    const normalizedData = normalize(response.data.data, ResourceSchema);
    dispatch({ type: RESOURCE_CREATE_OK, appId, payload: normalizedData });
    return response.data as { data: Resource; uploadUrl: string };
  };

//
//
export const deleteResource = (resource: Resource) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/resources/${resource.id}`,
      method: 'DELETE',
    },
    dispatch,
    {
      init: RESOURCE_REMOVE,
      fail: RESOURCE_REMOVE_FAIL,
    },
    204
  );
  dispatch({ type: RESOURCE_REMOVE_OK, resource });
  return true;
};

//
//
export const rerunConversionOnResource =
  (resourceId: number, appId: number) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/resources/${resourceId}/rerunconversion`,
        method: 'POST',
      },
      dispatch,
      {
        init: RESOURCE_RERUN_CONVERSION,
        fail: RESOURCE_RERUN_CONVERSION_FAIL,
      }
    );
    const normalizedData = normalize(response.data, ResourceSchema);
    // TODO: handle this in Files- + ResurceReducer
    dispatch({ type: RESOURCE_RERUN_CONVERSION_OK, payload: normalizedData, appId });
    return true;
  };

//
//
export const rerunAllConversionForApp =
  (appId: number, conversionType: string) => async (dispatch: AppDispatch) => {
    await wrapFetch(
      {
        url: `/resources/rerunconversion`,
        method: 'POST',
        data: {
          appId,
          type: conversionType,
        },
      },
      dispatch,
      null
    );
    return true;
  };
