import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

/* eslint-disable import/no-named-as-default-member */
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
/* eslint-enable import/no-named-as-default-member */

dayjs.updateLocale('en', {
  weekStart: 1,
});

export type DateWrapper = dayjs.Dayjs;

export default dayjs;

export const CLIENT_TIMEZONE = dayjs.tz.guess();

//
//
export const formatDate = (
  date: DateWrapper | Date | null | undefined,
  format = 'YYYY-MM-DD HH:mm'
) => {
  if (date == null) {
    console.warn('formatDate() no Date supplied', date);
    return '??';
  }

  if (date instanceof Date) {
    return dayjs(date).format(format);
  }

  return date.format(format);
};
