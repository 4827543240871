export const EPISODE_GET_LIST = 'EPISODE_GET_LIST';
export const EPISODE_GET_LIST_OK = 'EPISODE_GET_LIST_OK';
export const EPISODE_GET_LIST_FAIL = 'EPISODE_GET_LIST_FAIL';

export const EPISODE_GET_DETAILS = 'EPISODE_GET_DETAILS';
export const EPISODE_GET_DETAILS_OK = 'EPISODE_GET_DETAILS_OK';
export const EPISODE_GET_DETAILS_FAIL = 'EPISODE_GET_DETAILS_FAIL';

export const EPISODE_GET_RELEASES = 'EPISODE_GET_RELEASES';
export const EPISODE_GET_RELEASES_OK = 'EPISODE_GET_RELEASES_OK';
export const EPISODE_GET_RELEASES_FAIL = 'EPISODE_GET_RELEASES_FAIL';

export const EPISODE_CREATE = 'EPISODE_CREATE';
export const EPISODE_CREATE_OK = 'EPISODE_CREATE_OK';
export const EPISODE_CREATE_FAIL = 'EPISODE_CREATE_FAIL';

export const EPISODE_UPDATE = 'EPISODE_UPDATE';
export const EPISODE_UPDATE_OK = 'EPISODE_UPDATE_OK';
export const EPISODE_UPDATE_FAIL = 'EPISODE_UPDATE_FAIL';

export const EPISODE_REMOVE = 'EPISODE_REMOVE';
export const EPISODE_REMOVE_OK = 'EPISODE_REMOVE_OK';
export const EPISODE_REMOVE_FAIL = 'EPISODE_REMOVE_FAIL';

export const EPISODE_GET_METADATA = 'EPISODE_GET_METADATA';
export const EPISODE_GET_METADATA_OK = 'EPISODE_GET_METADATA_OK';
export const EPISODE_GET_METADATA_FAIL = 'EPISODE_GET_METADATA_FAIL';

export const EPISODE_TAGS_CLEANUP = 'EPISODE_TAGS_CLEANUP';
