const JAN = 'Jan';
const FEB = 'Feb';
const MAR = 'Mar';
const APR = 'Apr';
const MAY = 'May';
const JUN = 'Jun';
const JUL = 'Jul';
const AUG = 'Aug';
const SEP = 'Sep';
const OCT = 'Oct';
const NOV = 'Nov';
const DEC = 'Dec';

export const monthLabels = {
  JAN,
  FEB,
  MAR,
  APR,
  MAY,
  JUN,
  JUL,
  AUG,
  SEP,
  OCT,
  NOV,
  DEC,
} as const;

export const monthLabelsByIndex = [
  JAN,
  FEB,
  MAR,
  APR,
  MAY,
  JUN,
  JUL,
  AUG,
  SEP,
  OCT,
  NOV,
  DEC,
] as const;

export const ValidMonthLabels = Object.keys(monthLabels).map(
  (key) => monthLabels[key as keyof typeof monthLabels]
);
export type AllowedMonthLabel = (typeof ValidMonthLabels)[number];

export const monthLabelMapping = {
  [monthLabels.JAN]: 'January',
  [monthLabels.FEB]: 'February',
  [monthLabels.MAR]: 'March',
  [monthLabels.APR]: 'April',
  [monthLabels.MAY]: 'May',
  [monthLabels.JUN]: 'June',
  [monthLabels.JUL]: 'July',
  [monthLabels.AUG]: 'August',
  [monthLabels.SEP]: 'September',
  [monthLabels.OCT]: 'October',
  [monthLabels.NOV]: 'November',
  [monthLabels.DEC]: 'December',
} as const;
