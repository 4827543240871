import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { pop, push, replace } from '../actions/pagehistory';
import { useAppDispatch } from '../utils/hooks';

//
//
const BackButtonFunctionality = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(push(history.location));
    return history.listen((location, action) => {
      if (action === 'PUSH') {
        dispatch(push(location));
      } else if (action === 'REPLACE') {
        dispatch(replace(location));
      } else if (action === 'POP') {
        dispatch(pop(location));
      }
    });
  }, [dispatch, history]);

  return null;
};

export default BackButtonFunctionality;
