import { createSelector } from 'reselect';

import type { RootState } from '../reducers';

//
//
const getById = (state: RootState) => state.jobLogs.byId;

//
//
export const getJobLogByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => byId[id]
);

//
//
export const getJobLogsByIdsFunc = createSelector(
  getJobLogByIdFunc,
  //
  (getJobLogById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      ids = [];
    }
    return ids.map((id) => getJobLogById(id)).filter(Boolean);
  }
);
