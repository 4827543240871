import type { DeploymentChange } from '../../typings/deployments';

type DeploymentChangeProps = {
  change: DeploymentChange;
};

//
//
const DeploymentChangeItem = (props: DeploymentChangeProps) => {
  const { change } = props;
  const { type, data, old = '', prefix = '' } = change;

  let label;
  let info = JSON.stringify(data);
  let color = '#FFE18E';

  if (type.indexOf('update_') === 0) {
    info = `${prefix}${old} => ${data}`;
  } else if (type.indexOf('add_') === 0) {
    color = '#CAFFB0';
  } else if (type.indexOf('remove_') === 0) {
    color = '#FF8FAE';
  }

  switch (type) {
    case 'add_assets':
      label = 'App assets added';
      break;
    case 'update_assets':
      label = 'App assets updated';
      break;
    case 'remove_assets':
      label = 'App assets removed';
      break;

    case 'add_region':
      label = 'Market added';
      info = JSON.stringify(data, null, 2);
      break;
    case 'remove_region':
      label = 'Market removed';
      info = JSON.stringify(data, null, 2);
      break;
    case 'add_release':
      label = 'Release added';
      info = `${data.title}`;
      break;
    case 'remove_release':
      label = 'Release removed';
      info = `${data.title}`;
      break;
    case 'add_locale':
      label = 'Locale added';
      break;
    case 'remove_locale':
      label = 'Locale removed';
      break;
    case 'add_category':
      label = 'Category added';
      info = `"${data.name}" at position ${data.ordinal}`;
      break;
    case 'remove_category':
      label = 'Category removed';
      info = `${data.name}`;
      break;
    case 'update_category':
      label = 'Category updated';
      break;
    case 'add_country':
      label = 'Country added';
      break;
    case 'remove_country':
      label = 'Country removed';
      break;
    case 'add_qualityclass':
      label = 'Template added';
      break;
    case 'remove_qualityclass':
      label = 'Template removed';
      break;
    case 'update_qualityclass_url':
      label = 'Main content updated';
      break;
    case 'update_isdraft':
      label = 'isDraft changed';
      break;
    case 'update_title':
      label = 'title changed';
      break;
    case 'update_publishdate':
      label = 'publish-date changed';
      break;
    case 'update_unpublishdate':
      label = 'unpublish-date changed';
      break;
    case 'update_playermodule':
      label = 'player-module changed';
      break;
    case 'update_accesscategory':
      label = 'access-category changed';
      break;
    case 'update_thumbnailid':
      label = 'thumbnail-id changed';
      break;
    default:
      label = type;
  }

  return (
    <li style={{ backgroundColor: color }}>
      <span>{label}</span>
      <pre>{info}</pre>
    </li>
  );
};

export default DeploymentChangeItem;
