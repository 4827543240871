import { Button, Card } from 'antd';
import { hash } from 'immutable';

type Props = Readonly<{
  title: string;
  links: Array<HelpItem>;
}>;

export type HelpItem = {
  link: string;
  name: string;
  header?: JSX.Element;
};

const HelpBox = ({ title, links }: Props) => {
  return (
    <Card title={title} style={{ height: '150px', width: '250px' }}>
      {links.map(({ link, name, header }) => {
        return (
          <div style={{ paddingTop: 6 }} key={hash(name)}>
            {header}
            <Button
              onClick={() => window.open(link)}
              type="link"
              style={{ padding: 0, height: 'auto', whiteSpace: 'normal', textAlign: 'left' }}
            >
              {name}&nbsp;&#8594;
            </Button>
          </div>
        );
      })}
    </Card>
  );
};

export default HelpBox;
