import {
  EPISODE_GET_LIST_OK,
  EPISODE_GET_METADATA_OK,
  EPISODE_GET_DETAILS_OK,
} from '../constants/episodes';
import { LOCALE_GET_LIST_OK } from '../constants/locales';
import { REGION_GET_DETAILS_OK, REGION_GET_LIST_OK } from '../constants/regions';
import { RELEASE_GET_LIST_OK } from '../constants/releases';

import type { AnyAction } from 'redux';

export type Locale = {
  id: number;
  name: string;
  shortcode: string;
  createdAt: Date;
  updatedAt: Date | null;
};

export type LocaleState = {
  [k: string | number]: Locale;
};

//
//
export const getEmptyLocale = (id?: number): Locale => ({
  id: id ?? -1,
  name: '',
  shortcode: '',
  createdAt: new Date(),
  updatedAt: null,
});

const initialStateById: LocaleState = {};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): LocaleState => {
  switch (action.type) {
    case LOCALE_GET_LIST_OK:
      return action.payload?.entities?.locales ?? [];

    case REGION_GET_DETAILS_OK:
    case EPISODE_GET_LIST_OK:
    case EPISODE_GET_METADATA_OK:
    case EPISODE_GET_DETAILS_OK:
    case RELEASE_GET_LIST_OK:
    case REGION_GET_LIST_OK:
      return {
        ...state,
        ...(action.payload?.entities?.locales ?? []),
      };

    default:
      return state;
  }
};

export default byId;
