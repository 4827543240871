import { useEffect } from 'react';

import { getPageTitle } from '../selectors/pageHistory/advanced';
import { useGlobalSelector } from '../utils/hooks';

//
//
const PageTitle = () => {
  const pageTitle = useGlobalSelector(getPageTitle);

  useEffect(() => {
    console.log('setDocumentTitle()', pageTitle);
    window.document.title = pageTitle;
  }, [pageTitle]);

  return null;
};

export default PageTitle;
