import { CalendarOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Dropdown, List, Menu, Pagination, Select, Tag } from 'antd';
import classNames from 'classnames';
import * as React from 'react';

import styles from './EpisodeList.module.scss';
import { FeedType, FeedTypeNames } from '../common/constants/calendar-feed';
import { LocalDateFootnote } from '../components/base/LocalReleaseDates';
import MbxButton from '../components/base/MbxButton';
import ButtonLinked from '../components/ButtonLinked';
import { CopyInput } from '../components/CopyInput';
import CustomPageHeader from '../components/CustomPageHeader';
import FilterDisplay from '../components/FilterDisplay';
import ListSearchInput from '../components/ListSearchInput';
import ReleaseListItem from '../components/ReleaseListItem';
import SelectPlayerType from '../components/SelectPlayerType';
import { ROUTE_NAME_APP_REGIONDETAIL } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';

import type { SearchItem } from '../components/ListSearchInput';
import type { App } from '../reducers/AppsReducer';
import type { CalendarUrlsType } from '../reducers/CalendarFeedReducer';
import type { Category } from '../reducers/CategoryReducer';
import type { Region } from '../reducers/RegionReducer';
import type { Release } from '../reducers/ReleaseReducer';

type Props = Readonly<{
  regions: Array<Region>;
  currentApp: App;
  currentRegionId: number;
  currentPage: number;
  searchSource: Array<SearchItem>;
  categorySource: Array<SearchItem>;
  searchItems: Array<SearchItem>;
  currentFree: boolean;
  currentPageItems: Array<Release>;
  currentFilterCategories: Array<string>;
  currentFilterTypes: Array<number>;
  allReleasesForRegion: Array<Release>;
  allCategoriesForApp: Array<Category>;
  itemsPerPage: number;
  totalItems: number;
  sortedBy: string;
  calendarLinks: CalendarUrlsType | null;
  appUsingCalendarFeed: boolean;

  openEditRelease: (releaseId: number) => void;
  openEpisodeDetail: (release: Release) => void;
  openCategory: (categoryId: number) => void;

  handleSort: (sortBy: string) => void;
  handleCategoriesChanged: (ids: Array<string>) => void;
  handleTypesChanged: (ids: Array<number>) => void;
  handleOpenAddRelease: () => void;
  handleSelectSearch: (value: string | number) => void;
  handleChangeRegion: (regionId: number) => void;
  handleFilter: (q: string) => void;
  handleResetSearch: (item: SearchItem) => void;
  handleToggleFreeContent: () => void;
  handlePaginationChange: (page: number) => void;
  handleGenerateCalendarData: () => void;
}>;

//
//
const ReleaseList = (props: Props) => {
  const {
    regions,
    currentApp,
    currentPageItems,
    currentRegionId,
    currentPage,
    searchSource,
    searchItems,
    currentFilterCategories,
    currentFilterTypes,
    categorySource,
    currentFree,
    totalItems,
    itemsPerPage,
    openEditRelease,
    openCategory,
    openEpisodeDetail,
    allCategoriesForApp,
    sortedBy,
    calendarLinks,
    appUsingCalendarFeed,

    handleSort,
    handleOpenAddRelease,
    handleSelectSearch,
    handleChangeRegion,
    handleFilter,
    handleResetSearch,
    handleToggleFreeContent,
    handlePaginationChange,
    handleCategoriesChanged,
    handleTypesChanged,
    handleGenerateCalendarData,
  } = props;

  const selectedKeys = [currentRegionId.toString()];
  const currentRegion = regions.find((r) => r.id === currentRegionId);

  //
  //
  const calendarLinksOverlay = () => {
    const OverlayContainer = ({ children }: { children: React.ReactNode }) => (
      <div data-tid="calendar-feed-overlay" className={styles.calendarOverlayContainer}>
        {children}
      </div>
    );

    if (calendarLinks?.incomplete === '') {
      return (
        <OverlayContainer>
          <MbxButton mbxType="tertiary" onClick={() => handleGenerateCalendarData()}>
            Generate urls
          </MbxButton>
        </OverlayContainer>
      );
    }
    return (
      <OverlayContainer>
        <CopyInput
          label="Incomplete releases subscription"
          style={{ marginBottom: '8px' }}
          value={calendarLinks?.incomplete ?? ''}
          data-tid={`calendar-feed-input-${
            FeedTypeNames[FeedType.INCOMPLETE as keyof typeof FeedTypeNames]
          }`}
        />
        <CopyInput
          label="Published releases subscription"
          style={{ marginBottom: '8px' }}
          value={calendarLinks?.published ?? ''}
          data-tid={`calendar-feed-input-${
            FeedTypeNames[FeedType.PUBLISHED as keyof typeof FeedTypeNames]
          }`}
        />
        <CopyInput
          data-tid={`calendar-feed-input-${
            FeedTypeNames[FeedType.SCHEDULED as keyof typeof FeedTypeNames]
          }`}
          label="Scheduled releases subscription"
          value={calendarLinks?.scheduled ?? ''}
        />
      </OverlayContainer>
    );
  };

  // console.log('ReleaseList()', JSON.stringify({ regions, currentRegionId, currentRegion }));

  return (
    <div>
      <CustomPageHeader
        title="List of all releases"
        extra={[
          appUsingCalendarFeed === true && (
            <Dropdown
              key="dropdown-get-calendar"
              overlay={calendarLinksOverlay}
              placement="bottomRight"
            >
              <MbxButton data-tid="calendar-feed-dropdown" icon={<CalendarOutlined />} size="large">
                calendar feed
              </MbxButton>
            </Dropdown>
          ),
          <MbxButton
            mbxType="primary"
            onClick={handleOpenAddRelease}
            icon={<PlusOutlined />}
            key="btn-add-release"
          >
            add release
          </MbxButton>,
        ]}
        footer={
          regions.length > 1 ? (
            <Menu
              onClick={(e) => {
                const regionId = parseInt(e.key, 10);
                if (currentRegion?.id !== regionId) {
                  handleChangeRegion(regionId);
                }
              }}
              selectedKeys={selectedKeys}
              mode="horizontal"
            >
              {regions.map((r: Region) => (
                <Menu.Item key={r.id}>{r.name}</Menu.Item>
              ))}
            </Menu>
          ) : null
        }
      />

      <div
        style={{
          marginTop: '2em',
          marginBottom: '2em',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div>
          <ListSearchInput
            id="releaselist-search"
            dataSource={searchSource}
            filterOption={(inputValue, option) => {
              // console.log('filterOption', { inputValue, option });
              // return option.label.toLowerCase().indexOf(inputValue.toString().toLowerCase()) !== -1;
              const splits = inputValue
                .toString()
                .split(' ')
                .map((str) => str.toLowerCase());
              let found = true;
              const label = (option?.label as string) ?? '';
              splits.forEach((str) => {
                found = found && label.toLowerCase().indexOf(str) !== -1;
              });
              return found;
            }}
            handleSelect={handleSelectSearch}
            handleButtonClick={handleFilter}
          />
          <FilterDisplay items={searchItems} type="search" onClose={handleResetSearch} />
        </div>
      </div>

      <div className={styles.filterBar}>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '1em' }}>
          <Select
            placeholder="Sort by"
            value={sortedBy}
            onChange={handleSort}
            style={{ minWidth: 120, marginRight: 10, marginBottom: 10 }}
            className={classNames(['mbx-round-select'])}
          >
            <Select.Option value="name:asc">{`A -> Z`}</Select.Option>
            <Select.Option value="name:desc">{`Z -> A`}</Select.Option>
            <Select.Option value="publishDate:asc">Published first</Select.Option>
            <Select.Option value="publishDate:desc">Published last</Select.Option>
            {/* <Select.Option value="date:asc">Created first</Select.Option>
            <Select.Option value="date:desc">Created last</Select.Option> */}
          </Select>

          <Select
            mode="multiple"
            allowClear
            defaultActiveFirstOption={false}
            placeholder="Filter by category"
            value={currentFilterCategories}
            onChange={handleCategoriesChanged}
            style={{ minWidth: 150, marginRight: 10, marginBottom: 10 }}
            optionFilterProp="label"
            dropdownMatchSelectWidth={false}
            className={classNames([styles.selectWithClear, 'mbx-round-select'])}
            // eslint-disable-next-line react/no-unstable-nested-components
            tagRender={(options) => {
              const { label, closable, onClose } = options;
              const text = (label as React.ReactElement).props?.children ?? '??';
              return (
                <Tag
                  color="pink"
                  closable={closable}
                  onClose={onClose}
                  style={{ marginRight: 3, lineHeight: 1.4 }}
                >
                  {text}
                </Tag>
              );
            }}
          >
            {categorySource.map((item) => (
              <Select.Option value={item.value} key={item.key} label={item.text}>
                <span
                  style={{
                    color: item.isError ? 'red' : '#eb2f96',
                    // fontWeight: 'bold',
                  }}
                >
                  {item.text}
                </span>
              </Select.Option>
            ))}
          </Select>
          <SelectPlayerType value={currentFilterTypes} onChange={handleTypesChanged} />

          <Checkbox
            checked={currentFree}
            onChange={handleToggleFreeContent}
            style={{ marginBottom: 10, lineHeight: 2.2 }}
          >
            free only
          </Checkbox>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', minWidth: '170px' }}>
          <Pagination
            simple
            hideOnSinglePage
            current={currentPage}
            total={totalItems}
            pageSize={itemsPerPage}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {(currentRegion?.regionCountries.length === 0 ||
        currentRegion?.regionLocales.length === 0) && (
        <Alert
          style={{ marginBottom: '2em' }}
          message={`Setup of market "${currentRegion.name}" is not complete yet`}
          description={
            <>
              <p>
                You have to add at least one country and one language to the market, otherwise
                releases in this market cannot be published during deployment.
              </p>
              <ButtonLinked
                linkTo={getUrlByName(ROUTE_NAME_APP_REGIONDETAIL, {
                  ':appUid': currentApp.uid,
                  ':regionId': currentRegion.id,
                })}
              >
                edit market
              </ButtonLinked>
            </>
          }
          type="error"
        />
      )}

      <List
        data-tid="release-list"
        bordered={false}
        dataSource={currentPageItems}
        renderItem={(release: Release) => (
          <ReleaseListItem
            release={release}
            allCategoriesForApp={allCategoriesForApp}
            currentRegion={currentRegion}
            openEditRelease={openEditRelease}
            openCategory={openCategory}
            openEpisodeDetail={openEpisodeDetail}
          />
        )}
      />

      <div
        style={{
          marginTop: '2em',
          marginBottom: '2em',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Pagination
          simple
          hideOnSinglePage
          current={currentPage}
          total={totalItems}
          pageSize={itemsPerPage}
          onChange={handlePaginationChange}
        />
      </div>

      <LocalDateFootnote />
    </div>
  );
};

export default ReleaseList;
