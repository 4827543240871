import { message, Tag, Popover } from 'antd';
import { useState } from 'react';

import styles from './ReleaseCategoryInlineAdd.module.scss';
import ReleaseCategoryInlineAddForm from './ReleaseCategoryInlineAddForm';
import { updateRelease } from '../actions/releases';
import { useAppDispatch } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { Category } from '../reducers/CategoryReducer';
import type { Release } from '../reducers/ReleaseReducer';
import type { MouseEventHandler } from 'react';

type Props = Readonly<{
  release: Release;
  allCategoriesForApp: Array<Category>;
}>;

//
//
const ReleaseCategoryInlineAdd = (props: Props) => {
  const dispatch = useAppDispatch();
  const { release, allCategoriesForApp } = props;

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    console.log('handleVisibleChange()', visible);
    setIsPopoverVisible(visible);
  };

  const handleOk = async (categories: Array<{ id: number; name: string }>) => {
    console.log('handleOk()', categories);
    try {
      await dispatch(
        updateRelease(
          release.id,
          release.isDemoContent,
          release.isDraft,
          release.publishDate,
          release.unpublishDate,
          categories
        )
      );
      setIsPopoverVisible(false);
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  const handleCancel: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setIsPopoverVisible(false);
  };

  if (allCategoriesForApp.length === 0) {
    return null;
  }

  return (
    <Popover
      placement="bottomLeft"
      trigger="click"
      content={
        <ReleaseCategoryInlineAddForm
          visible={isPopoverVisible}
          release={release}
          allCategoriesForApp={allCategoriesForApp}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      }
      arrowPointAtCenter
      visible={isPopoverVisible}
      onVisibleChange={handleVisibleChange}
      overlayClassName={styles.popover}
      destroyTooltipOnHide
    >
      <Tag
        color="pink"
        className={styles.tag}
        key={`${release.id}-category-inlineadd`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        +
      </Tag>
    </Popover>
  );
};

export default ReleaseCategoryInlineAdd;
