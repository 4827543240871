import type { AppNormalized } from '../reducers/AppsReducer';
import type { TwofaData } from '../reducers/AuthReducer';
import type { UserInvite } from '../reducers/InviteReducer';
import type { Role } from '../reducers/RoleReducer';
import type { UserNormalized } from '../reducers/UsersReducer';

export type LoginOkAction = {
  type: 'LOGIN_OK';
  payload: {
    result: number;
    entities: {
      apps: { [k: string | number]: AppNormalized };
      users: { [k: string | number]: UserNormalized };
      roles: { [k: string | number]: Role };
    };
  };
  accessToken: string;
  authServiceName: string;
  redirectForceTwofa: number;
  invites: Array<UserInvite>;
};

export type TwofaDeactiveAction = {
  type: 'TWOFA_DEACTIVATE';
};

export type TwofaDeactiveOkAction = {
  type: 'TWOFA_DEACTIVATE_OK';
  redirectForceTwofa: number;
};

export type TwofaDeactiveFailAction = {
  type: 'TWOFA_DEACTIVATE_FAIL';
  payload: string;
};

export type LoginFailAction = {
  type: 'LOGIN_FAIL';
  payload: {
    code: string;
    message: string;
  };
};

export type LogoutOkAction = {
  type: 'LOGOUT_OK';
  redirectUrl: string | null;
};

export type LogoutAction = {
  type: 'LOGOUT';
};

export type LogoutFailAction = {
  type: 'LOGOUT_FAIL';
  payload: {
    code: string;
    message: string;
  };
};

export type LoginTwofaAction = {
  type: 'LOGIN_TWOFA_STEP';
  payload: TwofaData;
};

export type LoginTwofaResetAction = {
  type: 'LOGIN_TWOFA_RESET';
};

export const LOGIN = 'LOGIN';
export const LOGIN_OK = 'LOGIN_OK';
export const LOGIN_FAIL = 'LOGIN_FAIL';

// export const LOGINCHECK = 'LOGINCHECK';
// export const LOGINCHECK_OK = 'LOGINCHECK_OK';
// export const LOGINCHECK_FAIL = 'LOGINCHECK_FAIL';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_OK = 'LOGOUT_OK';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const TWOFA_SETUP = 'TWOFA_SETUP';
export const TWOFA_SETUP_OK = 'TWOFA_SETUP_OK';
export const TWOFA_SETUP_FAIL = 'TWOFA_SETUP_FAIL';

export const TWOFA_ACTIVATE = 'TWOFA_ACTIVATE';
export const TWOFA_ACTIVATE_OK = 'TWOFA_ACTIVATE_OK';
export const TWOFA_ACTIVATE_FAIL = 'TWOFA_ACTIVATE_FAIL';

export const TWOFA_DEACTIVATE = 'TWOFA_DEACTIVATE';
export const TWOFA_DEACTIVATE_OK = 'TWOFA_DEACTIVATE_OK';
export const TWOFA_DEACTIVATE_FAIL = 'TWOFA_DEACTIVATE_FAIL';

export const LOGIN_TWOFA_STEP = 'LOGIN_TWOFA_STEP';

export const LOGIN_TWOFA_RESET = 'LOGIN_TWOFA_RESET';

export const GET_AUTH_SERVICES = 'GET_AUTH_SERVICES';
export const GET_AUTH_SERVICES_OK = 'GET_AUTH_SERVICES_OK';
export const GET_AUTH_SERVICES_FAIL = 'GET_AUTH_SERVICES_FAIL';
