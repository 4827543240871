import { EditOutlined, DeleteOutlined, OrderedListOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Tag } from 'antd';
import { useState } from 'react';

import styles from './CategoryList.module.scss';
import MbxButton from '../components/base/MbxButton';
import CategoryAddModal from '../components/CategoryAddModal';
import CustomPageHeader from '../components/CustomPageHeader';
import { isUserSuperadmin } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { Category } from '../reducers/CategoryReducer';

type Props = {
  allCategories: Array<Category>;
  deleteCategory: (categoryId: number) => Promise<void>;
  createOrUpdateCategory: (
    categoryId: number | null | undefined,
    name: string,
    isDraft: boolean,
    isLocked: boolean
  ) => Promise<void>;
  openCategorySort: (category: Category) => void;
};

//
//
const CategoryList = (props: Props) => {
  const [modalInfo, setModalInfo] = useState<{
    isOpen: boolean;
    selectedCategory: Category | null;
  }>({ isOpen: false, selectedCategory: null });

  const handleCategoryAdd = () => {
    setModalInfo({ isOpen: true, selectedCategory: null });
  };

  const handleCategoryAddCancel = () => {
    setModalInfo({ isOpen: false, selectedCategory: null });
  };

  const handleCategoryEdit = (category: Category) => {
    setModalInfo({ isOpen: true, selectedCategory: category });
  };

  const handleCategoryDelete = (category: Category) => {
    if (window.confirm(`Really delete ${category.name}?`)) {
      const { deleteCategory } = props;
      deleteCategory(category.id);
    }
  };

  const handleOnSubmit = async (
    categoryId: number | null | undefined,
    name: string,
    isDraft: boolean,
    isLocked: boolean
  ) => {
    try {
      const { createOrUpdateCategory } = props;
      await createOrUpdateCategory(categoryId, name, isDraft, isLocked);
      handleCategoryAddCancel();
    } catch (err) {
      logUnknownError(err);
    }
  };

  const { allCategories, openCategorySort } = props;
  const isSuperadmin = useGlobalSelector(isUserSuperadmin);

  return (
    <div>
      <CustomPageHeader
        title="List of all categories"
        extra={[
          <MbxButton
            key="btn-add-category"
            mbxType="primary"
            onClick={handleCategoryAdd}
            icon={<PlusOutlined />}
          >
            add category
          </MbxButton>,
        ]}
      />

      <div style={{ marginTop: '2em' }}>
        <List
          data-tid="category-list"
          bordered={false}
          dataSource={allCategories}
          renderItem={(category: Category) => {
            const actions = [];

            actions.push(
              <MbxButton
                size="small"
                icon={<OrderedListOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  openCategorySort(category);
                }}
              />
            );

            if (!category.isLocked || (category.isLocked && isSuperadmin)) {
              actions.push(
                <MbxButton
                  size="small"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCategoryEdit(category);
                  }}
                />
              );
              actions.push(
                <MbxButton
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCategoryDelete(category);
                  }}
                />
              );
            }

            return (
              <List.Item
                className={styles.item}
                onClick={() => openCategorySort(category)}
                key={category.id}
                actions={actions}
              >
                <List.Item.Meta
                  title={
                    <>
                      <span style={{ paddingRight: '1em' }}>{category.name}</span>
                      {category.isDraft && <Tag color="orange">is draft</Tag>}
                      {category.isLocked && <Tag color="purple">locked</Tag>}
                    </>
                  }
                />
              </List.Item>
            );
          }}
        />
      </div>

      <CategoryAddModal
        visible={modalInfo.isOpen}
        onSubmit={handleOnSubmit}
        onClose={handleCategoryAddCancel}
        data={modalInfo.selectedCategory}
        isUserSuperadmin={isSuperadmin}
      />
    </div>
  );
};

export default CategoryList;
