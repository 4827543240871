import { Alert, message } from 'antd';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { createEpisode } from '../actions/episodes';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { getTagList } from '../actions/tags';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_EPISODEDETAIL } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import EpisodeCreate from '../pages/EpisodeCreate';
import { getAllTagsForApp } from '../selectors/tagSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';
import { generateTagInfo } from '../utils/tag';

import type { EpisodeEditFormValues } from '../components/EpisodeEditForm';

//
//
const EpisodeCreateContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { currentApp } = useCurrentAppContext();
  const allTags = useGlobalSelector((state) => getAllTagsForApp(state, currentApp.id));

  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(getTagList(currentApp.id));
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(() => msg);
      } finally {
        dispatch(counterDecrement());
      }
    };

    fetchData();
  }, [dispatch, currentApp.id]);

  const handleSubmit = async (values: EpisodeEditFormValues) => {
    try {
      setIsBusy(true);
      console.log('handleSubmit()', values);
      const { name, playerType, resourceType, tags } = values;
      const tagInfo = generateTagInfo(tags, allTags);
      const episode = await dispatch(
        createEpisode(currentApp.id, name, playerType, resourceType, tagInfo)
      );
      // don't use finally to reset isBusy b/c we're switching pages before that would happen
      setIsBusy(false);
      history.push(
        getUrlByName(ROUTE_NAME_APP_EPISODEDETAIL, {
          ':appUid': currentApp.uid,
          ':episodeId': episode.id as number,
        })
      );
      return true;
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
      // don't use finally to reset isBusy b/c we're switching pages before that would happen
      setIsBusy(false);
    }
    return false;
  };

  if (error !== '') {
    return <Alert showIcon type="error" message="Error" description={error} />;
  }

  return <EpisodeCreate createEpisode={handleSubmit} isBusy={isBusy} />;
};

export default EpisodeCreateContainer;
