import { APPPLATFORMS_DELETE_OK, APPPLATFORMS_GET_LIST_OK } from '../constants/appplatforms';
import { GET_APPS_LIST_OK, GET_APP_DETAILS_OK } from '../constants/apps';
import {
  APPVERSIONS_CREATE_OK,
  APPVERSIONS_UPDATE_OK,
  APPVERSIONS_DELETE_OK,
} from '../constants/appversions';
import { ASSET_GET_DETAILS_OK } from '../constants/assets';
import { LOGIN_OK } from '../constants/auth';
import { EPISODE_GET_DETAILS_OK } from '../constants/episodes';

import type { AnyAction } from 'redux';

export type AppVersion = {
  id: number;
  name: string;
  description: string | null | undefined;
};

export type AppVersionState = { [k: string | number]: AppVersion };

export const getEmptyAppVersion = (id?: number): AppVersion => ({
  id: id ?? -1,
  name: '0.0.0-unknown',
  description: null,
});

const initialStateById: AppVersionState = {};

//
//
const byId = (state = initialStateById, action: AnyAction): AppVersionState => {
  switch (action.type) {
    case GET_APPS_LIST_OK:
    case LOGIN_OK:
    case GET_APP_DETAILS_OK:
    case ASSET_GET_DETAILS_OK:
    case APPVERSIONS_CREATE_OK:
    case APPVERSIONS_UPDATE_OK:
    case EPISODE_GET_DETAILS_OK:
    case APPPLATFORMS_GET_LIST_OK: {
      if (action?.payload?.entities?.appVersions == null) {
        return state;
      }
      return {
        ...state,
        ...action.payload.entities.appVersions,
      };
    }

    case APPVERSIONS_DELETE_OK: {
      const { appVersionId } = action;
      const { [appVersionId]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    case APPPLATFORMS_DELETE_OK: {
      const { deletedAppPlatform } = action;
      const copy = { ...state };
      deletedAppPlatform.versions.forEach((v: AppVersion) => delete copy[v.id]);
      return copy;
    }

    default:
      return state;
  }
};

export default byId;
