import { COUNTRY_GET_LIST_OK } from '../constants/countries';
import { REGION_GET_DETAILS_OK, REGION_GET_LIST_OK } from '../constants/regions';

import type { AnyAction } from 'redux';

export type Country = {
  id: number;
  name: string;
  shortcode: string;
  createdAt: Date;
  updatedAt: Date | null;
};

export type CountryState = {
  [k: string | number]: Country;
};

//
//
export const getEmptyCountry = (id?: number): Country => ({
  id: id ?? -1,
  name: '',
  shortcode: '',
  createdAt: new Date(),
  updatedAt: null,
});

const initialStateById: CountryState = {};

//
//
// eslint-disable-next-line default-param-last
const byId = (state = initialStateById, action: AnyAction): CountryState => {
  switch (action.type) {
    case COUNTRY_GET_LIST_OK:
      return action.payload.entities.countries;

    case REGION_GET_LIST_OK:
    case REGION_GET_DETAILS_OK:
      return {
        ...state,
        ...action.payload.entities.countries,
      };

    default:
      return state;
  }
};

export default byId;
