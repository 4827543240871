import { createSelector } from 'reselect';

import { getEmptyPlatform } from '../reducers/PlatformReducer';
import { sortByName } from '../utils/sort';

import type { RootState } from '../reducers';

//
//
const getById = (state: RootState) => state.platforms.byId;
const getAllIds = (state: RootState) => state.platforms.allIds;

//
//
export const getPlatformByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => {
    const platform = byId[id];
    if (platform == null) {
      return getEmptyPlatform(id);
    }
    return platform;
  }
);

//
//
export const getPlatformsByIdsFunc = createSelector(
  getPlatformByIdFunc,
  //
  (getPlatformById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }

    return ids.map((id) => getPlatformById(id));
  }
);

//
//
export const getAllPlatforms = createSelector(
  getAllIds,
  getPlatformsByIdsFunc,
  //
  (allIds, getPlatformsByIds) => {
    const platforms = getPlatformsByIds(allIds);
    platforms.sort(sortByName);
    return platforms;
  }
);
