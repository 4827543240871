import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

import { ERROR_STATUS_ABOVE_THIS, FileStatusNames } from '../common/constants/file-status';

import type { EpisodeLocalized } from '../reducers/EpisodeLocalizedReducer';
import type { Episode } from '../reducers/EpisodeReducer';
import type { VersionFile } from '../reducers/FileReducer';

type Props = Readonly<{
  episode?: Episode;
  localizedEpisode?: EpisodeLocalized;
}>;

type ErrorInfo = {
  name: string;
  status: string;
};

function shortenVersionName(v: VersionFile) {
  const name = `${v.name}.${v.ext}`;
  const splits = name.split('.');
  splits.shift();
  return splits.join('.');
}

function checkIfErrorFound(le: EpisodeLocalized) {
  const errors: Array<ErrorInfo> = [];
  le.content.forEach((ec) => {
    if (ec.resource.file == null) {
      return;
    }

    const original = ec.resource.file.originalName;

    // there are files that don't have any versions b/c some error happened and then the status of
    // the original file was changed to an error state
    if (ec.resource.file.versions.length === 0) {
      const s = ec.resource.file.lastStatusId;
      if (s > ERROR_STATUS_ABOVE_THIS) {
        errors.push({ name: original, status: FileStatusNames[s] });
      }
    }

    ec.resource.file.versions.forEach((v) => {
      const shortVersionName = shortenVersionName(v);
      if (v.lastStatusId > ERROR_STATUS_ABOVE_THIS) {
        errors.push({
          name: shortVersionName,
          status: FileStatusNames[v.lastStatusId],
        });
      }
    });
  });
  return errors;
}

const Marker = ({ errors }: { errors: Array<ErrorInfo> }) => (
  <Popover
    content={
      <ul style={{ padding: 0, margin: 0, fontSize: 11, listStyle: 'none' }}>
        {errors.map((e) => (
          <li key={`error-${e.name}`}>
            {e.name}: <b style={{ color: '#FF8163' }}>{e.status}</b>
          </li>
        ))}
      </ul>
    }
    title="Conversion errors"
  >
    <div
      style={{
        backgroundColor: '#FF8163',
        width: 24,
        padding: '10px 2px 0',
        marginLeft: 8,
        borderRadius: 4,
        fontSize: 20,
        color: '#fff',
      }}
      data-tid="episode-with-error-mark"
    >
      <InfoCircleOutlined />
    </div>
  </Popover>
);

//
//
const EpisodeErrorMarker = (props: Props) => {
  const { episode, localizedEpisode } = props;
  const errorsFound: Array<ErrorInfo> = [];

  if (episode != null) {
    episode.localizedEpisodes.forEach((le) => {
      const errors = checkIfErrorFound(le);
      errorsFound.push(...errors);
    });

    return errorsFound.length > 0 ? <Marker errors={errorsFound} /> : null;
  }

  if (localizedEpisode != null) {
    const errors = checkIfErrorFound(localizedEpisode);
    errorsFound.push(...errors);
    return errorsFound.length > 0 ? <Marker errors={errorsFound} /> : null;
  }

  return null;
};

export default EpisodeErrorMarker;
