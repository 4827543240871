import { schema, normalize } from 'normalizr';

import {
  COUNTRY_GET_LIST,
  COUNTRY_GET_LIST_OK,
  COUNTRY_GET_LIST_FAIL,
} from '../constants/countries';
import { CountrySchema } from '../schemas';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';

//
//
export const getCountryList = () => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/countries`,
    },
    dispatch,
    { init: COUNTRY_GET_LIST, fail: COUNTRY_GET_LIST_FAIL }
  );
  const normalizedData = normalize(response.data, new schema.Array(CountrySchema));
  dispatch({ type: COUNTRY_GET_LIST_OK, payload: normalizedData });
  return normalizedData;
};
