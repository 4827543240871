const Uploading = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.1" cx="12" cy="12" r="11.4375" stroke="#0E0E2C" strokeWidth="1.125" />
    <path
      d="M9.62997 17.04H14.13C14.2791 17.04 14.4 17.1609 14.4 17.31C14.4 17.4591 14.2791 17.58 14.13 17.58H9.62997C9.48086 17.58 9.35997 17.4591 9.35997 17.31C9.35997 17.1609 9.48086 17.04 9.62997 17.04Z"
      stroke="#0E0E2C"
      strokeWidth="0.96"
    />
    <path
      d="M12 14.4V7.44M12 7.44L9.35999 10.08M12 7.44L14.76 10.08"
      stroke="#0E0E2C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="mask0_419_38"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="12"
      y="0"
      width="12"
      height="12"
    >
      <rect x="12" width="12" height="12" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_419_38)">
      <circle cx="12" cy="12" r="11.0625" stroke="#0E0E2C" strokeWidth="1.875" />
    </g>
  </svg>
);

export default Uploading;
