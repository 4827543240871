import { combineReducers } from 'redux';

import { JOB_GET_LIST_OK, JOB_GET_DETAILS_OK, JOB_GET_METADATA_OK } from '../constants/jobs';

import type { AnyAction } from 'redux';

export type JobLog = {
  id: number;
  statusId: number;
  meta: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  createdAt: Date;
};

export type JobLogStateById = { [k: string | number]: JobLog };

export type JobLogState = {
  byId: JobLogStateById;
};

const initialStateById: JobLogStateById = {};

//
//
const byId = (state = initialStateById, action: AnyAction): JobLogStateById => {
  switch (action.type) {
    case JOB_GET_LIST_OK:
    case JOB_GET_DETAILS_OK:
    case JOB_GET_METADATA_OK: {
      if (typeof action.payload.entities.jobLogs === 'undefined') {
        return state;
      }
      return {
        ...state,
        ...action.payload.entities.jobLogs,
      };
    }
    default:
      return state;
  }
};

const combined = combineReducers({
  byId,
});

export default combined;
