import { DownOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { List, Tag, Tooltip, Dropdown, Menu } from 'antd';

import LocalReleaseDates from './base/LocalReleaseDates';
import MbxButton from './base/MbxButton';
import EpisodeThumbnail from './EpisodeLocalizedThumbnail';
import ListItemType from './ListItemType';
import ReleaseCategoryInlineAdd from './ReleaseCategoryInlineAdd';
import styles from './ReleaseListItem.module.scss';
import { getReleaseStatus } from '../common/utils/release';
import { getAppPlatformsForApp } from '../selectors/appPlatformSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { Category } from '../reducers/CategoryReducer';
import type { Region } from '../reducers/RegionReducer';
import type { ReleaseCategory } from '../reducers/ReleaseCategoryReducer';
import type { Release } from '../reducers/ReleaseReducer';
import type { MenuProps } from 'antd';

type Props = {
  release: Release;
  allCategoriesForApp: Array<Category>;
  currentRegion: Region | null | undefined;

  openEpisodeDetail: (release: Release) => void;
  openEditRelease: (releaseId: number) => void;
  openCategory: (categoryId: number) => void;
};

//
//
const ReleaseListItem = (props: Props) => {
  const {
    release,
    currentRegion,
    openEditRelease,
    openCategory,
    openEpisodeDetail,
    allCategoriesForApp,
  } = props;

  const appPlatforms = useGlobalSelector((state) =>
    getAppPlatformsForApp(state, currentRegion?.appId ?? -1)
  );

  const releaseStatus = getReleaseStatus(release, currentRegion, appPlatforms.length);

  const handleMenuClick: MenuProps['onClick'] = (info) => {
    console.log('handleMenuClick()', info);
    info.domEvent.stopPropagation();
    switch (info.key) {
      case 'editRelease':
        openEditRelease(release.id);
        break;
      case 'editEpisode':
        openEpisodeDetail(release);
        break;
      default:
        console.error('unknown key', info.key);
    }
  };

  return (
    <List.Item
      key={`release-item-${release.id}`}
      className={styles.item}
      onClick={() => {
        openEditRelease(release.id);
      }}
      actions={[
        <Dropdown
          key="dd-edit-release-or-episode"
          trigger={['click']}
          overlay={
            <Menu onClick={handleMenuClick}>
              <Menu.Item key="editRelease">release</Menu.Item>
              <Menu.Item key="editEpisode">episode</Menu.Item>
            </Menu>
          }
        >
          <MbxButton
            mbxType="secondary"
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            edit <DownOutlined />
          </MbxButton>
        </Dropdown>,
      ]}
    >
      <List.Item.Meta
        title={
          <>
            <span
              style={{
                paddingRight: '1em',
              }}
            >
              <span>{release?.episode?.name ?? '...'}</span>
            </span>
            <span>
              {release.isDraft && (
                <Tag style={{ marginBottom: '4px', border: 0 }} color="orange">
                  draft
                </Tag>
              )}
              {release.isDemoContent && (
                <Tag style={{ marginBottom: '4px', border: 0 }} color="green">
                  free
                </Tag>
              )}
              {release.categories
                .map((releaseCategory: ReleaseCategory) => releaseCategory.category)
                .map((c: Category) => (
                  <Tag
                    color="pink"
                    style={{
                      opacity: c.isDraft ? 0.4 : 1.0,
                      cursor: 'pointer',
                      border: 0,
                    }}
                    className={styles.categoryTag}
                    key={`${release.id}-category-${c.id}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      openCategory(c.id);
                    }}
                  >
                    {c.name}
                  </Tag>
                ))}
              <ReleaseCategoryInlineAdd
                release={release}
                allCategoriesForApp={allCategoriesForApp}
              />
            </span>
          </>
        }
        description={
          <>
            <LocalReleaseDates release={release} releaseStatus={releaseStatus} />

            {(releaseStatus.releasable === false || releaseStatus.releasable === 'incomplete') && (
              <div style={{ marginTop: 2 }}>
                {releaseStatus.localeStatus.map((ls) => (
                  <Tag
                    key={`release-loc-status-${ls.id}`}
                    color={ls.releasable === true ? 'green' : undefined}
                    style={{
                      fontSize: 10,
                      lineHeight: '14px',
                      paddingRight: 4,
                      paddingLeft: 4,
                    }}
                  >{`${ls.shortcode} `}</Tag>
                ))}
              </div>
            )}
          </>
        }
        avatar={
          <div style={{ display: 'flex' }}>
            <ListItemType episode={release.episode} />
            <EpisodeThumbnail episode={release.episode} />
            {/* <div
              style={{
                backgroundColor: '#f0f',
                width: 24,
                padding: '10px 2px 0',
                marginLeft: 8,
                fontSize: 20,
                color: '#fff',
              }}
            >
              <Tooltip title={<pre>{JSON.stringify(releaseStatus, null, 2)}</pre>}>
                <InfoCircleOutlined />
              </Tooltip>
            </div> */}

            {/* isDataFetching ? ( */}

            {releaseStatus.releasable === 'unknown' ? (
              <div
                style={{
                  backgroundColor: '#9c9c9c66',
                  width: 24,
                  padding: '10px 2px 0',
                  marginLeft: 8,
                  borderRadius: 4,
                  fontSize: 20,
                  color: '#fff',
                }}
              >
                <LoadingOutlined />
              </div>
            ) : (
              (releaseStatus.releasable === false || releaseStatus.releasable === 'incomplete') && (
                <div
                  style={{
                    backgroundColor: '#FF8163',
                    width: 24,
                    padding: '10px 2px 0',
                    marginLeft: 8,
                    borderRadius: 4,
                    fontSize: 20,
                    color: '#fff',
                  }}
                  data-tid="unreleasable-release-mark"
                >
                  <Tooltip
                    title={
                      <>
                        <p>
                          {release.episode?.localizedEpisodes?.length === 0
                            ? 'no content found'
                            : `missing content for: ${releaseStatus.localeStatus
                                .filter((ls) => ls.releasable !== true)
                                .map((ls) => ls.shortcode)
                                .join(', ')}`}
                        </p>
                        {release.episode?.localizedEpisodes?.length > 0 && (
                          <ul style={{ fontSize: 10, padding: '0 0 0 10px', margin: 0 }}>
                            {releaseStatus.localeStatus
                              .filter((ls) => !ls.releasable && ls.leCreated)
                              .flatMap((ls) => ls.reasons)
                              .map((reason) => (
                                <li key={`reason-${reason.versionId}`}>{reason.msg}</li>
                              ))}
                          </ul>
                        )}
                      </>
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              )
            )}
          </div>
        }
      />
    </List.Item>
  );
};

export default ReleaseListItem;
