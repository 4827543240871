import { Alert } from 'antd';
import { Redirect } from 'react-router-dom';

import PasswordForgotForm from '../components/PasswordForgotForm';
import { ROUTE_NAME_HOME } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getUserProfileOptional } from '../selectors/authSelectors';
import { useGlobalSelector } from '../utils/hooks';

import type { PasswordForgotFormValues } from '../components/PasswordForgotForm';

type Props = Readonly<{
  prefilledEmail: string | null | undefined;
  onSubmit: (values: PasswordForgotFormValues) => Promise<void>;
  sendData: { isSending: boolean; error: string; success: boolean };
}>;

//
//
const PasswordForgot = (props: Props) => {
  const { onSubmit, prefilledEmail, sendData } = props;
  const user = useGlobalSelector(getUserProfileOptional);

  if (user != null) {
    return <Redirect to={getUrlByName(ROUTE_NAME_HOME)} />;
  }
  return (
    <div>
      <h1>Forgot your password?</h1>
      <p>
        No problem, just enter your email-address and we will send you a link to set a new one...
      </p>

      {!sendData.success && (
        <PasswordForgotForm
          onSubmit={onSubmit}
          prefilledEmail={prefilledEmail}
          isSending={sendData.isSending}
        />
      )}

      {sendData.error !== '' && <p style={{ color: '#f00' }}>{sendData.error}</p>}

      {sendData.success && (
        <Alert
          type="success"
          message="We sent out an email with the reset link - look for it in your inbox..."
          style={{ width: 480 }}
        />
      )}
    </div>
  );
};

export default PasswordForgot;
