import { message } from 'antd';
import { useEffect, useState } from 'react';

import { removeUserInvite, getUserInvitesForApp } from '../actions/users';
import PendingInvites from '../components/PendingInvites';
import { getAllInvitesForApp } from '../selectors/inviteSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

type Props = Readonly<{
  appId: number | null;
}>;

//
//
const PendingInvitesContainer = (props: Props) => {
  const dispatch = useAppDispatch();

  const { appId } = props;
  const invites = useGlobalSelector((state) => getAllInvitesForApp(state, appId));

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(getUserInvitesForApp(appId));
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    if (appId !== -1) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch, appId]);

  const handleInviteRemove = async (inviteId: number, inviteHash: string) => {
    console.log('handleInviteRemove()', { inviteId, inviteHash });
    try {
      await dispatch(removeUserInvite(inviteId, inviteHash));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    }
  };

  return (
    <PendingInvites
      invites={invites}
      handleInviteRemove={handleInviteRemove}
      isLoading={isLoading}
    />
  );
};

export default PendingInvitesContainer;
