import { schema, normalize } from 'normalizr';

import {
  EPISODE_GET_LIST,
  EPISODE_GET_LIST_OK,
  EPISODE_GET_LIST_FAIL,
  EPISODE_GET_DETAILS,
  EPISODE_GET_DETAILS_OK,
  EPISODE_GET_DETAILS_FAIL,
  EPISODE_GET_RELEASES,
  EPISODE_GET_RELEASES_OK,
  EPISODE_GET_RELEASES_FAIL,
  EPISODE_CREATE,
  EPISODE_CREATE_OK,
  EPISODE_CREATE_FAIL,
  EPISODE_UPDATE,
  EPISODE_UPDATE_OK,
  EPISODE_UPDATE_FAIL,
  EPISODE_REMOVE,
  EPISODE_REMOVE_OK,
  EPISODE_REMOVE_FAIL,
  EPISODE_GET_METADATA,
  EPISODE_GET_METADATA_OK,
  EPISODE_GET_METADATA_FAIL,
  EPISODE_TAGS_CLEANUP,
} from '../constants/episodes';
import { EpisodeSchema, ReleaseSchema } from '../schemas';
import { getAllEpisodesForApp, getEpisodeById } from '../selectors/episodeSelectors';
import { getEpisodeReleases as getEpisodeReleasesSelector } from '../selectors/releaseSelectors';
import { wrapFetch } from '../utils/api';

import type { AppDispatch } from '../configureStore';
import type { GlobalStateGetter } from '../reducers';
import type { Episode } from '../reducers/EpisodeReducer';
import type { TagInfo } from '../utils/tag';

//
//
export const createEpisode =
  (
    appId: number,
    name: string,
    playerTypeId: number,
    resourceTypeId: number,
    tags: Array<TagInfo>
  ) =>
  async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/episodes`,
        method: 'POST',
        data: {
          appId,
          name,
          playerTypeId,
          resourceTypeId,
          tags,
        },
      },
      dispatch,
      { init: EPISODE_CREATE, fail: EPISODE_CREATE_FAIL },
      201
    );
    const normalizedData = normalize(response.data, EpisodeSchema);
    dispatch({ type: EPISODE_CREATE_OK, appId, payload: normalizedData });
    return response.data;
  };

//
//
export const getEpisodeDetails =
  (appId: number, episodeId: number) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch({ url: `/episodes/${episodeId}` }, dispatch, {
      init: EPISODE_GET_DETAILS,
      fail: EPISODE_GET_DETAILS_FAIL,
    });
    const normalizedData = normalize(response.data, EpisodeSchema);
    dispatch({ type: EPISODE_GET_DETAILS_OK, appId, episodeId, payload: normalizedData });
    return response.data;
  };

//
//
export const getEpisodeReleases = (episodeId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch({ url: `/episodes/${episodeId}/releases` }, dispatch, {
    init: EPISODE_GET_RELEASES,
    fail: EPISODE_GET_RELEASES_FAIL,
  });
  const normalizedData = normalize(response.data, new schema.Array(ReleaseSchema));
  dispatch({ type: EPISODE_GET_RELEASES_OK, payload: normalizedData });
  return response.data;
};

//
//
export const getEpisodesMetadata =
  (appId: number, ids: Array<number>) =>
  async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const state = getState();

    // allow maximum of 25 ids (performance ...)
    if (ids.length > 25) {
      throw new Error('getEpisodesMetadata(): asked for too many episodes');
    }

    // check if all episodeIds belong to the same app
    let checkAppId: null | number = null;
    ids.forEach((episodeId) => {
      const episode = getEpisodeById(state, episodeId);
      if (episode.id === -1) {
        throw new Error(`unknown episode [id:${episodeId}]`);
      }
      if (checkAppId == null) {
        checkAppId = episode.appId;
      } else if (checkAppId !== episode.appId) {
        throw new Error('episodes do not belong to same app');
      }
    });

    const response = await wrapFetch(
      {
        url: `/episodes/metadata`,
        params: {
          ids: ids.join(','),
          appId,
        },
      },
      dispatch,
      {
        init: EPISODE_GET_METADATA,
        fail: EPISODE_GET_METADATA_FAIL,
      }
    );
    const normalizedData = normalize(response.data, new schema.Array(EpisodeSchema));
    dispatch({ type: EPISODE_GET_METADATA_OK, payload: normalizedData, appId });
    return response.data;
  };

//
//
export const getEpisodeList = (appId: number) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/episodes`,
      params: {
        appId,
      },
    },
    dispatch,
    {
      init: EPISODE_GET_LIST,
      fail: EPISODE_GET_LIST_FAIL,
    }
  );
  const normalizedData = normalize(response.data, new schema.Array(EpisodeSchema));
  dispatch({ type: EPISODE_GET_LIST_OK, appId, payload: normalizedData });
  return normalizedData;
};

//
//
export const updateEpisode =
  (
    episodeId: number,
    name: string,
    playerTypeId: number,
    resourceTypeId: number,
    thumbnailId: number | null,
    tags: Array<{ id: 'new' | number; name: string }>,
    appId: number
  ) =>
  async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    const response = await wrapFetch(
      {
        url: `/episodes/${episodeId}`,
        method: 'PUT',
        data: {
          appId,
          name,
          playerTypeId,
          resourceTypeId,
          thumbnailId,
          tags,
        },
      },
      dispatch,
      {
        init: EPISODE_UPDATE,
        fail: EPISODE_UPDATE_FAIL,
      }
    );
    const normalizedData = normalize(response.data, EpisodeSchema);
    dispatch({ type: EPISODE_UPDATE_OK, payload: normalizedData, appId: response.data.appId });

    const episodes = getAllEpisodesForApp(getState(), Number(response.data.appId));
    dispatch({ type: EPISODE_TAGS_CLEANUP, appId: response.data.appId, episodes });

    return response.data;
  };

//
//
export const deleteEpisode =
  (episode: Episode) => async (dispatch: AppDispatch, getState: GlobalStateGetter) => {
    await wrapFetch(
      {
        url: `/episodes/${episode.id}`,
        method: 'DELETE',
      },
      dispatch,
      {
        init: EPISODE_REMOVE,
        fail: EPISODE_REMOVE_FAIL,
      },
      204
    );
    const episodeReleases = getEpisodeReleasesSelector(getState(), episode.id);
    dispatch({ type: EPISODE_REMOVE_OK, episode, releaseIds: episodeReleases.map((r) => r.id) });

    const episodes = getAllEpisodesForApp(getState(), episode.appId);
    dispatch({ type: EPISODE_TAGS_CLEANUP, appId: episode.appId, episodes });

    return true;
  };
