import { getEmptyAppPlatform } from './AppPlatformReducer';
import {
  ASSETRESOURCEVERSIONREQUIREMENTS_CREATE_OK,
  ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE_OK,
  ASSETRESOURCEVERSIONREQUIREMENTS_DELETE_OK,
} from '../constants/assetResourceVersionRequirements';
import { ASSET_GET_DETAILS_OK } from '../constants/assets';

import type { AppPlatform } from './AppPlatformReducer';
import type { AppVersion } from './AppVersionReducer';
import type { AnyAction } from 'redux';
import type { Merge } from 'type-fest';

type AssetResourceVersionRequirementBase = {
  id: number;
  exclude?: boolean;
};

export type AssetResourceVersionRequirementNormalized = Merge<
  AssetResourceVersionRequirementBase,
  {
    appPlatform: number;
    minVersion: number | null;
    maxVersion: number | null;
  }
>;

export type AssetResourceVersionRequirement = Merge<
  AssetResourceVersionRequirementBase,
  {
    appPlatform: AppPlatform;
    minVersion: AppVersion | null;
    maxVersion: AppVersion | null;
  }
>;

export type AssetResourceVersionRequirementState = {
  [k: string | number]: AssetResourceVersionRequirementNormalized;
};

export const getEmptyAssetResourceVersionRequirement = (
  id?: number
): AssetResourceVersionRequirement => ({
  id: id ?? -1,
  appPlatform: getEmptyAppPlatform(),
  minVersion: null,
  maxVersion: null,
});

const initialStateById: AssetResourceVersionRequirementState = {};

//
//
const byId = (
  state = initialStateById,
  action: AnyAction
): AssetResourceVersionRequirementState => {
  switch (action.type) {
    case ASSET_GET_DETAILS_OK:
    case ASSETRESOURCEVERSIONREQUIREMENTS_CREATE_OK:
    case ASSETRESOURCEVERSIONREQUIREMENTS_UPDATE_OK: {
      const requirements = action?.payload?.entities?.assetResourceRequirements;
      if (requirements == null) {
        return state;
      }
      return {
        ...state,
        ...requirements,
      };
    }

    case ASSETRESOURCEVERSIONREQUIREMENTS_DELETE_OK: {
      const { [action.assetResourceVersionRequirementId]: remove, ...stateWithoutDeleted } = state;
      return stateWithoutDeleted;
    }

    default:
      return state;
  }
};

export default byId;
