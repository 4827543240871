import { Alert, Form, Select, Switch, Checkbox, Spin } from 'antd';
import uniqBy from 'lodash.uniqby';
import { useState, useEffect, useRef } from 'react';

import BackButton from './BackButton';
import MbxButton from './base/MbxButton';
import ReleaseFormCategories from './ReleaseFormCategories';
import ReleaseFormDates from './ReleaseFormDates';
import ReleaseMultiEpisodeSelector from './ReleaseMultiEpisodeSelector';
import { CLIENT_TIMEZONE, type DateWrapper } from '../utils/date';
import { sortByName } from '../utils/sort';
import { addTimeoffset } from '../utils/timezone';

import type { Category } from '../reducers/CategoryReducer';
import type { Episode } from '../reducers/EpisodeReducer';
import type { Region } from '../reducers/RegionReducer';
import type { Release } from '../reducers/ReleaseReducer';

type ReleaseFormValues = {
  regionId: number;
  episodeId: number;
  // see: https://stackoverflow.com/q/70041229/388026
  [key: `episodeId${number}`]: number;
  isDemoContent: boolean;
  isDraft: boolean;
  categories: Array<string>;
  publishDate: DateWrapper;
  unpublishDate: DateWrapper | null | undefined;
  createAnother: boolean;
  unpublishDateEnabled: boolean;
};

export type ReleaseFormSubmitValues = {
  regionId: number;
  episodeId: number | Array<number>;
  isDemoContent: boolean;
  isDraft: boolean;
  categories: Array<{ id: string | number; name: string }>;
  publishDate: Date;
  unpublishDate: Date | null | undefined;
  createAnotherChecked?: boolean;
};

type Props = Readonly<{
  type: 'add' | 'edit';
  backLink: string;
  data?: Release;
  releasedEpisodeIdsPerRegion: { [k: string | number]: Array<number> };
  appEpisodes: Array<Episode>;
  appRegions: Array<Region>;
  selectedRegionId: number | null;
  currentEpisodeId?: number;
  appCategories: Array<Category>;
  onSubmit: (values: ReleaseFormSubmitValues) => Promise<void>;
  setSelectedRegionId?: (regionId: number) => void;
  // onSelectRegion: (regionId: number) => Promise<void>,
}>;

//
//
const ReleaseEditForm = (props: Props) => {
  const {
    data,
    appRegions,
    appCategories,
    type,
    currentEpisodeId,
    // currentRegionId,
    selectedRegionId,
    appEpisodes,
    releasedEpisodeIdsPerRegion,
    backLink,
    // onSelectRegion,
    setSelectedRegionId,
    onSubmit,
  } = props;

  const [form] = Form.useForm();
  const [startValue, setStartValue] = useState(data?.publishDate ? data.publishDate : null);
  const [endValue, setEndValue] = useState(data?.unpublishDate ? data.unpublishDate : null);
  const [unpublishEnabled, setUnpublishEnabled] = useState(!!data?.unpublishDate);

  const selectedRegion = appRegions ? appRegions.find((r) => r.id === selectedRegionId) : null;
  const selectedTimezone = selectedRegion?.releaseTimezone?.name ?? null;

  const regionsEmpty = appRegions == null || appRegions.length === 0;

  useEffect(() => {
    if (data?.publishDate != null) {
      setStartValue(data.publishDate);
    }

    if (data?.unpublishDate != null) {
      setEndValue(data.unpublishDate);
    }
  }, [data]);

  // console.log('region', {
  //   'data?.region?.id': data?.region?.id,
  //   selectedRegionId,
  //   appRegions,
  //   currentEpisodeId,
  // });

  let unreleasedEpisodes: Array<Episode> = [];
  if (selectedRegionId !== null && type === 'add') {
    unreleasedEpisodes = appEpisodes
      .filter((e) => {
        const x = releasedEpisodeIdsPerRegion[selectedRegionId];
        if (!Array.isArray(x)) {
          return true;
        }
        return !releasedEpisodeIdsPerRegion[selectedRegionId].includes(e.id);
      })
      .sort(sortByName);
  }

  const initialEpisodeIdValue = currentEpisodeId !== -1 ? currentEpisodeId : undefined;

  const [alreadySelectedEpisodes, setAlreadySelectedEpisodes] = useState([
    initialEpisodeIdValue ?? -1,
  ]);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (type === 'add' && selectedRegionId != null) {
      console.log('SET REGION', selectedRegionId, 'firstUpdate', firstUpdate.current);
      // b/c I don't want to reset all episodeId0-999 by hand, just reset the whole form
      form.resetFields();

      form.setFieldsValue({
        regionId: selectedRegionId,
        publishDate: undefined,
        unpublishDate: undefined,
        unpublishDateEnabled: false,
        episodeId: firstUpdate.current === true ? initialEpisodeIdValue : undefined,
        isDraft: false,
        isDemoContent: false,
      });
      setUnpublishEnabled(false);
      setAlreadySelectedEpisodes(() => {
        if (firstUpdate.current === true && initialEpisodeIdValue != null) {
          return [initialEpisodeIdValue];
        }
        return [-1];
      });
      setStartValue(null);
      setEndValue(null);

      if (firstUpdate.current === true) {
        firstUpdate.current = false;
      }
    }
  }, [form, type, selectedRegionId, initialEpisodeIdValue]);

  // console.log({ selectedRegionId, releasedEpisodeIdsPerRegion, unreleasedEpisodes });

  const handleSubmit = async (values: ReleaseFormValues) => {
    console.log('Received values of form: ', values);
    const { unpublishDateEnabled } = values;

    if (selectedTimezone == null) {
      throw new Error('selectedTimezone not set');
    }

    let unpublishDate;
    if (unpublishDateEnabled === true) {
      if (values.unpublishDate == null) {
        console.error('create release form error: ', { unpublishDateEnabled, values });
        return;
      }
      const unpublishDateForTimezone = addTimeoffset(
        values.unpublishDate,
        selectedTimezone,
        CLIENT_TIMEZONE
      );
      if (unpublishDateForTimezone == null) {
        throw new Error('unpublishDateForTimezone is not set');
      }
      unpublishDate = unpublishDateForTimezone.toDate();
    }

    const dateForTimezone = addTimeoffset(values.publishDate, selectedTimezone, CLIENT_TIMEZONE);
    if (dateForTimezone == null) {
      throw new Error('dateForTimezone is not set');
    }

    const publishDate = dateForTimezone.toDate();
    const categoryInfo = uniqBy(
      values.categories.map((categoryStr: string) => {
        const existingCategory = appCategories.find(
          (c: Category) => c.name.toLowerCase() === categoryStr.toLowerCase()
        );
        if (typeof existingCategory !== 'undefined') {
          return { id: Number(existingCategory.id), name: existingCategory.name };
        }
        throw new Error('unknown category');
      }),
      'name'
    );

    const isPropNumber = (obj: unknown): obj is number => typeof obj === 'number';

    const submitData: ReleaseFormSubmitValues = {
      createAnotherChecked: values.createAnother,
      publishDate,
      unpublishDate: unpublishDate ?? null, // make sure its null if nullish
      categories: [],
      episodeId: values.episodeId,
      isDemoContent: values.isDemoContent,
      isDraft: values.isDraft,
      regionId: values.regionId,
    };

    console.log('converted categories to: ', categoryInfo);
    submitData.categories = categoryInfo;

    // check if one or multiple episodeIds
    let episodeIds = [values.episodeId];
    Object.keys(values).forEach((key) => {
      const k = key as keyof ReleaseFormValues;
      const val = values[k];
      if (/episodeId\d+/s.test(k) && isPropNumber(val)) {
        episodeIds.push(val);
      }
    });
    // make em unique
    episodeIds = [...new Set(episodeIds)];
    if (episodeIds.length > 1) {
      submitData.episodeId = episodeIds;
    }

    console.log('SEND', submitData);
    await onSubmit(submitData);

    if (values.createAnother) {
      // b/c I don't want to reset all episodeId0-999 by hand, just reset the whole form
      form.resetFields();

      form.setFieldsValue({
        regionId: selectedRegionId,
        episodeId: undefined,
        publishDate: undefined,
        unpublishDateEnabled: false,
        unpublishDate: undefined,
        isDraft: false,
        isDemoContent: false,
      });

      setUnpublishEnabled(false);
      setAlreadySelectedEpisodes([-1]);
    }
  };

  const handleRegionChange = (value: number) => {
    if (typeof setSelectedRegionId === 'function') {
      setSelectedRegionId(value);
    }
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        name="ReleaseEditForm"
        colon={false}
        layout="vertical"
      >
        {type === 'add' && (
          <Form.Item label="Market" initialValue={selectedRegionId} required={type === 'add'}>
            {regionsEmpty && <Spin />}
            {!regionsEmpty && (
              <Form.Item
                name="regionId"
                noStyle
                rules={[{ required: true, message: 'Please add at least one market!' }]}
              >
                <Select
                  data-tid="region-select"
                  showSearch
                  disabled={data != null}
                  placeholder="Select a market"
                  onChange={handleRegionChange}
                  filterOption={(input, option) =>
                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {appRegions.map((r: Region) => (
                    <Select.Option key={r.id} value={r.id}>
                      {r.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
        )}

        {!regionsEmpty && selectedTimezone != null && (
          <>
            {type === 'add' &&
              selectedRegionId !== null &&
              unreleasedEpisodes.length === 0 &&
              Object.keys(releasedEpisodeIdsPerRegion).length !== 0 && (
                <Alert
                  showIcon
                  type="success"
                  message="All episodes released in this market"
                  // description="You've already released all episodes in this region, create a new episode "
                />
              )}

            {(type === 'edit' || unreleasedEpisodes.length > 0) && (
              <>
                <ReleaseMultiEpisodeSelector
                  form={form}
                  unreleasedEpisodes={unreleasedEpisodes}
                  alreadySelectedEpisodes={alreadySelectedEpisodes}
                  setAlreadySelectedEpisodes={setAlreadySelectedEpisodes}
                />

                <ReleaseFormCategories form={form} data={data} appCategories={appCategories} />

                <ReleaseFormDates
                  form={form}
                  selectedTimezone={selectedTimezone}
                  unpublishEnabled={unpublishEnabled}
                  setUnpublishEnabled={setUnpublishEnabled}
                  startValue={startValue}
                  setStartValue={setStartValue}
                  endValue={endValue}
                  setEndValue={setEndValue}
                />

                <Form.Item
                  label="Is Draft"
                  name="isDraft"
                  initialValue={data?.isDraft ?? false}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  label="Is Free"
                  name="isDemoContent"
                  initialValue={data?.isDemoContent ?? false}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>

                <Form.Item>
                  <div style={{ marginTop: '2em', marginBottom: '1em' }}>
                    <MbxButton mbxType="primary" htmlType="submit" data-tid="btn-submit">
                      {type === 'edit' ? 'Save' : 'Create'}
                    </MbxButton>
                    <BackButton
                      style={{ marginLeft: '1em', marginRight: '1em' }}
                      linkTo={backLink}
                      size="large"
                    >
                      Cancel
                    </BackButton>
                    {type === 'add' && (
                      <Form.Item name="createAnother" valuePropName="checked" noStyle>
                        <Checkbox>create another one</Checkbox>
                      </Form.Item>
                    )}
                  </div>
                </Form.Item>
              </>
            )}
          </>
        )}
      </Form>
    </div>
  );
};

export default ReleaseEditForm;
