import { DownOutlined } from '@ant-design/icons';
import { List, Dropdown, Menu, Alert } from 'antd';
import { useEffect, useState } from 'react';

import styles from './AppAnalyticsViews.module.scss';
import { fetchAnalyticsExportsList } from '../actions/analytics';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { AnalyticsExportType } from '../common/constants/analyticsexport-type';
import MbxButton from '../components/base/MbxButton';
import CustomPageHeader from '../components/CustomPageHeader';
import { useCurrentAppContext } from '../contexts';
import { ROUTE_NAME_APP_ANALYTICS } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getAllAnalyticsExportsForAppAndType } from '../selectors/analyticsSelectors';
import { formatDate } from '../utils/date';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

import type { AnalyticsExport } from '../reducers/AnalyticsReducer';

const date = new Date();

const displaySizeInKb = (size: unknown): string => {
  if (typeof size === 'number' && size !== -1) {
    return `${(size / 1024).toFixed(2)} kB`;
  }
  return '??';
};

const displayExportsExt = (ae: AnalyticsExport): string => {
  if (ae.ext === 'csv') {
    return `${ae.ext} (${ae.meta?.counterName ?? '??'})`;
  }
  return ae.ext;
};

//
//
const AppAnalyticsMaus = () => {
  const { currentApp } = useCurrentAppContext();
  const dispatch = useAppDispatch();

  const backLink = getUrlByName(ROUTE_NAME_APP_ANALYTICS, {
    ':appUid': currentApp.uid,
  });

  const allExports = useGlobalSelector((state) =>
    getAllAnalyticsExportsForAppAndType(
      state,
      currentApp.id,
      AnalyticsExportType.MONTHLY_ACTIVE_USERS
    )
  );

  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(counterIncrement());
        await dispatch(
          fetchAnalyticsExportsList(currentApp.id, AnalyticsExportType.MONTHLY_ACTIVE_USERS)
        );
      } catch (err) {
        const { msg } = logUnknownError(err);
        setError(msg);
      } finally {
        dispatch(counterDecrement());
      }
    };

    if (currentApp.id !== -1) {
      fetchData();
    }
  }, [dispatch, currentApp.id]);

  const groupedAndSorted: Record<string, Array<AnalyticsExport>> = {};
  allExports.forEach((ae) => {
    const key = `${ae.year}-${ae.month.toString().padStart(2, '0')}`;
    if (groupedAndSorted[key] == null) {
      groupedAndSorted[key] = [];
    }
    groupedAndSorted[key].push(ae);
  });
  const orderedKeys = Object.keys(groupedAndSorted).sort((a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) {
      return 1;
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return -1;
    }
    return 0;
  });
  const list = orderedKeys.map((key) => {
    const aes = groupedAndSorted[key];
    aes.sort((a, b) => {
      if (a.ext.toLowerCase() < b.ext.toLowerCase()) {
        return -1;
      }
      if (a.ext.toLowerCase() > b.ext.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return aes;
  });

  return (
    <div>
      <CustomPageHeader
        title={`Monthly active user reports for ${currentApp.name}`}
        backLink={backLink}
      />

      {error != null && (
        <Alert data-tid="error-box" showIcon type="error" message="Error" description={error} />
      )}

      {error == null && (
        <div
          style={{
            marginTop: '2em',
          }}
        >
          <List
            id="mbx-analyticsexports-maus-list"
            bordered={true}
            dataSource={list}
            renderItem={(aes) => {
              const firstItem = aes[0];
              // database is 1-based, JS is 0-based
              date.setMonth(firstItem.month - 1);

              const menu = (
                <Menu>
                  {aes.map((ae) => (
                    <Menu.Item key={`ae-download-menuitem-${ae.id}`}>
                      <a href={`/api/analytics/exports/${ae.id}/download`} download>
                        <span>{displayExportsExt(ae)}</span>
                        <span className={styles.itemDate}>{`(${displaySizeInKb(
                          ae.meta?.size
                        )})`}</span>
                        <span className={styles.itemDate}>{formatDate(ae.createdAt)}</span>
                      </a>
                    </Menu.Item>
                  ))}
                </Menu>
              );

              return (
                <List.Item
                  key={`ae-item-${firstItem.year}-${firstItem.month}`}
                  actions={[
                    <Dropdown
                      key={`ae-dd-${firstItem.year}-${firstItem.month}`}
                      overlay={menu}
                      placement="bottomRight"
                    >
                      <MbxButton size="small">
                        download <DownOutlined />
                      </MbxButton>
                    </Dropdown>,
                  ]}
                  className={styles.listItem}
                >
                  <List.Item.Meta
                    title={`${date.toLocaleString('default', { month: 'long' })} ${firstItem.year}`}
                    description={`available formats: ${aes
                      .map((ae) => displayExportsExt(ae))
                      .join(', ')}`}
                  />
                </List.Item>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AppAnalyticsMaus;
