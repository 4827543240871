import {
  ROLE_GET_LIST,
  ROLE_GET_LIST_FAIL,
  ROLE_GET_LIST_OK,
  ROLE_CREATE,
  ROLE_CREATE_OK,
  ROLE_CREATE_FAIL,
  ROLE_UPDATE,
  ROLE_UPDATE_OK,
  ROLE_UPDATE_FAIL,
  ROLE_REMOVE,
  ROLE_REMOVE_OK,
  ROLE_REMOVE_FAIL,
} from '../constants/roles';
import { wrapFetch } from '../utils/api';

import type { RoleModalValues } from '../components/RoleModal';
import type { AppDispatch } from '../configureStore';
import type { Role } from '../reducers/RoleReducer';

//
//
export const createRole =
  (appId: number | null, values: RoleModalValues) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/roles`,
        method: 'POST',
        data: {
          appId,
          ...values,
        },
      },
      dispatch,
      { init: ROLE_CREATE, fail: ROLE_CREATE_FAIL },
      201
    );

    dispatch({ type: ROLE_CREATE_OK, role: response.data });
    return response.data;
  };

//
//
export const deleteRole = (role: Role) => async (dispatch: AppDispatch) => {
  await wrapFetch(
    {
      url: `/roles/${role.id}`,
      method: 'DELETE',
    },
    dispatch,
    { init: ROLE_REMOVE, fail: ROLE_REMOVE_FAIL },
    204
  );

  dispatch({ type: ROLE_REMOVE_OK, roleId: role.id, appId: role.appId });
  return true;
};

//
//
export const editRole =
  (roleId: number, values: RoleModalValues) => async (dispatch: AppDispatch) => {
    const response = await wrapFetch(
      {
        url: `/roles/${roleId}`,
        method: 'PUT',
        data: {
          ...values,
        },
      },
      dispatch,
      { init: ROLE_UPDATE, fail: ROLE_UPDATE_FAIL }
    );

    dispatch({ type: ROLE_UPDATE_OK, role: response.data });
    return response.data;
  };

//
//
export const getRoleList = (appId?: number | null) => async (dispatch: AppDispatch) => {
  const response = await wrapFetch(
    {
      url: `/roles`,
      method: 'GET',
      params: {
        appId: appId == null ? 'null' : appId,
      },
    },
    dispatch,
    { init: ROLE_GET_LIST, fail: ROLE_GET_LIST_FAIL }
  );

  dispatch({ type: ROLE_GET_LIST_OK, appId, roles: response.data });
  return response.data;
};
