import { Layout } from 'antd';
import { Link } from 'react-router-dom';

import { UI_COMMIT_HASH } from '../constants/appversion';
import { ROUTE_NAME_IMPRINT } from '../navigation/routes';
import { getUrlByName } from '../navigation/utils';
import { getAppVersion } from '../selectors/adminUiVersionSelectors';
import { useGlobalSelector } from '../utils/hooks';

const { Footer } = Layout;

const currentYear = new Date().getFullYear();

//
//
const PageFooter = () => {
  const appVersion = useGlobalSelector(getAppVersion);

  return (
    <Footer
      style={{
        padding: '14px 25px 15px',
        textAlign: 'center',
        fontSize: 12,
        backgroundColor: 'transparent',
      }}
    >
      <span>{`\xA9 2019-${currentYear.toString().substring(2, 4)} Wonderz GmbH`}</span>
      <span
        style={{ marginLeft: 10, color: '#ccc', fontSize: 11 }}
      >{`v${appVersion} (build ${UI_COMMIT_HASH}) (${process.env.REACT_APP_ENVIRONMENT})`}</span>
      <span style={{ marginLeft: 10 }}>
        <Link
          style={{ color: '#ccc', fontSize: 11, textDecoration: 'underline' }}
          to={getUrlByName(ROUTE_NAME_IMPRINT)}
        >
          Imprint
        </Link>
      </span>
    </Footer>
  );
};

export default PageFooter;
