import { Form, DatePicker, Switch } from 'antd';
import { useEffect } from 'react';

import MbxButton from './base/MbxButton';
import ClientTimezoneLabel from './ClientTimezoneLabel';
import { CLIENT_TIMEZONE } from '../utils/date';
import { addTimeoffset } from '../utils/timezone';

import type { DateWrapper } from '../utils/date';
import type { FormInstance, DatePickerProps } from 'antd';

type Props = {
  form: FormInstance;
  selectedTimezone: string;
  unpublishEnabled: boolean;
  setUnpublishEnabled: (bool: boolean) => void;
  startValue: Date | null;
  setStartValue: (val: Date | null) => void;
  endValue: Date | null;
  setEndValue: (val: Date | null) => void;
};

//
//
const ReleaseFormDates = (props: Props) => {
  const {
    form,
    selectedTimezone,
    unpublishEnabled,
    setUnpublishEnabled,
    startValue,
    setStartValue,
    endValue,
    setEndValue,
  } = props;

  const disabledStartDate = (start: DateWrapper) => {
    if (start == null || endValue == null) {
      return false;
    }
    return start.valueOf() > endValue.valueOf();
  };

  const disabledEndDate = (end: DateWrapper) => {
    if (end == null || startValue == null) {
      return false;
    }
    return end.valueOf() <= startValue.valueOf();
  };

  const onStartChange: DatePickerProps['onChange'] = (value) => {
    const dateForTimezone = addTimeoffset(value, selectedTimezone, CLIENT_TIMEZONE);
    if (dateForTimezone == null) {
      console.info('onStartChange()', {
        value,
        selectedTimezone,
        CLIENT_TIMEZONE,
      });
      throw new Error('dateForTimezone is null');
    }
    setStartValue(dateForTimezone.toDate());
  };

  const onEndChange: DatePickerProps['onChange'] = (value) => {
    const dateForTimezone = addTimeoffset(value, selectedTimezone, CLIENT_TIMEZONE);
    if (dateForTimezone == null) {
      console.info('onEndChange()', {
        value,
        selectedTimezone,
        CLIENT_TIMEZONE,
      });
      throw new Error('dateForTimezone is null');
    }
    setEndValue(dateForTimezone.toDate());
  };

  const handleUnpublishEnabledChange = (value: boolean) => {
    setUnpublishEnabled(value);
    console.log('handleUnpublishDisableChange()', value);
    if (value === false) {
      form.setFieldsValue({ unpublishDate: null });
      // b/c form.setFieldsValue doesn't trigger onChange, we do it manually
      onEndChange(null, '');
    }
  };

  const handleNowClicked = () => {
    setStartValue(new Date());
  };

  useEffect(() => {
    if (startValue != null && selectedTimezone != null) {
      form.setFieldsValue({
        publishDate: addTimeoffset(startValue, CLIENT_TIMEZONE, selectedTimezone),
      });
    }
  }, [startValue, form, selectedTimezone]);

  useEffect(() => {
    if (endValue != null && selectedTimezone != null) {
      form.setFieldsValue({
        unpublishDate: addTimeoffset(endValue, CLIENT_TIMEZONE, selectedTimezone),
      });
    }
  }, [endValue, form, selectedTimezone]);

  return (
    <>
      <Form.Item
        data-tid="form-item-publishdate"
        label={
          <div style={{ display: 'flex' }}>
            <span style={{ display: 'inline-block' }}>Publish Date</span>
            <span style={{ marginLeft: '0.5em', fontWeight: 500 }}>{`(${selectedTimezone})`}</span>
          </div>
        }
      >
        <Form.Item
          name="publishDate"
          rules={[{ required: true, message: 'Please add a publish date!' }]}
          noStyle
        >
          <DatePicker
            disabled={selectedTimezone === null}
            disabledDate={disabledStartDate}
            showTime={{
              minuteStep: 15,
              format: 'HH:mm',
            }}
            showNow={false}
            format="YYYY-MM-DD HH:mm"
            showToday={false}
            placeholder="Start"
            onChange={onStartChange}
            style={{ marginRight: 10 }}
          />
        </Form.Item>
        <MbxButton
          data-tid="btn-publishdate-now"
          style={{ marginRight: 10 }}
          size="middle"
          onClick={handleNowClicked}
        >
          Now
        </MbxButton>
        <ClientTimezoneLabel selectedTimezone={selectedTimezone} time={startValue} />
      </Form.Item>

      <Form.Item
        label={
          <div style={{ display: 'flex' }}>
            <span style={{ display: 'inline-block' }}>Unpublish Date</span>
            <span style={{ marginLeft: '0.5em', fontWeight: 500 }}>{`(${selectedTimezone})`}</span>
          </div>
        }
      >
        <Form.Item
          name="unpublishDateEnabled"
          initialValue={endValue != null}
          valuePropName="checked"
          noStyle
        >
          <Switch disabled={selectedTimezone === null} onChange={handleUnpublishEnabledChange} />
        </Form.Item>
        <Form.Item
          name="unpublishDate"
          rules={[
            {
              required: unpublishEnabled,
              message: 'Please add an unpublish date!',
            },
          ]}
          noStyle
        >
          <DatePicker
            data-tid="datepicker-unpublish"
            disabled={selectedTimezone === null || !unpublishEnabled}
            disabledDate={disabledEndDate}
            showTime={{
              minuteStep: 15,
              format: 'HH:mm',
            }}
            format="YYYY-MM-DD HH:mm"
            showToday={false}
            placeholder="End"
            onChange={onEndChange}
            style={{ marginLeft: 10, marginRight: 10 }}
          />
        </Form.Item>
        <ClientTimezoneLabel selectedTimezone={selectedTimezone} time={endValue} />
      </Form.Item>
    </>
  );
};

export default ReleaseFormDates;
