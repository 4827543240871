import { createSelector } from 'reselect';

import { getEmptyAnalyticsViewsEpisodedLocalized } from '../reducers/AnalyticsViewsEpisodesLocalizedReducer';
import { getEmptyAnalyticsViewsEpisodes } from '../reducers/AnalyticsViewsEpisodesReducer';

import type { RootState } from '../reducers';

const viewsEpisodesById = (state: RootState) => state.analyticsViewsEpisodes.byId;
const viewsEpisodesLocalizedById = (state: RootState) => state.analyticsViewsEpisodesLocalized.byId;

export const getAnalyticsViewsForEpisodeLocalizedFunc = createSelector(
  viewsEpisodesLocalizedById,
  (byId) => (id: number) => {
    if (byId[id] == null) {
      return getEmptyAnalyticsViewsEpisodedLocalized();
    }

    return byId[id];
  }
);

export const getAnalyticsViewsForEpisodeFunc = createSelector(
  viewsEpisodesById,
  (byId) => (id: number) => {
    if (byId[id] == null) {
      return getEmptyAnalyticsViewsEpisodes();
    }

    return byId[id];
  }
);
