import { Button } from 'antd';
import classNames from 'classnames';

import styles from './MbxButton.module.scss';

import type { ButtonProps } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import type { CSSProperties } from 'react';

export type MbxButtonType = 'primary' | 'secondary' | 'tertiary';

export type MbxButtonProps = Readonly<
  ButtonProps & {
    mbxType?: MbxButtonType;
    linkColor?: string;
  }
>;

const classes = {
  primary: styles.mbxButtonPrimary,
  secondary: styles.mbxButtonSecondary,
  tertiary: styles.mbxButtonTertiary,
};

export function getButtonProps({
  mbxType = 'secondary',
  size,
  className,
  linkColor,
}: {
  mbxType?: MbxButtonType;
  size?: SizeType;
  className?: string;
  linkColor?: string;
}) {
  const buttonStyle: ButtonProps = {};

  const style: CSSProperties = {};

  if (mbxType === 'primary') {
    buttonStyle.type = 'primary';
    buttonStyle.size = size ?? 'large';
    buttonStyle.shape = 'round';
  } else if (mbxType === 'secondary') {
    buttonStyle.type = 'default';
    buttonStyle.size = size ?? 'middle';
    buttonStyle.shape = 'round';
  } else {
    style.color = linkColor;
  }

  return {
    className: classNames([styles.mbxButton, classes[mbxType], className]),
    ...buttonStyle,
    style,
  };
}

//
//
const MbxButton = (props: MbxButtonProps) => {
  const {
    children,
    className,
    mbxType = 'secondary',
    size,
    linkColor,
    style: originalStyle,
    ...otherTagProps
  } = props;
  const {
    className: cn,
    style,
    ...buttonStyle
  } = getButtonProps({ mbxType, size, className, linkColor });

  return (
    <Button
      className={cn}
      {...otherTagProps}
      {...buttonStyle}
      style={{ ...originalStyle, ...style }}
    >
      {children}
    </Button>
  );
};

export default MbxButton;
