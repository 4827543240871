import { createSelector } from 'reselect';

import { getEmptyRole } from '../reducers/RoleReducer';

import type { RootState } from '../reducers';
import type { Role } from '../reducers/RoleReducer';

//
//
const getById = (state: RootState) => state.roles.byId;
const allIds = (state: RootState) => state.roles.allIds;

//
//
export const getRoleByIdFunc = createSelector(
  getById,
  //
  (byId) => (id: number) => {
    const role = byId[id];
    if (role == null) {
      return getEmptyRole(id);
    }
    return role;
  }
);

//
//
export const getRoleById = createSelector(
  getRoleByIdFunc,
  (_: unknown, id: number) => id,
  //
  ($getRoleById, id) => $getRoleById(id)
);

//
//
export const getRolesByIdsFunc = createSelector(
  getRoleByIdFunc,
  //
  ($getRoleById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }

    return ids.map((id: number) => $getRoleById(id)).filter((r): r is Role => !!r);
  }
);

//
//
export const getAllRolesForApp = createSelector(
  allIds,
  getRolesByIdsFunc,
  (_: unknown, appId: number | null) => `${appId}`,
  //
  ($allIds, $getRolesByIds, appId) => {
    const allAppIds = $allIds[appId];
    if (!Array.isArray(allAppIds)) {
      return [];
    }
    return $getRolesByIds(allAppIds);
  }
);
