import { message } from 'antd';
import { useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { getJobDetails } from '../actions/jobs';
import { counterIncrement, counterDecrement } from '../actions/syncing';
import { JobStatus } from '../common/constants/job-status';
import JobDetail from '../pages/JobDetail';
import { getJobById } from '../selectors/jobSelectors';
import { getJobId } from '../selectors/urlSelectors';
import { useAppDispatch, useGlobalSelector } from '../utils/hooks';
import { logUnknownError } from '../utils/log';

//
//
const JobDetailContainer = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const jobId = getJobId(params);
  const jobDetails = useGlobalSelector((state) => getJobById(state, jobId));
  console.log('JobDetailContainer jobDetails', jobDetails);

  const timeoutRef = useRef<number | null>(null);

  let lastJobStatus = jobDetails.logs?.[0]?.statusId.toString() ?? '-1';
  if (lastJobStatus !== '-1' && timeoutRef.current == null) {
    lastJobStatus += `###${+new Date()}`;
  }

  const fetchData = useCallback(async () => {
    try {
      dispatch(counterIncrement());
      await dispatch(getJobDetails(jobId));
    } catch (err) {
      const { msg } = logUnknownError(err);
      message.error(msg);
    } finally {
      dispatch(counterDecrement());
    }
  }, [dispatch, jobId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const checkJobStatus = async () => {
      if (timeoutRef.current != null) {
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      await fetchData();
    };

    if (lastJobStatus === '-1') {
      return;
    }

    const [statusAsString] = lastJobStatus.toString().split('###');
    const status = Number(statusAsString);
    if (status < JobStatus.COMPLETED) {
      if (timeoutRef.current == null) {
        timeoutRef.current = window.setTimeout(checkJobStatus, 5000);
      }
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (timeoutRef.current != null) {
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [lastJobStatus, fetchData]);

  return <JobDetail details={jobDetails} />;
};

export default JobDetailContainer;
