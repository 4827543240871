import { createSelector } from 'reselect';

import { getTagByIdFunc } from './tagSelectors';
import { getEmptyEpisodeTag } from '../reducers/EpisodeTagReducer';

import type { RootState } from '../reducers';

//
//
const getById = (state: RootState) => state.episodeTags.byId;

//
//
export const getEpisodeTagByIdFunc = createSelector(
  getById,
  getTagByIdFunc,
  //
  (byId, $getTagById) => (id: number) => {
    const episodeTag = byId[id];
    if (episodeTag == null) {
      return getEmptyEpisodeTag(id);
    }
    return {
      ...episodeTag,
      tag: $getTagById(episodeTag.tag),
    };
  }
);

//
//
export const getEpisodeTagsByIdsFunc = createSelector(
  getEpisodeTagByIdFunc,
  //
  ($getEpisodeTagById) => (ids: Array<number>) => {
    if (!Array.isArray(ids)) {
      return [];
    }
    return ids.map((id) => $getEpisodeTagById(id));
  }
);
