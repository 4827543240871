import { UserRightIndexes } from '../constants/user-right';

import type { AllowedUserRight } from '../constants/user-right';

//
//
export const hasUserSufficientRights = (
  user: { roles: Array<{ appId: number | null; rights: Array<number> }> | null } | null,
  appId: number | null,
  rightName: AllowedUserRight
): boolean => {
  if (user == null || user.roles == null) {
    return false;
  }
  const index = UserRightIndexes[rightName];
  const { roles } = user;
  if (!Array.isArray(roles)) {
    return false;
  }
  return user.roles.some(
    (role) => (role.appId === appId || role.appId === null) && role.rights.includes(index)
  );
};
