export const APPVERSIONS_CREATE = 'APPVERSIONS_CREATE';
export const APPVERSIONS_CREATE_OK = 'APPVERSIONS_CREATE_OK';
export const APPVERSIONS_CREATE_FAIL = 'APPVERSIONS_CREATE_FAIL';

export const APPVERSIONS_DELETE = 'APPVERSIONS_DELETE';
export const APPVERSIONS_DELETE_OK = 'APPVERSIONS_DELETE_OK';
export const APPVERSIONS_DELETE_FAIL = 'APPVERSIONS_DELETE_FAIL';

export const APPVERSIONS_UPDATE = 'APPVERSIONS_UPDATE';
export const APPVERSIONS_UPDATE_OK = 'APPVERSIONS_UPDATE_OK';
export const APPVERSIONS_UPDATE_FAIL = 'APPVERSIONS_UPDATE_FAIL';
