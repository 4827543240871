import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip, Spin } from 'antd';

import styles from './PendingInvites.module.scss';

import type { UserInvite } from '../reducers/InviteReducer';

type Props = Readonly<{
  invites: Array<UserInvite>;
  handleInviteRemove: (inviteId: number, inviteHash: string) => Promise<void>;
  isLoading: boolean;
}>;

//
//
const PendingInvites = (props: Props) => {
  const { invites, handleInviteRemove, isLoading } = props;

  const handleDeleteInvite = (invite: UserInvite) => {
    if (window.confirm(`Really delete invite for ${invite.email}`)) {
      handleInviteRemove(invite.id, invite.hash);
    }
  };

  if ((invites?.length ?? 0) === 0) {
    return null;
  }

  return (
    <Tooltip
      title={
        <>
          {invites.map((i) => (
            <div
              data-tid={`pending-invites-tooltip-${i.id}`}
              key={`invite-tooltip-${i.id}`}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div
                style={{
                  padding: 0,
                  margin: '4px 0',
                  width: 'calc(100% - 28px)',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {i.email}
              </div>
              <Button
                danger
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                ghost
                onClick={() => {
                  handleDeleteInvite(i);
                }}
              />
            </div>
          ))}
        </>
      }
      placement="bottomRight"
    >
      <div data-tid="pending-invites-indicator" className={styles.invites}>
        {isLoading && <Spin style={{ margin: '0 1em 0 0' }} size="small" />}
        <span style={{ cursor: 'default' }}>{`${invites.length} pending User invite(s)`}</span>
      </div>
    </Tooltip>
  );
};

export default PendingInvites;
