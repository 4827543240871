import type { RootState } from '../reducers';

//
//
export const getAppVersion = (state: RootState) => state.adminUiVersion.appVersion;

//
//
export const getApiCommitHash = (state: RootState) => state.adminUiVersion.apiCommitHash;

//
//
export const getLastTimeChecked = (state: RootState) => state.adminUiVersion.lastTimeChecked;

//
//
export const getLastTimeAsked = (state: RootState) => state.adminUiVersion.lastTimeAsked;

//
//
export const getLastTimeReloaded = (state: RootState) => state.adminUiVersion.lastTimeReloaded;

//
//
export const getIsModalOpen = (state: RootState) => state.adminUiVersion.isModalOpen;
